import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import {
  Button,
  Tooltip,
} from 'antd';
import CarBlackListUploadModal from 'components/Modal/CarBlackListUpload';
import DocumentBatchDownloadModal from 'components/Modal/DocumentBatchDownload';
import { SendSmsBatchModal } from 'components/Modal/SendSmsBatch/SendSmsBatch';
import StatBalanceModal from 'components/Modal/StatBalance';
import TestEmailModal from 'components/Modal/TestEmail';
import VersionedDocumentUploadModal from 'components/Modal/VersionedDocumentUpload';
import { useAdminGeneralControls } from 'pages/PageAdmin/components/useAdminControls';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  border: 1px solid ${getLightStyleOr(palette.black80Color, palette.black30Color)};
  background-color: transparent;
  color: ${palette.whiteColor};
`;

export const ToolbarControls: React.FC = () => {
  const {
    controls,
    isCarBlackListUploadModalOpen,
    isDocumentBatchDownloadModalOpen,
    isSmsBatchModalOpen,
    isStatbalanceModalOpen,
    isTestEmailModalOpen,
    isUploadVersionedDocumentModalOpen,
    setCarBlackListUploadModal,
    setIsDocumentBatchDownloadModalOpen,
    setIsSmsBatchModalOpen,
    setIsStatbalanceModalOpen,
    setTestEmailModal,
    setUploadVersionedDocumentModal,
  } = useAdminGeneralControls();

  return (
    <>
      <Wrapper>
        {_.map(_.reject(controls, `isHidden`), (control, index) => (
          <React.Fragment key={index}>
            <Tooltip placement='bottomLeft' title={control.title}>
              <StyledButton
                disabled={control.isDisabled}
                icon={control.icon}
                onClick={control.onClick}
                shape='circle'
              />
            </Tooltip>
            {control.additional || null}
          </React.Fragment>
        ))}
      </Wrapper>

      <CarBlackListUploadModal
        isVisible={isCarBlackListUploadModalOpen}
        onClose={() => setCarBlackListUploadModal(!isCarBlackListUploadModalOpen)}
      />
      <TestEmailModal
        isVisible={isTestEmailModalOpen}
        onClose={() => setTestEmailModal(!isTestEmailModalOpen)}
      />
      <VersionedDocumentUploadModal
        isVisible={isUploadVersionedDocumentModalOpen}
        onClose={() => setUploadVersionedDocumentModal(!isUploadVersionedDocumentModalOpen)}
      />
      <DocumentBatchDownloadModal
        isVisible={isDocumentBatchDownloadModalOpen}
        onClose={() => setIsDocumentBatchDownloadModalOpen(v => !v)}
      />
      <StatBalanceModal
        isOpen={isStatbalanceModalOpen}
        onClose={() => setIsStatbalanceModalOpen(v => !v)}
      />
      <SendSmsBatchModal
        isOpen={isSmsBatchModalOpen}
        onClose={() => setIsSmsBatchModalOpen(v => !v)}
      />
    </>
  );
};
