/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import _ from 'lodash';

import {
  getAvgBy,
  prettifyNumber,
} from 'helper';

import { CellDateTime } from 'components/DefaultTable/CellDateTime/CellDateTime';
import { ISummaryItem } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';

export const getAvg = (field: string) => (data: any) => getAvgBy(data, field);
export const getMaxDate = (field: string) => (data: any) => _.max(_.map(data as [], field));
export const getSum = (field: string | ((value: unknown) => number) | undefined) => (data: _.List<unknown> | null | undefined) => _.sumBy(data, field);

export const SUMMARY_COLUMNS: ISummaryItem[] = [
  {
    key   : `businessCategory`,
    title : ``,
    render: _.noop,
  },
  {
    key   : `openedDate`,
    title : `Посл. открытие`,
    render: data => data.length && <CellDateTime value={getMaxDate(`openedDate`)(data)} />,
  },
  {
    key   : `lastPaymentDate`,
    title : `Дата посл. платежа`,
    render: data => data.length && <CellDateTime value={getMaxDate(`lastPaymentDate`)(data)} />,
  },
  {
    key   : `closedDate`,
    title : ``,
    render: _.noop,
  },
  {
    key   : `reportingDate`,
    title : `Посл. обновление`,
    render: data => data.length && <CellDateTime value={getMaxDate(`reportingDate`)(data)} />,
  },
  {
    key   : `affiliation`,
    title : ``,
    render: _.noop,
  },
  {
    key   : `accountType`,
    title : ``,
    render: _.noop,
  },
  {
    align : `right`,
    key   : `creditLimit`,
    title : `Средняя ∑ кредита`,
    render: data => prettifyNumber(getAvg(`creditLimit`)(data)),
  },
  {
    align : `right`,
    key   : `currentBalanceAmount`,
    title : `Средняя ∑ погашений`,
    render: data => prettifyNumber(getAvg(`currentBalanceAmount`)(data)),
  },
  {
    align : `right`,
    key   : `amountPastDue`,
    title : `∑ просрочек`,
    render: data => (
      <span
        className={getSum(`amountPastDue`)(data) > 0 ? `table-cell_red` : `table-cell`}
      >
        {prettifyNumber(getSum(`amountPastDue`)(data))}
      </span>
    ),
  },
  {
    align : `right`,
    key   : `monthlyPayment`,
    title : `∑ платежей (Активных кредитов)`,
    render: data => prettifyNumber(getSum(`monthlyPayment`)(_.filter(data, { accountRating: `Активный` }))),
  },
  {
    align : `right`,
    key   : `remainingDebt`,
    title : `∑ тек. остатков`,
    render: data => prettifyNumber(getSum(`remainingDebt`)(data)),
  },
  {
    key   : `paymentPat`,
    title : ``,
    render: _.noop,
  },
  {
    key   : `accountRating`,
    title : ``,
    render: _.noop,
  },
  {
    key   : `businessCategory`,
    title : ``,
    render: _.noop,
  },
  {
    key   : `normRate`,
    title : ``,
    render: _.noop,
  },
];
