import React, { forwardRef } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { Link } from 'dva/router';
import styled from 'styled-components';
import {
  getLightStyleOr,
  height,
  palette,
  shadows,
} from 'styles/theme';

import { logout as logoutAction } from 'models/user/actions';
import {
  getFullName,
  getRolesTitle,
} from 'models/user/selectors';

import { Menu } from 'antd';
import { MainNav } from 'pages/Layout/Header/helpers';

const Wrapper = styled.div<{ isVisible: boolean; }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${palette.backgroundColor};
  top: ${height.headerMobile};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 100ms ease;
  z-index: ${({ isVisible }) => (isVisible ? 4 : -1)};
  left: 0;
  pointer-events: ${({ isVisible }) => (isVisible ? `auto` : `none`)};
`;

const MenuStyled = styled(Menu)<{ visible?: boolean; }>`

  color: ${getLightStyleOr(palette.black100Color, palette.black40Color)};

  &.ant-menu-inline {
    border-color: ${getLightStyleOr(palette.whiteColor, palette.black30Color)};
  }

  .ant-menu-item {
    a {
      color: ${getLightStyleOr(palette.black100Color, palette.black40Color)};
    }

    &.ant-menu-item-selected {
      a {
        color: ${palette.primary500Color};
      }
    }
  }

  .ant-menu-item-divider {
    border-color: ${getLightStyleOr(palette.black20Color, palette.black30Color)};
  }


  &.ant-menu-root.ant-menu-inline {
    box-shadow: ${getLightStyleOr(shadows.basic, shadows.image)};
  }
`;

const Roles = styled.span`
  color: ${getLightStyleOr(palette.black60Color, palette.black40Color)};
`;

interface IComponentProps {
  nav: MainNav;
  onPreferencesOpen: () => void;
  selectedKeys: string[];
  visible: boolean;
  toggleMenuVisibility(): void;
}

export const MenuMobile = forwardRef<HTMLDivElement, IComponentProps>((
  {
    nav,
    onPreferencesOpen,
    selectedKeys,
    toggleMenuVisibility,
    visible,
  }, ref,
) => {
  const dispatch = useDispatch();

  const userName = useSelector(getFullName);
  const roleNames = useSelector(getRolesTitle);
  const logout = () => dispatch(logoutAction());

  const menuItems = _.map(nav, ({ name, path, testAttribute }) => (
    <Menu.Item
      data-test={testAttribute}
      key={path}
      onClick={() => toggleMenuVisibility()}
    >
      <Link to={path}>{name}</Link>
    </Menu.Item>
  ));

  return (
    <Wrapper isVisible={visible} ref={ref}>
      <MenuStyled
        mode='inline'
        selectedKeys={selectedKeys}
        visible={visible}
      >
        {menuItems}
        <Menu.Item onClick={logout}>Выход</Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={({ domEvent }) => {
          domEvent.stopPropagation();
          onPreferencesOpen();
        }}
        >
          {userName} <Roles>({roleNames})</Roles>
        </Menu.Item>
      </MenuStyled>
    </Wrapper>
  );
});
