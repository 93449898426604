import React from 'react';

import { mapResultsForSelect } from 'pages/PageClient/Single/VerificationResult/CheckList/Fields/MapResultsForSelect';
import { ICheckListFieldProps } from 'pages/PageClient/Single/VerificationResult/CheckList/Fields/types';
import { Select } from 'pages/PageClient/Single/VerificationResult/CheckList/styled';

interface MultipleChoiceProps extends ICheckListFieldProps {
  value: number[]
}

export const MultipleChoice: React.FC<MultipleChoiceProps> = ({ isDisabled, onChange, value, verificationResults }) => (
  <Select
    disabled={isDisabled}
    mode='multiple'
    // @ts-ignore
    onChange={onChange}
    options={mapResultsForSelect(verificationResults)}
    value={value}
  />
);
