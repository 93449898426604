import _ from 'lodash';

import { formatDate } from 'helper';

export const getResolutionsColumns = (resolutionGroupTypes: any, resolutionTypes: any) => [
  {
    dataIndex: `createDtm`,
    key      : `createDtm`,
    title    : `Дата`,
    render   : (v: any) => formatDate(v),
  },
  {
    dataIndex: `loanId`,
    key      : `loanId`,
    title    : `loanId`,
  },

  {
    dataIndex: `enforcementProceedingsNumber`,
    key      : `enforcementProceedingsNumber`,
    title    : `№ исполнительного производства`,
  },
  {
    dataIndex: `resolutionTypeId`,
    key      : `resolutionTypeId`,
    title    : `Тип постановления`,
    render   : (id: any) => _.get(_.find(resolutionTypes, { id }), `name`),
  },
  {
    dataIndex: `resolutionGroupId`,
    key      : `resolutionGroupId`,
    title    : `Группа постановления`,
    render   : (id: any) => _.get(_.find(resolutionGroupTypes, { id }), `name`),
  },
  {
    dataIndex: `updateOperatorName`,
    key      : `updateOperatorName`,
    title    : `Автор обновления`,
  },
  {
    dataIndex: `updateDtm`,
    key      : `updateDtm`,
    title    : `Дата обновления записи`,
    render   : (v: any) => formatDate(v),
  },
];
