import React from 'react';

import { Warning } from 'pages/PageClient/Single/Decision/components/Warning';
import { useVerificationPath } from 'pages/PageClient/Single/Decision/useVerificationPath';

interface WarningsProps {
  carDataWarningShow?: boolean;
  firstPathBlock?: string;
  isResultsComplete: boolean;
  verificationId?: number;
}

export const Warnings: React.FC<WarningsProps> = ({
  carDataWarningShow,
  firstPathBlock,
  isResultsComplete,
  verificationId,
}) => {
  const { onCarEditClick, onResultsClick } = useVerificationPath({ verificationId, firstPathBlock });
  return (
    <>
      {carDataWarningShow && (
        <Warning
          message='Необходимо заполнить данные об автомобиле'
          onButtonClick={onCarEditClick}
        />
      )}

      {!isResultsComplete && (
        <Warning
          message='Необходимо заполнить результаты проверок'
          onButtonClick={onResultsClick}
        />
      )}
    </>
  );
};
