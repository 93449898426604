import * as React from 'react';
import { useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'dva/router';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { Modal } from 'antd';
import { HotkeyListener } from 'pages/Layout/GlobalHotkeys/HotkeyListener';

const Wrapper = styled.div``;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`;

const Symbol = styled.kbd`
  padding: 6px 8px;
  border-radius: 3px;
  background: ${palette.black5Color};
  border: 1px solid ${palette.black20Color};
  box-shadow: 0 -1px 0 ${palette.black40Color} inset;
  text-transform: Capitalize;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  font-family: "PT Root UI", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;

  color: ${palette.black100Color};

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Description = styled.div`
  margin-left: 8px;
`;

interface IComponentProps {
}

interface IConnectedProps {
  history: History;
}

interface IHotkeyItem {
  description: string;
  keys: string[];
  callback(): void;
}

type IProps = IConnectedProps & IComponentProps;

const GlobalHotkeysComponent: React.FC<IProps> = ({ history }) => {
  const [visible, setVisible] = useState(false);

  const globalHotkeys: IHotkeyItem[] = [
    {
      keys       : [`shift+?`, `shift+/`, `shift+7`, `shift+,`],
      description: `Список всех горячих клавиш`,
      callback   : () => setVisible(true),
    },
    {
      keys       : [`shift+f`],
      description: `Открыть следующую страницу`,
      callback   : () => history.forward(),
    },
    {
      keys       : [`shift+b`],
      description: `Открыть предыдущую страницу`,
      callback   : () => history.back(),
    },
  ];

  return (
    <Wrapper>
      {_.map(globalHotkeys, (hotkeyItem, index) => (
        <React.Fragment key={index}>
          {/* eslint-disable-next-line @typescript-eslint/unbound-method */}
          {_.map(hotkeyItem.keys, key => <HotkeyListener callback={hotkeyItem.callback} hotkey={key} key={key} />)}
        </React.Fragment>
      ))}

      <Modal
        cancelText={null}
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => setVisible(false)}
        open={visible}
        title='Горячие клавиши'
      >
        <Title>Глобальные</Title>

        {_.map(globalHotkeys, hotkeyItem => {
          const { description, keys } = hotkeyItem;

          const firstKey = _.first(keys);
          const symbols = _.split(firstKey, `+`);

          return (
            <Item key={firstKey}>
              {_.map(symbols, (symbol, index) => {
                const isLast = index === symbols.length - 1;

                return (
                  <React.Fragment key={index}>
                    <Symbol>{symbol}</Symbol>
                    {!isLast && (
                      <>&nbsp;+&nbsp;</>
                    )}
                  </React.Fragment>
                );
              })}

              <Description>{description}</Description>
            </Item>
          );
        })}
      </Modal>
    </Wrapper>
  );
};

export const GlobalHotkeys = withRouter(GlobalHotkeysComponent);
