import _ from 'lodash';

import { getField } from 'helper';

import { Select } from 'antd';

export const NO_ROWS_SELECTED_MESSAGE = `Нужно выбрать хотя бы одну задачу`;

export const FORM_FIELDS = ({ hmSearchPartners, initialValues, isMultiplePartnerEdit, statusesToSelect }) => _.map([
  {
    Component   : Select,
    disabled    : _.isEmpty(statusesToSelect),
    key         : `taskStatusId`,
    title       : `Статус`,
    initialValue: ``,
    options     : _.concat(
      {
        label: initialValues?.taskStatus?.description || initialValues?.taskStatus?.name,
        value: initialValues?.taskStatus?.id,
      },
      _.map(statusesToSelect, ({ description, id, name }) => ({
        label: description || name,
        value: id,
      })),
    ),
  },
  ...(isMultiplePartnerEdit ? [{
    Component   : Select,
    disabled    : _.isEmpty(hmSearchPartners),
    key         : `searchPartnerId`,
    title       : `Партнёр`,
    initialValue: ``,
    options     : _.map(hmSearchPartners, ({ id, name }) => ({
      label: name,
      value: id,
    })),
  }] : []),
], getField);
