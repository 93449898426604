import * as React from 'react';
import styled from 'styled-components';

import useOnScreen from 'hooks/useOnScreen';

const Wrapper = styled.div<{ isAfterVisible: boolean; isBeforeVisible: boolean; }>`
  position: relative;
  padding-bottom: 8px;
  height: 80px;

  &:before, &:after {
    content: "";
    opacity: 0;
    transition: opacity 300ms ease;
    position: absolute;
    width: 30px;
    height: 100%;
    z-index: 1;
    top: 0;
    pointer-events: none;
  }

  &:before {
    left: 0;
    box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 8%);
  }

  &:after {
    right: 0;
    box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 8%);
  }


  ${({ isBeforeVisible }) => isBeforeVisible && `
    &:before {
      opacity: 1;
    }
  `}

  ${({ isAfterVisible }) => isAfterVisible && `
    &:after {
      opacity: 1;
    }
  `}
`;

const Content = styled.div`
  padding-top: 8px;
  display: flex;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display:none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

interface IComponentProps {
  children: React.ReactNode
  className?:string;
}

export const AttachmentsWrapper: React.FC<IComponentProps> = ({ children, className }) => {
  const startRef = React.useRef(null);
  const isStartOnScreen = useOnScreen(startRef, true);

  const endRef = React.useRef(null);
  const isEndOnScreen = useOnScreen(endRef, true);

  return (
    <Wrapper className={className} isAfterVisible={!isEndOnScreen} isBeforeVisible={!isStartOnScreen}>
      <Content>
        <div ref={startRef} />
        {children}
        <div ref={endRef} />
      </Content>
    </Wrapper>
  );
};
