import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

import { OVERDUE_ITEMS_PERIOD } from './constants';

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(getState, ({ items }) => items);

export const getItemsByEntity = createSelector(
  [getItems, (state, entity) => entity],
  (items, entity) => _.filter(items, { entity }),
);

export const getItemsByEntityId = createSelector(
  [getItemsByEntity, (state, entity, id) => id],
  (itemsEntity, entityId) => _.filter(itemsEntity, { entityId }),
);

export const getItemsByEntities = createSelector(
  [getItems, (state, entities) => entities],
  (items, entities) => _.orderBy(_.filter(items, item => (
    _.some(entities, entity => item.entity === entity.entity
            && (item.entityId === entity.entityId || item.personId === entity.personId))
  )), `createdAt`, `desc`),
);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);

export const getLoadedItems = createSelector(getState, ({ loadedItems }) => loadedItems);

export const isItemsOverdue = createSelector(
  [getLoadedItems, (s, entities) => entities],
  (loadedItems, entities) => _.some(entities, ({ entity, entityId }) => {
    const key = `${entity}_${entityId}`;
    return !loadedItems[key] || (loadedItems[key] < (+(new Date()) - OVERDUE_ITEMS_PERIOD));
  }),
);

export const getLastSearch = createSelector(
  getState, ({ lastSearch }) => lastSearch,
);
