import styled from 'styled-components';
import {
  breakpoints,
  height,
  palette,
  zIndex,
} from 'styles/theme';

export const SubHeader = styled.div<{ thinBottom?: boolean }>`
  padding: 16px 16px ${({ thinBottom }) => (thinBottom ? `0` : `16px`)};
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: ${palette.backgroundAccentColor};
  position: sticky;
  top: ${height.headerMobile};
  z-index: ${zIndex.subheader};

  @media(min-width: ${breakpoints.md}) {
    top: ${height.headerTablet};
    padding: 20px 40px ${({ thinBottom }) => (thinBottom ? `0` : `16px`)};
  }

  @media(min-width: ${breakpoints.lg}) {
    padding: 20px 60px ${({ thinBottom }) => (thinBottom ? `0` : `16px`)};
  }
`;

