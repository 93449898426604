import _ from 'lodash';

import { parseResponse } from 'api/helpers';
import {
  downloadBlob,
  formatDate,
  showError,
} from 'helper';

import { DEFAULT_TABLE_STATE } from 'models/lead/constants';
import {
  getFilters as getFiltersSelector,
  getItems as getItemsSelector,
  getTableState as getTableStateSelector,
} from 'models/lead/selectors';
import { getCurrent as getCurrentUserSelector } from 'models/user/selectors';

import { notification } from 'antd';

import { intercom } from '@cashdrive/api/socket';

import {
  ASSIGN,
  CREATE,
  EXPORT_EXCEL,
  GET,
  GET_FILTERS,
  GET_SINGLE,
  model as namespace,
  REASSIGN,
  REMOVE,
  RESET,
  SET,
  SET_LOADING,
  SET_OPERATOR,
  SET_OPERATOR_MULTIPLE,
  SET_STATUS,
  SET_STATUS_MULTIPLE,
  SET_TABLE_STATE,
  UPDATE,
  UPLOAD_FILE,
} from './actions';
import * as api from './api';

const initialState = {
  isLoaded    : false,
  isLoading   : false,
  items       : [],
  searchString: ``,
  total       : 0,
  userId      : null,
  taskStatusId: null,
  operatorId  : null,
  tableState  : { ...DEFAULT_TABLE_STATE },
  filters     : {},
};

export default {
  namespace,
  state        : initialState,
  subscriptions: {
    setup({ dispatch, history }) {
      let unsubscribe;

      history.listen(({ pathname }) => {
        if (unsubscribe) {
          unsubscribe();
          unsubscribe = null;
        }

        if (pathname === `/leads`) {
          unsubscribe = intercom.subscribe(`LEADS_UPDATED`, ({ payload: [type] }) => {
            if (type === `leadsUpdated`) dispatch({ type: GET });
          });
        }
      });
    },
  },
  effects: {
    *[ASSIGN](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids } = action.payload;
      const defaultError = `При назначении задач(и) произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(`${defaultError}: не указаны идентификаторы задач(и) для назначения`);
        const operatorId = yield select(getCurrentUserSelector);

        parseResponse({
          defaultError,
          response: yield call(api.setOperatorMultiple, ids, operatorId),
        });

        // yield put({ type: GET });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        notification.success({ message: `Успешно назначено` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При создании задачи произошла ошибка`;

      try {
        yield call(api.create, _.omit(action.payload, [`callback`]));
        // yield put({ type: GET });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        notification.success({ message: `Задача создана` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[EXPORT_EXCEL](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При экспорте задач произошла ошибка`;

      try {
        const { ids } = action.payload;
        const tableState = yield select(getTableStateSelector);

        notification.info({ message: `По завершении экспорта будет скачан Excel файл. Ожидайте` });
        const blob = parseResponse({
          defaultError,
          response: yield call(api.exportExcel, ids, tableState),
        });
        downloadBlob(blob, `Реестр лидов ${formatDate(new Date(), `dd.MM.yyyy`)}.xlsx`);
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке задач произошла ошибка`;
      try {
        const { callback } = action.payload || {};
        let tableState = yield select(getTableStateSelector);
        const sorter = _.pick(tableState.sorter, [`columnKey`, `order`]);
        const response = yield call(api.search, { ...tableState, sorter });
        const { data: items, total } = parseResponse({ defaultError, response });
        yield put({
          type   : SET,
          payload: {
            items,
            isLoaded: true,
          },
        });
        tableState = yield select(getTableStateSelector);
        const updatedTableState = {
          ...tableState,
          pagination: {
            ...tableState.pagination,
            current: tableState.pagination?.current === 0 ? 1 : tableState.pagination.current,
            total,
          },
        };
        yield put({
          type   : SET_TABLE_STATE,
          payload: updatedTableState,
        });
        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_SINGLE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id } = action.payload;
      const defaultError = `При загрузке задачи с id '${id}' произошла ошибка`;
      try {
        if (!id) throw new Error(defaultError);

        const item = parseResponse({
          defaultError,
          response: yield call(api.getSingle, id),
        });
        const currentItems = yield select(getItemsSelector);

        if (_.isEmpty(currentItems)) {
          yield put({ type: SET, payload: { items: [item] } });
        } else {
          yield put({
            type   : SET,
            payload: {
              items: _.map(currentItems, currentItem => (currentItem.id === id
                ? { ...currentItem, ...item }
                : currentItem
              )),
            },
          });
        }
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback(item);
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
    *[GET_FILTERS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке фильтров задач произошла ошибка`;
      try {
        const filters = parseResponse({
          defaultError,
          response: yield call(api.getFilters),
        });
        yield put({ type: SET, payload: { filters } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REASSIGN](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });

      const { ids } = action.payload;
      const defaultError = `При снятии задач(и) с оператора произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(`${defaultError}: не указаны идентификаторы задач(и) для снятия`);
        parseResponse({
          defaultError,
          response: yield call(api.setOperatorMultiple, ids, null),
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        // yield put({ type: GET });
        notification.success({ message: `Успешно снято с сотрудника` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_OPERATOR](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const {
        id, omitNotification, operatorId,
      } = action.payload;
      const defaultError = `При назначении задачи с id '${id}' оператору произошла ошибка`;

      try {
        if (!id) throw new Error(`${defaultError}: не указан идентификатор задачи`);

        parseResponse({
          defaultError,
          response: yield call(api.setOperator, id, operatorId),
        });

        // const currentItems = yield select(getItemsSelector);
        //
        // yield put({
        //   type   : SET,
        //   payload: {
        //     items: _.map(currentItems, currentItem => (currentItem.id === id
        //       ? { ...currentItem, operatorId: data.operatorId }
        //       : currentItem
        //     )),
        //   },
        // });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Оператор изменен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_OPERATOR_MULTIPLE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids, omitNotification, operatorId } = action.payload;
      const defaultError = `При назначении задач оператору произошла ошибка`;

      try {
        if (!ids) throw new Error(`${defaultError}: не указаны идентификаторы задач`);

        parseResponse({
          defaultError,
          response: yield call(api.setOperatorMultiple, ids, operatorId),
        });

        // yield put({ type: GET });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Оператор изменен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_STATUS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id, leadStatusId, omitNotification } = action.payload;
      const defaultError = `При изменении статуса задачи с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(`${defaultError}: не указан идентификатор задачи`);
        if (_.isNil(leadStatusId)) throw new Error(`${defaultError}: не указан идентификатор статуса задачи`);

        parseResponse({
          defaultError,
          response: yield call(api.setStatus, id, leadStatusId),
        });

        // yield put({
        //   type   : GET_SINGLE,
        //   payload: { id },
        // });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Статус задания изменен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_STATUS_MULTIPLE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids, leadStatusId, omitNotification } = action.payload;
      const defaultError = `При изменении статуса задач с id '${_.join(ids)}' произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(`${defaultError}: не указаны идентификаторы задач`);
        if (_.isNil(leadStatusId)) throw new Error(`${defaultError}: не указан идентификатор статуса задачи`);

        parseResponse({
          defaultError,
          response: yield call(api.setStatusMultiple, ids, leadStatusId),
        });

        // yield put({ type: GET });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Статус заданий изменён` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_TABLE_STATE](action, { put, select }) {
      const { callback, ...tableState } = action.payload;
      const currentTableState = yield select(getTableStateSelector);
      const filters = yield select(getFiltersSelector);
      const isFiltersChanged = !_.isEqual(
        _.omitBy(tableState?.filters, _.isNil),
        _.omitBy(filters, _.isNil),
      );
      yield put({
        type   : SET,
        payload: {
          tableState: {
            ...currentTableState,
            ...tableState,
            pagination: isFiltersChanged
              ? {
                ...currentTableState.pagination,
                ...tableState.pagination,
                current       : 1,
                defaultCurrent: 1,
                page          : 1,
              }
              : {
                ...currentTableState.pagination,
                ...tableState.pagination,
              },
          },
        } });
      if (_.isFunction(callback)) callback();
    },

    *[REMOVE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids } = action.payload;
      const defaultError = `При удалении лидов произошла ошибка`;

      try {
        if (_.isEmpty(ids)) {
          throw new Error(`${defaultError}: не указаны идентификаторы лидов для удаления`);
        }

        parseResponse({
          defaultError,
          response: yield call(api.remove, ids),
        });

        // yield put({ type: GET });

        notification.success({ message: `Успешно удалено` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id, item } = action.payload;
      const defaultError = `При обновлении лидов произошла ошибка`;

      try {
        if (!id) throw new Error(`Не указан идентификатор лида`);
        if (_.isEmpty(item)) throw new Error(`Нет данных для обновления`);

        parseResponse({
          defaultError,
          response: yield call(api.update, id, item),
        });

        // yield put({
        //   type   : GET_SINGLE,
        //   payload: { id },
        // });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback(item);
        notification.success({ message: `Успешно сохранено` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPLOAD_FILE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { file } = action.payload;
      const defaultError = `При загрузке файла произошла ошибка`;

      try {
        const addedCount = parseResponse({
          defaultError,
          response: yield call(api.uploadFile, file),
        });

        // yield put({ type: GET });
        notification.success({ message: `Обработано задач: ${addedCount}` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        ...payload,
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
};
