import { Page } from 'react-pdf';
import styled from 'styled-components';

export const PageStyled = styled(Page)`
  display: flex;
  justify-content: center;

  &+& {
    margin-top: 16px;
  }

  canvas {
    max-width:100%;
  }
`;
