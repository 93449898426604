import _ from 'lodash';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { notification } from 'antd';

import {
  MAKE_CALL,
  model as namespace,
  SET_LOADING,
} from './actions';
import * as api from './api';

const initialState = {
  isLoading: false,
};

export default {
  namespace,
  state  : initialState,
  effects: {
    *[MAKE_CALL](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При инициации вызова произошла ошибка`;
      try {
        const { mttWorkplace, phone } = action.payload;

        if (!phone || !mttWorkplace) throw new Error(defaultError);

        const data = parseResponse({
          defaultError,
          response: yield call(api.makeCall, { params: { calleeId: phone, callerId: mttWorkplace } }),
        });

        if (data?.success) {
          notification.success({ message: `Вызов инициирован, необходимо подтвердить в приложении МТТ` });
        }

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
};
