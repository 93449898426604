import * as React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { SendOutlined } from '@ant-design/icons';

const Wrapper = styled.button<{ disabled: boolean }>`
  background: ${palette.primary500Color};
  padding: 8px;
  border: none;
  cursor: pointer;
  height: 100%;
  transition: background 300ms ease;
  display: flex;
  align-items: center;

  .anticon {
    color: ${palette.whiteColor};
    font-size: 14px;
  }

  :disabled {
    background: ${palette.black60Color};
    cursor: not-allowed;
  }

  @media(min-width: ${breakpoints.md}) {
    padding: 16px 8px;
    border-radius: 0;
  }
`;

interface IComponentProps {
  disabled: boolean;
  onClick: () => void;
}

export const InputSendButton: React.FC<IComponentProps> = ({ disabled, onClick }) => (
  <Wrapper disabled={disabled} onClick={onClick}>
    <SendOutlined />
  </Wrapper>
);
