import React from 'react';
import _ from 'lodash';
import { Link } from 'dva/router';

import {
  formatDate,
  getColumn,
  getFullNameInitials,
  prettifyNumber,
} from 'helper';

import {
  LEAD_ADMIN,
  SUPER_ADMIN,
} from 'models/roles/constants';

import { SearchOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Tag,
} from 'antd';
import DateFilterDropDown from 'components/DateFilterDropDown';
import { CellId } from 'components/DefaultTable/CellId/CellId';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import StringFilterDropDown from 'components/StringFilterDropDown';
import {
  ExternalLink,
  MttPhoneButtonStyled,
} from 'pages/PageClient/Single/Info/styled';

export const ADMIN_ROLES = [SUPER_ADMIN, LEAD_ADMIN];

const getSortOrder = (sorter, column) => (sorter?.columnKey === column ? sorter.order : undefined);

export const getColumns = ({
  dictionaries,
  filterOptions,
  operators,
  operatorsToSelect,
  tableState,
}) => _.map([
  {
    title         : `Статус`,
    align         : `left`,
    filtered      : !_.isEmpty(tableState?.filters?.leadStatusId),
    filteredValue : tableState?.filters?.leadStatusId,
    filterMultiple: true,
    filters       : _.map(dictionaries?.leadStatus, leadStatus => ({
      text: (
        <DefaultTag
          color={leadStatus?.color}
        >
          {`${leadStatus?.description || leadStatus?.name || ``}`}
        </DefaultTag>),
      value: leadStatus?.id,
    })),
    key   : `leadStatusId`,
    render: leadStatusId => {
      const leadStatus = _.find(dictionaries?.leadStatus, { id: leadStatusId });
      return (
        <Tag color={leadStatus?.color}>
          {`${leadStatus?.description || leadStatus?.name || ``}`}
        </Tag>
      );
    },
    sorter   : true,
    sortOrder: getSortOrder(tableState.sorter, `leadStatusId`),
    width    : 270,
  },
  {
    title         : `Имя`,
    align         : `left`,
    filterDropdown: ({ confirm, selectedKeys, setSelectedKeys, visible }) => (
      <StringFilterDropDown
        confirm={confirm}
        isVisible={visible}
        placeholder='Поиск по имени'
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
      />
    ),
    filtered     : !_.isEmpty(tableState?.filters?.name),
    filteredValue: tableState?.filters?.name,
    filterIcon   : filtered => (
      <SearchOutlined style={{ color: filtered ? `#1890ff` : undefined }} />
    ),
    key  : `name`,
    width: 230,
  },
  {
    title : `Person ID`,
    align : `center`,
    key   : `personId`,
    width : 90,
    render: personId => (personId ? (
      <Link to={`/client/person/${personId}/info`}>
        <CellId value={personId} />
      </Link>
    ) : `-`),
    filterDropdown: ({ confirm, selectedKeys, setSelectedKeys, visible }) => (
      <StringFilterDropDown
        confirm={confirm}
        isVisible={visible}
        placeholder='Поиск по personId заёмщика'
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
      />
    ),
    filtered     : !_.isEmpty(tableState?.filters?.personId),
    filteredValue: tableState?.filters?.personId,
    filterIcon   : filtered => (
      <SearchOutlined style={{ color: filtered ? `#1890ff` : undefined }} />
    ),
  },
  {
    title         : `Телефон`,
    align         : `left`,
    filterDropdown: ({ confirm, selectedKeys, setSelectedKeys, visible }) => (
      <StringFilterDropDown
        confirm={confirm}
        isVisible={visible}
        placeholder='Поиск по телефону'
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
      />
    ),
    filtered     : !_.isEmpty(tableState?.filters?.phone),
    filteredValue: tableState?.filters?.phone,
    filterIcon   : filtered => (
      <SearchOutlined style={{ color: filtered ? `#1890ff` : undefined }} />
    ),
    key   : `phone`,
    width : 150,
    render: phoneRaw => {
      const phone = `+${_.replace(phoneRaw, /[^0-9]/gi, ``)}`;
      return (
        <>
          <ExternalLink href={`tel:${phone}`}>{phone}</ExternalLink>
          {/* @ts-ignore */}
          <MttPhoneButtonStyled phone={phone} />
        </>
      );
    },
  },
  {
    title    : `Сумма`,
    align    : `left`,
    key      : `sum`,
    width    : 120,
    render   : prettifyNumber,
    sorter   : true,
    sortOrder: getSortOrder(tableState.sorter, `sum`),
  },
  {
    title: `Комментарий`,
    align: `left`,
    key  : `comment`,
    width: 250,
  },
  {
    title     : `Нужен звонок`,
    align     : `center`,
    key       : `needCall`,
    width     : 150,
    render    : v => <Checkbox checked={v} />,
    filtered  : !_.isEmpty(tableState?.filters?.needCall),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? `#1890ff` : undefined }} />
    ),
    filteredValue: tableState?.filters?.needCall,
    filters      : [
      { text: `Да`, value: true },
      { text: `Нет`, value: false },
    ],
    sorter   : true,
    sortOrder: getSortOrder(tableState.sorter, `needCall`),
  },
  {
    title         : `Регион`,
    align         : `right`,
    key           : `region`,
    width         : 170,
    filtered      : !_.isEmpty(tableState?.filters?.region),
    filteredValue : tableState?.filters?.region,
    filterMultiple: true,
    filters       : _.map(filterOptions.regions, value => ({
      text: value,
      value,
    })),
    sorter   : true,
    sortOrder: getSortOrder(tableState.sorter, `region`),
  },
  {
    title : `Часовой пояс`,
    align : `left`,
    key   : `timeZone`,
    width : 185,
    render: v => {
      if (v !== 0 && (!v || isNaN(Date.parse(v)))) {
        return `-`;
      }
      const date = new Date();
      const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
      const newDate = new Date(utc + (3600000 * v));
      return `${v > 0 ? `+` : ``}${v} (${newDate.toLocaleString()})`;
    },
    filtered      : !_.isEmpty(tableState?.filters?.timeZone),
    filteredValue : tableState?.filters?.timeZone,
    filterMultiple: true,
    filters       : _.orderBy(_.map(filterOptions.timeZones, value => ({
      text: `${value > 0 ? `+` : ``}${value}`,
      value,
    })), `value`, `asc`),
    sorter   : true,
    sortOrder: getSortOrder(tableState.sorter, `timeZone`),
  },
  {
    title         : `Лидген`,
    key           : `leadGenName`,
    align         : `right`,
    filtered      : !_.isEmpty(tableState?.filters?.leadGenName),
    filteredValue : tableState?.filters?.leadGenName,
    filterMultiple: true,
    filters       : _.map(filterOptions.leadGenNames, value => ({
      text: value,
      value,
    })),
    sorter   : true,
    sortOrder: getSortOrder(tableState.sorter, `leadGenName`),
    width    : 200,
    render   : leadGenName => (
      <ExternalLink
        href={`${import.meta.env.REACT_APP_APPLICATION_URL}?utm_source=api&utm_medium=cpa&utm_campaign=${leadGenName}`}
        rel='noreferrer'
        target='_blank'
      >
        {leadGenName}
      </ExternalLink>
    ),
  },
  {
    title         : `Сотрудник`,
    filtered      : !_.isEmpty(tableState?.filters?.operatorId),
    filteredValue : tableState?.filters?.operatorId,
    filterMultiple: true,
    filters       : _.map(operatorsToSelect, operator => ({
      text : getFullNameInitials(_.get(operator, `user`, {})),
      value: operator.id,
    })),
    key   : `operatorId`,
    render: operatorId => getFullNameInitials(
      _.get(
        _.find(operators, { id: operatorId }),
        `user`,
        {},
      ),
    ),
    width: 200,
  },
  {
    title         : `Создана`,
    align         : `center`,
    filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
      <DateFilterDropDown
        confirm={confirm}
        placeholder={[`с`, `по`]}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
      />
    ),
    filtered     : !_.isEmpty(tableState?.filters?.createdAt),
    filteredValue: tableState?.filters?.createdAt,
    key          : `createdAt`,
    render       : v => (v ? formatDate(new Date(v)) : ``),
    sorter       : true,
    sortOrder    : getSortOrder(tableState.sorter, `createdAt`),
    width        : 135,
  },
  {
    title         : `Обновлена`,
    align         : `center`,
    filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
      <DateFilterDropDown
        confirm={confirm}
        placeholder={[`с`, `по`]}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
      />
    ),
    filtered     : !_.isEmpty(tableState?.filters?.updatedAt),
    filteredValue: tableState?.filters?.updatedAt,
    key          : `updatedAt`,
    render       : v => (v ? formatDate(new Date(v)) : ``),
    sorter       : true,
    sortOrder    : getSortOrder(tableState.sorter, `updatedAt`),
    width        : 135,
  },
  {
    title : `Lead ID`,
    align : `center`,
    key   : `leadId`,
    width : 90,
    render: leadId => <CellId value={leadId} />,
  },
  {
    title    : `#`,
    align    : `right`,
    key      : `id`,
    sorter   : true,
    sortOrder: getSortOrder(tableState.sorter, `id`),
    width    : 70,
  },
], getColumn);
