import * as React from 'react';
import styled from 'styled-components';

import useMobileContext from 'hooks/useMobileContext';

import { ImagePreviewDesktop } from 'components/ImagePreview/ImagePreviewDesktop';
import { ImagePreviewMobile } from 'components/ImagePreview/ImagePreviewMobile';
import { AttachmentFileThumbnail } from 'components/Messenger/Attachment/AttachmentFileThumbnail';

const Wrapper = styled.div``;

interface IComponentProps {
  image: {
    name: string;
    src?: string;
    type: string;
  };
  imageIndex: number;
  isVisible: boolean;
  quantity: number;
  onCancel(): void;
  onClickNext(): void;
  onClickPrev(): void;
}

export const AttachmentPreviewModal: React.FC<IComponentProps> = ({
  image,
  imageIndex,
  isVisible,
  onCancel,
  onClickNext,
  onClickPrev,
  quantity,
}) => {
  const { mobile } = useMobileContext();

  return (
    <Wrapper>
      {mobile ? (
        <ImagePreviewMobile
          // @ts-ignore
          fallback={<AttachmentFileThumbnail file={image} />}
          image={image}
          imageIndex={imageIndex}
          isVisible={isVisible}
          onCancel={onCancel}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
          quantity={quantity}
        />
      ) : (
        <ImagePreviewDesktop
          // @ts-ignore
          fallback={<AttachmentFileThumbnail file={image} />}
          image={image}
          isVisible={isVisible}
          onCancel={onCancel}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
        />
      )}
    </Wrapper>
  );
};
