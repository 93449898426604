import _ from 'lodash';

const now = +(new Date());

export const getItemColor = ({ key, value }) => {
  const sanitizedValue = _.toLower(_.trim(value));
  let color = false;
  switch (key) {
    case `oki_curr_sum_overd`:
    case `oki_active_monthly_payment`:
      color = `red`;
      break;
    case `decision_group_with_digital`:
    case `decision_group`:
    case `il_group`:
      if (_.includes(value, `BAD`)) color = `red`;
      break;
    case `fms_good_flag`:
    case `sovpalo_docdate`:
    case `sovpalo_dr`:
      if (value === 0 || value === `0`) color = `red`;
      break;
    case `bankrupt_flag`:
    case `has_another_address`:
    case `has_another_client`:
    case `has_another_phone`:
    case `has_another_vin`:
    case `lombard`:
    case `ref_traf`:
      if (value === 1 || value === `1`) color = `red`;
      break;
    case `fsspall`:
    case `fsspcredsum`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v < 10000) color = `yellow`;
        if (v >= 10000) color = `red`;
      }
      break;
    case `curr_sum_overd`:
    case `eq_curr_sum_overd`:
    case `fssp_count_active_debt`:
    case `fssp_count_debts`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v > 0) color = `red`;
      }
      break;
    case `fssp_count_closed_debt`:
    case `knt_active_loans`:
    case `knt_loans`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v > 0) color = `yellow`;
      }
      break;
    case `passportmatch`:
      if (value === `false` || (!_.isNil(value) && !value)) color = `yellow`;
      break;
    case `phonematch`:
      if (value === `false` || (!_.isNil(value) && !value)) color = `red`;
      break;
    case `lifetime_bin`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v < 2) color = `yellow`;
      }
      break;
    case `megaf_score`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v >= 0.7) color = `yellow`;
      }
      break;
    case `bio_bad_rules`:
      if (value === 1 || value === `1`) color = `red`;
      break;
    case `bio_possitive`:
      if (value === 1 || value === `1`) color = `green`;
      break;
    case `active_monthly_payment`:
    case `active_and_overdue_monthly_payment`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v > 50000) color = `yellow`;
      }
      break;
    case `active_ostatok_dolga`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v > 1000000) color = `yellow`;
      }
      break;
    case `dolya_payments_without_overdue`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v < 0.5) color = `red`;
        if (v >= 0.5 && v <= 0.8) color = `yellow`;
      }
      break;
    case `priceavg`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v < 100000) color = `yellow`;
      }
      break;
    case `car_age`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v > 15) color = `yellow`;
      }
      break;
    case `accidents_num`:
    case `ownership_num`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v > 5) color = `red`;
      }
      break;
    case `fnp_flag`:
    case `multLoansFlagOkb`:
      if (value === 1 || value === `1`) color = `red`;
      break;
    case `knt_closed_loans`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v > 0) color = `green`;
      }
      break;
    case `avg_srok_credita`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v < 60) color = `yellow`;
      }
      break;
    case `max_day_overdue`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v >= 0 && v <= 30) color = `yellow`;
        if (v > 30) color = `red`;
      }
      break;
    case `last_approve_date`:
      if (!_.isNil(value)) {
        const v = +(new Date(value));
        // меньше 30 дней
        if ((now - v) < 30 * 24 * 60 * 60 * 1000) color = `yellow`;
      }
      break;
    case `count_app`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v > 2) color = `yellow`;
      }
      break;
    case `inner_count_active_credit`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v >= 3 && v <= 5) color = `yellow`;
        if (v >= 6) color = `red`;
      }
      break;
    case `sum_issue_active_credit`:
    case `sum_total_debt`:
    case `sum_total_main_debt`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v >= 500000 && v < 800000) color = `yellow`;
        if (v >= 800000) color = `red`;
      }
      break;
    case `last_credit_mnth`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v <= 4) color = `yellow`;
        if (v <= 2) color = `red`;
      }
      break;
    case `active_max_days_overdue`:
    case `max_overdue_ever`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v >= 30 && v <= 60) color = `yellow`;
        if (v > 60) color = `red`;
      }
      break;
    case `active_max_totaloverduedebt`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v >= 50000 && v <= 100000) color = `yellow`;
        if (v > 100000) color = `red`;
      }
      break;
    case `inc_dolya_payments_without_overdue`:
      if (!_.isNil(value)) {
        const v = _.toNumber(value);
        if (v > 50 && v <= 70) color = `yellow`;
        if (v < 50) color = `red`;
      }
      break;
    case `passportSelfyIsMoving`:
    case `carRightFrontIsMoving`:
    case `carVINIsMoving`:
      if (value === `false` || (!_.isNil(value) && !value)) color = `red`;
      break;
    case `probDefaultOkb`:
      if (sanitizedValue === `очень высокий`) color = `red`;
      if (sanitizedValue === `высокий`) color = `orange`;
      if (sanitizedValue === `средний`) color = `yellow`;
      break;
    default:
      color = false;
      break;
  }
  return color;
};
