import * as React from 'react';
import {
  useCallback,
  useContext,
  useState,
} from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';
import { TableState } from 'types/TableState';

import { downloadSms as downloadSmsAction } from 'models/clients/actions';
import {
  SMS_DOWNLOADER,
  SUPER_ADMIN,
} from 'models/roles/constants';

import { useHasRoles } from 'hooks/useHasRoles';
import { useSelectedRowKeys } from 'hooks/useSelectedRowKeys';

import { FileExcelOutlined } from '@ant-design/icons';
import {
  Button,
  TablePaginationConfig,
} from 'antd';
import {
  FilterValue,
  SorterResult,
} from 'antd/es/table/interface';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';
import { COLUMNS } from 'pages/PageClient/Single/Sms/constants';
import { ISms } from 'pages/PageClient/Single/Sms/SmsCard';

interface IComponentProps {
  sms: ISms[];
}

export const SmsTable: React.FC<IComponentProps> = ({ sms }) => {
  const dispatch = useDispatch();

  const { personId } = useContext(ClientMatchContext);

  const { onSelectChange, selectedRowKeys } = useSelectedRowKeys();
  const [tableState, setTableState] = useState<TableState>({
    filters   : {},
    pagination: {
      hideOnSinglePage: true,
      pageSize        : _.size(sms),
    },
    sorter: {},
  });

  const onTableChange = useCallback((
    pagination:TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter:SorterResult<any>,
  ) => {
    // @ts-ignore
    setTableState({ filters, pagination, sorter });
  }, [tableState]);

  const hasRoles = useHasRoles();
  const canExportSms = hasRoles([SMS_DOWNLOADER, SUPER_ADMIN]);

  const downloadSms = useCallback(() => {
    dispatch(downloadSmsAction({ personId, smsExternalIds: selectedRowKeys }));
  }, [personId, dispatch, selectedRowKeys]);

  return (
    <AdjustableTable
      columns={COLUMNS({ tableState })}
      dataSource={sms}
      // @ts-ignore
      onChange={onTableChange}
      pagination={{
        hideOnSinglePage: true,
        pageSize        : _.size(sms),
      }}
      preferences={{
        path      : `clientInfo.sms`,
        cellRender: (_pass, item) => {
          if (canExportSms && item?.smsExternalId === selectedRowKeys?.[0]) {
            return (
              <DefaultTooltip placement='left' title='Выгрузить в Excel'>
                <Button
                  icon={<FileExcelOutlined />}
                  onClick={downloadSms}
                  type='primary'
                />
              </DefaultTooltip>
            );
          }
        },
      }}
      rowKey='smsExternalId'
      rowSelection={{
        // @ts-ignore
        onChange: onSelectChange,
        selectedRowKeys,
      }}
      scroll={{ x: `max-content`, y: window.innerHeight - 250 }}
    />
  );
};
