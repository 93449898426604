import React, {
  useCallback,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { get as getDictionaryAction } from 'models/dictionaries/actions';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';
import { search as searchEventlogAction } from 'models/eventlogs/actions';
import { getItems as getEventlogSelector } from 'models/eventlogs/selectors';
import { get as getOperatorsAction } from 'models/operators/actions';
import { getItems as getOperatorsSelector } from 'models/operators/selectors';

import {
  Drawer,
  Empty,
} from 'antd';

import { UploadHistoryItem } from './Item';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    max-width: 100%;
  }
`;

const StyledEmpty = styled(Empty)`
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const Items = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const MemoizedUploadHistoryItem = React.memo(UploadHistoryItem);

const UploadHistory = ({
  applicationId = null,
  close = _.noop,
  documentTypeId = null,
  entity,
  operatorId = null,
  personId,
  isOpen,
}) => {
  const dispatch = useDispatch();

  const { documentTypes, items: _items, operators } = useSelector(state => ({
    documentTypes: _.sortBy(getDictionarySelector(state, `documentType`), `name`),
    items        : getEventlogSelector(state, entity),
    operators    : getOperatorsSelector(state),
  }));

  const getDocumentTypes = useCallback(
    () => dispatch(getDictionaryAction({ entity: `documentType`, force: true })),
    [dispatch],
  );
  const getEventlog = useCallback(() => dispatch(searchEventlogAction(_.omitBy({
    applicationId,
    documentTypeId,
    entity: `document`,
    operatorId,
    personId,
    type  : `documentUpload`,
  }, _.isNil))), [dispatch, applicationId, documentTypeId, operatorId, personId]);
  const getOperators = useCallback(() => dispatch(getOperatorsAction()), [dispatch]);

  useEffect(() => {
    if (_.isEmpty(documentTypes)) getDocumentTypes();
    if (_.isEmpty(operators)) getOperators();
  }, []);

  const afterVisibleChange = _isOpen => {
    if (_isOpen) getEventlog();
  };

  useEffect(() => {
      getEventlog()
    },
    [applicationId, documentTypeId, personId],
  );

  const documentType = _.find(documentTypes, { id: documentTypeId });
  let title = `История загрузки документов`;
  if (documentType) {
    title += ` "${documentType.name}"`;
  } else if (personId && !applicationId) {
    title += ` клиента ${personId}`;
  }
  if (applicationId) {
    title += ` по заявке ${applicationId}`;
  }

  const items = _.filter(_items, _.omitBy({ documentTypeId, applicationId, personId }, _.isNil));
  const showApplicationId = !applicationId;
  return (
    <StyledDrawer
      afterOpenChange={afterVisibleChange}
      destroyOnClose
      onClose={close}
      open={isOpen}
      placement='right'
      title={title}
      width={640}
    >
      {_.isEmpty(items)
        ? (
          <StyledEmpty
            description='Нет данных'
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
        : (
          <Items>
            {_.map(items, item => (
              <MemoizedUploadHistoryItem
                documentTypes={documentTypes}
                key={item.id}
                operators={operators}
                showApplicationId={showApplicationId}
                {...item}
              />
            ))}
          </Items>
        )}
    </StyledDrawer>
  );
};

export default UploadHistory;
