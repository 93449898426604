export interface IntercomResponse {
  key?: string;
  reply?: any;
}

interface IntercomEvents {
  SOCKET_CONNECT: boolean;
  SOCKET_CONNECT_ERROR: Error;
  SOCKET_MESSAGE: [string, any];
  SOCKET_VERSION: [string, string];
  SOCKET_MTT_CALL: [string, { fullName: string; personId: number; phone: string }];
  LEADS_UPDATED: [string];
  SOCKET_EMIT: { payload: [string, any]; key?: string };
}

class Intercom<T extends Record<string, any>> {
  private listeners: { [K in keyof T]?: Array<(data: T[K]) => void> } = {};
  private requests: Map<string, (response: IntercomResponse) => void> = new Map();
  private channel: BroadcastChannel;

  constructor(channelName = 'default_channel') {
    this.channel = new BroadcastChannel(channelName);

    this.channel.onmessage = (event) => {
      const { eventName, data } = event.data as { eventName: keyof T; data: T[keyof T] };
      this.emit(eventName, data, { fromExternal: true });
    };
  }

  subscribe<K extends keyof T>(eventName: K, callback: (data: T[K]) => void): void {
    // @ts-ignore
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }
    this.listeners[eventName]!.push(callback);
  }

  unsubscribe<K extends keyof T>(eventName: K, callback: (data: T[K]) => void): void {
    if (!this.listeners[eventName]) return;

    this.listeners[eventName] = this.listeners[eventName]!.filter(listener => listener !== callback);
  }

  emit<K extends keyof T>(
    eventName: K,
    data: T[K],
    { broadcast = true, fromExternal = false }: { broadcast?: boolean; fromExternal?: boolean } = {}
  ): void {
    if (this.listeners[eventName]) {
      this.listeners[eventName]!.forEach(callback => callback(data));
    }

    if (broadcast && !fromExternal) {
      this.channel.postMessage({ eventName, data });
    }
  }

  postMessage<K extends keyof T>(eventName: K, data: T[K]): void {
    this.emit(eventName, data);
  }

  postReply<K extends keyof T>(
    eventName: K,
    replyData: T[K],
  ): void {
    this.emit(eventName, replyData);
  }


  postRequest<K extends keyof T>(
    eventName: K,
    payload: T[K],
    timeout = 5000
  ): Promise<IntercomResponse> {
    return new Promise((resolve, reject) => {
      const requestKey = `${String(eventName)}_${Date.now()}`;
      this.requests.set(requestKey, (response: IntercomResponse) => {
        if (response.key === requestKey) {
          resolve(response);
        } else {
          reject(new Error('Unexpected response key'));
        }
      });

      const timer = setTimeout(() => {
        this.requests.delete(requestKey);
        reject(new Error('Request timed out'));
      }, timeout);

      this.channel.postMessage({ eventName, payload, key: requestKey });

      // Cleanup timer if response is received in time
      this.requests.get(requestKey) && clearTimeout(timer);
    });
  }

  close(): void {
    this.channel.close();
  }
}

// Экспортируем типизированный Intercom с интерфейсом IntercomEvents
export const intercom = new Intercom<IntercomEvents>('my_app_channel');
