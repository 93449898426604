import React, { useContext } from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { getClientMarketingOffers as getClientMarketingOffersAction } from 'models/clients/actions';
import { getIsLoading as isLoadingSelector } from 'models/clients/selectors';

import { Layout } from 'antd';
import MarketingOfferTable from 'components/MarketingOfferTable';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';

const LayoutStyled = styled(Layout)`
  @media screen and (max-width: 767px) {
      padding-bottom: 16px;
  }
`;

const PageClientSingleMarketingOffer = () => {
  const dispatch = useDispatch();

  const { personId } = useContext(ClientMatchContext);

  const isLoading = useSelector(isLoadingSelector);

  const getItems = () => dispatch(getClientMarketingOffersAction({ personId }));
  return (
    <ClientTab isReloading={isLoading} onReload={getItems}>
      <LayoutStyled>
        <MarketingOfferTable
          hiddenColumns={[`typeName`]}
          hideControls
          personId={personId}
        />
      </LayoutStyled>
    </ClientTab>
  );
};

export default PageClientSingleMarketingOffer;
