import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';

import {
  getClientApplication as getClientApplicationSelector,
  getClientCarByApplicationId,
} from 'models/clients/selectors';
import { IApplication } from 'models/clients/types';
import {
  SUPER_ADMIN,
  VERIFICATION_SEND,
} from 'models/roles/constants';
import { useVerification } from 'models/verifications/hooks/useVerification';
import { isResultsComplete as isResultsCompleteSelector } from 'models/verifications/selectors';

import { useHasRoles } from 'hooks/useHasRoles';

import { CAR_FIELD_NAMES } from 'pages/PageClient/Single/Car/constants';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ApproveTab } from 'pages/PageClient/Single/Decision/components/ApproveTab/ApproveTab';
import { DecisionTab } from 'pages/PageClient/Single/Decision/components/DecisionTab';
import { DeclineForm } from 'pages/PageClient/Single/Decision/components/DeclineForm';
import { PhotoBlurredForm } from 'pages/PageClient/Single/Decision/components/PhotoBlurredForm';
import { Warnings } from 'pages/PageClient/Single/Decision/Forms/Warnings';
import { ETabs } from 'pages/PageClient/Single/Decision/types';

import {
  FormTabs,
  LayoutContent,
  StyledLayout,
} from './styled';

export const ContentWrapper = styled.div``;

interface VerificationFormProps {}

export const VerificationForm: React.FC<VerificationFormProps> = () => {
  const { personId, verificationId } = useContext(ClientMatchContext) as { personId: string; verificationId: number; };

  const [activeTab, setActiveTab] = useState<ETabs | undefined>();

  const [isLoading, verification] = useVerification(verificationId);
  const isResultsComplete = useSelector(state => isResultsCompleteSelector(state, verificationId));
  const application:IApplication = useSelector(state => getClientApplicationSelector(
    state,
    personId,
    verification?.applicationId,
  ));
  const { dealershipId, id: applicationId, productId } = application;
  // @ts-ignore
  const car = useSelector(state => getClientCarByApplicationId(state, personId, applicationId));

  const hasRoles = useHasRoles();
  const isAbleToSend = hasRoles([SUPER_ADMIN, VERIFICATION_SEND]);
  const isApproved = activeTab === ETabs.APPROVE;

  const isCarDataIncomplete = useMemo(() => !!_.find(CAR_FIELD_NAMES, field => (
    _.isNil(_.get({ ...car, ...(car.autoCalc || {}) }, field)))), [car]);
  const decisionHasBeenMade = useMemo(() => _.includes(
    [`notinverification`, `approved`, `declined`, `send`],
    verification?.verificationStatus?.name,
  ), [verification?.verificationStatus?.name]);
  const carDataWarningShow = useMemo(() => {
    if (_.includes([4, 15], productId)) {
      return (productId === 4 || isApproved) && isCarDataIncomplete;
    }
    return null;
  }, [productId, isApproved, isCarDataIncomplete]);

  useEffect(() => {
    if (verification?.approved !== undefined) {
      setActiveTab(verification.approved ? ETabs.APPROVE : ETabs.DECLINE);
    }
  }, [verification?.approved]);

  return (
    <StyledLayout>
      <Warnings
        // @ts-ignore
        carDataWarningShow={carDataWarningShow}
        isResultsComplete={isResultsComplete}
      />
      <LayoutContent>
        <>
          <FormTabs>
            <DecisionTab
              // @ts-ignore
              activeTab={activeTab} onChange={setActiveTab} tabName={ETabs.PHOTO_BLURRED}>
              Нечеткие фото
            </DecisionTab>
            <DecisionTab
              // @ts-ignore
              activeTab={activeTab} onChange={setActiveTab} tabName={ETabs.APPROVE}>
              Одобрить
            </DecisionTab>
            <DecisionTab
              // @ts-ignore
              activeTab={activeTab}
              isDanger
              onChange={setActiveTab}
              tabName={ETabs.DECLINE}
            >
              Отказать
            </DecisionTab>
          </FormTabs>

          <ContentWrapper>
            {activeTab === ETabs.PHOTO_BLURRED && <PhotoBlurredForm />}

            {activeTab === ETabs.APPROVE && (
              <ApproveTab
                applicationId={applicationId}
                // @ts-ignore
                carDataWarningShow={carDataWarningShow}
                // @ts-ignore
                dealershipId={dealershipId}
                decisionHasBeenMade={decisionHasBeenMade}
                isAbleToSend={isAbleToSend}
                isLoading={isLoading}
                isResultsComplete={isResultsComplete}
                productId={productId}
                verification={verification}
                verificationId={verificationId}
              />
            )}

            {activeTab === ETabs.DECLINE && (
              <DeclineForm
                decisionHasBeenMade={decisionHasBeenMade}
                isAbleToSend={isAbleToSend}
                isLoading={isLoading}
                verification={verification}
                verificationId={verificationId}
              />
            )}
          </ContentWrapper>
        </>
      </LayoutContent>
    </StyledLayout>
  );
};

