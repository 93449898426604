import * as React from 'react';
import {
  memo,
  useState,
} from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { getSearchClients as searchClientsSelector } from 'models/clients/selectors';
import { ILoanClient } from 'models/clients/types';

import useMobileContext from 'hooks/useMobileContext';
import { useStatePreference } from 'hooks/useStatePreference';

import { Empty } from 'antd';
import { ScrollbarsDesktop } from 'components/ScrollbarsDesktop/ScrollbarsDesktop';
import { ClientItem } from 'pages/PageClient/Plural/components/ClientItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  gap: 16px;
  overflow: auto;
  min-width: 100%;

  @media(min-width: ${breakpoints.md}) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media(min-width: ${breakpoints.lg}) {
    padding-left: 60px;
    padding-right: 60px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  @media(max-width: ${breakpoints.xs}) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 16px;

  @media(min-width: ${breakpoints.md}) {
    padding-left: 40px;
  }

  @media(min-width: ${breakpoints.lg}) {
    padding-left: 60px;
  }
`;

interface IComponentProps {
}

const StyledEmpty = styled(Empty)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0;
`;

const ScrollbarsDesktopStyled = styled(ScrollbarsDesktop)<{ $mobile: boolean }>`
  ${p => p.$mobile && `
    min-width: 100vw;
  `}
`;

export const ClientsList: React.FC<IComponentProps> = memo(() => {
  const { mobile } = useMobileContext();
  const clients:ILoanClient[] = useSelector(searchClientsSelector);
  const [isExpandedPreferences, setIsExpandedPreferences] = useStatePreference(
    `ui.client.search.isClientItemExpanded`,
    false,
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(isExpandedPreferences);

  const onExpandedChange = () => {
    setIsExpanded(!isExpanded);
    setIsExpandedPreferences(!isExpanded);
  };

  return _.isEmpty(clients) ? (
    <EmptyWrapper>
      <StyledEmpty
        description='Клиенты не найдены'
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    </EmptyWrapper>
  ) : (
    <ScrollbarsDesktopStyled $mobile={mobile} autoHide>
      <Wrapper>
        {_.map(clients, item => (
          <ClientItem
            client={item}
            isExpanded={isExpanded}
            key={item.personId}
            onExpandedChange={onExpandedChange}
          />
        ))}
      </Wrapper>
    </ScrollbarsDesktopStyled>
  );
});
