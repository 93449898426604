import * as React from 'react';
import {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { hasIntersection } from 'helper';

import { getClientApplicationDocs as getClientApplicationDocsAction } from 'models/clients/actions';
import { getClientApplication as getClientApplicationSelector } from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';
import {
  SUPER_ADMIN,
  VERIFICATION_SEND,
  VERIFICATION_WRITE,
} from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';
import { sendBlurredPhotos as sendBlurredPhotosAction } from 'models/verifications/actions';
import { getItem as getVerificationSelector } from 'models/verifications/selectors';

import { SendOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Select,
} from 'antd';
import clientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { DecisionFormWrapper } from 'pages/PageClient/Single/Decision/components/DecisionFormWrapper';

const allowedRoles = [
  SUPER_ADMIN,
  VERIFICATION_SEND,
  VERIFICATION_WRITE,
];

interface IProps {
}

export const PhotoBlurredForm: React.FC<IProps> = () => {
  const dispatch = useDispatch();

  const { personId, verificationId } = useContext(clientMatchContext);

  const [, dictionaries] = useDictionaries([`documentGroup`]);

  const { application, userRoles, verification } = useSelector(state => {
    const verificationInner = getVerificationSelector(state, verificationId);
    return {
      application : getClientApplicationSelector(state, personId, verificationInner?.applicationId),
      userRoles   : getRoleNamesSelector(state),
      verification: verificationInner,
    };
  });

  const recapturedDocTypeIds = _.flatMap(_.filter(dictionaries?.documentGroup, `isRecaptured`), `typeIds`);

  const onSendBlurred = useCallback(({ documentTypeIds }: { documentTypeIds: number[] }) => {
    dispatch(sendBlurredPhotosAction({ id: verificationId, documentTypeIds: _.flatten(documentTypeIds) }));
  }, [dispatch, verificationId]);

  const isAbleToSend = hasIntersection(userRoles, allowedRoles);

  const docs = _.filter(application?.docs, ({ type }) => _.includes(recapturedDocTypeIds, type));

  const getApplicationDocs = useCallback(() => {
    if (!verification?.applicationId || docs?.length) return;

    dispatch(getClientApplicationDocsAction({
      applicationId: verification.applicationId,
      personId,
    }));
  }, [personId, verification?.applicationId, docs?.length]);

  useEffect(() => {
    getApplicationDocs();
  }, [getApplicationDocs]);

  return (
    <DecisionFormWrapper>
      <Form.Provider>
        <Form
          initialValues={[{ documentTypeIds: [] }]}
          layout='vertical'
          name='PageClientSingleDecision'
          onFinish={onSendBlurred}
          scrollToFirstError={{ behavior: `smooth` }}
          validateTrigger={[`onChange`, `onBlur`]}
        >
          <Form.Item
            label='Фото для переснятия'
            name='documentTypeIds'
            rules={[{ required: true, message: `Выберите фото для переснятия` }]}
          >
            <Select
              mode='multiple'
              options={_.map(docs, ({ name, type }) => ({ label: name, value: type }))}
            >
              Нечеткие фото
            </Select>
          </Form.Item>

          {isAbleToSend && (
            <Form.Item
              shouldUpdate={(prevValues, currentValues) => prevValues.documentTypeIds !== currentValues.documentTypeIds}
            >
              {({ getFieldValue }) => (
                <Button
                  disabled={_.isEmpty(getFieldValue(`documentTypeIds`))}
                  htmlType='submit'
                  icon={<SendOutlined />}
                  type='primary'
                >
                  Отправить на переснятие
                </Button>
              )}
            </Form.Item>
          )}
        </Form>
      </Form.Provider>
    </DecisionFormWrapper>
  );
};
