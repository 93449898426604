import * as React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { Radio } from 'antd';
import { ETabs } from 'pages/PageClient/Single/Decision/types';

const Wrapper = styled.button<{ isActive: boolean; isDanger: boolean }>`
  border: 1px solid ${({ isActive }) => (isActive ? palette.primary500Color : palette.black10Color)};
  background: ${({ isActive }) => (isActive ? palette.backgroundColor : palette.black5Color)};
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: all 200ms ease;
  display: flex;
  justify-content: flex-start;
  font-weight: 500;


  @media (min-width: ${breakpoints.md}) {
    padding: 24px 16px;
    width: 100%;
    margin-bottom: 16px;
  }

  span.ant-radio + * {
    font-size: 16px;
    line-height: 24px;
    color: ${palette.textSecondaryColor};
    font-weight: 500;
    padding-left: 12px;
  }

  :focus {
    border-color: ${palette.primary500Color};
  }

  ${p => p.isDanger && p.isActive && `
     border-color: ${palette.alert500Color(p)};

     :focus {
       border-color: ${palette.alert500Color(p)};
     }

     .ant-radio-checked .ant-radio-inner {
       border-color: ${palette.alert500Color(p)};
       ::after {
         background-color: ${palette.alert500Color(p)};
       }
     }
  `}
`;

interface IComponentProps {
  activeTab: ETabs;
  children: React.ReactNode;
  isDanger?: boolean;
  onChange: (newActiveTab: ETabs) => void;
  tabName: ETabs;
}

export const DecisionTab: React.FC<IComponentProps> = ({
  activeTab,
  children,
  isDanger= false,
  onChange,
  tabName,
}) => {
  const isActive = activeTab === tabName;

  return (
    <Wrapper isActive={isActive} isDanger={isDanger} onClick={() => onChange(tabName)}>
      <Radio checked={isActive} tabIndex={-1}>{children}</Radio>
    </Wrapper>
  );
};
