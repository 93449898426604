import React, { useMemo } from 'react';
import _ from 'lodash';

import {
  getAge,
  getDateFormatted,
  getGenderIcon,
  getGenderTitle,
} from 'models/clients/helpers';
import { useClient } from 'models/clients/hooks';
import {
  CLIENT_EDIT,
  SUPER_ADMIN,
} from 'models/roles/constants';

import { EditOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardRow } from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';

const allowedRoles = [SUPER_ADMIN, CLIENT_EDIT];

const Passport = ({ openEditModal, personId }) => {
  const [isLoading, client] = useClient(personId);

  const items = useMemo(() => _.reject([
    {
      name : `Фамилия`,
      value: client.surname,
    },
    {
      name : `Имя`,
      value: client.name,
    },
    {
      name : `Отчество`,
      value: client.patronymic,
    },
    {
      name : `Пол`,
      value: <>{getGenderIcon(client.gender)}{getGenderTitle(client.gender)}</>,
      text : getGenderTitle(client.gender),
    },
    {
      name : `Возраст`,
      value: getAge(client),
    },
    {
      name : `Место рождения`,
      value: client.birthPlace,
    },
    {
      name : `Дата рождения`,
      value: getDateFormatted(client, `birthDate`),
    },
    {
      name : `Серия`,
      value: client.passportSerial,
    },
    {
      name : `Номер`,
      value: client.passportNumber,
    },
    {
      name : `Выдан`,
      value: client.passportIssuer,
    },
    {
      name : `Дата выдачи`,
      value: getDateFormatted(client, `passportIssuedDate`),
    },
  ], ({ value }) => _.isNil(value)), [personId, client]);

  if (_.isEmpty(items)) return null;

  return (
    <Spin spinning={isLoading}>
      <EntityCard>
        <EntityCardTitle>
          Паспорт

          <EntityCardTitleControl
            actionTitle='Изменение данных'
            allowedRoles={allowedRoles}
            onClick={() => openEditModal()}
          >
            <EditOutlined />
          </EntityCardTitleControl>
        </EntityCardTitle>

        <EntityCardContent>
          {_.map(items, ({ name, text, value }, index) => (
            <EntityCardRow key={index} title={name} valueToCopy={text || value}>
              {value}
            </EntityCardRow>
          ))}
        </EntityCardContent>
      </EntityCard>
    </Spin>

  );
};

export default Passport;
