interface IGetSubheaderLinksProps {
  id?: number;
  personId?: string;
  verificationId?: number;
}

interface IGetSubheaderLinks {
  applications: string
  loans: string
}
export const getSubheaderLinks = ({ personId, verificationId }:IGetSubheaderLinksProps): IGetSubheaderLinks => ({
  applications: verificationId
    ? `/verification/${verificationId}/person/${personId}/application`
    : `/client/person/${personId}/application`,
  loans: verificationId
    ? `/verification/${verificationId}/person/${personId}/loan`
    : `/client/person/${personId}/loan`,
});
