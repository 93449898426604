import React from 'react';

import { Communication } from 'models/communications/types';
import { TEventlogItem } from 'models/eventlogs/types';

import useMobileContext from 'hooks/useMobileContext';

import { MessageOutlined } from '@ant-design/icons';
import { ItemHead } from 'components/Eventlog/Item/ItemHead';
import CommunicationItem from 'pages/PageClient/Single/Communication/Item';

import {
  Content,
  Description,
  Icon,
  Wrapper,
} from '../styled';

interface IComponentProps extends TEventlogItem {
  date: string;
  next?: Communication;
  operatorName?:string;
}

export const UserCommunicationCreate: React.FC<IComponentProps> = ({ date, next, operatorName }) => {
  const { mobile } = useMobileContext();

  const title = `Создана коммуникация`;
  return (
    <Wrapper $isMobile={mobile}>
      <Icon $color='green'><MessageOutlined /></Icon>
      <Content>
        <ItemHead date={date} operatorName={operatorName} title={title} />
        <Description>
          {/*@ts-ignore*/}
          <CommunicationItem hideTitleLabels={[`Дата`]} isMobile {...next} />
        </Description>
      </Content>
    </Wrapper>
  );
};
