import React from 'react';

import { TDictionaryItem } from 'models/dictionaries/types';
import { TEventlogItem } from 'models/eventlogs/types';

import useMobileContext from 'hooks/useMobileContext';

import {
  LoginOutlined,
  LogoutOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import { ItemHead } from 'components/Eventlog/Item/ItemHead';

import {
  Arrow,
  Content,
  Description,
  Icon,
  Wrapper,
} from '../styled';

interface IComponentProps extends TEventlogItem {
  communicationTypeNext?: TDictionaryItem;
  communicationTypePrev?: TDictionaryItem;
  date:string;
  operatorName?:string;
}

export const UserCommunicationTypeUpdate: React.FC<IComponentProps> = ({
  communicationTypeNext,
  communicationTypePrev,
  date,
  operatorName,
}) => {
  const { mobile } = useMobileContext();

  let title;
  let icon;
  let color;
  if (!communicationTypePrev) {
    title = `Добавлен предпочитаемый канал связи клиента`;
    color = `green`;
    icon = <LoginOutlined />;
  } else if (!communicationTypeNext) {
    title = `Снят предпочитаемый канал связи клиента`;
    color = `red`;
    icon = <LogoutOutlined />;
  } else {
    title = `Изменён предпочитаемый канал связи клиента`;
    color = `orange`;
    icon = <SwapOutlined />;
  }

  return (
    <Wrapper $isMobile={mobile}>
      <Icon $color={color}>{icon}</Icon>
      <Content>
        <ItemHead date={date} operatorName={operatorName} title={title} />
        <Description>
          {communicationTypePrev && (
            <DefaultTag color={communicationTypePrev.color}>{communicationTypePrev.description}</DefaultTag>
          )}
          {communicationTypePrev && communicationTypeNext && <Arrow />}
          {communicationTypeNext && (
            <DefaultTag color={communicationTypeNext.color}>{communicationTypeNext.description}</DefaultTag>
          )}
        </Description>
      </Content>
    </Wrapper>
  );
};
