import React, {
  useCallback,
  useEffect,
  useMemo, useRef,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { ViewportList } from 'react-viewport-list';


import {
  getClientAuthCalls as getClientAuthCallsAction,
  getClientCalibriChats as getClientCalibriChatsAction,
  getClientSms as getClientSmsAction,
} from 'models/clients/actions';
import {
  getClientAuthCalls as getClientAuthCallsSelector,
  getClientCalibriChats as getClientCalibriChatsSelector,
  getClientSms as getClientSmsSelector,
} from 'models/clients/selectors';
import { search as searchCommunicationsAction } from 'models/communications/actions';
import {
  getFilters as getFiltersSelector,
  getItemsByPersonId as getItemsSelector,
  getSorter as getSorterSelector,
} from 'models/communications/selectors';
import { isGlobalLoading as isGlobalLoadingSelector } from "models/ui/selectors";
import { useDictionaries } from 'models/dictionaries/hooks';

import { useStatePreference } from 'hooks/useStatePreference';

import {
  Empty,
  Spin as SpinDefault,
} from 'antd';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import {
  TabContentWrapper as TabContentWrapperDefault,
} from 'pages/PageClient/Single/components/TabContentWrapper/TabContentWrapper';

import CalibriComment from './CalibriComment';
import Controls from './Controls';
import {
  getCommunicationsFiltered,
  getItems,
} from './helpers';
import Item from './Item';

const TabContentWrapper = styled(TabContentWrapperDefault)`
  margin: 0;
  padding: 0;
  min-height: 100%;
`;

const Spin = styled(SpinDefault)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Content = ({
  isCommunicationsDetailedView,
  isLoading,
  isWrapped,
  items,
  onReload,
  setCommunicationsDetailedView,
  viewportRef,
}) => {
  return (
    <TabContentWrapper>
      <Controls
        isCommunicationsDetailedView={isCommunicationsDetailedView}
        isWrapped={isWrapped}
        onReload={onReload}
        setCommunicationsDetailedView={setCommunicationsDetailedView}
      />
      {isLoading && <Spin />}
      {_.isEmpty(items) && <Empty />}
      {!_.isEmpty(items) && (
        <ViewportList
          items={items}
          itemsSize={items.length}
          itemHeight={90}
          viewportRef={viewportRef}
        >
          {item => (
            <Item isCommunicationsDetailedView={isCommunicationsDetailedView} key={item.id} {...item} />
          )}
        </ViewportList>
      )}
    </TabContentWrapper>
  );
};

const PageClientSingleCommunication = ({
  isWrapped = true,
  match = { params: {} },
  personId: _personId,
  viewportRef: _viewportRef,
}) => {
  const [isCommunicationsDetailedView, setCommunicationsDetailedView] = useStatePreference(
    `ui.collection.isCommunicationsDetailedView`,
    true,
  );

  const personId = _personId || match?.params?.personId;

  const {
    authCalls,
    calibriChats,
    communications,
    isGlobalLoading,
    filters,
    sms,
    sorter,
  } = useSelector(state => ({
    authCalls     : getClientAuthCallsSelector(state, personId),
    calibriChats  : getClientCalibriChatsSelector(state, personId),
    communications: getItemsSelector(state, personId),
    isGlobalLoading: isGlobalLoadingSelector(state),
    filters       : getFiltersSelector(state),
    sms           : getClientSmsSelector(state, personId),
    sorter        : getSorterSelector(state),
  }));
  const [isDictionariesLoading, dictionaries] = useDictionaries([`communicationType`], undefined, true);
  const dispatch = useDispatch();
  const getAuthCalls = useCallback(
    _.debounce((force = false) => dispatch(getClientAuthCallsAction({ personId, force })), 500),
    [dispatch, personId],
  );
  const getCalibriChats = useCallback(
    _.debounce((force = false) => dispatch(getClientCalibriChatsAction({ personId, force })), 500),
    [dispatch, personId],
  );
  const getSms = useCallback(
    _.debounce((force = false) => dispatch(getClientSmsAction({ personId, force })), 500),
    [dispatch, personId],
  );
  const search = useCallback(
    _.debounce(() => dispatch(searchCommunicationsAction({ personId })), 500),
    [dispatch, personId],
  );

  const onReload = useCallback(() => {
    search();
    getSms(true);
    getCalibriChats(true);
    getAuthCalls(true);
  }, [
    getAuthCalls,
    getCalibriChats,
    getSms,
    isWrapped,
    search,
  ]);

  const smsMailingTypeId = _.find(dictionaries.communicationType, { name: `smsMailing` })?.id;
  const calibriChatTypeId = _.find(dictionaries.communicationType, { name: `calibriChat` })?.id;
  const authCallTypeId = _.find(dictionaries.communicationType, { name: `authCall` })?.id;

  const filteredAuthCalls = useMemo((() => getCommunicationsFiltered({
    communicationTypeId: authCallTypeId,
    filters,
    itemMapFunction    : (item, index) => ({
      campaignName: item.operator,
      comment     : `Код: ${item.code}; (${[`FORBIDDEN`, `REJECTED`, `UNAVAILABLE`].includes(item.operatorStatusText)
        ? `Недозвон`
        : `Успешно`})`,
      communicationTypeId: authCallTypeId,
      createdAt          : new Date(item.createDtm).toISOString(),
      id                 : item.callExternalId || `authCall_${index}`,
      operator           : item.operator,
    }),
    items: authCalls,
  })), [authCalls?.length, JSON.stringify(filters)]);

  const filteredSms = useMemo(() => getCommunicationsFiltered({
    communicationTypeId: smsMailingTypeId,
    filters,
    itemMapFunction    : (item, index) => ({
      campaignName       : item.operator,
      comment            : `${item.smsText} (${item.operatorStatusText})`,
      communicationTypeId: smsMailingTypeId,
      createdAt          : new Date(item.createDtm).toISOString(),
      id                 : item.smsExternalId || `sms_${index}`,
      operator           : item.operator,
    }),
    items: sms,
  }), [sms?.length, JSON.stringify(filters)]);

  const filteredCalibriChats = useMemo(() => getCommunicationsFiltered({
    communicationTypeId: calibriChatTypeId,
    createDtmFieldName : `statisticsDtm`,
    filters,
    itemMapFunction    : (item, index) => {
      const id = item.chatId || `calibriChat_${index}`;
      return {
        campaignName    : item.nameType,
        clientName      : item.clientName,
        commentWithLabel: (
          <CalibriComment communicationId={id} item={item} personId={personId} />
        ),
        communicationTypeId: calibriChatTypeId,
        createdAt          : new Date(item.statisticsDtm).toISOString(),
        id,
        operator           : item.responsibleManager,
        phone              : item.phone,
      };
    },
    items: calibriChats,
  }), [calibriChats?.length, JSON.stringify(filters)]);
  const items = getItems(
      filteredAuthCalls,
      filteredSms,
      communications,
      filteredCalibriChats,
      sorter,
    );
  useEffect(() => {
    if (_.isEmpty(items)) onReload();
  }, [items.length]);

  const isLoading = isDictionariesLoading || isGlobalLoading;
  const viewportRef = useRef(null);
  return isWrapped ? (
    <ClientTab isReloading={isLoading} onReload={onReload} ref={viewportRef}>
      <Content
        isCommunicationsDetailedView={isCommunicationsDetailedView}
        isLoading={isLoading}
        isWrapped={isWrapped}
        items={items}
        onReload={onReload}
        setCommunicationsDetailedView={setCommunicationsDetailedView}
        viewportRef={viewportRef}
      />
    </ClientTab>
  ) : (
    <Content
      isCommunicationsDetailedView={isCommunicationsDetailedView}
      isLoading={isLoading}
      isWrapped={isWrapped}
      items={items}
      onReload={onReload}
      setCommunicationsDetailedView={setCommunicationsDetailedView}
      viewportRef={_viewportRef}
    />
  );
};

export default PageClientSingleCommunication;
