import * as React from 'react';
import _ from 'lodash';

import {
  Dropdown as DropdownDefault,
  MenuProps,
} from 'antd';
import { DropdownProps } from 'antd/lib/dropdown/dropdown';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import {MenuItemProps} from "antd/lib";
import styled from "styled-components";
import {getLightStyleOr, palette} from "styles/theme";

type TItemType = MenuItemProps & {
  hidden?: boolean;
  label?: React.ReactNode;
  primary?: boolean
  tooltip?: string;
};

interface IMenuProps extends Omit<MenuProps, `items`> {
  items?: TItemType[];
}

interface IComponentProps extends Omit<DropdownProps, `menu`> {
  menu?: IMenuProps
}

const Dropdown = styled(DropdownDefault)`
  color: ${getLightStyleOr(palette.blackColor, palette.whiteColor)};
`;

export const DefaultDropdown: React.FC<IComponentProps> = ({ children, menu, trigger }) => {
  // @ts-ignore
  const { onClick } = menu;

  const rejectedItems = _.reject(menu?.items, `hidden`);

  const menuItems: MenuItemProps[] = _.map(rejectedItems, item => {
    const {
      label,
      primary,
      tooltip,
      ...other
    } = item;

    return {
      ...other,
      className: primary ? `ant-dropdown-menu-item-primary` : ``,
      label    : tooltip ? <DefaultTooltip title={tooltip}>{label}</DefaultTooltip> : label,
    };
  });

  return (
    <Dropdown
      // @ts-ignore
      menu={{ items: menuItems, onClick }}
      trigger={trigger}
    >
      {children}
    </Dropdown>
  );
};
