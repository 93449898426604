import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { makeCall as makeCallAction } from 'models/mtt/actions';
import { isLoading as isLoadingSelector } from 'models/mtt/selectors';
import { getCurrent as getCurrentOperatorSelector } from 'models/operators/selectors';

import {
  LoadingOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

const PhoneButton = styled(Button)`
  border: none;
  color: ${palette.primary500Color};
  margin-top: -6px;
  margin-bottom: -6px;

  :disabled {
    color: ${palette.textDisabledColor};
  }
`;

interface IProps {
  className?: string,
  phone: string,
}

const MttPhoneButton: React.FC<IProps> = ({ className = ``, phone }) => {
  const dispatch = useDispatch();

  const currentOperator = useSelector(getCurrentOperatorSelector);
  const isLoading = useSelector(isLoadingSelector);

  const makeCall = (data: { mttWorkplace: any; phone: string; }) => dispatch(makeCallAction(data));
  if (!phone) return null;

  const mttWorkplace = currentOperator?.mttWorkplace;
  const tooltip = mttWorkplace
    ? `Позвонить`
    : `Проверьте соответствие рабочего места в Администрирование / Операторы`;

  return (
    <DefaultTooltip placement='top' title={tooltip}>
      <PhoneButton
        className={className}
        disabled={isLoading || !mttWorkplace}
        icon={isLoading ? <LoadingOutlined /> : <PhoneOutlined />}
        onClick={() => makeCall({ mttWorkplace, phone })}
        type='text'
      />
    </DefaultTooltip>
  );
};

export default MttPhoneButton;
