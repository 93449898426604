import React, {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import {
  updateClientCondition as updateClientConditionAction,
  updateContractCondition as updateContractConditionAction,
} from 'models/clients/actions';
import {
  getClient as getClientSelector,
  getClientLoanById as getClientLoanByIdSelector,
} from 'models/clients/selectors';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';

import {
  Modal,
  Select as AntSelect,
} from 'antd';

const Select = styled(AntSelect)`
  width: 100%;
`;

interface IProps {
  entity: `contract` | `client`;
  loanId?: string;
  onClose: () => void;
  personId: string;
  visible: boolean;
}

const ConditionModal: React.FC<IProps> = ({
  entity,
  loanId,
  onClose,
  personId,
  visible,
}) => {
  const dispatch = useDispatch();

  const { client, loan, options } = useSelector(state => ({
    options: getDictionarySelector(state, `${entity}Condition`),
    client : getClientSelector(state, personId),
    loan   : entity === `contract` ? getClientLoanByIdSelector(state, personId, loanId) : null,
  }));

  const updateClientCondition = (v: { personId: string; clientConditionId: number; }) => dispatch(updateClientConditionAction(v));
  const updateContractCondition = (v: { personId: string; contractConditionId: number; loanId: string | undefined; }) => dispatch(updateContractConditionAction(v));

  const onSubmit = (value: number) => {
    if (entity === `client`) {
      updateClientCondition({ personId, clientConditionId: value });
    } else if (entity === `contract`) {
      updateContractCondition({ personId, contractConditionId: value, loanId });
    }
    onClose();
  };

  const initialValue = entity === `client`
    ? _.get(client, `clientConditionId`)
    : _.get(loan, `contractConditionId`);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
      setValue(initialValue)
    },
    [initialValue],
  );

  return (
    <Modal
      cancelText='Отмена'
      centered
      okButtonProps={{ disabled: initialValue === value }}
      okText='Ok'
      onCancel={onClose}
      onOk={() => onSubmit(value)}
      open={visible}
      title={`Состояние ${entity === `client` ? `клиента` : `контракта`}`}
    >
      <p>{`Выберите новое состояние ${entity === `client` ? `клиента` : `контракта`}`}</p>
      <Select autoFocus onChange={setValue} value={value}>
        {_.map(options, ({ id, name }) => (
          <Select.Option key={id} value={id}>{name}</Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default ConditionModal;
