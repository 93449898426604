/* eslint-disable max-len */

import api from 'api/index';

import { IUpdateData } from 'models/lead/types';

export const create = (data: any) => api.put(`/leads/create`, data);
export const exportExcel = (ids: number[], tableState: any) => api.post(`/leads/export`, { ...tableState, ids }, {
  responseType: `blob`,
});
export const search = (tableState: any) => api.post(`/leads/search`, { ...tableState });
export const getFilters = () => api.get(`/leads/filters`);
export const getSingle = (id: number) => api.get(`/leads/lead/${id}`);
export const setStatus = (id: number, leadStatusId: number) => api.post(`/leads/lead/${id}/status/${leadStatusId}`);
export const setStatusMultiple = (ids: number[], leadStatusId: number) => api.post(`/leads/lead/status`, { ids, leadStatusId });
export const setOperator = (id: number, operatorId: number) => api.post(`/leads/lead/${id}/operator/${operatorId}`);
export const setOperatorMultiple = (ids: number[], operatorId: number) => api.post(`/leads/lead/operator`, { operatorId, ids });
export const remove = (ids: number[]) => api.post(`/leads/remove`, { ids });

export const uploadFile = (file: File) => {
  const formData = new FormData();

  formData.append(`file`, file, file.name);

  return api.post(`/leads/upload`, formData, { headers: { 'Content-Type': `multipart/form-data` } });
};

export const update = (id: number, data: IUpdateData) => api.patch(`/leads/lead/${id}/update`, data);
