import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { parseResponse } from 'api/helpers';
import {
  downloadBlob,
  formatDate,
  showError,
} from 'helper';

import {
  get as getCallsAction,
  setLoading as setLoadingAction,
  setTableState as setTableStateAction,
} from 'models/calls/actions';
import { getCallRecord } from 'models/calls/api';
import {
  getClientCalls as getCallsSelector,
  getIsLoading as getIsLoadingSelector,
  getTableState as getTableStateSelector,
} from 'models/calls/selectors';
import { useClient } from 'models/clients/hooks';

import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { CallRecordControls } from 'pages/PageClient/Single/Call/CallRecordControls';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';

import { COLUMNS } from './constants';
import { ActionMeta } from 'redux-actions';

const PageClientSingleCall = ({isWrapped = true, personId: _personId}: { isWrapped?: boolean; personId?: string; }) => {
  const dispatch = useDispatch();

  const clientMatch = useContext(ClientMatchContext);
  const personId = _personId || clientMatch?.personId as string;

  const [, client] = useClient(personId);

  const tableState = useSelector(getTableStateSelector);
  const calls = useSelector(state => getCallsSelector(state, personId));
  const isLoading = useSelector(getIsLoadingSelector);

  const [audioSrc, setAudioSrc] = React.useState<string | null>(null);
  const [currentAudioUrl, setCurrentAudioUrl] = React.useState<string | null>(null);
  const [isAudioPopoverOpen, setIsAudioPopoverOpen] = React.useState<boolean>(false);

  const handleAudioPopoverState = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setCurrentAudioUrl(null);
      setAudioSrc(null);
    }
    setIsAudioPopoverOpen(isOpen);
  }, []);

  const getClientCalls = () => dispatch(getCallsAction({personId, phone: client?.phone}));
  const setTableState = (state: { callback: () => ActionMeta<any, any>; filters: Record<string, FilterValue>; }) => dispatch(setTableStateAction(state));
  const setLoading = (state: boolean) => dispatch(setLoadingAction(state));

  const onTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue>) => {
    setTableState({ callback: getClientCalls, filters });
  };

  const getCallRecordBlob = useCallback(async (recordUrl: string): Promise<Blob | null> => {
    try {
      setLoading(true);
      return parseResponse({
        defaultError: `Не удалось загрузить запись звонка`,
        response    : await getCallRecord(recordUrl),
      });
    } catch (error) {
      showError({ error });
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  const downloadCallRecord = useCallback(async (recordUrl: string, recordTitle: string) => {
    const blob = await getCallRecordBlob(recordUrl);
    downloadBlob(blob, `${recordTitle}.mp3`);
  }, [getCallRecordBlob]);

  const showCallRecordPlayer = useCallback(async (recordUrl: string) => {
    setCurrentAudioUrl(recordUrl);
    const blob = await getCallRecordBlob(recordUrl);
    if (blob) {
      const url = URL.createObjectURL(blob);
      setAudioSrc(url);
    }
  }, [getCallRecordBlob]);

  useEffect(() => {
    if (_.isEmpty(calls)) getClientCalls();
  }, []);

  const content = (
    <AdjustableTable
      columns={COLUMNS(tableState)}
      dataSource={calls}
      // @ts-ignore
      onChange={onTableChange}
      pagination={{
        hideOnSinglePage: true,
        pageSize        : _.size(calls),
        size            : `small`,
      }}
      preferences={{
        path      : `clientInfo.call`,
        cellRender: (text, record) => {
          const { recordUrl, startTime } = record;

          const recordTitle = `Запись звонка от ${formatDate(new Date(startTime))}`;
          return recordUrl && _.map(recordUrl, url => {
            const isCallRecordPlayerOpen = !!(currentAudioUrl === url && audioSrc && isAudioPopoverOpen);
            return (
              <CallRecordControls
                audioSrc={audioSrc}
                downloadCallRecord={downloadCallRecord}
                handleAudioPopoverState={handleAudioPopoverState}
                isCallRecordPlayerOpen={isCallRecordPlayerOpen}
                recordTitle={recordTitle}
                showCallRecordPlayer={showCallRecordPlayer}
                url={url}
              />
            );
          });
        },
      }}
      rowKey='callId'
      scroll={{ x: `max-content`, y: window.innerHeight - 255 }}
    />
  );

  return isWrapped ? (
    <ClientTab isReloading={isLoading} onReload={getClientCalls}>
      {content}
    </ClientTab>
  ) : content;
};

export default PageClientSingleCall;
