import * as React from 'react';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { CloseOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  background: ${palette.black30Color};
  padding: 8px 16px;
  display: flex;
  align-items: center;

  .anticon {
    font-size: 14px;
    padding: 1px;
    color: ${palette.black60Color};
  }
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${palette.black80Color};
  margin-right: 8px;
  margin-left: 8px;
`;

interface IComponentProps {
  icon: React.ReactNode;
  onClick:() => void;
  title: string;
}

export const InputActionPlate: React.FC<IComponentProps> = ({ icon, onClick, title }) => (
  <Wrapper>
    {icon}
    <Title>{title}</Title>
    <CloseOutlined onClick={onClick} />
  </Wrapper>
);
