import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { Tabs } from 'antd';

const mainColor = palette.secondaryAccentColor;

export const SubHeaderMenu = styled(Tabs)`
  &.ant-tabs {
    color: ${getLightStyleOr(palette.whiteColor, palette.black60Color)};
    max-width: 100%;
    overflow: hidden;
  }

  .ant-tabs-tab {
    border-bottom: 2px solid transparent;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid ${mainColor};
  }

  &.ant-tabs .ant-tabs-nav {
    margin-bottom: 0px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab-btn:focus {
    color: ${mainColor};
  }

  .ant-tabs-tab:hover, .ant-tabs-tab:active, .ant-tabs-tab:focus {
    color: ${mainColor};
  }

  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: ${mainColor};
  }

  &.ant-tabs-top > .ant-tabs-nav::before,
  &.ant-tabs-bottom > .ant-tabs-nav::before,
  &.ant-tabs-top > div > .ant-tabs-nav::before,
  &.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 0px;
  }

  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  &.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    background: transparent;
  }
`;
