import * as React from 'react';
import { useContext } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
  shadows,
} from 'styles/theme';

import api from 'api/index';
import {
  downloadBlob,
  showError,
} from 'helper';

import { IDoc } from 'models/clients/types';
import { ROLE_NAMES } from 'models/roles/constants';

import {
  DownloadOutlined,
  EditOutlined,
  FieldTimeOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import {
  Collapse,
  List,
} from 'antd';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';
import DownloadGroupControls from 'pages/PageClient/Single/Docs/DownloadGroupControls';
import { DocsContext } from 'pages/PageClient/Single/Docs/index';
import { IDocumentGroup } from 'pages/PageClient/Single/Docs/types';

const Wrapper = styled(Collapse)`
  &.ant-collapse {
    border-radius: inherit;
    border: none;
    background-color: ${palette.backgroundColor};

    .ant-collapse-item {
      margin-bottom: 8px;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid ${palette.borderDefaultColor};
      box-shadow: ${shadows.image};
      background-color: ${palette.backgroundColor};

      .ant-collapse-header {
        font-family: 'PT Root UI', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
        color: ${palette.textSecondaryColor};
        margin-right: -12px;
      }

      .ant-collapse-content {
        border-top: none;
        background-color: ${palette.backgroundColor};

        .ant-collapse-content-box {
          padding: 0 16px 16px 16px;

          .ant-spin-container {
            .ant-list-items {
              .ant-list-item {
                flex-direction: column;
                align-items: flex-start;
                border-bottom: none;
                padding: 8px 0;
                margin-right: -8px;

                @media(min-width: ${breakpoints.md}) {
                  flex-direction: row;
                }

                .ant-list-item-meta {
                  .ant-list-item-meta-avatar {
                    color: ${palette.lightGrayColor};
                  }
                  .ant-list-item-meta-content{
                    width: 100%;
                    margin-bottom: 8px;
                    @media(min-width: ${breakpoints.md}) {
                      margin-bottom: 0;
                    }
                    .ant-list-item-meta-title {
                      color: ${palette.textSecondaryColor};
                      cursor: pointer;

                      :hover {
                        color: ${palette.textPrimaryColor};
                      }
                    }
                  }
                }

                .ant-list-item-action {
                  margin-left: 0;

                  @media(min-width: ${breakpoints.md}) {
                    margin-left: 12px;
                  }

                  .ant-list-item-action-split {
                    background: ${palette.borderDefaultColor};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ActionItem = styled(EntityCardTitleControl)`
  .anticon {
    font-size: 16px;
  }
`;

const getIconByContentType = (contentType: string | _.NumericDictionary<string> | null | undefined) => {
  if (_.includes(contentType, `pdf`)) return <FilePdfOutlined />;
  if (_.includes(contentType, `image`)) return <FileImageOutlined />;
  if (_.includes(contentType, `word`)) return <FileWordOutlined />;
  if (_.includes(contentType, `zip`)) return <FileZipOutlined />;
  return <FileOutlined />;
};

interface IComponentProps {
  applicationId: string;
  docs: IDoc[];
  documentGroups: IDocumentGroup[];
  personId: string;
  onOpenUploadHistory(): void;
  onOpenUploadModal(type: any): void;
  onSetHistoryDocumentType(type:any): void;
}

export const DocsCollapse: React.FC<IComponentProps> = ({
  applicationId,
  docs,
  documentGroups,
  onOpenUploadHistory,
  onOpenUploadModal,
  onSetHistoryDocumentType,
  personId,
}) => {
  const { CLIENT_EDIT, SUPER_ADMIN } = ROLE_NAMES;

  const { isDownloading, setIsDownloading, setUrl } = useContext(DocsContext);

  const downloadFile = async (url: string, fileName: string | undefined) => {
    try {
      setIsDownloading(true);
      const { data: blob } = await api.get(url, { responseType: `blob` });
      downloadBlob(blob, fileName);
    } catch (error) {
      showError({
        error: `При скачивании документа произошла ошибка. Попробуйте еще раз или обратитесь к администратору`,
      });
      console.log({ error }); // eslint-disable-line no-console
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Wrapper>
      {_.map(_.reject(documentGroups, `isHidden`), item => {
        const { description, typeIds } = item;
        const items = _.compact(_.map(typeIds, type => _.find(docs, { type })));
        if (_.isEmpty(items)) return null;
        return (
          <Collapse.Panel
            extra={(
              <DownloadGroupControls
                applicationId={applicationId}
                buttonType='primary'
                docs={items}
                personId={personId}
                sectionTitle={description}
              />
            )}
            header={description}
            key={description}
          >
            <List
              dataSource={items}
              itemLayout='horizontal'
              renderItem={({
                contentType,
                downloadName,
                extension,
                label,
                readOnly,
                type,
                typeName,
                url,
                urlPdf,
              }) => (
                <List.Item
                  actions={_.compact([
                    <ActionItem
                      actionTitle='История загрузок по заявке'
                      disabled={!readOnly}
                      key={`${typeName}_link`}
                      onClick={() => {
                        onSetHistoryDocumentType(type);
                        onOpenUploadHistory();
                      }}
                    >
                      <FieldTimeOutlined />
                    </ActionItem>,
                    <ActionItem
                      actionTitle='Заменить документ'
                      allowedRoles={[CLIENT_EDIT, SUPER_ADMIN]}
                      disabled={!readOnly && !!applicationId}
                      key={`${typeName}_link`}
                      onClick={() => onOpenUploadModal(type)}
                    >
                      <EditOutlined />
                    </ActionItem>,
                    <ActionItem
                      actionTitle={`Скачать ${_.toUpper(extension)}`}
                      disabled={isDownloading}
                      key={`${typeName}_link`}
                      onClick={() => downloadFile(url, downloadName)}
                    >
                      <DownloadOutlined />
                    </ActionItem>,
                    ...(extension === `pdf` ? [] : [
                      <ActionItem
                        actionTitle='Скачать PDF (конвертация документа может занимать до 20 секунд)'
                        disabled={isDownloading}
                        key={`${typeName}_link`}
                        onClick={() => downloadFile(urlPdf, downloadName)}
                      >
                        <FilePdfOutlined />
                      </ActionItem>,
                    ]),
                  ])}
                >
                  <List.Item.Meta
                    avatar={getIconByContentType(contentType)}
                    title={(
                      <div onClick={() => setUrl(url)}>
                        {label}
                      </div>
                    )}
                  />
                </List.Item>
              )}
              rowKey='name'
            />
          </Collapse.Panel>
        );
      })}
    </Wrapper>
  );
};
