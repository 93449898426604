import { createAction } from 'redux-actions';

export const model = `actions`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const CREATE = `CREATE`;
export const CREATE_PERSON_ACTION = `CREATE_PERSON_ACTION`;
export const GET = `GET`;
export const GET_PERSON_ACTION = `GET_PERSON_ACTION`;
export const REMOVE_PERSON_ACTION = `REMOVE_PERSON_ACTION`;
export const SET = `SET`;
export const SET_LOADING = `SET_LOADING`;
export const SET_PERSON_ACTIONS = `SET_PERSON_ACTIONS`;
export const UPDATE = `UPDATE`;
export const UPLOAD_FILE = `UPLOAD_FILE`;

export const create = ca(CREATE);
export const createPersonAction = ca(CREATE_PERSON_ACTION);
export const get = ca(GET);
export const getPersonAction = ca(GET_PERSON_ACTION);
export const removePersonAction = ca(REMOVE_PERSON_ACTION);
export const set = ca(SET);
export const setLoading = ca(SET_LOADING);
export const setPersonActions = ca(SET_PERSON_ACTIONS);
export const update = ca(UPDATE);
export const uploadFile = ca(UPLOAD_FILE);
