import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(getState, ({ items }) => items);

export const getPersonActions = createSelector(getState, ({ personActions }) => personActions || {});

export const getPersonActionsByPersonId = createSelector(
  getPersonActions,
  (state, personId) => personId,
  (personActions, personId) => personActions[personId] || [],
);

export const isLoaded = createSelector(getState, ({ isLoaded: _isLoaded }) => _isLoaded);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);
