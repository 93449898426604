import _ from 'lodash';
import { useSelector } from 'dva';

import { TRoleName } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

export function useHasRole() {
  const userRoles = useSelector(getRoleNamesSelector) || [];

  function hasRole(role: TRoleName) {
    return _.includes(userRoles, role);
  }

  return hasRole;
}
