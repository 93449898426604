import * as React from 'react';
import {
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
  zIndex,
} from 'styles/theme';

import { useClickOutsideHandler } from 'hooks/useClickOutside';

import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import CommunicationToggle from 'pages/Layout/Header/CommunicationToggle';
import { HeaderLogo } from 'pages/Layout/Header/HeaderLogo';
import { HeaderNotificationBadge } from 'pages/Layout/Header/HeaderNotificationBadge';
import { MenuMobile } from 'pages/Layout/Header/MenuMobile';

import { MainNav } from './helpers';

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  padding: 0 16px;
  position: sticky;
  background: ${palette.backgroundColor};
  top: 0;
  z-index: ${zIndex.header};
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  margin-left: auto;
`;

const MobileMenuBtn = styled(Button)`
  margin-left: 8px;
  flex-shrink: 0;
`;

const StyledMenuOutlined = styled(MenuOutlined)`
  color: ${getLightStyleOr(palette.black60Color, palette.black40Color)};
`;

interface IComponentProps {
  activeTabPath: string;
  nav: MainNav;
  onPreferencesOpen():void;
}

export const HeaderMobile: React.FC<IComponentProps> = ({ activeTabPath, nav, onPreferencesOpen }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible(!visible);
  };

  const exceptionRef = useRef(null);
  const wrapperRef = useRef(null);

  useClickOutsideHandler(wrapperRef, () => {
    if (visible) {
      toggleVisible();
    }
  }, exceptionRef);

  return (
    <Wrapper>
      <HeaderLogo to='/client' />
      <Controls>
        <CommunicationToggle />
        <HeaderNotificationBadge />

        <MobileMenuBtn
          icon={<StyledMenuOutlined />}
          onClick={() => toggleVisible()}
          ref={exceptionRef}
          type='text'
        />

        <MenuMobile
          nav={nav}
          onPreferencesOpen={onPreferencesOpen}
          ref={wrapperRef}
          selectedKeys={[activeTabPath]}
          toggleMenuVisibility={() => toggleVisible()}
          visible={visible}
        />
      </Controls>
    </Wrapper>
  );
};

