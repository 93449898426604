import { useEffect } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import {
  getMessages as getMessagesAction,
  search as searchDialogAction,
} from 'models/dialogs/actions';
import { getItemByPersonId } from 'models/dialogs/selectors';

export const useMessenger = (personId: string) => {
  const dispatch = useDispatch();

  const dialog = useSelector(state => getItemByPersonId(state, personId));

  useEffect(() => {
    if (_.isEmpty(dialog)) {
      dispatch(searchDialogAction({
        personId,
        shouldCreate: true,
        single      : true,
      }));
    }
  }, [dialog, personId]);

  useEffect(() => {
    if (!_.isEmpty(dialog) && !_.has(dialog, `messages`)) {
      dispatch(getMessagesAction({ id: dialog?.id }));
    }
  }, [dialog, personId]);

  useEffect(() => {
    if (dialog?.id) dispatch(getMessagesAction({ id: dialog?.id }));
  }, []);

  return { dialog };
};
