import * as React from 'react';
import {
  useContext,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { parseResponse } from 'api/helpers';
import {
  getNumber,
  getNumberOrEmpty,
  hasIntersection,
  prettifyAmount,
  prettifyNumber,
  prettifyNumberOrEmpty,
  showError,
} from 'helper';

import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';
import {
  approve as approveAction,
  createGuaranteeLetter as createGuaranteeLetterAction,
  setDecision as setDecisionAction,
  verifyApplicationIncome as verifyApplicationIncomeAction,
} from 'models/verifications/actions';
import { getPdn } from 'models/verifications/api';
import { COMISSIONS } from 'models/verifications/constants';

import {
  SaveOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  Popconfirm,
  Select,
  Space,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import MobileContext from 'pages/Layout/MobileContext';
import AmountSplit from 'pages/PageClient/Single/Decision/components/AmountSplit';
import { ILimitProps } from 'pages/PageClient/Single/Decision/components/ApproveTab/types';
import { useAmountSplit } from 'pages/PageClient/Single/Decision/components/ApproveTab/useAmountSplit';
import { useSendButtonDisabled } from 'pages/PageClient/Single/Decision/components/ApproveTab/useSendButtonDisabled';
import { DecisionFormWrapper } from 'pages/PageClient/Single/Decision/components/DecisionFormWrapper';
import { FormRowCalculatePdn } from 'pages/PageClient/Single/Decision/components/FormRowCalculatePdn';
import { Warning } from 'pages/PageClient/Single/Decision/components/Warning';

const Red = styled.span`
  color: ${palette.redColor};
`;

const IncomeWrapper = styled.div`
  display: flex;

  .ant-input-number {
    width: 323px;
  }

  .ant-input-number-group-addon {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const IncomeSaveButton = styled(Button)`
  font-size: 14px;
  color: ${palette.primaryColor};
  cursor: pointer;
  border: none;
  width: 20px;
  height: 20px;
`;

interface IFormValues {
  amount: number;
  comission: string;
  comment: string;
  insurance: boolean;
  rate: number;
  term: number;
}

interface IComponentProps {
  applicationId: string;
  carDataWarningShow: boolean;
  dealershipId: number;
  decisionHasBeenMade: boolean;
  isAbleToSend: boolean;
  isLoading: boolean;
  isResultsComplete: boolean;
  limitProps: ILimitProps;
  productId: number;
  verification: any;
  verificationId: number;
}

export const ApproveForm: React.FC<IComponentProps> = ({
  applicationId,
  carDataWarningShow,
  dealershipId,
  decisionHasBeenMade,
  isAbleToSend,
  isLoading,
  isResultsComplete,
  limitProps,
  productId,
  verification,
  verificationId,
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const {
    amountMax,
    amountMaxRaw,
    amountMin,
    amountMinRaw,
    rateMax,
    rateMaxRaw,
    rateMin,
    rateMinRaw,
    termMax,
    termMaxRaw,
    termMin,
    termMinRaw,
  } = limitProps;

  const initialValues = {
    amount   : _.get(verification, `amount`, 0),
    comment  : _.get(verification, `comment`, ``),
    comission: _.get(verification, `comission`, null),
    insurance: false,
    rate     : _.get(verification, `rate`, 0),
    term     : _.get(verification, `term`, 0),
  };

  const [formValues, setFormValues] = React.useState<IFormValues>(initialValues);
  // @ts-ignore
  const [verifiedIncome, setVerifiedIncome] = React.useState<number>(null);
  const [pdn, setPdn] = useState<number | null>(null);
  const [isPdnLoading, setIsPdnLoading] = useState<boolean>(false);
  const [isDecisionLoading, setIsDecisionLoading] = useState<boolean>(false);

  const pdnDecision = {
    amount   : formValues.amount,
    rate     : formValues.rate,
    term     : formValues.term,
    insurance: formValues.insurance,
  };

  const calculatePdn = async ():Promise<number | null> => {
    const defaultError = `При запросе ПДН произошла ошибка`;
    setIsPdnLoading(true);
    try {
      const data = parseResponse({
        defaultError,
        // @ts-ignore
        response: await getPdn(applicationId, pdnDecision, verifiedIncome),
      });
      const result = _.round(data / 100, 2);
      setPdn(result);
      return result;
    } catch (error) {
      // @ts-ignore
      showError({ defaultError, error: error.message });
      setPdn(null);
      return null;
    } finally {
      setIsPdnLoading(false);
    }
  };

  const { addAmountSplit, amountSplit, editSplit, removeAmountSplit } = useAmountSplit();

  const createGuaranteeLetter = (partnerId:number) => dispatch(createGuaranteeLetterAction({ partnerId }));
  const approve = () => {
    setIsDecisionLoading(true);
    dispatch(approveAction({
      callback: () => setIsDecisionLoading(false),
      id      : verificationId,
      details : {
        apr         : formValues.rate,
        comission   : formValues.comission,
        insuranceFlg: formValues.insurance,
        limit       : formValues.amount,
        term        : formValues.term,
        transfers   : amountSplit,
      },
    }));
  };
  const saveIncome = (callback?:()=>void, hideMessage?:boolean) => dispatch(verifyApplicationIncomeAction({
    applicationId,
    callback,
    hideMessage,
    verifiedIncome,
  }));
  const setDecision = async (decision: { approved: boolean; amount: number; comission: string; comment: string; insurance: boolean; rate: number; term: number; }, callback?: (() => void) | undefined) => {
    setIsDecisionLoading(true);
    const calculatedPdn = await calculatePdn();
    // @ts-ignore
    const setFunc = () => saveIncome(dispatch(setDecisionAction({ id: verificationId, decision, callback })), true);
    // @ts-ignore
    if (calculatedPdn > 0.9) {
      const NOTIFICATION_ID = `pdnWarn`;
      notification.warning({
        key    : NOTIFICATION_ID,
        message: (
          <div>
            <div>{`Внимание! Показатель ПДН > 0,9`}</div>
            <Button
              danger
              onClick={() => {
                // @ts-ignore
                notification.close(NOTIFICATION_ID);
                setFunc();
              }}
              type='primary'
            >
              Верно
            </Button>
          </div>
        ),
        duration: 0,
      });
    } else {
      setFunc();
    }
    if (!callback) setIsDecisionLoading(false);
  };

  const userRoles = useSelector(getRoleNamesSelector);

  const onSave = () => {
    setDecision({ ...formValues, approved: true });
  };

  const onSaveAndSend = () => {
    setDecision({ ...formValues, approved: true }, approve);
  };

  const isAddSplitButtonVisible = hasIntersection(userRoles, [
    ROLE_NAMES.SUPER_ADMIN,
    ROLE_NAMES.PARTNER_EDITOR,
    ROLE_NAMES.PARTNER_VIEWER,
  ])
    && productId === 15;

  const isComissionSelectVisible = hasIntersection(userRoles, [
    ROLE_NAMES.SUPER_ADMIN,
    ROLE_NAMES.VERIFICATION_ADMIN,
    ROLE_NAMES.VERIFICATION_SEND,
  ]);

  const showAmountSplitWarning = !_.isEmpty(amountSplit)
    && _.sum(_.map(amountSplit, ({ amount }) => amount || 0)) !== (formValues.amount || 0);

  const approved = true;
  const amount = _.get(formValues, `amount`);
  const rate = _.get(formValues, `rate`);
  const term = _.get(formValues, `term`);

  const formIncomplete = (!amount || !rate || !term);

  const isButtonsDisabled = isLoading || isDecisionLoading || isPdnLoading || formIncomplete;

  const isSaveButtonDisabled = decisionHasBeenMade;

  const isSendButtonDisabled = useSendButtonDisabled({
    amountSplit,
    approved,
    carDataWarningShow,
    decisionHasBeenMade,
    isResultsComplete,
    showAddSplitButton: isAddSplitButtonVisible,
    showAmountSplitWarning,
  });

  const onFormChange = (formName:string, info: { forms: { [x: string]: { getFieldsValue: () => React.SetStateAction<IFormValues>; }; }; }) => setFormValues(info.forms[formName].getFieldsValue());

  const mobile = useContext(MobileContext);

  let pdnTooltip:string | null;
  if (isLoading) pdnTooltip = `Подождите, идёт загрузка`;
  if (!applicationId) pdnTooltip = `Не удалось получить идентификатор заявки, перезагрузите страницу`;
  if (!verifiedIncome) pdnTooltip = `Не указан актуальный доход`;
  if (!pdnDecision) {
    pdnTooltip = `Не заполнено решение`;
  } else {
    if (!pdnDecision.amount) pdnTooltip = `Не указана сумма`;
    if (!pdnDecision.rate) pdnTooltip = `Не указана ставка`;
    if (!pdnDecision.term) pdnTooltip = `Не указан срок`;
  }

  return (
    <DecisionFormWrapper>
      <Form.Provider onFormChange={onFormChange}>
        <Form
          className='form'
          form={form}
          initialValues={initialValues}
          layout='vertical'
          name='PageClientSingleDecision'
          scrollToFirstError={{ behavior: `smooth` }}
          validateTrigger={[`onChange`, `onBlur`]}
        >

          {/* <Form.Item label='Страховка' name='insurance'> */}
          {/*  <Select */}
          {/*    options={[ */}
          {/*      // @ts-ignore */}
          {/*      { label: `Принудительно включить`, value: true }, */}
          {/*      // @ts-ignore */}
          {/*      { label: `Принудительно выключить`, value: false }, */}
          {/*      { label: `Довериться стратегии`, value: null }, */}
          {/*    ]} */}
          {/*  /> */}
          {/* </Form.Item> */}

          {mobile && (
            <div>
              <div>{amountMin}</div>
              <div>{amountMax}</div>
            </div>
          )}

          <Form.Item
            label='Одобренная сумма, ₽'
            name='amount'
            rules={[
              {
                validator: (pass, v) => {
                  if (!v || v < amountMinRaw) {
                    return Promise.reject(new Error(`Не может быть меньше ${prettifyAmount(amountMinRaw)}`));
                  }
                  if (v > amountMaxRaw) {
                    return Promise.reject(new Error(`Не может быть больше ${prettifyAmount(amountMaxRaw)}`));
                  }
                  if (!_.isInteger(v)) {
                    return Promise.reject(new Error(`Только целочисленные значения`));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber
              formatter={prettifyNumber}
              parser={getNumber}
            />
          </Form.Item>

          {mobile && (
            <div>
              <div>{rateMin}</div>
              <div>{rateMax}</div>
            </div>
          )}

          <Form.Item
            label='Ставка, % годовых'
            name='rate'
            rules={[
              {
                validator: (pass, v) => {
                  if (!v || v < rateMinRaw) return Promise.reject(new Error(`Не может быть меньше ${rateMinRaw}%`));
                  if (v > rateMaxRaw) return Promise.reject(new Error(`Не может быть больше ${rateMaxRaw}%`));
                  if (!_.isInteger(v)) return Promise.reject(new Error(`Только целочисленные значения`));
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          {mobile && (
            <div>
              <div>{termMin}</div>
              <div>{termMax}</div>
            </div>
          )}

          <Form.Item label='Срок, месяцев' name='term'>
            <Select options={
              _.map(
                _.filter(
                  [3, 12, 24, 36, 48, 60, 84],
                  v => v >= termMinRaw && v <= termMaxRaw,
                ),
                v => ({
                  label: `${v} мес.`,
                  value: v,
                }),
              )
            }
            />
          </Form.Item>

          <Form.Item label='Актуальный доход'>
            <IncomeWrapper>
              <InputNumber
                addonAfter={(
                  <DefaultTooltip title='Сохранить доход'>
                    <IncomeSaveButton
                      disabled={!_.isNumber(verifiedIncome)}
                      icon={<SaveOutlined />}
                      // @ts-ignore
                      onClick={saveIncome}
                    />
                  </DefaultTooltip>
                )}
                formatter={prettifyNumberOrEmpty}
                // @ts-ignore
                onChange={setVerifiedIncome}
                // @ts-ignore
                parser={getNumberOrEmpty}
                value={verifiedIncome}
              />
            </IncomeWrapper>
          </Form.Item>

          {isComissionSelectVisible && (
            <Form.Item label='Комиссия' name='comission'>
              <Select options={COMISSIONS} />
            </Form.Item>
          )}

          <Form.Item label='Комментарий' name='comment'>
            <Input.TextArea />
          </Form.Item>

          {mobile && (
            <div>
              <div>До 0,5 для 90% займов</div>
              <div><Red>До 0,8</Red> для 10% займов</div>
            </div>
          )}
          <FormRowCalculatePdn
            calculatePdn={calculatePdn}
            isLoading={isPdnLoading}
            // @ts-ignore
            pdn={pdn}
            // @ts-ignore
            tooltip={pdnTooltip}
          />

          {!_.isEmpty(amountSplit) && _.map(amountSplit, (item, index) => (
            <AmountSplit
              // @ts-ignore
              amount={item.amount}
              createGuaranteeLetter={createGuaranteeLetter}
              dealershipId={dealershipId}
              index={index}
              key={index}
              // @ts-ignore
              partnerRequisiteId={item.partnerRequisiteId}
              remove={removeAmountSplit}
              set={editSplit}
            />
          ))}

          {showAmountSplitWarning && (
            <Warning message='Разбитая сумма не равна общей сумме' />
          )}

          {hasIntersection(userRoles, [`superAdmin`, `verificationSend`, `verificationWrite`]) && (
            <Form.Item
              className='form-controls form-controls_center'
            >
              <Space>
                {isAddSplitButtonVisible && (
                  <Button
                    className='form-controls-item'
                    onClick={addAmountSplit}
                  >
                    Добавить разбивку суммы
                  </Button>
                )}

                <Button
                  className='form-controls-item'
                  disabled={isButtonsDisabled || isSaveButtonDisabled}
                  htmlType='submit'
                  icon={<SaveOutlined />}
                  onClick={() => form.validateFields().then(onSave)}
                  type='primary'
                >
                  Сохранить
                </Button>

                {isAbleToSend && (
                  <Popconfirm
                    disabled={isButtonsDisabled || isSendButtonDisabled}
                    okText='Одобрить'
                    onConfirm={() => form.validateFields().then(onSaveAndSend)}
                    title={(
                      <>
                        <p>Одобрить займ?</p>
                        <p>{`Одобренная сумма, ₽ - ${prettifyNumber(formValues.amount)}`}</p>
                        <p>{`Ставка, % годовых - ${formValues.rate}`}</p>
                        <p>{`Срок, месяцев - ${formValues.term}`}</p>
                      </>
                    )}
                  >
                    <Button
                      className='form-controls-item'
                      disabled={isButtonsDisabled || isSendButtonDisabled}
                      htmlType='submit'
                      icon={<SendOutlined />}
                      type='primary'
                    >
                      Сохранить и отправить
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            </Form.Item>
          )}
        </Form>
      </Form.Provider>
    </DecisionFormWrapper>
  );
};
