import React from 'react';

import { TDictionaryItem } from 'models/dictionaries/types';
import { TEventlogItem } from 'models/eventlogs/types';

import useMobileContext from 'hooks/useMobileContext';

import { SwapOutlined } from '@ant-design/icons';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import { ItemHead } from 'components/Eventlog/Item/ItemHead';

import {
  Arrow,
  Content,
  Description,
  Icon,
  Wrapper,
} from './styled';

interface IComponentProps extends TEventlogItem {
  date:string;
  operatorName?:string;
  statusNext?: TDictionaryItem;
  statusPrev?: TDictionaryItem;
}

export const Status: React.FC<IComponentProps> = ({ date, operatorName, statusNext, statusPrev }) => {
  const { mobile } = useMobileContext();

  let color = `orange`;
  let title = `Изменён статус`;

  if (!statusPrev) {
    color = `green`;
    title = `Добавлен статус`;
  }

  if (!statusNext) {
    color = `red`;
    title = `Удалён статус`;
  }

  return (
    <Wrapper $isMobile={mobile}>
      <Icon $color={color}><SwapOutlined /></Icon>
      <Content>
        <ItemHead date={date} operatorName={operatorName} title={title} />
        <Description>
          {statusPrev && (
            <>
              <DefaultTag color={statusPrev.color}>{statusPrev.description}</DefaultTag>
              <Arrow />
            </>
          )}
          {statusNext && (
          <DefaultTag color={statusNext.color}>{statusNext.description}</DefaultTag>
          )}
        </Description>
      </Content>
    </Wrapper>
  );
};
