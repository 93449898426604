import {
  lighten,
  transparentize,
} from 'polished';
import styled, { css } from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { Tag } from 'antd';

const tagColorMixin = (baseColor: string) => css`
  color: ${baseColor};
  border-color: ${transparentize(0.5, lighten(0.2, baseColor))};
  background-color: ${transparentize(0.9, lighten(0.2, baseColor))};
`;

export const DefaultTag = styled(Tag)<{ $isPointer?: boolean; }>`
  &.ant-tag {
    border-color: transparent;
    padding: 0 8px;
    line-height: 16px;


    .ant-tag-close-icon {
      color: ${palette.textSecondaryColor};

      :hover {
        color: ${palette.textPrimaryColor};
      }
    }
  }


  &.ant-tag-cyan {
    ${tagColorMixin(`#08979c`)};
  }

  &.ant-tag-green {
    ${tagColorMixin(`#389e0d`)};
  }

  &.ant-tag-red {
    ${tagColorMixin(`#cf1322`)};
  }

  &.ant-tag-orange {
    ${tagColorMixin(`#d46b08`)};
  }

  &.ant-tag-blue {
    ${tagColorMixin(`#096dd9`)};
  }

  &.ant-tag-magenta {
    ${tagColorMixin(`#c41d7f`)};
  }

  &.ant-tag-success {
    color: ${palette.primary600Color};
    background-color: ${palette.primary5Color};
  }

  &.ant-tag-warning {
    color: ${palette.secondary600Color};
    background-color: ${palette.secondary5Color};
  }

  &.ant-tag-error {
    ${tagColorMixin(`#ff4d4f`)};
  }

  &.ant-tag-default {
    color: ${palette.black80Color};
    background-color: ${palette.black10Color};
  }

  &.ant-tag-processing {
    ${tagColorMixin(`#40BAB5`)};
  }

  &.ant-tag-purple {
    ${tagColorMixin(`#531dab`)};
  }

  &.ant-tag-gold {
    ${tagColorMixin(`#d48806`)};
  }

  &.ant-tag-lime {
    ${tagColorMixin(`#7cb305`)};
  }

  &.ant-tag-geekblue {
    ${tagColorMixin(`#1d39c4`)};
  }

  &.ant-tag-volcano {
    ${tagColorMixin(`#d4380d`)};
  }

  &.ant-tag-pink {
    ${tagColorMixin(`#c41d7f`)};
  }

  @media(min-width: ${breakpoints.md}) {
    &.ant-tag {
      padding: 4px 12px;
      line-height: 20px;
    }
  }

  ${({ $isPointer }) => $isPointer && `
    cursor: pointer;
  `}
`;
