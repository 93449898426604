import { createAction } from 'redux-actions';

export const model = `lead`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const ASSIGN = `ASSIGN`;
export const CREATE = `CREATE`;
export const GET = `GET`;
export const GET_FILTERS = `GET_FILTERS`;
export const GET_MULTIPLE = `GET_MULTIPLE`;
export const GET_SINGLE = `GET_SINGLE`;
export const EXPORT_EXCEL = `EXPORT_EXCEL`;
export const REASSIGN = `REASSIGN`;
export const REDIRECT_AND_SEARCH = `REDIRECT_AND_SEARCH`;
export const REMOVE = `REMOVE`;
export const RESET = `RESET`;
export const SET = `SET`;
export const SET_LOADING = `SET_LOADING`;
export const SET_OPERATOR = `SET_OPERATOR`;
export const SET_OPERATOR_MULTIPLE = `SET_OPERATOR_MULTIPLE`;
export const SET_STATUS = `SET_STATUS`;
export const SET_STATUS_MULTIPLE = `SET_STATUS_MULTIPLE`;
export const SET_TABLE_STATE = `SET_TABLE_STATE`;
export const UPDATE = `UPDATE`;
export const UPLOAD_FILE = `UPLOAD_FILE`;

export const assign = ca(ASSIGN);
export const create = ca(CREATE);
export const exportExcel = ca(EXPORT_EXCEL);
export const get = ca(GET);
export const getFilters = ca(GET_FILTERS);
export const getMultiple = ca(GET_MULTIPLE);
export const getSingle = ca(GET_SINGLE);
export const reassign = ca(REASSIGN);
export const remove = ca(REMOVE);
export const reset = ca(RESET);
export const set = ca(SET);
export const setLoading = ca(SET_LOADING);
export const setOperator = ca(SET_OPERATOR);
export const setOperatorMultiple = ca(SET_OPERATOR_MULTIPLE);
export const setStatus = ca(SET_STATUS);
export const setStatusMultiple = ca(SET_STATUS_MULTIPLE);
export const setTableState = ca(SET_TABLE_STATE);
export const update = ca(UPDATE);
export const uploadFile = ca(UPLOAD_FILE);
