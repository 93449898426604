import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment, { Moment } from 'moment/moment';
import styled from 'styled-components';

import {
  getFullNameInitials,
  getNumber,
  inputNumberFormatter,
  inputNumberParser,
  normalizeMomentToStartOfDay,
  validateEmail,
  validateInnPhysical,
  validatePassportNumber,
  validatePassportSerial,
  validatePhone,
} from 'helper';

import { getClientSurname as getClientSurnameSelector } from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';
import { getItemsForSelect as getDictionaryOptions } from 'models/dictionaries/selectors';
import { exportClaimStatements } from 'models/task/actions';
import {
  CLAIM_STATEMENTS_DOC_IDS_WITH_IMHA_ONLY,
  CLAIM_STATEMENTS_DOC_IDS_WITH_THIRDPARTY,
} from 'models/task/constants';
import { isLoading as isLoadingTaskSelector } from 'models/task/selectors';
import { getCurrent as getCurrentUserSelector } from 'models/user/selectors';

import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
} from 'antd';

const StyledInputNumber = styled(InputNumber)`width: 200px;`;

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

interface IProps {
  documentTemplateId: number,
  documentTypeId: number,
  isSkipModal: boolean,
  onClose: () => void,
  personId: string,
  taskId: number,
  visible: boolean
}

interface IValuesStep1 {
  addToRegistry: `new` | `current` | `off`,
  imhaFine : number | null,
  isImhaOnly?: boolean,
  managerEmail : string,
  managerFullName: string,
  managerInt : string,
  managerPhone: string,
  natureFlg: boolean,
  newOwnerFlg : boolean,
  payedFee : number | null,
  registryTypeId : number | null,
  tpAddress : string,
  tpBirthDtm : moment.Moment | null,
  tpBirthPlace : string,
  tpFio : string,
  tpInn : string,
  tpPassportNumber : string,
  tpPassportSerial : string,
}

const defaultValuesStep1: IValuesStep1 = {
  imhaFine        : null,
  isImhaOnly      : false,
  payedFee        : null,
  managerFullName : ``,
  managerEmail    : ``,
  managerPhone    : ``,
  managerInt      : ``,
  natureFlg       : false,
  newOwnerFlg     : false,
  tpFio           : ``,
  tpBirthDtm      : null,
  tpPassportSerial: ``,
  tpPassportNumber: ``,
  tpBirthPlace    : ``,
  tpInn           : ``,
  tpAddress       : ``,
  addToRegistry   : `new`,
  registryTypeId  : null,
};

const registryOptions = [
  { value: `new`, label: `Создать новый` },
  { value: `current`, label: `В текущий` },
  { value: `off`, label: `Не добавлять` },
];

type IErrors = { [key: string]: string };

const validateForm = (values: IValuesStep1) => {
  const errors: IErrors = {};

  if (values.isImhaOnly) return errors;

  if (!values.managerFullName.length) errors.managerFullName = `Обязательно для заполнения`;
  if (!validateEmail(values.managerEmail)) errors.managerEmail = `Некорректный email`;
  if (!values.managerInt.length) errors.managerInt = `Обязательно для заполнения`;

  if (values.managerPhone && !validatePhone(values.managerPhone)) {
    errors.managerPhone = `Некорректный телефон`;
  }

  if (values.newOwnerFlg) {
    if (!values.tpFio.length) errors.tpFio = `Обязательно для заполнения`;
    if (!values.tpBirthDtm) errors.tpBirthDtm = `Обязательно для заполнения`;
    if (!validatePassportSerial(values.tpPassportSerial)) errors.tpPassportSerial = `Некорректный номер паспорта`;
    if (!validatePassportNumber(values.tpPassportNumber)) errors.tpPassportNumber = `Некорректная серия паспорта`;
    if (!values.tpBirthPlace.length) errors.tpBirthPlace = `Обязательно для заполнения`;
    if (!validateInnPhysical(values.tpInn)) errors.tpInn = `Обязательно для заполнения`;
    if (!values.tpAddress.length) errors.tpAddress = `Обязательно для заполнения`;
  }

  return errors;
};

export const LegalClaimStatementCompose: React.FC<IProps> = ({
  documentTemplateId,
  documentTypeId,
  isSkipModal,
  onClose,
  personId,
  taskId,
  visible,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUserSelector);
  const [step, setStep] = useState<1 | 2>(1);
  const [values, setValues] = useState(defaultValuesStep1);
  const isTaskLoading = useSelector(isLoadingTaskSelector);
  const registryTypes = useSelector(state => getDictionaryOptions(state, `registryType`));
  const clientSurname:string = useSelector(state => getClientSurnameSelector(state, personId));
  const [isDictionaryLoading] = useDictionaries([`registryType`]);

  const setValue = (
    field: string,
    data: ChangeEvent<HTMLInputElement> | RadioChangeEvent | Moment | string | number | boolean,
  ) => setValues({
    ...values,
    // @ts-ignore
    [field]: !_.isEmpty(data) && data.target ? (data as ChangeEvent<HTMLInputElement>).target.value : data,
  });

  const handleClose = () => {
    setStep(1);
    onClose();
  };

  useEffect(() => {
    if (!currentUser) return;

    setValues({
      ...values,
      isImhaOnly     : _.includes(CLAIM_STATEMENTS_DOC_IDS_WITH_IMHA_ONLY, documentTypeId),
      managerFullName: currentUser.fullName,
      managerEmail   : currentUser.email,
      managerPhone   : currentUser.phone ? currentUser.phone : ``,
      managerInt     : getFullNameInitials(currentUser),
    });

    if (isSkipModal && visible) {
      setStep(2);
    }
  }, [currentUser.id, isSkipModal, documentTemplateId, documentTypeId, visible]);

  const onSubmit = () => {
    dispatch(exportClaimStatements({
      callback  : handleClose,
      clientSurname,
      documentTemplateId,
      documentTypeId,
      taskId,
      ...values,
      imhaFine  : getNumber(values.imhaFine),
      payedFee  : getNumber(values.payedFee),
      tpBirthDtm: normalizeMomentToStartOfDay(values.tpBirthDtm)?.valueOf() || null,
    }));
  };

  const errors = validateForm(values);

  if (step === 1 && isSkipModal) return null;

  if (step === 1) {
    return (
      <Modal
        cancelText='Отмена'
        centered
        okButtonProps={{ disabled: !_.isEmpty(errors) }}
        okText='Далее'
        onCancel={onClose}
        onOk={() => setStep(2)}
        open={visible}
        title='Формирование документа'
      >
        <Form layout='vertical'>
          {_.includes(CLAIM_STATEMENTS_DOC_IDS_WITH_IMHA_ONLY, documentTypeId) && (
            <Form.Item hidden name='isImhaOnly' />
          )}
          <Form.Item label='Сумма за ИМХА'>
            <StyledInputNumber
              formatter={inputNumberFormatter}
              min={0}
              // @ts-ignore
              onChange={event => setValue(`imhaFine`, event)}
              parser={inputNumberParser}
              placeholder='Сумма, ₽'
              precision={2}
              value={values.imhaFine}
            />
          </Form.Item>
          {!_.includes(CLAIM_STATEMENTS_DOC_IDS_WITH_IMHA_ONLY, documentTypeId) && (
            <>
              <Form.Item label='Уплаченная пошлина'>
                <StyledInputNumber
                  formatter={inputNumberFormatter}
                  min={0}
                  // @ts-ignore
                  onChange={event => setValue(`payedFee`, event)}
                  parser={inputNumberParser}
                  placeholder='Сумма, ₽'
                  precision={2}
                  value={values.payedFee}
                />
              </Form.Item>
              <Form.Item
                label='ФИО сотрудника'
                required
                validateStatus={errors.managerFullName ? `error` : `success`}
              >
                <Input
                  onChange={event => setValue(`managerFullName`, event)}
                  value={values.managerFullName}
                />
              </Form.Item>
              <Form.Item
                label='Почта сотрудника'
                required
                validateStatus={errors.managerEmail ? `error` : `success`}
              >
                <Input
                  onChange={event => setValue(`managerEmail`, event)}
                  value={values.managerEmail}
                />
              </Form.Item>
              <Form.Item
                label='Телефон сотрудника'
                validateStatus={errors.managerPhone ? `error` : `success`}
              >
                <Input
                  onChange={event => setValue(`managerPhone`, event)}
                  value={values.managerPhone}
                />
              </Form.Item>
              <Form.Item
                label='Фамилия инициалы сотрудника'
                required
                validateStatus={errors.managerInt ? `error` : `success`}
              >
                <Input
                  onChange={event => setValue(`managerInt`, event)}
                  value={values.managerInt}
                />
              </Form.Item>
              <Form.Item>
                <Checkbox
                  checked={values.natureFlg}
                  onChange={event => setValue(`natureFlg`, event.target.checked)}
                >
                  ТС в натуре
                </Checkbox>
              </Form.Item>

              {_.includes(CLAIM_STATEMENTS_DOC_IDS_WITH_THIRDPARTY, documentTypeId) && (
                <>
                  <p>Данные третьей стороны (соответчика/нового владельца)</p>
                  <Form.Item>
                    <Checkbox
                      checked={values.newOwnerFlg}
                      onChange={event => setValue(`newOwnerFlg`, event.target.checked)}
                    >
                      Новый владелец
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    label='ФИО (именительный)'
                    required={values.newOwnerFlg}
                    validateStatus={errors.tpFio ? `error` : `success`}
                  >
                    <Input
                      disabled={!values.newOwnerFlg}
                      onChange={event => setValue(`tpFio`, event)}
                      value={values.tpFio}
                    />
                  </Form.Item>
                  <Form.Item
                    label='День рождения'
                    required={values.newOwnerFlg}
                    validateStatus={errors.tpBirthDtm ? `error` : `success`}
                  >
                    <DatePicker
                      disabled={!values.newOwnerFlg}
                      onChange={value => setValue(`tpBirthDtm`, value)}
                      value={values.tpBirthDtm}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Серия паспорта'
                    required={values.newOwnerFlg}
                    validateStatus={errors.tpPassportSerial ? `error` : `success`}
                  >
                    <Input
                      disabled={!values.newOwnerFlg}
                      onChange={event => setValue(`tpPassportSerial`, event)}
                      value={values.tpPassportSerial}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Номер паспорта'
                    required={values.newOwnerFlg}
                    validateStatus={errors.tpPassportNumber ? `error` : `success`}
                  >
                    <Input
                      disabled={!values.newOwnerFlg}
                      onChange={event => setValue(`tpPassportNumber`, event)}
                      value={values.tpPassportNumber}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Место рождения'
                    required={values.newOwnerFlg}
                    validateStatus={errors.tpBirthPlace ? `error` : `success`}
                  >
                    <Input
                      disabled={!values.newOwnerFlg}
                      onChange={event => setValue(`tpBirthPlace`, event)}
                      value={values.tpBirthPlace}
                    />
                  </Form.Item>
                  <Form.Item
                    label='ИНН'
                    required={values.newOwnerFlg}
                    validateStatus={errors.tpInn ? `error` : `success`}
                  >
                    <Input
                      disabled={!values.newOwnerFlg}
                      onChange={event => setValue(`tpInn`, event)}
                      value={values.tpInn}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Адрес'
                    required={values.newOwnerFlg}
                    validateStatus={errors.tpAddress ? `error` : `success`}
                  >
                    <Input
                      disabled={!values.newOwnerFlg}
                      onChange={event => setValue(`tpAddress`, event)}
                      value={values.tpAddress}
                    />
                  </Form.Item>
                </>
              )}
            </>
          )}
        </Form>
      </Modal>
    );
  }

  if (step === 2) {
    return (
      <Modal
        cancelText='Назад'
        centered
        footer={[
          <Button
            key='back'
            loading={isDictionaryLoading || isTaskLoading}
            onClick={() => {
              if (isSkipModal) {
                handleClose();
              } else {
                setStep(1);
              }
            }}
          >
            Назад
          </Button>,
          <Button
            disabled={values.addToRegistry !== `off` && _.isNil(values.registryTypeId)}
            key='submit'
            loading={isDictionaryLoading || isTaskLoading}
            onClick={onSubmit}
            type='primary'
          >
            Сформировать
          </Button>,
        ]}
        onCancel={handleClose}
        open={visible}
        title='Добавление в почтовый реестр'
      >
        <Form layout='vertical'>
          <Form.Item label='Добавить в реестр'>
            <Radio.Group
              onChange={event => setValue(`addToRegistry`, event)}
              options={registryOptions}
              value={values.addToRegistry}
            />
          </Form.Item>
          <Form.Item label='Тип'>
            <StyledSelect
              disabled={values.addToRegistry === `off`}
              // @ts-ignore
              onChange={(value:number) => setValue(`registryTypeId`, value)}
              options={registryTypes}
              placeholder='Выберите тип'
              value={values.registryTypeId}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
};
