export const INFO = [{
  key : `gibdd`,
  name: `ГИБДД`,
}, {
  key : `reestr`,
  name: `ФНП`,
}, {
  key : `rsa`,
  name: `РСА`,
}];
