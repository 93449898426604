import {
  useCallback,
  useState,
} from 'react';
import _ from 'lodash';

import { useDictionaries } from 'models/dictionaries/hooks';

type DocumentTypeId = number | null;

interface IUseUploadDocModal {
  close: () => void;
  documentTypeId: DocumentTypeId;
  isOpen: boolean;
  open: (documentTypeName?: string) => void;
}

export const useUploadDocModal = (): IUseUploadDocModal => {
  const [, dictionaries] = useDictionaries([`documentType`]);

  const [documentTypeId, setDocumentTypeId] = useState<DocumentTypeId>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = useCallback(() => {
    setDocumentTypeId(null);
    setIsOpen(false);
  }, []);

  const open = useCallback((documentTypeName?: string) => {
    if (documentTypeName) {
      // @ts-ignore
      setDocumentTypeId(_.find(dictionaries?.documentType, { name: documentTypeName })?.id);
    }
    setIsOpen(true);
  }, []);

  return {
    documentTypeId,
    isOpen,
    close,
    open,
  };
};
