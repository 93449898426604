import React from 'react';
import _ from 'lodash';
import {
  format as fnsFormat,
  formatDistanceStrict,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  intervalToDuration,
} from 'date-fns';
import locale from 'date-fns/locale/ru';
import ExcelJS from 'exceljs';
import convert from 'heic-convert/browser';
import JSONParseSafe from 'json-parse-safe';
import moment from 'moment';
import qs from 'query-string';

import TAG_COLORS from 'constants/TAG_COLORS';

import {
  Input,
  notification,
} from 'antd';

export const clickedOutsideElement = (_element, event) => {
  let eventTarget = event.target ? event.target : event.srcElement;
  const element = _.has(_element, `current`) ? _element.current : _element;
  while (eventTarget !== null) {
    if (eventTarget === element || !eventTarget) {
      return false;
    }
    eventTarget = eventTarget.offsetParent;
  }

  return true;
};

const fallbackCopyToClipboard = text => {
  const textArea = document.createElement(`textarea`);
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = `0`;
  textArea.style.left = `0`;
  textArea.style.position = `fixed`;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand(`copy`);
  } catch (err) {
    // pass
  }

  document.body.removeChild(textArea);
};

export const copyToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyToClipboard(text);
    notification.success({ message: `Успешно скопировано` });
    return;
  }

  navigator.clipboard.writeText(text);
  notification.success({ message: `Успешно скопировано` });
};

export const isValidDate = d => d && _.isDate(d) && !_.isNaN(_.toNumber(d));

export const validateContractNumber = str => {
  const s = _.trim(str);
  const patterns = [
    /^2[0-9]\/0000\/MFO1\/\d{6}$/gim,
    /^20\/0000\/MFO1\/\d{3}$/gim,
    /^(19|20)\/\d{1,4}$/gim,
    /^20\/(0[1-9]|1[0-2])\/(0[1-9]|[1-9][0-9])\/(9[0-9]{2}|1000)$/gim,
    /^2[0-9]\/\d{2}\/\d{2}\/\d{6}$/gim,
    /^2[0-9]\/\d{2}\/\d{2}\/\d{6}_[1-2]$/gim,
  ];
  return _.some(patterns, pattern => pattern.test(s));
};

export const validateEmail = email => {
  const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return regExp.test(email);
};

export const validatePhone = s => /7\d{10}$/.test(_.replace(s, /\D/gi, ``));

export const validatePhoneOperator = s => /^\+7(\(|\s)?\d{3}(\)|\s)?\d{3}-?\d{2}-?\d{2}(\s\(доб\.\s\d+\))?$/.test(s);

export const validatePhoneSearch = s => /7|8\d{10}/.test(_.replace(s, /\D/gi, ``));

export const validateUuid = s => /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i.test(s);

export const validateUuid4 = s => /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(s);

export const validatePassportNumber = s => /^\d{6}$/.test(s);

export const validatePassportSerial = s => /^\d{4}$/.test(s);

export const validatePassword = password => _.size(password) >= 5;

export const validateSubDivisonCode = string => !!string && /^\d{3}-\d{3}$/gm.test(string);

export const validateVinLength = string => _.includes([11, 17], _.size(string));

export const validateVinSybmols = string => /^[a-zA-Z0-9]+$/gi.test(string);

export const validateInnPhysical = s => /^\d{12}$/.test(_.replace(s, /\D/gi, ``));

export const validateCarNumber = string => /^[abekmhopctyx]{1}[0-9]{3}[abekmhopctyx]{2}[0-9]{2,3}$/i.test(string);

export const hasIntersection = (array1, array2) => !_.isEmpty(_.intersection(array1, array2));

export const prettifyNumber = (value, options = {}) => {
  if (_.isNil(value)) return;
  const number = _.toNumber(value);
  if (_.isNaN(number)) return value;
  return number.toLocaleString(`ru-RU`, options);
};

export const prettifyNumberOrEmpty = (value, options = {}) => {
  if (value === ``) return value;
  return prettifyNumber(value, options);
};

export const prettifyAmount = v => {
  if (v === `` || _.isNil(v)) return ``;
  return `${prettifyNumber(v)} ₽`;
};

export const formatDate = (_date, format = `dd.MM.yyyy HH:mm`) => {
  if (!_date) return ``;
  let date = _date;
  if (!isValidDate(date) && !_.isNumber(date)) date = new Date(date);
  if (!isValidDate(date) && !_.isNumber(date)) return ``;
  return fnsFormat(date, format, { locale });
};

export const formatDateToNow = date => {
  if (!isValidDate(date) && !_.isNumber(date)) return ``;
  return formatDistanceToNow(date, { locale });
};

export const formatDateToNowStrict = (date, opts = {}) => {
  if (!isValidDate(date) && !_.isNumber(date)) return ``;
  return formatDistanceToNowStrict(date, { locale, ...opts });
};

export const formatDurationToMinutes = duration => {
  if (!isValidDate(duration) && !_.isNumber(duration)) return ``;
  return formatDistanceStrict(0, duration, { locale });
};

export const formatDuration = seconds => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  const zeroPad = num => _.padStart(num, 2, `0`);

  return `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`;
};

// export const formatDurationToYears = duration => {
//   if (!_.isDate(duration) && !_.isNumber(duration)) return ``;
//   return formatDistanceStrict(0, duration, { locale });
// };

export const getAvgBy = (data, field) => {
  if (_.isEmpty(data) || !field) return ``;
  const nonZeroValues = _.compact(_.map(data, item => _.toNumber(_.get(item, field))));
  const sum = _.sum(nonZeroValues);

  return _.round(sum / _.size(nonZeroValues));
};

export const JSONParse = (string, defaultValue = null) => _.get(JSONParseSafe(string), `value`, defaultValue);

export const lsSet = (field, value) => {
  if (!field) return;
  localStorage.setItem(field, JSON.stringify(value));
};

export const lsGet = field => {
  if (!field) return;
  return JSONParse(localStorage.getItem(field));
};

export const getSort = (fieldPath, sorter) => (a, c) => (_.isFunction(sorter)
  ? sorter(_.get(a, fieldPath), _.get(c, fieldPath))
  : _.get(a, fieldPath) - _.get(c, fieldPath)
);

export const sortAlphabetically = (a, c) => (a ? _.toString(a) : ``).localeCompare(c ? _.toString(c) : ``);

export const fallBackTableValue = (fallbackField, defaultValue = ``, falsyValue) => (text, item) => {
  const result = text || _.get(item, fallbackField, defaultValue);
  if (!_.isNil(falsyValue) && !result) return falsyValue;
  return result;
};

export const getRandomTagColor = () => _.get(TAG_COLORS, _.random(0, TAG_COLORS.length));

export const b64toBlob = (b64Data, contentType = ``, sliceSize = 512) => {
  try {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
};

export const blobToBase64 = blob => new Promise(resolve => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result);
  reader.readAsDataURL(blob);
});

export const downloadBlob = (blob, fileName = `file`, openInNewWindow = false) => {
  const objUrl = window.URL.createObjectURL(blob);
  if (openInNewWindow) {
    window.open(objUrl, `_blank`);
  } else {
    const link = document.createElement(`a`);
    link.href = objUrl;
    link.setAttribute(`download`, fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(objUrl);
  }
};

export const getFullName = (user = {}) => {
  if (_.isEmpty(user)) return `-`;
  return _.join(_.compact([user.surname, user.name, user.patronymic]), ` `);
};

export const getFullNameInitials = (user = {}, defaultValue = `-`) => {
  if (_.isEmpty(user) || !user.surname || !user.name) return defaultValue;
  return `${user.surname} ${user.name.charAt(0)}.${user.patronymic ? `${user.patronymic.charAt(0)}.` : ``}`;
};

export const getLimitParams = (limit, offset, params) => {
  if (!limit && !offset && _.isEmpty(params)) return ``;
  return `?${qs.stringify(_.omitBy({ limit, offset, ...params }, _.isNil))}`;
};

const getNumberClearStr = numStr => _.replace(_.replace(numStr, /,/gi, `.`), /[^0-9,.]/gi, ``);

export const getNumber = numStr => {
  if (!numStr) return null;
  const clearStr = getNumberClearStr(numStr);
  if (_.isNaN(_.toNumber(clearStr))) return null;
  return _.toNumber(clearStr);
};

export const getNumberOrEmpty = numStr => {
  if (!numStr) return null;
  const clearStr = getNumberClearStr(numStr);
  if (_.isNaN(_.toNumber(clearStr))) return null;
  const number = _.toNumber(clearStr);
  if (clearStr === `0`) return number;
  if (!number) return ``;
  return number;
};

export const normalizeMomentToStartOfDay = v => {
  if (!v || !moment.isMoment(v)) return v;
  if (_.isString(v.creationData().input)) return moment.utc(v.creationData().input, v.creationData().format);
  return v.utc().startOf(`day`);
};

export const normalizePhoneNumber = number => _.replace(number, /\s/g, ``);

export const getCheckedField = (value, type) => {
  if (!value) return `Не указано`;

  switch (type) {
    case `tel`:
      return <a href={`tel:+${value}`}>{`+${value}`}</a>;
    case `email`:
      return <a href={`mailto:${value}`}>{value}</a>;
    default: return value;
  }
};

export const useLazyEffect = (cb, dep) => { // https://github.com/ant-design/pro-blocks/issues/205#issuecomment-752740167
  const initializeRef = React.useRef(false);

  React.useEffect(args => {
    if (initializeRef.current) {
      cb(args);
    } else {
      initializeRef.current = true;
    }
  }, dep); // eslint-disable-line react-hooks/exhaustive-deps
};

export const getInitials = user => ((user?.name || user?.surname)
  ? `${(user?.name || ``).charAt(0)}${(user?.surname || ``).charAt(0)}`
  : `??`
);

export const getSurnameInitials = ({ name, patronymic, surname }) => (surname ? `${surname} ` : ``)
  + (name ? `${name[0]}.` : ``)
  + (patronymic ? `${patronymic[0]}.` : ``);

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const getFileSize = _size => {
  let unit = `b`;
  let size = _size || 0;

  if (size >= (1024 * 1024 * 1024)) {
    unit = `Gb`;
    size = _.floor(size / (1024 * 1024 * 1024), 1);
  } else if (size >= (1024 * 1024)) {
    unit = `Mb`;
    size = _.floor(size / (1024 * 1024), 1);
  } else if (size >= 1024) {
    unit = `Kb`;
    size = _.floor(size / (1024), 1);
  }

  return `${size} ${unit}`;
};

export const getPlural = (size, one, two, five) => {
  const lastDigit = size % 10;
  const secondLastDigit = Math.floor(size / 10) % 10;

  if (secondLastDigit === 1) {
    return five;
  } if (lastDigit === 1) {
    return one;
  } if (lastDigit >= 2 && lastDigit <= 4) {
    return two;
  }
  return five;
};

export const validateFileType = (accept, file) => {
  const acceptArray = _.isArray(accept) ? accept : _.split(accept, `,`);
  const fileNameExtension = `.${_.toLower(_.last(_.split(file.name, `.`)))}`;

  return !!hasIntersection(acceptArray, [file.type, fileNameExtension]);
};

export const getColumn = props => {
  if (_.has(props, `children`)) {
    return {
      ...props,
      children: _.map(props.children, getColumn),
    };
  }
  if (_.isEmpty(props) || !props.key) return;
  return {
    ...props,
    dataIndex: props.dataIndex || props.key,
    title    : _.isNil(props.title) ? props.key : props.title,
  };
};

export const getField = props => {
  if (_.isEmpty(props) || !props.key) return;
  return {
    ...props,
    Component: props.Component || Input,
    title    : _.isNil(props.title) ? props.key : props.title,
  };
};

export const getShortened = (value, length) => (value ? `*${value.slice(value.length - (length || 5))}` : `*`);

export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const fileToBuffer = file => {
  if (!file) return;
  return new Promise(resolve => {
    const reader = new FileReader();
    const readFile = () => {
      const buffer = reader.result;
      resolve(buffer);
    };

    reader.addEventListener(`loadend`, readFile);
    reader.readAsArrayBuffer(file);
  });
};

export const getImgFileDimensions = file => {
  if (!file) return;
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width : img.width,
      });
    };
    img.src = URL.createObjectURL(file);
  });
};

export const convertHeicToJpeg = async heicFile => {
  if (!heicFile) return;
  const fileName = `${_.join(_.dropRight(_.split(heicFile.name, `.`), 1), `.`)}.jpeg`;
  const heicBuffer = await fileToBuffer(heicFile);
  const uint8Array = new Uint8Array(heicBuffer);
  const jpegUint8Array = await convert({
    buffer : uint8Array,
    format : `JPEG`,
    quality: 0.4,
  });
  const jpegBuffer = jpegUint8Array.buffer;
  const jpegBlob = new Blob([jpegBuffer], { type: `image/jpeg` });
  return new File([jpegBlob], fileName, { type: `image/jpeg` });
};

export const getColumnSum = field => data => prettifyNumber(_.round(_.sumBy(data, field), 2));

export const getColumnMax = field => data => prettifyNumber(_.get(_.maxBy(data, field), field));

export const inputNumberFormatter = value => {
  const parts = value.split(`.`);
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ` `);
  const decimalPart = parts[1];
  return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
};

export const inputNumberParser = value => _.replace(
  _.replace(value, /\s/g, ``),
  /,/g,
  `.`,
);

export const showError = ({ defaultError: _defaultError = ``, error: _error, ...notificationProps }) => {
  const defaultError = _.toString(_defaultError);
  const error = _.toString(_error);
  const date = formatDate(new Date());

  console.error(defaultError); // eslint-disable-line no-console
  console.error(error); // eslint-disable-line no-console

  notification.error({
    ...notificationProps,
    message: (
      <>
        {defaultError !== error && <div style={{ marginBottom: `8px` }}>{defaultError}</div>}
        {error && <div style={{ color: `red`, whiteSpace: `pre-wrap` }}>{error}</div>}
        <div style={{ color: `darkgray`, fontSize: `12px` }}>{date}</div>
      </>),
  });
};

export const createExcelBlob = async (data, columns) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Отправленные уведомления`);

    // Устанавливаем столбцы согласно параметру columns
    worksheet.columns = columns;

    // Добавляем данные из массива
    _.each(data, item => {
      worksheet.addRow(item);
    });

    // Создаем Blob из книги
    const blob = await workbook.xlsx.writeBuffer();

    return new Blob([blob], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` });
  } catch (error) {
    showError({ error: `При формировании Excel произошла ошибка` });
  }
};

export const validateCellValue = (value, expectedFormat) => {
  if (expectedFormat === `string`) {
    return _.isString(value) && !!value;
  } if (expectedFormat === `number`) {
    return !_.isNil(value) && (!isNaN(value) || !isNaN(parseFloat(value)));
  } if (expectedFormat === `boolean`) {
    return !_.isNil(value) && (_.isBoolean(value) || [`истина`, `ложь`, `true`, `false`].includes(_.toLower(value)));
  } if (expectedFormat === `date`) {
    return !_.isNil(value) && (_.isDate(value));
  }
  return true;
};
