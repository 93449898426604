import _ from 'lodash';

import { Rule } from 'antd/lib/form';

interface IFieldWatcher {
  fieldToChange: string;
  handler: (newValue: any) => any;
}

export interface ICreateModalFormField {
  Component: any;
  editable?: boolean;
  fieldWatchers?: IFieldWatcher[];
  hidden?: boolean;
  initialValue?: any;
  key: string;
  labelAsChild?: boolean;
  optionLabelProp?: string;
  options?: any;
  optionsSource?: any;
  required?: boolean;
  rules?: Rule[];
  testAttribute?: string;
  title?: any;
  transformInitialValue?: any;
  valuePropName?: string;
  normalizeOnSubmit?(v?:any): any;
  optionsRender?(option: any): any;
}

export const getFormFieldsWithWatchers = (formFields: ICreateModalFormField[]) => _.filter(
  formFields, field => !_.isEmpty(field.fieldWatchers),
);

export const mapFormFieldsByWatchers = (
  formFields: ICreateModalFormField[],
  fieldWatchers: IFieldWatcher[],
  newFieldValue: any,
  fieldKey: string,
) => _.map(formFields, formField => {
  const findFieldWatcher = _.find(fieldWatchers, [`fieldToChange`, formField.key]);

  if (findFieldWatcher) {
    const newValue = _.get(newFieldValue, fieldKey);
    const updatedValues = findFieldWatcher.handler(newValue);

    return { ...formField, ...updatedValues };
  }

  return formField;
});
