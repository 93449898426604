import _ from 'lodash';
import moment from 'moment/moment';
import { v4 } from 'uuid';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { getDataFromPath } from 'models/ui/helpers';

import { notification } from 'antd';

import {
  ADD_ITEM,
  CHECK_MODAL_MINIMIZE,
  CREATE,
  GET_EDITABLE_ID,
  model,
  OPEN_EDIT_MODAL,
  REMOVE_LAST_COMMUNICATION,
  REPLACE_ITEM,
  RESET_MODAL,
  SEARCH,
  SET_EDITABLE_ID,
  SET_FILTERS,
  SET_ITEMS,
  SET_LOADING,
  SET_MODAL,
  SET_SORTER,
  UPDATE,
} from './actions';
import * as api from './api';
import {
  getEditableId as getEditableIdSelector,
  getFilters as getFiltersSelector,
  getItemByPersonId as getItemByPersonIdSelector,
  getModal as getModalSelector,
  getPersonId as getPersonIdSelector,
} from './selectors';

import { INITIAL_MODAL_STATE } from './constants';

const initialState = {
  editableIds: {},
  filters    : {},
  items      : {},
  modal      : {
    ...INITIAL_MODAL_STATE,
  },
  sorter: {
    sortBy   : `createdAt`,
    sortOrder: `desc`,
  },
  isLoading: false,
};

export default {
  namespace    : model,
  state        : initialState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ location }) => {
        const { modalData } = getDataFromPath(location);
        dispatch({
          type   : CHECK_MODAL_MINIMIZE,
          payload: modalData,
        });
        dispatch({
          type   : SET_MODAL,
          payload: modalData,
        });
      });
    },
  },
  effects: {
    *[CHECK_MODAL_MINIMIZE](action, { put, select }) {
      const { personId: personIdNew } = action.payload;
      const { personId: personIdCurrent } = yield select(getModalSelector);
      if (!personIdNew && personIdCurrent && personIdNew !== personIdCurrent) {
        yield put({ type: RESET_MODAL });
      }
    },

    *[CREATE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { item, personId, phone } = action.payload;
      const defaultError = `При создании коммуникации с клиентом c ${personId
        ? `personId '${personId}'`
        : `телефоном ${phone}`} произошла ошибка`;

      try {
        if ((!personId && !phone) || _.isEmpty(item)) throw new Error(defaultError);

        const itemToCreate = _.reduce(item, (result, _value, key) => {
          let value = _value;
          if (_.endsWith(key, `Date`)) value = moment.isMoment(value) ? value.format() : value;
          return {
            ...result,
            [key]: value,
          };
        }, {});

        const createdItem = parseResponse({
          defaultError,
          response: yield call(personId ? api.create : api.createWithPhone, personId || phone, itemToCreate),
        });

        yield put({ type: ADD_ITEM, payload: { personId, item: createdItem } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
        notification.success({ message: `Коммуникация успешно создана` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_EDITABLE_ID](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { personId, uuid } = action.payload;
      const defaultError = `При запросе id коммуникации доступной для редактирования произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);

        const editableId = parseResponse({
          dataPath: `data`,
          defaultError,
          response: yield call(api.getEditableId, personId),
        });

        yield put({ type: SET_EDITABLE_ID, payload: { personId, editableId, uuid } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[OPEN_EDIT_MODAL](action, { put, select, take }) {
      const uuid = v4();
      yield put({ type: SET_LOADING, payload: true });

      const defaultError = `При открытии формы редактирования последней коммуникации произошла ошибка`;

      try {
        const personId = yield select(getPersonIdSelector);
        if (!personId) throw new Error(defaultError);
        // запрашиваем id последней коммуникации
        yield put({ type: GET_EDITABLE_ID, payload: { personId, uuid } });
        yield take(({ payload, type }) => type === `${model}/${SET_EDITABLE_ID}` && payload.uuid === uuid);
        const editableId = yield select(state => getEditableIdSelector(state, personId));
        if (!editableId) throw new Error(defaultError);
        // запрашиваем коммуникации
        yield put({ type: SEARCH, payload: { personId, customFilters: {}, uuid } });
        yield take(({ payload, type }) => type === `${model}/${SET_ITEMS}` && payload.uuid === uuid);
        // выбираем последнюю
        const itemToEdit = yield select(state => getItemByPersonIdSelector(state, personId, editableId));
        if (_.isEmpty(itemToEdit)) throw new Error(defaultError);
        // засовываем в модалку
        const { taskId } = yield select(getModalSelector);
        yield put({
          type   : RESET_MODAL,
          payload: {
            ..._.omitBy(itemToEdit, _.isNil),
            taskId,
            personId,
            mode  : `update`,
            isOpen: true,
          },
        });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE_LAST_COMMUNICATION](action, { call, put, select, take }) {
      const uuid = v4();
      yield put({ type: SET_LOADING, payload: true });

      const defaultError = `При удалении последней коммуникации произошла ошибка`;

      try {
        const personId = yield select(getPersonIdSelector);
        if (!personId) throw new Error(defaultError);
        // запрашиваем id последней коммуникации
        yield put({ type: GET_EDITABLE_ID, payload: { personId, uuid } });
        yield take(({ payload, type }) => type === `${model}/${SET_EDITABLE_ID}` && payload.uuid === uuid);
        const editableId = yield select(state => getEditableIdSelector(state, personId));
        if (!editableId) throw new Error(defaultError);
        parseResponse({
          defaultError,
          response: yield call(api.remove, personId, editableId),
        });
        // запрашиваем коммуникации
        yield put({ type: SEARCH, payload: { personId, customFilters: {}, uuid } });
        yield take(({ payload, type }) => type === `${model}/${SET_ITEMS}` && payload.uuid === uuid);
        notification.success({ message: `Коммуникация успешно удалена` });
        const modalState = yield select(getModalSelector);
        yield put({
          type   : RESET_MODAL,
          payload: {
            personId,
            ..._.omitBy(_.pick(modalState, [`taskId`]), _.isNil),
          },
        });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SEARCH](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { customFilters, personId, uuid } = action.payload;
      const defaultError = `При поиске коммуникаций произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);
        const filters = customFilters || { ...yield select(getFiltersSelector) };
        if (!_.isEmpty(filters.createdAt)) {
          filters.dateFrom = filters.createdAt[0]; // eslint-disable-line
          filters.dateTo = filters.createdAt[1]; // eslint-disable-line
          delete filters.createdAt;
        }
        const items = parseResponse({
          defaultError,
          response: yield call(api.search, personId, filters),
        });

        yield put({ type: SET_ITEMS, payload: { personId, items, uuid } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { item, personId } = action.payload;
      const defaultError = `При редактировании коммуникации с клиентом c personId '${personId}' произошла ошибка`;

      try {
        if (!personId || _.isEmpty(item)) throw new Error(defaultError);

        const itemToUpdate = _.reduce(item, (result, _value, key) => {
          let value = _value;
          if (_.endsWith(key, `Date`)) value = moment.isMoment(value) ? value.format() : value;
          return {
            ...result,
            [key]: value,
          };
        }, {});

        const updatedItem = parseResponse({
          defaultError,
          response: yield call(api.update, personId, itemToUpdate.id, itemToUpdate),
        });

        yield put({ type: REPLACE_ITEM, payload: { personId, item: updatedItem } });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
        notification.success({ message: `Коммуникация успешно отредактирована` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [ADD_ITEM](state, { payload: { item, personId } }) {
      if (!personId || !item) return state;
      return {
        ...state,
        items: {
          ...(state.items || {}),
          [personId]: [
            ...(state.items?.[personId] || []),
            item,
          ],
        },
      };
    },

    [REPLACE_ITEM](state, { payload: { item, personId } }) {
      if (!personId || !item) return state;
      return {
        ...state,
        items: {
          ...(state.items || {}),
          [personId]: _.map(
            (state.items?.[personId] || []),
            stateItem => (stateItem.id === item.id ? item : stateItem),
          ),
        },
      };
    },

    [RESET_MODAL](state, { payload }) {
      return {
        ...state,
        modal: {
          ...INITIAL_MODAL_STATE,
          ...payload,
        },
      };
    },

    [SET_EDITABLE_ID](state, { payload: { editableId, personId } }) {
      return {
        ...state,
        editableIds: {
          ...(state.editableIds || {}),
          [personId]: editableId,
        },
      };
    },

    [SET_FILTERS](state, { payload: filters }) {
      if (!filters) return state;
      return {
        ...state,
        filters,
      };
    },

    [SET_ITEMS](state, { payload: { items, personId } }) {
      if (!personId || !items) return state;
      return {
        ...state,
        items: {
          ...(state.items || {}),
          [personId]: items,
        },
      };
    },

    [SET_LOADING](state, { payload: isLoading = false }) {
      return {
        ...state,
        isLoading,
      };
    },

    [SET_MODAL](state, { payload = {} }) {
      return {
        ...state,
        modal: {
          ...state.modal,
          ...payload,
        },
      };
    },

    [SET_SORTER](state, { payload: sorter }) {
      if (!sorter) return state;
      return {
        ...state,
        sorter: {
          ...state.sorter,
          ...sorter,
        },
      };
    },
  },
};
