import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import {
  getColumn,
  getSort,
  sortAlphabetically,
} from 'helper';

import { EXTERNAL } from 'models/dictionaries/constants';

import { CellControls } from 'pages/PageAdmin/Dictionaries/Plural/components/DictionaryTable/components/CellControls';
import {
  COLUMNS_BY_ENTITY,
  LINKABLE_ENTITIES,
  OMIT_COLUMNS_ENTITIES,
  SELECTABLE_UNREMOVABLE_ENTITIES,
  UNREMOVABLE_ENTITIES,
} from 'pages/PageAdmin/Dictionaries/Plural/constants';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';

interface IComponentProps {
  canEdit: boolean;
  entity: string;
  isLoading: boolean;
  items: any[];
  onReload: () => void;
  onSelectChange: (selectedRowKeys: any[]) => void;
  openLinkModal: () => void;
  selectedRowKeys: any[];
  setItemEditing: (value: any) => void;
  setItemLinking: (value: any) => void;
  toggleCopyModal: (value: any) => void;
  toggleEditModal: () => void;
}

const StyledTable = styled(AdjustableTable)`
    &_entity {
      &_mobileConfig, &_mobileConfigSchema {
        .ant-table-cell {
          vertical-align: top;
        }
      }
    }
`;

export const DictionaryTable: React.FC<IComponentProps> = ({
  canEdit,
  entity,
  isLoading,
  items,
  onReload,
  onSelectChange,
  openLinkModal,
  selectedRowKeys,
  setItemEditing,
  setItemLinking,
  toggleCopyModal,
  toggleEditModal,
}) => {
  const columns = _.map([
    ...(_.has(EXTERNAL, entity)
      ? []
      : [
        {
          key   : `id`,
          sorter: getSort(`id`),
          width : 40,
        },
        {
          key   : `name`,
          title : `Название`,
          sorter: getSort(`name`, sortAlphabetically),
          width : 100,
        },
        {
          key   : `description`,
          title : `Описание`,
          sorter: getSort(`description`, sortAlphabetically),
          width : 100,
        },
      ]),
    // @ts-ignore
    ...(_.reject((COLUMNS_BY_ENTITY[entity] || []),
      // @ts-ignore
      col => !col?.key || _.includes(OMIT_COLUMNS_ENTITIES[entity] || [], col?.key))),
  ], getColumn);

  return (
    <StyledTable
      columns={columns}
      dataSource={items}
      loading={isLoading}
      pagination={{
        hideOnSinglePage: true,
        pageSize        : _.size(items),
        size            : `small`,
      }}
      preferences={{
        path      : `admin.dictionaries.${entity}`,
        cellRender: (value: any, record: { id: any; }) => (
          <CellControls
            canEdit={canEdit}
            entity={entity}
            onCopyClick={() => toggleCopyModal(_.omit(record, [`id`]))}
            onEditClick={() => {
              setItemEditing(record);
              toggleEditModal();
            }}
            onLinkClick={() => {
              // @ts-ignore
              setItemLinking({ id: record.id, link: LINKABLE_ENTITIES[entity] });
              openLinkModal();
            }}
            onReload={onReload}
            record={record}
          />
        ),
      }}
      preferencesWidth={88}
      rowKey='id'
      rowSelection={((canEdit
        && !_.includes(UNREMOVABLE_ENTITIES, entity)) || _.includes(SELECTABLE_UNREMOVABLE_ENTITIES, entity)) ? {
          onChange: onSelectChange,
          selectedRowKeys,
        } : undefined}
      scroll={{ x: `max-content`, y: window.innerHeight - 300 }}
      scrollableHeader
    />
  );
};
