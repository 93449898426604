import {
  useCallback,
  useEffect,
} from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';

import {
  getClientApplications as getClientApplicationsAction,
  getClientPhones as getClientPhonesAction,
  getClientVerifications as getClientVerificationsAction,
} from 'models/clients/actions';
import { getMultiple as getDictionariesAction } from 'models/dictionaries/actions';
import {
  getSingle as getVerificationAction,
  setResults as setVerificationResultsAction,
  updateResults as updateVerificationResultsAction,
} from 'models/verifications/actions';
import { useVerification } from 'models/verifications/hooks/useVerification';
import { IVerification } from 'models/verifications/types';

interface IUseResultProps {
  isDisabled: boolean;
  isLoading: boolean;
  personId: string;
  setIsLoading: (isLoading: boolean) => void;
  verificationId: number;
}

type TUpdateResults = {
  clear?: boolean;
  omitNotification?: boolean;
};

interface IUseResults {
  clearResults: () => void;
  onReload: () => void;
  results: IVerification[`results`];
  setResults: (results: IVerification[`results`]) => void;
  updateResults: (options: TUpdateResults) => void;
}

export const useVerificationResults = ({
  isDisabled,
  isLoading,
  personId,
  setIsLoading,
  verificationId,
}: IUseResultProps): IUseResults => {
  const dispatch = useDispatch();

  const [, verification] = useVerification(verificationId);
  const { results } = verification;

  const getApplications = useCallback(
    callback => dispatch(getClientApplicationsAction({ callback, personId, force: true })),
    [dispatch, personId],
  );

  const getClientPhones = useCallback(() => dispatch(getClientPhonesAction({ personId })), [dispatch, personId]);

  const getClientVerifications = useCallback((callback?: () => void) => dispatch(getClientVerificationsAction({
    callback,
    personId,
    force: true,
  })), [dispatch, personId]);

  const getDictionaries = useCallback(() => dispatch(getDictionariesAction({
    entities: [`documentGroup`, `verificationResult`, `verificationResultType`],
    force   : true,
  })), [dispatch]);

  const getVerification = useCallback(
    (callback = _.noop) => dispatch(getVerificationAction({ callback, id: verificationId })),
    [dispatch, verificationId],
  );

  const setResults = useCallback(
    r => dispatch(setVerificationResultsAction({ results: r, verificationId })),
    [dispatch, verificationId],
  );

  const updateResults = useCallback(
    ({ clear = false, omitNotification = false }) => {
      if (isDisabled) return;
      dispatch(updateVerificationResultsAction({
        clear,
        omitNotification,
        verificationId,
      }));
    }, [dispatch, isDisabled, verificationId],
  );

  const clearResults = useCallback(
    () => updateResults({ clear: true }),
    [updateResults],
  );
  const onReload = useCallback(() => {
    if (isLoading) return;
    setIsLoading(true);
    getClientVerifications();
    getApplications(() => {
      getClientPhones();
      getDictionaries();
      getVerification();
      setIsLoading(false);
    });
  }, [
    getClientVerifications,
    getApplications,
    getClientPhones,
    getDictionaries,
    getVerification,
    isLoading,
  ]);

  useEffect(() => {
    onReload();
  }, [verificationId]);

  return {
    clearResults,
    onReload,
    results,
    setResults,
    updateResults,
  };
};
