import React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { logout as logoutAction } from 'models/user/actions';
import {
  getRolesTitle,
  getSurnameInitials,
} from 'models/user/selectors';

import { LogoutOutlined } from '@ant-design/icons';
import {
  Button,
  Popconfirm,
  Tooltip,
} from 'antd';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;

const LogOutBtn = styled(Button)`
  flex-shrink: 0;
  color: ${p => (p.theme.info.name === `light` ? palette.whiteColor : palette.black60Color)};
`;

const Popover = styled(Tooltip)`
  min-width: 0;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: PT Root UI, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${getLightStyleOr(palette.black80Color, palette.black40Color)};
`;

const StyledLogoutOutlined = styled(LogoutOutlined)`
  color: ${getLightStyleOr(palette.black60Color, palette.black40Color)};
`;
interface IProps {
  className?: string;
  onUserNameClick?: () => void;
}
const SessionControl: React.FC<IProps> = ({ className = ``, onUserNameClick = _.noop }) => {
  const dispatch = useDispatch();

  const userName = useSelector(getSurnameInitials);
  const roles = useSelector(getRolesTitle);

  const logout = () => dispatch(logoutAction());

  return (
    <Wrap className={className}>
      <Popover
        placement='bottomRight'
        title={roles && `Роли: ${roles}`}
      >
        <Button onClick={onUserNameClick} size='small' type='text'>
          {userName}
        </Button>
      </Popover>

      <Popconfirm
        cancelText='Нет'
        okText='Да'
        onConfirm={() => logout()}
        title='Выйти?'
      >
        <LogOutBtn
          icon={<StyledLogoutOutlined />}
          shape='circle'
          size='large'
          title='Выйти'
          type='text'
        />
      </Popconfirm>
    </Wrap>
  );
};

export default SessionControl;
