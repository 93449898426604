import * as React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { CloseOutlined } from '@ant-design/icons';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { IInputAttachment } from 'components/Messenger/types';

const Action = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -4px;
  right: -4px;
  border-radius: 40px;
  border: 1px solid ${palette.black20Color};
  background: ${palette.whiteColor};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;

  :hover {
    border: 1px solid ${palette.alert500Color};
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin-right: 8px;
  width: 64px;
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${Action} {
    transition: all 100ms ease;
    opacity: 1;

    @media(min-width: ${breakpoints.md}) {
      opacity: 0;
    }
  }

  :hover {
    ${Action} {
      opacity: 1;
    }

    img {
      filter: brightness(0.8);
    }
  }
`;

const IconWrapper = styled.div`
  .anticon {
    font-size: 16px;
    color: ${palette.alert500Color};
  }
`;

const ImageWrapper = styled.div`
  background: ${palette.whiteColor};
  border: 1px solid ${palette.black10Color};

  height: 64px;
  width: 64px;
  min-width: 64px;

  transition: all 200ms ease;

  overflow: hidden;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  :hover {
    border: 1px solid ${palette.black20Color};
    background: ${palette.black10Color};
    cursor: zoom-in;
  }
`;

interface IComponentProps {
  file: IInputAttachment;
  onClick?: () => void;
  onRemoveFile: () => void;
  src?: string;
}

export const InputImageThumbnail: React.FC<IComponentProps> = ({ file, onClick, onRemoveFile, src }) => {
  const ref = React.useRef();
  const title = file?.name || ``;

  return (
    <Wrapper
      onClick={onClick}
      // @ts-ignore
      ref={ref}
    >
      <ImageWrapper>
        <img
          alt={title}
          src={src}
        />
      </ImageWrapper>

      <Action onClick={e => {
        e.stopPropagation();
        onRemoveFile();
      }}
      >
        <DefaultTooltip title='Убрать файл'>
          <IconWrapper>
            <CloseOutlined />
          </IconWrapper>
        </DefaultTooltip>
      </Action>
    </Wrapper>
  );
};
