import * as React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { useDictionaries } from 'models/dictionaries/hooks';
import { checkRightByCampaignName as checkRightSelector } from 'models/dictionaries/selectors';
import { ITaskStatus } from 'models/dictionaries/types';
import { setStatus as setStatusAction } from 'models/task/actions';
import { USE_NEXT_STATUS_FIELD_CAMPAIGNS } from 'models/task/constants';
import { getStatusesToSelectFiltered } from 'models/task/helpers';

import { Select } from 'antd';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';

const StatusTag = styled(DefaultTag)`
  border-radius: 8px;
`;

interface IComponentProps {
  campaign: any;
  canEditTask: boolean;
  task: any;
  taskId: number;
}

export const SelectTaskStatus: React.FC<IComponentProps> = ({ campaign, canEditTask, task, taskId }) => {
  const dispatch = useDispatch();

  const isAdmin = useSelector(state => checkRightSelector(state, campaign?.name, `admin`));
  const [isDictionariesLoading, dictionaries] = useDictionaries([`taskStatus`]);
  const taskStatus = _.find(dictionaries?.taskStatus, { id: task.taskStatusId }) as ITaskStatus;

  let statusesToSelect = _.filter(dictionaries.taskStatus, { campaignId: campaign?.id });
  if (_.includes(USE_NEXT_STATUS_FIELD_CAMPAIGNS, campaign?.name) && !_.isEmpty(taskStatus?.possibleNextStatusIds)) {
    statusesToSelect = _.filter(statusesToSelect, ({ id }) => _.includes(taskStatus.possibleNextStatusIds, id));
  }
  if (!isAdmin) {
    statusesToSelect = getStatusesToSelectFiltered({ statusesToSelect, taskStatus });
  }

  const options = _.map(statusesToSelect, item => ({
    value: item.id,
    label: <StatusTag color={item.color}>{item.description || item.name}</StatusTag>,
  }));

  const value = taskStatus ? {
    label: <StatusTag color={taskStatus.color}>{taskStatus.description || taskStatus.name}</StatusTag>,
    value: taskStatus.id,
  } : undefined;

  const handleSelect = (statusId: string) => {
    dispatch(setStatusAction({
      id          : taskId,
      taskStatusId: _.toNumber(statusId),
    }));
  };

  return (
    <Select
      disabled={!canEditTask || _.isEmpty(statusesToSelect) || isDictionariesLoading}
      // @ts-ignore
      onSelect={handleSelect}
      options={options}
      style={{ width: `100%` }}
      value={value}
    />
  );
};
