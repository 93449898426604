import React, {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import TEST_ATTRIBUTES from 'constants/TEST_ATTRIBUTES';

import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  Modal,
  Select,
} from 'antd';
import { DefaultSpin } from 'components/DefaultSpin/DefaultSpin';
import {
  getFormFieldsWithWatchers,
  ICreateModalFormField,
  mapFormFieldsByWatchers,
} from 'components/Modal/Create/helpers';

const Wrapper = styled.div`
  .date-picker {
    &_wide {
      width: 100%;
    }
  }

  .select {
    &_tag {
      .ant{
        &-select-selection-item {
          height: auto;
          white-space: unset;

          &-content {
            white-space: unset;
          }
        }

        &-tag {
          white-space: unset;
        }
      }
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  .ant-row {
    margin-bottom: 0;
  }

  Button {
    :first-child {
      margin-right: 8px;
    }
  }
`;

interface IComponentProps {
  formFields: ICreateModalFormField[];
  isLoading?: boolean;
  item?: any;
  mode?: `update` | `copy` | `create`;
  title: React.ReactNode;
  visible: boolean;
  close(): void;
  create?(valuesToSubmit: any): void;
  getInitialValues?(item: any, mode: `update` | `create`): any;
  getUpdateFields?(item: any, valuesToSubmit: any): void;
  onRef?(ref: React.Ref<FormInstance>): void;
  update?(valuesToSubmit: any): void;
}

const CreateModal: React.FC<IComponentProps> = ({
  close,
  create,
  getInitialValues,
  getUpdateFields,
  isLoading,
  item = {},
  mode = `create`,
  title,
  update,
  visible,
  onRef,
  ...props
}) => {
  const [form] = Form.useForm();

  const [formFields, setFormFields] = useState<ICreateModalFormField[]>([]);
  const [initialValues, setInitialValues] = useState<any>();

  const submit = async () => {
    const values = await form.validateFields();
    const valuesToSubmit = _.pick(values, _.map(formFields, `key`));

    _.each(formFields, ({ key, normalizeOnSubmit }) => {
      if (_.isFunction(normalizeOnSubmit)) valuesToSubmit[key] = normalizeOnSubmit(valuesToSubmit[key]);
    });

    if (_.includes([`create`, `copy`], mode)) {
      // @ts-ignore
      create(valuesToSubmit);
      form.resetFields();
      if (!values.createAnother) {
        close();
      } else {
        form.setFieldsValue({ createAnother: true });
      }
    } else {
      const result = _.isFunction(getUpdateFields)
        ? getUpdateFields(item, valuesToSubmit)
        : { ...valuesToSubmit, id: item.id };

      // @ts-ignore
      update(result);
      form.resetFields();
      close();
    }
  };

  const onCancel = () => {
    form.resetFields();
    close();
  };

  const handleValuesChange = (changedValues: any) => {
    const formFieldsWithWatchers = getFormFieldsWithWatchers(formFields);

    _.forEach(formFieldsWithWatchers, ({ fieldWatchers, key }) => {
      if (_.has(changedValues, key)) {
        // @ts-ignore
        setFormFields(mapFormFieldsByWatchers(formFields, fieldWatchers, changedValues, key));
      }
    });
  };

  useEffect(() => {
    setFormFields(mode === `create`
      ? props.formFields
      : _.filter(props.formFields, ({ editable = true }) => editable));
  }, [props.formFields, mode]);

  useEffect(() => {
    if (!_.isEmpty(props.formFields) && !_.isEmpty(item)) {
      if (getInitialValues && _.isFunction(getInitialValues)) {
        // @ts-ignore
        return setInitialValues(getInitialValues(item, mode));
      }

      return setInitialValues(_.reduce(props.formFields, (result, field) => ({
        ...result,
        [field.key]: mode === `create`
          ? (field.initialValue || ``)
          : (_.isFunction(field.transformInitialValue)
            ? field.transformInitialValue(_.get(item, field.key, field.initialValue || ``))
            : _.get(item, field.key, field.initialValue || ``)),
      }), {}));
    }
  }, [props.formFields, item, getInitialValues, mode]);

  useEffect(() => {
    if (!_.isEmpty(initialValues)) {
      form.setFieldsValue(initialValues);
      const watchers = getFormFieldsWithWatchers(props.formFields);

      if (!_.isEmpty(watchers)) {
        _.forEach(watchers, ({ fieldWatchers, key }) => {
          if (_.has(initialValues, key)) {
            // @ts-ignore
            const mappedFormFields = mapFormFieldsByWatchers(props.formFields, fieldWatchers, initialValues, key);

            setFormFields(mappedFormFields);
          }
        });
      }
    }
  }, [form, props.formFields, initialValues]);

  return (
    <Wrapper>
      <Modal
        centered
        footer={null}
        maskClosable={false}
        onCancel={onCancel}
        open={visible}
        title={title}
      >
        <>
          <Form
            form={form}
            initialValues={{
              createAnother: false,
              ...initialValues,
            }}
            layout='vertical'
            onValuesChange={handleValuesChange}
            // @ts-ignore
            ref={onRef}
            scrollToFirstError={{ behavior: `smooth` }}
            validateTrigger={[`onChange`, `onBlur`]}
          >

            {_.map(formFields, ({
              Component,
              editable, // eslint-disable-line no-unused-vars
              hidden = false,
              initialValue, // eslint-disable-line no-unused-vars
              key,
              labelAsChild,
              optionLabelProp = `name`,
              options,
              optionsRender,
              optionsSource,
              required = false,
              rules,
              testAttribute,
              title: fieldTitle,
              transformInitialValue, // eslint-disable-line no-unused-vars
              valuePropName = `value`,
              fieldWatchers, // eslint-disable-line no-unused-vars
              ...componentProps
            }) => (
              <Form.Item
                data-test={testAttribute}
                hidden={hidden}
                key={key}
                label={labelAsChild ? `` : fieldTitle}
                name={key}
                required={required}
                rules={rules}
                valuePropName={valuePropName}
              >
                {(labelAsChild || options || optionsSource)
                  ? (
                    <Component {...componentProps}>
                      {labelAsChild ? fieldTitle : ``}
                      {// @ts-ignore
                        optionsSource && _.map(props[optionsSource], option => (
                        <Select.Option key={`${optionsSource}_${option.id}`} value={option.id}>
                          {optionsRender ? optionsRender(option) : option[optionLabelProp]}
                        </Select.Option>
                      ))}
                      {options && _.map(_.filter(options, o => o.label || o.value), option => (_.isObjectLike(option)
                        ? (
                          <Select.Option
                            key={option.value}
                            label={option.label}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        )
                        : (
                          <Select.Option key={option} value={option}>
                            {optionsRender ? optionsRender(option) : option}
                          </Select.Option>
                        )))}
                    </Component>
                  ) : <Component {...componentProps} />}
              </Form.Item>
            ))}

            {_.includes([`copy`, `create`], mode) && (
              <div data-test={TEST_ATTRIBUTES.DICTIONARY_ADD_CREATE_ANOTHER_BUTTON}>
                <Form.Item
                  name='createAnother'
                  noStyle
                  valuePropName='checked'
                >
                  <Checkbox>Создать ещё</Checkbox>
                </Form.Item>
              </div>
            )}

            <ButtonsWrapper>
              <Button
                data-test={TEST_ATTRIBUTES.DICTIONARY_ADD_CANCEL_BUTTON}
                onClick={onCancel}
              >
                Отмена
              </Button>
              <Form.Item>
                <Button
                  data-test={TEST_ATTRIBUTES.DICTIONARY_ADD_SAVE_BUTTON}
                  htmlType='submit'
                  onClick={submit}
                  type='primary'
                >
                  Сохранить
                </Button>
              </Form.Item>
            </ButtonsWrapper>
          </Form>

          {isLoading && (
            <DefaultSpin />
          )}
        </>
      </Modal>
    </Wrapper>
  );
};

export default CreateModal;
