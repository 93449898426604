/* eslint-disable max-len */
import _ from 'lodash';
import { AxiosResponse } from 'axios';

import api from 'api/index';

import {
  ClaimStatementArgs,
  ExportCourtNotificationsArgs,
  IGenerateLegalDocumentData,
  ILastTouch,
  IUpdateData,
  OtherClaimStatementArgs,
} from 'models/task/types';

export const create = (data: any) => api.put(`/tasks/create`, data);
// @ts-ignore
export const exportExcel = ({ ids, tableState }) => api.post(`/tasks/export`, { ...tableState, ids }, {
  responseType: `blob`,
});
export const search = (tableState: any) => api.post(`/tasks/search`, { ...tableState });
export const getFilters = () => api.get(`/tasks/filters`);
export const getSingle = (id: string, force = false) => api.get(`/tasks/task/${id}?force=${force}`);
export const setStatus = (id: any, taskStatusId: number) => api.post(`/tasks/task/${id}/status`, { taskStatusId });
export const setStatusMultiple = (collectionTaskIds: number[], taskStatusId: number) => api.post(`/tasks/task/status`, { collectionTaskIds, taskStatusId });
export const setSearchPartner = (id: number, searchPartnerId: string) => api.post(`/tasks/task/${id}/searchPartner`, { searchPartnerId });
export const setSearchPartnerMultiple = (collectionTaskIds: number[], searchPartnerId: string) => api.post(`/tasks/task/searchPartner`, { collectionTaskIds, searchPartnerId });
export const setOperator = (id: number, {
  operatorId,
  userId,
}: { operatorId: number, userId: number }) => api.post(`/tasks/task/${id}/operator`, { operatorId, userId });
export const terminate = (collectionTaskIds: number[]) => api.post(`/tasks/terminate`, { collectionTaskIds });
export const deleteTasks = (taskIds: number[]) => api.post(`/tasks/delete`, { taskIds });

export const uploadFile = (file: File) => {
  const formData = new FormData();

  formData.append(`file`, file, file.name);

  return api.post(`/tasks/upload`, formData, { headers: { 'Content-Type': `multipart/form-data` } });
};

export const exportCourtNotifications = (query: ExportCourtNotificationsArgs) => api.post(`/proxy/f/legal/notification`, query);

export const composeClaimStatement = (query: ClaimStatementArgs) => api.post(`/proxy/f/legal/claim`, query, {
  validateStatus: status => !!status,
});

export const composeOtherClaimStatement = (query: OtherClaimStatementArgs) => api.post(`/proxy/f/legal/claim/other`, query, {
  validateStatus: status => !!status,
});

export const uploadLegalRegistry = (data: any) => {
  const formData = new FormData();
  _.each(data, (value, key) => {
    formData.append(key, value);
  });

  return api.post(`/proxy/f/legal/save-registry`, formData, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
};
export const getLoanInsurance = (applicationId: string) => api.get(`/proxy/loan/insurance/${applicationId}`);

export const generateLegalDocument = (data: IGenerateLegalDocumentData) => api.post(`/proxy/document/legal/generate`, data);

export const update = (id: number, data: IUpdateData) => api.patch(`/tasks/task/${id}/update`, data);

export const getLastTouch = (personId:string):Promise<AxiosResponse<ILastTouch[]>> => api.get(`/tasks/person/${personId}/lastTouch`);
