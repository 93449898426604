import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { DefaultTable } from 'components/DefaultTable/DefaultTable';

export const Wrap = styled.div<{ $isWrapped: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  @media(min-width: 320px) {
    ${({ $isWrapped }) => $isWrapped && `padding: 24px 16px;`}
  }

  @media(min-width: ${breakpoints.sm}) {
    ${({ $isWrapped }) => $isWrapped && `padding: 24px 16px;`}
  }

  @media(min-width: ${breakpoints.md}) {
    ${({ $isWrapped }) => $isWrapped && `padding: 24px 40px;`}
  }

  @media(min-width: ${breakpoints.lg}) {
    ${({ $isWrapped }) => $isWrapped && `padding: 24px 60px;`}
  }
`;

export const Title = styled.div`
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
  color: ${palette.textPrimaryColor};
`;

export const Table = styled(DefaultTable)`
  border: 1px solid ${palette.borderAccentColor};
`;
