import * as React from 'react';
import _ from 'lodash';
// @ts-ignore
import Linkify from 'react-linkify';
import styled from 'styled-components';

import { LONG_MESSAGE_TRESHOLD } from 'components/Messenger/constants';

const Wrapper = styled.div<{ hasFiles?: boolean }>`
  margin-bottom: ${({ hasFiles }) => (hasFiles ? `8px` : `0px`)};
`;

interface IComponentProps {
  hasFiles: boolean;
  isCollapsed: boolean;
  rawText: string;
}

export const MessageText: React.FC<IComponentProps> = ({ hasFiles, isCollapsed, rawText }) => {
  const text = isCollapsed ? rawText : _.truncate(rawText, { length: LONG_MESSAGE_TRESHOLD });

  return (
    <Wrapper hasFiles={hasFiles}>
      <Linkify
        // @ts-ignore
        componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          href={decoratedHref}
          key={key}
          rel='noreferrer'
          target='_blank'
        >
          {decoratedText}
        </a>
      )}
      >
        {text}
      </Linkify>
    </Wrapper>
  );
};
