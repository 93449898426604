import React, { useMemo } from 'react';
import _ from 'lodash';

import { IFedresursItem } from 'models/clients/types';

import { Collapse } from 'antd';
import { CopyIconButton } from 'components/CopyIconButton/CopyIconButton';
import {
  Label,
  Row,
  Value,
} from 'components/Modal/BankruptModal/FedResurs/styled';

interface IFedResursItemView {
  copy?: boolean;
  data: IFedresursItem;
  items?: IFedResursItemView[];
  itemsHeader?: (item: any) => string;
  label?: string;
  path: string;
  shouldCopy?: boolean;
}

interface ISimpleValue {
  copy: boolean;
  label: string;
  value: string;
}

export const SimpleValue:React.FC<ISimpleValue> = ({ copy, label, value }) => (
  <Row>
    <Label>{label}</Label>
    <Value>{value}</Value>
    {copy && <CopyIconButton valueToCopy={value} />}
  </Row>
);

export const FedResursItem: React.FC<IFedResursItemView> = ({
  copy,
  data,
  items,
  itemsHeader,
  label,
  path,
  shouldCopy: _shouldCopy,
}) => {
  const value = path ? _.get(data, path) : data;
  const shouldCopy = useMemo(
    () => _shouldCopy || data?.type === `Сообщение о судебном акте`,
    [_shouldCopy, data?.type],
  );
  if (!value) return null;
  if (_.isArray(items)) {
    return (
      <Collapse>
        {_.isArray(value)
          ? _.map(value, valueItem => (
            <Collapse.Panel header={itemsHeader ? itemsHeader(valueItem) : ``} key={label ?? ``}>
              {_.map(items, (item, index) => (
                <FedResursItem
                  {...item}
                  data={valueItem}
                  key={index}
                  shouldCopy={shouldCopy}
                />
              ))}
            </Collapse.Panel>
          ))
          : (
            <Collapse.Panel header={itemsHeader ? itemsHeader(value) : ``} key={label ?? ``}>
              {_.map(items, (item, index) => (
                <FedResursItem
                  {...item}
                  data={data}
                  key={index}
                  shouldCopy={shouldCopy}
                />
              ))}
            </Collapse.Panel>
          )}

      </Collapse>
    );
  }

  // @ts-ignore
  return <SimpleValue copy={copy && shouldCopy} label={label} value={value} />;
};
