import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment, { Moment } from 'moment/moment';
import styled from 'styled-components';

import {
  inputNumberFormatter,
  inputNumberParser,
} from 'helper';

import { editLegalDead as editLegalDeadAction } from 'models/clients/actions';
import {
  getIsLoading as isLoadingSelector,
  getLegalDeadById,
} from 'models/clients/selectors';

import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
} from 'antd';

const StyledInputNumber = styled(InputNumber)`width: 200px;`;

interface IProps {
  deadId: string | null,
  isVisible: boolean,
  personId: string,
  onClose():void,
  onSuccess():void,
}

interface IValues {
  claimDtm: moment.Moment | null,
  comments: string,
  contractNumber: string,
  courtName: string,
  deathCertificateNumber: string,
  deathDtm: moment.Moment | null,
  inheritanceCaseNumber: string,
  inheritanceDtm: moment.Moment | null,
  receiptInfoDtm: moment.Moment | null,
  totalDebt: number | null,
}

type IErrors = {
  deathDtm?: string,
};

const defaultValues: IValues = {
  deathDtm              : null,
  deathCertificateNumber: ``,
  receiptInfoDtm        : null,
  contractNumber        : ``,
  totalDebt             : null,
  inheritanceDtm        : null,
  inheritanceCaseNumber : ``,
  claimDtm              : null,
  courtName             : ``,
  comments              : ``,
};

const validateForm = (values: IValues) => {
  const errors: IErrors = {};
  if (!values.deathDtm) errors.deathDtm = `Обязательно для заполнения`;
  return errors;
};

export const LegalDeadModal: React.FC<IProps> = ({
  deadId,
  isVisible,
  onClose,
  onSuccess,
  personId,
}) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(defaultValues);
  const isLoading = useSelector(isLoadingSelector);
  const dead = useSelector(state => getLegalDeadById(state, personId, deadId));

  useEffect(() => {
      if (isVisible && dead) {
        setValues({
          deathDtm              : dead.deathDtm ? moment.utc(dead.deathDtm) : null,
          deathCertificateNumber: dead.deathCertificateNumber,
          receiptInfoDtm        : dead.receiptInfoDtm ? moment.utc(dead.receiptInfoDtm) : null,
          contractNumber        : dead.contractNumber,
          totalDebt             : dead.totalDebt,
          inheritanceDtm        : dead.inheritanceDtm ? moment.utc(dead.inheritanceDtm) : null,
          inheritanceCaseNumber : dead.inheritanceCaseNumber,
          claimDtm              : dead.claimDtm ? moment.utc(dead.claimDtm) : null,
          courtName             : dead.courtName,
          comments              : dead.comments,
        });
      }
    },
    [deadId, dead, isVisible],
  );

  const setValue = (field: string, data: ChangeEvent<HTMLInputElement> | Moment | string | boolean) => setValues({
    ...values,
    [field]: !_.isEmpty(data) && _.has(data, `target`) ? (data as ChangeEvent<HTMLInputElement>).target.value : data,
  });

  const handleClose = () => {
    setValues(defaultValues);
    onClose();
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const errors = validateForm(values);

  const onSubmit = () => {
    dispatch(editLegalDeadAction({
      callback: handleSuccess,
      deadId,
      personId,
      ...values,
    }));
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      confirmLoading={isLoading}
      okButtonProps={{ disabled: isLoading }}
      okText='Сохранить'
      onCancel={handleClose}
      onOk={onSubmit}
      open={isVisible}
      title='Дело по смерти заемщика'
    >
      <Form layout='vertical'>
        <Form.Item
          label='Дата смерти'
          required
          validateStatus={errors.deathDtm ? `error` : `success`}
        >
          <DatePicker
            onChange={value => setValue(`deathDtm`, value)}
            value={values.deathDtm}
          />
        </Form.Item>
        <Form.Item label='Реквизиты свидетельства о смерти'>
          <Input
            onChange={event => setValue(`deathCertificateNumber`, event)}
            value={values.deathCertificateNumber}
          />
        </Form.Item>
        <Form.Item label='Дата получения информации'>
          <DatePicker
            onChange={value => setValue(`receiptInfoDtm`, value)}
            value={values.receiptInfoDtm}
          />
        </Form.Item>
        <Form.Item label='Номер договора'>
          <Input
            onChange={event => setValue(`contractNumber`, event)}
            value={values.contractNumber}
          />
        </Form.Item>
        <Form.Item label='Сумма задолженности'>
          <StyledInputNumber
            formatter={inputNumberFormatter}
            min={0}
            // @ts-ignore
            onChange={event => setValue(`totalDebt`, event)}
            parser={inputNumberParser}
            placeholder='Сумма, ₽'
            precision={2}
            value={values.totalDebt}
          />
        </Form.Item>
        <Form.Item label='Примерная дата вступления в наследство'>
          <DatePicker
            onChange={value => setValue(`inheritanceDtm`, value)}
            value={values.inheritanceDtm}
          />
        </Form.Item>
        <Form.Item label='Наследственное дело'>
          <Input
            onChange={event => setValue(`inheritanceCaseNumber`, event)}
            value={values.inheritanceCaseNumber}
          />
        </Form.Item>
        <Form.Item label='Дата подачи иска/претензии'>
          <DatePicker
            onChange={value => setValue(`claimDtm`, value)}
            value={values.claimDtm}
          />
        </Form.Item>
        <Form.Item label='Суд'>
          <Input
            onChange={event => setValue(`courtName`, event)}
            value={values.courtName}
          />
        </Form.Item>
        <Form.Item label='Комментарии'>
          <Input
            onChange={event => setValue(`comments`, event)}
            value={values.comments}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
