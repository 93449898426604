import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { parseResponse } from 'api/helpers';

import { useDictionaries } from 'models/dictionaries/hooks';
import {
  IVerificationResultType,
  IVerificationStatus,
} from 'models/dictionaries/types';
import {
  SALE_SEND,
  SUPER_ADMIN,
} from 'models/roles/constants';
import {
  getSingle as getVerificationAction,
  setStatus as setStatusAction,
} from 'models/verifications/actions';
import { getLastSaleVerificationId } from 'models/verifications/api';
import { SALE_AVAILABLE_STATUS_NAMES } from 'models/verifications/constants';
import {
  useCheckVerificationResultTypeForComplete,
} from 'models/verifications/hooks/useCheckVerificationResultTypeForComplete';
import { useVerification } from 'models/verifications/hooks/useVerification';
import { useVerificationResults } from 'models/verifications/hooks/useVerificationResults';
import { isResultsComplete as isResultsCompleteSelector } from 'models/verifications/selectors';

import { useHasRoles } from 'hooks/useHasRoles';

import {
  Button,
  Empty,
  Modal,
  Spin,
} from 'antd';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import {
  FormTabs as FormTabsDefault,
  LayoutContent,
  StyledLayout,
} from 'pages/PageClient/Single/Decision/Forms/styled';
import { Warnings } from 'pages/PageClient/Single/Decision/Forms/Warnings';
import { CheckList } from 'pages/PageClient/Single/VerificationResult/CheckList/CheckList';
import ApplicationDocumentUploadModal from "components/Modal/ApplicationDocumentUploadModal";

const FormTabs = styled(FormTabsDefault)`
  width: 300px;
  position: relative;
  flex: 1;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

const ControlButton = styled(Button)`
  height: 60px;
  width: 100%;
  position: relative;
  padding: 0;
  border: none;

  &+& {
    margin-top: 16px;
  }

  .ant-tag {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

const CheckListWrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  width: 400px;
  margin-left: 24px;

  > * {
    width: 100%;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const CheckListTitle = styled.h3`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: bold;
`;

const CheckListDescription = styled.p`
  margin-bottom: 24px;
  font-size: 14px;
`;

interface SaleFormProps {}

interface SaleFormContentProps {
  personId: string;
  verificationId: number;
}

const SaleFormContent: React.FC<SaleFormContentProps> = ({ personId, verificationId }) => {
  const dispatch = useDispatch();

  const [uploadingDocumentType, setUploadingDocumentType] = useState<null | number>(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [isCheckModalOpen, setIsCheckModalOpen] = useState<boolean>(false);
  // @ts-ignore
  const [verificationStatusIdToChange, setVerificationStatusIdToChange] = useState<number>(null);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);

  const openCheckModal = useCallback(():void => {
    setIsCheckModalOpen(true);
  }, []);

  const closeCheckModal = useCallback(
    ():void => {
      setIsCheckModalOpen(false);
      // @ts-ignore
      setVerificationStatusIdToChange(null);
    }, [],
  );

  const openUploadModal = (uploadingDocType: number | null) => {
    setIsUploadModalOpen(true);
    setUploadingDocumentType(uploadingDocType);
  };
  const closeUploadModal = () => {
    setUploadingDocumentType(null);
    setIsUploadModalOpen(false);
  };

  const [isLoading, verification] = useVerification(verificationId);
  const [, dictionaries] = useDictionaries<{
    verificationResultType: IVerificationResultType[]
    verificationStatus: IVerificationStatus[]
  }>([`verificationStatus`, `verificationResultType`]);
  const verificationStatuses = useMemo(() => _.filter(
    dictionaries?.verificationStatus,
    vs => _.includes(SALE_AVAILABLE_STATUS_NAMES, vs?.name),
  ), [dictionaries?.verificationStatus]);

  const getVerification = useCallback((callback = _.noop) => dispatch(
    getVerificationAction({ id: verificationId, callback }),
  ),
  [dispatch, verificationId]);

  const setVerificationStatus = useCallback((verificationStatusId: number) => {
    if (!verificationStatusId || !verificationId) return;
    dispatch(setStatusAction({
      id      : verificationId,
      verificationStatusId,
      callback: () => getVerification() }));
  }, [dispatch, verificationId]);

  const hasRoles = useHasRoles();
  const isAbleToSend = hasRoles([SUPER_ADMIN, SALE_SEND]);

  const { results, setResults, updateResults } = useVerificationResults({
    isDisabled  : !isAbleToSend,
    isLoading   : isLoadingResults,
    setIsLoading: setIsLoadingResults,
    personId,
    verificationId,
  });

  const checkComplete = useCheckVerificationResultTypeForComplete(results);

  const getVerificationResultTypesByVerificationStatusId = useCallback(
    (verificationStatusId: number): IVerificationResultType[] => _.filter(
      dictionaries?.verificationResultType,
      verificationResultType => _.includes(
        verificationResultType?.saleVerificationStatusIds,
        verificationStatusId,
      ),
    ), [dictionaries?.verificationResultType],
  );

  const verificationResultTypesForNewStatus = useMemo((): IVerificationResultType[] => {
    if (!verificationStatusIdToChange) return [];
    return getVerificationResultTypesByVerificationStatusId(verificationStatusIdToChange);
  }, [verificationStatusIdToChange, getVerificationResultTypesByVerificationStatusId]);

  const requiredVerificationResultTypesForNewStatus = _.filter(
    verificationResultTypesForNewStatus,
    `isRequiredSale`,
  );

  const isSomeRequiredVerificationResultTypesForNewStatusIncomplete = _.some(
    requiredVerificationResultTypesForNewStatus,
    verificationResultType => !checkComplete(verificationResultType?.id),
  );

  const currentVerificationResultTypes = useMemo(() => getVerificationResultTypesByVerificationStatusId(
    verification?.verificationStatusId,
  ), [verification?.verificationStatusId, getVerificationResultTypesByVerificationStatusId]);

  const isDefaultVerificationResultsComplete = useSelector(
    state => isResultsCompleteSelector(state, verificationId),
  );

  const checkVerificationStatus = useCallback((verificationStatusIdToCheck: number) => {
    setVerificationStatusIdToChange(verificationStatusIdToCheck);
    const verificationResultTypesToCheck = getVerificationResultTypesByVerificationStatusId(
      verificationStatusIdToCheck,
    );
    if (_.isEmpty(verificationResultTypesToCheck)) {
      setVerificationStatus(verificationStatusIdToCheck);
      // @ts-ignore
      setVerificationStatusIdToChange(null);
    } else {
      openCheckModal();
    }
  }, [verificationResultTypesForNewStatus]);

  const renderCheckLists = (
    verificationResultTypes: IVerificationResultType[],
    isDisabled?: boolean,
    showRequired?: boolean,
  ) => _.map(
    verificationResultTypes,
    ({ id }) => (
      <CheckList
        isDisabled={isDisabled}
        isLoading={isLoading}
        key={id}
        openUploadModal={openUploadModal}
        results={results}
        setResults={setResults}
        showRequired={showRequired}
        updateResults={updateResults}
        verificationResultTypeId={id}
      />
    ),
  );

  return (
    <StyledLayout>
      <Warnings
        firstPathBlock='sale'
        isResultsComplete={isDefaultVerificationResultsComplete}
        verificationId={verificationId}
      />
      <LayoutContent>
        <ApplicationDocumentUploadModal
          applicationId={verification?.applicationId}
          close={closeUploadModal}
          documentTypeId={uploadingDocumentType}
          isOpen={isUploadModalOpen}
          personId={personId}
        />
        <Spin spinning={isLoading}>
          <FormWrapper>
            <FormTabs>
              <Controls>
                {_.map(verificationStatuses, vs => (
                  <ControlButton
                    disabled={!isAbleToSend || isLoading}
                    key={vs?.id}
                    onClick={() => checkVerificationStatus(vs?.id)}
                  >
                    <DefaultTag
                      color={vs?.color}
                    >{`${vs?.id === verification?.verificationStatusId ? `✅ ` : ``}${vs?.description}`}
                    </DefaultTag>
                  </ControlButton>
                ))}
              </Controls>
            </FormTabs>
            {!_.isEmpty(currentVerificationResultTypes) && (
              <CheckListWrapper>
                <CheckListTitle>Чек-листы</CheckListTitle>
                {renderCheckLists(currentVerificationResultTypes, true)}
              </CheckListWrapper>
            )}
            <Modal
              cancelText='Отмена'
              okButtonProps={{ disabled: isSomeRequiredVerificationResultTypesForNewStatusIncomplete }}
              okText='Сохранить'
              onCancel={closeCheckModal}
              onOk={() => {
                setVerificationStatus(verificationStatusIdToChange);
                closeCheckModal();
              }}
              open={isCheckModalOpen}
            >
              <CheckListWrapper>
                <CheckListTitle>Чек-листы при смене статуса</CheckListTitle>
                <CheckListDescription>Заполните/проверьте перед сменой статуса</CheckListDescription>
                {renderCheckLists(verificationResultTypesForNewStatus, false, true)}
              </CheckListWrapper>
            </Modal>
          </FormWrapper>
        </Spin>
      </LayoutContent>
    </StyledLayout>
  );
};

export const SaleForm: React.FC<SaleFormProps> = () => {
  const { personId, verificationId: contextVerificationId } = useContext(ClientMatchContext) as { personId: string; verificationId: number; };
  const [verificationId, setVerificationId] = useState<number | null>(contextVerificationId);
  const [isVerificationIdLoaded, setIsVerificationIdLoaded] = useState<boolean>(!!contextVerificationId);

  const retrieveLastSaleVerificationId = useCallback(async () => {
    const lastSaleVerificationId = parseResponse({
      dataPath: `data.id`,
      response: await getLastSaleVerificationId(personId),
    });
    setVerificationId(lastSaleVerificationId);
    setIsVerificationIdLoaded(true);
  }, [personId]);

  useEffect(() => {
    if (!verificationId) {
      retrieveLastSaleVerificationId();
    }
  }, [verificationId, personId]);

  if (!verificationId && !isVerificationIdLoaded) {
    return <Spin />;
  }
  if (!verificationId && isVerificationIdLoaded) {
    return <Empty description='Не найдено задач на верификацию в блоке продаж по данному клиенту' />;
  }
  // @ts-ignore
  return <SaleFormContent personId={personId} verificationId={verificationId} />;
};
