import api from 'api';

import {
  ICourtData,
  IEnforcementProceeding,
  ISearchRequest,
  ISearchResponse,
} from './types';

export const get = (searchRequest:ISearchRequest) => api.post<ISearchResponse>(
  `/proxy/f/court/search`,
  searchRequest,
);
export const create = (query: ICourtData) => api.post(`/proxy/hm/court/create`, query);
export const update = (courtId: string, query: ICourtData) => api.post(
  `/proxy/hm/court/update/${courtId}`,
  query,
);

// Enforcement Proceedings
export const createEnforcementProceeding = (enforcementProceeding: IEnforcementProceeding) => api.post(
  `/proxy/hm/enforcement-proceedings/create`,
  enforcementProceeding,
);
export const updateEnforcementProceeding = (enforcementProceeding: IEnforcementProceeding) => api.post(
  `/proxy/hm/enforcement-proceedings/update/${enforcementProceeding.enforcementProceedingsId}`,
  enforcementProceeding,
);
// eslint-disable-next-line max-len
export const deleteEnforcementProceeding = (enforcementProceedingsId: IEnforcementProceeding[`enforcementProceedingsId`]) => api.post(
  `/proxy/hm/enforcement-proceedings/update/${enforcementProceedingsId}`,
  { deleteFlg: true },
);
