/* eslint-disable max-len */
import {
  darken,
  lighten,
  rgba,
  transparentize,
} from 'polished';
import { createGlobalStyle } from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { DefaultDropdownStyles } from 'components/DefaultDropdown/DefaultDropdownStyles';
import { DefaultModalStyles } from 'components/DefaultModal/DefaultModalStyles';

export const GlobalStyles = createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }


  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  b {
    font-weight: bold;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;

    &:before,
    &:after {
      content: '';
      content: none;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html,
  body,
  #app {
    height: 100%;
    background-color: ${palette.backgroundColor};
    --antd-wave-shadow-color: ${palette.primaryColor};
  }

  body {
    width: 100%;
    min-height: 100vh;
    color: black;
    background-color: ${palette.backgroundColor};
    font-family: 'PT Root UI', sans-serif;
    font-size: 16px;
    line-height: 1;
    transition: all 0.50s linear;

    text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${palette.backgroundColor};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: ${getLightStyleOr(
    p => darken(0.2, palette.backgroundColor(p)),
    p => lighten(0.2, palette.backgroundColor(p)),
  )};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${getLightStyleOr(
    p => darken(0.4, palette.backgroundColor(p)),
    p => lighten(0.4, palette.backgroundColor(p)),
  )};
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display:none;
  }

  ::-webkit-scrollbar-corner {
    background: ${palette.backgroundColor};
  }


  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  * {
    outline: none;
  }

  a {
    text-decoration: none;
  }

  .ant {
    &-btn-link {
      color: ${palette.primaryColor} ;

      &:hover {
        color: ${p => darken(0.4, palette.primaryColor(p))};
      }
    }

    &-tag {
      border-radius: 24px ;
      padding: 4px 12px ;
    }


    &-input,
    &-input-affix-wrapper,
    &-select-selector,
    &-picker {
      border-radius: 8px !important;
    }

    &-checkbox {
      &-wrapper {
        white-space: nowrap;
      }

      &-checked {
        .ant-checkbox-inner {
          border-color: ${palette.primaryColor} !important;
          background-color: ${palette.primaryColor} !important;
        }
      }
    }

    &-select {

      &-arrow,
      &-clear {
        color: ${palette.darkBlueColor};
      }

      &-dropdown {
        .ant-tag {
          white-space: pre-wrap ;
        }
      }
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: ${palette.primaryColor};
    border-color: ${palette.primaryColor};
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: ${palette.whiteColor};
  }

  .ant-btn-link[disabled] {
    color: ${getLightStyleOr(palette.black45Color, palette.black40Color)};
    :hover {
      color: ${getLightStyleOr(palette.black45Color, palette.black40Color)};
    }
  }

  .ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: ${p => rgba(palette.darkBlueColor(p), 0.1)};
    background-color: ${palette.darkBlueColor} ;
    color: ${palette.whiteColor};
    border: none ;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: ${palette.primaryColor};
  }


  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${palette.primaryColor};
  }

  a:hover {
    color: ${palette.primaryColor};
  }

  .ant-menu {
    background-color: ${palette.backgroundColor};
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid ${palette.primaryColor} !important;
  }


  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${p => rgba(palette.primaryColor(p), 0.2)};
  }


  .ant-input-affix-wrapper:hover {
    border-color: ${palette.primaryColor};
  }


  .ant-breadcrumb>span {
    a {
      color: ${palette.primaryColor};
    }

    &:last-child {
      color: ${palette.darkBlueColor};
    }
  }



  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${palette.primaryColor} ;
  }

  .ant-tabs-tab:hover,
  .ant-tabs-tab:active,
  .ant-tabs-tab:focus {
    color: ${palette.primaryColor} ;
  }

  .ant-tabs-ink-bar {
    background: ${palette.primaryColor} ;
  }

  .ant-picker-today-btn {
    color: ${palette.primaryColor} ;
  }


  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: ${palette.whiteColor};
    background-color: ${palette.primaryColor} ;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: ${palette.primaryColor} ;
  }

  .ant-picker-focused {
    border-color: ${palette.primaryColor} ;
    box-shadow: 0 0 0 2px ${p => rgba(palette.primaryColor(p), 0.2)};
  }


  .ant-picker:hover,
  .ant-picker-focused,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${palette.primaryColor};
  }

  .ant-picker-panel-container .ant-picker-panels {
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  .ant-dropdown-menu-dark,
  .ant-dropdown-menu-dark .ant-dropdown-menu {
    background-color: ${p => lighten(5, palette.darkBlueColor(p))};
    color: ${palette.whiteColor};
    border-radius: 8px;
  }

  .ant-dropdown-menu-dark .ant-dropdown-menu-item,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item>a,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item>.anticon+span>a {
    color: ${rgba(255, 255, 255, 0.85)};
  }

  .ant-empty-description {
    color: ${getLightStyleOr(palette.black45Color, palette.black40Color)};
  }

  .ant-btn-primary {
    background-color: ${palette.primary500Color};
  }

  a {
    color: ${palette.primary500Color};
  }

  .ant-pagination {
    color: ${getLightStyleOr(palette.black100Color, palette.whiteColor)};

    .ant-pagination-item a {
      color: ${getLightStyleOr(palette.black100Color, palette.whiteColor)};
    }

    .ant-pagination-item-active {
      background: ${palette.backgroundColor};
      border-color: ${palette.primary500Color};

      a {
        color: ${palette.primary500Color};
      }
    }

    .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
      color: ${getLightStyleOr(palette.black40Color, palette.black40Color)};
    }

    .ant-pagination-prev button, .ant-pagination-next button {
      color: ${getLightStyleOr(palette.black100Color, palette.whiteColor)};
    }

    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: ${getLightStyleOr(palette.black40Color, palette.black40Color)};
    }

    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: ${getLightStyleOr(palette.black40Color, palette.black40Color)};
    }

    .ant-select {
      color: ${getLightStyleOr(palette.black100Color, palette.whiteColor)};
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: ${palette.backgroundColor};
      border-color: ${getLightStyleOr(palette.black40Color, palette.black40Color)};
    }
  }


  .ant-checkbox-inner {
    border-color: ${getLightStyleOr(palette.black40Color, palette.black40Color)};
    background-color: ${palette.backgroundColor};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${palette.primary500Color} !important;
    border-color: ${palette.primary500Color} !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: ${palette.primary500Color};
  }

  .ant-spin-container::after {
    background-color: ${palette.backgroundColor};
  }

  .ant-btn {
    background: ${palette.backgroundColor};
    border-color: ${getLightStyleOr(palette.black40Color, palette.black40Color)};
    color: ${getLightStyleOr(palette.black100Color, palette.whiteColor)};

    :hover {
      background: ${palette.backgroundColor};
      color: ${palette.primary500Color};
      border-color: ${palette.primary500Color};
    }

    :active {
      background: ${palette.backgroundColor};
    }

    :focus {
      background: ${palette.backgroundColor};
    }
  }

  .ant-btn-text {
    border-color: transparent;
    background: transparent;
  }

  .ant-btn-primary {
    background: ${palette.primary500Color};
    border-color: ${palette.primary500Color};
    color: ${palette.whiteColor};

    :hover {
      background: ${p => lighten(0.1, palette.primary500Color(p))};
      color: ${palette.whiteColor};
    }

    :active {
      background: ${p => darken(0.1, palette.primary500Color(p))};
    }

    :focus {
      background: ${palette.primary500Color};
    }
  }

  .ant-radio-group {
    .ant-radio-wrapper {
      color: ${palette.textPrimaryColor};
    }

    .ant-radio-button-wrapper {
      background: ${palette.backgroundColor};
      color: ${getLightStyleOr(palette.black100Color, palette.whiteColor)};
      border-color: ${getLightStyleOr(palette.black40Color, palette.black40Color)};
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      background: ${getLightStyleOr(palette.black40Color, palette.black40Color)};
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: ${palette.primary500Color};
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background: ${palette.primary500Color};
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: ${palette.primary500Color};
    }
  }

  .ant-select-dropdown {
     background-color: ${palette.backgroundColor};
    color: ${getLightStyleOr(palette.black100Color, palette.whiteColor)};

    .ant-select-item {
       color: ${getLightStyleOr(palette.black100Color, palette.whiteColor)};
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background: ${getLightStyleOr(palette.black10Color, palette.black10Color)};
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background: ${p => transparentize(0.9, palette.primaryColor(p))};
    }
  }

  ${DefaultDropdownStyles};
  ${DefaultModalStyles};

  .ant-divider {
    &.ant-divider-with-text {
      color: ${palette.textPrimaryColor};
      border-top-color: ${palette.borderDefaultColor};
    }
  }

  .ant-list {
    .ant-list-item {
      color: ${palette.textPrimaryColor};
    }
  }

  .ant-notification {
    .ant-notification-notice {
      background-color: ${palette.backgroundColor};
      .ant-notification-notice-message {
        color: ${palette.textPrimaryColor};
      }

      .ant-notification-notice-close {
        color: ${palette.textSecondaryColor};
        :hover {
          color: ${palette.textPrimaryColor};
        }
      }
    }
  }

  .ant-form {
    color: ${palette.textPrimaryColor};

    .ant-form-item-label > label {
      color: ${palette.textPrimaryColor};
    }
  }

  .ant-checkbox-wrapper {
    color: ${palette.textPrimaryColor};
  }

  .ant-popover {
    .ant-popover-arrow-content::before {
      background-color: ${palette.backgroundColor};
    }

    .ant-popover-inner {
      background-color: ${palette.backgroundColor};

      .ant-popover-title {
        color: ${palette.textPrimaryColor};
        border-color: ${palette.borderDefaultColor};
      }

      .ant-popover-inner-content {
        color: ${palette.textPrimaryColor};
      }

      .ant-popover-message {
        color: ${palette.textPrimaryColor};
      }
    }
  }

  .ant-upload {
    &.ant-upload-drag {
      background: ${getLightStyleOr(
    p => darken(0.02, palette.backgroundColor(p)),
    p => lighten(0.02, palette.backgroundColor(p)),
  )};
    }
  }

  .ant-descriptions {
    .ant-descriptions-title {
      color: ${palette.textPrimaryColor};
    }

    .ant-descriptions-item-content {
      color: ${palette.textPrimaryColor};
    }

    &.ant-descriptions-bordered {
      .ant-descriptions-view {
        border-color: ${palette.borderDefaultColor};
      }

      .ant-descriptions-row {
        border-color: ${palette.borderDefaultColor};
      }

      .ant-descriptions-item-content {
        border-color: ${palette.borderDefaultColor};
      }
    }
  }

  .ant-drawer {
    .ant-drawer-content {
      background-color: ${palette.backgroundColor};

      .ant-drawer-header {
        border-bottom-color: ${palette.borderDefaultColor};

        .ant-drawer-header-title {
          .ant-drawer-close {
            color: ${palette.textSecondaryColor};
          }

          .ant-drawer-title {
            color: ${palette.textPrimaryColor};
          }
        }
      }
    }
  }

  .ant-tabs-dropdown {
    .ant-tabs-dropdown-menu {
      background: ${palette.backgroundColor};

      .ant-tabs-dropdown-menu-item {
        color: ${palette.textPrimaryColor};
      }

      .ant-tabs-dropdown-menu-item-active {
        background: ${palette.black10Color};
      }
    }
  }


  .ant-collapse {
    background-color: ${getLightStyleOr(palette.white100Color, palette.black10Color)};
    border-color: ${palette.borderDefaultColor};

    .ant-collapse-item {
      border-color: ${palette.borderDefaultColor};

      .ant-collapse-header {
        color: ${palette.textPrimaryColor};
      }

      .ant-collapse-content {
        background-color: ${palette.backgroundColor};
        border-color: ${palette.borderDefaultColor};
      }
    }
  }

  .ant-minimized-modal-wrap {
    position: fixed;
    top: unset !important;
    right: unset !important;
    bottom: 0;
    left: 0;
    overflow: hidden;
    outline: 0;
    height: 56px;
    width: 520px;

    .ant-modal-header {
      background-color: ${palette.primary100Color} !important;
      white-space: nowrap;
    }

    .ant-modal-title {
      max-width: calc(100% - 70px);
      white-space: nowrap;
    }
  }
`;
