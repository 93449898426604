import _ from 'lodash';

import {
  getColumnMax,
  getColumnSum,
  prettifyNumber,
} from 'helper';

export const SUMMARY_FIELDS = [
  {
    key   : `loanId`,
    title : ``,
    render: _.noop,
  },
  {
    key   : `carInfo`,
    title : ``,
    render: _.noop,
  },
  {
    key   : `contractNumber`,
    title : ``,
    render: _.noop,
  },
  {
    align : `right`,
    key   : `loanAmount`,
    title : `Сумма`,
    render: data => prettifyNumber(getColumnSum(`loanAmount`)(data)),
  },
  {
    key   : `overdueDebtPaymentCount`,
    title : `Максимум`,
    render: data => getColumnMax(`overdueDebtPaymentCount`)(data),
  },
  {
    align : `right`,
    key   : `totalDebt`,
    title : `Сумма`,
    render: data => getColumnSum(`totalDebt`)(data),
  },
  {
    align : `right`,
    key   : `totalOverdueDebt`,
    title : `Сумма`,
    render: data => getColumnSum(`totalOverdueDebt`)(data),
  },
  {
    align : `right`,
    key   : `totalMainDebt`,
    title : `Сумма`,
    render: data => getColumnSum(`totalMainDebt`)(data),
  },
  {
    align : `right`,
    key   : `overdueMainDebt`,
    title : `Сумма`,
    render: data => getColumnSum(`overdueMainDebt`)(data),
  },
  {
    align : `right`,
    key   : `totalDebtInterest`,
    title : `Сумма`,
    render: data => getColumnSum(`totalDebtInterest`)(data),
  },
  {
    align : `right`,
    key   : `overdueDebtInterest`,
    title : `Сумма`,
    render: data => getColumnSum(`overdueDebtInterest`)(data),
  },
  {
    align : `right`,
    key   : `totalDebtPenalty`,
    title : `Сумма`,
    render: data => getColumnSum(`totalDebtPenalty`)(data),
  },
  {
    align : `right`,
    key   : `totalDebtFine`,
    title : `Сумма`,
    render: data => getColumnSum(`totalDebtFine`)(data),
  },
  {
    align : `right`,
    key   : `totalDebtStateDuty`,
    title : `Сумма`,
    render: data => getColumnSum(`totalDebtStateDuty`)(data),
  },
  {
    align : `right`,
    key   : `totalDebtCommission`,
    title : `Сумма`,
    render: data => getColumnSum(`totalDebtCommission`)(data),
  },
  {
    key   : `overdueDebtTerm`,
    title : `Максимум`,
    render: data => getColumnMax(`overdueDebtTerm`)(data),
  },
  {
    key   : `mainDebtTerm`,
    title : `Максимум`,
    render: data => getColumnMax(`mainDebtTerm`)(data),
  },
  {
    key   : `debtInterestTerm`,
    title : `Максимум`,
    render: data => getColumnMax(`debtInterestTerm`)(data),
  },
  {
    key   : `settings`,
    title : ``,
    render: _.noop,
  },
];

