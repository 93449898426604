import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  getInitials as getInitialsFunc,
  getSurnameInitials as getSurnameInitialsFn,
} from 'helper';

import { ROLE_NAMES } from 'models/roles/constants';

import { model } from './actions'; // eslint-disable-line import/no-cycle

import { INITIAL_STATE } from './constants';

export const getState = state => _.get(state, model, INITIAL_STATE);

export const isLoaded = createSelector(
  getState,
  ({ isLoaded: _isLoaded }) => _isLoaded,
);

export const isLoading = createSelector(
  getState,
  ({ isLoading: _isLoading }) => _isLoading,
);

export const isRefreshTokenLoading = createSelector(
  getState,
  ({ isRefreshTokenLoading: _isRefreshTokenLoading }) => _isRefreshTokenLoading,
);

export const getCurrent = createSelector(
  getState,
  ({ current }) => current || {},
);

export const getFullName = createSelector(
  getCurrent,
  ({ fullName }) => fullName || ``,
);

export const getId = createSelector(
  getCurrent,
  ({ id }) => id,
);

export const getName = createSelector(
  getCurrent,
  ({ name }) => name || ``,
);

export const getSurname = createSelector(
  getCurrent,
  ({ surname }) => surname || ``,
);

export const getInitials = createSelector(
  getName,
  getSurname,
  (name, surname) => getInitialsFunc({ name, surname }),
);

export const getSurnameInitials = createSelector(
  getCurrent,
  getSurnameInitialsFn,
);

export const getRoles = createSelector(
  getCurrent,
  ({ roles }) => roles,
);

export const getRoleNames = createSelector(
  getRoles,
  roles => _.sortBy(_.map(roles, `name`)),
);

export const getRoleIds = createSelector(
  getRoles,
  roles => _.map(roles, `id`),
);

export const getRolesTitle = createSelector(
  getRoleNames,
  roles => _.join(roles, `, `),
);

export const isSuperAdmin = createSelector(
  getCurrent,
  ({ roles = [] }) => _.find(roles, { name: ROLE_NAMES.SUPER_ADMIN }),
);

export const getAll = createSelector(
  getState,
  ({ items }) => items || [],
);

export const getAllByIds = createSelector(
  [getAll, (state, ids) => ids],
  (all, ids) => _.filter(all, ({ id }) => _.includes(ids, id)),
);
