import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
} from 'styles/theme';

export const DecisionFormWrapper = styled.div`
  .ant-form-item-label > label {
    font-weight: 500;
    color: ${palette.textPrimaryColor};
    font-size: 14px;
    line-height: 16px;
  }

  .ant-input-number {
    border-radius: 8px;
    overflow: hidden;
    color: ${palette.textPrimaryColor};
  }

  .ant-input-number-input-wrap {
    background: ${getLightStyleOr(palette.black5Color, palette.black10Color)};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    overflow: hidden;
    color: ${palette.textPrimaryColor};
    background: ${getLightStyleOr(palette.black5Color, palette.black10Color)};
    border-color: ${palette.borderAccentColor};
  }

  .ant-input-number-group-addon {
    background: ${getLightStyleOr(palette.black5Color, palette.black10Color)};
    border-color: ${palette.borderAccentColor};
  }


  textarea {
    background: ${getLightStyleOr(palette.black5Color, palette.black10Color)};
    color: ${palette.textPrimaryColor};
    border-color: ${palette.borderAccentColor};
  }


  .ant-select {
    max-width: 400px !important;
    min-width: 280px;
  }

  .ant-input-number {
    max-width: 400px;
    width: 100%;
    border-color: ${palette.borderAccentColor};
  }

  @media(min-width: ${breakpoints.md}) {
    .ant-select {
      max-width: 360px !important;
    }

    .ant-input-number {
      width: 360px;
    }
  }
`;
