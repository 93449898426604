import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { getClientsRecent } from 'models/clients/actions';
import { getClientsRecent as clientsRecentSelector } from 'models/clients/selectors';
import { IRecent } from 'models/clients/types';

import { HistoryOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { RecentItem } from 'pages/PageClient/Plural/components/Recent/RecentItem';
import { Scrollbars } from 'react-custom-scrollbars';

const RecentWrapper = styled.div`
  min-width: 300px;
  height: 100%;
  color: ${palette.whiteColor};

  font-size: 14px;
  padding: 32px 16px 16px;
  background-color: ${palette.backgroundAccentColor};
`;

const RecentIcon = styled(HistoryOutlined)`
  font-size: 18px;
  margin-right: 12px;
`;

const RecentTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

const StyledEmpty = styled(Empty)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0;
`;

interface IComponentProps {}

export const RecentDesktop: React.FC<IComponentProps> = () => {
  const dispatch = useDispatch();

  const recent:IRecent[] = useSelector(clientsRecentSelector);
  const getRecent = () => dispatch(getClientsRecent());

  useEffect(() => {
    getRecent()
  }, []);

  return (
    <RecentWrapper>
      <RecentTitle>
        <RecentIcon />
        Недавно просмотрено
      </RecentTitle>

      {_.isEmpty(recent)
        ? (
          <StyledEmpty
            description='Клиенты не найдены'
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <Scrollbars autoHide>
            {_.map(recent, ({ label, personId }) => (
              <RecentItem
                key={personId}
                label={label}
                personId={personId}
              />
            ))}
          </Scrollbars>
        )}
    </RecentWrapper>
  );
};
