import _ from 'lodash';
import { createSelector } from 'reselect';

import { hasIntersection } from 'helper';

import { ROLE_NAMES } from 'models/roles/constants';
import { NAV } from 'models/ui/constants';
import { getRoleNames } from 'models/user/selectors';

type MainNavItem = {
  name: string,
  path: string,
  testAttribute?: string,
};

export type MainNav = MainNavItem[];

export const getNavigation = createSelector(
  [s => s, getRoleNames],
  (state, userRoles) => (_.includes(userRoles, ROLE_NAMES.SUPER_ADMIN)
    ? NAV
    : _.filter(
      NAV,
      ({ checkIsAvailable, roles }: { checkIsAvailable?: (state: any) => boolean, roles?: string[] }) => {
        if (_.isFunction(checkIsAvailable)) return checkIsAvailable(state);
        return (_.isEmpty(roles) || hasIntersection(roles, userRoles));
      },
    )),
);
