import React, {
  useCallback,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
  shadows,
} from 'styles/theme';

import { formatDate } from 'helper';

import { getClientCalibriChatHistory as getClientCalibriChatHistoryAction } from 'models/clients/actions';
import {
  getCalibriChatHistoryLoading as getCalibriChatHistoryLoadingSelector,
  getClientCalibriChatHistory as getClientCalibriChatHistorySelector,
} from 'models/clients/selectors';

import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';

import {
  Label,
  StyledButton,
  Value,
} from './styled';

const Wrapper = styled.div`
  display: flex;
`;

const CommentWrapper = styled.div`
  display: flex;
  align-items: center;

  ${StyledButton} {
    margin-top: -4px;
    margin-bottom: -4px;
    align-self: flex-start;
  }
`;

const StyledSpin = styled(Spin)`
  margin: 16px auto;
  width: 100%;
`;

const Chat = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 648px;
  margin: 0 auto;
`;

const ChatTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin: 16px;

  @media (max-width: ${breakpoints.sm}) {
    font-size: 14px;
  }
`;

const MessageWrapper = styled.div`
  align-self: ${({ $isOperator }) => ($isOperator ? `flex-end` : `flex-start`)};
  position: relative;
  max-width: 450px;
  min-width: 50px;

  &+& {
    margin-top: 8px;
  }

  @media (max-width: ${breakpoints.sm}) {
    max-width: 90%;
  }
`;

const Name = styled.p`
  color: ${palette.black100Color};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const Message = styled.div`
  background-color: ${palette.whiteColor};
  border: ${palette.black10Color};
  border-radius: 8px;
  box-shadow: ${shadows.card};
  color: ${palette.black80Color};
  font-size: 14px;
  padding: 12px 8px 16px;
`;

const MessageTime = styled.p`
  color: ${palette.black60Color};
  font-size: 12px;
  position: absolute;
  bottom: 2px;
  right: 8px;
`;

const CalibriComment = ({ communicationId, item, personId }) => {
  const dispatch = useDispatch();

  const getCalibriChatHistory = useCallback(() => dispatch(getClientCalibriChatHistoryAction({
    chatId: item.chatId,
    communicationId,
    personId,
  })), [dispatch, personId, communicationId, item.chatId]);

  const calibriChatHistoryLoading = useSelector(getCalibriChatHistoryLoadingSelector);
  const isLoading = _.includes(calibriChatHistoryLoading, item.chatId);
  const calibriChatHistory = useSelector(state => getClientCalibriChatHistorySelector(state, personId, item.chatId));// FIXME to personId

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Wrapper>
        <Label isComment>Комментарий</Label>
        <Value>
          <CommentWrapper>
            Тема: {item.status}{item.rating ? `, оценка: ${item.rating} ` : ``}
            <StyledButton
              icon={isOpen ? <UpOutlined /> : <DownOutlined />}
              onClick={() => {
                setIsOpen(!isOpen);
                getCalibriChatHistory();
              }}
            />
          </CommentWrapper>
        </Value>
      </Wrapper>
      {isOpen && (
          isLoading
            ? <StyledSpin />
            : (
              <Chat>
                <ChatTitle>
                  Обращение №{item.chatId} от {formatDate(item.statisticsDtm, `dd.MM.yyyy`)}
                </ChatTitle>
                {_.map(calibriChatHistory, ({
                  clientName,
                  direction,
                  messageDtm,
                  messageId,
                  messageText,
                }, index) => (
                  <MessageWrapper $isOperator={direction === `in`} key={messageId}>
                    {(index === 0 || (index > 0 && calibriChatHistory[index - 1]?.direction !== direction)) && (
                      <Name>{clientName}</Name>
                    )}
                    <Message dangerouslySetInnerHTML={{ __html: messageText }} />
                    <MessageTime>{formatDate(messageDtm, `HH:mm`)}</MessageTime>
                  </MessageWrapper>
                ))}
              </Chat>
            )
      )}
    </>
  );
};

export default CalibriComment;
