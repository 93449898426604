import React from 'react';
import _ from 'lodash';

import { REQUIRED } from 'constants/FORM_VALIDATION_RULES';
import {
  getColumn as getColumnDefault,
  getField,
  getSort,
  sortAlphabetically,
} from 'helper';

import { DeleteOutlined } from '@ant-design/icons';
import {
  Input,
  Select,
  Tooltip,
} from 'antd';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';

const getColumn = ({ key, ...rest }) => getColumnDefault(({
  key,
  sorter: getSort(key, sortAlphabetically),
  ...rest,
}));

export const COLUMNS = ({ removeRoleFromGroup, roles }) => _.map([
  {
    key   : `id`,
    sorter: getSort(`id`),
    width : 50,
  },
  {
    key  : `name`,
    title: `Название`,
    width: 100,
  },
  {
    key  : `description`,
    title: `Описание`,
    width: 100,
  },
  {
    dataIndex: `roleIds`,
    key      : `id`,
    title    : `Роли`,
    render   : (roleIds, roleGroup) => (_.map(roleIds, roleId => {
      const role = _.find(roles, { id: roleId });
      return (
        <Tooltip title={role?.description}>
          <DefaultTag
            closable
            closeIcon={<DeleteOutlined />}
            color={_.get(role, `color`, `geekblue`)}
            key={roleId}
            onClose={() => removeRoleFromGroup({ id: roleId, roleGroupId: roleGroup.id })}
          >
            {role?.name}
          </DefaultTag>
        </Tooltip>
      );
    })),
  },
], getColumn);

export const FORM_FIELDS = ({ roleGroupNames = [], itemEditing, roles }) => _.map([
  {
    key         : `name`,
    title       : `Название`,
    initialValue: ``,
    required    : true,
    rules       : [
      REQUIRED,
      {
        validator: (pass, value) => {
          if (_.get(itemEditing, `name`) === value || !_.includes(roleGroupNames, value)) return Promise.resolve();
          return Promise.reject(new Error(`Такое имя группы ролей уже есть, придумайте другое`));
        },
      },
    ],
  },
  {
    key         : `description`,
    title       : `Описание`,
    Component   : Input.TextArea,
    initialValue: ``,
  },
  {
    key         : `roleIds`,
    title       : `Роли`,
    Component   : Select,
    initialValue: [],
    mode        : `multiple`,
    options     : _.map(roles, ({ description, id, name }) => ({
      label: `${name} (${description})`,
      value: id,
    })),
  },
], getField);
