import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { formatDate } from 'helper';

import { getClientDead as getDeadAction } from 'models/clients/actions';
import {
  getIsLoading as isClientLoadingSelector,
  getLegalDeads as getLegalDeadsSelector,
} from 'models/clients/selectors';
import { getItem as selectTaskById } from 'models/task/selectors';

import {
  Button,
  Space,
} from 'antd';
import { DefaultTable } from 'components/DefaultTable/DefaultTable';
import { LegalDeadModal } from 'components/Modal/LegalDeadModal';

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const Table = styled(DefaultTable)`
  border: 1px solid ${palette.black30Color};
`;

const COLUMNS = [
  {
    dataIndex: `operatorName`,
    key      : `operatorName`,
    title    : `Оператор`,
  },
  {
    dataIndex: `createDtm`,
    key      : `createDtm`,
    title    : `Дата`,
    render   : (v: any) => formatDate(v),
  },
  {
    dataIndex: `deathDtm`,
    key      : `deathDtm`,
    title    : `Дата смерти`,
    render   : (v: any) => formatDate(v, `dd.MM.yyyy`),
  },
  {
    dataIndex: `deathCertificateNumber`,
    key      : `deathCertificateNumber`,
    title    : `Реквизиты свидетельства о смерти`,
  },
  {
    dataIndex: `receiptInfoDtm`,
    key      : `receiptInfoDtm`,
    title    : `Дата получения информации`,
    render   : (v: any) => formatDate(v, `dd.MM.yyyy`),
  },
  {
    dataIndex: `contractNumber`,
    key      : `contractNumber`,
    title    : `Номер договора`,
  },
  {
    dataIndex: `totalDebt`,
    key      : `totalDebt`,
    title    : `Сумма задолженности`,
  },
  {
    dataIndex: `inheritanceDtm`,
    key      : `inheritanceDtm`,
    title    : `Примерная дата вступления в наследство`,
    render   : (v: any) => formatDate(v, `dd.MM.yyyy`),
  },
  {
    dataIndex: `inheritanceCaseNumber`,
    key      : `inheritanceCaseNumber`,
    title    : `Наследственное дело`,
  },
  {
    dataIndex: `claimDtm`,
    key      : `claimDtm`,
    title    : `Дата подачи иска/претензии`,
    render   : (v: any) => formatDate(v, `dd.MM.yyyy`),
  },
  {
    dataIndex: `courtName`,
    key      : `courtName`,
    title    : `Суд`,
  },
  {
    dataIndex: `comments`,
    key      : `comments`,
    title    : `Комментарии`,
  },
  {
    dataIndex: `updateOperatorName`,
    key      : `updateOperatorName`,
    title    : `Автор обновления`,
  },
  {
    dataIndex: `updateDtm`,
    key      : `updateDtm`,
    title    : `Дата обновления записи`,
    render   : (v: any) => formatDate(v),
  },
];

interface IProps {
  taskId: number;
}

export const Dead: React.FC<IProps> = ({ taskId }) => {
  const dispatch = useDispatch();
  const { personId } = useSelector(state => selectTaskById(state, taskId));

  const loadDead = useCallback(
    () => dispatch(getDeadAction({ personId })),
    [personId, dispatch],
  );

  useEffect(() => {
      if (personId) loadDead();
    },
    [loadDead, personId],
  );

  const isLoading = useSelector(isClientLoadingSelector);
  const dead = useSelector(state => getLegalDeadsSelector(state, personId));
  const [isDeadModalOpen, setIsDeadModalOpen] = useState(false);
  const [currentDeadId, setCurrentDeadId] = useState<string | null>(null);

  const openDeadModal = (last: boolean) => {
    setIsDeadModalOpen(true);
    setCurrentDeadId(last ? dead[0].deadId : null);
  };

  return (
    <Wrap>
      <Space>
        <Button onClick={() => openDeadModal(false)}>
          Создать дело по смерти заемщика
        </Button>
        <Button
          disabled={_.isEmpty(dead)}
          onClick={() => openDeadModal(true)}
        >
          Редактировать последнее дело по смерти заемщика
        </Button>
      </Space>
      <Title>Умершие:</Title>
      <Table
        // @ts-ignore
        columns={COLUMNS}
        dataSource={dead}
        loading={isLoading}
        pagination={false}
        rowKey='deadId'
      />
      <LegalDeadModal
        deadId={currentDeadId}
        isVisible={isDeadModalOpen}
        onClose={() => setIsDeadModalOpen(false)}
        onSuccess={loadDead}
        personId={personId}
      />
    </Wrap>
  );
};
