import React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import {
  closeSignDocument as closeSignDocumentAction,
  finalizeSignDocument as finalizeSignDocumentAction,
  getClientSignDocuments as getClientSignDocumentsAction,
} from 'models/clients/actions';
import { SIGN_DOCUMENT_STATUS_NAMES } from 'models/clients/constants';
import {
  getClientSignDocuments as getClientSignDocumentsSelector,
  getIsLoadingSignDocuments as getIsLoadingSignDocumentsSelector,
} from 'models/clients/selectors';
import { get as getDictionaryAction } from 'models/dictionaries/actions';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';

import {
  Drawer,
  Empty,
} from 'antd';
import { DefaultSpin } from 'components/DefaultSpin/DefaultSpin';

import Item from './Item';

import { CLOSE_BUTTON_STATUSES } from './constants';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    max-width: 100%;
  }
`;

const StyledEmpty = styled(Empty)`
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const SignDocuments = ({ close, isOpen, personId }) => {
  const dispatch = useDispatch();

  const { fileStatuses, isLoading, signDocuments } = useSelector(state => ({
    fileStatuses : getDictionarySelector(state, `fileStatus`),
    isLoading    : getIsLoadingSignDocumentsSelector(state),
    signDocuments: getClientSignDocumentsSelector(state, personId),
  }));

  const closeSignDocument = id => dispatch(closeSignDocumentAction({ personId, id }));
  const finalizeSignDocument = (documentId, sms = false) => dispatch(
    finalizeSignDocumentAction({ personId, documentId, sms }),
  );
  const getDictionary = entity => dispatch(getDictionaryAction({ entity, force: true }));
  const getSignDocuments = () => dispatch(getClientSignDocumentsAction({ personId }));

  const afterOpenChange = _isOpen => {
    if (_isOpen) {
      getSignDocuments();
      if (_.isEmpty(fileStatuses)) getDictionary(`fileStatus`);
    }
  };

  const isCloseSignDocumentButton = !!_.find(
    _.flatten(_.values(signDocuments)),
    ({ fileStatusId }) => _.includes(CLOSE_BUTTON_STATUSES, fileStatusId),
  );
  const isSignDocumentsEmpty = _.isEmpty(_.flatten(_.values(signDocuments)));

  return (
    <StyledDrawer
      afterOpenChange={afterOpenChange}
      destroyOnClose
      onClose={close}
      open={isOpen}
      placement='right'
      title='Файлы клиента на подписи'
      width={780}
    >
      {isLoading && <DefaultSpin />}
      {isSignDocumentsEmpty ? (
        <StyledEmpty description='Нет данных' image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : _.map(_.keys(SIGN_DOCUMENT_STATUS_NAMES), key => (
        <Item
          closeSignDocument={closeSignDocument}
          fileStatuses={fileStatuses}
          finalizeSignDocument={finalizeSignDocument}
          isCloseSignDocumentButton={isCloseSignDocumentButton}
          isDraft={key === `DRAFT`}
          key={key}
          signDocuments={signDocuments}
          status={key}
        />
      ))}
    </StyledDrawer>
  );
};

export default React.memo(
  SignDocuments,
  _.isEqual,
);
