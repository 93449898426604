import _ from 'lodash';
import { v4 } from 'uuid';

import { parseResponse } from 'api/helpers';
import {
  b64toBlob,
  createExcelBlob,
  downloadBlob,
  formatDate,
  getFullName,
  showError,
  sleep,
} from 'helper';

import { setClientAttribute as setClientAttributeAction } from 'models/clients/actions';
import * as clientApi from 'models/clients/api';
import { getClientLoans as getClientLoansSelector } from 'models/clients/selectors';
import { getItems as getDictionary } from 'models/dictionaries/selectors';
import {
  CLAIM_STATEMENTS_DOC_IDS_WITH_THIRDPARTY,
  DEFAULT_TABLE_STATE,
  STATUS_NAME_IN_PROGRESS,
  STATUS_NAME_NEW,
} from 'models/task/constants';
import {
  getFilters as getFiltersSelector,
  getItem as getItemByIdSelector,
  getItems as getItemsSelector,
  getItemsByIds as getItemsByIdsSelector,
  getTableState as getTableStateSelector,
  getTableStateCut as getTableStateCutSelector,
} from 'models/task/selectors';
import { getCurrent as getCurrentUserSelector } from 'models/user/selectors';

import { notification } from 'antd';

import {
  ASSIGN,
  CREATE,
  DELETE_TASKS,
  EXPORT_CLAIM_STATEMENTS,
  EXPORT_COURT_NOTIFICATIONS,
  EXPORT_EXCEL,
  GET,
  GET_FILTERS,
  GET_MULTIPLE,
  GET_SINGLE,
  model as namespace,
  REASSIGN,
  RESET,
  SET,
  SET_LOADING,
  SET_OPERATOR,
  SET_SEARCH_PARTNER,
  SET_SEARCH_PARTNER_MULTIPLE,
  SET_STATUS,
  SET_STATUS_MULTIPLE,
  SET_TABLE_STATE,
  TERMINATE,
  UPDATE,
  UPLOAD_FILE,
  UPLOAD_LEGAL_REGISTRY,
} from './actions';
import * as api from './api';

const initialState = {
  isLoaded    : false,
  isLoading   : false,
  items       : [],
  searchString: ``,
  total       : 0,
  userId      : null,
  taskStatusId: null,
  operatorId  : null,
  tableState  : {
    plural: DEFAULT_TABLE_STATE,
  },
  filters: {},
};

export default {
  namespace,
  state        : initialState,
  subscriptions: {},
  effects      : {
    *[ASSIGN](action, { all, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids } = action.payload;
      const defaultError = `При назначении задач(и) произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(`${defaultError}: не указаны идентификаторы задач(и) для назначения`);
        const { campaignTypes, currentUser, items, taskStatuses } = yield select(state => ({
          currentUser  : getCurrentUserSelector(state),
          taskStatuses : getDictionary(state, `taskStatus`),
          campaignTypes: getDictionary(state, `campaignType`),
          items        : getItemsByIdsSelector(state, ids),
        }));

        const taskStatusMap = _.map(
          items,
          ({ campaignTypeId, id }) => {
            const campaignId = _.get(
              _.find(campaignTypes, { id: campaignTypeId }),
              `campaignId`,
            );

            const taskStatusId = _.get(
              _.find(taskStatuses, { name: STATUS_NAME_IN_PROGRESS, campaignId }),
              `id`,
            );

            return { id, taskStatusId };
          },
        );

        yield all(
          _.flatten(
            _.map(taskStatusMap, ({ id, taskStatusId }) => ([
              put({
                type   : SET_OPERATOR,
                payload: {
                  id,
                  omitNotification: true,
                  userId          : currentUser.id,
                },
              }),
              taskStatusId
                ? put({
                  type   : SET_STATUS,
                  payload: {
                    id,
                    omitNotification: true,
                    taskStatusId,
                  },
                })
                : _.noop(),
            ])),
          ),
        );

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        notification.success({ message: `Успешно назначено` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При создании задачи произошла ошибка`;

      try {
        yield call(api.create, action.payload);
        yield put({ type: GET });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        notification.success({ message: `Задача создана` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[EXPORT_EXCEL](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При экспорте задач произошла ошибка`;

      try {
        const { ids, personId = `plural` } = action.payload;
        const tableState = yield select(state => getTableStateCutSelector(state, personId));

        notification.info({ message: `По завершении экспорта будет скачан Excel файл. Ожидайте` });
        const blob = parseResponse({
          defaultError,
          response: yield call(api.exportExcel, { ids, tableState }),
        });
        downloadBlob(blob, `Реестр ИМХА для передачи ${formatDate(new Date(), `dd.MM.yyyy`)}.xlsx`);
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[EXPORT_CLAIM_STATEMENTS](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При экспорте документов произошла ошибка`;

      try {
        const {
          addToRegistry,
          callback,
          clientSurname,
          documentTemplateId,
          newOwnerFlg,
          registryTypeId,
          taskId,
        } = action.payload;

        const task = yield select(getItemByIdSelector, taskId);

        const useOtherClaimCall = _.includes(
          CLAIM_STATEMENTS_DOC_IDS_WITH_THIRDPARTY,
          action.payload.documentTypeId,
        );

        const params = {
          applicationId: task.applicationId,
          loanId       : task.loanId,
          personId     : task.personId,
        };

        const { productId } = parseResponse({
          defaultError,
          response: yield call(clientApi.getApplication, params.applicationId),
        });

        const insuranceFlg = !_.isEmpty(parseResponse({
          defaultError,
          response: yield call(api.getLoanInsurance, params.applicationId),
        }));

        const values = _.omit(
          action.payload,
          [
            `callback`,
            `addToRegistry`,
            `newOwnerFlg`,
            `taskId`,
            `tpFio`,
            `tpBirthDtm`,
            `tpPassportSerial`,
            `tpPassportNumber`,
            `tpBirthPlace`,
            `tpInn`,
            `tpAddress`,
            `registryTypeId`,
          ],
        );

        const thirdParty = {
          tpFio           : action.payload.tpFio,
          tpBirthDtm      : action.payload.tpBirthDtm,
          tpPassportSerial: action.payload.tpPassportSerial,
          tpPassportNumber: action.payload.tpPassportNumber,
          tpBirthPlace    : action.payload.tpBirthPlace,
          tpInn           : action.payload.tpInn,
          tpAddress       : action.payload.tpAddress,
        };

        const response = parseResponse({
          defaultError,
          errorPath: `data.message`,
          response : yield call(
            useOtherClaimCall
              ? api.composeOtherClaimStatement
              : api.composeClaimStatement,
            {
              productId,
              documentTemplateId,
              ...params,
              ...values,
              ...useOtherClaimCall && newOwnerFlg
                ? { thirdParty }
                : null,
              insuranceFlg,
            },
          ),
        });

        if (response.file) {
          downloadBlob(b64toBlob(response.file, `application/zip`), `Сформированные документы_${clientSurname}.zip`);
        }

        if (addToRegistry !== `off`) {
          parseResponse({
            defaultError,
            response: yield call(
              clientApi.createLegalRegistry,
              {
                createRegistryFlg: addToRegistry === `new`,
                registryTypeId,
                params           : [{
                  documentId: response.documentId,
                  loanId    : task.loanId,
                  personId  : task.personId,
                }],
              },
            ),
          });
        }

        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[EXPORT_COURT_NOTIFICATIONS](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При экспорте документов произошла ошибка`;

      try {
        const {
          clientSurname,
          createRegistryFlg,
          imhaFlg,
          registryTypeId,
          taskIds,
          type,
        } = action.payload;
        if (_.isEmpty(taskIds)) throw new Error(`Не выбраны задачи для уведомлений`);

        const tasks = yield select(getItemsByIdsSelector, taskIds);
        const params = yield call(
          _taskIds => Promise.all(_.map(
            _taskIds,
            taskId => api.getSingle(taskId).then(({ data: {
              applicationId,
              client,
              loan,
              loanId,
              personId,
            } }) => ({
              applicationId,
              client,
              loanId,
              personId,
              productId: _.get(loan, `productId`),
            })),
          )),
          taskIds,
        );

        if (type === `legal`) {
          notification.info({
            message: `По завершении экспорта будет скачан архив. Ожидайте`,
          });
        }
        if (type === `service`) {
          notification.info({
            message: `По завершении экспорта будет скачан архив и файл с реестром отправленных уведомлений. Ожидайте`,
          });
        }

        let personTasksToExcel = _.map(params, ({ client, loanId }) => ({
          loanId,
          addressReg: client?.addressReg,
          fullName  : getFullName(client),
        }));
        const response = parseResponse({
          defaultError,
          response: yield call(api.exportCourtNotifications, {
            createRegistryFlg,
            types : { imhaFlg },
            registryTypeId,
            params: _.map(params, param => _.omit(param, [`client`])),
          }),
        });

        if (response.file) {
          downloadBlob(
            b64toBlob(response.file, `application/zip`),
            `Сформированные документы${clientSurname ? `_${clientSurname}` : ``}.zip`,
          );
        }

        if (!_.isEmpty(response.fail)) {
          const failedLoanIds = _.compact(response.fail);
          personTasksToExcel = _.reject(
            personTasksToExcel,
            ({ loanId }) => _.includes(failedLoanIds, loanId),
          );
          const failedLoans = _.map(
            _.filter(tasks, ({ loanId }) => _.includes(failedLoanIds, loanId)),
            ({ client: { name, patronymic, surname }, contractNumber }) => (
              `${_.trim(`${surname} ${name} ${patronymic}`)}\n${contractNumber}\n`
            ),
          );

          notification.warning({
            duration: null,
            message : `Не сформированы документы по клиентам:\n${_.join(failedLoans, `\n`)}`,
            style   : {
              whiteSpace: `pre-wrap`,
            },
          });
        }

        if (type === `service` && !_.isEmpty(personTasksToExcel)) {
          const columns = [
            { header: `ADDRESSLINE`, key: `addressReg`, width: 30 },
            { header: `ADRESAT`, key: `fullName`, width: 30 },
          ];

          const excelBlob = yield call(createExcelBlob, personTasksToExcel, columns);
          downloadBlob(excelBlob, `Отправленные уведомления.xlsx`);
        }

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке задач произошла ошибка`;
      try {
        const { callback, personId = `plural` } = action.payload || {};
        let tableState = yield select(state => getTableStateCutSelector(state, personId));
        const sorter = _.pick(tableState.sorter, [`columnKey`, `order`]);
        const response = yield call(api.search, { ...tableState, sorter });
        const { data: items, total } = parseResponse({ defaultError, response });
        yield put({
          type   : SET,
          payload: {
            items,
            isLoaded: true,
          },
        });
        tableState = yield select(state => getTableStateCutSelector(state, personId));
        const updatedTableState = {
          ...tableState,
          pagination: {
            ...tableState.pagination,
            current: tableState.pagination?.current === 0 ? 1 : tableState.pagination.current,
            total,
          },
        };
        yield put({
          type   : SET_TABLE_STATE,
          payload: {
            personId,
            ...updatedTableState,
          },
        });
        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_SINGLE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { force = false, id } = action.payload;
      const defaultError = `При загрузке задачи с id '${id}' произошла ошибка`;
      try {
        if (!id) throw new Error(defaultError);

        const item = parseResponse({
          defaultError,
          response: yield call(api.getSingle, id, force),
        });
        if (item?.personId && !_.isEmpty(item?.loans)) {
          const currentLoans = yield select(state => getClientLoansSelector(state, item.personId));
          yield put(setClientAttributeAction({
            personId: item.personId,
            loans   : _.map(item.loans, loan => ({
              ...(_.find(currentLoans, { id: loan.id }) || {}),
              ...loan,
            })),
          }));
        }
        const currentItems = yield select(getItemsSelector);

        if (_.isEmpty(currentItems)) {
          yield put({ type: SET, payload: { items: [item] } });
        } else {
          yield put({
            type   : SET,
            payload: {
              items: _.map(currentItems, currentItem => (currentItem.id === id
                ? { ...currentItem, ...item }
                : currentItem
              )),
            },
          });
        }
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback(item);
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_MULTIPLE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { taskIds } = action.payload;
      const defaultError = `При множественной загрузке задач произошла ошибка`;
      try {
        if (!taskIds || _.isEmpty(taskIds)) throw new Error(`Не указаны идентификаторы задач`);
        const notificationId = v4();
        notification.info({
          key    : notificationId,
          message: `Началась загрузка задач.`,
        });
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const i in taskIds) {
          const id = taskIds[i];
          yield put({ type: GET_SINGLE, payload: { id, force: true } });
          yield call(sleep, 700);
          notification.info({
            key    : notificationId,
            message: `Загружено: ${i} из ${taskIds.length} задач.`,
          });
        }
        notification.success({
          key    : notificationId,
          message: `Все задачи загружены, секунду...`,
        });
        yield call(sleep, 5000);
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_FILTERS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке фильтров задач произошла ошибка`;
      try {
        const filters = parseResponse({
          defaultError,
          response: yield call(api.getFilters),
        });
        yield put({ type: SET, payload: { filters } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REASSIGN](action, { all, put, select }) {
      yield put({ type: SET_LOADING, payload: true });

      const { ids } = action.payload;
      const defaultError = `При снятии задач(и) с оператора произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(`${defaultError}: не указаны идентификаторы задач(и) для снятия`);
        const { campaignTypes, items, taskStatuses } = yield select(state => ({
          items        : getItemsByIdsSelector(state, ids),
          taskStatuses : getDictionary(state, `taskStatus`),
          campaignTypes: getDictionary(state, `campaignType`),
        }));

        const inProgressTaskStatusIds = _.map(_.filter(taskStatuses, { name: STATUS_NAME_IN_PROGRESS }), `id`);

        const idsToChangeStatus = _.map(
          _.filter(items, ({ taskStatusId }) => _.includes(inProgressTaskStatusIds, taskStatusId)),
          `id`,
        );

        const taskStatusMap = _.map(
          items,
          ({ campaignTypeId, id }) => {
            const campaignId = _.get(
              _.find(campaignTypes, { id: campaignTypeId }),
              `campaignId`,
            );

            const taskStatusId = _.get(
              _.find(taskStatuses, { name: STATUS_NAME_NEW, campaignId }),
              `id`,
            );

            return { id, taskStatusId };
          },
        );

        yield all(
          _.flatten(
            _.compact(_.map(taskStatusMap, ({ id, taskStatusId }) => ([
              put({
                type   : SET_OPERATOR,
                payload: {
                  id,
                  omitNotification: true,
                  operatorId      : null,
                },
              }),
              _.includes(idsToChangeStatus, id)
                ? put({
                  type   : SET_STATUS,
                  payload: {
                    id,
                    omitNotification: true,
                    taskStatusId,
                  },
                })
                : null,
            ]))),
          ),
        );

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        notification.success({ message: `Успешно снято с сотрудника` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_OPERATOR](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const {
        id, omitNotification, operatorId, userId,
      } = action.payload;
      const defaultError = `При назначении задачи с id '${id}' оператору произошла ошибка`;

      try {
        if (!id) throw new Error(`${defaultError}: не указан идентификатор задачи`);

        const data = parseResponse({
          defaultError,
          response: yield call(api.setOperator, id, { operatorId, userId }),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => (currentItem.id === id
              ? { ...currentItem, operatorId: data.operatorId }
              : currentItem
            )),
          },
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Оператор изменен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_SEARCH_PARTNER](action, { all, call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const {
        id, ids, omitNotification, searchPartnerId,
      } = action.payload;
      const defaultError = `При изменении партнера в задаче с id '${id}' произошла ошибка`;

      try {
        if (!id && _.isEmpty(ids)) throw new Error(`${defaultError}: не указан идентификатор задачи`);
        if (_.isNil(searchPartnerId)) throw new Error(`${defaultError}: не указан идентификатор партнера`);

        let data;
        if (id) {
          data = parseResponse({
            defaultError,
            response: yield call(api.setSearchPartner, id, searchPartnerId),
          });
        } else {
          const responses = yield all(_.map(ids, _id => call(api.setSearchPartner, _id, searchPartnerId)));
          data = _.map(responses, response => parseResponse({ defaultError, response }));
        }

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => ((currentItem.id === id || _.includes(ids, currentItem.id))
              ? {
                ...currentItem,
                searchPartnerId: _.isArray(data)
                  ? _.get(_.find(data, { id: currentItem.id }), `searchPartnerId`)
                  : data.searchPartnerId,
              } : currentItem
            )),
          },
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Партнер по розыску изменен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_SEARCH_PARTNER_MULTIPLE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids, omitNotification, searchPartnerId } = action.payload;
      const defaultError = `При изменении партнёра в задачах с id '${_.join(ids)}' произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(`${defaultError}: не указаны идентификаторы задач`);
        if (_.isNil(searchPartnerId)) throw new Error(`${defaultError}: не указан идентификатор партнёра`);

        parseResponse({
          defaultError,
          response: yield call(api.setSearchPartnerMultiple, ids, searchPartnerId),
        });

        yield put({ type: GET });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Партнер по розыску изменён` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_STATUS](action, { all, call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const {
        id, ids, omitNotification, taskStatusId,
      } = action.payload;
      const defaultError = `При изменении статуса задачи с id '${id}' произошла ошибка`;

      try {
        if (!id && _.isEmpty(ids)) throw new Error(`${defaultError}: не указан идентификатор задачи`);
        if (_.isNil(taskStatusId)) throw new Error(`${defaultError}: не указан идентификатор статуса задачи`);

        let data;
        if (id) {
          data = parseResponse({
            defaultError,
            response: yield call(api.setStatus, id, taskStatusId),
          });
        } else {
          const responses = yield all(_.map(ids, _id => call(api.setStatus, _id, taskStatusId)));
          data = _.map(responses, response => parseResponse({ defaultError, response }));
        }

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => ((currentItem.id === id || _.includes(ids, currentItem.id))
              ? {
                ...currentItem,
                taskStatusId: _.isArray(data)
                  ? _.get(_.find(data, { id: currentItem.id }), `taskStatusId`)
                  : data.taskStatusId,
              } : currentItem
            )),
          },
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Статус задания изменен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_STATUS_MULTIPLE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids, omitNotification, taskStatusId } = action.payload;
      const defaultError = `При изменении статуса задач с id '${_.join(ids)}' произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(`${defaultError}: не указаны идентификаторы задач`);
        if (_.isNil(taskStatusId)) throw new Error(`${defaultError}: не указан идентификатор статуса задачи`);

        parseResponse({
          defaultError,
          response: yield call(api.setStatusMultiple, ids, taskStatusId),
        });

        yield put({ type: GET });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Статус заданий изменён` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_TABLE_STATE](action, { put, select }) {
      const { callback, personId = `plural`, ...tableState } = action.payload;
      const currentTableState = yield select(getTableStateSelector);
      const currentTableStateCut = yield select(state => getTableStateCutSelector(state, personId));
      const filters = yield select(state => getFiltersSelector(state, personId));
      const isFiltersChanged = !_.isEqual(
        _.omitBy(tableState?.filters, _.isNil),
        _.omitBy(filters, _.isNil),
      );
      yield put({
        type   : SET,
        payload: {
          tableState: {
            ...currentTableState,
            [personId]: {
              ...currentTableStateCut,
              ...tableState,
              pagination: isFiltersChanged
                ? {
                  ...currentTableStateCut.pagination,
                  ...tableState.pagination,
                  current       : 1,
                  defaultCurrent: 1,
                  page          : 1,
                }
                : {
                  ...currentTableStateCut.pagination,
                  ...tableState.pagination,
                },
            },
          },
        } });
      if (_.isFunction(callback)) callback();
    },

    *[DELETE_TASKS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids } = action.payload;
      const defaultError = `При удалении задач(и) произошла ошибка`;

      try {
        if (_.isEmpty(ids)) {
          throw new Error(`${defaultError}: не указаны идентификаторы задач(и) для удаления`);
        }

        parseResponse({
          defaultError,
          response: yield call(api.deleteTasks, ids),
        });

        yield put({ type: GET });

        notification.success({ message: `Успешно удалено` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[TERMINATE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids } = action.payload;
      const defaultError = `При закрытии задач(и) произошла ошибка`;

      try {
        if (_.isEmpty(ids)) {
          throw new Error(`${defaultError}: не указаны идентификаторы задач(и) для закрытия`);
        }

        parseResponse({
          defaultError,
          response: yield call(api.terminate, ids),
        });

        yield put({ type: GET });

        notification.success({ message: `Успешно закрыто` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { item, taskId } = action.payload;
      const defaultError = `При обновлении полей задачи произошла ошибка`;

      try {
        if (!taskId) throw new Error(`Не указан идентификатор задачи`);
        if (_.isEmpty(item)) throw new Error(`Нет данных для обновления`);

        const task = parseResponse({
          defaultError,
          response: yield call(api.update, taskId, item),
        });

        const currentItems = yield select(getItemsSelector);

        if (_.isEmpty(currentItems)) {
          yield put({ type: SET, payload: { items: [item] } });
        } else {
          yield put({
            type   : SET,
            payload: {
              items: _.map(currentItems, currentItem => (currentItem.id === taskId
                ? { ...currentItem, ...task }
                : currentItem
              )),
            },
          });
        }
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback(item);

        notification.success({ message: `Успешно сохранено` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPLOAD_FILE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { file } = action.payload;
      const defaultError = `При загрузке файла произошла ошибка`;

      try {
        const addedCount = parseResponse({
          defaultError,
          response: yield call(api.uploadFile, file),
        });

        yield put({ type: GET });
        notification.success({ message: `Обработано задач: ${addedCount}` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPLOAD_LEGAL_REGISTRY](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { data } = action.payload;
      const defaultError = `При загрузке реестра произошла ошибка`;

      try {
        parseResponse({
          defaultError,
          response: yield call(api.uploadLegalRegistry, data),
        });

        notification.success({ message: `Реестр загружен` });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        ...payload,
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
};
