import React from 'react';
import _ from 'lodash';

import TEST_ATTRIBUTES from 'constants/TEST_ATTRIBUTES';

import { ROLE_NAMES } from 'models/roles/constants';

import {
  AppstoreOutlined,
  AuditOutlined,
  BankOutlined,
  BellOutlined,
  CarOutlined,
  CheckCircleOutlined,
  DollarCircleOutlined,
  ExclamationCircleOutlined,
  ExpandAltOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FileWordOutlined,
  GroupOutlined,
  HeartOutlined,
  IdcardOutlined,
  IssuesCloseOutlined,
  LikeOutlined,
  MailOutlined,
  MehOutlined,
  OrderedListOutlined,
  PercentageOutlined,
  PhoneOutlined,
  ProfileOutlined,
  RocketOutlined,
  SettingOutlined,
  SmileOutlined,
  SolutionOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  UnorderedListOutlined,
  UserOutlined,
  UserSwitchOutlined,
  WalletOutlined,
} from '@ant-design/icons';

export const DICTIONARY_ROLES = [
  ROLE_NAMES.COLLECTION_ADMIN,
  ROLE_NAMES.DICTIONARY_EDITOR,
  ROLE_NAMES.DICTIONARY_VIEWER,
  ROLE_NAMES.INFO_EDITOR,
  ROLE_NAMES.MOBILE_CONFIG_EDITOR,
  ROLE_NAMES.PARTNER_EDITOR,
  ROLE_NAMES.PARTNER_REQUISITE_EDITOR,
  ROLE_NAMES.PARTNER_REQUISITE_VIEWER,
  ROLE_NAMES.PARTNER_VIEWER,
  ROLE_NAMES.SALE_ADMIN,
  ROLE_NAMES.TEMPLATE_EDIT,
  ROLE_NAMES.VERIFICATION_ADMIN,
];

export const ACTION_ROLES = [
  ROLE_NAMES.ACTION_EDITOR,
  ROLE_NAMES.ACTION_VIEWER,
];

export const ADMIN_MENU = [
  {
    title        : `Справочники`,
    icon         : fontSize => <AppstoreOutlined style={{ fontSize }} />,
    path         : `/admin/dictionaries`,
    roles        : DICTIONARY_ROLES,
    testAttribute: TEST_ATTRIBUTES.ADMIN_MENU_DICTIONARY_ITEM,
  },
  {
    title        : `Роли`,
    icon         : fontSize => <UserOutlined style={{ fontSize }} />,
    path         : `/admin/roles`,
    roles        : [ROLE_NAMES.SUPER_ADMIN],
    testAttribute: TEST_ATTRIBUTES.ADMIN_MENU_ROLES_ITEM,
  },
  {
    title        : `Группы ролей`,
    icon         : fontSize => <TeamOutlined style={{ fontSize }} />,
    path         : `/admin/role-groups`,
    roles        : [ROLE_NAMES.SUPER_ADMIN],
    testAttribute: TEST_ATTRIBUTES.ADMIN_MENU_ROLE_GROUPS_ITEM,
  },
  {
    title        : `Сотрудники`,
    icon         : fontSize => <SolutionOutlined style={{ fontSize }} />,
    path         : `/admin/operators`,
    testAttribute: TEST_ATTRIBUTES.ADMIN_MENU_EMPLOYEES_ITEM,
  },
  {
    title        : `Акции`,
    icon         : fontSize => <PercentageOutlined style={{ fontSize }} />,
    path         : `/admin/actions`,
    roles        : ACTION_ROLES,
    testAttribute: TEST_ATTRIBUTES.ADMIN_MENU_ACTIONS_ITEM,
  },
];

export const DICTIONARIES_MENU = [
  {
    color        : `secondary`,
    group        : `Внешние`,
    icon         : fontSize => <TeamOutlined style={{ fontSize }} />,
    entity       : `partner`,
    testAttribute: TEST_ATTRIBUTES.DICTIONARIES_PARTNERS_MENU_ITEM,
    title        : `Партнеры`,
    roles        : [
      ROLE_NAMES.PARTNER_EDITOR,
      ROLE_NAMES.PARTNER_VIEWER,
    ],
  },
  {
    color        : `secondary`,
    group        : `Внешние`,
    icon         : fontSize => <IdcardOutlined style={{ fontSize }} />,
    entity       : `partnerRequisite`,
    testAttribute: TEST_ATTRIBUTES.DICTIONARIES_PARTNER_REQUISITES_MENU_ITEM,
    title        : `Реквизиты партнеров`,
    roles        : [
      ROLE_NAMES.PARTNER_REQUISITE_EDITOR,
      ROLE_NAMES.PARTNER_REQUISITE_VIEWER,
    ],
  },
  {
    color        : `secondary`,
    group        : `Внешние`,
    icon         : fontSize => <PercentageOutlined style={{ fontSize }} />,
    entity       : `partnerRate`,
    testAttribute: TEST_ATTRIBUTES.DICTIONARIES_PARTNER_RATE_MENU_ITEM,
    title        : `Тарифы партнеров`,
    roles        : [
      ROLE_NAMES.PARTNER_RATE_EDITOR,
      ROLE_NAMES.PARTNER_RATE_VIEWER,
    ],
  },
  {
    color        : `secondary`,
    group        : `Внешние`,
    icon         : fontSize => <CarOutlined style={{ fontSize }} />,
    entity       : `dealership`,
    testAttribute: TEST_ATTRIBUTES.DICTIONARIES_DEALERSHIP_MENU_ITEM,
    title        : `Салоны`,
    roles        : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color        : `secondary`,
    group        : `Внешние`,
    icon         : fontSize => <UserSwitchOutlined style={{ fontSize }} />,
    entity       : `assignee`,
    testAttribute: TEST_ATTRIBUTES.DICTIONARIES_CESSIONARIES_MENU_ITEM,
    title        : `Цессионеры`,
    roles        : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color        : `alert`,
    group        : `Внешние`,
    icon         : fontSize => <BellOutlined style={{ fontSize }} />,
    entity       : `notificationList`,
    testAttribute: TEST_ATTRIBUTES.DICTIONARIES_MAILING_LIST_MENU_ITEM,
    title        : `Списки рассылки`,
    roles        : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color        : `alert`,
    group        : `Внешние`,
    icon         : fontSize => <DollarCircleOutlined style={{ fontSize }} />,
    entity       : `marketingOfferType`,
    testAttribute: TEST_ATTRIBUTES.DICTIONARIES_MARKETING_OFFER_TYPE,
    title        : `Типы маркетинговых офферов`,
    roles        : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color        : `alert`,
    group        : `Внешние`,
    icon         : fontSize => <IssuesCloseOutlined style={{ fontSize }} />,
    entity       : `marketingBlacklistType`,
    testAttribute: TEST_ATTRIBUTES.DICTIONARIES_MARKETING_BLACKLIST_TYPE,
    title        : `Типы черных списков`,
    roles        : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color        : `alert`,
    group        : `Внешние`,
    icon         : fontSize => <MailOutlined style={{ fontSize }} />,
    entity       : `smsTemplate`,
    testAttribute: TEST_ATTRIBUTES.DICTIONARIES_SMS_TEMPLATE,
    title        : `Шаблоны СМС`,
    roles        : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <AuditOutlined style={{ fontSize }} />,
    entity: `verificationStatus`,
    title : `Статусы заданий`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.VERIFICATION_ADMIN,
    ],
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <FileSearchOutlined style={{ fontSize }} />,
    entity: `vectorTitle`,
    title : `Поля вектора данных`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.VERIFICATION_ADMIN,
    ],
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <CarOutlined style={{ fontSize }} />,
    entity: `carServiceTitle`,
    title : `Поля данных сервисов по авто`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.VERIFICATION_ADMIN,
    ],
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <CheckCircleOutlined style={{ fontSize }} />,
    entity: `verificationResult`,
    title : `Результаты`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.VERIFICATION_ADMIN,
    ],
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <FileDoneOutlined style={{ fontSize }} />,
    entity: `verificationResultType`,
    title : `Списки результатов`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.VERIFICATION_ADMIN,
    ],
  },
  {
    color : `primary`,
    group : `Верификация`,
    icon  : fontSize => <ExpandAltOutlined style={{ fontSize }} />,
    entity: `verificationLimit`,
    title : `Лимиты`,
  },
  {
    color : `gray`,
    group : `Взыскание/Продажи`,
    icon  : fontSize => <LikeOutlined style={{ fontSize }} />,
    entity: `communicationFeedback`,
    title : `Обратная связь`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.SALE_ADMIN,
    ],
  },
  {
    color : `gray`,
    group : `Взыскание/Продажи`,
    icon  : fontSize => <PhoneOutlined style={{ fontSize }} />,
    entity: `communicationType`,
    title : `Типы коммуникации`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.SALE_ADMIN,
    ],
  },
  {
    color : `gray`,
    group : `Взыскание/Продажи`,
    icon  : fontSize => <PhoneOutlined style={{ fontSize }} />,
    entity: `campaignType`,
    title : `Типы кампаний`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.SALE_ADMIN,
    ],
  },
  {
    color : `gray`,
    group : `Взыскание/Продажи`,
    icon  : fontSize => <ThunderboltOutlined style={{ fontSize }} />,
    entity: `campaign`,
    title : `Кампании`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.SALE_ADMIN,
    ],
  },
  {
    color : `gray`,
    group : `Взыскание/Продажи`,
    icon  : fontSize => <SmileOutlined style={{ fontSize }} />,
    entity: `clientCharacteristic`,
    title : `Характеристики клиентов`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.SALE_ADMIN,
    ],
  },
  {
    color : `gray`,
    group : `Взыскание/Продажи`,
    entity: `taskStatus`,
    icon  : fontSize => <IssuesCloseOutlined style={{ fontSize }} />,
    title : `Статусы задач`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `purple`,
    group : `Взыскание`,
    icon  : fontSize => <FieldTimeOutlined style={{ fontSize }} />,
    entity: `collectionPromiseStatus`,
    title : `Статусы обещаний`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `purple`,
    group : `Взыскание`,
    icon  : fontSize => <RocketOutlined style={{ fontSize }} />,
    entity: `collectionService`,
    title : `Сервисы`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `purple`,
    group : `Взыскание`,
    icon  : fontSize => <FileTextOutlined style={{ fontSize }} />,
    entity: `creditHistoryField`,
    title : `Поля кредитной истории`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `purple`,
    group : `Взыскание`,
    icon  : fontSize => <FileTextOutlined style={{ fontSize }} />,
    entity: `biddingStatus`,
    title : `Статусы торгов`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `blue`,
    group : `Продажи`,
    icon  : fontSize => <HeartOutlined style={{ fontSize }} />,
    entity: `proposalReaction`,
    title : `Реакции на предложение`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.SALE_ADMIN,
    ],
  },
  {
    color : `blue`,
    group : `Продажи`,
    icon  : fontSize => <ProfileOutlined style={{ fontSize }} />,
    entity: `saleAdditionalInfoField`,
    title : `Поля доп. информации`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.SALE_ADMIN,
    ],
  },
  {
    color : `blue`,
    group : `Продажи`,
    icon  : fontSize => <FieldTimeOutlined style={{ fontSize }} />,
    entity: `saleFeedbackStatus`,
    title : `Статусы обратной связи`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.SALE_ADMIN,
    ],
  },
  {
    color : `blue`,
    group : `Лиды`,
    icon  : fontSize => <AuditOutlined style={{ fontSize }} />,
    entity: `leadStatus`,
    title : `Статусы задач по лидам`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.LEAD_ADMIN,
    ],
  },
  {
    color : `red`,
    group : `Хард`,
    icon  : fontSize => <FileSearchOutlined style={{ fontSize }} />,
    entity: `hmSearchPartner`,
    title : `Партнеры по розыску`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.HARD_ADMIN,
    ],
  },
  {
    color : `red`,
    group : `Хард`,
    icon  : fontSize => <CarOutlined style={{ fontSize }} />,
    entity: `hmParking`,
    title : `Стоянки`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.HARD_ADMIN,
    ],
  },
  {
    color : `red`,
    group : `Хард`,
    icon  : fontSize => <MehOutlined style={{ fontSize }} />,
    entity: `clientCondition`,
    title : `Состояния клиента`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.HARD_ADMIN,
    ],
  },
  {
    color : `red`,
    group : `Хард`,
    icon  : fontSize => <UserOutlined style={{ fontSize }} />,
    entity: `contractCondition`,
    title : `Состояния договора`,
    roles : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.HARD_ADMIN,
    ],
  },
  {
    color : `red`,
    group : `Хард`,
    icon  : fontSize => <AuditOutlined style={{ fontSize }} />,
    entity: `enforcementProceedingStatus`,
    title : `Итоговые статусы по ИП`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.HARD_ADMIN,
    ],
  },
  {
    color : `secondary`,
    group : `Банкротство`,
    icon  : fontSize => <BankOutlined style={{ fontSize }} />,
    entity: `arbitrationCourt`,
    title : `Арбитражные суды`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `secondary`,
    group : `Банкротство`,
    icon  : fontSize => <SolutionOutlined style={{ fontSize }} />,
    entity: `auctionStatus`,
    title : `Статусы торгов`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `secondary`,
    group : `Банкротство`,
    icon  : fontSize => <WalletOutlined style={{ fontSize }} />,
    entity: `pledgeStatus`,
    title : `Статусы залога`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `blue`,
    group : `Сервис`,
    icon  : fontSize => <UserOutlined style={{ fontSize }} />,
    entity: `appealSubject`,
    title : `Темы обращений`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `blue`,
    group : `Триггеры`,
    icon  : fontSize => <ProfileOutlined style={{ fontSize }} />,
    entity: `triggerBlock`,
    title : `Блоки`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `blue`,
    group : `Триггеры`,
    icon  : fontSize => <ThunderboltOutlined style={{ fontSize }} />,
    entity: `trigger`,
    title : `События`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `yellow`,
    group : `Инфо`,
    icon  : fontSize => <ExclamationCircleOutlined style={{ fontSize }} />,
    entity: `info`,
    title : `Информация`,
    roles : [
      ROLE_NAMES.INFO_EDITOR,
    ],
  },
  {
    color : `primary`,
    group : `Документы`,
    icon  : fontSize => <GroupOutlined style={{ fontSize }} />,
    entity: `documentGroup`,
    title : `Группы документов`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `primary`,
    group : `Документы`,
    icon  : fontSize => <AuditOutlined style={{ fontSize }} />,
    entity: `documentPackage`,
    title : `Пакеты документов ГАС`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `primary`,
    group : `Документы`,
    icon  : fontSize => <UnorderedListOutlined style={{ fontSize }} />,
    entity: `documentAlias`,
    title : `Наименование документов в пакете ГАС`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `primary`,
    group : `Документы`,
    icon  : fontSize => <FileWordOutlined style={{ fontSize }} />,
    entity: `documentTemplate`,
    title : `Шаблоны`,
    roles : [
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
    ],
  },
  {
    color : `black`,
    group : `Мобильное приложение`,
    icon  : fontSize => <SettingOutlined style={{ fontSize }} />,
    entity: `mobileConfig`,
    title : `Конфигурация`,
    roles : [
      ROLE_NAMES.MOBILE_CONFIG_EDITOR,
    ],
  },
  {
    color : `black`,
    group : `Мобильное приложение`,
    icon  : fontSize => <OrderedListOutlined style={{ fontSize }} />,
    entity: `mobileConfigSchema`,
    title : `JSON Схема конфигурации`,
    roles : [
      ROLE_NAMES.MOBILE_CONFIG_EDITOR,
    ],
  },
  {
    color : `black`,
    group : `Приложение анкеты`,
    icon  : fontSize => <SettingOutlined style={{ fontSize }} />,
    entity: `applicationFallback`,
    title : `Заглушки`,
  },
];

export const BREADCRUMB_NAME_MAP = {
  '/admin': `Администрирование`,
  ...(_.reduce(
    _.concat(ADMIN_MENU, DICTIONARIES_MENU),
    (res, item) => ({
      ...res,
      [item.path || `/admin/dictionaries/${item.entity}`]: item.title }),
    {},
  )),
};
