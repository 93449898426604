import * as React from 'react';
import styled from 'styled-components';

import { RcFile } from 'antd/lib/upload';
import { InputEmojiPickerButton } from 'components/Messenger/Input/InputControls/InputEmojiPickerButton';
import { InputSendButton } from 'components/Messenger/Input/InputControls/InputSendButton';
import { InputUploadButton } from 'components/Messenger/Input/InputControls/InputUploadButton';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconButtons = styled.div`
  display: flex;
  margin-right: 16px;
`;

interface IComponentProps {
  acceptString: string;
  isDisabled: boolean;
  isLoading: boolean;
  onEmojiClose: () => void;
  onEmojiSelect: (emoji: any) => void;
  onSend: () => void;
  onUpload: (file: RcFile) => void;
  onUploadClick: () => void;
}

export const InputControls: React.FC<IComponentProps> = ({
  acceptString,
  isDisabled,
  isLoading,
  onEmojiClose,
  onEmojiSelect,
  onSend,
  onUpload,
  onUploadClick,
}) => (
  <Wrapper>
    <IconButtons>
      <InputEmojiPickerButton disabled={isLoading} onClose={onEmojiClose} onSelect={onEmojiSelect} />
      <InputUploadButton
        accept={acceptString}
        // @ts-ignore
        customRequest={onUpload}
        onClick={onUploadClick}
      />
    </IconButtons>

    <InputSendButton disabled={isDisabled} onClick={onSend} />
  </Wrapper>
);
