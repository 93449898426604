import * as React from 'react';
import { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import {
  uploadDocumentTemplates,
  uploadDocumentTemplatesArchive,
} from 'models/admin/api';

import { InboxOutlined } from '@ant-design/icons';
import {
  Modal,
  notification,
  Select,
  Upload,
} from 'antd';

const ContentWrapper = styled.div`
  .ant-upload-list-text {
    color: ${palette.textPrimaryColor};
  }

  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: ${palette.black10Color};
  }

  .ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    color: ${palette.textSecondaryColor};
  }

  .ant-upload-list-item-card-actions .anticon {
    color: ${palette.textSecondaryColor};
  }
`;

const StyledUpload = styled(Upload.Dragger)`
  border-color: ${palette.primaryColor} !important;
  border-radius: 8px !important;
`;

const UploadIcon = styled.p`
  color: ${palette.primaryColor};
  font-size: 32px;
`;

const UploadText = styled.p`
  color: ${palette.primaryColor};
`;

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

const ACCEPT_ARRAY = [
  `application/msword`,
  `.docx`,
];

const ACCEPT_ARCHIVE = [
  `.zip`,
  `application/zip`,
];

const ACCEPT_STR = {
  archive: _.join(ACCEPT_ARCHIVE),
  array  : _.join(ACCEPT_ARRAY),
};

const UPLOAD_TYPES = [
  {
    label: `Массив`,
    value: `array`,
  },
  {
    label: `Архив`,
    value: `archive`,
  },
];

interface IComponentProps {
  isVisible: boolean;
  onClose: () => void;
  onReload?: () => void;
}

export const DocumentTemplateUploadModal: React.FC<IComponentProps> = ({ isVisible, onClose, onReload }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [type, setType] = useState(UPLOAD_TYPES[0].value);

  const handleTypeChange = (value: React.SetStateAction<string>) => {
    setType(value);
    setSelectedFiles([]);
  };

  const handleUpload = async (callback: (() => void) | undefined) => {
    const notificationKey = `documentTemplateUploadNotification`;

    notification.info({
      key    : notificationKey,
      message: `Загрузка документов...`,
    });

    const defaultError = `При загрузке документов произошла ошибка`;

    try {
      if (type === `array`) {
        parseResponse({
          defaultError,
          errorPath: `data.message`,
          response : await uploadDocumentTemplates(selectedFiles),
        });
      } else {
        parseResponse({
          defaultError,
          errorPath: `data.message`,
          response : await uploadDocumentTemplatesArchive(selectedFiles),
        });
      }
      notification.success({
        key    : notificationKey,
        message: `Шаблоны обновлены`,
      });

      if (_.isFunction(callback)) callback();
    } catch (error) {
      showError({ defaultError, error });
    }
  };

  const handleFileChange = (info: { fileList: any; }) => {
    const { fileList } = info;
    setSelectedFiles(fileList.map((file: { originFileObj: any; }) => file.originFileObj || file));
  };

  const handleClose = () => {
    onClose();
    setSelectedFiles([]);
  };

  const handleSubmit = () => {
    handleUpload(onReload);
    handleClose();
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      maskClosable={false}
      okButtonProps={{ disabled: _.isEmpty(selectedFiles) }}
      okText='Загрузить'
      onCancel={handleClose}
      onOk={handleSubmit}
      open={isVisible}
      title='Обновление шаблонов'
    >
      <ContentWrapper>
        <StyledSelect
          // @ts-ignore
          onChange={handleTypeChange}
          options={UPLOAD_TYPES}
          placeholder='Способ загрузки'
          value={type}
        />

        <StyledUpload
          // @ts-ignore
          accept={ACCEPT_STR[type]}
          beforeUpload={() => false}
          customRequest={_.noop}
          fileList={selectedFiles}
          multiple={type === `array`}
          onChange={handleFileChange}
          {...(type === `archive` ? { maxCount: 1 } : {})}
        >
          <UploadIcon><InboxOutlined /></UploadIcon>
          <UploadText>
            Кликните или перетащите сюда {type === `array` ? `шаблоны` : `zip-архив с шаблонами`} для загрузки
          </UploadText>
        </StyledUpload>
      </ContentWrapper>
    </Modal>
  );
};
