import { createAction } from 'redux-actions';

export const model = `dialogs`;

const ca = (type: string, meta?: any) => createAction(`${model}/${type}`, (payload: any) => payload, () => meta);

export const ADD_MESSAGE = `ADD_MESSAGE`;
export const CREATE = `CREATE`;
export const CONNECTION_RESTORED = `CONNECTION_RESTORED`;
export const GET = `GET`;
export const GET_MESSAGES = `GET_MESSAGES`;
export const GET_MESSAGES_MULTIPLE = `GET_MESSAGES_MULTIPLE`;
export const GET_SINGLE = `GET_SINGLE`;
export const MERGE_ITEMS = `MERGE_ITEMS`;
export const REMOVE = `REMOVE`;
export const REMOVE_MESSAGE = `REMOVE_MESSAGE`;
export const RESET = `RESET`;
export const REPLY = `REPLY`;
export const SEARCH = `SEARCH`;
export const SEND_MESSAGE = `SEND_MESSAGE`;
export const SET = `SET`;
export const SET_INPUT_VALUE = `SET_INPUT_VALUE`;
export const SET_LOADING = `SET_LOADING`;
export const SET_MESSAGE_READ = `SET_MESSAGE_READ`;
export const UPDATE = `UPDATE`;
export const UPDATE_MESSAGE = `UPDATE_MESSAGE`;

export const create = ca(CREATE);
export const get = ca(GET);
export const getMessages = ca(GET_MESSAGES);
export const getSingle = ca(GET_SINGLE);
export const remove = ca(REMOVE);
export const reply = ca(REPLY);
export const reset = ca(RESET);
export const search = ca(SEARCH);
export const sendMessage = ca(SEND_MESSAGE);
export const set = ca(SET);
export const setLoading = ca(SET_LOADING);
export const setMessageRead = ca(SET_MESSAGE_READ);
export const update = ca(UPDATE);
