import React from 'react';
import _ from 'lodash';

import {
  getColumn,
  getSort,
  prettifyNumber,
  sortAlphabetically,
} from 'helper';

import { CellId } from 'components/DefaultTable/CellId/CellId';

const COMMON = _.map([
  {
    key   : `loanId`,
    sorter: getSort(`loanId`, sortAlphabetically),
    title : `ID займа`,
    render: v => <CellId value={v} />,
  },
  {
    key   : `carInfo`,
    sorter: getSort(`carInfo`),
    title : `Авто`,
    width : 150,
  },
  {
    key   : `contractNumber`,
    sorter: getSort(`contractNumber`),
    title : `Номер договора`,
    width : 150,
  },
  {
    align : `right`,
    key   : `loanAmount`,
    render: prettifyNumber,
    sorter: getSort(`loanAmount`),
    title : `Сумма займа`,
    width : 100,
  },
  {
    key   : `overdueDebtPaymentCount`,
    render: prettifyNumber,
    title : `Количество просроченных платежей`,
    width : 120,
  },
], getColumn);

const REMAINS_IN_RUBLES = _.map([
  {
    align : `right`,
    key   : `totalDebt`,
    title : `Задолженность общая`,
    width : 120,
    render: prettifyNumber,
  },
  {
    align : `right`,
    key   : `totalOverdueDebt`,
    title : `Задолженность просроченная`,
    width : 120,
    render: prettifyNumber,
  },
  {
    align : `right`,
    key   : `totalMainDebt`,
    title : `Основной долг (остаток)`,
    width : 100,
    render: prettifyNumber,
  },
  {
    align : `right`,
    key   : `overdueMainDebt`,
    title : `Основной долг (просроченный)`,
    width : 140,
    sorter: getSort(`overdueMainDebt`),
    render: prettifyNumber,
  },
  {
    align : `right`,
    key   : `totalDebtInterest`,
    title : `Проценты`,
    width : 100,
    render: prettifyNumber,
  },
  {
    align : `right`,
    key   : `overdueDebtInterest`,
    title : `Проценты (Просроченные) `,
    sorter: getSort(`overdueDebtInterest`),
    width : 140,
    render: prettifyNumber,
  },
  {
    align : `right`,
    key   : `totalDebtPenalty`,
    title : `Пени`,
    width : 100,
    sorter: getSort(`totalDebtPenalty`),
    render: prettifyNumber,
  },
  {
    align : `right`,
    key   : `totalDebtFine`,
    title : `Штрафы`,
    width : 100,
    render: prettifyNumber,
  },
  {
    align : `right`,
    key   : `totalDebtStateDuty`,
    title : `Госпошлина`,
    width : 100,
    render: prettifyNumber,
  },
  {
    align : `right`,
    key   : `totalDebtCommission`,
    title : `Комиссии`,
    width : 100,
    render: prettifyNumber,
  },
], getColumn);

const LOAN_OVERDUE_DAYS = _.map([
  {
    key   : `overdueDebtTerm`,
    title : `Срок ПЗ в днях`,
    width : 100,
    render: prettifyNumber,
  },
  {
    key   : `mainDebtTerm`,
    title : `Срок ПЗ ОД в днях`,
    width : 100,
    render: prettifyNumber,
  },
  {
    key   : `debtInterestTerm`,
    title : `Срок ПЗ % в днях`,
    width : 100,
    render: prettifyNumber,
  },
], getColumn);

export const DEBT_COLUMNS = [
  ...COMMON,
  ...REMAINS_IN_RUBLES,
  ...LOAN_OVERDUE_DAYS,
];

