import React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import {
  updateClientCondition as updateClientConditionAction,
  updateContractCondition as updateContractConditionAction,
} from 'models/clients/actions';
import {
  getClient as getClientSelector,
  getClientLoanById as getClientLoanByIdSelector,
  getIsLoading as isClientLoadingSelector,
} from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';

import { CaretDownOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { DefaultDropdown } from 'components/DefaultDropdown/DefaultDropdown';

const Wrap = styled.span`
  cursor: pointer;
`;

interface IProps {
  className?: string,
  entity: `contract` | `client`,
  loanId?: string
  personId: string,
}

export const ConditionDropdown: React.FC<IProps> = ({ className, entity, loanId, personId }) => {
  const dispatch = useDispatch();
  const { client, isClientLoading, loanFromStore } = useSelector(state => ({
    client         : getClientSelector(state, personId),
    isClientLoading: isClientLoadingSelector(state),
    loanFromStore  : getClientLoanByIdSelector(state, personId, loanId),
  }));
  const loan = entity === `contract` ? loanFromStore : null;
  const initialValue = entity === `client`
    ? _.get(client, `clientConditionId`)
    : _.get(loan, `contractConditionId`);

  const dictionaryEntity = `${entity}Condition`;
  const [isDictionariesLoading, dictionaries] = useDictionaries([dictionaryEntity], true, true);
  const items = dictionaries?.[dictionaryEntity];
  const menuItems = _.map(dictionaries?.[dictionaryEntity], ({ id, name }) => ({ key: id, label: name }));
  const label = _.get(_.find(items, { id: initialValue }), `name`, `не задано`);

  const updateClientCondition = (data: any) => dispatch(updateClientConditionAction(data));
  const updateContractCondition = (data: any) => dispatch(updateContractConditionAction(data));

  const handleChange = ({ key }: { key: string | number }) => {
    const newId = parseInt((key as string), 10);

    if (entity === `client`) {
      updateClientCondition({ personId, clientConditionId: newId });
    } else if (entity === `contract`) {
      updateContractCondition({ personId, contractConditionId: newId, loanId });
    }
  };

  const isLoading = isDictionariesLoading || isClientLoading;

  return (
    <Spin spinning={isLoading}>
      <DefaultDropdown
        menu={{ items: menuItems, onClick: handleChange }}
        trigger={[`click`]}
      >
        <Wrap className={className}>
          {label}
          <CaretDownOutlined />
        </Wrap>
      </DefaultDropdown>
    </Spin>
  );
};
