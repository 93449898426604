import { useRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import {
  showError,
  validateFileType,
} from 'helper';

const StyledInputFile = styled.input.attrs(() => ({
  type: `file`,
}))`
  display: none;
`;

const validateFiles = (files: File[], acceptString: string): boolean => {
  try {
    _.forEach(files, file => {
      if (!validateFileType(acceptString, file)) {
        const message = `Файл ${file.name} имеет неподходящее расширение\n Подходящие расширения: ${acceptString}`;

        showError({ error: message });

        throw new Error(`file validation error`);
      }
    });

    return true;
  } catch (e) {
    return false;
  }
};

interface IUseFileInput {
  acceptedFiles: string[];
  onFilesSelected: (files: File[]) => void;
}

interface UseFileInput {
  ACCEPTED_FILES_STRING: string;
  clearInputValue: () => void;
  handleFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  openFileInput: () => void;
  StyledInputFile: typeof StyledInputFile;
}

export const useFileInput = ({ acceptedFiles, onFilesSelected }:IUseFileInput): UseFileInput => {
  const inputRef = useRef(null);
  const openFileInput = () => inputRef.current.click();
  const clearInputValue = () => {
    inputRef.current.value = ``;
  };
  const ACCEPTED_FILES_STRING = _.join(acceptedFiles);

  const handleFileSelect = e => {
    const { files } = e.target;

    if (files && files.length && validateFiles(files, ACCEPTED_FILES_STRING)) {
      if (onFilesSelected) {
        onFilesSelected(files);
        clearInputValue();
      }
    }
  };
  return {
    ACCEPTED_FILES_STRING,
    clearInputValue,
    StyledInputFile,
    inputRef,
    handleFileSelect,
    openFileInput,
  };
};
