import _ from 'lodash';
import moment from 'moment';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { getFullName as getFullNameSelector } from 'models/user/selectors';

import { notification } from 'antd';

import {
  GET,
  model as namespace,
  POST,
  SET,
  SET_ITEMS,
  SET_LOADING,
  SET_TABLE_STATE,
} from './actions';
import * as api from './api';
import {
  getRequestParams,
  getTableState as getTableStateSelector,
} from './selectors';

const initialState = {
  isLoading : false,
  items     : [],
  tableState: {
    filters   : {},
    pagination: {
      defaultCurrent : 1,
      defaultPageSize: 10,
      page           : 1,
      pageSize       : 10,
      pageSizeOptions: [`10`, `25`, `50`],
      size           : `small`,
      total          : 10,
    },
    sorter: {
      columnKey: `bankruptCreateDtm`,
      field    : `bankruptCreateDtm`,
      order    : `descend`,
    },
  },
};

export default {
  namespace,
  state  : initialState,
  effects: {
    *[POST](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const {
        bankruptId,
        callback,
        loanId,
        personId,
        ...data
      } = action.payload;
      const defaultError = `При сохранении банкротства произошла ошибка`;

      try {
        if (bankruptId) {
          const updateOperatorName = yield select(getFullNameSelector);
          const updateDtm = moment.utc();

          parseResponse({
            defaultError,
            response: yield call(
              api.update,
              bankruptId,
              {
                ...data,
                updateOperatorName,
                updateDtm,
                personId,
              },
            ),
          });
        } else {
          const operatorName = yield select(getFullNameSelector);
          const createDtm = moment.utc();

          parseResponse({
            defaultError,
            response: yield call(
              api.create,
              {
                ...data,
                loanId,
                operatorName,
                createDtm,
                personId,
              },
            ),
          });
        }

        notification.success({ message: `Банкротство сохранено` });
        yield put({ type: GET });
        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке банкротов произошла ошибка`;
      try {
        const { callback } = action.payload || {};

        const requestParams = yield select(getRequestParams);

        const { data: items, fullCount: total } = parseResponse({
          defaultError,
          response: yield call(api.get, requestParams),
        });

        yield put({ type: SET_ITEMS, payload: items });
        const currentTableState = yield select(getTableStateSelector);
        yield put({ type   : SET_TABLE_STATE,
          payload: {
            ...currentTableState,
            pagination: {
              ...currentTableState.pagination,
              total,
            },
          } });

        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_TABLE_STATE](action, { put, select }) {
      const { callback, ...tableState } = action.payload;
      const currentTableState = yield select(getTableStateSelector);
      const isFiltersChanged = !_.isEqual(
        _.omitBy(tableState?.filters, _.isNil),
        _.omitBy(currentTableState?.filters, _.isNil),
      );
      yield put({
        type   : SET,
        payload: {
          tableState: {
            ...tableState,
            pagination: isFiltersChanged
              ? {
                ...tableState.pagination,
                current       : 1,
                defaultCurrent: 1,
                page          : 1,

              }
              : tableState.pagination,
          },
        } });
      if (_.isFunction(callback)) callback();
    },
  },
  reducers: {
    [SET](state, { payload = {} }) {
      return {
        ...state,
        ...payload,
      };
    },

    [SET_ITEMS](state, { payload: items = [] }) {
      return {
        ...state,
        items,
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
};
