import api from 'api/index';

import { ISearchCallParams } from 'models/calls/types';

export const get = (personId: string, data: ISearchCallParams) => api.post(`/call/person/${personId}/search`, data);

export const getCallRecord = (recordUrl: string) => api.post(`/call/record`, { recordUrl },
  {
    responseType  : `blob`,
    validateStatus: () => true,
  });
