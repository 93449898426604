import { useSelector } from 'dva';

import { hasIntersection } from 'helper';

import { TRoleName } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

export function useHasRoles() {
  const userRoles = useSelector(getRoleNamesSelector) || [];

  function hasRoles(roles: TRoleName[]) {
    return hasIntersection(userRoles, roles);
  }

  return hasRoles;
}
