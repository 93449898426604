import _ from 'lodash';
import { createSelector } from 'reselect';

import { hasIntersection } from 'helper';

import { CAMPAIGN_RIGHT_COLUMNS_MAP } from 'models/dictionaries/constants';
import { getItems as getDictionary } from 'models/dictionaries/selectors';
import { getItemsIdsByNames as getRolesIdsByNames } from 'models/roles/selectors';
import { getId as getCurrentUserId } from 'models/user/selectors';

import { model } from './actions';

import { DEFAULT_PREFERENCES } from './constants';

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(getState, ({ items }) => items);

export const getItemsSortedByFullName = createSelector(getState, ({ items }) => _.sortBy(items, `user.fullName`));

export const getItemsNames = createSelector(getItems, items => _.map(items, `name`));

export const getItem = createSelector(
  [getItems, (state, id) => id],
  (items, id) => _.find(items, { id }),
);

export const getSearchString = createSelector(getState, ({ searchString }) => searchString);

export const getItemsFiltered = createSelector(
  [getItems, getSearchString],
  (items, search) => (search
    ? _.filter(items, ({ user }) => (
      _.includes(_.toLower(user.fullName), _.toLower(search))
      || _.includes(_.toLower(user.email), _.toLower(search))
    ))
    : items
  ),
);

export const getItemsByUserIds = createSelector(
  [getItems, (state, userIds) => userIds],
  (items, userIds) => _.filter(items, ({ userId }) => _.includes(userIds, userId)),
);

export const getItemsByRoleNames = createSelector(
  [getItems, getRolesIdsByNames],
  (items, roleIds) => _.filter(items, ({ user }) => hasIntersection(user.roleIds, roleIds)),
);

export const getItemsByCampaignAccessRight = createSelector(
  [
    getItemsSortedByFullName,
    (s, campaignName) => campaignName,
    (s, c, right) => right,
    state => getDictionary(state, `campaign`),
  ],
  (items, campaignName, right = `write`, campaigns) => {
    const roleColumns = CAMPAIGN_RIGHT_COLUMNS_MAP[right];
    const roleIds = _.reduce(
      campaigns,
      (acc, { name, ...campaign }) => ((name === campaignName || campaignName === `all`)
        ? [...acc, ..._.compact(_.values(_.pick(campaign, roleColumns)))]
        : acc),
      [],
    );

    return _.filter(items, ({ user }) => hasIntersection(user.roleIds, roleIds));
  },
);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);

export const isLoaded = createSelector(getState, ({ isLoaded: _isLoaded }) => _isLoaded);

export const getCurrent = createSelector(getState, ({ current }) => current || {});

export const getCurrentNotificationLists = createSelector(
  getCurrent,
  operator => (operator?.user?.notificationLists || []),
);

export const getCurrentPreferences = createSelector(
  getCurrent,
  operator => (operator?.preferences ? operator.preferences : DEFAULT_PREFERENCES),
);

export const getCurrentFromList = createSelector(
  [getItems, getCurrentUserId],
  (items, userId) => _.find(items, { userId }),
);

export const getAttorney = createSelector(
  getCurrentPreferences,
  ({ attorney }) => attorney,
);
