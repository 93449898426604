import {
  buildThemeHelpers,
  TStyleLambda,
} from './build-theme-helpers';

const VITE_REACT_APP_ENV = import.meta.env.VITE_REACT_APP_ENV;

export const lightTheme = {
  info: {
    name: `light`,
  },
  palette: {
    baseAccentColor        : `#FFCE54`,
    secondaryAccentColor   : `#F9A056`,
    blackestColor          : `#000`,
    blackColor             : `#222222`,
    blackTransparent85Color: `#262626`,
    lightGrayColor         : `#DADADA`,

    darkBlueColor   : `${VITE_REACT_APP_ENV === `test` ? `#563752` : `#00475D`}`,
    lightBlueColor  : `${VITE_REACT_APP_ENV === `test` ? `#F45681` : `#1890FF`}`,
    lighterBlueColor: `${VITE_REACT_APP_ENV === `test` ? `#F57CA8` : `#40a9ff`}`,

    transparentBlackColor: `rgba(0, 0, 0, 0.16)`,
    black500Color        : `#333333`,
    blueColor            : `#E9FBFC`,
    blue20Color          : `rgba(45, 156, 219, 0.2)`,
    blue5Color           : `rgba(45, 156, 219, 0.05)`,
    blue500Color         : `#2F80ED`,
    errorColor           : `#FF4D4F`,
    grayColor            : `#656D78`,
    gray5Color           : `rgba(101, 109, 120, 0.05)`,
    greenColor           : `#62B157`,
    green20Color         : `rgba(98, 177, 87, 0.2)`,
    green5Color          : `rgba(98, 177, 87, 0.05)`,
    primaryColor         : `#40BAB5`,
    purpleColor          : `#220084`,
    purple500Color       : `#9B51E0`,
    purple5Color         : `rgba(155, 81, 224, 0.05)`,
    redColor             : `#DB3D40`,
    red5Color            : `rgba(219, 61, 64, 0.05)`,
    orangeColor          : `orange`,
    yellow5Color         : `rgba(230, 210, 0, 0.05)`,
    yellow500Color       : `#E6D200`,

    whiteColor   : `#fff`,
    white170Color: `#F7F7F7`,
    white160Color: `#F6F6F6`,
    white150Color: `#F5F5F5`,
    white100Color: `#f0f0f0`,
    white95Color : `#f2f2f2`,
    white75Color : `#BFBFBF`,
    white60Color : `#999999`,
    white5Color  : `rgba(255, 255, 255, 0.05)`,

    primary800Color: `#005a77`,
    primary700Color: `#336C7D`,
    primary600Color: `#2E9A9F`,
    primary500Color: `#40BAB5`,
    primary400Color: `#6CD5C7`,
    primary100Color: `#DAFBEE`,
    primary5Color  : `#F5FCFB`,

    secondary600Color: `#D67B3E`,
    secondary500Color: `#F9A056`,
    secondary400Color: `#FBBE7F`,
    secondary100Color: `#FEF3DD`,
    secondary5Color  : `rgba(249, 160, 86, 0.05)`,

    black100Color: `#22284B`,
    black80Color : `#596A8C`,
    black60Color : `#9EAFC7`,
    black50Color : `#A4A4A4`,
    black45Color : `#BCBCBC`,
    black40Color : `#C5CFDD`,
    black35Color : `#d8d8d8`,
    black30Color : `#E2E7EE`,
    black20Color : `#EAE9ED`,
    black10Color : `#F5F7F9`,
    black5Color  : `#FAFBFC`,

    alert600Color: `#DB3D40`,
    alert500Color: `#FF5F54`,
    alert400Color: `#FF917E`,
    alert100Color: `#FFEFEE`,
    alert5Color  : `#FFF7F6`,

    backgroundColor      : `#FFFFFF`,
    backgroundAccentColor: `${VITE_REACT_APP_ENV === `test` ? `#563752` : `#00475D`}`,

    textPrimaryColor  : `#22284B`,
    textSecondaryColor: `#596A8C`,
    textDisabledColor : `rgba(0, 0, 0, 0.25)`,

    borderDefaultColor: `#EAE9ED`,
    borderAccentColor : `#E2E7EE`,
  },
  breakpoints: {
    xs : `360px`,
    sm : `540px`,
    md : `768px`,
    mm : `1000px`,
    lg : `1200px`,
    xl : `1346px`,
    xxl: `1800px`,
  },
  shadows: {
    basic: `0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08)`,
    card : `0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #C0CDD91F, 0px 4px 8px 0px #C0CDD92E`,
    // eslint-disable-next-line max-len
    image: `0px 4px 8px rgba(192, 205, 217, 0.18), 0px 0px 2px rgba(192, 205, 217, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04)`,
  },
  zIndex: {
    header   : `5`,
    subheader: `3`,
  },
  height: {
    headerMobile: `54px`,
    headerTablet: `64px`,
  },
};

export const darkTheme = {
  ...lightTheme,
  info: {
    name: `dark`,
  },
  palette: {
    ...lightTheme.palette,

    primary600Color: `#2E9A9F`,
    primary500Color: `rgba(46, 154, 159, 0.8)`,
    primary400Color: `rgba(46, 154, 159, 0.4)`,
    primary100Color: `rgba(46, 154, 159, 0.1)`,
    primary5Color  : `rgba(46, 154, 159, 0.05)`,

    alert600Color: `#DB3D40`,
    alert500Color: `rgba(219, 61, 64, 0.8)`,
    alert400Color: `rgba(219, 61, 64, 0.4)`,
    alert100Color: `rgba(219, 61, 64, 0.3)`,
    alert5Color  : `rgba(219, 61, 64, 0.1)`,

    black100Color: `#F8F9FD`,
    black80Color : `#EBEDF7`,
    black60Color : `#C9D1EF`,
    black40Color : `#95A2DD`,
    black30Color : `#334180`,
    black10Color : `#1D2139`,
    black5Color  : `#10121D`,

    backgroundColor      : `#171A2F`,
    backgroundAccentColor: `${VITE_REACT_APP_ENV === `test` ? `#10121D` : `#10121D`}`,

    textPrimaryColor  : `#F8F9FD`,
    textSecondaryColor: `#95A2DD`,
    textDisabledColor : `rgba(255, 255, 255, 0.25)`,

    borderDefaultColor: `#1D2139`,
    borderAccentColor : `#334180`,
  },
  shadows: {
    ...lightTheme.shadows,
    // eslint-disable-next-line max-len
    image: `0px 4px 8px rgba(30, 35, 66, 0.18), 0px 0px 2px rgba(30, 35, 66, 0.12), 0px 0px 1px rgba(30, 35, 66, 0.04)`,
  },
};

export type TTheme = typeof lightTheme;

// eslint-disable-next-line
declare module 'styled-components' {
  export interface DefaultTheme extends TTheme {}
}

export const getLightStyleOr = (
  lightStyle: TStyleLambda,
  darkStyle: TStyleLambda,
) => (props: any) => {
  if (props.theme.info.name === `light`) {
    return () => lightStyle;
  }
  return () => darkStyle;
};

const {
  breakpoints,
  height,
  info,
  palette,
  shadows,
  zIndex,
} = buildThemeHelpers(lightTheme);

export { info, palette, breakpoints, shadows, zIndex, height };
