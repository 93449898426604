export const INITIAL_STATE = {
  current: {
    fullName  : ``,
    id        : null,
    name      : ``,
    patronymic: ``,
    role      : null,
    roles     : [],
    surname   : ``,
    type      : 1,
  },
  isLoaded    : false,
  isLoading   : false,
  isPushInited: false,
  items       : [],
};
