import styled from 'styled-components';
import { palette } from 'styles/theme';

import { DefaultTable } from 'components/DefaultTable/DefaultTable';

export const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div`
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
`;
export const Table = styled(DefaultTable)`
  border: 1px solid ${palette.black30Color};
`;
