import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import {
  CheckOutlined,
  CloseOutlined,
  SendOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Popconfirm,
} from 'antd';
import { CLOSE_BUTTON_STATUSES } from 'pages/PageClient/Single/Docs/SignDocuments/constants';

const StyledButton = styled(Button)`
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const SignDocumentActionControls = ({
  closeSignDocument,
  fileStatusId,
  finalizeSignDocument,
  id,
  isCloseSignDocumentButton,
  isFinalizeDocumentButton,
}) => {
  if (!(
    isFinalizeDocumentButton
    || (isCloseSignDocumentButton && _.includes(CLOSE_BUTTON_STATUSES, fileStatusId))
  )) return null;

  const menu = {
    items: [
      ...(isFinalizeDocumentButton ? [
        {
          key  : `finalize`,
          label: (
            <Popconfirm
              cancelText='Нет'
              okButtonProps={{ type: `primary` }}
              okText='Да'
              onConfirm={() => finalizeSignDocument(id)}
              placement='topLeft'
              title='Одобрить черновик и перевести документ в статус "Новый"? Это сделает его видимым для клиента'
            >
              <StyledButton type='text'>
                Одобрить
                <CheckOutlined />
              </StyledButton>
            </Popconfirm>
          ),
        },
        {
          key  : `finalizeWithSms`,
          label: (
            <Popconfirm
              cancelText='Нет'
              okButtonProps={{ type: `primary` }}
              okText='Да'
              onConfirm={() => finalizeSignDocument(id, true)}
              placement='topLeft'
              title='Одобрить черновик и перевести документ в статус "Новый" с отправкой SMS-сообщения клиенту?'
            >
              <StyledButton type='text'>
                Одобрить и отправить смс
                <SendOutlined />
              </StyledButton>
            </Popconfirm>
          ),
        },
      ] : []),
      ...((isCloseSignDocumentButton && _.includes(CLOSE_BUTTON_STATUSES, fileStatusId)) ? [{
        key  : `close`,
        label: (
          <Popconfirm
            cancelText='Нет'
            okButtonProps={{ danger: true }}
            okText='Да'
            onConfirm={() => closeSignDocument(id)}
            placement='topLeft'
            title='Вы уверены, что хотите закрыть задачу?'
          >
            <StyledButton danger type='text'>
              Закрыть
              <CloseOutlined />
            </StyledButton>
          </Popconfirm>
        ),
      }] : []),
    ],
  };
  return (
    <Dropdown menu={menu} trigger='click'>
      <SettingOutlined />
    </Dropdown>
  );
};
