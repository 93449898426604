import React, { useState } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { hasIntersection } from 'helper';

import { useDictionaries } from 'models/dictionaries/hooks';
import {
  COLLECTION_ADMIN,
  COLLECTION_BATCH_CLOSER,
  COLLECTION_TASK_UPLOADER,
  DOCUMENT_UPLOADER,
  HARD_ADMIN,
  IMHA_ADMIN,
  LEGAL_ADMIN,
  SALE_ADMIN,
  SALE_BATCH_CLOSER,
  SALE_TASK_UPLOADER,
  SUPER_ADMIN,
} from 'models/roles/constants';
import {
  assign as assignAction,
  deleteTasks as deleteAction,
  exportExcel as exportExcelAction,
  get as getTasksAction,
  getMultiple as multipleTaskGetAction,
  reassign as reassignAction,
  setSearchPartnerMultiple as setSearchPartnerMultipleAction,
  setStatusMultiple as setStatusMultipleAction,
  terminate as terminateAction,
  uploadFile as uploadFileAction,
} from 'models/task/actions';
import {
  ACCEPTED_FILES,
  CAMPAIGNS_WITH_EXPORT_TO_EXCEL,
  CAMPAIGNS_WITH_MULTIPLE_PARTNER_EDIT,
  CAMPAIGNS_WITH_PRE_COURT_NOTIFY,
  MAX_PRE_COURT_EXPORT_TASKS,
} from 'models/task/constants';
import { getStatusesToSelectFiltered } from 'models/task/helpers';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { useEffectDebounced } from 'hooks/useEffectDebounced';
import { useFileInput } from 'hooks/useFileInput';

import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FileExcelOutlined,
  FileWordOutlined,
  MoreOutlined,
  SettingOutlined,
  SolutionOutlined,
  SyncOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { notification } from 'antd';
import { DefaultDropdown } from 'components/DefaultDropdown/DefaultDropdown';
import CreateModal from 'components/Modal/Create';
import LegalNotificationDownload from 'components/Modal/LegalNotificationDownload';
import { subHeaderButtonDefaultMixin } from 'components/SubHeader/SubHeaderButtonDefault';

import {
  FORM_FIELDS,
  NO_ROWS_SELECTED_MESSAGE,
} from './constants';

const ShowMoreControlsButton = styled.button`
  ${subHeaderButtonDefaultMixin};
  position: absolute;
  right: 21px;
  bottom: -40px;
  height: 32px;
  z-index: 3;

  .anticon:first-child {
    margin-right: 0;
  }

  @media(min-width: ${breakpoints.sm}) {
    bottom: auto;
    margin-left: 16px;
  }

  @media(max-width: ${breakpoints.md}) {
    bottom: 16px;
    right: 18px;
  }
`;

const getMultipleStatusEditInitialValues = item => ({
  ...item,
  searchPartnerId: item.searchPartner?.id,
  taskStatusId   : item.taskStatus?.id,
});

const deleteTaskRoles = [
  COLLECTION_BATCH_CLOSER,
  SALE_BATCH_CLOSER,
  SALE_ADMIN,
  COLLECTION_ADMIN,
  HARD_ADMIN,
  LEGAL_ADMIN,
  SUPER_ADMIN,
];
const terminateTaskRoles = [
  COLLECTION_BATCH_CLOSER,
  SALE_BATCH_CLOSER,
  SUPER_ADMIN,
];
const taskUploaderRoles = [
  COLLECTION_TASK_UPLOADER,
  SALE_TASK_UPLOADER,
  SUPER_ADMIN,
];
const serviceNotificationRoles = [
  DOCUMENT_UPLOADER,
  SUPER_ADMIN,
];
const multipleStatusEditRoles = [IMHA_ADMIN, SUPER_ADMIN];

const TaskControls = ({ campaignId, campaignName, selectedRowKeys, tasks }) => {
  const dispatch = useDispatch();

  const [, dictionaries] = useDictionaries([
    `hmSearchPartner`,
    `taskStatus`,
  ]);

  const { hmSearchPartner: hmSearchPartners, taskStatus: taskStatusesDict } = dictionaries;

  const taskStatuses = _.orderBy(taskStatusesDict, `description`, `desc`);
  const userRoles = useSelector(getRoleNamesSelector);

  const assign = () => dispatch(assignAction({ ids: selectedRowKeys }));
  const exportExcel = () => dispatch(exportExcelAction({ ids: selectedRowKeys }));

  const getTasksRaw = () => dispatch(getTasksAction());
  const getTasks = useEffectDebounced(getTasksRaw, 200);
  const multipleTaskGet = () => dispatch(multipleTaskGetAction({ taskIds: selectedRowKeys }));
  const reassign = () => dispatch(reassignAction({ ids: selectedRowKeys }));
  const setSearchPartnerMultiple = data => dispatch(setSearchPartnerMultipleAction(data));
  const setStatusMultiple = data => dispatch(setStatusMultipleAction(data));
  const terminate = () => dispatch(terminateAction({ ids: selectedRowKeys }));
  const deleteTasks = () => dispatch(deleteAction({ ids: selectedRowKeys }));
  const uploadExcel = file => dispatch(uploadFileAction({ file }));

  const onMultipleStatusEdit = values => {
    const selectedTasks = _.filter(tasks, ({ id }) => _.includes(selectedRowKeys, id));
    const prevStatusId = _.get(_.head(selectedTasks), `taskStatusId`);

    const { searchPartnerId, taskStatusId } = values;
    if (values.taskStatusId !== prevStatusId) setStatusMultiple({ ids: selectedRowKeys, taskStatusId });
    if (values.searchPartnerId) setSearchPartnerMultiple({ ids: selectedRowKeys, searchPartnerId });
  };

  const onFilesSelected = ([file]) => uploadExcel(file);

  const [isLegalNotifyModalOpen, setIsLegalNotifyModalOpen] = useState(false);
  const [legalNotifyModalType, setLegalNotifyModalType] = useState(undefined);
  const [isMultipleStatusEditModalOpen, setIsMultipleStatusEditModalOpen] = useState(false);

  const closeLegalNotifyModal = () => {
    setIsLegalNotifyModalOpen(false);
    setLegalNotifyModalType(undefined);
  };
  const closeMultipleStatusEditModal = () => setIsMultipleStatusEditModalOpen(false);
  const openLegalNotifyModal = type => {
    if (selectedRowKeys?.length > MAX_PRE_COURT_EXPORT_TASKS) {
      notification.warning({
        message: `Нельзя сформировать больше ${MAX_PRE_COURT_EXPORT_TASKS} уведомлений за раз`,
      });
      return;
    }
    setIsLegalNotifyModalOpen(true);
    setLegalNotifyModalType(type);
  };
  const openMultipleStatusEditModal = () => setIsMultipleStatusEditModalOpen(true);

  const isRowsSelected = !_.isEmpty(selectedRowKeys);

  const isAllowedToUploadExcel = hasIntersection(userRoles, taskUploaderRoles);
  const isAllowedToDeleteTasks = hasIntersection(userRoles, deleteTaskRoles);
  const isAllowedToTerminateTasks = hasIntersection(userRoles, terminateTaskRoles);
  const isAllowedToServiceNotify = hasIntersection(userRoles, serviceNotificationRoles);

  const assignTitle = isRowsSelected
    ? `Взять себе в работу`
    : NO_ROWS_SELECTED_MESSAGE;
  const iconColor = isRowsSelected
    ? `blue`
    : `grey`;
  const reassignTitle = isRowsSelected
    ? `Вернуть в очередь`
    : NO_ROWS_SELECTED_MESSAGE;
  const legalNotificationTitle = isRowsSelected
    ? `Сформировать legal уведомления`
    : NO_ROWS_SELECTED_MESSAGE;
  const serviceNotificationTitle = isAllowedToServiceNotify
    ? isRowsSelected
      ? `Сформировать legal уведомления (сервис)`
      : NO_ROWS_SELECTED_MESSAGE
    : `(доступно для ролей: ${serviceNotificationRoles.join(`,`)})`;

  const deleteTitle = isAllowedToDeleteTasks
    ? isRowsSelected
      ? `Удалить задачи`
      : NO_ROWS_SELECTED_MESSAGE
    : `(доступно для ролей: ${deleteTaskRoles.join(`,`)})`;
  const terminateTitle = isAllowedToTerminateTasks
    ? isRowsSelected
      ? `Перевести задачу в терминальный статус`
      : NO_ROWS_SELECTED_MESSAGE
    : `(доступно для ролей: ${terminateTaskRoles.join(`,`)})`;
  const uploadExcelTitle = isAllowedToUploadExcel
    ? `Загрузить задачи из Excel`
    : `(доступно для ролей: ${taskUploaderRoles.join(`,`)})`;

  const selectedTasks = _.filter(tasks, ({ id }) => _.includes(selectedRowKeys, id));
  const isSelectedTasksSameStatus = _.size(_.uniqBy(selectedTasks, `taskStatusId`)) === 1;
  const isMultiplePartnerEdit = _.includes(CAMPAIGNS_WITH_MULTIPLE_PARTNER_EDIT, campaignName);
  const multipleStatusEditRowsSelectedTitle = isSelectedTasksSameStatus
    ? `Массовая смена статуса${isMultiplePartnerEdit ? `/партнёра` : ``}`
    : `Задачи должны быть в одном статусе`;

  let statusesToSelect;
  let taskStatus;
  if (isSelectedTasksSameStatus) {
    statusesToSelect = _.filter(taskStatuses, { campaignId });

    taskStatus = _.find(taskStatuses, { id: _.head(selectedTasks)?.taskStatusId });
    if (!_.isEmpty(taskStatus?.possibleNextStatusIds)) {
      statusesToSelect = _.filter(statusesToSelect, ({ id }) => _.includes(taskStatus.possibleNextStatusIds, id));
    }
    statusesToSelect = getStatusesToSelectFiltered({ statusesToSelect, taskStatus });
  }

  const isSelectedTasksSamePartner = _.size(_.uniqBy(selectedTasks, `searchPartnerId`)) === 1;
  const isAllowedToMultipleStatusEdit = hasIntersection(userRoles, multipleStatusEditRoles)
    && isSelectedTasksSameStatus;

  const multipleStatusEditInitialValues = {
    searchPartner: isSelectedTasksSamePartner
      ? _.find(hmSearchPartners, { id: _.head(selectedTasks)?.searchPartnerId })
      : null,
    taskStatus,
  };
  const multipleStatusEditTitle = isAllowedToMultipleStatusEdit
    ? (isRowsSelected ? multipleStatusEditRowsSelectedTitle : NO_ROWS_SELECTED_MESSAGE)
    : `(доступно для ролей: ${multipleStatusEditRoles.join(`,`)})`;

  const {
    ACCEPTED_FILES_STRING,
    handleFileSelect,
    inputRef,
    openFileInput,
    StyledInputFile,
  } = useFileInput({ acceptedFiles: ACCEPTED_FILES, onFilesSelected });

  const isExcelExportDisabled = _.isEmpty(selectedRowKeys) || _.size(selectedRowKeys) > 200;
  let excelExportTooltip;
  if (_.isEmpty(selectedRowKeys)) {
    excelExportTooltip = `Не выбрано ни одной задачи`;
  }
  if (_.size(selectedRowKeys) > 200) {
    excelExportTooltip = `Доступно максимум 200 задач для экспорта, выбрано: ${_.size(selectedRowKeys)}`;
  }

  const menuItems = [
    {
      key     : `reassign`,
      disabled: !isRowsSelected,
      onClick : reassign,
      icon    : <UsergroupDeleteOutlined style={{ color: iconColor }} />,
      tooltip : reassignTitle,
      label   : `Вернуть в очередь`,
    },
    {
      key     : `assign`,
      disabled: !isRowsSelected,
      onClick : assign,
      icon    : <SolutionOutlined style={{ color: iconColor }} />,
      tooltip : assignTitle,
      label   : `Взять себе в работу`,
    },
    {
      key     : `terminate`,
      disabled: !isRowsSelected || !isAllowedToTerminateTasks,
      onClick : terminate,
      icon    : <CloseCircleOutlined style={{ color: isAllowedToTerminateTasks ? iconColor : `grey` }} />,
      label   : `Перевести задачу в терминальный статус`,
      tooltip : terminateTitle,
    },
    {
      key : `divider-1`,
      type: `divider`,
    },
    {
      key    : `reload`,
      onClick: getTasks,
      label  : `Обновить`,
      icon   : <SyncOutlined style={{ color: `blue` }} />,
    },
    {
      key     : `file`,
      label   : `Загрузить задачи из Excel`,
      icon    : <FileExcelOutlined style={{ color: `green` }} />,
      tooltip : uploadExcelTitle,
      disabled: !isAllowedToUploadExcel,
      onClick : openFileInput,
    },
    {
      key     : `exportExcel`,
      label   : `Выгрузить в Excel`,
      icon    : <FileExcelOutlined style={{ color: `green` }} />,
      hidden  : !_.includes(CAMPAIGNS_WITH_EXPORT_TO_EXCEL, campaignName),
      disabled: isExcelExportDisabled,
      tooltip : excelExportTooltip,
      onClick : exportExcel,
    },
    {
      key     : `multipleStatusEdit`,
      disabled: !isRowsSelected || !isAllowedToMultipleStatusEdit,
      onClick : openMultipleStatusEditModal,
      icon    : <EditOutlined style={{ color: !isRowsSelected || !isAllowedToMultipleStatusEdit ? `grey` : `blue` }} />,
      label   : `Сменить статус${isMultiplePartnerEdit ? `/партнёра` : ``}`,
      tooltip : multipleStatusEditTitle,
    },
    {
      key     : `delete`,
      danger  : true,
      disabled: !isRowsSelected || !isAllowedToDeleteTasks,
      onClick : deleteTasks,
      icon    : <DeleteOutlined style={{ color: isAllowedToTerminateTasks ? `red` : `grey` }} />,
      label   : `Удалить задачи`,
      tooltip : deleteTitle,
    },
    {
      key     : `multipleTaskGet`,
      disabled: !isRowsSelected,
      onClick : multipleTaskGet,
      icon    : <EllipsisOutlined style={{ color: isRowsSelected ? `blue` : `grey` }} />,
      label   : `Срез 1С`,
    },
    {
      key     : `exportPreCourtNotification`,
      disabled: !isRowsSelected,
      onClick : () => openLegalNotifyModal(`legal`),
      icon    : <FileWordOutlined style={{ color: !isRowsSelected ? `grey` : `blue` }} />,
      label   : `Сформировать уведомления`,
      tooltip : legalNotificationTitle,
      hidden  : !_.includes(CAMPAIGNS_WITH_PRE_COURT_NOTIFY, campaignName),
    },
    {
      key     : `exportServiceNotification`,
      disabled: !isRowsSelected,
      onClick : () => openLegalNotifyModal(`service`),
      icon    : <FileExcelOutlined style={{ color: !isRowsSelected ? `grey` : `blue` }} />,
      label   : `Сформировать уведомления (сервис)`,
      tooltip : serviceNotificationTitle,
      hidden  : !_.includes(CAMPAIGNS_WITH_PRE_COURT_NOTIFY, campaignName),
    },
  ];

  return (
    <>
      <DefaultDropdown
        menu={{ items: menuItems }}
        trigger={[`click`]}
      >
        <ShowMoreControlsButton icon={<SettingOutlined />} shape='circle'>
          <MoreOutlined />
        </ShowMoreControlsButton>
      </DefaultDropdown>

      <StyledInputFile
        accept={ACCEPTED_FILES_STRING}
        multiple={false}
        onChange={handleFileSelect}
        ref={inputRef}
      />

      <CreateModal
        close={closeMultipleStatusEditModal}
        formFields={FORM_FIELDS({
          hmSearchPartners,
          initialValues: multipleStatusEditInitialValues,
          isMultiplePartnerEdit,
          statusesToSelect,
        })}
        getInitialValues={getMultipleStatusEditInitialValues}
        item={multipleStatusEditInitialValues}
        mode='update'
        title={`Смена статуса${isMultiplePartnerEdit ? `/партнёра` : ``}`}
        update={onMultipleStatusEdit}
        visible={isMultipleStatusEditModalOpen}
      />
      <LegalNotificationDownload
        onClose={closeLegalNotifyModal}
        taskIds={selectedRowKeys}
        type={legalNotifyModalType}
        visible={isLegalNotifyModalOpen}
      />
    </>
  );
};

export default TaskControls;
