import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { getFullName } from 'models/clients/helpers';

import { IMessage } from 'components/Messenger/types';

const Wrapper = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

const Author = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: ${palette.black60Color};
`;

const MessageWrapper = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  position: relative;
  color: ${palette.black80Color};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: ${palette.black10Color};
  border-radius: 4px;

  &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background: ${palette.primary500Color};
  }
`;

const Message = styled.div`
  margin-left: 16px;
`;

interface IComponentProps {
  messageToReply: IMessage;
  onClick: () => void;
}

export const MessageReplyTo: React.FC<IComponentProps> = ({ messageToReply, onClick }) => (
  <Wrapper onClick={onClick}>
    <Author>{messageToReply.author
      // @ts-ignore
      ? getFullName(_.get(messageToReply, `author`))
      : `—`
    }</Author>

    <MessageWrapper>
      <Message>{messageToReply.raw ? messageToReply.raw : `—`}</Message>
    </MessageWrapper>
  </Wrapper>
);
