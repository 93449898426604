import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import {
  get as getAction,
  setTableState as setTableStateAction,
} from 'models/bankrupts/actions';
import {
  getItems as getItemsSelector,
  getTableState as getTableStateSelector,
} from 'models/bankrupts/selectors';
import { IBankruptFacade } from 'models/bankrupts/types';
import { useDictionaries } from 'models/dictionaries/hooks';

import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Layout,
  TablePaginationConfig,
} from 'antd';
import {
  FilterValue,
  SorterResult,
} from 'antd/es/table/interface';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import BankruptModal from 'components/Modal/BankruptModal';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';

import { BankruptSubHeader } from './BankruptSubHeader';

import { getColumns } from './constants';

const StyledLayout = styled(Layout)``;

const tableScrollY = (window.innerWidth < 769) ? (window.innerHeight - 320) : (window.innerHeight - 270);

const PageBankruptPlural = () => {
  const dispatch = useDispatch();

  const getItems = useCallback(
    () => dispatch(getAction()),
    [dispatch],
  );
  const setTableState = useCallback((state: any) => dispatch(setTableStateAction(state)), [dispatch]);

  const items:IBankruptFacade[] = useSelector(getItemsSelector);
  const tableState = useSelector(getTableStateSelector);

  const [, dictionaries] = useDictionaries([`auctionStatus`, `biddingStatus`, `product`]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedBankrupt, setSelectedBankrupt] = useState<IBankruptFacade | null>(null);

  const openModal = (bankrupt: IBankruptFacade) => {
    setSelectedBankrupt(bankrupt);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBankrupt(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onTableChange = (
    pagination:TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter:SorterResult<any>,
  ) => {
    setTableState({
      pagination: {
        ...pagination,
        showSizeChanger: true,
      },
      filters,
      sorter,
      callback: getItems,
    });
  };

  const resetFilters = () => {
    onTableChange(
      {
        ...(tableState?.pagination || {}),
        current       : 1,
        defaultCurrent: 1,
        page          : 1,
      },
      {},
      {},
    );
  };

  const onTableColumnsChange = () => {
    const columns = tableState?.columns || {};

    setTableState({
      ...(tableState || {}),
      columns,
    });
  };

  const columns = getColumns({ dictionaries, tableState });

  useEffect(() => {
    getItems()
  }, [getItems]);

  return (
    <>
      <BankruptSubHeader
        onResetFilters={resetFilters}
        onTableChange={onTableChange}
        tableState={tableState}
      />
      <StyledLayout>
        <AdjustableTable
          columns={columns}
          dataSource={items}
          defaultColumns={tableState?.columns || _.map(columns, `key`)}
          filters={tableState?.filters}
          onChange={onTableChange}
          onTableColumnsChange={onTableColumnsChange}
          pagination={tableState?.pagination}
          preferences={{
            path      : `bankrupts`,
            cellRender: (_pass, item:IBankruptFacade) => (
              <DefaultTooltip placement='left' title='Редактировать'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => openModal(item)}
                  shape='circle'
                  type='primary'
                />
              </DefaultTooltip>
            ),
          }}
          rowKey='bankruptId'
          scroll={{
            scrollToFirstRowOnChange: true,
            x                       : _.isEmpty(items)
              ? undefined
              : `100%`,
            y: tableScrollY,
          }}
          scrollableHeader
        />
        <BankruptModal
          isVisible={isModalOpen}
          item={selectedBankrupt}
          onClose={closeModal}
          onSuccess={getItems}
        />
      </StyledLayout>
    </>
  );
};

export default PageBankruptPlural;
