import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const get = createSelector(
  [getState, (state, key) => key],
  (state, key) => _.get(state, key),
);

export const isLoading = createSelector(getState, state => _.get(state, `isLoading`, false));

export const isError = createSelector(getState, state => _.get(state, `isError`, false));

export const getModal = createSelector(
  [getState, (state, modalName) => modalName],
  (state, modalName) => _.get(state, `modal.${modalName}`, {}),
);

export const getActiveTabPath = createSelector(getState, state => _.get(state, `activeTabPath`, `/client`));

export const isGlobalLoading = state => state?.loading?.global || false;
