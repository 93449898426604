import React, {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { getField } from 'helper';

import {
  get as getItemsAction,
  linkExternal as linkItemsAction,
  remove as removeItemsAction,
  unlinkExternal as unlinkItemsAction,
} from 'models/dictionaries/actions';
import { EXTERNAL } from 'models/dictionaries/constants';
import {
  getItems as getItemsSelector,
  getItemsFiltered as getItemsFilteredSelector,
  getItemsNames as getItemNamesSelector,
  isLoading as isLoadingSelector,
} from 'models/dictionaries/selectors';
import { useOperators } from 'models/operators/hooks';
import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { useModalHandlers } from 'hooks/useModalHandlers';

import LinkModal from 'components/Modal/Link';
import { AdminPluralLayout } from 'pages/PageAdmin/components/AdminPluralLayout';
import { DictionaryCopyModal } from 'pages/PageAdmin/Dictionaries/Plural/components/DictionaryCopyModal';
import { DictionaryCreateModal } from 'pages/PageAdmin/Dictionaries/Plural/components/DictionaryCreateModal';
import { DictionaryEditModal } from 'pages/PageAdmin/Dictionaries/Plural/components/DictionaryEditModal';
import { DictionaryRemoveModal } from 'pages/PageAdmin/Dictionaries/Plural/components/DictionaryRemoveModal';
import { DictionarySearch } from 'pages/PageAdmin/Dictionaries/Plural/components/DictionarySearch';
import { DictionaryTable } from 'pages/PageAdmin/Dictionaries/Plural/components/DictionaryTable/DictionaryTable';

import {
  CUSTOM_DATA_BY_ENTITY,
  EDITOR_ROLES_BY_ENTITY,
  FIELDS_BY_ENTITY,
  FIELDS_COMMON,
  LINKABLE_ENTITIES,
  SOURCES_BY_ENTITY,
} from './constants';

const { DICTIONARY_EDITOR, SUPER_ADMIN } = ROLE_NAMES;

// @ts-ignore
const PageDictionariesPlural = ({ match }) => {
  const dispatch = useDispatch();

  const entity = _.get(match, `params.entity`);

  useOperators();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _items, itemNames, userRoles } = useSelector(state => ({
    itemNames: getItemNamesSelector(state, entity),
    // @ts-ignore
    _items   : getItemsFilteredSelector(state, entity),
    userRoles: getRoleNamesSelector(state),
  }));
  let items = _items;

  const getItems = () => dispatch(getItemsAction({ entity, force: true }));
  const getSourceItems = (e: string) => dispatch(getItemsAction({ entity: e, force: true }));
  const removeItemsDispatch = (ids: number[]) => dispatch(removeItemsAction({ entity, ids }));

  const {
    itemEditing,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    onSelectChange: _onSelectChange,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    selectedRowKeys: _selectedRowKeys,
    setItemEditing,
  } = useModalHandlers();
  let onSelectChange = _onSelectChange;
  let selectedRowKeys = _selectedRowKeys;
  if (entity === `notificationList`) {
    // @ts-ignore
    onSelectChange = (pass, selectedRows) => {
      // @ts-ignore
      selectedRowKeys = _.reduce(selectedRows, (result, { id, isPersonal }) => {
        if (isPersonal) return result;
        return [...result, id];
      }, []);
      _onSelectChange(selectedRowKeys);
    };
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
  const _formFields = [
    ...(_.has(EXTERNAL, entity) ? [] : FIELDS_COMMON(itemNames, itemEditing, entity)),
    // @ts-ignore
    ...(_.isFunction(FIELDS_BY_ENTITY[entity])
      // @ts-ignore
      ? FIELDS_BY_ENTITY[entity]({ itemEditing, items, userRoles })
      // @ts-ignore
      : FIELDS_BY_ENTITY[entity] || []
    ),
  ];
  let formFields = _formFields;

  const removeItems = () => {
    onSelectChange([]);
    removeItemsDispatch(_.filter(selectedRowKeys, id => (entity === `verificationStatus` ? (id > 0) : (id >= 0))));
  };

  useEffect(() => {
    getItems()
  }, []);
  // @ts-ignore
  const isUsesSourceItems = !_.isEmpty(SOURCES_BY_ENTITY[entity]) || _.has(LINKABLE_ENTITIES, entity);
  useEffect(() => {
    if (isUsesSourceItems) {
      // @ts-ignore
      _.each(SOURCES_BY_ENTITY[entity], (v, _entity) => {
        if (entity !== _entity) getSourceItems(_entity);
      });
      if (_.has(LINKABLE_ENTITIES, entity)) getSourceItems(LINKABLE_ENTITIES[entity].entity);
    }
  }, [entity]);

  let sourceItems = {
    targetLinkItems: [],
  };
  sourceItems = useSelector(state => {
    if (isUsesSourceItems) {
      return _.reduce(
        // @ts-ignore
        SOURCES_BY_ENTITY[entity],
        (result, value, key) => ({
          ...result,
          [key]: getItemsSelector(state, key),
        }),
        _.has(LINKABLE_ENTITIES, entity)
          ? { targetLinkItems: getItemsSelector(state, LINKABLE_ENTITIES[entity].entity) }
          : { targetLinkItems: [] },
      );
    }
    return { targetLinkItems: [] };
  });

  // @ts-ignore
  const sources = SOURCES_BY_ENTITY[entity];
  if (isUsesSourceItems) {
    formFields = _.map(_.concat(
      _formFields,
      _.map(_.omitBy(sources, `hidden`), ({
        Component,
        getOptions,
        key,
        optionFilterProp,
        optionsRender,
        required,
        rules,
        showSearch,
        testAttribute,
        title,
      }, sourceKey) => ({
        Component,
        key,
        options: getOptions
          ? getOptions({ sourceItems, itemEditing })
          : _.map(_.get(sourceItems, sourceKey, []), source => ({
            // @ts-ignore
            ...source,
            // @ts-ignore
            value: source.id,
            label: optionsRender(source),
          })),
        optionFilterProp,
        optionsRender,
        required,
        rules,
        showSearch,
        title,
        testAttribute,
      })),
    ), getField);
    items = _.map(items, item => ({
      ...item,
      ..._.reduce(
        sources,
        (result, value, key) => {
          const sourceId = item[value.key] || value.sourceId;
          if (_.isArray(sourceId)) {
            // eslint-disable-next-line @typescript-eslint/no-for-in-array
            for (const i in sourceId) {
              const sourceIdCurrent = sourceId[i];
              // eslint-disable-next-line no-param-reassign
              // @ts-ignore
              if (!result[key]) result[key] = [];
              // @ts-ignore
              result[key].push(_.find(sourceItems[key], { id: sourceIdCurrent }));
            }
            return result;
          }
          return {
            ...result,
            // @ts-ignore
            [key]: _.find(sourceItems[key], { id: sourceId }),
          };
        },
        {},
      ),
    }));
    formFields = _.map(formFields, getField);
  }

  // @ts-ignore
  const isUsesCustomData = !_.isEmpty(CUSTOM_DATA_BY_ENTITY[entity]);

  const checkIsCustomDataLoaded = useSelector(state => {
    if (!isUsesCustomData) return null;
    // @ts-ignore
    const { checkIsLoaded, enrichItems, getFormFields } = CUSTOM_DATA_BY_ENTITY[entity];

    formFields = [...formFields, ...getFormFields(state)];
    items = enrichItems ? enrichItems(state, items) : items;
    return () => checkIsLoaded(state);
  });

  // @ts-ignore
  const { load } = CUSTOM_DATA_BY_ENTITY[entity] || {};
  const loadCustomData = _.isFunction(load) ? () => load(dispatch) : _.noop;

  useEffect(() => {
    if (isUsesCustomData && _.isFunction(checkIsCustomDataLoaded) && !checkIsCustomDataLoaded()) {
      loadCustomData();
    }
  }, [isUsesCustomData, checkIsCustomDataLoaded]);

  const linkItems = (data: any) => dispatch(linkItemsAction(data));
  const unlinkItems = (data: any) => dispatch(unlinkItemsAction(data));

  const isLoading = useSelector(isLoadingSelector);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);

  const [initialValues, setInitialValues] = useState({});
  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);

  const toggleCopyModal = (newInitialValues?: any) => {
    setIsCopyModalVisible(!isCopyModalVisible);
    setInitialValues(newInitialValues);
  };

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const toggleCreateModal = () => setIsCreateModalOpen(!isCreateModalOpen);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const toggleRemoveModal = () => setIsRemoveModalOpen(!isRemoveModalOpen);

  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [itemLinking, setItemLinking] = useState(null);

  // @ts-ignore
  const handleItemLinking = ({ id, link }) => {
  // @ts-ignore
    setItemLinking({ id, link });
  };

  let editorRole = DICTIONARY_EDITOR;

  if (_.has(EXTERNAL, entity)) {
    // @ts-ignore
    editorRole = `${entity}Editor`;
  }

  // @ts-ignore
  if (EDITOR_ROLES_BY_ENTITY[entity]) {
    // @ts-ignore
    editorRole = EDITOR_ROLES_BY_ENTITY[entity];
  }

  const canEdit = _.includes(userRoles, SUPER_ADMIN)
    ? true
    : _.includes(userRoles, editorRole);

  if (_.some(formFields, field => !field.Component)) {
    formFields = _.map(formFields, getField);
  }

  return (
    <>
      <DictionarySearch
        canEdit={canEdit}
        entity={entity}
        isLoading={isLoading}
        loadCustomData={loadCustomData}
        onReload={getItems}
        selectedRowKeys={selectedRowKeys}
        toggleCreateModal={toggleCreateModal}
        toggleRemoveModal={toggleRemoveModal}
      />
      <AdminPluralLayout>
        <DictionaryTable
          canEdit={canEdit}
          entity={entity}
          isLoading={isLoading}
          items={items}
          onReload={getItems}
          onSelectChange={onSelectChange}
          openLinkModal={() => setIsLinkModalOpen(true)}
          selectedRowKeys={selectedRowKeys}
          setItemEditing={setItemEditing}
          setItemLinking={handleItemLinking}
          toggleCopyModal={toggleCopyModal}
          toggleEditModal={toggleEditModal}
        />

        <DictionaryCreateModal
          entity={entity}
          formFields={formFields}
          isVisible={isCreateModalOpen}
          onClose={toggleCreateModal}
        />

        <DictionaryCopyModal
          entity={entity}
          formFields={formFields}
          initialValues={initialValues}
          isVisible={isCopyModalVisible}
          onClose={() => toggleCopyModal()}
        />

        <DictionaryEditModal
          entity={entity}
          formFields={formFields}
          isVisible={isEditModalOpen}
          itemEditing={itemEditing}
          onClose={toggleEditModal}
        />

        {_.has(LINKABLE_ENTITIES, entity) && isLinkModalOpen && (
          <LinkModal
            close={() => setIsLinkModalOpen(false)}
            entity={entity}
            item={itemLinking}
            modalTitle={LINKABLE_ENTITIES[entity].title}
            onSubmit={linkItems}
            onUnlink={unlinkItems}
            targetItems={sourceItems?.targetLinkItems}
            // @ts-ignore
            userRoles={userRoles}
            visible={isLinkModalOpen}
          />
        )}

        <DictionaryRemoveModal
          entity={entity}
          isVisible={isRemoveModalOpen}
          onClose={toggleRemoveModal}
          onSubmit={removeItems}
          selectedRowKeys={selectedRowKeys}
        />
      </AdminPluralLayout>
    </>
  );
};

export default PageDictionariesPlural;
