import React, {
  useEffect,
  useState,
} from 'react';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import * as api from 'models/clients/api';
import { IFedresursItem } from 'models/clients/types';

import ItemsList from 'components/Modal/BankruptModal/FedResurs/itemsList';

interface IProps {
  personId: string;
}

export const FedResursMessages: React.FC<IProps> = ({ personId }) => {
  const [items, setItems] = useState<IFedresursItem[]>([]);

  const getFedResursMessages = async () => {
    const defaultError = `При запросе данных Федресурса произошла ошибка`;
    if (!personId) return;
    try {
      const response = parseResponse({
        defaultError,
        response: await api.getFedResursMessages(personId),
      });
      setItems(response);
    } catch (error) {
      showError({ defaultError, error });
    }
  };

  // @ts-ignore
  useEffect(() => {
    getFedResursMessages()
  }, [personId]);

  return <ItemsList data={items} />;
};
