import _ from 'lodash';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { isAuth } from 'models/user/helpers';

import { notification } from 'antd';

import {
  GET_PAYMENT_GATEWAYS,
  GET_SHORT_LINK_SERVICES,
  GET_SMS_GATEWAYS,
  model,
  PROCESS_TASKS,
  RESET,
  RUN_TRIGGERS,
  SEND_TEST_EMAILS,
  SET,
  SET_LOADING,
  SET_PAYMENT_GATEWAY_ACTIVE,
  SET_SHORT_LINK_SERVICE_ACTIVE,
  SET_SMS_GATEWAY_ACTIVE,
  SWITCH_PAYMENT_GATEWAY,
  SWITCH_SHORT_LINK_SERVICE,
  SWITCH_SMS_GATEWAY,
} from './actions';
import * as api from './api';

const initialState = {
  isLoading        : false,
  isTriggersLoading: false,
  paymentGateways  : [],
  shortLinkServices: [],
  smsGateways      : [],
};

export default {
  namespace    : model,
  state        : initialState,
  subscriptions: {
    setup({ dispatch }) {
      if (isAuth()) setTimeout(() => dispatch({ type: GET_PAYMENT_GATEWAYS }), 200);
    },
  },
  effects: {
    *[GET_PAYMENT_GATEWAYS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При запросе списка платёжных шлюзов произошла ошибка`;

      try {
        const paymentGateways = parseResponse({
          defaultError,
          response: yield call(api.getPaymentGateways),
        });

        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();

        yield put({ type: SET, payload: { paymentGateways } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_SHORT_LINK_SERVICES](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При запросе сервисов коротких ссылок произошла ошибка`;

      try {
        const shortLinkServices = parseResponse({
          defaultError,
          response: yield call(api.getShortLinkServices),
        });

        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();

        yield put({ type: SET, payload: { shortLinkServices } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_SMS_GATEWAYS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При запросе смс-шлюзов произошла ошибка`;

      try {
        const smsGateways = parseResponse({
          defaultError,
          response: yield call(api.getSmsGateways),
        });

        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();

        yield put({ type: SET, payload: { smsGateways } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[PROCESS_TASKS](action, { call, put }) {
      yield put({ type: SET, payload: { isProcessTasksLoading: true } });
      const defaultError = `При обработке задач произошла ошибка`;

      try {
        const { block } = action.payload;
        const processed = parseResponse({
          dataPath: `data.processed`,
          defaultError,
          response: yield call(api.processTasks, block),
        });
        notification.success({ message: `Обработка завершена успешно. Задач обработано: ${processed}` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isProcessTasksLoading: false } });
      }
    },

    *[RUN_TRIGGERS](action, { call, put }) {
      yield put({ type: SET, payload: { isTriggersLoading: true } });
      const defaultError = `При обработке триггеров произошла ошибка`;

      try {
        parseResponse({
          defaultError,
          response: yield call(api.runTriggers),
        });
        notification.success({ message: `Обработка триггеров запущена успешно` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isTriggersLoading: false } });
      }
    },

    *[SWITCH_PAYMENT_GATEWAY](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { callback, hideMessage = false, item } = action.payload;
      const defaultError = `При переключении платёжного шлюза на ${item?.channelName} произошла ошибка`;

      try {
        if (_.isEmpty(item)) throw new Error(`${defaultError} - не указан платёжный шлюза для переключения.`);
        parseResponse({
          defaultError: `${defaultError} - ошибки при взаимодействии с API.`,
          response    : yield call(api.switchPaymentGateway, item),
        });

        if (_.isFunction(callback)) callback();

        yield put({ type: SET_PAYMENT_GATEWAY_ACTIVE, payload: { channelId: item.channelId } });
        if (!hideMessage) {
          notification.success({ message: `Платёжный шлюз успешно переключен на ${item.channelName}` });
        }
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SWITCH_SHORT_LINK_SERVICE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { callback, hideMessage = false, shortUrlClientName } = action.payload;
      const defaultError = `При переключении сервиса коротких ссылок на ${shortUrlClientName} произошла ошибка`;

      try {
        if (!shortUrlClientName) throw new Error(`${defaultError} - не указано название сервиса коротких ссылок.`);
        parseResponse({
          defaultError: `${defaultError} - ошибки при взаимодействии с API.`,
          response    : yield call(api.switchShortLinkService, shortUrlClientName),
        });

        if (_.isFunction(callback)) callback();

        yield put({ type: SET_SHORT_LINK_SERVICE_ACTIVE, payload: { shortUrlClientName } });
        if (!hideMessage) {
          notification.success({ message: `Сервис коротких ссылок успешно переключен на ${shortUrlClientName}` });
        }
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SWITCH_SMS_GATEWAY](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { callback, hideMessage = false, operatorName } = action.payload;
      const defaultError = `При переключении смс-шлюза на ${operatorName} произошла ошибка`;

      try {
        if (!operatorName) throw new Error(`${defaultError} - не указано название оператора.`);
        parseResponse({
          defaultError: `${defaultError} - ошибки при взаимодействии с API.`,
          response    : yield call(api.switchSmsGateway, operatorName),
        });

        if (_.isFunction(callback)) callback();

        yield put({ type: SET_SMS_GATEWAY_ACTIVE, payload: { operatorName } });
        if (!hideMessage) notification.success({ message: `СМС шлюз успешно переключен на ${operatorName}` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SEND_TEST_EMAILS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { emails, html } = action.payload;
      const defaultError = `При отправке тестового email произошла ошибка`;

      try {
        if (_.isEmpty(emails) || !html) throw new Error(defaultError); // TODO добавить проверку на файл (гугл)

        parseResponse({
          defaultError,
          response: yield call(api.sendTestEmail, {
            emails,
            html,
          }),
        });

        notification.success({ message: `Тестовый email успешно отправлен` });
        if (_.isFunction(_.get(action, `payload.callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

  },
  reducers: {
    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload = {} }) {
      return {
        ...state,
        ...payload,
      };
    },

    [SET_LOADING](state, { payload: isLoading = false }) {
      return {
        ...state,
        isLoading,
      };
    },

    [SET_PAYMENT_GATEWAY_ACTIVE](state, { payload: { channelId } }) {
      return {
        ...state,
        paymentGateways: _.map(state.paymentGateways, paymentGateway => ({
          ...paymentGateway,
          activeFlg: paymentGateway.channelId === channelId,
        })),
      };
    },

    [SET_SHORT_LINK_SERVICE_ACTIVE](state, { payload: { shortUrlClientName } }) {
      return {
        ...state,
        shortLinkServices: _.map(state.shortLinkServices, shortLinkService => ({
          ...shortLinkService,
          status: shortLinkService.shortUrlClientName === shortUrlClientName ? 1 : 0,
        })),
      };
    },

    [SET_SMS_GATEWAY_ACTIVE](state, { payload: { operatorName } }) {
      return {
        ...state,
        smsGateways: _.map(state.smsGateways, smsGateway => ({
          ...smsGateway,
          status: smsGateway.operatorName === operatorName ? 1 : 0,
        })),
      };
    },
  },
};
