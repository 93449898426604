import React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { hasIntersection } from 'helper';

import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import useMobileContext from 'hooks/useMobileContext';

import { Space } from 'antd';
import MenuItemAdminSmall from 'pages/PageAdmin/Main/components/MenuItemAdminSmall';

import { DICTIONARIES_MENU } from '../../constants';

const Wrapper = styled(Space)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px 60px 80px 60px;
  width: 100%;
  position: relative;
  gap: 16px !important;

  .ant-space {
    &-item {
      width: 100%;
      border-bottom: 1px solid ${palette.borderDefaultColor};

      &:last-of-type {
        border-bottom: unset;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: center;
  }

  @media screen and (max-width: 536px) {
    padding: 24px 16px 40px 16px;
  }
`;

const DictionariesGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    width: 100%;
  }

  @media screen and (max-width: 536px) {
    padding-bottom: 8px;
  }
`;

const GroupTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.textSecondaryColor};

  @media(min-width: ${breakpoints.md}) {
    display: block;
    justify-content: unset;
  }
`;

const GroupItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const DictionariesList = () => {
  const { mobile } = useMobileContext();

  const userRoles = useSelector(state => getRoleNamesSelector(state));

  const menuItems = _.includes(userRoles, ROLE_NAMES.SUPER_ADMIN)
    ? DICTIONARIES_MENU
    : _.filter(DICTIONARIES_MENU, ({ roles }) => hasIntersection(userRoles, roles));

  return (
    <Wrapper
      align='center'
      direction={mobile ? `vertical` : `horizontal`}
      wrap
    >
      {_.map(_.groupBy(menuItems, `group`), (items, group) => (_.isEmpty(items) ? null : (
        <DictionariesGroup key={group}>
          <GroupTitle>{group}</GroupTitle>
          <GroupItems>
            {_.map(items, ({
              color,
              entity,
              icon,
              testAttribute,
              title,
            }) => (
              <MenuItemAdminSmall
                color={color}
                icon={icon}
                key={entity}
                path={`/admin/dictionaries/${entity}`}
                testAttribute={testAttribute}
                title={title}
              />
            ))}
          </GroupItems>
        </DictionariesGroup>
      )))}
    </Wrapper>
  );
};

export default DictionariesList;
