export default {
  NEW           : 1, // новый оффер
  CHOSEN        : 2, // предварительно выбранный оффер
  APPROVED      : 3, // оффер подтвержден
  DOCS_GENERATED: 4, // сформированы документы для подписания
  DOCS_SIGNED   : 5, // документы подписаны
  OUTDATED      : 6, // оффер неактуален, стратегия назначила новый оффер
  MONEY_SENT    : 12, // деньги выданы
  DENY_BY_CLIENT: 13, // отказ клиента
};
