import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';

import { getClientLoanById } from 'models/clients/selectors';
import { IGenerateLegalDocument } from 'models/task/types';

import ApplicationDocumentUploadModal from 'components/Modal/ApplicationDocumentUploadModal';
import LegalDocumentGenerate from 'components/Modal/LegalDocumentGenerate';

import {
  DocumentButton,
  DocumentControls,
  DocumentLabel,
  DocumentRow,
} from './styled';

interface IProps {
  documents: IGenerateLegalDocument[];
  generateModalTitle: string;
  loanId: string;
  personId: string;
  taskId: number;
}

const LegalDocuments: React.FC<IProps> = ({
  documents,
  generateModalTitle,
  loanId,
  personId,
  taskId,
}) => {
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState<boolean>(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [documentTemplateId, setDocumentTemplateId] = useState<number | null>(null);
  const [documentTypeId, setDocumentTypeId] = useState<number | null>(null);

  const closeGenerateModal = () => {
    setDocumentTemplateId(null);
    setIsGenerateModalOpen(false);
  };
  const openGenerateModal = (templateId: number | null) => {
    setDocumentTemplateId(templateId);
    setIsGenerateModalOpen(true);
  };

  const closeUploadModal = () => {
    setDocumentTypeId(null);
    setIsUploadModalOpen(false);
  };
  const openUploadModal = (typeId: React.SetStateAction<number | null>) => {
    setDocumentTypeId(typeId);
    setIsUploadModalOpen(true);
  };

  const loan = useSelector(state => getClientLoanById(state, personId, loanId)) || {};
  const { applicationId } = loan;

  return (
    <>
      {_.map(documents, doc => (
        <DocumentRow key={doc.label}>
          <DocumentLabel>{doc.label}</DocumentLabel>
          <DocumentControls>
            <DocumentButton onClick={() => openGenerateModal(doc.templateId)} type='primary'>
              Сформировать
            </DocumentButton>
            <DocumentButton onClick={() => openUploadModal(doc.typeId)} type='primary'>
              Загрузить
            </DocumentButton>
          </DocumentControls>
        </DocumentRow>
      ))}
      <LegalDocumentGenerate
        documents={documents}
        // @ts-ignore
        documentTemplateId={documentTemplateId}
        loanId={loanId}
        onClose={closeGenerateModal}
        personId={personId}
        taskId={taskId}
        title={generateModalTitle}
        visible={isGenerateModalOpen}
      />
      <ApplicationDocumentUploadModal
        applicationId={applicationId}
        close={closeUploadModal}
        // @ts-ignore
        documentTypeId={documentTypeId}
        isOpen={isUploadModalOpen}
        personId={personId}
      />
    </>
  );
};

export default LegalDocuments;
