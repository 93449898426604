import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';
import styled from 'styled-components';

import {
  CopyOutlined,
  EditOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import {
  Button,
  Tooltip,
} from 'antd';
import { DocumentTemplateSingleUploadModal } from 'components/Modal/DocumentTemplateSingleUploadModal';
import {
  CELL_CONTROLS_BY_ENTITY,
  LINKABLE_ENTITIES,
  UNEDITABLE_ENTITIES,
} from 'pages/PageAdmin/Dictionaries/Plural/constants';

const Wrapper = styled.div`
  min-width: 30px;
  display: flex;
  justify-content: flex-end;
`;

const ButtonStyled = styled(Button)`
  flex-shrink: 0;

  &+& {
    margin-left: 8px;
  }

  &+.ant-tooltip-disabled-compatible-wrapper {
    margin-left: 8px;
  }
`;

interface IComponentProps {
  canEdit: boolean;
  entity: string;
  onCopyClick: () => void;
  onEditClick: () => void;
  onLinkClick: () => void;
  onReload: () => void;
  record: any;
}

export const CellControls: React.FC<IComponentProps> = ({
  canEdit,
  entity,
  onCopyClick,
  onEditClick,
  onLinkClick,
  onReload,
  record,
}) => {
  const dispatch = useDispatch();

  const [isUploadSingleModalOpen, setIsUploadSingleModalOpen] = useState(false);
  const [itemUploadingSingle, setItemUploadingSingle] = useState(null);
  const toggleUploadSingleModal = () => setIsUploadSingleModalOpen(!isUploadSingleModalOpen);

  const onUploadSingleClick = () => {
    setItemUploadingSingle(record);
    toggleUploadSingleModal();
  };

  return (
    <Wrapper>
      {canEdit && !_.includes(UNEDITABLE_ENTITIES, entity) && (entity !== `notificationList` || !record.isPersonal) && (
        <>
          <ButtonStyled
            icon={<EditOutlined />}
            onClick={onEditClick}
            type='primary'
          />
          <ButtonStyled
            icon={<CopyOutlined />}
            onClick={onCopyClick}
            type='primary'
          />
        </>
      )}

      {_.has(LINKABLE_ENTITIES, entity) && (canEdit || record.id > 0) && (
        // @ts-ignore
        <Tooltip title={LINKABLE_ENTITIES[entity].title}>
          <ButtonStyled
            icon={<LinkOutlined />}
            onClick={onLinkClick}
            type='primary'
          />
        </Tooltip>
      )}

      {// @ts-ignore
        !_.isEmpty(CELL_CONTROLS_BY_ENTITY({ canEdit, onUploadSingleClick })[entity])
        && (
          <>
            {_.map(
              // @ts-ignore
              CELL_CONTROLS_BY_ENTITY({ canEdit, onUploadSingleClick })[entity],
              ({
                disabled,
                icon,
                onClick,
                title,
                type,
              }, index) => (
                <Tooltip key={index} placement='topRight' title={title}>
                  <ButtonStyled
                    disabled={disabled}
                    icon={icon}
                    onClick={() => onClick(dispatch, record)}
                    type={type}
                  />
                </Tooltip>
              ),
            )}

            <DocumentTemplateSingleUploadModal
              isVisible={isUploadSingleModalOpen}
              onClose={toggleUploadSingleModal}
              onReload={onReload}
              // @ts-ignore
              templateId={itemUploadingSingle?.id}
            />
          </>
        )}
    </Wrapper>
  );
};
