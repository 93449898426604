/* eslint-disable max-len */
import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import useMobileContext from 'hooks/useMobileContext';

import { EntityDetailsRowHorizontal } from 'pages/PageClient/Single/components/EntityDetails/EntityDetailsRowHorizontal';
import { EntityDetailsRowVertical } from 'pages/PageClient/Single/components/EntityDetails/EntityDetailsRowVertical';

const StyledEntityDetailsRow = styled(EntityDetailsRowHorizontal)``;

const Wrapper = styled.div`
  ${StyledEntityDetailsRow} {
    &:last-child {
      padding-bottom: 4px;
    }
  }

  @media(min-width: ${breakpoints.md}) {
    display: flex;
  }
`;

export interface IRow {
  dataIndex?: string[];
  key: string;
  title: string;
  valueToCopy?: (false) | ((value: any, record: any) => string | number);
  render?(value: any, record: any): any;
}

interface IComponentProps {
  className?: string;
  record: any;
  rows: IRow[];
}

export const EntityDetails: React.FC<IComponentProps> = ({ className, record, rows }) => {
  const { mobile } = useMobileContext();

  return (
    <Wrapper className={className}>
      {_.map(rows, row => {
        const dataIndex = row.dataIndex || row.key;
        const valueToCopy = row.valueToCopy === undefined
          ? _.get(record, dataIndex)
          : row.valueToCopy !== false
            ? row.valueToCopy(_.get(record, dataIndex), record)
            : undefined;

        return mobile ? (
          <StyledEntityDetailsRow
            key={row.key}
            title={row.title}
            valueToCopy={valueToCopy}
          >
            {row.render ? row.render(_.get(record, dataIndex), record) : _.get(record, dataIndex)}
          </StyledEntityDetailsRow>
        ) : (
          <EntityDetailsRowVertical
            key={row.key}
            title={row.title}
            valueToCopy={valueToCopy}
          >
            {row.render ? row.render(_.get(record, dataIndex), record) : _.get(record, dataIndex)}
          </EntityDetailsRowVertical>
        );
      })}
    </Wrapper>
  );
};
