import _ from 'lodash';

export function getCommunicationsFiltered({
  communicationTypeId,
  createDtmFieldName = `createDtm`,
  filters = {},
  itemMapFunction,
  items,
}) {
  const result = [];
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (filters.communicationTypeId && (filters.communicationTypeId !== communicationTypeId)) {
      continue;
    }
    if (filters.createdAt) {
      const [dateFrom, dateTo] = filters.createdAt;
      const dateFromMs = dateFrom.valueOf();
      const dateToMs = dateTo.valueOf();
      if (item[createDtmFieldName] < dateFromMs || item[createDtmFieldName] > dateToMs) {
        continue;
      }
    }
    result.push(itemMapFunction(item, i));
  }
  return result;
}

export const getItems = (filteredAuthCalls, filteredSms, communications, filteredCalibriChats, sorter) => {
  const allItems = [];
  allItems.push.apply(allItems, filteredAuthCalls);
  allItems.push.apply(allItems, filteredSms);
  allItems.push.apply(allItems, communications);
  allItems.push.apply(allItems, filteredCalibriChats);
  if (sorter.isLastCut) {
    const sortedItems = allItems.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    const uniqueItems = [];
    for (let i = 0; i < sortedItems.length; i += 1) {
      const communication = sortedItems[i];
      const nextCommunication = sortedItems[i + 1];
      if (
        (communication.campaignName || ``) + (communication.campaignBlock || ``)
        !== (nextCommunication?.campaignName || ``) + (nextCommunication?.campaignBlock || ``)) {
        uniqueItems.push(communication);
      }
    }
    return uniqueItems;
  }
  return _.orderBy(allItems, sorter.sortBy || `createdAt`, sorter.sortOrder || `desc`);
};
