import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { formatDate } from 'helper';

const DateWrapper = styled.span`
  margin-right: 8px;
  color: ${palette.textPrimaryColor};
`;

const TimeWrapper = styled.span`
  color: ${palette.textSecondaryColor};
`;

interface IComponentProps {
  value?: number;
}

export const CellDateTime: React.FC<IComponentProps> = ({ value }) => {
  if (!value) return null;
  const dateObject = new Date(value);

  // @ts-ignore
  if (dateObject !== `Invalid Date` && !isNaN(dateObject)) {
    const [date, time] = _.split(formatDate(dateObject), ` `);

    return (
      <>
        <DateWrapper>{date}</DateWrapper>
        <br />
        <TimeWrapper>{time}</TimeWrapper>
      </>
    );
  }

  return <>value</>;
};
