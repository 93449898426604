import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { prettifyAmount } from 'helper';

import {
  EntityDetails,
  IRow,
} from 'pages/PageClient/Single/components/EntityDetails/EntityDetails';
import {IApplication} from "models/clients/types";

const ROWS: IRow[] = [
  {
    key   : `amount`,
    title : `Сумма`,
    render: (v, record) => prettifyAmount(record?.requestedLoan),
  },
  {
    key   : `term`,
    title : `Срок`,
    render: (v, record) => record?.requestedTerm,
  },
  {
    key   : `status`,
    title : `Статус`,
    render: (v, record) => record?.applicationStatusName || record?.applicationStatusName,
  },
  {
    key   : `product`,
    title : `Продукт`,
    render: (v, record) => record?.productName || record?.productId,
  },
  {
    key   : `partner`,
    title : `Партнер`,
    render: (v, record) => record?.partnerName || record?.partnerId,
  },
  {
    key   : `income`,
    title : `Доход`,
    render: (v, record) => prettifyAmount(record.monthlyIncome),

  },
  {
    key   : `burden`,
    title : `Нагрузка`,
    render: (v, record) => prettifyAmount(record.loanBurden),
  },
];

const StyledEntityDetails = styled(EntityDetails)`
  margin-left: 16px;
  margin-right: 16px;

  @media(min-width: ${breakpoints.md}) {
    margin-right: 24px;
    margin-left: 24px;
  }
`;

export const ApplicationDescription = ({ application }: { application: IApplication }) => (
  <StyledEntityDetails record={application} rows={ROWS} />
);
