import { useState } from 'react';
import _ from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook';

export const useAttachmentsPreview = (images: any[]) => {
  const [previewImageIndex, setPreviewImageIndex] = useState<number | null>(null);
  const isPreviewMode = previewImageIndex !== null;

  const firstIndex = 0;
  const lastIndex = _.size(images) - 1;

  const selectNextImage = () => {
    setPreviewImageIndex(previewImageIndex === lastIndex
      ? firstIndex
      // @ts-ignore
      : previewImageIndex + 1);
  };

  const selectPrevImage = () => {
    setPreviewImageIndex(previewImageIndex === firstIndex
      ? lastIndex
      // @ts-ignore
      : v => v - 1);
  };

  const selectImageByIndex = (index: number) => {
    setPreviewImageIndex(index);
  };

  const closePreviewMode = () => setPreviewImageIndex(null);

  useHotkeys(`LEFT`, () => (isPreviewMode ? selectPrevImage() : _.noop()), [selectPrevImage, isPreviewMode]);
  useHotkeys(`RIGHT`, () => (isPreviewMode ? selectNextImage() : _.noop()), [selectNextImage, isPreviewMode]);
  useHotkeys(`ESC`, () => (isPreviewMode ? closePreviewMode() : _.noop()), {}, [isPreviewMode]);

  return {
    previewImageIndex,
    isPreviewMode,
    selectNextImage,
    selectPrevImage,
    closePreviewMode,
    selectImageByIndex,
  };
};
