import * as React from 'react';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';

import { getFullName } from 'models/clients/helpers';

import {
  EditOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { getFileFromFileObject } from 'components/Messenger/helpers';
import { InputActionPlate } from 'components/Messenger/Input/InputActionPlate';
import { InputCore } from 'components/Messenger/Input/InputCore';
import { useInputFiles } from 'components/Messenger/Input/useInputFiles';
import {
  IDialog,
  IMessage,
} from 'components/Messenger/types';

interface IComponentProps {
  dialog: IDialog;
  messageToDelete: IMessage | null;
  messageToEdit: IMessage | null;
  messageToReply: IMessage | null;
  notificationTitle?: string;
  setMessageToDelete: (message: IMessage) => void;
  setMessageToEdit: (message: IMessage) => void;
  setMessageToReply: (message: IMessage) => void;
}

export const Input: React.FC<IComponentProps> = ({
  dialog,
  messageToDelete,
  messageToEdit,
  messageToReply,
  notificationTitle,
  setMessageToDelete,
  setMessageToEdit,
  setMessageToReply,
}) => {
  const [notificationsListIds, setNotificationsListIds] = useState([]);
  const [message, setMessage] = useState(``);
  const inputRef = useRef(null);
  const {
    addFile,
    clearFiles,
    files,
    isProcessing,
    removeFileByIndex,
  } = useInputFiles();

  const isMessageToEditHasBeenDeleted = messageToEdit?.id === messageToDelete?.id;
  const isMessageToReplyHasBeenDeleted = messageToReply?.id === messageToDelete?.id;

  const focusInput = () => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  };

  const resetState = () => {
    // @ts-ignore
    setMessageToEdit(null);
    // @ts-ignore
    setMessageToReply(null);
    clearFiles();
    setNotificationsListIds([]);
    setMessage(``);
    focusInput();
  };

  useEffect(() => {
    if (messageToReply) {
      // @ts-ignore
      setMessageToEdit(null);

      focusInput();
    }
  }, [messageToReply]);

  useEffect(() => {
    focusInput();
  }, []);

  useEffect(() => {
    if (isMessageToEditHasBeenDeleted) {
      // @ts-ignore
      setMessageToEdit(null);
      // @ts-ignore
      setMessageToDelete(null);
    }
  }, [isMessageToEditHasBeenDeleted]);

  useEffect(() => {
    if (isMessageToReplyHasBeenDeleted) {
      // @ts-ignore
      setMessageToReply(null);
      // @ts-ignore
      setMessageToDelete(null);
    }
  }, [isMessageToReplyHasBeenDeleted]);

  useEffect(() => {
    if (messageToEdit) {
      // @ts-ignore
      setMessageToReply(null);
      setMessage(messageToEdit.raw || ``);

      clearFiles();

      if (messageToEdit.files && messageToEdit.files.length > 0) {
        Promise.all(_.map(messageToEdit.files, file => getFileFromFileObject(file, messageToEdit.id)))
          .then(result => { _.forEach(result, addFile); })
          // eslint-disable-next-line no-console
          .catch(console.log);
      }
    }
  }, [messageToEdit]);

  return (
    <>
      {messageToEdit && (
        <InputActionPlate icon={<EditOutlined />} onClick={() => resetState()} title={messageToEdit.raw} />
      )}

      {messageToReply && (
        <InputActionPlate
          icon={<RollbackOutlined />}
          // @ts-ignore
          onClick={() => setMessageToReply(null)}
          title={
            // @ts-ignore
            messageToReply.raw || messageToReply.author ? getFullName(_.get(messageToReply, `author`)) : `—`
          }
        />
      )}

      <InputCore
        addFile={addFile}
        clearFiles={clearFiles}
        dialog={dialog}
        files={files}
        isProcessing={isProcessing}
        message={message}
        messageToEdit={messageToEdit}
        messageToReply={messageToReply}
        notificationsListIds={notificationsListIds}
        notificationTitle={notificationTitle}
        ref={inputRef}
        removeFileByIndex={removeFileByIndex}
        resetState={resetState}
        setMessage={setMessage}
        // @ts-ignore
        setNotificationsListIds={setNotificationsListIds}
      />
    </>
  );
};
