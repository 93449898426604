import * as React from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { getMultiple as getDictionariesAction } from 'models/dictionaries/actions';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';
import { setSearchPartner as setSearchPartnerAction } from 'models/task/actions';

import { Select } from 'antd';

interface IComponentProps {
  canEditTask: boolean;
  task: any;
  taskId: number;
}

export const SelectTaskSearchPartner: React.FC<IComponentProps> = ({ canEditTask, task, taskId }) => {
  const dispatch = useDispatch();

  const hmSearchPartners = useSelector(state => getDictionarySelector(state, `hmSearchPartner`));
  const searchPartner = _.find(hmSearchPartners, { id: task.searchPartnerId });

  const value = searchPartner ? {
    label: `Партнер: ${searchPartner.name}`,
    value: searchPartner.id,
  } : {
    label: `Партнер: не задан`,
    value: null,
  };

  const options = [..._.map(hmSearchPartners, item => ({
    label: item.name,
    value: item.id,
  })), {
    label: `Убрать партнера`,
    value: null,
  }];

  const handleSelect = (partnerId: any) => {
    dispatch(setSearchPartnerAction({
      id             : taskId,
      searchPartnerId: _.toNumber(partnerId),
    }));
  };

  useEffect(() => {
    dispatch(getDictionariesAction({ entities: [`hmSearchPartner`] }));
  }, []);

  return (
    <Select
      disabled={!canEditTask}
      onSelect={handleSelect}
      optionFilterProp='label'
      options={options}
      placeholder='Партнер:'
      style={{ width: `100%` }}
      value={value}
    />
  );
};
