import React, { useState } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { sendSms as sendSmsAction } from 'models/clients/actions';
import { setModal as setModalAction } from 'models/ui/actions';
import { getModal as getModalSelector } from 'models/ui/selectors';

import {
  Button,
  Checkbox,
  Input,
  Modal,
  Spin,
} from 'antd';

const MAX_TEXT_SIZE = 500;
const SMS_SIZE = 70;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledSpin = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledCheckbox = styled(Checkbox)`
  flex: 1;
`;

const Counter = styled.div<{ $alert?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ $alert, theme }) => ($alert ? theme.palette.alert600Color : theme.palette.white60Color)};
`;

const Controls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;

  @media (max-width: 390px) {
    flex-direction: column;
    align-items: flex-end;

    ${StyledCheckbox} {
      margin-bottom: 16px;
      margin-top: -8px;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  &+& {
    margin-left: 8px;
  }
`;

interface SendSmsModalState {
  isLoading: boolean;
  isOpen: boolean;
  personId: string | null; // Замените "string" на фактический тип вашего personId
}

interface IProps {
}

const SendSmsModal: React.FC<IProps> = () => {
  const dispatch = useDispatch();

  const { isLoading, isOpen, personId }:SendSmsModalState = useSelector(state => getModalSelector(state, `sendSms`));

  const close = () => dispatch(setModalAction({ isOpen: false, modalName: `sendSms` }));
  const sendSms = ({ callback, phone, text }: any) => {
    dispatch(sendSmsAction({ callback, personId, phone, text }));
  };

  const [sendMore, setSendMore] = useState<boolean>(false);
  const [text, setText] = useState<string>(``);

  const onCancel = () => {
    close();
    setSendMore(false);
    setText(``);
  };
  const onSubmit = () => sendSms({
    callback: !sendMore ? onCancel : undefined,
    text,
  });

  const textSize = _.size(text);
  const isTextTooBig = textSize >= MAX_TEXT_SIZE;
  const isSubmitDisabled = isLoading || isTextTooBig || !text;
  const smsCount = _.ceil(textSize / SMS_SIZE);

  if (!isOpen || !personId) return null;

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      onCancel={onCancel}
      open={isOpen}
      title='Отправка SMS'
    >
      <Content>
        {isLoading && <StyledSpin />}
        <Input.TextArea
          allowClear
          maxLength={MAX_TEXT_SIZE}
          onChange={e => setText(e.target.value)}
          rows={5}
          value={text}
        />
        <Counter $alert={isTextTooBig}>{`${textSize} / ${MAX_TEXT_SIZE} (${smsCount} SMS)`}</Counter>
        <Controls>
          <StyledCheckbox checked={sendMore} onChange={() => setSendMore(!sendMore)}>
            Отправить ещё
          </StyledCheckbox>
          <ButtonsWrapper>
            <StyledButton onClick={onCancel}>Отмена</StyledButton>
            <StyledButton disabled={isSubmitDisabled} onClick={onSubmit} type='primary'>Отправить</StyledButton>
          </ButtonsWrapper>
        </Controls>
      </Content>
    </Modal>
  );
};

export default SendSmsModal;
