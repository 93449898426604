import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { prettifyNumber } from 'helper';

import {
  Button,
  DatePicker,
  Space,
} from 'antd';
import { CopyIconButton } from 'components/CopyIconButton/CopyIconButton';

const DATE_FORMAT = `DD.MM.YYYY`;

// @ts-ignore
const checkIsDisabledDate = (date: number) => date < moment().subtract(14, `days`).startOf(`day`)
// @ts-ignore
  || date > moment().add(14, `days`).endOf(`day`);

const Wrapper = styled(Space)`
  max-width: calc(100vw - 32px);

  .ant-btn > .anticon + span {
    margin-left: 0;
  }
`;

interface IComponentProps {
  calculatedDate: number;
  isDetailsLoading: boolean;
  onPdpCalc: (date: moment.Moment | null) => void;
  sum: number;
}

const PdpCalculation: React.FC<IComponentProps> = ({ calculatedDate, isDetailsLoading, onPdpCalc, sum }) => {
  const [date, setDate] = useState(moment());

  const dateFormatted = moment(calculatedDate).format(DATE_FORMAT);
  const sumPrettified = prettifyNumber(sum);

  const resultMessage = dateFormatted && sumPrettified
    ? `Сумма к полному погашению на ${dateFormatted}: ${sumPrettified} ₽`
    : undefined;

  return (
    <Wrapper direction='vertical'>
      <Space direction='vertical'>
        <Space>
          <DatePicker
            allowClear={false}
            // @ts-ignore
            disabledDate={checkIsDisabledDate}
            format={DATE_FORMAT}
            onChange={setDate}
            value={date}
          />
          <Button loading={isDetailsLoading} onClick={() => onPdpCalc(date)}>Рассчитать</Button>
        </Space>

        {resultMessage && (
          <p>{resultMessage}
            <CopyIconButton as='span' valueToCopy={resultMessage} />
          </p>
        )}
      </Space>
    </Wrapper>
  );
};

export default PdpCalculation;
