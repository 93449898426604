import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
} from 'styles/theme';

import {
  CheckOutlined,
  StopOutlined,
} from '@ant-design/icons';
import {
  Popconfirm,
  Tag,
} from 'antd';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  &:first-of-type {
    margin-top: 8px;
  }

  @media(min-width: ${breakpoints.md}) {
    &:first-of-type {
      margin-top: 8px;
    }
  }
`;

const Items = styled.ul`
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  @media(min-width: ${breakpoints.xl}) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

const Item = styled.li`
  width: 100%;
  padding: 0 16px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid ${getLightStyleOr(palette.black80Color, palette.black30Color)};
  background-color: transparent;
  transition: all 100ms ease;

  :hover {
    border-color: ${palette.primary500Color};
    background: ${getLightStyleOr(palette.primary700Color, palette.backgroundColor)};
  }
`;

const ItemTitle = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${palette.whiteColor};

  @media(min-width: ${breakpoints.xl}) {
    margin-right: 16px;
  }
`;

const Title = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${palette.whiteColor};
  margin-bottom: 8px;

  @media(min-width: ${breakpoints.md}) {
    margin-bottom: 16px;
  }
`;

const StyledTag = styled(Tag)<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  border-color: unset;
  background-color: ${props => (props.$isActive
    ? `${palette.primary500Color(props)}`
    : `${palette.alert500Color(props)}`
  )};
`;

interface IComponentProps {
  canEdit?: boolean;
  options : Array<{ item?: object; status: boolean, value: string }>;
  title: string;
  switchOption(arg0: any): any;
}

const SelectBlock: React.FC<IComponentProps> = ({ canEdit, options, switchOption, title }) => (
  <Wrapper>
    <Title>{title}</Title>

    <Items>
      {_.map(options, ({ item, status, value }) => (
        <Popconfirm
          cancelText='Нет'
          disabled={!canEdit || status}
          key={value}
          okButtonProps={{ danger: true }}
          okText='Да'
          onConfirm={() => switchOption(item || value)}
          title={`Вы уверены, что хотите активировать ${value}?`}
        >
          <Item>
            <ItemTitle>{value}</ItemTitle>
            <StyledTag $isActive={status}>
              {status
                ? <CheckOutlined style={{ color: `white` }} />
                : <StopOutlined style={{ color: `white` }} />}
            </StyledTag>
          </Item>
        </Popconfirm>
      ))}
    </Items>
  </Wrapper>
);

export default SelectBlock;
