import React, { useState } from 'react';

import { Button } from 'antd';
import LegalNotificationDownload from 'components/Modal/LegalNotificationDownload';

interface IProps {
  personId: string;
  taskId: number;
}

const LegalNotifications: React.FC<IProps> = ({ personId, taskId }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <>
      <Button onClick={() => setIsModalOpened(true)} type='primary'>
        Создать уведомление
      </Button>
      <LegalNotificationDownload
        onClose={() => setIsModalOpened(false)}
        personId={personId}
        taskIds={[taskId]}
        visible={isModalOpened}
      />
    </>
  );
};

export default LegalNotifications;
