import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { updateClientCommunicationType as updateClientCommunicationTypeAction } from 'models/clients/actions';
import { useClient } from 'models/clients/hooks';
import { useDictionaries } from 'models/dictionaries/hooks';

import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

const Wrapper = styled.li<{ noPadding?: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: ${({ noPadding }) => (noPadding ? `8px 0` : `8px 16px`)};
  padding-right: 0;

  & + & {
    border-top: solid 1px ${palette.borderDefaultColor};
  }

  @media (max-width: ${breakpoints.md}) {
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin: 8px 0;
    padding: 0;
    min-width: 100%;

    & + & {
      border-top: none;
    }
  }
`;

const Label = styled.div`
  display: block;
  width: 50%;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.textSecondaryColor};
  flex-grow: 1;
  margin-right: 8px;
  margin-bottom: 4px;

  @media (max-width: ${breakpoints.md}) {
    display: unset;
    margin-bottom: 0;
  }
`;

const Value = styled.span<{ $noLabel: boolean }>`
  display: block;
  font-weight: normal;
  font-size: ${({ $noLabel }) => ($noLabel ? `12px` : `12px`)};
  line-height: 16px;
  color: ${palette.textPrimaryColor};

  @media (max-width: ${breakpoints.md}) {
    display: unset;
    font-size: 14px;
  }
`;

const IconWrapper = styled.span<{ $noLabel: boolean }>`
  ${p => (p.$noLabel && `
    margin: 0 8px;
    color: ${palette.black60Color(p)};
  `)}
`;

interface IProps {
  icon?: React.ReactNode;
  noLabel?: boolean;
  noPadding?: boolean;
  personId: string;
}

const CommunicationTypeDropdown:React.FC<IProps> = ({
  icon: Icon = CaretDownOutlined,
  noLabel = false,
  noPadding = false,
  personId,
}) => {
  const dispatch = useDispatch();
  const [isClientLoading, client] = useClient(personId);
  const [isDictionariesLoading, dictionaries] = useDictionaries([`communicationType`]);

  const clientCommunicationType = _.find(dictionaries.communicationType, { id: client?.communicationTypeId });

  const setClientCommunicationType = useCallback((id: any) => {
    dispatch(updateClientCommunicationTypeAction({ personId, communicationTypeId: _.toNumber(id) }));
  }, [personId, dispatch]);

  const menu = {
    items: _.map(dictionaries.communicationType, ct => ({
      key    : ct.id,
      label  : ct.description || ct.name,
      onClick: () => setClientCommunicationType(ct.id),
    })),
  };

  return (
    <Wrapper noPadding={noPadding}>
      {!noLabel && <Label>Предпочтительный способ связи:</Label>}
      <Dropdown
        disabled={isDictionariesLoading || isClientLoading}
        menu={menu}
        trigger={[`click`]}
      >
        <Value $noLabel={noLabel}>
          {` ${_.isEmpty(clientCommunicationType) ? `не выбран` : clientCommunicationType.description}`}
          {/*@ts-ignore*/}
          <IconWrapper $noLabel={noLabel}><Icon /></IconWrapper>
        </Value>
      </Dropdown>
    </Wrapper>
  );
};

export default CommunicationTypeDropdown;
