import React from 'react';
import _ from 'lodash';
import { Link } from 'dva/router';
import { parsePhoneNumber } from 'libphonenumber-js';

import { REQUIRED } from 'constants/FORM_VALIDATION_RULES';
import {
  getColumn,
  getField,
  getSort,
  sortAlphabetically,
  validateEmail,
  validatePhoneOperator,
} from 'helper';

import { ROLE_NAMES } from 'models/roles/constants';

import { DeleteOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Select,
} from 'antd';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';

export const DISABLED_TITLE = `Доступно для роли ${ROLE_NAMES.SUPER_ADMIN}`;

export const COLUMNS = ({ notificationLists, removeRoleFromUsers, roles }) => _.map([
  {
    key        : `id`,
    align      : `center`,
    render     : id => <Link to={`/admin/operators/${id}`}>{id}</Link>,
    renderExcel: id => id,
    width      : 50,
  },
  {
    dataIndex: [`user`, `fullName`],
    key      : `fullName`,
    title    : `ФИО`,
    sorter   : getSort(`user.fullName`, sortAlphabetically),
    width    : 200,
  },
  {
    dataIndex: [`user`, `email`],
    key      : `email`,
    sorter   : getSort(`user.email`, sortAlphabetically),
    width    : 200,
  },
  {
    dataIndex: [`user`, `roleIds`],
    key      : `roleIds`,
    render   : (roleIds, operator) => _.map(roleIds, roleId => {
      const role = _.find(roles, { id: roleId });
      return (
        <DefaultTag
          closable
          closeIcon={<DeleteOutlined />}
          color={_.get(role, `color`, `geekblue`)}
          key={roleId}
          onClose={() => removeRoleFromUsers({ userId: _.get(operator, `userId`), roleId })}
        >
          {_.get(role, `name`, `-`)}
        </DefaultTag>
      );
    }),
    renderExcel: roleIds => _.join(_.map(roleIds, roleId => {
      const role = _.find(roles, { id: roleId });
      return role?.name || `_`;
    }), `, `),
    title: `Роли`,
    width: 500,
  },
  {
    dataIndex: [`user`, `notificationListIds`],
    key      : `notificationListIds`,
    render   : notificationListIds => _.map(notificationListIds, notificationListId => {
      const notificationList = _.find(notificationLists, { id: notificationListId });
      if (_.isEmpty(notificationList)) return null;
      return (
        <DefaultTag color='geekblue' key={notificationListId}>
          {`${notificationList.description} (${notificationList.name})`}
        </DefaultTag>
      );
    }),
    renderExcel: notificationListIds => _.join(_.map(notificationListIds, notificationListId => {
      const notificationList = _.find(notificationLists, { id: notificationListId });
      if (_.isEmpty(notificationList)) return ``;
      return `${notificationList.description} (${notificationList.name})`;
    }), `, `),
    title: `Общие списки рассылки`,
    width: 150,
  },
  {
    dataIndex: `mttWorkplace`,
    key      : `mttWorkplace`,
    title    : `Рабочее место в МТТ`,
    sorter   : getSort(`mttWorkplace`, sortAlphabetically),
    width    : 150,
  },
  {
    dataIndex: `mttTerminals`,
    key      : `mttTerminals`,
    title    : `Терминалы в МТТ`,
    render   : mttTerminals => _.map(mttTerminals, terminal => (
      <DefaultTag color='default' key={terminal}>{terminal}</DefaultTag>
    )),
    renderExcel: mttTerminals => _.join(mttTerminals, `, `),
    width      : 150,
  },
  {
    dataIndex  : `phone`,
    key        : `phone`,
    title      : `Телефон`,
    width      : 150,
    renderExcel: v => {
      if (!_.isEmpty(v)) {
        const parsedPhone = parsePhoneNumber(v, `RU`);

        return parsedPhone.formatInternational();
      }
      return v;
    },
  },
  {
    dataIndex  : `isEmployed`,
    key        : `isEmployed`,
    title      : `Активен`,
    render     : isEmployed => <Checkbox checked={_.isBoolean(isEmployed) && isEmployed} />,
    renderExcel: _.identity,
    width      : 150,
  },
], getColumn);

export const FORM_FIELDS = ({ genders, omitRoleGroups }) => _.map([
  {
    key         : `surname`,
    title       : `Фамилия`,
    entity      : `user`,
    initialValue: ``,
    required    : true,
    rules       : [REQUIRED],
  },
  {
    key         : `name`,
    title       : `Имя`,
    entity      : `user`,
    initialValue: ``,
    required    : true,
    rules       : [REQUIRED],
  },
  {
    key         : `patronymic`,
    title       : `Отчество`,
    entity      : `user`,
    initialValue: ``,
  },
  {
    key         : `email`,
    title       : `Почта/Логин`,
    entity      : `user`,
    initialValue: ``,
    required    : true,
    rules       : [
      REQUIRED,
      {
        validator: (pass, value) => {
          if (validateEmail(value)) return Promise.resolve();
          return Promise.reject(new Error(`Введите корректный email`));
        },
      },
    ],
  },
  {
    key         : `phone`,
    title       : `Телефон`,
    entity      : `operator`,
    initialValue: ``,
    rules       : [
      {
        validator: (pass, value) => {
          if (value) {
            if (validatePhoneOperator(value)) return Promise.resolve();
            return Promise.reject(new Error(`Введите телефон в формате +7(999)999-99-99 (доб. 1)
             или +79999999999 (доб. 1)`));
          }

          return Promise.resolve();
        },
      },
    ],
  },
  {
    key          : `roleIds`,
    title        : `Роли`,
    className    : `select select_tag`,
    Component    : Select,
    entity       : `user`,
    filterOption : (input, option) => _.includes(option?.children?.props?.children, input),
    initialValue : [],
    mode         : `multiple`,
    optionsRender: ({
      color,
      description,
      name,
    }) => <DefaultTag color={color}>{`${name}${description ? ` (${description})` : ``}`}</DefaultTag>,
    optionsSource: `roles`,
  },
  ...(omitRoleGroups ? [] : [{
    key          : `roleGroupIds`,
    title        : `Группы ролей`,
    Component    : Select,
    entity       : `user`,
    initialValue : [],
    mode         : `multiple`,
    optionsSource: `roleGroups`,
  }]),
  {
    key          : `notificationListIds`,
    title        : `Общие списки рассылки уведомлений`,
    Component    : Select,
    entity       : `user`,
    initialValue : [],
    mode         : `multiple`,
    optionsRender: ({ description, name }) => `${description} (${name})`,
    optionsSource: `notificationLists`,
  },
  {
    key          : `isEmployed`,
    title        : `Активен`,
    Component    : Checkbox,
    entity       : `operator`,
    required     : false,
    initialValue : true,
    valuePropName: `checked`,
    labelAsChild : true,
  },
  {
    key         : `gender`,
    title       : `Пол`,
    Component   : Select,
    entity      : `user`,
    initialValue: 1,
    options     : genders,
    required    : true,
    rules       : [REQUIRED],
  },
], getField);
