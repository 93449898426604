import * as React from 'react';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  CloseOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

const ImageName = styled.div`
  position: absolute;
  color: ${palette.whiteColor};
  top: 0;
  width: 100%;
  text-align: center;
  padding: 8px;
  margin-top: 8px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  background: ${p => transparentize(0.7, palette.blackColor(p))};
  position: absolute;
  top: 0;
  z-index: 1;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;

  ${ImageName} {
    position: relative;
  }
`;

const Actions = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
`;

const IconWrapper = styled.div<{ isDisabled?: boolean; }>`
  .anticon {
    user-select: none;
    padding: 16px;
    font-size: 18px;
    color: ${palette.whiteColor};
    cursor: pointer;

    ${({ isDisabled }) => isDisabled && `
      opacity: 0.5;
      cursor: not-allowed;
    `};
  }
`;

interface IComponentProps {
  imageName: string;
  isScaleDownDisabled: boolean;
  isScaleUpDisabled: boolean;
  onCancel: () => void;
  rotateLeft: () => void;
  rotateRight: () => void;
  scaleDown: () => void;
  scaleUp: () => void;
}

export const ImagePreviewControls: React.FC<IComponentProps> = ({
  imageName,
  isScaleDownDisabled,
  isScaleUpDisabled,
  onCancel,
  rotateLeft,
  rotateRight,
  scaleDown,
  scaleUp,
}) => (
  <Wrapper>
    <div />
    <ImageName>{imageName}</ImageName>
    <Actions>
      <DefaultTooltip placement='bottom' title='Повернуть влево "["'>
        <IconWrapper onClick={rotateLeft}>
          <RotateLeftOutlined />
        </IconWrapper>
      </DefaultTooltip>
      <DefaultTooltip placement='bottom' title='Повернуть вправо "]"'>
        <IconWrapper onClick={rotateRight}>
          <RotateRightOutlined />
        </IconWrapper>
      </DefaultTooltip>
      <DefaultTooltip placement='bottom' title='Отдалить [-]'>
        <IconWrapper
          isDisabled={isScaleDownDisabled}
          onClick={scaleDown}
        >
          <ZoomOutOutlined />
        </IconWrapper>
      </DefaultTooltip>
      <DefaultTooltip placement='bottom' title='Приблизить [+]'>
        <IconWrapper
          isDisabled={isScaleUpDisabled}
          onClick={scaleUp}
        >
          <ZoomInOutlined />
        </IconWrapper>
      </DefaultTooltip>
      <DefaultTooltip placement='bottomRight' title='Закрыть [Esc]'>
        <IconWrapper onClick={onCancel}>
          <CloseOutlined />
        </IconWrapper>
      </DefaultTooltip>
    </Actions>
  </Wrapper>
);
