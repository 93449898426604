import { createAction } from 'redux-actions';

export const model = `calls`;

const ca = (type: string, meta?: any) => createAction(`${model}/${type}`, (payload: any) => payload, () => meta);

export const GET = `GET`;
export const SET = `SET`;
export const SET_CALLS = `SET_CALLS`;
export const SET_LOADING = `SET_LOADING`;
export const SET_TABLE_STATE = `SET_TABLE_STATE`;

export const get = ca(GET);
export const set = ca(SET);
export const setCalls = ca(SET_CALLS);
export const setLoading = ca(SET_LOADING);
export const setTableState = ca(SET_TABLE_STATE);
