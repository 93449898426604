import styled, { css } from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
  shadows,
} from 'styles/theme';

export const EntityCard = styled.div`
  position: relative
`;

export const EntityCardTitle = styled.div`
  font-family: "PT Root UI", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;

  color: ${palette.textPrimaryColor};
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  a {
    color: ${palette.textPrimaryColor};

    :hover {
      color: ${palette.textSecondaryColor};
    }
  }
`;

export const EntityCardContent = styled.div`
  box-shadow: ${shadows.image};
  background-color: ${getLightStyleOr(palette.whiteColor, palette.black10Color)};
  border: 1px solid ${palette.black10Color};

  padding: 12px;
  border-radius: 8px;

  @media(min-width: ${breakpoints.md}) {
    padding: 16px;
  }
`;

export const entityCardRowTextMixin = css`
  font-family: "PT Root UI", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;

  @media(min-width: ${breakpoints.lg}) {
    font-size: 14px;
  }
`;
