import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import {
  downloadBlob,
  showError,
} from 'helper';

import {
  getDocumentsArchive,
  getDocumentsPdf,
} from 'models/clients/api';
import { useClient } from 'models/clients/hooks';
import {
  IDoc,
  IGetDocumentsRequest,
} from 'models/clients/types';

import useMobileContext from 'hooks/useMobileContext';

import {
  FilePdfOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Popover,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { DocsContext } from 'pages/PageClient/Single/Docs/index';

const ButtonExtra = styled(Button)`
  margin-right: 12px;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;

  &+& {
    margin-top: 12px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${breakpoints.md}) {
    margin-bottom: 16px;
    min-width: calc(100% - 4px);
    justify-content: space-between;

    >div {
      flex: 1;

      :first-child {
        margin-right: 8px;
      }
    }
  }
`;

interface IControl {
  buttonType?: `default` | `primary`;
  docs: IDoc[];
  downloadType: `archive` | `pdf`;
  isDownloading: boolean;
  selectedTypes: number[];
  clearAllTypes(): void;
  downloadArchive():void;
  downloadPdf():void;
  selectAllTypes(): void;
  setSelectedTypes(selectedTypes: number[]): void;
}

interface IProps {
  applicationId: string;
  buttonType?: `default` | `primary`;
  docs: IDoc[];
  personId: string;
  sectionTitle?: string;
}

const DownloadControl: React.FC<IControl> = ({
  buttonType,
  clearAllTypes,
  docs,
  downloadArchive,
  downloadPdf,
  downloadType,
  isDownloading,
  selectAllTypes,
  selectedTypes,
  setSelectedTypes,
}) => {
  const { mobile } = useMobileContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const typeTitle = downloadType === `pdf` ? `PDF` : `Архив`;

  const actionTitle = `Скачать ${typeTitle}${downloadType === `pdf` ? ` (конвертация и склейка большого числа документов может занимать до 30 секунд)` : ``}`; // eslint-disable-line max-len
  const shortActionTitle = `Скачать ${typeTitle}`;
  const title = `Выберите файлы для добавления в ${typeTitle}`;
  const action = downloadType === `pdf` ? downloadPdf : downloadArchive;
  const Icon = downloadType === `pdf` ? FilePdfOutlined : FileZipOutlined;
  return (
    <DefaultTooltip key={downloadType} title={actionTitle}>
      <div onClick={e => e.stopPropagation()}>
        <Popover
          content={(
            <>
              <StyledCheckboxGroup
                //@ts-ignore
                onChange={setSelectedTypes}
                options={_.map(docs, ({ name, type }) => ({
                  label: name,
                  value: type,
                }))}
                value={selectedTypes}
              />
              <Buttons>
                <SubmitButton onClick={clearAllTypes}>
                  Очистить все
                </SubmitButton>
                <SubmitButton onClick={selectAllTypes}>
                  Выбрать все
                </SubmitButton>
                <SubmitButton
                  disabled={isDownloading}
                  onClick={() => {
                    setIsOpen(false);
                    action();
                  }}
                  type='primary'
                >
                  {actionTitle}
                </SubmitButton>
              </Buttons>
            </>
          )}
          onOpenChange={setIsOpen}
          open={isOpen}
          title={title}
          trigger='click'
        >
          <ButtonExtra
            icon={<Icon />}
            onClick={e => e.stopPropagation()}
            type={buttonType}
          >
            {mobile ? shortActionTitle : ``}
          </ButtonExtra>
        </Popover>
      </div>
    </DefaultTooltip>
  );
};

const DownloadGroupControls: React.FC<IProps> = ({
  applicationId,
  buttonType = `default`,
  docs,
  personId,
  sectionTitle,
}) => {
  const { isDownloading, setIsDownloading } = useContext(DocsContext);

  const [selectedTypes, setSelectedTypes] = useState<number[]>(_.map(docs, `type`));

  const clearAllTypes = () => setSelectedTypes([]);
  const selectAllTypes = () => setSelectedTypes(_.map(docs, `type`));

  const [,client] = useClient(personId);
  const clientSurname = _.get(client, `surname`, personId);
  const fileName = sectionTitle ? `${sectionTitle}_${clientSurname}` : clientSurname;

  useEffect(() => {
    clearAllTypes()
  }, [docs.length]);

  const filteredDocs = _.filter(
    docs,
    ({ type }) => _.includes(selectedTypes, type),
  );

  const downloadDocs = async (downloadType: string) => {
    try {
      setIsDownloading(true);
      const enrichedDocs:IGetDocumentsRequest[] = _.map(filteredDocs, doc => ({
        applicationId,
        fileId: doc.id,
        personId,
      }));
      const apiFn = downloadType === `pdf` ? getDocumentsPdf : getDocumentsArchive;
      const { data: blob } = await apiFn(enrichedDocs, fileName);
      downloadBlob(blob, fileName);
    } catch (error) {
      showError({
        error: `При скачивании документа произошла ошибка. Попробуйте еще раз или обратитесь к администратору`,
      });
      console.log({ error }); // eslint-disable-line no-console
    } finally {
      setIsDownloading(false);
    }
  };

  const downloadArchive = () => downloadDocs(`archive`);
  const downloadPdf = () => downloadDocs(`pdf`);

  return (
    <Wrapper>
      {_.map([`archive`, `pdf`], (downloadType:`archive` | `pdf`) => (
        <DownloadControl
          buttonType={buttonType}
          clearAllTypes={clearAllTypes}
          docs={docs}
          downloadArchive={downloadArchive}
          downloadPdf={downloadPdf}
          downloadType={downloadType}
          isDownloading={isDownloading}
          key={downloadType}
          selectAllTypes={selectAllTypes}
          selectedTypes={selectedTypes}
          setSelectedTypes={setSelectedTypes}
        />
      ))}
    </Wrapper>
  );
};
export default DownloadGroupControls;
