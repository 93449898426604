import {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';

export function useDynamicFilterValues(dataSource: any, key: string) {
  const [filterValues, setFilterValues] = useState([]);

  useEffect(() => {
    if (dataSource) {
      const uniqueItems = _.uniq(_.compact(_.map(dataSource, key)));
      // @ts-ignore
      setFilterValues(uniqueItems);
    }
  }, [dataSource, key]);

  return [filterValues];
}
