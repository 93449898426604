import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { hasIntersection } from 'helper';

import {
  getPaymentGateways as getPaymentGatewaysAction,
  getShortLinkServices as getShortLinksAction,
  getSmsGateways as getSmsGatewaysAction,
  switchPaymentGateway as switchPaymentGatewayAction,
  switchShortLinkService as switchShortLinksAction,
  switchSmsGateway as switchSmsGatewayAction,
} from 'models/admin/actions';
import {
  getPaymentGateways as getPaymentGatewaysSelector,
  getShortLinkServices as getShortLinksSelector,
  getSmsGateways as getSmsGatewaysSelector,
  isLoading as isAdminLoadingSelector,
} from 'models/admin/selectors';
import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as userRolesSelector } from 'models/user/selectors';

import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import { DefaultSpin } from 'components/DefaultSpin/DefaultSpin';
import SelectBlock from 'components/SelectBlock/SelectBlock';
import MobileContext from 'pages/Layout/MobileContext';
import { MenuItemAdminBig } from 'pages/PageAdmin/Main/components/MenuItemAdminBig';

import { ADMIN_MENU } from '../constants';

const StyledSpace = styled(Space)<{
  $align: string,
  $direction: string,
  $wrap: boolean,
}>`
  display: flex;
  flex-wrap: wrap;
  gap: 24px !important;
  margin: 0;
  padding: 40px 60px;

  @media screen and (max-width: ${breakpoints.lg}) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media (min-width:  ${breakpoints.md}) and (max-width: 1063px) {
    .ant-space-item {
      display: flex;
      width: calc(50% - 12px);
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: center;
    gap: 8px !important;
    padding: 24px 16px !important;

    .ant-space-item {
      display: flex;
      width: 100%;
    }
  }
`;

const StyledDarkSpace = styled(Space)<{
  $align: string,
  $direction: string,
  $isClosed: boolean,
  $wrap: boolean,
}>`
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: ${palette.backgroundAccentColor};
  padding: 0 60px 16px 60px;
  align-items: flex-start;

  .ant-space-item {
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    padding: 8px 16px 16px 16px;
    gap: 16px;
    ${props => (props.$isClosed && `padding-bottom: 12px;`)};
  }
`;

const SelectControl = styled.div<{ $isOpen: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.whiteColor};
  display: flex;
  padding-top: 12px;
  padding-bottom: ${({ $isOpen }) => ($isOpen && `4px`)};
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  span {
    font-size: 16px;
  }
`;

const Main = () => {
  const mobile = useContext(MobileContext);

  const isLoading = useSelector(isAdminLoadingSelector);
  const paymentGateways = _.map(useSelector(getPaymentGatewaysSelector), item => ({
    status: item.activeFlg,
    value : item.channelName,
    item,
  }));
  const shortLinks = _.map(useSelector(getShortLinksSelector), ({ shortUrlClientName, status }) => ({
    status: (status === 1),
    value : shortUrlClientName,
  }));
  const smsGateways = _.map(useSelector(getSmsGatewaysSelector), ({ operatorName, status }) => ({
    status: (status === 1),
    value : operatorName,
  }));
  const userRoles = useSelector(userRolesSelector);

  const dispatch = useDispatch();

  const getPaymentGateways = useCallback(() => dispatch(getPaymentGatewaysAction()), [dispatch]);
  const getShortLinkServices = useCallback(() => dispatch(getShortLinksAction()), [dispatch]);
  const getSmsGateways = useCallback(() => dispatch(getSmsGatewaysAction()), [dispatch]);

  const switchPaymentGateways = useCallback((item: { activeFlg: any; }) => dispatch(switchPaymentGatewayAction({ item: {
    ...item,
    activeFlg: !item.activeFlg,
  } })), [dispatch]);
  const switchShortLinks = useCallback((shortUrlClientName: any) => dispatch(
    switchShortLinksAction({ shortUrlClientName }),
  ), [dispatch]);
  const switchSmsGateway = useCallback((operatorName: string) => dispatch(switchSmsGatewayAction({ operatorName })), [dispatch]);

  const menu = _.includes(userRoles, ROLE_NAMES.SUPER_ADMIN)
    ? ADMIN_MENU
    : _.filter(ADMIN_MENU, ({ roles }) => _.isEmpty(roles) || hasIntersection(userRoles, roles));
  const canEditPaymentGateways = hasIntersection(
    userRoles,
    [ROLE_NAMES.SUPER_ADMIN, ROLE_NAMES.PAYMENT_GATEWAY_OPERATOR],
  );
  const canEditShortLinks = hasIntersection(
    userRoles,
    [ROLE_NAMES.SUPER_ADMIN, ROLE_NAMES.SHORT_LINK_OPERATOR],
  );
  const canEditSmsGateways = hasIntersection(
    userRoles,
    [ROLE_NAMES.SUPER_ADMIN, ROLE_NAMES.SMS_GATEWAY_OPERATOR],
  );

  const [isSelectBlocksOpen, setSelectBlocksOpen] = useState(false);

  useEffect(() => {
    getPaymentGateways();
    getShortLinkServices();
    getSmsGateways();
  }, []);

  return (
    <>
      {isLoading && <DefaultSpin />}
      <StyledDarkSpace
        $align='center'
        $direction={mobile ? `vertical` : `horizontal`}
        $isClosed={!isSelectBlocksOpen}
        $wrap
      >
        {mobile && (
        <SelectControl $isOpen={isSelectBlocksOpen} onClick={() => setSelectBlocksOpen(!isSelectBlocksOpen)}>
          Шлюзы и сервисы
          {isSelectBlocksOpen
            ? <UpOutlined />
            : <DownOutlined />}
        </SelectControl>
        )}

        {((mobile && isSelectBlocksOpen) || !mobile) && (
        <>
          <SelectBlock
            canEdit={canEditSmsGateways}
            options={smsGateways}
            switchOption={switchSmsGateway}
            title='СМС шлюзы'
          />
          <SelectBlock
            canEdit={canEditPaymentGateways}
            options={paymentGateways}
            switchOption={switchPaymentGateways}
            title='Платёжные шлюзы'
          />
          <SelectBlock
            canEdit={canEditShortLinks}
            options={shortLinks}
            switchOption={switchShortLinks}
            title='Сервисы коротких ссылок'
          />
        </>
        )}
      </StyledDarkSpace>
      <StyledSpace
        $align='center'
        $direction={mobile ? `vertical` : `horizontal`}
        $wrap
      >
        {_.map(menu, ({ icon, path, testAttribute, title }) => (
          <MenuItemAdminBig
            icon={icon}
            key={path}
            mobile={mobile}
            path={path}
            testAttribute={testAttribute}
            title={title}
          />
        ))}
      </StyledSpace>
    </>
  );
};

export default Main;
