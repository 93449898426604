import { transparentize } from 'polished';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { Spin } from 'antd';

export const DefaultSpin = styled(Spin)`
  align-items: center;
  background-color: ${p => transparentize(0.2, palette.backgroundColor(p))};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
`;
