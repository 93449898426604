import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  getClientApplicationDocs as getClientApplicationDocsAction,
  uploadApplicationDocument as uploadApplicationDocumentAction,
} from 'models/clients/actions';
import { IDoc } from 'models/clients/types';
import { useDictionaries } from 'models/dictionaries/hooks';
import { getUploadableDocumentTypes } from 'models/dictionaries/selectors';
import { TDictionaryItem } from 'models/dictionaries/types';

import {
  CheckCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  Checkbox,
  Modal,
  Select,
  Upload,
} from 'antd';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  >* {
    min-width: 100%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 16px;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

const StyledUpload = styled(Upload.Dragger)`
  border-color: ${palette.primaryColor} !important;
  border-radius: 8px !important;
  margin-bottom: 16px;
`;

const UploadIcon = styled.p`
  color: ${palette.primaryColor};
  font-size: 32px;
`;

const UploadText = styled.p`
  color: ${palette.primaryColor};
`;

interface IProps {
  afterUpload?:(applicationId:string)=>void;
  applicationId: string;
  docs?: IDoc[],
  documentTypeId?:number;
  isOpen: boolean
  personId: string,
  title?: string,
  close():void;
}

const ApplicationDocumentUploadModal: React.FC<IProps> = ({
  afterUpload,
  applicationId,
  close,
  docs,
  documentTypeId,
  isOpen,
  personId,
  title,
}) => {
  const dispatch = useDispatch();
  const [addToCurrent, setAddToCurrent] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  // @ts-ignore
  const [selectedDocumentType, setSelectedDocumentType] = useState<number>(documentTypeId || null);

  useDictionaries([`documentType`]);
  const uploadableDocumentTypes:TDictionaryItem[] = useSelector(getUploadableDocumentTypes) || [];
  const documentTypes = _.map(_.sortBy(uploadableDocumentTypes, `name`), dT => ({
    ...dT,
    label: dT.name,
    value: dT.id,
  }));

  const getApplicationDocs = useCallback(() => dispatch(getClientApplicationDocsAction({
    applicationId,
    personId,
  })), [dispatch, applicationId, personId]);
  const reset = () => {
    // @ts-ignore
    setSelectedDocumentType(null);
    setFile(null);
    close();
  };
  const upload = () => dispatch(uploadApplicationDocumentAction({
    addToCurrent,
    applicationId,
    callback: () => {
      reset();
      getApplicationDocs();
      if (afterUpload) afterUpload(applicationId);
    },
    documentType: selectedDocumentType,
    file,
    personId,
  }));

  useEffect(() => {
    // @ts-ignore
    setSelectedDocumentType(documentTypeId)
  }, [documentTypeId]);
  const isSubmitDisabled = !personId || !applicationId || !selectedDocumentType || !file;
  const okText = _.find(docs, { type: documentTypeId }) ? `Заменить` : `Добавить`;

  return (
    <Modal
      cancelText='Отмена'
      centered
      closable={false}
      maskClosable={false}
      okButtonProps={{
        disabled: isSubmitDisabled,
      }}
      okText={okText}
      onCancel={reset}
      onOk={upload}
      open={isOpen}
      title={title}
    >
      <Content>
        <StyledSelect
          disabled={!!documentTypeId}
          filterOption={(input, option) => (
            // @ts-ignore
            _.includes(_.toLower(option.label), _.toLower(input))
            // @ts-ignore
            || _.includes(_.toLower(input), _.toLower(option.label))
          )}
          // @ts-ignore
          onChange={setSelectedDocumentType}
          options={documentTypes}
          placeholder='Выберите тип документа'
          showSearch
          value={selectedDocumentType}
        />

        <StyledUpload
          // @ts-ignore
          customRequest={f => setFile(f ? f.file : null)}
          multiple={false}
          showUploadList={false}
        >
          {file
            ? (
              <>
                <UploadIcon><CheckCircleOutlined /></UploadIcon>
                <UploadText>{file.name}</UploadText>
              </>
            )
            : (
              <>
                <UploadIcon><InboxOutlined /></UploadIcon>
                <UploadText>Кликните или перетащите сюда файл для загрузки</UploadText>
              </>
            )}
        </StyledUpload>
        <StyledCheckbox
          checked={addToCurrent}
          onChange={() => setAddToCurrent(!addToCurrent)}
        >
          Записать в текущий
        </StyledCheckbox>
      </Content>
    </Modal>
  );
};

export default ApplicationDocumentUploadModal;
