import React from 'react';

import { TDictionaryItem } from 'models/dictionaries/types';
import { TEventlogItem } from 'models/eventlogs/types';

import useMobileContext from 'hooks/useMobileContext';

import {
  LoginOutlined,
  LogoutOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import { ItemHead } from 'components/Eventlog/Item/ItemHead';

import {
  Arrow,
  Content,
  Description,
  Icon,
  Wrapper,
} from '../styled';

interface IComponentProps extends TEventlogItem {
  date:string;
  hmSearchPartnerNext?: TDictionaryItem;
  hmSearchPartnerPrev?: TDictionaryItem;
  operatorName?:string;
}

export const TaskHmSearchPartner: React.FC<IComponentProps> = ({
  date,
  hmSearchPartnerNext,
  hmSearchPartnerPrev,
  operatorName,
}) => {
  const { mobile } = useMobileContext();

  let title;
  let icon;
  let color;
  if (!hmSearchPartnerPrev) {
    title = `Добавлен партнер по розыску`;
    color = `green`;
    icon = <LoginOutlined />;
  } else if (!hmSearchPartnerNext) {
    title = `Снят партнер по розыску`;
    color = `red`;
    icon = <LogoutOutlined />;
  } else {
    title = `Изменён партнер по розыску`;
    color = `orange`;
    icon = <SwapOutlined />;
  }

  return (
    <Wrapper $isMobile={mobile}>
      <Icon $color={color}>{icon}</Icon>
      <Content>
        <ItemHead date={date} operatorName={operatorName} title={title} />
        <Description>
          {hmSearchPartnerPrev && (
            <DefaultTag>{hmSearchPartnerPrev.name}</DefaultTag>
          )}
          {hmSearchPartnerPrev && hmSearchPartnerNext && <Arrow />}
          {hmSearchPartnerNext && (
            <DefaultTag>{hmSearchPartnerNext.name}</DefaultTag>
          )}
        </Description>
      </Content>
    </Wrapper>
  );
};
