import React, { forwardRef } from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import {
  CloseOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

const Wrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  z-index: 3;
  margin-top: 16px;
  right: 8px;
  top: 4px;
  background-color: ${palette.backgroundAccentColor};

  @media(min-width: ${breakpoints.sm}) {
    display: flex;
    justify-content: flex-end;
  }

  ${({ isVisible }) => isVisible && `
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    margin-top: 0;
    align-items: center;
  `}
`;

const RecentIcon = styled(HistoryOutlined)`
  font-size: 18px;
  margin-right: 12px;
`;

const Header = styled.div<{ isVisible: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ isVisible }) => isVisible && `
    position: relative;
    width: 100%;
    height: 100%;
  `}
`;

const Title = styled.div<{ isVisible: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 16px;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  color: ${palette.whiteColor};
  transition: opacity 100ms ease;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

const RecentButton = styled(Button)`
  color: ${palette.whiteColor};
`;

interface IComponentProps {
  isRecentOpen: boolean;
  setIsRecentOpen: (value: boolean) => void;
}

export const RecentMobile = forwardRef<HTMLButtonElement, IComponentProps>(
  ({ isRecentOpen, setIsRecentOpen },
    ref) => {
    const toggleRecent = () => setIsRecentOpen(!isRecentOpen);

    return (
      <Wrapper isVisible={isRecentOpen}>
        <Header isVisible={isRecentOpen}>
          {isRecentOpen ? (
            <>
              <Title isVisible={isRecentOpen}>
                <RecentIcon />
                Недавно просмотрено
              </Title>
              <RecentButton
                icon={<CloseOutlined />}
                onClick={toggleRecent}
                ref={ref}
                type='text'
              />
            </>
          ) : (
            <RecentButton
              icon={<HistoryOutlined />}
              onClick={toggleRecent}
              type='text'
            />
          )}
        </Header>
      </Wrapper>
    );
  },
);

