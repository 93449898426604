import _ from 'lodash';
import { createSelector } from 'reselect';

import { hasIntersection } from 'helper';

import {
  getRoleIds,
  isSuperAdmin as isSuperAdminSelector,
} from 'models/user/selectors';

import { model } from './actions';

import {
  CAMPAIGN_RIGHT_COLUMNS_MAP,
  DOC_GROUP_UPLOAD_BLACKLIST,
} from './constants';

const getItemsMap = (state, entity) => {
  const items = _.get(state, `items.${entity}`, []);
  if (_.some(items, item => _.has(item, `order`))) return _.sortBy(items, `order`);
  return items;
};

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(
  [
    getState,
    (state, entity) => entity,
  ],
  getItemsMap,
);

export const getItemsMultiple = createSelector(
  [
    getState,
    (state, entities) => entities,
  ],
  (state, entities) => _.reduce(entities, (result, entity) => ({
    ...result,
    [entity]: getItemsMap(state, entity),
  }), {}),
);

const mapItemsForSelect = items => _.map(items, item => ({
  label: item?.description || item?.name,
  value: item?.id,
}));

export const getItemsForSelect = createSelector(
  [getItems, (state, entity, mapFunc = mapItemsForSelect) => mapFunc],
  (items, mapFunc) => mapFunc(items),
);

export const getSortedByNameItems = createSelector(
  [
    getState,
    (state, entity) => entity,
  ],
  (state, entity) => {
    const items = _.get(state, `items.${entity}`, []);
    return _.sortBy(items, `name`);
  },
);

export const getDocumentTypes = createSelector(
  state => getItems(state, `documentType`),
  items => _.map(
    items,
    ({ groupId, ...item }) => ({
      ...item,
      readOnly: _.includes(DOC_GROUP_UPLOAD_BLACKLIST, groupId),
    }),
  ),
);

export const getUploadableDocumentTypes = createSelector(
  getDocumentTypes,
  items => _.sortBy(_.filter(items, ({ readOnly }) => !readOnly), `name`),
);

export const getItemsNames = createSelector(
  getItems,
  items => _.map(items, `name`),
);

export const getItem = createSelector(
  [
    getItems,
    (state, entity, id) => id,
  ],
  (items, id) => _.find(items, { id }),
);

export const getItemByName = createSelector(
  [
    getItems,
    (state, entity, name) => name,
  ],
  (items, name) => _.find(items, { name }),
);

export const getItemsByIds = createSelector(
  [
    getItems,
    (state, entity, ids) => ids,
  ],
  (items, ids) => _.filter(items, ({ id }) => _.includes(ids, id)),
);

export const getItemIdByName = createSelector(
  getItemByName,
  item => _.get(item, `id`),
);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);

export const getLoadedDictionaries = createSelector(getState, dictionaries => _.get(dictionaries, `loaded`, []));

export const isLoaded = createSelector(
  [
    getLoadedDictionaries,
    (state, dictionary) => dictionary,
  ],
  (loaded, dictionary) => _.includes(loaded, dictionary),
);

export const getUnloadedDictionaries = createSelector(
  [
    getLoadedDictionaries,
    (state, dictionariesToCheck) => dictionariesToCheck,
  ],
  (loaded, dictionariesToCheck) => _.difference(dictionariesToCheck, loaded),
);

export const getSearchString = createSelector(
  getState, (state, entity) => entity,
  ({ searchString }, entity) => _.get(searchString, entity, ``),
);

export const getItemsFiltered = createSelector(
  [getItems, getSearchString],
  (items, searchString) => (searchString
    ? _.filter(
      items,
      ({ description, name, text }) => (
        _.includes(_.toLower(name), _.toLower(searchString))
        || _.includes(_.toLower(description), _.toLower(searchString))
        || _.includes(_.toLower(text), _.toLower(searchString))
      ),
    )
    : items),
);

export const getAllowedCampaigns = createSelector(
  [state => getItems(state, `campaign`), getRoleIds, isSuperAdminSelector],
  (campaigns, userRoleIds, isSuperAdmin) => {
    if (isSuperAdmin) return campaigns;

    return _.filter(
      campaigns,
      ({ roleAdminId, roleReadId, roleWriteId }) => _.find(
        userRoleIds,
        id => id === roleAdminId || id === roleReadId || id === roleWriteId,
      ),
    );
  },
);

export const getCampaignBlocks = createSelector(
  state => getItems(state, `campaign`),
  campaigns => _.compact(_.uniq(_.map(campaigns, ({ block }) => block))),
);

export const getAllowedCampaignBlocks = createSelector(
  getAllowedCampaigns,
  campaigns => _.compact(_.uniq(_.map(campaigns, ({ block }) => block))),
);

export const getBlockCampaigns = createSelector(
  [state => getItems(state, `campaign`), (state, blockTitle) => blockTitle],
  (campaigns, blockTitle) => _.filter(campaigns, { block: blockTitle }),
);

export const getAllowedBlockCampaigns = createSelector(
  [getAllowedCampaigns, (state, blockTitle) => blockTitle],
  (campaigns, blockTitle) => _.filter(campaigns, { block: blockTitle }),
);

export const getBlockCampaignTypes = createSelector(
  [state => getItems(state, `campaignType`), getBlockCampaigns],
  (campaignTypes, campaigns) => {
    const campaignIds = _.map(campaigns, `id`);
    return _.filter(campaignTypes, ({ campaignId }) => _.includes(campaignIds, campaignId));
  },
);

/**
 * @param state
 * @param campaignName: string
 * @param right: one from (read | write | admin | batchLoad | batchClose)
 * @returns boolean
 */
export const checkRightByCampaignName = createSelector(
  [
    state => getItems(state, `campaign`),
    (s, campaignName) => campaignName,
    (s, c, right) => right,
    getRoleIds,
    isSuperAdminSelector,
  ],
  (campaigns, campaignName, right, userRoleIds, isSuperAdmin) => {
    if (isSuperAdmin) return true;
    const campaign = _.find(campaigns, { name: campaignName }, {});
    return hasIntersection(
      userRoleIds,
      _.compact(_.values(_.pick(campaign, CAMPAIGN_RIGHT_COLUMNS_MAP[right]))),
    );
  },
);

export const getFinished = createSelector(
  [getState, (state, uuid) => uuid],
  ({ finished }, uuid) => finished?.[uuid] || 0,
);

export const getCourtResolutionTypes = createSelector(
  [
    state => getItems(state, `resolutionType`),
    (s, groupId) => groupId,
  ],
  (items, groupId) => mapItemsForSelect(_.filter(items, { groupId })),
);
