import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import {
  copyToClipboard,
  fallBackTableValue,
  formatDate,
  getColumn as getColumnDefault,
  getSort,
  prettifyNumber,
  sortAlphabetically,
} from 'helper';

import {
  APPLICATION_STATUSES,
  APPLICATION_STATUSES_COLORS,
  APPLICATION_STATUSES_NAMES,
} from 'models/clients/constants';

import { CopyOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Tooltip,
} from 'antd';
import { CellCheckbox } from 'components/DefaultTable/CellCheckbox/CellCheckbox';
import { CellDateTime } from 'components/DefaultTable/CellDateTime/CellDateTime';
import { CellId } from 'components/DefaultTable/CellId/CellId';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';

const VerificationResultType = styled.div`
  font-weight: bold;
`;

const getColumn = (props: { key: any; }) => getColumnDefault({ sorter: getSort(props.key), ...props });

export const COLUMNS = (productNames: never[]) => _.map([
  {
    defaultSortOrder: `descend`,
    key             : `applicationDtm`,
    title           : `Создана`,
    render          : (v: number | undefined) => <CellDateTime value={v} />,
  },
  {
    key   : `id`,
    render: (v: string) => <CellId value={v} />,
  },
  {
    key    : `productName`,
    title  : `Продукт`,
    render : fallBackTableValue(`productId`),
    sorter : getSort(`productName`, sortAlphabetically),
    filters: _.map(productNames, productName => ({
      text : productName,
      value: productName,
    })),
    filterMultiple: true,
    onFilter      : (value: any, record: { productName: any; }) => value === record?.productName,
  },
  {
    align : `right`,
    key   : `requestedLoan`,
    title : `Сумма`,
    render: prettifyNumber,
    width : 100,
  },
  {
    align: `right`,
    key  : `requestedTerm`,
    title: `Срок, мес`,
    width: 120,
  },
  {
    key   : `hasSupplement`,
    title : `Страховка`,
    render: (v: boolean | undefined) => (_.isBoolean(v) ? (<Checkbox checked={v} />) : `?`),
    width : 100,
  },
  {
    key   : `partnerName`,
    render: fallBackTableValue(`partnerId`, undefined, `-`),
    title : `Партнер`,
    sorter: getSort(`partnerName`, sortAlphabetically),
    width : 110,
  },
  {
    key   : `applicationTypeName`,
    render: fallBackTableValue(`applicationTypeId`),
    title : `Тип`,
    sorter: getSort(`applicationTypeName`, sortAlphabetically),
    width : 100,
  },
  {
    key   : `applicationStatusId`,
    render: (v: any, record: { applicationStatusId: number; errorDescription: any; errorDtm: any; applicationStatusName: string | undefined; }) => {
      const color = APPLICATION_STATUSES_COLORS[record?.applicationStatusId] || `default`;

      if (record?.applicationStatusId === APPLICATION_STATUSES.ERROR) {
        const description = `${record?.errorDescription} (${formatDate(record?.errorDtm)})`;
        return (
          <>
            <Tooltip title={description}>
              <DefaultTag color={color}>
                {_.upperFirst(record?.applicationStatusName)}
              </DefaultTag>
            </Tooltip>
            <Tooltip title='Копировать описание ошибки'>
              <CopyOutlined onClick={() => copyToClipboard(description)} style={{ marginLeft: `4px` }} />
            </Tooltip>
          </>
        );
      }

      return (
        <DefaultTag color={color}>
          {_.upperFirst(record?.applicationStatusName)}
        </DefaultTag>
      );
    },
    title  : `Статус`,
    filters: _.map(_.values(APPLICATION_STATUSES), applicationStatusId => (
      {
        text: (
          <DefaultTag color={APPLICATION_STATUSES_COLORS[applicationStatusId]}>
            {_.upperFirst(APPLICATION_STATUSES_NAMES[applicationStatusId])}
          </DefaultTag>
        ),
        value: applicationStatusId,
      }
    )),
    filterMultiple: true,
    onFilter      : (value: any, record: { applicationStatusId: any; }) => value === record?.applicationStatusId,
  },
  {
    align : `left`,
    key   : `verificationResults`,
    render: (verificationResults: any) => {
      if (!verificationResults || _.isEmpty(verificationResults)) return null;
      return (
        <div>
          {_.map(verificationResults, (verificationResultNames, verificationResultType) => (
            <div key={verificationResultType}>
              {/* eslint-disable-next-line max-len */}
              <VerificationResultType>{verificationResultType}:</VerificationResultType>{_.join(verificationResultNames, `\n `)}
              <br />
            </div>
          ))}
        </div>
      );
    },
    title : `Причины отказа`,
    width : 200,
    sorter: false,
  },
  {
    align : `right`,
    key   : `monthlyIncome`,
    render: prettifyNumber,
    title : `Доход`,
    width : 120,
  },
  {
    align : `right`,
    key   : `loanBurden`,
    render: prettifyNumber,
    title : `Нагрузка`,
    width : 120,
  },
  {
    defaultSortOrder: `descend`,
    key             : `applicationCloseDtm`,
    title           : `Закрыта`,
    render          : (v: any) => <CellCheckbox isChecked={!!v} />,
    width           : 80,
  },
], getColumn);
