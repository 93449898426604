export const SUPER_ADMIN = `superAdmin`;
export const ACTION_CLIENT_EDITOR = `actionClientEditor`;
export const ACTION_EDITOR = `actionEditor`;
export const ACTION_VIEWER = `actionViewer`;
export const APPEAL_OPERATOR = `appealOperator`;
export const CAR_BLACK_LIST_UPLOADER = `carBlacksListUploader`;
export const CAR_EDIT = `carEdit`;
export const CLIENT_EDIT = `clientEdit`;
export const CLIENT_PROFILE_CLOSER = `clientProfileCloser`;
export const COLLECTION = `collection`;
export const COLLECTION_ADMIN = `collectionAdmin`;
export const COLLECTION_BATCH_CLOSER = `collectionBatchCloser`;
export const COLLECTION_READ = `collectionRead`;
export const COLLECTION_TASK_UPLOADER = `collectionTaskUploader`;
export const COLLECTION_WRITE = `collectionWrite`;
export const DEALERSHIP_EDITOR = `dealershipEditor`;
export const DICTIONARY_EDITOR = `dictionaryEditor`;
export const DICTIONARY_VIEWER = `dictionaryViewer`;
export const DOCUMENT_UPLOADER = `documentUploader`;
export const DOCUMENT_TEMPLATE_ADMIN = `documentTemplateAdmin`;
export const EMAIL_TESTER = `emailTester`;
export const HARD_ADMIN = `hardAdmin`;
export const IMHA_ADMIN = `imhaAdmin`;
export const INFO_EDITOR = `infoEditor`;
export const LEAD_ADMIN = `leadAdmin`;
export const LEAD_EDIT = `leadEdit`;
export const LEGAL_ADMIN = `legalAdmin`;
export const LOAN_OPERATOR = `loanOperator`;
export const MOBILE_CONFIG_EDITOR = `mobileConfigEditor`;
export const OFFER_UPLOADER = `offerUploader`;
export const PARTNER_EDITOR = `partnerEditor`;
export const PARTNER_RATE_EDITOR = `partnerRateEditor`;
export const PARTNER_RATE_VIEWER = `partnerRateViewer`;
export const PARTNER_REQUISITE_EDITOR = `partnerRequisiteEditor`;
export const PARTNER_REQUISITE_VIEWER = `partnerRequisiteViewer`;
export const PARTNER_VIEWER = `partnerViewer`;
export const PAYMENT_GATEWAY_OPERATOR = `paymentGatewayOperator`;
export const PHONE_EDIT = `phoneEdit`;
export const SALE_ADMIN = `saleAdmin`;
export const SALE_BATCH_CLOSER = `saleBatchCloser`;
export const SALE_READ = `saleRead`;
export const SALE_SEND = `saleSend`;
export const SALE_TASK_UPLOADER = `saleTaskUploader`;
export const SALE_WRITE = `saleWrite`;
export const SHORT_LINK_OPERATOR = `shortLinkOperator`;
export const SIGN_DOCUMENT_CREATOR = `signDocumentCreator`;
export const SMS_DOWNLOADER = `smsDownloader`;
export const SMS_GATEWAY_OPERATOR = `smsGatewayOperator`;
export const SMS_LIST_SENDER = `smsListSender`;
export const SMS_SENDER = `smsSender`;
export const STAT_BALANCE_RELOADER = `statBalanceReloader`;
export const TASK_CREATOR = `taskCreator`;
export const TEMPLATE_EDIT = `templateEdit`;
export const TRIGGER_ADMIN = `triggerAdmin`;
export const TRIGGER_WRITE = `triggerWrite`;
export const VERIFICATION_ADMIN = `verificationAdmin`;
export const VERIFICATION_READ = `verificationRead`;
export const VERIFICATION_SEND = `verificationSend`;
export const VERIFICATION_WRITE = `verificationWrite`;

export const ROLE_NAMES = {
  ACTION_CLIENT_EDITOR,
  ACTION_EDITOR,
  ACTION_VIEWER,
  APPEAL_OPERATOR,
  CAR_BLACK_LIST_UPLOADER,
  CAR_EDIT,
  CLIENT_EDIT,
  CLIENT_PROFILE_CLOSER,
  COLLECTION,
  COLLECTION_ADMIN,
  COLLECTION_BATCH_CLOSER,
  COLLECTION_READ,
  COLLECTION_TASK_UPLOADER,
  COLLECTION_WRITE,
  DEALERSHIP_EDITOR,
  DICTIONARY_EDITOR,
  DICTIONARY_VIEWER,
  DOCUMENT_TEMPLATE_ADMIN,
  DOCUMENT_UPLOADER,
  EMAIL_TESTER,
  HARD_ADMIN,
  IMHA_ADMIN,
  INFO_EDITOR,
  LEAD_ADMIN,
  LEAD_EDIT,
  LEGAL_ADMIN,
  LOAN_OPERATOR,
  MOBILE_CONFIG_EDITOR,
  OFFER_UPLOADER,
  PARTNER_EDITOR,
  PARTNER_RATE_EDITOR,
  PARTNER_RATE_VIEWER,
  PARTNER_REQUISITE_EDITOR,
  PARTNER_REQUISITE_VIEWER,
  PARTNER_VIEWER,
  PAYMENT_GATEWAY_OPERATOR,
  PHONE_EDIT,
  SALE_ADMIN,
  SALE_BATCH_CLOSER,
  SALE_READ,
  SALE_SEND,
  SALE_TASK_UPLOADER,
  SALE_WRITE,
  SHORT_LINK_OPERATOR,
  SIGN_DOCUMENT_CREATOR,
  SMS_DOWNLOADER,
  SMS_GATEWAY_OPERATOR,
  SMS_LIST_SENDER,
  SMS_SENDER,
  STAT_BALANCE_RELOADER,
  SUPER_ADMIN,
  TASK_CREATOR,
  TEMPLATE_EDIT,
  TRIGGER_ADMIN,
  TRIGGER_WRITE,
  VERIFICATION_ADMIN,
  VERIFICATION_READ,
  VERIFICATION_SEND,
  VERIFICATION_WRITE,
} as const;

export type TRoleName = typeof ROLE_NAMES[keyof typeof ROLE_NAMES] | string;
