import * as React from 'react';
import {
  forwardRef,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { getClientsRecent } from 'models/clients/actions';
import { getClientsRecent as clientsRecentSelector } from 'models/clients/selectors';
import { IRecent } from 'models/clients/types';

import { Empty } from 'antd';
import { RecentItem } from 'pages/PageClient/Plural/components/Recent/RecentItem';

const Wrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${palette.backgroundAccentColor};
  top: 0;
  overflow: scroll;
  padding: 0 16px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  z-index: ${({ isVisible }) => (isVisible ? 1 : -1)};
  pointer-events: ${({ isVisible }) => (isVisible ? `auto` : `none`)};
  transition: all 100ms ease;

  @media(min-width: ${breakpoints.sm}) {
    right: 0;
  }
`;

const StyledEmpty = styled(Empty)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0;
`;

interface IComponentProps {
  isOpen: boolean;
}

export const RecentMobileContent = forwardRef<HTMLDivElement, IComponentProps>(
  ({ isOpen }, ref) => {
    const dispatch = useDispatch();
    const recent:IRecent[] = useSelector(clientsRecentSelector);
    const getRecent = () => dispatch(getClientsRecent());

    useEffect(() => {
      if (isOpen) getRecent();
    }, [isOpen]);

    return (
      <Wrapper isVisible={isOpen} ref={ref}>
        {_.isEmpty(recent)
          ? (
            <StyledEmpty
              description='Клиенты не найдены'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ) : (
            <>
              {_.map(recent, ({ label, personId }) => (
                <RecentItem
                  key={personId}
                  label={label}
                  personId={personId}
                />
              ))}
            </>
          )}
      </Wrapper>
    );
  },
);
