/* eslint-disable max-len */
import React, {
  useContext,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { Link } from 'dva/router';

import { hasIntersection } from 'helper';

import {
  closeApplication as closeApplicationAction,
  getClientApplicationOffers as getClientApplicationOffersAction,
  getClientApplicationOperation as getClientApplicationOperationAction,
  getClientApplications as getClientApplicationsAction,
  getClientLoans as getClientLoansAction,
  reopenApplication as reopenApplicationAction,
  repairApplication as repairApplicationAction,
  sendApplicationToCall as sendApplicationToCallAction,
} from 'models/clients/actions';
import {
  getClientApplicationsWithLoans as getClientApplicationsWithLoansSelector,
  getIsLoading as isLoadingSelector,
} from 'models/clients/selectors';
import {IApplication, IOperation} from 'models/clients/types';
import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { useDynamicFilterValues } from 'hooks/useDynamicFilterValues';

import {
  CloseCircleOutlined,
  CloudDownloadOutlined,
  DownloadOutlined,
  FileSearchOutlined,
  IssuesCloseOutlined,
  SettingOutlined,
  SwapOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import {
  Descriptions,
  Dropdown,
} from 'antd';
import ApplicationOperation from 'components/ApplicationOperation';
import LoanDescription from 'components/LoanDescription';
import LoanOperations from 'components/LoanOperations';
import OfferDescription from 'components/OfferDescription';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';

import { COLUMNS } from './constants';

export const PageClientSingleApplication = ({ isWrapped = true, personId: _personId }: { isWrapped?: boolean; personId?: string;}) => {
  const dispatch = useDispatch();
  const clientMatch = useContext(ClientMatchContext);
  const personId = _personId || clientMatch?.personId;
  const applications = useSelector(state => getClientApplicationsWithLoansSelector(state, personId));
  const userRoles = useSelector(getRoleNamesSelector);
  const isLoading = useSelector(isLoadingSelector);

  const getApplicationOffers = (applicationId: string) => dispatch(getClientApplicationOffersAction({
    personId,
    applicationId,
  }));

  const getApplicationOperation = (applicationId: string) => dispatch(getClientApplicationOperationAction({
    applicationId,
    personId,
  }));

  const getClientLoans = () => dispatch(getClientLoansAction({
    personId,
  }));

  const getClientApplications = () => dispatch(getClientApplicationsAction({
    personId,
    callback: getClientLoans(),
  }));

  const closeApplication = (applicationId: string) => dispatch(closeApplicationAction({
    applicationId,
    callback: getClientApplications,
  }));

  const reopenApplication = (applicationId: string) => dispatch(reopenApplicationAction({
    applicationId,
    callback: getClientApplications,
  }));

  const repairApplication = (application:IApplication) => dispatch(repairApplicationAction({
    application,
    callback: getClientApplications,
  }));

  const sendApplicationToCall = ({
                                   applicationId,
                                   call,
                                   callback,
                                   hideMessage,
                                 }: { applicationId: string, call: number, callback?: () => void, hideMessage?: boolean }) => {
    dispatch(sendApplicationToCallAction({ applicationId, hideMessage, call, callback }));
  };

  const sendApplicationToCall1 = (applicationId: string) => sendApplicationToCall({
    applicationId,
    call    : 2,
    callback: getClientApplications,
  });

  const sendApplicationToCreditHistory = (applicationId: string) => sendApplicationToCall({
    applicationId,
    call    : 4,
    callback: getClientApplications,
  });

  const sendApplicationToVerify = (applicationId: string) => sendApplicationToCall({
    applicationId,
    call    : 5,
    callback: getClientApplications,
  });

  useEffect(() => {
    if (_.isEmpty(applications)) getClientApplications();
  }, []);

  const [productNames] = useDynamicFilterValues(applications, `productName`);

  const getMenu = (record: IApplication) => ({
    items: [
      {
        key  : 0,
        // @ts-ignore
        icon : <FileSearchOutlined />,
        label: (
          <Link to={`/client/person/${personId}/doc?applicationId=${record.id}`}>
            Перейти к документам
          </Link>
        ),
      },
      {
        key    : 1,
        label  : `Загрузить офферы`,
        // @ts-ignore
        icon   : <CloudDownloadOutlined />,
        onClick: () => getApplicationOffers(record.id),
      },
      {
        key    : 2,
        label  : `Загрузить операцию`,
        // @ts-ignore
        icon   : <DownloadOutlined />,
        onClick: () => getApplicationOperation(record.id),
      },
      ...(hasIntersection(userRoles, [ROLE_NAMES.APPEAL_OPERATOR, ROLE_NAMES.SUPER_ADMIN])
          ? [
            { type: `divider` },
            {
              key    : 3,
              label  : `Переоткрыть заявку`,
              // @ts-ignore
              icon   : <SwapOutlined />,
              onClick: () => reopenApplication(record.id),
            },
            {
              key    : 4,
              label  : `Отправить на Call1`,
              // @ts-ignore
              icon   : <IssuesCloseOutlined />,
              onClick: () => sendApplicationToCall1(record.id),
            },
            {
              key    : 5,
              label  : `Перезапрос КИ и Вектора`,
              // @ts-ignore
              icon   : <IssuesCloseOutlined />,
              onClick: () => sendApplicationToCreditHistory(record.id),
            },
            {
              key    : 6,
              label  : `Отправить на верификацию`,
              // @ts-ignore
              icon   : <IssuesCloseOutlined />,
              onClick: () => sendApplicationToVerify(record.id),
            },
            {
              key    : 7,
              label  : `Починить заявку`,
              // @ts-ignore
              icon   : <ToolOutlined />,
              // @ts-ignore
              onClick: () => repairApplication(record),
            },
            { type: `divider` },
            {
              danger : true,
              key    : 8,
              label  : `Закрыть заявку`,
              // @ts-ignore
              icon   : <CloseCircleOutlined />,
              onClick: () => closeApplication(record.id),
            },
          ]
          : []
      ),
    ],
  });

  const filterOperations = (operations: IOperation[]) => _.filter(operations, operation => (
    operation.operationTypeId === 1 && operation.operationStatusId !== 1
  ));

  const content = (
    <AdjustableTable
      columns={COLUMNS(productNames)}
      dataSource={applications}
      expandable={{
        onExpand: (expanded, application: IApplication) => {
          if (expanded && !application?.operation && application?.id) getApplicationOperation(application.id);
        },
        expandedRowRender: ({ applicationStatusId, loan, offers, operation }: IApplication) => (
          <div>
            {!_.isEmpty(operation) && <ApplicationOperation operation={operation} />}
            {_.includes([7, 12, 999], applicationStatusId) && loan && (
              <>
                <Descriptions bordered size='small' title='Операции' />
                {/*@ts-ignore*/}
                <LoanOperations filter={filterOperations} loanId={loan.id} personId={personId} />
              </>
            )}
            {loan && <LoanDescription loan={loan} />}
            {!_.isEmpty(offers) && (
              <>
                <Descriptions
                  bordered
                  layout='vertical'
                  size='small'
                  title='Офферы'
                />
                {_.map(offers, offer => <OfferDescription key={offer.id} offer={offer} />)}
              </>
            )}
          </div>
        ),

        rowExpandable: (application: IApplication) => !_.isEmpty(application.offers) || !_.isEmpty(application.operation) || application.loan,
      }}
      pagination={{
        hideOnSinglePage: true,
        pageSize        : _.size(applications),
        size            : `small`,
      }}
      preferences={{
        path      : `clientInfo.application`,
        cellRender: (text, record) => (
          <Dropdown
            // @ts-ignore
            menu={getMenu(record)}
            trigger={[`click`]}
          >
            {/* @ts-ignore */}
            <SettingOutlined style={{ padding: `8px` }} />
          </Dropdown>
        ),
      }}
      scroll={{ x: `max-content`, y: window.innerHeight - 250 }}
    />

  );

  return isWrapped ? (
    <ClientTab isReloading={isLoading} onReload={getClientApplications}>
      {content}
    </ClientTab>
  ) : content;
};

export default PageClientSingleApplication;
