import * as React from 'react';
import { useSelector } from 'dva';

import { isLoading as isLoadingSelector } from 'models/verifications/selectors';

import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';
import { COLUMNS } from 'pages/PageVerification/Plural/constants';

interface IComponentProps {
  isSale: boolean,
  onPaginationChange: (page: number, pageSize?: number) => void;
  onSelectChange: (selectedRowKeys: any[]) => void;
  page: number;
  pageSize: number;
  partners: any[];
  selectedRowKeys: any[]
  total: number;
  verifications: any[];
}
const preferences = {
  path: `verification`,
};

export const VerificationPluralTable: React.FC<IComponentProps> = ({
  isSale,
  onPaginationChange,
  onSelectChange,
  page,
  pageSize,
  partners,
  selectedRowKeys,
  total,
  verifications,
}) => {
  const isLoading = useSelector(isLoadingSelector);
  const tableScrollY = window.innerHeight - 300 < 200 ? 250 : window.innerHeight - 300;

  return (
    <AdjustableTable
      bordered
      columns={COLUMNS(partners, isSale)}
      dataSource={verifications}
      loading={isLoading}
      pagination={{
        onChange       : onPaginationChange,
        defaultCurrent : page,
        defaultPageSize: pageSize,
        // @ts-ignore
        page,
        pageSize,
        pageSizeOptions: [`10`, `25`, `50`],
        size           : `small`,
        total,
      }}
      preferences={preferences}
      rowKey='id'
      rowSelection={{
        onChange: onSelectChange,
        selectedRowKeys,
      }}
      scroll={{
        scrollToFirstRowOnChange: true,
        x                       : `max-content`,
        y                       : tableScrollY,
      }}
      size='small'
    />
  );
};
