import * as React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { Select } from 'antd';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media(min-width: ${breakpoints.md}) {
    flex-direction: row;
    align-items: center;
  }
`;

interface IComponentProps {
  disabled: boolean;
  onChange: (value: string) => void;
  value: string;
}

export const CreateTaskModalTitle: React.FC<IComponentProps> = ({ disabled, onChange, value }) => (
  <Wrapper>
    Новая задача в блоке
    <Select
      disabled={disabled}
      onChange={onChange}
      options={[
        { label: `Взыскание`, value: `collection` },
        { label: `Продажи`, value: `sale` },
        { label: `Сервис`, value: `service` },
      ]}
      style={{ minWidth: `120px` }}
      value={value}
    />
  </Wrapper>
);
