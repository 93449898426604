import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  Button,
  Form,
  Modal,
  notification,
  Select,
  Space,
  Spin,
} from 'antd';
import { FORM_FIELDS } from '../../constants';
import { useLazyEffect, validateVinLength, validateVinSybmols } from 'helper';
import { palette } from "styles/theme";

const { Option } = Select;

const Spinner = styled(Spin)`
  align-items: center;
  background-color: rgba(${palette.whiteColor}, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

const FormWrapper = styled(Space)`
  align-items: flex-start;
`;

const ButtonsWrapper = styled.div`
  margin-top: 30px;
`;

const NotificationTitle = styled.div`
  white-space: pre-wrap;
  margin-bottom: 8px;
`;

const NotificationControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const NotificationControlItem = styled(Button)`
  &:not(:first-child) {
    margin-left: 16px;
  }
`;

const EditCarModal = ({
                        car,
                        carNumberOnVerification,
                        close,
                        getCarInfo,
                        isLoading,
                        setVinEditedCar,
                        update,
                        updateGibddAndFnpService,
                        updateRsaService,
                        vinEditedCars,
                        visible,
                      }) => {
  const [form] = Form.useForm();
  setTimeout(form.validateFields, 200);

  useLazyEffect(() => form.setFieldsValue(car), [car]);

  const canUpdateServices = carNumberOnVerification === car?.carNumber;
  const isVinEdited = _.includes(vinEditedCars, car?.carNumber);

  const onChange = changedFields => {
    const vinField = _.find(changedFields, ({ name }) => _.includes(name, `vin`));
    if (!isVinEdited && vinField && vinField.value !== car?.vin
      && validateVinSybmols(vinField.value) && validateVinLength(vinField.value)) {
      setVinEditedCar(car?.carNumber);
    }
  };

  const onUpdateRsaService = vin => updateRsaService({ carNumber: car?.carNumber, vin: vin || car?.vin });

  if (_.isEmpty(car)) return null;

  return (
    <Modal
      cancelText='Отмена'
      centered
      destroyOnClose
      maskClosable={false}
      okText='Сохранить'
      onCancel={() => {
        form.resetFields();
        close();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            update(pickValues(values, car));
            form.resetFields();
            close();
          })
          .catch(({ values }) => {
            notification.warning({
              key    : `carEditWarning`,
              message: (
                <div>
                  <NotificationTitle>
                    {`Не все поля прошли валидацию.\nВы точно хотите сохранить невалидные данные?`}
                  </NotificationTitle>
                  <NotificationControls>
                    <NotificationControlItem
                      danger
                      onClick={() => {
                        update(pickValues(values, car));
                        notification.close(`carEditWarning`);
                        form.resetFields();
                        close();
                      }}
                      type='primary'
                    >
                      Да
                    </NotificationControlItem>
                  </NotificationControls>
                </div>
              ),
              duration: 0,
            });
          });
      }}
      open={visible}
      scrollToFirstError
      title={`Редактирование автомобиля '${_.join(_.compact([car.make, car.model, car.carNumber]), ` `)}'`}
    >
      {isLoading && <Spinner />}
      <Space direction='vertical'>
        <Button
          onClick={() => {
            getCarInfo(form.getFieldValue(`vin`), data => form.setFieldsValue(data));
          }}
          type='primary'
        >
          Заполнить данные
        </Button>
        <FormWrapper direction='horizontal'>
          <Form
            form={form}
            initialValues={car}
            layout='vertical'
            onFieldsChange={onChange}
          >
            {_.map(FORM_FIELDS, ({
               Component,
               dependencies,
               labelAsChild,
               options,
               required = false,
               rules,
               title,
               valuePropName = `value`,
             }, key) => (Component ? (
              <Form.Item
                dependencies={dependencies}
                key={key}
                label={labelAsChild ? `` : title}
                name={key}
                required={required}
                rules={rules}
                valuePropName={valuePropName}
              >
                {(labelAsChild || options)
                  ? (
                    <Component>
                      {labelAsChild ? title : ``}
                      {options && _.map(options, option => (
                        <Option key={option} value={option}>{option}</Option>
                      ))}
                    </Component>
                  )
                  : <Component />}

              </Form.Item>
            ) : null))}
          </Form>
          <ButtonsWrapper>
            <Space direction='vertical'>
              <Button
                disabled={!canUpdateServices || !isVinEdited}
                onClick={() => updateGibddAndFnpService(form.getFieldValue(`vin`))}
                type='primary'
              >
                Обновить ГИБДД и ФНП
              </Button>
              <Button
                disabled={!canUpdateServices}
                onClick={() => onUpdateRsaService(form.getFieldValue(`vin`))}
                type='primary'
              >
                Обновить РСА
              </Button>
            </Space>
          </ButtonsWrapper>
        </FormWrapper>
      </Space>
    </Modal>
  );
};

const pickValues = (values, car) => {
  if (values?.cost) values.pledgeCost = values.cost * 0.8; // eslint-disable-line
  return {
    ..._.pick(values, _.keys(FORM_FIELDS)),
    id: car?.id,
  };
};

export default EditCarModal;
