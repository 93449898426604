import * as React from 'react';
import { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { getColumn } from 'helper';

import { useStatePreference } from 'hooks/useStatePreference';

import {
  DownOutlined,
  SettingOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  notification,
  Table, TableProps,
  Tooltip,
} from 'antd';
import { DefaultTable } from 'components/DefaultTable/DefaultTable';
import {
  SummaryHeadline,
  SummaryTitle,
  SummaryValue,
} from 'pages/PageClient/Single/components/AdjustableTable/Summary/Summary';
import { TableColumnSettings } from 'pages/PageClient/Single/components/TableColumnSettings/TableColumnSettings';

import { OnTableChange } from 'types/OnTableChange';

export interface ISummaryItem {
  align?: `right` | `left` | `center`;
  key: string;
  title: string;
  render?(record: any): any;
}

const SettingsIcon = styled(SettingOutlined)`
  padding: 8px;
  visibility: hidden;
`;

type TComponentProps = Omit<TableProps<any>, `size` | `summary`> & {
  className?: string;
  columns: { key: string; title: string; }[];
  defaultColumns?: any[];
  filters?: { [key: string]: any };
  loading?: boolean;
  onChange?: OnTableChange;
  preferences: {
    cellRender?: (text: any, record: any) => void;
    path: string;
  }
  preferencesWidth?: number;
  scrollableHeader?:boolean;
  summary?: {
    columns: ISummaryItem[],
    data?: any[];
  };
  onTableColumnsChange?(newColumns: any): void;
};

export const AdjustableTable: React.FC<TComponentProps> = ({
  className,
  columns,
  dataSource,
  defaultColumns,
  expandable,
  filters,
  loading,
  onChange,
  onRow,
  onTableColumnsChange,
  pagination,
  preferences,
  preferencesWidth = 44,
  rowClassName,
  rowKey,
  rowSelection,
  scroll,
  scrollableHeader,
  summary = {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const columnKeysWithFilters = _.keys(_.omitBy(filters, _.isNil));
  const [tableSize, setTableSize] = useStatePreference(
    `ui.${preferences.path}.table.size`,
    `middle`,
  );

  const defaultColumnsKeys = defaultColumns || _.map(columns, `key`);

  const [filteredColumnKeys, setFilteredColumnKeys] = useStatePreference(
    `ui.${preferences.path}.table.filteredColumnKeys`,
    defaultColumnsKeys,
  );

  const visibleColumns = _.isEmpty(filteredColumnKeys)
    ? columns
    : _.filter(columns, item => _.includes(
      _.concat(filteredColumnKeys, columnKeysWithFilters),
      item.key,
    ));

  const visibleSummary: ISummaryItem[] = [...(_.isEmpty(filteredColumnKeys)
    ? (summary.columns || [])
    : _.filter((summary.columns || []), item => _.includes(filteredColumnKeys, item.key))),
  {
    key   : `settings`,
    title : ``,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    render: _.noop,
  }];

  if (expandable) {
    visibleSummary.unshift({
      key   : `expandable`,
      title : ``,
      render: _.noop,
    });
  }

  const summaryTitleIndex = expandable ? 1 : 0;

  const handleMenuClick = (info: { key: any; }) => {
    let newColumns = _.xor(filteredColumnKeys, [info.key]);

    if (_.isEmpty(newColumns)) {
      newColumns = filteredColumnKeys;
      notification.info({
        message : `Необходимо оставить видимым хотя бы один столбец`,
        duration: 2,
      });
    }
    setFilteredColumnKeys(newColumns);

    if (onTableColumnsChange) {
      onTableColumnsChange(newColumns);
    }
  };

  const onResetColumnKeys = () => {
    setFilteredColumnKeys(defaultColumnsKeys);

    if (onTableColumnsChange) {
      onTableColumnsChange(defaultColumnsKeys);
    }
  };

  return (
    <DefaultTable
      className={className}
      // @ts-ignore
      columns={[
        ...(visibleColumns || []),
        getColumn({
          key  : `settings`,
          align: `right`,
          fixed: `right`,
          title: (
            <TableColumnSettings
              columnKeysWithFilters={columnKeysWithFilters}
              columns={columns}
              onReset={onResetColumnKeys}
              onSelect={handleMenuClick}
              onSizeChange={setTableSize}
              tableSize={tableSize}
              visibleKeys={filteredColumnKeys}
            />
          ),
          render: (text: any, record: any) => (preferences.cellRender
            ? preferences?.cellRender(text, record)
            : (<SettingsIcon />)),
          width: preferencesWidth,
        }),
      ]}
      // @ts-ignore
      dataSource={dataSource}
      expandable={expandable}
      loading={loading}
      onChange={onChange}
      onRow={onRow}
      pagination={pagination}
      // @ts-ignore
      rowClassName={rowClassName}
      // @ts-ignore
      rowKey={rowKey}
      rowSelection={rowSelection}
      scroll={scroll}
      scrollableHeader={scrollableHeader}
      size={tableSize}
      // @ts-ignore
      summary={(data: string | any[]) => (!_.isEmpty(summary.columns) && (summary.data?.length > 0 || data.length > 0)
        ? (
          <Table.Summary
            fixed
          >
            <Table.Summary.Row>
              {/* eslint-disable-next-line no-empty-pattern */}
              {_.map(visibleSummary, ({}, index) => (
                <Table.Summary.Cell index={index} key={index}>
                  {index === summaryTitleIndex && (
                    <SummaryHeadline onClick={() => setIsExpanded(v => !v)}>
                      {isExpanded ? <UpOutlined /> : <DownOutlined />}
                      Итоги
                    </SummaryHeadline>
                  )}
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>

            {isExpanded && (
              <Table.Summary.Row>
                {_.map(visibleSummary, ({ title, align = `left` }, index) => (
                  <Table.Summary.Cell index={index} key={index}>
                    <SummaryTitle align={align}>{title}</SummaryTitle>
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            )}

            <Table.Summary.Row>
              {_.map(visibleSummary, ({ render, title, align = `left` }, index) => (
                <Table.Summary.Cell index={index} key={index}>
                  <Tooltip title={title}>
                    <SummaryValue align={align}>
                      {render && !_.isNaN(render(summary?.data || data)) && render(summary?.data || data)}
                    </SummaryValue>
                  </Tooltip>
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>
          </Table.Summary>
        )
        : null)}
    />
  );
};
