import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { EntityCard } from 'pages/PageClient/Single/components/EntityCard/EntityCard';

export const EntityCardsGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px 0;

  margin-right: -8px;
  margin-left: -8px;

  ${EntityCard} {
    overflow-x: hidden;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 8px;

    :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media(min-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    gap: 16px 8px;
  }

  @media(min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
