import React from 'react';

import { Checkbox } from 'antd';

const BOOL_COLUMN = (key, title, restProps = {}) => ({
  align : `center`,
  key,
  render: v => <Checkbox checked={v} />,
  title,
  width : 90,
  ...restProps,
});

export default BOOL_COLUMN;
