import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { MoreOutlined } from '@ant-design/icons';
import { DefaultDropdown } from 'components/DefaultDropdown/DefaultDropdown';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { subHeaderButtonDefaultMixin } from 'components/SubHeader/SubHeaderButtonDefault';
import { IControl } from 'pages/PageAdmin/components/AdminSearchWithControls';

const Wrapper = styled.div``;

interface IComponentProps {
  controls: IControl[];
}

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.button<{ buttonType?: `primary` | `danger` | `default` }>`
  ${subHeaderButtonDefaultMixin};

  font-size: 14px;
  padding: 8px 16px;

  .anticon:first-child {
    margin-right: 0;
  }

  ${p => p.buttonType === `danger` && `
      background: ${palette.alert500Color(p)};
    `}

  ${p => p.buttonType === `primary` && `
      background: ${palette.primary500Color(p)};
   `}
`;

export const VerificationPluralControlsButtons: React.FC<IComponentProps> = ({ controls }) => {
  const menuItems = _.map(controls, (control, index) => {
    const {
      icon,
      isDisabled,
      isHidden,
      key,
      onClick,
      title,
      tooltipTitle,
      type,
    } = control;

    return {
      disabled: isDisabled,
      key     : key || index,
      hidden  : isHidden,
      primary : type === `primary`,
      onClick,
      icon,
      tooltip : tooltipTitle,
      label   : title,
    };
  });

  const buttonControls = _.filter(controls, control => _.includes([`search`, `refresh`, `assign-to-me`], control.key));

  return (
    <Wrapper>
      <ButtonsWrapper>
        {_.map(_.reject(buttonControls, `isHidden`), control => {
          const {
            icon,
            isDisabled,
            key,
            onClick,
            title,
            tooltipTitle,
            type,
          } = control;

          return (
            <DefaultTooltip key={key} title={tooltipTitle || title}>
              <Button
                buttonType={type}
                disabled={isDisabled}
                key={key}
                onClick={onClick}
              >
                {icon}
              </Button>
            </DefaultTooltip>
          );
        })}

        <DefaultDropdown
          menu={{ items: menuItems }}
          trigger={[`click`]}
        >
          <Button>
            <MoreOutlined />
          </Button>
        </DefaultDropdown>
      </ButtonsWrapper>
    </Wrapper>
  );
};
