import _ from 'lodash';

import { formatDate } from 'helper';

import {
  IApplication,
  IDoc,
} from 'models/clients/types';
import {
  IDocumentGroup,
  IDocumentType,
} from 'models/dictionaries/types';

interface IGetDocsList {
  applicationId: string;
  applications: IApplication[];
  documentGroups: IDocumentGroup[];
  uploadableDocumentTypes: IDocumentType[]
}

export const getDocsList = ({
  applicationId,
  applications,
  documentGroups,
  uploadableDocumentTypes,
}:IGetDocsList):IDoc[] => {
  const docsByApplicationId = _.get(_.find(applications, { id: applicationId }), `docs`);
  const unsortedDocs = _.reduce(
    docsByApplicationId,
    (result, doc) => {
      // @ts-ignore
      result.push({
        ...doc,
        label   : `${doc.name}${doc.createDtm ? ` (${formatDate(doc.createDtm)})` : ``}`,
        readOnly: !!_.find(uploadableDocumentTypes, { id: doc.type }),
      });
      return result;
    },
    [],
  );
  return unsortedDocs
    .map(doc => {
      // @ts-ignore
      const group = documentGroups.find(g => g.typeIds.includes(doc.type));
      return {
        // @ts-ignore
        ...doc,
        groupOrder: group ? group.order : 999,
        // @ts-ignore
        typeOrder : group ? group.typeIds.indexOf(doc.type) : 999,
      };
    })
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    .sort((a, b) => ((a.groupOrder * 1000) + a.typeOrder) - ((b.groupOrder * 1000) + b.typeOrder))
    .map(({
      groupOrder,
      typeOrder,
      ...doc
    }) => doc);
};

