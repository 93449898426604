import { createAction } from 'redux-actions';

export const model = `mtt`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const MAKE_CALL = `MAKE_CALL`;
export const SET_LOADING = `SET_LOADING`;

export const makeCall = ca(MAKE_CALL);
export const setLoading = ca(SET_LOADING);
