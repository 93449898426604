import React from 'react';
import _ from 'lodash';

import { TextVerificationResultValue } from 'models/verifications/types';

import { Input } from 'antd';
import { ICheckListFieldProps } from 'pages/PageClient/Single/VerificationResult/CheckList/Fields/types';
import {
  Label,
  Row,
} from 'pages/PageClient/Single/VerificationResult/CheckList/styled';

interface TextFieldProps extends ICheckListFieldProps {
  value: TextVerificationResultValue
}

export const TextField: React.FC<TextFieldProps> = ({ isDisabled, onChange, value, verificationResults }) => (
  <>
    {_.map(verificationResults, verificationResult => (
      <Row key={verificationResult.id}>
        <Label>{verificationResult.description || verificationResult.name}</Label>
        <Input
          disabled={isDisabled}
          onChange={e => onChange({
            ...(value || {}),
            [verificationResult.id]: e.target.value,
          })}
          value={value?.[verificationResult.id]}
        />
      </Row>
    ))}
  </>
);
