import api from 'api/index';
import { DADATA_URL } from 'constants/DADATA';
import KEY_CODES from 'constants/KEY_CODES';

export const preventEnter = event => {
  if (event.keyCode === KEY_CODES.ENTER) event.preventDefault();
};

export const getDadataSuggestions = ({ count = 10, query, type }) => {
  let url = DADATA_URL;

  if (type === `address`) url += `/address`;

  const searchData = { count, query };

  return api.post(url, searchData);
};
