import React, {
  useCallback,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { hasIntersection } from 'helper';

import { getClientLoanDocument as getClientLoanDocumentAction } from 'models/clients/actions';
import { LOAN_STATUSES } from 'models/clients/constants';
import { ILoan } from 'models/clients/types';
import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { usePath } from 'hooks/usePath';

import {
  CloseCircleOutlined,
  DownloadOutlined,
  FileZipOutlined,
  LinkOutlined,
  SettingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import BankruptModal from 'components/Modal/BankruptModal';

const Wrapper = styled.div`
  display: flex;
`;

interface IComponentProps {
  children?: React.ReactNode;
  loan: ILoan;
  personId: string;
  toggleAgreementModal(loanId: string): void;
  toggleLoanCloseModal(loanId: string): void;
  toggleRepaymentLinkModal(loanId: string): void;
}

export const LoansTableCellControl = React.memo<IComponentProps>(({
  children,
  loan,
  personId,
  toggleAgreementModal,
  toggleLoanCloseModal,
  toggleRepaymentLinkModal,
}) => {
  const dispatch = useDispatch();

  const { id: loanId } = loan;

  const { pushPath } = usePath();

  const userRoles = useSelector(getRoleNamesSelector);

  const canGetAgreement = hasIntersection(userRoles, [
    ROLE_NAMES.SUPER_ADMIN,
    ROLE_NAMES.COLLECTION_READ,
    ROLE_NAMES.COLLECTION_WRITE,
    ROLE_NAMES.COLLECTION_ADMIN,
    ROLE_NAMES.VERIFICATION_READ,
  ]);
  const canCloseLoan = hasIntersection(userRoles, [
    ROLE_NAMES.SUPER_ADMIN,
    ROLE_NAMES.LOAN_OPERATOR,
  ]);
  const isBankruptsAllowed = hasIntersection(userRoles, [
    ROLE_NAMES.SUPER_ADMIN,
    ROLE_NAMES.COLLECTION_READ,
    ROLE_NAMES.COLLECTION_WRITE,
    ROLE_NAMES.COLLECTION_ADMIN,
    ROLE_NAMES.SALE_READ,
    ROLE_NAMES.SALE_WRITE,
    ROLE_NAMES.SALE_ADMIN,
    ROLE_NAMES.LEGAL_ADMIN,
  ]);
  const isLoanActive = loan?.loanStatusId !== LOAN_STATUSES.CLOSED;

  const goToDocs = useCallback((applicationId: string) => pushPath(
    `/client/person/${personId}/doc?applicationId=${applicationId}`,
  ), [pushPath, personId]);
  const getDocument = useCallback((type: string) => dispatch(
    getClientLoanDocumentAction({ personId, loanId, type }),
  ), [dispatch, personId, loanId]);

  const [isBankruptModalOpen, setIsBankruptModalOpen] = useState(false);

  const menu = {
    items: [
      {
        key    : `0`,
        icon   : <FileZipOutlined />,
        label  : `Перейти к документам`,
        onClick: () => goToDocs(loan?.applicationId),
      },
      {
        key    : `1`,
        icon   : <DownloadOutlined />,
        label  : `Справка`,
        onClick: () => getDocument(`loan-certificate`),
      },
      {
        key    : `2`,
        icon   : <DownloadOutlined />,
        label  : `Уведомление об ИМХА`,
        onClick: () => getDocument(`automobile-notification`),
      },
    ] };

  if (canGetAgreement) {
    menu.items.push({
      key    : `3`,
      icon   : <DownloadOutlined />,
      label  : `Сформировать согласие на реализацию`,
      // @ts-ignore
      onClick: () => toggleAgreementModal(loanId),
    });
  }

  if (isLoanActive) {
    menu.items.push({
      key    : `5`,
      icon   : <LinkOutlined />,
      label  : `Ссылка на оплату`,
      // @ts-ignore
      onClick: () => toggleRepaymentLinkModal(loanId),
    });
  }

  if (canCloseLoan || isBankruptsAllowed) {
    // @ts-ignore
    menu.items.push({ type: `divider` });
  }
  if (canCloseLoan) {
    menu.items.push({
      key    : `6`,
      icon   : <CloseCircleOutlined />,
      label  : `Закрыть договор`,
      // @ts-ignore
      danger : true,
      // @ts-ignore
      onClick: () => toggleLoanCloseModal(loanId),
    });
  }

  if (isBankruptsAllowed) {
    menu.items.push({
      key    : `7`,
      icon   : <WarningOutlined />,
      label  : `Создать банкротство`,
      // @ts-ignore
      danger : true,
      // @ts-ignore
      onClick: () => setIsBankruptModalOpen(true),
    });
  }

  return (
    <Wrapper>
      <DefaultTooltip placement='topRight' title='Действия'>
        <Dropdown
          menu={menu}
          placement='bottomRight'
          trigger={[`click`]}
        >
          {children ? (
            <div>{children}</div>
          ) : (
            <SettingOutlined style={{ padding: `8px` }} />
          )}
        </Dropdown>
      </DefaultTooltip>
      <BankruptModal
        isVisible={isBankruptModalOpen}
        // @ts-ignore
        item={{ loanId, personId }}
        mode='create'
        onClose={() => setIsBankruptModalOpen(false)}
      />
    </Wrapper>
  );
});
