import * as React from 'react';
import { useContext } from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { prettifyNumber } from 'helper';

import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';

import MobileContext from 'pages/Layout/MobileContext';
import { ApproveForm } from 'pages/PageClient/Single/Decision/components/ApproveTab/ApproveForm';
import { ILimitProps } from 'pages/PageClient/Single/Decision/components/ApproveTab/types';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  gap: 24px;
`;

const Condition = styled.div`
  background: ${getLightStyleOr(palette.black30Color, palette.black10Color)};
  border-radius: 8px ;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
`;

const ConditionTitle = styled.div`
  color: ${palette.black100Color};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 16px;
`;

const ConditionItem = styled.div`
  background: ${palette.black5Color};
  color: ${palette.black100Color};
  text-align: center;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  :not(:last-child) {
    margin-bottom: 8px;
  }

  width: 100%;
  border-radius: 8px;
  padding: 40px
`;

const Red = styled.span`
  color: ${palette.redColor};
`;

interface IComponentProps {
  applicationId: string;
  carDataWarningShow: boolean;
  dealershipId: number;
  decisionHasBeenMade: boolean;
  isAbleToSend: boolean;
  isLoading: boolean;
  isResultsComplete: boolean;
  productId: number;
  verification: any;
  verificationId: number;
}

export const ApproveTab: React.FC<IComponentProps> = ({
  applicationId,
  carDataWarningShow,
  dealershipId,
  decisionHasBeenMade,
  isAbleToSend,
  isLoading,
  isResultsComplete,
  productId,
  verification,
  verificationId,
}) => {
  const verificationLimitDictionary = useSelector(state => getDictionarySelector(state, `verificationLimit`));

  const verificationLimit = _.filter(verificationLimitDictionary, { productId });

  // @ts-ignore
  const limitProps:ILimitProps = _.reduce(verificationLimit, (res, limit) => {
    _.each([
      { key: `amount`, unit: `₽` },
      { key: `rate`, unit: `%` },
      { key: `term`, unit: `мес.` },
    ], ({ key, unit }) => {
      if (limit.name === key) {
        // @ts-ignore
        res[`${key}MinRaw`] = limit.min;
        // @ts-ignore
        res[`${key}MaxRaw`] = limit.max;
        // @ts-ignore
        res[`${key}Min`] = `от ${prettifyNumber(limit.min)} ${unit}`;
        // @ts-ignore
        res[`${key}Max`] = `до ${prettifyNumber(limit.max)} ${unit}`;
      }
    });
    return res;
  }, {});

  const {
    amountMax,
    amountMin,
    rateMax,
    rateMin,
    termMax,
    termMin,
  } = limitProps;

  const mobile = useContext(MobileContext);

  return mobile ? (
    <ApproveForm
      applicationId={applicationId}
      carDataWarningShow={carDataWarningShow}
      dealershipId={dealershipId}
      decisionHasBeenMade={decisionHasBeenMade}
      isAbleToSend={isAbleToSend}
      isLoading={isLoading}
      isResultsComplete={isResultsComplete}
      limitProps={limitProps}
      productId={productId}
      verification={verification}
      verificationId={verificationId}
    />
  ) : (
    <Wrapper>
      <Condition>
        <ConditionTitle>Минимальные значения</ConditionTitle>
        <ConditionItem>{amountMin}</ConditionItem>
        <ConditionItem>{rateMin}</ConditionItem>
        <ConditionItem>{termMin}</ConditionItem>
      </Condition>
      <ApproveForm
        applicationId={applicationId}
        carDataWarningShow={carDataWarningShow}
        dealershipId={dealershipId}
        decisionHasBeenMade={decisionHasBeenMade}
        isAbleToSend={isAbleToSend}
        isLoading={isLoading}
        isResultsComplete={isResultsComplete}
        limitProps={limitProps}
        productId={productId}
        verification={verification}
        verificationId={verificationId}
      />
      <Condition>
        <ConditionTitle>Максимальные значения</ConditionTitle>
        <ConditionItem>{amountMax}</ConditionItem>
        <ConditionItem>{rateMax}</ConditionItem>
        <ConditionItem>{termMax}</ConditionItem>
        <ConditionItem>
          <div>ПДН:</div>
          <div>До 0,5 для 90% займов</div>
          <div><Red>До 0,8</Red> для 10% займов</div>
        </ConditionItem>
      </Condition>
    </Wrapper>
  );
};
