import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { getCount as getCountAction } from 'models/notifications/actions';
import {
  getCount as getNotificationsCountSelector,
  getIsCountLoading as getIsNotificationsCountLoadingSelector,
} from 'models/notifications/selectors';

import { useClickOutsideHandler } from 'hooks/useClickOutside';
import useMobileContext from 'hooks/useMobileContext';

import {
  BellOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
} from 'antd';
import { NotificationPanel } from 'pages/Layout/NotificationPanel/NotificationPanel';

const Wrapper = styled.div`
  position: relative;
`;

const StyledBadge = styled(Badge)`
  margin-right: 8px;

  @media(min-width: ${breakpoints.md}) {
    margin-right: 12px;
  }

  .ant-badge-count {
    background-color: ${palette.alert600Color};
    box-shadow: 0 0 0 1px ${palette.backgroundColor};
    transform: translate(0, 0);
  }
`;

const StyledBellOutlined = styled(BellOutlined)`
  color: ${getLightStyleOr(palette.black60Color, palette.black40Color)};
`;

const StyledClockOutlined = styled(ClockCircleOutlined)`
  color: ${palette.textPrimaryColor};
`;

interface IComponentProps {

}

export const HeaderNotificationBadge: React.FC<IComponentProps> = () => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  const isNotificationsCountLoading = useSelector(getIsNotificationsCountLoadingSelector);
  const notificationsCount = useSelector(getNotificationsCountSelector);

  const count = isNotificationsCountLoading ? <StyledClockOutlined /> : notificationsCount;

  const getCount = () => {
    dispatch(getCountAction());
  };

  const toggleVisible = () => setVisible(!visible);

  const { mobile } = useMobileContext();

  const wrapperRef = React.useRef(null);
  const exceptionRef = React.useRef(null);

  useClickOutsideHandler(wrapperRef,
    () => {
      if (visible) toggleVisible();
    }, exceptionRef);

  useEffect(() => {
    getCount();
  }, []);

  return (
    <Wrapper>
      <StyledBadge
        count={count}
        overflowCount={9}
        size='small'
      >
        <Button
          icon={<StyledBellOutlined />}
          onClick={() => toggleVisible()}
          ref={exceptionRef}
          shape={mobile ? `default` : `circle`}
          size={mobile ? `middle` : `large`}
          title='Уведомления'
          type='text'
        />
      </StyledBadge>

      <NotificationPanel
        ref={wrapperRef}
        toggleVisible={toggleVisible}
        visible={visible}
      />
    </Wrapper>
  );
};

