import _ from 'lodash';

import { formatDate } from 'helper';

import { getFullName } from 'models/clients/helpers';

import {
  IGenerateLegalDocument,
  IGenerateLegalDocumentParams,
  IStatementDocument,
} from './types';

export const CAMPAIGN_NAMES = {
  FOLLOW_UP: `follow_up`,
  HARD     : `hard`,
  IMHA     : `imha`,
  LEGAL    : `legal`,
};

export const CAMPAIGNS_WITH_DOCS = [CAMPAIGN_NAMES.HARD, CAMPAIGN_NAMES.IMHA, CAMPAIGN_NAMES.LEGAL];
export const CAMPAIGNS_WITH_SEARCH_PARTNER = [CAMPAIGN_NAMES.IMHA];
export const CAMPAIGNS_WITH_MULTIPLE_PARTNER_EDIT = [CAMPAIGN_NAMES.IMHA];
export const CAMPAIGNS_WITH_EXPORT_TO_EXCEL = [CAMPAIGN_NAMES.IMHA];
export const CAMPAIGNS_WITH_PRE_COURT_NOTIFY = [CAMPAIGN_NAMES.IMHA, CAMPAIGN_NAMES.LEGAL];
export const CAMPAIGNS_WITHOUT_OFFERS_TAB = [CAMPAIGN_NAMES.HARD, CAMPAIGN_NAMES.IMHA, CAMPAIGN_NAMES.LEGAL];
export const USE_NEXT_STATUS_FIELD_CAMPAIGNS = [CAMPAIGN_NAMES.IMHA];
export const SHOW_SEARCH_PARTNER_CAMPAIGNS = [CAMPAIGN_NAMES.IMHA];

export const ACCEPTED_FILES = [`application/vnd.ms-excel`, `.xlsx`];

export const MAX_PRE_COURT_EXPORT_TASKS = 25;

export const STATUS_NAME_NEW = `new`;
export const STATUS_NAME_IN_PROGRESS = `vRabote`;

export const TASK_BLOCK_TITLE = {
  contactCenter: `КЦ`,
  collection   : `Взыскание`,
  sale         : `Продажи`,
  service      : `Сервис`,
};

export const CAR_FIELDS: string[] = [
  `applicationId`,
  `carNumber`,
  `id`,
  `make`,
  `model`,
  `pledgeCost`,
  `ptsNumber`,
  `ptsSerial`,
  `vin`,
  `year`,
];

export const CLAIM_STATEMENTS_DOC_IDS: IStatementDocument[] = [
  {
    id        : 81,
    name      : `Исковое/Заявление о вынесении СП`,
    templateId: 82,
  },
  {
    id        : 82,
    name      : `Заявление об обеспечении иска`,
    templateId: 83,
  },
  {
    id        : 83,
    name      : `Исковое в случае отмены судебного приказа`,
    templateId: 84,
  },
  {
    id        : 84,
    name      : `Заявление о привлечении соответчика`,
    templateId: 85,
  },
  {
    id        : 85,
    name      : `Иск к новому собственнику ТС`,
    templateId: 86,
  },
];
export const CLAIM_STATEMENTS_DOC_IDS_TO_COMPOSE: number[] = [81, 83, 84, 85];

export const BANKRUPT_STATEMENTS_DOC_IDS: IStatementDocument[] = [
  {
    id        : 226,
    name      : `Акт приема принятия ТС на баланс по банкроту`,
    skipModal : true,
    templateId: 157,
  },
  {
    id        : 227,
    name      : `Ответ на предложение принятия ТС на баланс по банкроту`,
    skipModal : true,
    templateId: 158,
  },
  {
    id        : 228,
    name      : `Соглашение принятия ТС на баланс по банкроту`,
    skipModal : true,
    templateId: 159,
  },
  {
    id        : 229,
    name      : `Запрос о начала торгов по банкроту`,
    skipModal : true,
    templateId: 160,
  },
  {
    id        : 230,
    name      : `Запрос о предоставлении фото ТС по банкроту`,
    skipModal : true,
    templateId: 161,
  },
  {
    id        : 231,
    name      : `Обращение о принятии ТС на хранение по банкроту`,
    skipModal : true,
    templateId: 162,
  },
  {
    id        : 232,
    name      : `Отказ в принятии ТС на баланс по банкроту`,
    skipModal : true,
    templateId: 163,
  },
  {
    id        : 233,
    name      : `Уведомление о перечислении ДС по банкроту`,
    skipModal : true,
    templateId: 164,
  },
  {
    id        : 234,
    name      : `Заявление о банкротстве`,
    skipModal : true,
    templateId: 165,
  },
  {
    id  : 103,
    name: `Заявление о включении в РТК по банкроту`,
  },
  {
    id        : 104,
    name      : `Положение о продаже ТС по банкроту`,
    skipModal : true,
    templateId: 103,
  },
  {
    id        : 105,
    name      : `Сопроводительное письмо к Положению о продаже ТС по банкроту`,
    skipModal : true,
    templateId: 104,
  },
];

export const DEAD_STATEMENTS_DOC_IDS: IStatementDocument[] = [
  {
    id        : 106,
    name      : `Исковое к наследникам`,
    templateId: 105,
  },
  {
    id        : 108,
    name      : `Исковое к Росимуществу`,
    templateId: 107,
  },
  {
    id        : 110,
    name      : `Претензия к нотариусу`,
    templateId: 109,
  },
];

export const CLAIM_STATEMENTS_DOC_IDS_WITH_THIRDPARTY: number[] = [
  84,
  85,
  101,
  ..._.map(BANKRUPT_STATEMENTS_DOC_IDS, `id`),
  ..._.map(DEAD_STATEMENTS_DOC_IDS, `id`),
];

export const CLAIM_STATEMENTS_DOC_IDS_WITH_IMHA_ONLY = [103];

const LEGAL_DOCUMENT_PARAMS: IGenerateLegalDocumentParams = {
  addressReg            : v => v.client?.addressReg,
  birthDtm              : v => formatDate(v?.client?.birthDate, `dd.MM.yyyy`),
  birthPlace            : v => v?.client?.birthPlace,
  caseNumber            : v => _.trim(v?.values?.caseNumber),
  color                 : v => v?.car?.color,
  contractNumber        : v => v?.loan?.contractNumber,
  creditStartDtm        : v => formatDate(v?.loan?.creditStartDtm, `dd.MM.yyyy`),
  currentDate           : () => formatDate(new Date(), `dd.MM.yyyy`),
  email                 : v => v?.client?.email,
  enforcementProceedings: v => _.trim(v?.values?.enforcementProceedings),
  executionWirt         : v => _.trim(v?.values?.executionWirt),
  fullName              : v => getFullName(v?.client),
  inn                   : v => v?.client?.inn,
  interestRateStr       : v => v?.loan?.details?.interestRateStr,
  make                  : v => v?.car?.make,
  managerEmail          : v => _.trim(v?.values?.managerEmail),
  managerPhone          : v => _.trim(v?.values?.managerPhone),
  managerFullName       : v => _.trim(v?.values?.managerFullName),
  managerInt            : v => _.trim(v?.values?.managerInt),
  model                 : v => v?.car?.model,
  passportIssueDtm      : v => formatDate(v?.client?.passportIssuedDate, `dd.MM.yyyy`),
  passportIssuer        : v => v?.client?.passportIssuer,
  passportNumber        : v => v?.client?.passportNumber,
  passportSerial        : v => v?.client?.passportSerial,
  payedFee              : v => _.toNumber(v?.values?.payedFee),
  phone                 : v => v?.client?.phone,
  postalCode            : v => {
    const { applicationId } = v;
    const addressReg = _.find(v.client?.addressesReg, { applicationId })
      || _.first(v.client?.addressesReg);
    return addressReg?.postalCode;
  },
  snils            : v => v?.client?.snils,
  totalDebt        : v => v?.loan?.details?.totalDebt,
  totalDebtInterest: v => v?.loan?.details?.totalDebtInterest,
  totalDebtPenalty : v => v?.loan?.details?.totalDebtPenalty,
  totalMainDebt    : v => v?.loan?.details?.totalMainDebt,
  vin              : v => v?.car?.vin,
  year             : v => v?.car?.year,
};

const MANAGER_PARAMS: string[] = [
  `managerEmail`,
  `managerPhone`,
  `managerFullName`,
  `managerInt`,
];

const PERSON_PARAMS: string[] = [
  `addressReg`,
  `birthDtm`,
  `birthPlace`,
  `email`,
  `fullName`,
  `inn`,
  `passportIssueDtm`,
  `passportIssuer`,
  `passportNumber`,
  `passportSerial`,
  `phone`,
  `postalCode`,
];

const CAR_PARAMS: string[] = [
  `color`,
  `make`,
  `model`,
  `vin`,
  `year`,
];

export const CLAIM_DOCUMENTS: IGenerateLegalDocument[] = [
  {
    label     : `Заявление об изменении способа и порядка исполнения решения суда – ТС в натуре`,
    typeId    : 101,
    templateId: 84,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `contractNumber`,
      `creditStartDtm`,
      `currentDate`,
      `totalDebt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
      ...CAR_PARAMS,
    ]),
  },
];

export const FSSP_DOCUMENTS: IGenerateLegalDocument[] = [
  {
    label     : `Заявление об окончании ИП`,
    typeId    : 92,
    templateId: 75,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `currentDate`,
      `enforcementProceedings`,
      `executionWirt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
    ]),
  },
  {
    label     : `Заявление о возбуждении ИП (ИЛ)`,
    typeId    : 86,
    templateId: 69,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `contractNumber`,
      `creditStartDtm`,
      `currentDate`,
      `interestRateStr`,
      `payedFee`,
      `totalDebt`,
      `totalDebtInterest`,
      `totalDebtPenalty`,
      `totalMainDebt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
      ...CAR_PARAMS,
    ]),
  },
  {
    label     : `Заявление о возбуждении ИП (Судебный приказ)`,
    typeId    : 87,
    templateId: 70,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `contractNumber`,
      `creditStartDtm`,
      `payedFee`,
      `totalDebt`,
      `totalDebtInterest`,
      `totalDebtPenalty`,
      `totalMainDebt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
    ]),
  },
  {
    label     : `Заявление о возбуждении ИП (Судебный приказ) доп. условия`,
    typeId    : 87,
    templateId: 145,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `contractNumber`,
      `creditStartDtm`,
      `interestRateStr`,
      `payedFee`,
      `totalDebt`,
      `totalDebtInterest`,
      `totalDebtPenalty`,
      `totalMainDebt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
    ]),
  },
  {
    label     : `Письмо в ССП для информации об ИП`,
    typeId    : 88,
    templateId: 71,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `contractNumber`,
      `creditStartDtm`,
      `payedFee`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
    ]),
  },
  {
    label     : `Заявление об отзыве ИЛ`,
    typeId    : 93,
    templateId: 76,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `currentDate`,
      `enforcementProceedings`,
      `executionWirt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
    ]),
  },
  {
    label     : `Письмо в ССП на розыск ТС`,
    typeId    : 94,
    templateId: 77,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `currentDate`,
      `enforcementProceedings`,
      `executionWirt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
      ...CAR_PARAMS,
    ]),
  },
  {
    label     : `Заявление в ФССП об исполнении определения суда по отмене обеспечительных мер`,
    typeId    : 95,
    templateId: 78,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `caseNumber`,
      `currentDate`,
      `executionWirt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
      ...CAR_PARAMS,
    ]),
  },
  {
    label     : `Заявление в ФССП об исполнении определения суда по наложению обеспечительных мер`,
    typeId    : 96,
    templateId: 79,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `caseNumber`,
      `currentDate`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
      ...CAR_PARAMS,
    ]),
  },
];

export const OTHER_DOCUMENTS: IGenerateLegalDocument[] = [
  {
    label     : `Ходатайство - оставление иска без рассмотрения при вхождении в график`,
    typeId    : 100,
    templateId: 83,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `caseNumber`,
      `contractNumber`,
      `creditStartDtm`,
      `currentDate`,
      `payedFee`,
      `totalDebt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
    ]),
  },
  {
    label     : `Заявление об исправлении описок и явных арифметических ошибок`,
    typeId    : 99,
    templateId: 82,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `caseNumber`,
      `contractNumber`,
      `creditStartDtm`,
      `currentDate`,
      `payedFee`,
      `totalDebt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
    ]),
  },
  {
    label     : `Заявление о возврате ГП (Иск возвращен/отказано в принятии)`,
    typeId    : 98,
    templateId: 81,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `contractNumber`,
      `creditStartDtm`,
      `currentDate`,
      `payedFee`,
      `totalDebt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
    ]),
  },
  {
    label     : `Заявление о возврате ГП (Не подавался иск)`,
    typeId    : 97,
    templateId: 80,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      `currentDate`,
      `payedFee`,
      `totalDebt`,
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
    ]),
  },
  {
    label     : `Истребование у суда инфо о вынесении СП`,
    typeId    : 91,
    templateId: 74,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      ...MANAGER_PARAMS,
      `birthDtm`,
      `contractNumber`,
      `fullName`,
    ]),
  },
  {
    label     : `Истребование у суда вынесенного СП`,
    typeId    : 90,
    templateId: 73,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      ...MANAGER_PARAMS,
      `birthDtm`,
      `fullName`,
    ]),
  },
  {
    label     : `Истребование у суда Решения и ИЛ`,
    typeId    : 89,
    templateId: 72,
    params    : _.pick(LEGAL_DOCUMENT_PARAMS, [
      ...MANAGER_PARAMS,
      ...PERSON_PARAMS,
      `contractNumber`,
      `creditStartDtm`,
    ]),
  },
];

export const DEFAULT_TABLE_STATE = {
  filters   : {},
  pagination: {
    defaultCurrent : 1,
    defaultPageSize: 10,
    page           : 1,
    pageSize       : 10,
    pageSizeOptions: [5, 10, 25, 50, 100, 200],
    showTotal      : (total, range) => `${range[0]}-${range[1]} из ${total} задач`,
    size           : `small`,
    total          : 0,
  },
  sorter: {
    columnKey: `id`,
    field    : `id`,
    order    : `descend`,
  },
};

export const REGISTRY_ID_DOCUMENT_ID_MAP = {
  1 : 80,
  7 : 122,
  6 : 123,
  3 : 124,
  2 : 126,
  4 : 133,
  5 : 134,
  8 : 135,
  9 : 140,
  10: 141,
  11: 142,
  12: 143,
  13: 144,
  14: 145,
};
