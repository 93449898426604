/* eslint-disable @typescript-eslint/no-unsafe-call,no-case-declarations,default-case */
import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  formatDate,
  inputNumberFormatter,
  inputNumberParser,
} from 'helper';

import {
  createClientDocument as createClientDocumentAction,
  getClient as getClientAction,
  getClientCars as getClientCarsAction,
  getClientLoanDetails as getClientLoanDetailsAction,
  getClientLoans as getClientLoansAction,
} from 'models/clients/actions';
import { LOAN_STATUSES } from 'models/clients/constants';
import { getFullName } from 'models/clients/helpers';
import {
  getClient as getClientSelector,
  getClientCarsWithApplications as getClientCarsSelector,
  getClientLoansWithApplications as getClientLoansWithApplicationsSelector,
} from 'models/clients/selectors';
import {
  ICar,
  IClient,
  ICreateDocumentData,
  ILoan,
} from 'models/clients/types';

import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
} from 'antd';
import { CREATE_SIGN_DOCUMENT_MODES } from 'pages/PageClient/Single/Docs/constants';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  > * {
    min-width: 100%;
  }

  :not(:last-child) {
    .ant-row {
      margin-bottom: 0;
    }
  }
`;

const ErrorLabel = styled.div`
  color: ${palette.alert600Color};
`;

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content:flex-end;
  margin-top: 16px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const MIGRATION_TYPE = {
  constantly: `регулярный`,
  once      : `разовый`,
};

const migrationTypes: { name: string, value: string }[] = [
  { name: `регулярный`, value: MIGRATION_TYPE.constantly },
  { name: `разовый`, value: MIGRATION_TYPE.once },
];

const documentTemplates: { name: string, value: string }[] = [
  { name: MIGRATION_TYPE.constantly, value: `с __ на __ число каждого месяца` },
  { name: MIGRATION_TYPE.once, value: `c «__» ________ 202_ г. на «__» ________ 202_ г.` },
];

const findMigrationTypeByValue = (value: string) => _.find(migrationTypes, { value });

const findDocumentTemplateByName = (name: string) => _.find(documentTemplates, { name });

interface IProps {
  isLoading: boolean;
  isOpen: boolean;
  mode: string;
  personId: string;
  onCancel(): void;
}

const CreateDocumentModal: React.FC<IProps> = ({
  isLoading,
  isOpen,
  mode,
  onCancel,
  personId,
}) => {
  const dispatch = useDispatch();

  const cars:ICar[] = useSelector((state:any) => getClientCarsSelector(state, personId));
  const client:IClient = useSelector((state:any) => getClientSelector(state, personId));
  const loans:ILoan[] = useSelector((state:any) => getClientLoansWithApplicationsSelector(state, personId));

  const createClientDocument = ({
    callback,
    data,
  }: {
    callback?: () => void
    data: ICreateDocumentData,
  }) => dispatch(createClientDocumentAction({
    data,
    callback,
  }));

  const getCars = (force?:boolean, callback?:()=>void) => dispatch(getClientCarsAction({
    callback,
    force,
    personId,
  }));

  const getClient = (callback?:()=>void) => dispatch(getClientAction({ personId, callback }));

  const getLoanDetails = (loanId:string, callback?:()=>void) => dispatch(getClientLoanDetailsAction({
    callback,
    personId,
    loanId,
  }));

  const getLoans = (callback?:()=>void) => dispatch(getClientLoansAction({ callback, personId }));

  const { documentTemplateId, label } = _.find(CREATE_SIGN_DOCUMENT_MODES, { mode }) || {};
  const [activeLoans, setActiveLoans] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState<any>();
  const [form] = Form.useForm();
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [car, setCar] = useState<ICar>();
  const [errorLabel, setErrorLabel] = useState(null);

  const onModalCancel = () => {
    setSelectedLoan(undefined);
    form.resetFields();
    onCancel();
  };

  const onLoanSelectChange = (id: any) => {
    const newSelectedLoan = _.find(activeLoans, { id });

    if (newSelectedLoan) setSelectedLoan(newSelectedLoan);
  };

  const handleMigrationTypeChange = (value: any) => {
    const newMigrationType = _.find(migrationTypes, { value });

    if (newMigrationType) {
      const newMigrationDescription = findDocumentTemplateByName(newMigrationType.value)?.value;

      form.setFieldsValue({ migrationDescription: newMigrationDescription });
    }
  };

  const onFinish = (values: any, isDraft?: boolean) => {
    const loanPicked = {
      contractNumber: selectedLoan.contractNumber,
      loanId        : selectedLoan.id,
    };
    const clientPicked = {
      ..._.pick(client, [
        `email`,
        `fullName`,
        `passportIssuer`,
        `passportNumber`,
        `passportSerial`,
        `passportSubDivisionCode`,
        `phone`,
      ]),
      fullName: getFullName(client),
    };
    const addressesFact = _.get(client, `addressesFact`, []);
    const addressFact = _.get(_.last(addressesFact), `address`) || _.get(client, `addressFact`); // eslint-disable-line @typescript-eslint/no-unsafe-argument

    const addressesReg = _.get(client, `addressesReg`, []);
    const addressReg = _.get(_.last(addressesReg), `address`) || _.get(client, `addressReg`); // eslint-disable-line @typescript-eslint/no-unsafe-argument

    const birthDate = new Date(client.birthDate);
    const createDtm = new Date(selectedLoan.createDtm); // eslint-disable-line @typescript-eslint/no-unsafe-argument
    const passportIssuedDate = new Date(client.passportIssuedDate);
    const { actualPrice, migrationDescription, migrationType, sms } = values;

    // общие данные для всех типов документов
    // @ts-ignore
    const data:ICreateDocumentData = {
      personId,
      // @ts-ignore
      documentTemplateId,
      // @ts-ignore
      draft : isDraft,
      sms,
      params: {
        ...clientPicked,
        ...loanPicked,
        createDtm         : formatDate(createDtm, `dd.MM.yyyy`),
        birthDate         : formatDate(birthDate, `dd.MM.yyyy`),
        passportIssuedDate: formatDate(passportIssuedDate, `dd.MM.yyyy`),
      },
    };

    switch (mode) {
      case `paymentTransfer`:
        data.params.addressFact = addressFact;
        data.params.migrationDescription = migrationDescription;
        data.params.migrationType = migrationType;
        break;
      case `settlementAgreement`:
      case `settlementAgreementPartial`:
        const { totalDebt, totalDebtInterest, totalDebtPenalty, totalMainDebt } = selectedLoan?.details || {};
        const {
          // @ts-ignore
          color,
          // @ts-ignore
          make,
          // @ts-ignore
          model,
          // @ts-ignore
          ptsNumber,
          // @ts-ignore
          ptsSerial,
          // @ts-ignore
          vin,
          // @ts-ignore
          year,
        } = car;
        let totalOtherDebt = _.round(totalDebt - totalMainDebt - totalDebtInterest - totalDebtPenalty, 2);
        if (_.isNaN(totalOtherDebt) || totalOtherDebt < 0) totalOtherDebt = 0;

        data.params.actualPrice = actualPrice;
        data.params.addressReg = addressReg;
        data.params.totalDebt = totalDebt;
        data.params.totalDebtInterest = totalDebtInterest;
        data.params.totalDebtPenalty = totalDebtPenalty;
        data.params.totalMainDebt = totalMainDebt;
        data.params.totalOtherDebt = totalOtherDebt;
        data.params.vin = vin;
        data.params.color = color;
        data.params.make = make;
        data.params.model = model;
        data.params.year = year;
        data.params.ptsSerial = ptsSerial;
        data.params.ptsNumber = ptsNumber;
        break;
    }

    if (mode === `settlementAgreementPartial`) {
      // @ts-ignore
      let remainingDebt = _.round(data.params.totalDebt - actualPrice, 2);
      if (_.isNaN(remainingDebt) || remainingDebt < 0) remainingDebt = 0;

      data.params.remainMainDebt = remainingDebt;
    }

    if (_.includes([`dsChangePSK`, `dsTermProlongation`], mode)) {
      data.params.dsNumber = values.dsNumber;
    }

    createClientDocument({ data, callback: onModalCancel });
  };

  const onCreateDraft = () => onFinish(form.getFieldsValue(), true);

  useEffect(() => {
    if (!personId || !isOpen || isLoading) return;
    if (!client) getClient();
    if (_.isEmpty(loans)) getLoans();
  }, [personId, isLoading, isOpen, loans]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoading || !isOpen || mode === `paymentTransfer` || !selectedLoan?.id) return;
    if (_.isEmpty(selectedLoan?.details)) getLoanDetails(selectedLoan?.id);
    if (_.isEmpty(cars) && _.includes(mode, `settlementAgreement`)) getCars();
  }, [isOpen, isLoading, mode, selectedLoan?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((loans && !selectedLoan?.id) || (mode !== `paymentTransfer` && !selectedLoan?.details?.loanId)) {
      const filteredLoans = _.filter(loans, { loanStatusId: LOAN_STATUSES.ACTIVE });
      const firstLoan = _.first(filteredLoans);

      // @ts-ignore
      setActiveLoans(filteredLoans);
      setSelectedLoan(firstLoan);

      if (mode === `paymentTransfer`) {
        const initialMigrationType = findMigrationTypeByValue(MIGRATION_TYPE.constantly);

        // @ts-ignore
        form.setFieldsValue({ migrationType: initialMigrationType.value });
        // @ts-ignore
        form.setFieldsValue({ migrationDescription: findDocumentTemplateByName(initialMigrationType.value)?.value });
      }
    }
    if (selectedLoan?.id && mode !== `paymentTransfer`) {
      const { applicationId } = selectedLoan;
      const selectedCar = _.find(cars, ({ applicationIds }) => _.includes(applicationIds, applicationId));
      setCar(selectedCar);
    }
  }, [loans, mode, selectedLoan?.id, selectedLoan?.details?.loanId]); // eslint-disable-line react-hooks/exhaustive-deps

  const validate = (pass: null, values: { migrationType?: any; actualPrice?: any; }) => {
    if (mode === `paymentTransfer`) {
      if (!selectedLoan?.id) {
        setSubmitDisabled(true);
      } else {
        const templateDescription = _.get(_.find(documentTemplates, { name: values.migrationType }), `value`);
        if (!templateDescription) {
          setSubmitDisabled(true);
        } else {
          setTimeout(() => setSubmitDisabled(form.getFieldValue(`migrationDescription`) === templateDescription), 80);
        }
      }
    }
    if (_.startsWith(mode, `settlementAgreement`)) {
      setSubmitDisabled(_.isEmpty(selectedLoan?.details) || _.isEmpty(car) || !values.actualPrice);
      if (_.isEmpty(selectedLoan?.details) && _.isEmpty(car)) {
        // @ts-ignore
        setErrorLabel(`Нет данных по деталям займа и авто`);
      } else if (_.isEmpty(car)) {
        // @ts-ignore
        setErrorLabel(`Нет данных по авто`);
      } else if (_.isEmpty(selectedLoan?.details)) {
        // @ts-ignore
        setErrorLabel(`Нет данных по деталям займа`);
      } else {
        setErrorLabel(null);
      }
    }
    if (_.includes([`dsChangePSK`, `dsTermProlongation`], mode)) {
      setSubmitDisabled(!selectedLoan?.id || !form.getFieldValue(`dsNumber`));
    }
  };
  useEffect(() => {
    validate(null, {})
  }, [isOpen, mode, selectedLoan?.id, selectedLoan?.details?.loanId, car?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      cancelText='Отмена'
      centered
      closable
      footer={null}
      maskClosable={false}
      okButtonProps={{ disabled: isSubmitDisabled }}
      okText='Отправить'
      onCancel={onModalCancel}
      open={isOpen}
      title={label}
    >
      <Content>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
          onValuesChange={validate}
        >
          <Form.Item label='Займ для формирования документа'>
            <StyledSelect
              onChange={onLoanSelectChange}
              options={_.map(activeLoans, ({ contractNumber, createDtm, id }) => ({
                label: `${contractNumber} от ${formatDate(createDtm, `dd.MM.yyyy`)}`,
                value: id,
              }))}
              placeholder='Выберите'
              value={selectedLoan?.id}
            />
          </Form.Item>
          {selectedLoan && (
            <>
              {_.includes([`dsChangePSK`, `dsTermProlongation`], mode) && (
                <Form.Item label='Номер доп. соглашения' name='dsNumber'>
                  <Input placeholder='Номер доп. соглашения' />
                </Form.Item>
              )}
              {mode === `paymentTransfer` && (
              <>
                <Form.Item label='Перенос платежа' name='migrationType'>
                  <StyledSelect
                    onChange={handleMigrationTypeChange}
                    options={_.map(migrationTypes, ({ name, value }) => ({ label: name, value }))}
                    placeholder='Перенос платежа'
                  />
                </Form.Item>

                <Form.Item
                  label='Описание'
                  name='migrationDescription'
                  rules={[{
                    message : `Обязательное поле`,
                    required: true,
                  }]}
                >
                  <Input.TextArea
                    placeholder='Описание'
                    rows={4}
                  />
                </Form.Item>
              </>
              )}

              {_.startsWith(mode, `settlementAgreement`) && (
              <Form.Item
                label='Сумма'
                name='actualPrice'
                rules={[{
                  message : `Обязательное поле`,
                  required: true,
                }]}
              >
                <StyledInputNumber
                  formatter={inputNumberFormatter}
                  parser={inputNumberParser}
                  placeholder='Сумма, ₽'
                  precision={2}
                />
              </Form.Item>
              )}

              <Form.Item
                initialValue={false}
                label=''
                name='sms'
                valuePropName='checked'
              >
                <Checkbox>Отправить СМС</Checkbox>
              </Form.Item>

              <StyledDivider />
              {errorLabel && (
                <>
                  <ErrorLabel>{errorLabel} - формирование документа невозможно</ErrorLabel>
                  <StyledDivider />
                </>
              )}
              <Form.Item>
                <ButtonsWrapper>
                  <Space>
                    <Button
                      onClick={onModalCancel}
                    >
                      Отмена
                    </Button>
                    <Button
                      disabled={isLoading || isSubmitDisabled}
                      htmlType='submit'
                      type='primary'
                    >
                      Сохранить
                    </Button>
                    <Button
                      disabled={isLoading || isSubmitDisabled}
                      onClick={onCreateDraft}
                      type='primary'
                    >
                      Создать черновик
                    </Button>
                  </Space>
                </ButtonsWrapper>
              </Form.Item>
            </>
          )}
        </Form>
      </Content>
    </Modal>
  );
};

export default CreateDocumentModal;
