import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';
import styled from 'styled-components';
import { v4 } from 'uuid';

import {
  getFullNameInitials,
  inputNumberFormatter,
  inputNumberParser,
} from 'helper';

import { useDictionaries } from 'models/dictionaries/hooks';
import {
  create,
  update,
} from 'models/lead/actions';
import { ILead } from 'models/lead/types';
import { IOperator } from 'models/operators/types';

import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from 'antd';

const StyledInputNumber = styled(InputNumber)`width: 200px;`;

interface IProps {
  item: ILead;
  open: boolean,
  operatorsToSelect: IOperator[];
  onClose():void,
  onSuccess():void,
}

interface IValues {
  comment?: string | null;
  leadGenName?: string | null;
  leadId?: string | null;
  leadStatusId: number | null;
  name?: string | null;
  needCall?: boolean;
  operatorId?: number | null;
  personId?: string | null;
  phone?: string | null;
  region?: string | null;
  sum?: number | null;
  timeZone?: number | null;
}

type IErrors = {
  leadStatusId?: string,
};

const defaultValues: IValues = {
  comment     : ``,
  leadGenName : ``,
  leadId      : ``,
  leadStatusId: null,
  name        : ``,
  needCall    : false,
  operatorId  : null,
  personId    : ``,
  phone       : ``,
  region      : ``,
  sum         : null,
  timeZone    : null,
};

const validateForm = (values: IValues) => {
  const errors: IErrors = {};
  if (!values.leadStatusId) errors.leadStatusId = `Обязательно для заполнения`;
  return errors;
};

export const LeadModal: React.FC<IProps> = ({
  item,
  onClose,
  onSuccess,
  open,
  operatorsToSelect,
}) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(defaultValues);

  const [, dictionaries] = useDictionaries([`leadStatus`]);

  useEffect(() => {
      if (open && item) setValues(item);
    },
    [item, open],
  );

  const setValue = (field: string, data: ChangeEvent<HTMLInputElement> | number | string | boolean) => {
    setValues({
      ...values,
      [field]: !_.isEmpty(data) && _.has(data, `target`)
        ? (data as ChangeEvent<HTMLInputElement>).target.value
        : data,
    });
  };

  const handleClose = () => {
    setValues(defaultValues);
    onClose();
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const errors = validateForm(values);

  const onSubmit = () => {
    if (item) {
      dispatch(update({
        callback: handleSuccess,
        id      : item.id,
        item    : {
          ...values,
          leadId   : values.leadId || v4(),
          updatedAt: new Date(),
        },
      }));
    } else {
      dispatch(create({
        callback : handleSuccess,
        ...values,
        leadId   : values.leadId || v4(),
        createdAt: new Date(),
        updatedAt: new Date(),
      }));
    }
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      okText='Сохранить'
      onCancel={handleClose}
      onOk={onSubmit}
      open={open}
      title='Лид'
    >
      <Form layout='vertical'>
        <Form.Item
          label='Статус'
          required
          validateStatus={errors.leadStatusId ? `error` : `success`}
        >
          <Select
            // @ts-ignore
            onChange={value => setValue(`leadStatusId`, value)}
            options={_.map(dictionaries?.leadStatus, leadStatus => ({
              label: leadStatus.description || leadStatus.name,
              value: leadStatus.id,
            }))}
            value={values.leadStatusId}
          />
        </Form.Item>
        <Form.Item
          label='Имя'
        >
          <Input
            onChange={event => setValue(`name`, event)}
            // @ts-ignore
            value={values.name}
          />
        </Form.Item>
        <Form.Item
          label='Телефон'
        >
          <Input
            onChange={event => setValue(`phone`, event)}
            // @ts-ignore
            value={values.phone}
          />
        </Form.Item>
        <Form.Item label='Сумма'>
          <StyledInputNumber
            formatter={inputNumberFormatter}
            min={0}
            // @ts-ignore
            onChange={event => setValue(`sum`, event)}
            parser={inputNumberParser}
            placeholder='Сумма, ₽'
            precision={2}
            value={values.sum}
          />
        </Form.Item>
        <Form.Item label='Комментарий'>
          <Input
            // @ts-ignore
            onChange={event => setValue(`comment`, event)}
            // @ts-ignore
            value={values.comment}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={values.needCall}
            onChange={() => setValue(`needCall`, !values.needCall)}
          >Нужен звонок
          </Checkbox>
        </Form.Item>
        <Form.Item
          label='Регион'
        >
          <Input
            onChange={event => setValue(`region`, event)}
            // @ts-ignore
            value={values.region}
          />
        </Form.Item>
        <Form.Item label='Часовой пояс'>
          <StyledInputNumber
            // @ts-ignore
            onChange={event => setValue(`timeZone`, event)}
            precision={0}
            value={values.timeZone}
          />
        </Form.Item>
        <Form.Item
          label='Лидогенератор'
        >
          <Input
            onChange={event => setValue(`leadGenName`, event)}
            // @ts-ignore
            value={values.leadGenName}
          />
        </Form.Item>
        <Form.Item
          label='Сотрудник'
        >
          <Select
            // @ts-ignore
            onChange={value => setValue(`operatorId`, value)}
            options={_.map(operatorsToSelect, operator => ({
              label: getFullNameInitials(operator?.user),
              value: operator?.id,
            }))}
            value={values.operatorId}
          />
        </Form.Item>
        <Form.Item
          label='Lead ID'
        >
          <Input
            onChange={event => setValue(`leadId`, event)}
            // @ts-ignore
            value={values.leadId}
          />
        </Form.Item>
        <Form.Item
          label='Person ID'
        >
          <Input
            onChange={event => setValue(`personId`, event)}
            // @ts-ignore
            value={values.personId}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
