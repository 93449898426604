import React from 'react';
import _ from 'lodash';

import BOOL_COLUMN from 'constants/BOOL_COLUMN';
import {
  formatDate,
  getColumn,
} from 'helper';

import { IAction } from 'models/actions/types';
import { ROLE_NAMES } from 'models/roles/constants';

import { DeleteOutlined } from '@ant-design/icons';
import {
  Popconfirm,
  Select,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

export const DISABLED_TOOLTIP = `Доступно для роли ${ROLE_NAMES.ACTION_CLIENT_EDITOR}`;

interface Column {
  align?: string;
  key: string;
  render?: (v: any, action?: any) => JSX.Element | string;
  sorter?: null;
  title: string;
  width: number;
}

interface PersonAction {
  id: string;
}

interface FormField {
  Component: typeof Select;
  key: string;
  mode: string;
  options: { label: string; value: string | number }[];
  rules: { validator: (pass: any, v: any) => Promise<void> }[];
  title: string;
}

export const COLUMNS = ({
  canRemove,
  onRemove,
}: { canRemove: boolean; onRemove: (id?: any) => void }): ColumnType<Column>[] => _.map(
  [
    {
      key  : `name`,
      title: `Название`,
      width: 200,
    },
    {
      key  : `description`,
      title: `Описание`,
      width: 250,
    },
    {
      key   : `startDtm`,
      render: (v: any) => formatDate(v),
      title : `Дата начала`,
      width : 100,
    },
    {
      key   : `endDtm`,
      render: (v: any) => formatDate(v),
      title : `Дата окончания`,
      width : 100,
    },
    BOOL_COLUMN(`technicalActionFlg`, `Техническая`),
    {
      key  : `additionalInfo`,
      title: `Дополнительная информация`,
      width: 250,
    },
    {
      key   : `id`,
      align : `center`,
      render: (v: any, action: { id: any; }) => (
        <DefaultTooltip placement='topRight' title={canRemove ? `` : DISABLED_TOOLTIP}>
          <Popconfirm
            cancelText='Нет'
            disabled={!canRemove}
            okButtonProps={{ danger: true }}
            okText='Да'
            onConfirm={() => onRemove(action?.id)}
            placement='topRight'
            title='Точно удалить?'
          >
            <DeleteOutlined />
          </Popconfirm>
        </DefaultTooltip>
      ),
      sorter: null,
      title : ``,
      width : 50,
    },
  ],
  getColumn,
);

export const CREATE_PERSON_ACTION_FORM_FIELDS = ({
  actions,
  personActions,
}: {
  actions: IAction[];
  personActions: PersonAction[];
}): FormField[] => [
  {
    Component: Select,
    key      : `actionIds`,
    mode     : `multiple`,
    options  : _.map(
      _.filter(
        actions,
        ({ endDtm, id }) => new Date(endDtm) > new Date() && !_.find(personActions, { id }),
      ),
      ({ endDtm, id, name }) => ({
        label: `${name} до ${formatDate(endDtm)}`,
        value: id,
      }),
    ),
    rules: [
      {
        validator: (pass, v) => {
          if (!v) {
            return Promise.reject(new Error(`Обязательное поле`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `Акции`,
  },
];
