import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import { transparentize } from 'polished';
import Draggable from 'react-draggable';
import { useHotkeys } from 'react-hotkeys-hook';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Empty } from 'antd';
import { ImagePreviewControls } from 'components/ImagePreview/ImagePreviewControls';
import { ScrollbarsDesktop } from 'components/ScrollbarsDesktop/ScrollbarsDesktop';
import {
  useRotation,
  useScale,
} from 'pages/PageClient/Single/Docs/DocsCarousel/hooks';

const Wrapper = styled.div<{ isVisible: boolean; }>`
  width: 100%;
  position: fixed;
  height: 100%;
  background: ${p => transparentize(0.5, palette.blackColor(p))};
  top: 0;
  z-index: 5;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? `visible` : `hidden`)};
  transition: opacity, 200ms ease;
`;

const Arrow = styled.div`
  position: absolute;
  color: ${palette.whiteColor};
  height: calc(100% - 50px);
  width: 100px;
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  transition: background, 300ms ease;
  z-index: 1;

  :hover {
    background: ${p => transparentize(0.7, palette.blackColor(p))};

    .anticon {
      opacity: 1;
    }
  }

  .anticon {
    transition: opacity 300ms ease;
    opacity: 0.5;
    font-size: 40px;
  }
`;

const ArrowLeft = styled(Arrow)`
  left: 0;
`;

const ArrowRight = styled(Arrow)`
  right: 0;
`;

const ImageWrapper = styled.div`
  padding-right: 70px;
  padding-left: 70px;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: zoom-out;
`;

const GalleryImage = styled.img<{ rotation: number, scale: number }>`
  transform: ${({ rotation, scale }) => `rotate(${rotation}deg) scale3d(${scale}, ${scale}, 1)`};
  cursor: grab;
  user-select: none;
  max-width: 100%;
`;

interface IComponentProps {
  fallback?: React.ReactNode;
  image: {
    name: string;
    src?: string;
    type: string;
  }
  isVisible: boolean;
  onCancel(): void;
  onClickNext(): void;
  onClickPrev(): void;
}

export const ImagePreviewDesktop: React.FC<IComponentProps> = ({
  fallback: Fallback,
  image,
  isVisible,
  onCancel,
  onClickNext,
  onClickPrev,
}) => {
  const { resetRotation, rotateLeft, rotateRight, rotation } = useRotation();
  const {
    downscale,
    isDownscaleDisabled,
    isUpscaleDisabled,
    resetScale,
    scale,
    upscale,
  } = useScale(1);

  const initialPosition = { x: 0, y: 0 };

  const [position, setPosition] = useState(initialPosition);

  const handleDrag = (e: any, ui: { deltaX: number; deltaY: number; }) => {
    if (scale > 1) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      setPosition(value => ({ x: value.x + ui.deltaX, y: value.y + ui.deltaY }));
    } else {
      setPosition(initialPosition);
    }
  };

  useEffect(() => {
    if (scale === 1) {
      setPosition(initialPosition);
    }
  }, [scale]);

  useHotkeys(`=, num_add`, () => isVisible && upscale(), {}, [scale, isVisible]);
  useHotkeys(`-, num_subtract`, () => isVisible && downscale(), {}, [scale]);
  useHotkeys(`[`, () => isVisible && rotateLeft(), [isVisible]);
  useHotkeys(`]`, () => isVisible && rotateRight(), [isVisible]);

  useEffect(() => {
    resetRotation();
    resetScale();
  }, [isVisible, image]);

  return (
    <Wrapper isVisible={isVisible}>
      {isVisible && (
      <>
        <ImagePreviewControls
          imageName={image.name}
          isScaleDownDisabled={isDownscaleDisabled}
          isScaleUpDisabled={isUpscaleDisabled}
          onCancel={onCancel}
          rotateLeft={rotateLeft}
          rotateRight={rotateRight}
          scaleDown={downscale}
          scaleUp={upscale}
        />

        {onClickPrev && (
          <ArrowLeft onClick={() => onClickPrev()}>
            <LeftOutlined />
          </ArrowLeft>
        )}

        {onClickNext && (
          <ArrowRight onClick={() => onClickNext()}>
            <RightOutlined />
          </ArrowRight>
        )}

        <ScrollbarsDesktop>
          <ImageWrapper onClick={onCancel}>
            {image && (
              image.src ? (
                <Draggable onDrag={handleDrag} position={position}>
                  <span>
                    <GalleryImage
                      alt={image.name}
                      draggable='false'
                      onClick={e => e.stopPropagation()}
                      rotation={rotation}
                      scale={scale}
                      src={image.src}
                    />
                  </span>
                </Draggable>
              ) : (
                  Fallback
                    ? Fallback
                    : <Empty description='Файл не может быть отображен' />
              )
            )}

          </ImageWrapper>
        </ScrollbarsDesktop>
      </>
      )}
    </Wrapper>
  );
};
