import { createAction } from 'redux-actions';

export const model = `verifications`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const APPROVE = `APPROVE`;
export const ASSIGN = `ASSIGN`;
export const CREATE_GUARANTEE_LETTER = `CREATE_GUARANTEE_LETTER`;
export const DECLINE = `DECLINE`;
export const GET = `GET`;
export const GET_SINGLE = `GET_SINGLE`;
export const REASSIGN = `REASSIGN`;
export const RESET = `RESET`;
export const SEND_BLURRED_PHOTOS = `SEND_BLURRED_PHOTOS`;
export const SET = `SET`;
export const SET_DECISION = `SET_DECISION`;
export const SET_LOADING = `SET_LOADING`;
export const SET_OPERATOR = `SET_OPERATOR`;
export const SET_RESULTS = `SET_RESULTS`;
export const SET_STATUS = `SET_STATUS`;
export const UPDATE_RESULTS = `UPDATE_RESULTS`;
export const UPDATE_USER_DATA = `UPDATE_USER_DATA`;
export const VERIFY_APPLICATION_INCOME = `VERIFY_APPLICATION_INCOME`;

export const approve = ca(APPROVE);
export const assign = ca(ASSIGN);
export const createGuaranteeLetter = ca(CREATE_GUARANTEE_LETTER);
export const decline = ca(DECLINE);
export const get = ca(GET);
export const getSingle = ca(GET_SINGLE);
export const reassign = ca(REASSIGN);
export const reset = ca(RESET);
export const sendBlurredPhotos = ca(SEND_BLURRED_PHOTOS);
export const set = ca(SET);
export const setDecision = ca(SET_DECISION);
export const setLoading = ca(SET_LOADING);
export const setResults = ca(SET_RESULTS);
export const setStatus = ca(SET_STATUS);
export const updateResults = ca(UPDATE_RESULTS);
export const updateUserData = ca(UPDATE_USER_DATA);
export const verifyApplicationIncome = ca(VERIFY_APPLICATION_INCOME);
