import React, { useState } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { uploadExternal as uploadExternalAction } from 'models/dictionaries/actions';
import { isLoading as isLoadingSelector } from 'models/dictionaries/selectors';

import {
  CheckCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  Modal,
  Select,
  Upload,
} from 'antd';
import { RcFile } from 'antd/lib/upload';

const BLACK_LIST_TYPES = [
  {
    label: `Список террористов`,
    value: `terrorist-list`,
    path : `/proxy/blacklist/terrorist/create/file`,
  },
  {
    label: `Список межведомственной комиссии`,
    value: `interdepartmental-commission`,
    path : `/proxy/blacklist/f/interdepartmental-commission`,
  },
  {
    label: `Список ЦБ`,
    value: `central-bank`,
    path : `/proxy/blacklist/f/central-bank`,
  },
];

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  >* {
    min-width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

const StyledUpload = styled(Upload.Dragger)`
  border-color: ${palette.primaryColor} !important;
  border-radius: 8px !important;
`;

const UploadIcon = styled.p`
  color: ${palette.primaryColor};
  font-size: 32px;
`;

const UploadText = styled.p`
  color: ${palette.primaryColor};
`;

interface IProps {
  isVisible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const CarBlackListUploadModal :React.FC<IProps> = ({
  isVisible = false,
  onClose = _.noop,
  onSuccess = _.noop,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [type, setType] = useState(BLACK_LIST_TYPES[0].value);
  const [file, setFile] = useState<RcFile | File | null>(null);

  const reset = () => {
    // @ts-ignore
    setType(null);
    setFile(null);
    onClose();
  };

  const upload = () => {
    const blackList = _.find(BLACK_LIST_TYPES, { value: type });
    dispatch(uploadExternalAction({
      callback: () => {
        reset();
        onSuccess();
      },
      file,
      path : blackList?.path,
      label: blackList?.label,
      type,
    }));
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      closable={false}
      maskClosable={false}
      okButtonProps={{ disabled: !type || !file || isLoading }}
      okText='Загрузить'
      onCancel={reset}
      onOk={upload}
      open={isVisible}
      title='Загрузка Чёрных Списков'
    >
      <Content>
        <StyledSelect
          // @ts-ignore
          onChange={setType}
          options={BLACK_LIST_TYPES}
          placeholder='Выберите тип ЧС'
          value={type}
        />

        <StyledUpload
          // @ts-ignore
          customRequest={({ file: f }) => setFile(f)}
          multiple={false}
          showUploadList={false}
        >
          {file
            ? (
              <>
                <UploadIcon><CheckCircleOutlined /></UploadIcon>
                <UploadText>{file.name}</UploadText>
              </>
            )
            : (
              <>
                <UploadIcon><InboxOutlined /></UploadIcon>
                <UploadText>Кликните или перетащите сюда файл с ЧС для загрузки</UploadText>
              </>
            )}
        </StyledUpload>
      </Content>
    </Modal>
  );
};

export default CarBlackListUploadModal;
