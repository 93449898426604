import * as React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { getShortened } from 'helper';

import { Tooltip } from 'antd';
import { CopyIconButton } from 'components/CopyIconButton/CopyIconButton';

const CopyButton = styled(CopyIconButton)``;

const Wrapper = styled.div`
  display: flex;

  @media(min-width: ${breakpoints.lg}) {
    ${CopyButton} {
      opacity: 0;
    }

    :hover {
      ${CopyButton} {
        opacity: 1;
      }
    }
  }
`;

interface IComponentProps {
  length?: number;
  value: string;
}

export const CellId: React.FC<IComponentProps> = ({ length, value }) => (
  <Wrapper>
    <Tooltip title={value}>
      {getShortened(value, length)}
    </Tooltip>
    <CopyButton valueToCopy={value} />
  </Wrapper>
);
