import { createContext } from 'react';

interface IClientMatchContext {
  personId?: string;
  taskId?: number;
  verificationId?: number;
}

const ClientMatchContext = createContext<IClientMatchContext>({
  personId      : undefined,
  taskId        : undefined,
  verificationId: undefined,
});

export default ClientMatchContext;
