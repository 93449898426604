import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment, { Moment } from 'moment/moment';
import styled from 'styled-components';

import { parseResponse } from 'api/helpers';
import {
  inputNumberFormatter,
  inputNumberParser,
  showError,
} from 'helper';

import { post as postBankruptAction } from 'models/bankrupts/actions';
import { IBankruptFacade } from 'models/bankrupts/types';
import * as api from 'models/clients/api';
import { getIsLoading as isLoadingSelector } from 'models/clients/selectors';
import { ILoanClient } from 'models/clients/types';
import { useDictionaries } from 'models/dictionaries/hooks';
import { getFullName as getFullNameSelector } from 'models/user/selectors';

import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from 'antd';
import { FedResursMessages } from 'components/Modal/BankruptModal/FedResurs';
import MobileContext from 'pages/Layout/MobileContext';

const StyledInputNumber = styled(InputNumber)`width: 200px;`;

const StyledButton = styled(Button)`
  margin-top: 8px;
  width: 100%;
`;

const Padding = styled.div`
  padding: 0 24px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

interface IProps {
  isVisible: boolean,
  item: IBankruptFacade | null,
  mode?: `update` | `create`,
  onClose():void,
  onSuccess?():void,
}

interface IValues {
  arbitrationCourtId: number | null,
  auctionFirstStageDtm: moment.Moment | null,
  auctionSecondStageDtm: moment.Moment | null,
  auctionStatusId: number | null,
  auctionThirdStageDtm: moment.Moment | null,
  balanceCarAcceptingDtm: moment.Moment | null,
  balanceCarCost: number | null,
  bankruptDecisionDtm: moment.Moment | null,
  bankruptEndDtm: moment.Moment | null,
  bankruptsyValidation: moment.Moment | null,
  biddingStatus: string,
  carEstimateCost: number | null,
  caseNumber: string,
  comment: string,
  commersantDtm: moment.Moment | null,
  contacts: string,
  courtTaktikumRtkDtm: moment.Moment | null,
  financialManager: string,
  financialManagerAddress: string,
  financialManagerEmail: string,
  judge: string,
  mainInformationComment: string,
  mfoInDtm: moment.Moment | null,
  parkingFlg: boolean,
  pledgeStatusId: number | null,
  propertyRestructuringDtm: moment.Moment | null,
  propertySaleDtm: moment.Moment | null,
  receivedAmount: number | null,
  registryClaimAmount: number | null,
  registryInclusionDtm: moment.Moment | null,
  rtkDtm: moment.Moment | null,
  szDate: moment.Moment | null,
}

const defaultValues: IValues = {
  arbitrationCourtId      : null,
  auctionFirstStageDtm    : null,
  auctionSecondStageDtm   : null,
  auctionStatusId         : null,
  auctionThirdStageDtm    : null,
  balanceCarAcceptingDtm  : null,
  balanceCarCost          : null,
  bankruptDecisionDtm     : null,
  bankruptEndDtm          : null,
  bankruptsyValidation    : null,
  biddingStatus           : ``,
  carEstimateCost         : null,
  caseNumber              : ``,
  comment                 : ``,
  commersantDtm           : null,
  contacts                : ``,
  courtTaktikumRtkDtm     : null,
  financialManager        : ``,
  financialManagerAddress : ``,
  financialManagerEmail   : ``,
  judge                   : ``,
  mainInformationComment  : ``,
  mfoInDtm                : null,
  parkingFlg              : true,
  pledgeStatusId          : null,
  propertyRestructuringDtm: null,
  propertySaleDtm         : null,
  receivedAmount          : null,
  registryClaimAmount     : null,
  registryInclusionDtm    : null,
  rtkDtm                  : null,
  szDate                  : null,
};

const BankruptModal: React.FC<IProps> = ({
  isVisible,
  item,
  mode = `update`,
  onClose,
  onSuccess = _.noop,
}) => {
  const dispatch = useDispatch();
  const mobile = useContext(MobileContext);

  const { bankruptId, loanId, personId } = item || {};

  const isClientLoading = useSelector(isLoadingSelector);
  const operatorName = useSelector(getFullNameSelector);

  const [values, setValues] = useState<IValues>(defaultValues);
  const [isClientConditionLoading, setIsClientConditionLoading] = useState<boolean>(false);
  const [client, setClient] = useState<ILoanClient | null>(null);
  const [clientConditionId, setClientConditionId] = useState<number | null>(null);

  const getClient = async () => {
    if (!personId) return;
    const defaultError = `При получении клиентских данных произошла ошибка`;
    setIsClientConditionLoading(true);
    try {
      const loanClient:ILoanClient = parseResponse({
        defaultError,
        response: await api.getClientInfo(personId),
      });
      setClient(loanClient);
      if (loanClient?.clientConditionId) setClientConditionId(loanClient.clientConditionId);
    } catch (error) {
      showError({ defaultError, error });
    } finally {
      setIsClientConditionLoading(false);
    }
  };

  const saveClientCondition = async () => {
    if (!personId) return;
    setIsClientConditionLoading(true);
    const defaultError = `При обновлении состояния клиента произошла ошибка`;
    try {
      parseResponse({
        defaultError,
        response: await api.updateClientCondition({
          changeDtm: moment.utc(),
          clientConditionId,
          operatorName,
          personId,
        }),
      });
      await getClient();
    } catch (error) {
      showError({ defaultError, error });
    } finally {
      setIsClientConditionLoading(false);
    }
  };

  const [, dictionaries] = useDictionaries([
    `arbitrationCourt`,
    `auctionStatus`,
    `biddingStatus`,
    `clientCondition`,
    `pledgeStatus`,
  ]);

  // @ts-ignore
  useEffect(() => {
    getClient();
  }, [personId]);
  useEffect(() => {
      if (isVisible && item) {
        setValues({
          arbitrationCourtId      : item.arbitrationCourtId,
          auctionFirstStageDtm    : item.auctionFirstStageDtm ? moment.utc(item.auctionFirstStageDtm) : null,
          auctionSecondStageDtm   : item.auctionSecondStageDtm ? moment.utc(item.auctionSecondStageDtm) : null,
          auctionStatusId         : item.auctionStatusId,
          auctionThirdStageDtm    : item.auctionThirdStageDtm ? moment.utc(item.auctionThirdStageDtm) : null,
          balanceCarAcceptingDtm  : item.balanceCarAcceptingDtm ? moment.utc(item.balanceCarAcceptingDtm) : null,
          balanceCarCost          : item.balanceCarCost,
          bankruptDecisionDtm     : item.bankruptDecisionDtm ? moment.utc(item.bankruptDecisionDtm) : null,
          bankruptEndDtm          : item.bankruptEndDtm ? moment.utc(item.bankruptEndDtm) : null,
          bankruptsyValidation    : item.bankruptsyValidation ? moment.utc(item.bankruptsyValidation) : null,
          biddingStatus           : item.biddingStatus,
          carEstimateCost         : item.carEstimateCost,
          caseNumber              : item.caseNumber,
          comment                 : item.comment,
          commersantDtm           : item.commersantDtm ? moment.utc(item.commersantDtm) : null,
          contacts                : item.contacts,
          courtTaktikumRtkDtm     : item.courtTaktikumRtkDtm ? moment.utc(item.courtTaktikumRtkDtm) : null,
          financialManager        : item.financialManager,
          financialManagerAddress : item.financialManagerAddress,
          financialManagerEmail   : item.financialManagerEmail,
          judge                   : item.judge,
          mainInformationComment  : item.mainInformationComment,
          mfoInDtm                : item.mfoInDtm ? moment.utc(item.mfoInDtm) : null,
          parkingFlg              : item.parkingFlg,
          pledgeStatusId          : item.pledgeStatusId,
          propertyRestructuringDtm: item.propertyRestructuringDtm ? moment.utc(item.propertyRestructuringDtm) : null,
          propertySaleDtm         : item.propertySaleDtm ? moment.utc(item.propertySaleDtm) : null,
          receivedAmount          : item.receivedAmount,
          registryClaimAmount     : item.registryClaimAmount,
          registryInclusionDtm    : item.registryInclusionDtm ? moment.utc(item.registryInclusionDtm) : null,
          rtkDtm                  : item.rtkDtm ? moment.utc(item.rtkDtm) : null,
          szDate                  : item.szDtm ? moment.utc(item.szDtm) : null,
        });
      }
    },
    [bankruptId, isVisible],
  );

  const setValue = (field: string, data: ChangeEvent<HTMLInputElement> | Moment | string | number | boolean) => setValues({
    ...values,
    [field]: !_.isEmpty(data) && _.has(data, `target`) ? (data as ChangeEvent<HTMLInputElement>).target.value : data,
  });

  const handleClose = () => {
    setValues(defaultValues);
    onClose();
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const onSubmit = () => {
    dispatch(postBankruptAction({
      callback: handleSuccess,
      bankruptId,
      loanId,
      personId,
      ...values,
    }));
  };

  const isLoading = isClientLoading || isClientConditionLoading;

  return (
    <StyledModal
      cancelText='Отмена'
      centered
      confirmLoading={isLoading}
      okButtonProps={{ disabled: isLoading }}
      okText='Сохранить'
      onCancel={handleClose}
      onOk={onSubmit}
      open={isVisible}
      title={mode === `update` ? `Редактирование банкротства ${item?.clientFullName || ``}` : `Создание банкротства`}
      width={mobile ? `90%` : 1000}
    >
      <Form layout='vertical'>
        {personId && <FedResursMessages personId={personId} />}
        <Collapse>
          {item && client && (
          <Collapse.Panel header='Состояние клиента' key='clientCondition'>
            <Padding>
              <Form.Item label='Состояние клиента'>
                <Select
                  fieldNames={{
                    label: `name`,
                    value: `id`,
                  }}
                  onChange={setClientConditionId}
                  options={dictionaries.clientCondition}
                  value={clientConditionId}
                />
                {client.clientConditionId !== clientConditionId && (
                <StyledButton disabled={isClientConditionLoading} onClick={saveClientCondition} type='primary'>
                  Сохранить состояние клиента
                </StyledButton>
                )}
              </Form.Item>
            </Padding>
          </Collapse.Panel>
          )}
          <Collapse.Panel header='Основная информация' key='mainInfo'>
            <Padding>

              <Form.Item label='Номер дела'>
                <Input
                  onChange={event => setValue(`caseNumber`, event)}
                  value={values.caseNumber}
                />
              </Form.Item>
              <Form.Item label='Судебное заседание по проверке обоснованности заявления о банкротстве'>
                <DatePicker
                  onChange={value => setValue(`bankruptsyValidation`, value)}
                  // @ts-ignore
                  value={values.bankruptsyValidation}
                />
              </Form.Item>
              <Form.Item label='Дата решения о признании банкротом'>
                <DatePicker
                  onChange={value => setValue(`bankruptDecisionDtm`, value)}
                  // @ts-ignore
                  value={values.bankruptDecisionDtm}
                />
              </Form.Item>
              <Form.Item label='Реструктуризация имущества'>
                <DatePicker
                  onChange={value => setValue(`propertySaleDtm`, value)}
                  // @ts-ignore
                  value={values.propertySaleDtm}
                />
              </Form.Item>
              <Form.Item label='Реализация имущества'>
                <DatePicker
                  onChange={value => setValue(`propertyRestructuringDtm`, value)}
                  // @ts-ignore
                  value={values.propertyRestructuringDtm}
                />
              </Form.Item>
              <Form.Item label='Арбитражный суд'>
                <Select
                  fieldNames={{
                    label: `name`,
                    value: `id`,
                  }}
                  filterOption={(input, option) => _.includes(option?.name, input)}
                  onChange={value => setValue(`arbitrationCourtId`, value)}
                  options={dictionaries.arbitrationCourt}
                  showSearch
                  value={values.arbitrationCourtId}
                />
              </Form.Item>
              <Form.Item label='Адрес арбитражного суда'>
                {values.arbitrationCourtId
                  ? (
                    <div>{_.find(dictionaries?.arbitrationCourt, { id: values.arbitrationCourtId })?.address}</div>
                  )
                  : ``}
              </Form.Item>
              <Form.Item label='Судья'>
                <Input
                  onChange={event => setValue(`judge`, event)}
                  value={values.judge}
                />
              </Form.Item>
              <Form.Item label='Финансовый управляющий'>
                <Input
                  onChange={event => setValue(`financialManager`, event)}
                  value={values.financialManager}
                />
              </Form.Item>
              <Form.Item label='Адрес ФУ'>
                <Input.TextArea
                  onChange={event => setValue(`financialManagerAddress`, event?.target?.value)}
                  rows={3}
                  value={values.financialManagerAddress}
                />
              </Form.Item>
              <Form.Item label='Электронная почта ФУ'>
                <Input
                  onChange={event => setValue(`financialManagerEmail`, event)}
                  value={values.financialManagerEmail}
                />
              </Form.Item>
              <Form.Item label='Контактные данные'>
                <Input
                  onChange={event => setValue(`contacts`, event)}
                  value={values.contacts}
                />
              </Form.Item>
              <Form.Item label='Дата СЗ'>
                <DatePicker
                  onChange={value => setValue(`szDate`, value)}
                  // @ts-ignore
                  value={values.szDate}
                />
              </Form.Item>
              <Form.Item label='Дата включения в реестр'>
                <DatePicker
                  onChange={value => setValue(`registryInclusionDtm`, value)}
                  // @ts-ignore
                  value={values.registryInclusionDtm}
                />
              </Form.Item>
              <Form.Item label='Сумма требований в реестре'>
                <StyledInputNumber
                  formatter={inputNumberFormatter}
                  min={0}
                  // @ts-ignore
                  onChange={event => setValue(`registryClaimAmount`, event)}
                  parser={inputNumberParser}
                  placeholder='Сумма, ₽'
                  precision={2}
                  value={values.registryClaimAmount}
                />
              </Form.Item>
              <Form.Item label='Комментарий'>
                <Input.TextArea
                  onChange={event => setValue(`mainInformationComment`, event?.target?.value)}
                  rows={3}
                  value={values.mainInformationComment}
                />
              </Form.Item>
              <Form.Item label='Полученная сумма'>
                <StyledInputNumber
                  formatter={inputNumberFormatter}
                  min={0}
                  // @ts-ignore
                  onChange={event => setValue(`receivedAmount`, event)}
                  parser={inputNumberParser}
                  placeholder='Сумма, ₽'
                  precision={2}
                  value={values.receivedAmount}
                />
              </Form.Item>
              <Form.Item label='Дата завершения процедуры банкротства'>
                <DatePicker
                  onChange={value => setValue(`bankruptEndDtm`, value)}
                  // @ts-ignore
                  value={values.bankruptEndDtm}
                />
              </Form.Item>
            </Padding>
          </Collapse.Panel>
          <Collapse.Panel header='Наличие залога' key='pledge'>
            <Padding>
              <Form.Item label='Наличие залога'>
                <Select
                  fieldNames={{
                    label: `status`,
                    value: `id`,
                  }}
                  onChange={value => setValue(`pledgeStatusId`, value)}
                  options={dictionaries.pledgeStatus}
                  value={values.pledgeStatusId}
                />
              </Form.Item>
            </Padding>
          </Collapse.Panel>
          <Collapse.Panel header='Торги' key='auction'>
            <Padding>

              <Form.Item label='Статус торгов'>
                <Select
                  fieldNames={{
                    label: `status`,
                    value: `id`,
                  }}
                  onChange={value => setValue(`auctionStatusId`, value)}
                  options={dictionaries.auctionStatus}
                  value={values.auctionStatusId}
                />
              </Form.Item>
              <Form.Item label='Оценка ТС'>
                <StyledInputNumber
                  formatter={inputNumberFormatter}
                  min={0}
                  // @ts-ignore
                  onChange={event => setValue(`carEstimateCost`, event)}
                  parser={inputNumberParser}
                  placeholder='Сумма, ₽'
                  precision={2}
                  value={values.carEstimateCost}
                />
              </Form.Item>
              <Form.Item label='Первый этап торгов'>
                <DatePicker
                  onChange={value => setValue(`auctionFirstStageDtm`, value)}
                    // @ts-ignore
                  value={values.auctionFirstStageDtm}
                />
              </Form.Item>
              <Form.Item label='Второй этап торгов'>
                <DatePicker
                  onChange={value => setValue(`auctionSecondStageDtm`, value)}
                    // @ts-ignore
                  value={values.auctionSecondStageDtm}
                />
              </Form.Item>
              <Form.Item label='Третий этап торгов'>
                <DatePicker
                  onChange={value => setValue(`auctionThirdStageDtm`, value)}
                    // @ts-ignore
                  value={values.auctionThirdStageDtm}
                />
              </Form.Item>
              <Form.Item label='Принятие ТС на баланс'>
                <DatePicker
                  onChange={value => setValue(`balanceCarAcceptingDtm`, value)}
                    // @ts-ignore
                  value={values.balanceCarAcceptingDtm}
                />
              </Form.Item>
              <Form.Item label='Балансовая стоимость ТС'>
                <StyledInputNumber
                  formatter={inputNumberFormatter}
                  min={0}
                  // @ts-ignore
                  onChange={event => setValue(`balanceCarCost`, event)}
                  parser={inputNumberParser}
                  placeholder='Сумма, ₽'
                  precision={2}
                  value={values.balanceCarCost}
                />
              </Form.Item>
            </Padding>
          </Collapse.Panel>
          <Collapse.Panel header='Дополнительная информация' key='additionalInfo'>
            <Padding>

              <Form.Item label='Дата поступления в МФО'>
                <DatePicker
                  onChange={value => setValue(`mfoInDtm`, value)}
                    // @ts-ignore
                  value={values.mfoInDtm}
                />
              </Form.Item>
              <Form.Item label='Дата РТК'>
                <DatePicker
                  onChange={value => setValue(`rtkDtm`, value)}
                    // @ts-ignore
                  value={values.rtkDtm}
                />
              </Form.Item>
              <Form.Item label='Дата Коммерсант'>
                <DatePicker
                  onChange={value => setValue(`commersantDtm`, value)}
                    // @ts-ignore
                  value={values.commersantDtm}
                />
              </Form.Item>
              <Form.Item label='Дата отправки в суд или в Тактикум'>
                <DatePicker
                  onChange={value => setValue(`courtTaktikumRtkDtm`, value)}
                    // @ts-ignore
                  value={values.courtTaktikumRtkDtm}
                />
              </Form.Item>
              <Form.Item label='Комментарий'>
                <Input.TextArea
                  onChange={event => setValue(`comment`, event?.target?.value)}
                  rows={3}
                  value={values.comment}
                />
              </Form.Item>
            </Padding>
          </Collapse.Panel>
        </Collapse>
        {/* <Form.Item label=''> */}
        {/*  <Checkbox */}
        {/*    onChange={event => setValue(`parkingFlg`, event.target.checked)} */}
        {/*    value={values.parkingFlg} */}
        {/*  > */}
        {/*    Авто на стоянке */}
        {/*  </Checkbox> */}
        {/* </Form.Item> */}
      </Form>
    </StyledModal>
  );
};

export default BankruptModal;
