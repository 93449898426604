import _ from 'lodash';
import { AxiosResponse } from 'axios';
import qs from 'query-string';

import api from 'api/index';
import { parseResponse } from 'api/helpers';
import MULTIPART_CONFIG from 'constants/MULTIPART_CONFIG';

import {
  CourtResolutionData,
  CreateRegistryArgs,
  DeadData,
  ICarImplementation,
  ICreateDocumentData,
  IFedresursItem,
  IGenerateDocumentPackageRequest,
  IGenerateRepaymentLinkData,
  IGetDocumentsRequest,
  ILoan,
  ILoanDetail,
  IMarketingBlacklist,
  IMarketingBlacklistCreate,
  IMarketingBlacklistUpdate,
  IMarketingOffer,
  IOffer,
  IRecent,
  ISMS,
  ITrigger,
} from './types';

export const cancelInsurance = (insuranceId: any) => api.post(`/proxy/loan/insurance/cancel/${insuranceId}`);

export const closeApplication = (applicationId:string):Promise<AxiosResponse> => api.post(
  `/proxy/application/close/${applicationId}`,
);

export const closeLoan = (loanId: any, data = {}) => api.post(`/proxy/loan/close/${loanId}`, {
  ...data,
  closeSource: `crm`,
});

export const closeSignDocument = (id: any) => api.post(`/proxy/dfm/close/${id}`);
export const finalizeSignDocument = ({ documentId, personId, sms = false }: { documentId: any, personId: any, sms?: boolean }) => api.post(`/proxy/dfm/finalize-draft`, {
  documentListId: documentId,
  personId,
  sms,
});

export const getRecent = ():Promise<AxiosResponse<IRecent[]>> => api.get(`/recent`);

export const searchClients = (search: any) => api.post(`/client/search`, search);

export const createClientPhone = (personId: any, data: any) => api.post(`/proxy/person/${personId}/phone`, data);

export const createClientActiveEmployment = (personId: any, data: any) => api.post(`/proxy/person/${personId}/employment`, data);

export const createClientDocument = (data: ICreateDocumentData) => api.post(`/proxy/dfm/create`, data);

export const generateRepaymentLink = (
  loanId: string,
  data: IGenerateRepaymentLinkData,
) => api.post(`/proxy/operation/repayment/create/${loanId}`, data);

export const getClient = (personId: any) => api.get(`/client/person/${personId}`);

export const getClientAddressGeolocation = (applicationId: any) => api.get(`/proxy/person/address-geo/${applicationId}`);

export const getClientApplications = (personId: string):Promise<AxiosResponse> => api.get(
  `/application/person/${personId}`,
);

export const getClientVerificationResults = (personId: string):Promise<AxiosResponse> => api.get(
  `/verification/person/${personId}/results`,
);

export const getClientApplicationGeolocation = (applicationId: any) => api.get(
  `/proxy/application/geolocation/${applicationId}`,
);

export const getClientCalibriChats = (data: any) => api.post(`/proxy/calibri/chat-client`, data);

export const getClientCalibriChatHistory = (chatId: any) => api.get(`/proxy/calibri/chat-history/${chatId}`);

export const getClientCarInfo = (vin: any) => api.get(`/proxy/f/au/crm-check/vin/${vin}`, { // CHECK
  // @ts-ignore
  transformResponse: _.concat(api.defaults.transformResponse, data => ({
    ...data,
    vehicleBodyNumber   : data.bodyNumber || null,
    vehicleChassisNumber: data.chassisNumber || null,
  })),
});

export const getClientCarService = (data: any) => api.post(`/proxy/f/au/service`, data);

export const getClientCars = (personId: any) => api.get(`/proxy/auto/search?personIds=${personId}`);

export const getClientCarByApplicationId = (applicationId: any) => api.get(
  `/proxy/auto/search?applicationId=${applicationId}`,
);

export const getClientInfo = (personId: string) => api.get(`/proxy/person/${personId}`);

export const getClientPhones = (personId: any) => api.get(`/proxy/person/${personId}/phone`);

export const getClientSignDocuments = (personId: any) => api.get(`/proxy/dfm/document-list/${personId}`);

export const getClientSms = (personId:string):Promise<AxiosResponse<ISMS[]>> => api.get( // CHECK
  `/proxy/f/sms/person/${personId}`,
);

export const switchProfile = (personId: any, accessFlg: any) => api.post(
  `/proxy/person/update/lk-access/${personId}`,
  { accessFlg },
);

export const updateClientCommunicationType = (personId: any, communicationTypeId: any) => api.patch(
  `/communicationType/person/${personId}/communicationType/${communicationTypeId}`,
);

export const getClientDebts = (personId:string, loanIds:string[]):Promise<AxiosResponse<ILoanDetail[]>> => api.post( // CHECK
  `/proxy/abs-connector/loan/debt-balances-request`,
  { loanIds },
);

export const getDoc = (url:string) => api.get(url, { responseType: `arraybuffer` });

export const getDocsByApplication = (personId: string, applicationId:string) => api.get(
  `/document/person/${personId}/application/${applicationId}`,
);

export const getOffersByApplication = (applicationId:string):Promise<AxiosResponse<IOffer[]>> => api.get(
  `/proxy/application/offer/${applicationId}`,
);

export const getClientCreditHistory = (applicationId: any) => api.get(`/application/${applicationId}/credit-history`);

export const getClientLoanDetails = (loanId: any, pdp: any, requestDtm: any) => api.get(`/proxy/loan/details/${loanId}${pdp
  ? `?requestDtm=${requestDtm}` : ``}`);

export const getClientLoans = (personId: string): Promise<AxiosResponse<ILoan[]>> => api.get(
  `/loan/person/${personId}`,
);

export const getClientLoanAgreement = ({
                                         loanId,
                                         ...data
                                       }: { loanId: string }) => api({
  url         : `/proxy/document/loan-certificate/${loanId}/agreement`,
  data,
  responseType: `blob`,
  method      : `post`,
});

export const getApplication = (applicationId: string) => api.get(`/proxy/loan/application/${applicationId}`);

export const getApplicationVector = (applicationId: string) => api.get(
  `/proxy/application/v1/${applicationId}`,
);

export const getClientLoanDocument = (type: string, loanId:string, fileName:string) => api.post(
  `/loan/${loanId}/documentType/${type}`,
  { fileName },
  { responseType: `blob` },
);

export const getClientLoanInsurance = (personId: any) => api.get(
  `/proxy/loan/insurance/person/${personId}`,
);

export const getClientLoanRepaymentLinks = (loanId: string) => api.get(
  `/proxy/operation/repayment/info/form-url/${loanId}`,
);

// interface PaymentDetail {
//     sum: number;
//     type: string;
//     absPaymentType: string;
//     paymentForm: string;
// }
// interface Payment {
//     number: number;
//     paymentId: string;
//     paymentDtm: number;
//     totalSum: number;
//     details: PaymentDetail[];
// }
// interface DataInterface {
//     result: string;
//     payments: Payment[];
// }

export const getClientLoanRepayments = (loanId: any) => api.get(`/proxy/loan/payments/${loanId}`);

export const getClientLoanSchedule = (loanId: any) => api.get(`/loan/${loanId}/schedule`);

export const getClientMarketingOffers = (data: any) => api.post(`/proxy/f/marketing-offer/search`, data);

export const reopenApplication = (applicationId: string):Promise<AxiosResponse> => api.get(
  `/proxy/application/open/${applicationId}`,
);

export const repairApplication = (applicationId:string):Promise<AxiosResponse> => api.post(
  `/proxy/application/repeat/${applicationId}`,
);

export const resetSms = (personId: any) => api.get(`/proxy/auth/reset-sms/${personId}`);

export const sendApplicationToCall = (applicationId:string, call:number):Promise<AxiosResponse> => api.post(
  `/application/${applicationId}/call/${call}`,
);

export const closeClientMarketingOffer = ({ offerId }: { offerId: string }) => api.post(
  `/proxy/application/marketing-offer/decline/${offerId}`,
);

export const sendClientMarketingOfferDecision = ({
                                                   chosenFlg,
                                                   offerId,
                                                 }: { chosenFlg: boolean; offerId: string }) => api.post(`/proxy/application/marketing-offer/decision`, { chosenFlg, offerId });

export const sendSms = ({ personId, text }: { personId: string; text: string }) => api.post(`/client/person/${personId}/sms`, { text });

export const updateClientAddress = (data: any) => api.post(`/proxy/person/address`, data);

export const updateCar = (carInfo: any) => api.post(`/proxy/auto/update?overwriteFlg=true`, carInfo);

export const updateClientCondition = ({ changeDtm, clientConditionId, operatorName, personId }: { changeDtm: string; clientConditionId: string; operatorName: string; personId: string }) => api.post(
  `/proxy/hm/condition/change-client`,
  {
    operatorName,
    clientConditionId,
    personId,
    changeDtm,
  },
);

export const updateClientGibddService = (vin: any) => api.post(`/proxy/f/au/service/gibdd/${vin}`);

export const updateClientInfo = (personId: string, data: any) => api.post(
  `/proxy/f/p/update/${personId}`,
  { ...data, editReasonId: 0 },
);

export const updateClientPhone = (phoneId: any, phone: any) => api.post(`/proxy/person/phone/${phoneId}`, { phone });

export const updateClientReestrService = (vin: any) => api.post(`/proxy/f/au/service/reestr/${vin}`);

export const updateClientRsaServiceVin = (vin: any) => api.post(`/proxy/f/au/service/rsa/vin/${vin}`);

export const updateClientRsaServiceCarNumber = (carNumber: any) => api.post(`/proxy/f/au/service/rsa/${carNumber}`);

export const updateContractCondition = ({
                                          changeDtm,
                                          contractConditionId,
                                          loanId,
                                          operatorName,
                                          personId,
                                        }: { changeDtm: string; contractConditionId: string; loanId: string; operatorName: string; personId: string }) => api.post(
  `/proxy/hm/condition/change-contract`,
  {
    changeDtm,
    contractConditionId,
    loanId,
    operatorName,
    personId,
  },
);

export const uploadApplicationDocument = (
  personId: string,
  applicationId: string,
  documentTypeId:number,
  file:File,
  replace?:boolean,
) => {
  const formData = new FormData();
  formData.append(`file`, file);
  return api.post(
    `/document/person/${personId}/application/${applicationId}/documentType/${documentTypeId}/upload?replace=${!!replace}`, // eslint-disable-line max-len
    formData,
    {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    },
  );
};

export const getApplicationOperation = (applicationId: any) => api.get(`/proxy/operation/${applicationId}`);
export const getClientVerifications = (personId: any) => api.get(`/verification/person/${personId}`);

export const getLegalRegistries = (query: { loanId?: string, personId?: string, startDtm?: number }) => (
  api.get(`/proxy/hm/registry?${qs.stringify(query)}`)
);

export const createLegalRegistry = (query: CreateRegistryArgs) => api.post(`/proxy/hm/registry/create`, query);

export const getDead = (personId: string) => api.get(`/proxy/hm/dead?personId=${personId}`);

export const createDead = (query: DeadData) => api.post(`/proxy/hm/dead/create`, query);

export const updateDead = (deadId: string, query: DeadData) => api.post(
  `/proxy/hm/dead/update/${deadId}`,
  query,
);

export const getCourtResolutions = (personId: string) => api.get(`/proxy/hm/resolution?personId=${personId}`);

export const createCourtResolution = (query: CourtResolutionData) => api.post(`/proxy/hm/resolution/create`, query);

export const updateCourtResolution = (deadId: string, query: CourtResolutionData) => api.post(
  `/proxy/hm/resolution/update/${deadId}`,
  query,
);

export const getClientAuthCalls = (personId: string) => api.get(`/proxy/call/person/${personId}`);

export const getTriggers = (personId: string) => api.get(
  `/proxy/collection/orchestrator/triggers?personId=${personId}`,
);

export const createTrigger = (query: ITrigger) => api.post(`/proxy/collection/orchestrator/triggers/create`, query);

export const updateTrigger = (triggerId: string, query: ITrigger) => api.post(
  `/proxy/collection/orchestrator/triggers/update/${triggerId}`,
  query,
);

export const getDocumentsArchive = (docs:IGetDocumentsRequest[], name:string) => api.post(
  `/document/archive`,
  { docs, name },
  { responseType: `blob` },
);

export const getDocumentsPdf = (docs:IGetDocumentsRequest[], name:string) => api.post(
  `/document/pdf`,
  { docs, name },
  { responseType: `blob` },
);

export const generateDocumentPackage = (request:IGenerateDocumentPackageRequest):Promise<AxiosResponse<Blob>> => api.post( // eslint-disable-line max-len
  `/document/package`,
  request,
  { responseType: `blob` },
);

export const getCommunicationCallRecord = (communicationId: number) => api.get(
  `/communication/${communicationId}/call-record`,
  { responseType: `blob`, validateStatus: () => true },
);

export const getClientLoanOperations = (loanId: string) => api.post(
  `/proxy/operation/search`,
  {
    includeDetails: true,
    loanIds       : [loanId],
  },
);

export const getFedResursMessages = (personId: string):Promise<AxiosResponse<IFedresursItem[]>> => api.get(
  `/proxy/f/bankrupt/message/person/${personId}`,
);

export const createMarketingOffer = (data: IMarketingOffer):Promise<AxiosResponse> => api.post(
  `/proxy/application/marketing-offer/create`,
  data,
);

export const uploadMarketingOffers = (file: File):Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append(`file`, file, file.name);

  return api.post(`/proxy/application/marketing-offer/create/file`, formData, MULTIPART_CONFIG);
};

export const searchMarketingBlacklist = (personId: string):Promise<AxiosResponse<IMarketingBlacklist[]>> => api.post(
  `/proxy/blacklist/marketing/search`,
  { personId },
);

export const postMarketingBlacklist = (data: IMarketingBlacklistCreate | IMarketingBlacklistUpdate):Promise<AxiosResponse> => { // eslint-disable-line max-len
  const route = _.has(data, `marketingBlacklistId`) ? `update` : `create`;
  return api.post(
    `/proxy/blacklist/marketing/${route}`,
    data,
  );
};

export const getPersonIdByPhone = async (phone: string): Promise<string | null> => {
  if (!phone) return null;
  try {
    return parseResponse({
      dataPath : `data.personId`,
      response : await api.post(`/proxy/person/phone-exists-check`, { phone }),
      normalize: personId => personId || null,
    });
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    return null;
  }
};

export const createCarImplementation = (data: ICarImplementation):Promise<AxiosResponse> => api.post(
  `/proxy/auto/implementation/create`,
  data,
);
