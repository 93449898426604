import * as React from 'react';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { formatDate } from 'helper';

const Wrapper = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  transition: 200ms ease;

  color: ${palette.black60Color};
`;

interface IComponentProps {
  className?: string;
  createdAt: string;
}

export const MessageTime: React.FC<IComponentProps> = ({ className, createdAt }) => {
  const format = `HH:mm`;

  const time = formatDate(new Date(createdAt), format);

  return (
    <Wrapper className={className}>
      {time}
    </Wrapper>
  );
};
