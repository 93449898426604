/* eslint-disable max-len */
export default {
  ADMIN_MENU_ACTIONS_ITEM                                     : `ADMIN_MENU_ACTIONS_ITEM`,
  ADMIN_MENU_DICTIONARY_ITEM                                  : `ADMIN_MENU_DICTIONARY_ITEM`,
  ADMIN_MENU_EMPLOYEES_ITEM                                   : `ADMIN_MENU_EMPLOYEES_ITEM`,
  ADMIN_MENU_ROLES_ITEM                                       : `ADMIN_MENU_ROLES_ITEM`,
  ADMIN_MENU_ROLE_GROUPS_ITEM                                 : `ADMIN_MENU_ROLE_GROUPS_ITEM`,
  DICTIONARIES_CESSIONARIES_MENU_ITEM                         : `DICTIONARIES_CESSIONARIES_MENU_ITEM`,
  DICTIONARIES_DEALERSHIP_MENU_ITEM                           : `DICTIONARIES_DEALERSHIP_MENU_ITEM`,
  DICTIONARIES_MAILING_LIST_MENU_ITEM                         : `DICTIONARIES_MAILING_LIST_MENU_ITEM`,
  DICTIONARIES_MARKETING_BLACKLIST_TYPE                       : `DICTIONARIES_MARKETING_BLACKLIST_TYPE`,
  DICTIONARIES_MARKETING_OFFER_TYPE                           : `DICTIONARIES_MARKETING_OFFER_TYPE`,
  DICTIONARIES_PARTNERS_MENU_ITEM                             : `DICTIONARIES_PARTNERS_MENU_ITEM`,
  DICTIONARIES_PARTNER_RATE_MENU_ITEM                         : `DICTIONARIES_PARTNER_RATE_MENU_ITEM`,
  DICTIONARIES_PARTNER_REQUISITES_MENU_ITEM                   : `DICTIONARIES_PARTNER_REQUISITES_MENU_ITEM`,
  DICTIONARIES_SMS_TEMPLATE                                   : `DICTIONARIES_SMS_TEMPLATE`,
  DICTIONARY_ADD_CANCEL_BUTTON                                : `DICTIONARY_ADD_CANCEL_BUTTON`,
  DICTIONARY_ADD_CREATE_ANOTHER_BUTTON                        : `DICTIONARY_ADD_CREATE_ANOTHER_BUTTON`,
  DICTIONARY_ADD_SAVE_BUTTON                                  : `DICTIONARY_ADD_SAVE_BUTTON`,
  DICTIONARY_CESSIONARY_ADD_BUTTON                            : `DICTIONARY_CESSIONARY_ADD_BUTTON`,
  DICTIONARY_CESSIONARY_DESCRIPTION_FIELD                     : `DICTIONARY_CESSIONARY_DESCRIPTION_FIELD`,
  DICTIONARY_CESSIONARY_NAME_FIELD                            : `DICTIONARY_CESSIONARY_NAME_FIELD`,
  DICTIONARY_CESSIONARY_SEARCH_BUTTON                         : `DICTIONARY_CESSIONARY_SEARCH_BUTTON`,
  DICTIONARY_CESSIONARY_SEARCH_FIELD                          : `DICTIONARY_CESSIONARY_SEARCH_FIELD`,
  DICTIONARY_CESSIONARY_SEARCH_REFRESH_BUTTON                 : `DICTIONARY_CESSIONARY_SEARCH_REFRESH_BUTTON`,
  DICTIONARY_COMMON_FIELD_DESCRIPTION                         : `DICTIONARY_COMMON_FIELD_DESCRIPTION`,
  DICTIONARY_COMMON_FIELD_NAME                                : `DICTIONARY_COMMON_FIELD_NAME`,
  DICTIONARY_DEALERSHIP_ADD_ADDRESS_FIELD                     : `DICTIONARY_DEALERSHIP_ADD_ADDRESS_FIELD`,
  DICTIONARY_DEALERSHIP_ADD_BUTTON                            : `DICTIONARY_DEALERSHIP_ADD_BUTTON`,
  DICTIONARY_DEALERSHIP_ADD_CITY_FIELD                        : `DICTIONARY_DEALERSHIP_ADD_CITY_FIELD`,
  DICTIONARY_DEALERSHIP_ADD_HIDDEN_FIELD                      : `DICTIONARY_DEALERSHIP_ADD_HIDDEN_FIELD`,
  DICTIONARY_DEALERSHIP_ADD_LATITUDE_FIELD                    : `DICTIONARY_DEALERSHIP_ADD_LATITUDE_FIELD`,
  DICTIONARY_DEALERSHIP_ADD_LONGITUDE_FIELD                   : `DICTIONARY_DEALERSHIP_ADD_LONGITUDE_FIELD`,
  DICTIONARY_DEALERSHIP_ADD_NAME_FIELD                        : `DICTIONARY_DEALERSHIP_ADD_NAME_FIELD`,
  DICTIONARY_DEALERSHIP_ADD_PARTNER_FIELD                     : `DICTIONARY_DEALERSHIP_ADD_PARTNER_FIELD`,
  DICTIONARY_DEALERSHIP_ADD_UTM_FIELD                         : `DICTIONARY_DEALERSHIP_ADD_UTM_FIELD`,
  DICTIONARY_DEALERSHIP_SEARCH_BUTTON                         : `DICTIONARY_DEALERSHIP_SEARCH_BUTTON`,
  DICTIONARY_DEALERSHIP_SEARCH_FIELD                          : `DICTIONARY_DEALERSHIP_SEARCH_FIELD`,
  DICTIONARY_DEALERSHIP_SEARCH_REFRESH_BUTTON                 : `DICTIONARY_DEALERSHIP_SEARCH_REFRESH_BUTTON`,
  DICTIONARY_NOTIFICATION_LIST_ADD_BUTTON                     : `DICTIONARY_NOTIFICATION_LIST_ADD_BUTTON`,
  DICTIONARY_NOTIFICATION_LIST_INDIVIDUAL_FIELD               : `DICTIONARY_NOTIFICATION_LIST_INDIVIDUAL_FIELD`,
  DICTIONARY_NOTIFICATION_LIST_SEARCH_BUTTON                  : `DICTIONARY_NOTIFICATION_LIST_SEARCH_BUTTON`,
  DICTIONARY_NOTIFICATION_LIST_SEARCH_FIELD                   : `DICTIONARY_NOTIFICATION_LIST_SEARCH_FIELD`,
  DICTIONARY_NOTIFICATION_LIST_SEARCH_REFRESH_BUTTON          : `DICTIONARY_NOTIFICATION_LIST_SEARCH_REFRESH_BUTTON`,
  DICTIONARY_PARTNER_ADD_BUTTON                               : `DICTIONARY_PARTNER_ADD_BUTTON`,
  DICTIONARY_PARTNER_ADD_NAME_FIELD                           : `DICTIONARY_PARTNER_ADD_NAME_FIELD`,
  DICTIONARY_PARTNER_ID_COLUMN                                : `DICTIONARY_PARTNER_ID_COLUMN`,
  DICTIONARY_PARTNER_NAME_COLUMN                              : `DICTIONARY_PARTNER_NAME_COLUMN`,
  DICTIONARY_PARTNER_RATE_ADD_BUTTON                          : `DICTIONARY_PARTNER_RATE_ADD_BUTTON`,
  DICTIONARY_PARTNER_RATE_DATE_END_FIELD                      : `DICTIONARY_PARTNER_RATE_DATE_END_FIELD`,
  DICTIONARY_PARTNER_RATE_DATE_START_FIELD                    : `DICTIONARY_PARTNER_RATE_DATE_START_FIELD`,
  DICTIONARY_PARTNER_RATE_MAX_RATE_FIELD                      : `DICTIONARY_PARTNER_RATE_MAX_RATE_FIELD`,
  DICTIONARY_PARTNER_RATE_MIN_RATE_FIELD                      : `DICTIONARY_PARTNER_RATE_MIN_RATE_FIELD`,
  DICTIONARY_PARTNER_RATE_PARTNER_FIELD                       : `DICTIONARY_PARTNER_RATE_PARTNER_FIELD`,
  DICTIONARY_PARTNER_RATE_SEARCH_BUTTON                       : `DICTIONARY_PARTNER_RATE_SEARCH_BUTTON`,
  DICTIONARY_PARTNER_RATE_SEARCH_FIELD                        : `DICTIONARY_PARTNER_RATE_SEARCH_FIELD`,
  DICTIONARY_PARTNER_RATE_SEARCH_REFRESH_BUTTON               : `DICTIONARY_PARTNER_RATE_SEARCH_REFRESH_BUTTON`,
  DICTIONARY_PARTNER_RATE_SUM_FIELD                           : `DICTIONARY_PARTNER_RATE_SUM_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_ADD_ABS_ID_FIELD               : `DICTIONARY_PARTNER_REQUISITE_ADD_ABS_ID_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_ADD_ACCOUNT_NUMBER_FIELD       : `DICTIONARY_PARTNER_REQUISITE_ADD_ACCOUNT_NUMBER_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_ADD_BANK_NAME_FIELD            : `DICTIONARY_PARTNER_REQUISITE_ADD_BANK_NAME_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_ADD_BIK_FIELD                  : `DICTIONARY_PARTNER_REQUISITE_ADD_BIK_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_ADD_BUTTON                     : `DICTIONARY_PARTNER_REQUISITE_ADD_BUTTON`,
  DICTIONARY_PARTNER_REQUISITE_ADD_COMPANY_NAME_FIELD         : `DICTIONARY_PARTNER_REQUISITE_ADD_COMPANY_NAME_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_ADD_CORRESPONDENT_ACCOUNT_FIELD: `DICTIONARY_PARTNER_REQUISITE_ADD_CORRESPONDENT_ACCOUNT_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_ADD_DESCRIPTION_FIELD          : `DICTIONARY_PARTNER_REQUISITE_ADD_DESCRIPTION_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_ADD_INN_FIELD                  : `DICTIONARY_PARTNER_REQUISITE_ADD_INN_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_ADD_TYPE_FIELD                 : `DICTIONARY_PARTNER_REQUISITE_ADD_TYPE_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_SEARCH_BUTTON                  : `DICTIONARY_PARTNER_REQUISITE_SEARCH_BUTTON`,
  DICTIONARY_PARTNER_REQUISITE_SEARCH_FIELD                   : `DICTIONARY_PARTNER_REQUISITE_SEARCH_FIELD`,
  DICTIONARY_PARTNER_REQUISITE_SEARCH_REFRESH_BUTTON          : `DICTIONARY_PARTNER_REQUISITE_SEARCH_REFRESH_BUTTON`,
  DICTIONARY_PARTNER_SEARCH_BUTTON                            : `DICTIONARY_PARTNER_SEARCH_BUTTON`,
  DICTIONARY_PARTNER_SEARCH_FIELD                             : `DICTIONARY_PARTNER_SEARCH_FIELD`,
  DICTIONARY_PARTNER_SEARCH_REFRESH_BUTTON                    : `DICTIONARY_PARTNER_SEARCH_REFRESH_BUTTON`,
  NAV_BAR_ADMIN_TAB                                           : `NAV_BAR_ADMIN_TAB`,
  NAV_BAR_BANKRUPT_TAB                                        : `NAV_BAR_BANKRUPT_TAB`,
  NAV_BAR_CLIENTS_TAB                                         : `NAV_BAR_CLIENTS_TAB`,
  NAV_BAR_COURT_TAB                                           : `NAV_BAR_COURT_TAB`,
  NAV_BAR_INFO_TAB                                            : `NAV_BAR_INFO_TAB`,
  NAV_BAR_SALE_TAB                                            : `NAV_BAR_SALE_TAB`,
  NAV_BAR_TASKS_TAB                                           : `NAV_BAR_TASKS_TAB`,
  NAV_BAR_VERIFICATION_TAB                                    : `NAV_BAR_VERIFICATION_TAB`,
  NAV_BAR_LEADS_TAB                                           : `NAV_BAR_LEADS_TAB`,

};
