import React from 'react';

export const VECTOR_WEBDATA_NAMES = {
  passportSelfyIsMoving   : `Движение устройства (Селфи)`,
  passportSelfyCameraNames: `Доступные на устройстве камеры (Селфи)`,
  passportSelfyPermission : `Разрешение на считывание движения iOS (Селфи)`,
  carRightFrontIsMoving   : `Движение устройства (Авто)`,
  carRightFrontCameraNames: `Доступные на устройстве камеры (Авто)`,
  carRightFrontPermission : `Разрешение на считывание движения iOS (Авто)`,
  carVINIsMoving          : `Движение устройства (VIN)`,
  carVINCameraNames       : `Доступные на устройстве камеры (VIN)`,
  carVINPermission        : `Разрешение на считывание движения iOS (VIN)`,
};

export const VECTOR_WEBDATA_NOTE = (
  <>
    <b>1. Движение устройства = true</b> - не фрод{`\n\n`}
    <b>2. Пример отражения камер устройств:</b>{`\n`}
    <b>iPhone:</b>{` "Камера на передней панели; Камера на задней панели"\n`}
    <b>
      Android:
    </b>{` "camera2 1, facing front; camera2 3, facing front; camera2 2, facing back; camera2 0, facing back"\n`}
    <b>
      Ноутбук с фрод приложением:
    </b>{` "SplitCam Virtual Camera (m/vi:deo0); HD-камера FaceTime (встроенная) (05ac:8514)"\n`}
    <b>Ноутбук без фрод приложения:</b>{` "USB Camera (30c9:0041)"\n\n`}
    <b>
      3. Разрешение на считывание движения iOS
    </b>{`  - если false, тогда "движение устройства" тоже будет false, т.к. клиент не дал разрешение`}
  </>
);
