import _ from 'lodash';

import { formatDate } from 'helper';

import {
  IFedresursItem,
  IFedresursMessageLot,
} from 'models/clients/types';

const FEDRESURS_MESSAGE_FIELDS = [
  {
    label: `Дата публикации`,
    path : `fedresursMessage.datePublished`,
    copy : true,
  },
  {
    label: `Номер дела`,
    path : `fedresursMessage.caseNum`,
    copy : true,
  },
  {
    label: `Текст сообщения`,
    path : `fedresursMessage.text`,
  },
  {
    label: `Тип торгов`,
    path : `fedresursMessage.tradeType`,
  },
  {
    label: `Место торгов`,
    path : `fedresursMessage.tradePlace`,
    copy : true,
  },
  {
    label: `Дата старта торгов`,
    path : `fedresursMessage.tradeAppStartDate`,
    copy : true,
  },
  {
    label: `Дата окончания торгов`,
    path : `fedresursMessage.tradeAppEndDate`,
    copy : true,
  },
];

const LOT_FIELDS = [
  {
    label: `Описание`,
    path : `description`,
    copy : true,
  },
  {
    label: `Стартовая цена`,
    path : `startPrice`,
    copy : true,
  },
  {
    label: `Шаг`,
    path : `step`,
    copy : true,
  },
  {
    label: `Депозит`,
    path : `deposit`,
    copy : true,
  },
  {
    label: `Тип`,
    path : `type`,
    copy : true,
  },
];

export const FIELDS_VIEW = [{
  path       : ``,
  itemsHeader: (item: IFedresursItem) => (
    `${item?.datePublished ? `${formatDate(item.datePublished, `dd.MM.yyyy`)} - ` : ``}${item?.type}`
  ),
  items: [
    ...FEDRESURS_MESSAGE_FIELDS,
    {
      label      : `Лоты`,
      path       : `fedresursMessage.lots`,
      itemsHeader: (lot: IFedresursMessageLot) => `Лот №${_.get(lot, `num`, ``)}`,
      items      : LOT_FIELDS,
      shouldCopy : true,
    },
  ],
}];
