import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import moment, { Moment } from 'moment/moment';
import styled from 'styled-components';

import {
  inputNumberFormatter,
  inputNumberParser,
  normalizeMomentToStartOfDay,
} from 'helper';

import { createCarImplementation } from 'models/clients/api';
import {
  ICar,
  ICarImplementation,
} from 'models/clients/types';
import { getFullName } from 'models/user/selectors';

import {
  DatePicker,
  Form,
  InputNumber,
  Modal,
  notification,
  Select,
} from 'antd';
import {CarNumberPlate} from "pages/PageClient/Single/components/CarNumberPlate/CarNumberPlate";

const StyledInputNumber = styled(InputNumber)`width: 200px;`;

interface IProps {
  car: ICar,
  isVisible: boolean,
  onClose():void,
}

type IErrors = {
  type?: string,
};

const defaultValues: ICarImplementation = {
  autoId              : ``,
  balanceAcceptanceDtm: null,
  balanceAcceptanceSum: null,
  createdBy           : ``,
  deletedFlg          : false,
  implementationDtm   : null,
  implementationSum   : null,
  type                : ``,
};

const validateForm = (values: ICarImplementation) => {
  const errors: IErrors = {};
  if (!values.type) errors.type = `Обязательно для заполнения`;
  return errors;
};

const TYPES = [
  {
    label: `Добровольная реализация`,
    value: `Добровольная реализация`,
  },
  {
    label: `Постановка на баланс`,
    value: `Постановка на баланс`,
  },
];

export const CarImplementationModal: React.FC<IProps> = ({ car, isVisible, onClose }) => {
  const operatorFullName = useSelector(getFullName);
  const [values, setValues] = useState<ICarImplementation>(defaultValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(
    () => {
      if (isVisible && car?.id) {
        setValues({
          ...values,
          balanceAcceptanceDtm: normalizeMomentToStartOfDay(moment()),
          createdBy           : operatorFullName,
          deletedFlg          : false,
          autoId              : car.id,
        });
      }
    },
    [car?.id, isVisible],
  );

  const setValue = (field: string, data: ChangeEvent<HTMLInputElement> | Moment | string | boolean) => setValues({
    ...values,
    [field]: !_.isEmpty(data) && _.has(data, `target`) ? (data as ChangeEvent<HTMLInputElement>).target.value : data,
  });

  const handleClose = () => {
    setValues(defaultValues);
    onClose();
  };

  const handleSuccess = () => {
    handleClose();
  };

  const errors = validateForm(values);

  const isDisabled = isLoading || !_.isEmpty(errors);

  const onSubmit = async () => {
    if (isDisabled) return;
    setIsLoading(true);
    try {
      await createCarImplementation({
        ...values,
        implementationDtm: values.implementationDtm ? normalizeMomentToStartOfDay(values.implementationDtm) : null,
      });
      handleSuccess();
    } catch (e) {
      notification.error({
        message    : `Ошибка при сохранении`,
        // @ts-ignore
        description: e,
      });
      return;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      confirmLoading={isLoading}
      okButtonProps={{ disabled: isDisabled }}
      okText='Сохранить'
      onCancel={handleClose}
      onOk={onSubmit}
      open={isVisible}
      title={(
        <span>
          Реализация авто <CarNumberPlate carNumber={car?.carNumber} />
        </span>
      )}
    >
      <Form layout='vertical'>
        <Form.Item label='Сумма принятия на баланс'>
          <StyledInputNumber
            formatter={inputNumberFormatter}
            min={0}
            // @ts-ignore
            onChange={event => setValue(`balanceAcceptanceSum`, event)}
            parser={inputNumberParser}
            placeholder='Сумма, ₽'
            precision={2}
            value={values.balanceAcceptanceSum}
          />
        </Form.Item>
        <Form.Item label='Дата реализации'>
          <DatePicker
            // @ts-ignore
            onChange={value => setValue(`implementationDtm`, value)}
            // @ts-ignore
            value={values.implementationDtm}
          />
        </Form.Item>
        <Form.Item label='Сумма реализации'>
          <StyledInputNumber
            formatter={inputNumberFormatter}
            min={0}
            // @ts-ignore
            onChange={event => setValue(`implementationSum`, event)}
            parser={inputNumberParser}
            placeholder='Сумма, ₽'
            precision={2}
            value={values.implementationSum}
          />
        </Form.Item>
        <Form.Item
          label='Тип'
          required
          // @ts-ignore
          validateStatus={errors.type}
        >
          <Select
            onChange={type => setValue(`type`, type)}
            options={TYPES}
            value={values.type}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
