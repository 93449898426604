import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { Layout } from 'antd';

export const StyledLayout = styled(Layout)`
  padding: 16px;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    padding: 24px 32px;
  }
`;
export const FormTabs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: column;
    margin-bottom: 0;
    gap: 0;
    margin-right: 24px;
    width: 200px;
    min-width: 200px;
    white-space: nowrap;
  }
`;
export const LayoutContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`;
