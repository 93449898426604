import _ from 'lodash';
import ExcelJS from 'exceljs';
import moment from 'moment';

import { parseResponse } from 'api/helpers';
import {
  downloadBlob,
  showError,
} from 'helper';

import { COLUMNS } from 'models/courts/constants';
import { getFullName as getFullNameSelector } from 'models/user/selectors';

import { notification } from 'antd';

import {
  DELETE_ENFORCEMENT_PROCEEDING,
  DOWNLOAD_EXCEL,
  GET,
  model as namespace,
  POST,
  POST_ENFORCEMENT_PROCEEDING,
  RESET_FILTERS,
  SET,
  SET_ITEMS,
  SET_LOADING,
  SET_TABLE_STATE,
} from './actions';
import * as api from './api';
import {
  getItemsByIds,
  getRequestParams,
  getTableState as getTableStateSelector,
} from './selectors';

const initialState = {
  isLoading : false,
  items     : [],
  tableState: {
    filters   : {},
    pagination: {
      defaultCurrent : 1,
      defaultPageSize: 10,
      page           : 1,
      pageSize       : 10,
      pageSizeOptions: [`10`, `25`, `50`],
      showSizeChanger: true,
      size           : `small`,
      total          : 10,
    },
    sorter: {},
  },
};

export default {
  namespace,
  state  : initialState,
  effects: {
    *[POST](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const {
        callback,
        courtId,
        loanId,
        personId,
        ...data
      } = action.payload;
      const defaultError = `При сохранении судебного производства произошла ошибка`;

      try {
        if (courtId) {
          const updateOperatorName = yield select(getFullNameSelector);
          const updateDtm = moment.utc();
          const postData = {
            ...data,
            updateOperatorName,
            updateDtm,
            personId,
          };

          parseResponse({
            defaultError,
            response: yield call(
              api.update,
              courtId,
              postData,
            ),
          });
        } else {
          const operatorName = yield select(getFullNameSelector);
          const createDtm = moment.utc();

          parseResponse({
            defaultError,
            response: yield call(
              api.create,
              {
                ...data,
                loanId,
                operatorName,
                createDtm,
                personId,
              },
            ),
          });
        }

        notification.success({ message: `Судебное производство сохранено` });
        yield put({ type: GET });
        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[DELETE_ENFORCEMENT_PROCEEDING](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { callback, enforcementProceedingsId } = action.payload;
      const defaultError = `При удалении ИП произошла ошибка`;
      if (!enforcementProceedingsId) {
        throw new Error(`${defaultError}: не передан идентификатор ИП`);
      }

      try {
        parseResponse({
          defaultError,
          response: yield call(api.deleteEnforcementProceeding, enforcementProceedingsId),
        });

        notification.success({ message: `ИП удалено` });
        yield put({ type: GET });
        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[POST_ENFORCEMENT_PROCEEDING](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { callback, ...data } = action.payload;
      const defaultError = `При сохранении ИП произошла ошибка`;
      if (_.isEmpty(data)) {
        throw new Error(`${defaultError}: данные не переданы`);
      }

      try {
        console.log({ data });
        parseResponse({
          defaultError,
          response: yield call(
            data.enforcementProceedingsId
              ? api.updateEnforcementProceeding
              : api.createEnforcementProceeding,
            data,
          ),
        });

        notification.success({ message: `ИП сохранено` });
        yield put({ type: GET });
        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке судебных производств произошла ошибка`;
      try {
        const { callback } = action.payload || {};

        const requestParams = yield select(getRequestParams);

        const { data: items, fullCount: total } = parseResponse({
          defaultError,
          response: yield call(api.get, requestParams),
        });

        yield put({ type: SET_ITEMS, payload: items });
        const currentTableState = yield select(getTableStateSelector);
        yield put({ type   : SET_TABLE_STATE,
          payload: {
            ...currentTableState,
            pagination: {
              ...currentTableState.pagination,
              total,
            },
            shouldFetch: false,
          } });

        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[RESET_FILTERS](action, { put, select }) {
      const currentTableState = yield select(getTableStateSelector);
      yield put({
        type   : SET_TABLE_STATE,
        payload: {
          pagination: {
            ...(currentTableState?.pagination || {}),
            current        : 1,
            defaultCurrent : 1,
            page           : 1,
            showSizeChanger: true,
          },
          filters: {},
          sorter : {},
        },
      });
    },

    *[SET_TABLE_STATE](action, { put, select }) {
      const { shouldFetch = true, ...tableState } = action.payload;
      const currentTableState = yield select(getTableStateSelector);
      const isFiltersChanged = !_.isEqual(
        _.omitBy(tableState?.filters, _.isNil),
        _.omitBy(currentTableState?.filters, _.isNil),
      );
      yield put({
        type   : SET,
        payload: {
          tableState: {
            ...tableState,
            pagination: isFiltersChanged
              ? {
                ...tableState.pagination,
                current       : 1,
                defaultCurrent: 1,
                page          : 1,

              }
              : tableState.pagination,
          },
        } });
      if (shouldFetch) yield put({ type: GET });
    },

    *[DOWNLOAD_EXCEL](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При экспорте судебных производств в Excel произошла ошибка`;
      try {
        const { callback, courtIds } = action.payload || {};

        // Получаем элементы из состояния
        const items = yield select(state => getItemsByIds(state, courtIds));

        // Создаем новую книгу Excel
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Суды`);

        const columns = COLUMNS({});

        // Устанавливаем столбцы
        worksheet.columns = _.map(columns, ({ key, title, width }) => ({
          header: title,
          key,
          width : width / 6,
        }));

        // Заполняем книгу данными
        _.each(items, item => {
          worksheet.addRow(_.reduce(item, (result, value, key) => {
            const render = _.find(columns, { key })?.render || _.identity;
            return {
              ...result,
              [key]: render(value),
            };
          }, {}));
        });

        // Преобразуем книгу в blob
        const blob = yield call([workbook.xlsx, `writeBuffer`]);
        const blobObject = new Blob(
          [blob],
          { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` },
        );

        // Скачиваем файл
        downloadBlob(blobObject, `Суды.xlsx`);

        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [SET](state, { payload = {} }) {
      return {
        ...state,
        ...payload,
      };
    },

    [SET_ITEMS](state, { payload: items = [] }) {
      return {
        ...state,
        items,
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
};
