import * as React from 'react';
import { useCallback } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment from 'moment';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { formatDate } from 'helper';

import { getClientLoanRepayments as getClientLoanRepaymentsAction } from 'models/clients/actions';
import {
  getIsLoadingLoanDetails as getIsLoadingLoanDetailsSelector,
  getIsLoadingRepayments as getIsLoadingRepaymentsSelector,
} from 'models/clients/selectors';
import { getItem as getDictionaryItemSelector } from 'models/dictionaries/selectors';

import useMobileContext from 'hooks/useMobileContext';

import {
  EditOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { ApplicationDescription } from 'components/ApplicationDescription/ApplicationDescription';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import { LoanDetails } from 'components/LoanDetails';
import LoanOperations from 'components/LoanOperations';
import { LoanRepayments } from 'components/LoanRepayments';
import LoanSchedule from 'components/LoanSchedule';
import PdpCalculation from 'components/PdpCalculation';
import { IdTagButton } from 'pages/PageClient/Single/Loan/IdTagButton';
import { LoansTableExpandItem } from 'pages/PageClient/Single/Loan/LoansTable/LoansTableExpandItem';
import RepaymentLinkList from 'pages/PageClient/Single/Loan/RepaymentLinkList';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditIcon = styled(EditOutlined)`
  color: ${palette.primaryColor};
`;

const TableExpandButton = styled(Button)`
  align-self: flex-start;
`;

const ContractCondition = styled.div`
`;

const ContractConditionTag = styled(DefaultTag)`
  &.ant-tag {
    border: 1px solid ${palette.borderAccentColor};
  }
  &.ant-tag-default {
    color: ${palette.textSecondaryColor};
  }

  background: transparent;
  cursor: pointer;
  font-weight: 700;
`;

const ReloadButton = styled.button`
  border-radius: 100%;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: none;
  background: ${palette.primary500Color};
  cursor: pointer;

  .anticon {
    color: ${palette.whiteColor};
    font-size:14px;
  }

  @media(min-width: ${breakpoints.md}) {
    width: 26px;
    height: 26px;

    .anticon {
      font-size:16px;
    }
  }
`;

const NoData = styled.div`
  margin-left: 16px;
  margin-right: 16px;

  @media(min-width: ${breakpoints.md}) {
    margin-right: 24px;
    margin-left: 24px;
  }
`;

const Date = styled.div``;

interface IComponentProps {
  loan: any;
  personId: string;
  getDetails(loanId: string): void;
  onCancelInsurance(insuranceId: string): void;
  toggleConditionModal(loanId: string): void;
}

export const LoansTableExpand: React.FC<IComponentProps> = ({
  getDetails,
  loan,
  onCancelInsurance,
  personId,
  toggleConditionModal,
}) => {
  const {
    application,
    contractConditionId,
    details,
    id,
    insurance,
    key: loanId,
    operations,
    repaymentLinks,
    repayments,
    schedule,
  } = loan;

  const dispatch = useDispatch();

  const isDetailsLoading = useSelector(getIsLoadingLoanDetailsSelector);

  const { mobile } = useMobileContext();

  const contractConditionName = _.get(
    useSelector(state => getDictionaryItemSelector(state, `contractCondition`, contractConditionId)),
    `name`,
  );

  const isRepaymentsLoading = useSelector(getIsLoadingRepaymentsSelector);
  const getRepayments = useCallback(
    () => dispatch(getClientLoanRepaymentsAction({ personId, loanId })),
    [personId, dispatch, loanId],
  );

  const onPdpCalc = (requestDtm: moment.Moment | null) => {
    // @ts-ignore
    getDetails({ loanId, pdp: true, requestDtm });
  };

  return (
    <Wrapper>
      <LoansTableExpandItem
        controls={(
          <ContractCondition>
            <ContractConditionTag color='default' onClick={() => toggleConditionModal(loanId)}>
              {contractConditionName || `Не задано`}
            </ContractConditionTag>
            <EditIcon onClick={() => toggleConditionModal(loanId)} />
          </ContractCondition>
        )}
        title='Состояние договора'
      />

      <LoansTableExpandItem
        collapsed={!mobile}
        title={`Займ ${details?.contractNumber || ``}`}
      >
        {/*@ts-ignore*/}
        <LoanDetails details={_.omit(details, [`pdpDate`, `pdpSum`])} operations={operations} />
      </LoansTableExpandItem>

      <LoansTableExpandItem
        controls={(
          <IdTagButton description='id займа' id={id} />
        )}
        title='Расчет суммы полного погашения на дату'
      >
        <PdpCalculation
          calculatedDate={_.get(details, `pdpDate`)}
          isDetailsLoading={isDetailsLoading}
          onPdpCalc={date => onPdpCalc(date)}
          sum={_.get(details, `pdpSum`)}
        />
      </LoansTableExpandItem>

      <LoansTableExpandItem
        controls={(
          <>
            <IdTagButton description='id займа' id={id} />
            <ReloadButton
              onClick={() => getRepayments()}
            >
              {isRepaymentsLoading ? <LoadingOutlined spin /> : <ReloadOutlined />}
            </ReloadButton>
          </>
        )}
        onCollapse={getRepayments}
        title='Погашения по займу'
      >
        <LoanRepayments
          isLoading={isRepaymentsLoading}
          repayments={repayments}
        />
      </LoansTableExpandItem>

      <LoansTableExpandItem title='Попытки оплат'>
        <LoanOperations
          loanId={loanId}
          personId={personId}
        />
      </LoansTableExpandItem>

      <LoansTableExpandItem title='История ссылок на оплату'>
        <RepaymentLinkList repaymentLinks={repaymentLinks} />
      </LoansTableExpandItem>

      <LoansTableExpandItem
        controls={(
          <>
            <IdTagButton description='id заявки' id={application?.id} />
            <Date>
              от {formatDate(application?.applicationDtm, `dd.MM.yyyy`)}
            </Date>
          </>
        )}
        title='Заявка'
      >
        <ApplicationDescription application={application} />
      </LoansTableExpandItem>

      <LoansTableExpandItem
        title='График платежей'
      >
        {!_.isEmpty(schedule) ? (
          <LoanSchedule value={schedule} />
        ) : (
          <NoData>Данные не найдены</NoData>
        )}
      </LoansTableExpandItem>

      {insurance && insurance.activeFlg && moment().subtract(28, `days`) <= moment(insurance.createDtm) && (
        <TableExpandButton
          onClick={() => onCancelInsurance(insurance.insuranceId)}
          type='primary'
        >
          Отменить полис
        </TableExpandButton>
      )}
    </Wrapper>
  );
};
