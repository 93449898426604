import * as React from 'react';
import {ReactNode, useEffect} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  getFileSize,
  getPlural,
} from 'helper';

import { PaperClipOutlined } from '@ant-design/icons';
import { IInputAttachment } from 'components/Messenger/types';

const Wrapper = styled.div`
  display: flex;
  color: ${palette.black60Color};
  justify-content: space-between;
`;

const Info = styled.div`
  display: flex;
`;

const Details = styled.div`
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
`;

const IconWrapper = styled.div`
  font-size: 16px;
`;

interface IComponentProps {
  children: ReactNode;
  files: IInputAttachment[];
  isProcessing?: boolean;
}

export const AttachmentsTitle: React.FC<IComponentProps> = ({ children, files, isProcessing }) => {
  const quantity = files.length;
  const size = getFileSize(_.sumBy(files, `size`));

  const [intervalId, setIntervalId] = React.useState(null);
  const [intervalCount, setIntervalCount] = React.useState(0);

  useEffect(() => {
    if (isProcessing) {
      const interval = setInterval(() => {
        setIntervalCount(v => (v === 3 ? 0 : v + 1));
      }, 300);

      // @ts-ignore
      setIntervalId(interval);
    } else if (intervalId) {
      clearInterval(intervalId);
      setIntervalCount(0);
    }
  }, [isProcessing]);

  return (
    <Wrapper>
      <Info>
        <IconWrapper>
          <PaperClipOutlined />
        </IconWrapper>
        <Details>
          {quantity}{` `}
          {getPlural(quantity, `вложение`, `вложения`, `вложений`)}{` `}
          ({size})

          {isProcessing && (
            <> | Идет обработка{_.repeat(`.`, intervalCount)}</>
          )}
        </Details>
      </Info>

      {children}
    </Wrapper>
  );
};
