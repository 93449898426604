import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';

import { getClientAddresses as getClientAddressesSelector } from 'models/clients/selectors';

import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { ExpandButton } from 'pages/PageClient/Single/components/EntityCard/EntityCardExpandButton';
import { AddressesCollectionWrapper } from 'pages/PageClient/Single/Info/Address/AddressesCollectionWrapper';
import { AddressItem } from 'pages/PageClient/Single/Info/Address/AddressItem';
import { getAddressTitle } from 'pages/PageClient/Single/Info/Address/helpers';

interface IProps {
  personId: string;
  type: `fact` | `reg`;
}

const Address: React.FC<IProps> = ({ personId, type }) => {
  const addresses = useSelector(state => getClientAddressesSelector(state, personId));
  const [plural, setPlural] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const items = collapsed ? [_.first(addresses)] : addresses;

  useEffect(() => {
    if (addresses.length > 1) setPlural(true);
  }, [addresses.length]);

  const addressTitle = useMemo(() => getAddressTitle(type), [type]);
  if (_.isEmpty(items)) return null;

  return (
    <EntityCard>
        <EntityCardTitle>Адреса {addressTitle}</EntityCardTitle>

        <EntityCardContent>
          <AddressesCollectionWrapper autoHeight>
            {_.map(items, (item, index) => (
              <AddressItem
                item={item}
                key={index}
                personId={personId}
                type={type}
              />
            ))}
          </AddressesCollectionWrapper>

          {plural && (
            <ExpandButton collapsed={collapsed} onClick={() => setCollapsed(!collapsed)} />
          )}
        </EntityCardContent>
      </EntityCard>
  );
};

export default Address;
