import React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';

import { getClientLoanById as getLoanSelector } from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';

import { Empty } from 'antd';

import {
  Block,
  BlockContent,
  BlockTitle,
  Row,
  RowLabel,
  RowValue,
} from '../styled';

import { FIELDS } from './constants';

const LoanDetails = ({ loanId, personId }) => {
  useDictionaries([`creditHistoryField`]);
  const loan = useSelector(state => getLoanSelector(state, personId, loanId));
  if (!loanId || _.isEmpty(loan)) return null;
  return (
      loanId
        ? (_.map(FIELDS, ({ fields, label: _label }) => (
          <Block key={_label}>
            <BlockTitle>{_label}</BlockTitle>
            <BlockContent>
              {_.map(fields, ({ label, normalize = _.identity, path: pathInner }, key) => {
                let value = _.get(loan, `${pathInner ? `${pathInner}.` : ``}${key}`);
                if (_.isNil(value)) value = _.get(loan, `details.${key}`);
                if (_.isNil(value)) value = _.get(loan, `bankAccount.${key}`);
                if (_.isFunction(normalize) && !_.isNil(value)) value = normalize(value);
                if (!_.isNil(value)) {
                  return (
                    <Row key={key}>
                      <RowLabel>{label}</RowLabel>
                      <RowValue>{value}</RowValue>
                    </Row>
                  );
                }
              })}
            </BlockContent>
          </Block>
        ))
        )
        : (
          <Empty description='Нет данных по договору' />
        )
  );
};

export default LoanDetails;
