import React, {
  useContext,
  useMemo,
} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { useVerification } from 'models/verifications/hooks/useVerification';
import { useVerificationResults } from 'models/verifications/hooks/useVerificationResults';
import {
  useVerificationResultsTypeIdsForVerification,
} from 'models/verifications/hooks/useVerificationResultsTypeIdsForVerification';

import ApplicationDocumentUploadModal from 'components/Modal/ApplicationDocumentUploadModal';
import clientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import { EntityCard } from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import {
  TabContentWrapper as TabContentWrapperDefault,
} from 'pages/PageClient/Single/components/TabContentWrapper/TabContentWrapper';
import { AdditionalPhones } from 'pages/PageClient/Single/Info/AdditionalPhones';

import { CheckList } from './CheckList/CheckList';
import { BottomButtons } from './Controls/BottomButtons';
import { VerificationSelect } from './Controls/VerificationSelect';
import { useUploadDocModal } from './useUploadDocModal';

const Column = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px 0;

  margin-right: -8px;
  margin-left: -8px;
  margin-bottom: 16px;

  ${EntityCard} {
    padding-right: 8px;
    padding-left: 8px;

    :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    gap: 16px 8px;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const TabContentWrapper = styled(TabContentWrapperDefault)`
  margin-top: 64px;
  margin-bottom: 48px;

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 132px;
  }
`;

const PageClientSingleVerificationResult = ({ docs }: { docs: any }) => {
  const { personId, verificationId } = useContext(clientMatchContext) as { personId: string; verificationId: number; };
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [isVerificationLoading, verification] = useVerification(verificationId);
  const { application, applicationId } = verification;
  const isUpdateDisabled = application?.applicationStatusId !== 7;

  const isReloading = isLoading || isVerificationLoading;

  const {
    clearResults,
    onReload,
    results,
    setResults,
    updateResults,
  } = useVerificationResults({
    isDisabled: isUpdateDisabled,
    isLoading : isReloading,
    setIsLoading,
    personId,
    verificationId,
  });

  const { close, documentTypeId, isOpen, open } = useUploadDocModal();

  const verificationResultTypeIds = useVerificationResultsTypeIdsForVerification(verificationId);
  const verificationResultTypeColumns = useMemo(() => _.chunk(
    verificationResultTypeIds,
    _.ceil(_.size(verificationResultTypeIds) / 3),
  ),
  [verificationResultTypeIds]);

  return (
    <ClientTab isReloading={isReloading} onReload={onReload}>
      <TabContentWrapper>
        <VerificationSelect
          personId={personId}
          verificationId={verificationId}
        />
        <Wrapper>
          {_.map(verificationResultTypeColumns, (_verificationResultTypeIds: number[], index) => (
            <Column key={index}>
              {_.map(_verificationResultTypeIds, verificationResultTypeId => (
                <CheckList
                  isLoading={isLoading}
                  key={verificationResultTypeId}
                  openUploadModal={open}
                  results={results}
                  setResults={setResults}
                  updateResults={updateResults}
                  verificationResultTypeId={verificationResultTypeId}
                />
              ))}
              {index === (_.size(verificationResultTypeColumns) - 1) && <AdditionalPhones personId={personId} />}
            </Column>
          ))}
        </Wrapper>
        {!isUpdateDisabled && (
          <BottomButtons
            clearResults={clearResults}
            openUploadModal={open}
            updateResults={updateResults}
          />
        )}
      </TabContentWrapper>
      <ApplicationDocumentUploadModal
        applicationId={applicationId}
        close={close}
        docs={docs}
        // @ts-ignore
        documentTypeId={documentTypeId}
        isOpen={isOpen}
        personId={personId}
      />
    </ClientTab>
  );
};

export default PageClientSingleVerificationResult;
