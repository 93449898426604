import { createAction } from 'redux-actions';

export const model = `courts`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const POST_ENFORCEMENT_PROCEEDING = `POST_ENFORCEMENT_PROCEEDING`;
export const DELETE_ENFORCEMENT_PROCEEDING = `DELETE_ENFORCEMENT_PROCEEDING`;
export const DOWNLOAD_EXCEL = `DOWNLOAD_EXCEL`;
export const GET = `GET`;
export const POST = `POST`;
export const RESET_FILTERS = `RESET_FILTERS`;
export const SET = `SET`;
export const SET_ITEMS = `SET_ITEMS`;
export const SET_LOADING = `SET_LOADING`;
export const SET_TABLE_STATE = `SET_TABLE_STATE`;

export const postEnforcementProceeding = ca(POST_ENFORCEMENT_PROCEEDING);
export const deleteEnforcementProceeding = ca(DELETE_ENFORCEMENT_PROCEEDING);
export const downloadExcel = ca(DOWNLOAD_EXCEL);
export const get = ca(GET);
export const post = ca(POST);
export const resetFilters = ca(RESET_FILTERS);
export const set = ca(SET);
export const setItems = ca(SET_ITEMS);
export const setLoading = ca(SET_LOADING);
export const setTableState = ca(SET_TABLE_STATE);
