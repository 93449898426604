import {
  useCallback,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { get as getOperatorsAction } from './actions';
import {
  getItems as getOperatorsSelector,
  isLoading as isOperatorsLoading,
} from './selectors';
import { IOperator } from './types';

export const useOperators = (additionalCheck?: boolean, force?: boolean):[isLoading:boolean, operators:IOperator[]] => {
  const dispatch = useDispatch();

  const isLoading:boolean = useSelector(isOperatorsLoading);
  const operators:IOperator[] = useSelector(getOperatorsSelector);

  const getOperators = useCallback(() => dispatch(getOperatorsAction()), [dispatch]);

  useEffect(() => {
      if (isLoading || (_.isBoolean(additionalCheck) && !additionalCheck) || (!force && !_.isEmpty(operators))) return;
      getOperators();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      additionalCheck,
      force,
      getOperators,
      isLoading,
    ],
  );
  return [isLoading, operators];
};
