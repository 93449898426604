import * as React from 'react';
import { useMemo } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import useMobileContext from 'hooks/useMobileContext';

import { MoreOutlined } from '@ant-design/icons';
import { DefaultDropdown } from 'components/DefaultDropdown/DefaultDropdown';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import CarBlackListUploadModal from 'components/Modal/CarBlackListUpload';
import DocumentBatchDownloadModal from 'components/Modal/DocumentBatchDownload';
import { SendSmsBatchModal } from 'components/Modal/SendSmsBatch/SendSmsBatch';
import StatBalanceModal from 'components/Modal/StatBalance';
import TestEmailModal from 'components/Modal/TestEmail';
import VersionedDocumentUploadModal from 'components/Modal/VersionedDocumentUpload';
import { subHeaderButtonDefaultMixin } from 'components/SubHeader/SubHeaderButtonDefault';
import { SubHeaderSearch } from 'components/SubHeader/SubHeaderSearch';
import { useAdminGeneralControls } from 'pages/PageAdmin/components/useAdminControls';

const Wrapper = styled.div`
  align-items: flex-start;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: 0 16px 16px;
  background-color: ${palette.backgroundAccentColor};

  @media (min-width: ${breakpoints.md}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 40px 16px;
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 60px;
    justify-content: flex-end;
  }
`;

const SearchControls = styled.div`
  @media(min-width: ${breakpoints.md}) {
    > * {
      margin-right: 16px;
      margin-bottom: 8px;
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 0;

    > * {
      margin-right: 0;
      margin-left: 16px;
      margin-bottom: 0;
    }
  }
`;

const DesktopButton = styled.button`
  ${subHeaderButtonDefaultMixin};

  font-size: 14px;
  padding: 8px 16px;

  .anticon:first-child {
    margin-right: 12px;
  }
`;

const MobileButton = styled.button`
  ${subHeaderButtonDefaultMixin};

  font-size: 14px;
  padding: 8px 16px;

  .anticon:first-child {
    margin-right: 0;
  }
`;

export interface IControl {
  additional?: React.ReactNode;
  icon: React.ReactNode;
  isDisabled?: boolean;
  isHidden?: boolean;
  key?: string;
  onClick?: (arg0: any) => void;
  testAttribute?: string;
  title: string;
  tooltipTitle?: string;
  type?: `primary` | `default` | `danger`;
}

interface IComponentProps {
  controls: IControl[];
  'data-test-search-button'?: string; // eslint-disable-line quotes
  'data-test-search-field'?: string; // eslint-disable-line quotes
  search: {
    isHidden?: boolean;
    onChange?: (value: string) => void;
    placeholder?: string;
    value?: string;
  }
}

const Search = styled(SubHeaderSearch)`
  margin-right: 16px;

  @media(min-width: ${breakpoints.md}) {
    margin-bottom: 8px;
  }
  @media(min-width: ${breakpoints.lg}) {
    margin-bottom: 0;
  }
`;

export const AdminSearchWithControls: React.FC<IComponentProps> = ({
  controls,
  'data-test-search-button': dataTestSearchButton,
  'data-test-search-field': dataTestSearchField,
  search,
}) => {
  const { mobile } = useMobileContext();
  const {
    controls: adminGeneralControls,
    isCarBlackListUploadModalOpen,
    isDocumentBatchDownloadModalOpen,
    isSmsBatchModalOpen,
    isStatbalanceModalOpen,
    isTestEmailModalOpen,
    isUploadVersionedDocumentModalOpen,
    setCarBlackListUploadModal,
    setIsDocumentBatchDownloadModalOpen,
    setIsSmsBatchModalOpen,
    setIsStatbalanceModalOpen,
    setTestEmailModal,
    setUploadVersionedDocumentModal,
  } = useAdminGeneralControls();

  const menuItems = useMemo(
    () => [
      ..._.map(adminGeneralControls, (control, index) => ({
        disabled: control.isDisabled,
        key     : `1${index}`,
        onClick : control.onClick,
        icon    : control.icon,
        label   : control.title,
        hidden  : control.isHidden,
      })),
      {
        key : `divider-1`,
        type: `divider`,
      },
      ..._.map(controls, (control, index) => ({
        disabled: control.isDisabled,
        key     : `2${index}`,
        onClick : control.onClick,
        icon    : control.icon,
        label   : control.title,
        hidden  : control.isHidden,
      })),
    ],
    [adminGeneralControls, controls],
  );

  return (
    <>
      <Wrapper>
        {!search.isHidden && (
          // @ts-ignore
          <Search
            data-test-search-button={dataTestSearchButton}
            data-test-search-field={dataTestSearchField}
            onChange={search.onChange}
            placeholder={search.placeholder}
            value={search.value}
          />
        )}

        {controls && (
            mobile ? (
              <DefaultDropdown
                forceRender
                // @ts-ignore
                menu={{ items: menuItems }}
                trigger={[`click`]}
              >
                <MobileButton>
                  <MoreOutlined />
                </MobileButton>
              </DefaultDropdown>
            ) : (
              <SearchControls>
                {_.map(_.reject(controls, `isHidden`), (control, index) => (
                  <React.Fragment key={index}>
                    <DefaultTooltip placement='topLeft' title={control.tooltipTitle || control.title}>
                      <DesktopButton
                        data-test={control.testAttribute}
                        disabled={control.isDisabled}
                        onClick={control.onClick}
                      >
                        {control.icon}{control.title}
                      </DesktopButton>
                    </DefaultTooltip>
                  </React.Fragment>
                ))}
              </SearchControls>
            )
        )}
      </Wrapper>

      <CarBlackListUploadModal
        isVisible={isCarBlackListUploadModalOpen}
        onClose={() => setCarBlackListUploadModal(v => !v)}
      />
      <TestEmailModal
        isVisible={isTestEmailModalOpen}
        onClose={() => setTestEmailModal(v => !v)}
      />
      <VersionedDocumentUploadModal
        isVisible={isUploadVersionedDocumentModalOpen}
        onClose={() => setUploadVersionedDocumentModal(v => !v)}
      />
      <DocumentBatchDownloadModal
        isVisible={isDocumentBatchDownloadModalOpen}
        onClose={() => setIsDocumentBatchDownloadModalOpen(v => !v)}
      />
      <StatBalanceModal
        isOpen={isStatbalanceModalOpen}
        onClose={() => setIsStatbalanceModalOpen(v => !v)}
      />
      <SendSmsBatchModal
        isOpen={isSmsBatchModalOpen}
        onClose={() => setIsSmsBatchModalOpen(v => !v)}
      />
    </>
  );
};
