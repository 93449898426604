import * as React from 'react';
import styled from 'styled-components';
import { palette } from 'styles/theme';

const Wrapper = styled.div`
  .cd-check-mark:before {
    color: ${palette.black60Color};
    font-size: 16px;
  }
`;

const IsReadWrapper = styled.div`
  .cd-check-marks:before {
    font-size: 16px;
    color: ${palette.primary500Color};
  }
`;

interface IComponentProps {
  message: any;
}

export const MessageStatusIcon: React.FC<IComponentProps> = ({ message }) => {
  const { isRead } = message;

  return (
    <Wrapper>
      {isRead
        ? (
          <IsReadWrapper>
            <span className='cd cd-check-marks' />
          </IsReadWrapper>
        ) : (
          <span className='cd cd-check-mark' />
        )}
    </Wrapper>
  );
};
