import * as React from 'react';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';

const Wrapper = styled.button`
  background: ${palette.primary5Color};
  width: 100%;
  border: none;
  cursor: pointer;
  transition: background 200ms ease;

  .anticon {
    transition: color 200ms ease;
    color: ${palette.primary500Color};
    font-size: 14px;
  }

  :hover {
    background: ${palette.primary500Color};

    .anticon {
      color: ${palette.whiteColor};
    }
  }
`;

interface IComponentProps {
  isCollapsed: boolean;
  onClick: () => void;
}

export const MessageToggleCollapseButton: React.FC<IComponentProps> = ({ isCollapsed, onClick }) => (
  <Wrapper onClick={onClick}>
    {isCollapsed ? (
      <UpOutlined />
    ) : (
      <DownOutlined />
    )}
  </Wrapper>
);
