import React from 'react';
import { Link as LinkDefault } from 'dva/router';
import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
  shadows,
} from 'styles/theme';

import { Card } from 'antd';

const Link = styled(LinkDefault)`
  display: flex;
  height: 152px;
  width: 312px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background-color: ${palette.backgroundColor};
  border: 1px solid ${palette.borderDefaultColor};
  box-shadow: ${shadows.image};

  .ant-card-meta-title {
    color: ${getLightStyleOr(palette.black80Color, palette.black60Color)};
    font-weight: 700;
    white-space: pre-wrap;
    font-size: 20px;
    line-height: 20px;
  }

  &:hover {
    background-color: ${palette.primary5Color};
    border: 1px solid ${palette.primary500Color};

    .ant-card-meta-title {
      color: ${palette.textPrimaryColor};
    }
  }

  .ant-card, .ant-card-bordered, .ant-card-body {
    background-color: transparent;
    border-radius: 8px;
    border: unset;
    height: 100%;
  }

  .ant-card-body {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 40px 32px;
  }

  .ant-card-meta {
    align-items: center;
    display: flex;
    margin: 0 !important;

    &-avatar {
      width: 72px;
      height: 72px;
      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;
      background-color: ${palette.primary5Color};
      border-radius: 88px;
      padding: 0;
      margin-right: 16px;

      span {
        color: ${palette.primary500Color};
      }
    }
  }

  @media (min-width: ${breakpoints.md}) and (max-width: 1063px) {
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    height: 72px;
    width: 100%;

    .ant-card-body {
      padding: 16px 24px;
    }

    .ant-card-meta {
      &-title {
        font-size: 16px;
        line-height: 16px;
      }

      &-avatar {
        width: 40px;
        height: 40px;
      }
    }
  }
`;

interface IComponentProps {
  icon: any;
  mobile?: boolean;
  path: string;
  testAttribute?: string;
  title: string;
}

export const MenuItemAdminBig: React.FC<IComponentProps> = ({
  icon,
  mobile,
  path,
  testAttribute,
  title,
}) => (
  <Link data-test={testAttribute} to={path}>
    <Card>
      <Card.Meta avatar={mobile ? icon(20) : icon(36)} title={title} />
    </Card>
  </Link>
);

