import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import {
  create as createAction,
  get as getRoleGroupsAction,
  remove as removeRoleGroupsAction,
  set as setAction,
  update as updateAction,
} from 'models/roleGroups/actions';
import {
  getItems as getRoleGroupsSelector,
  getItemsFiltered as getRoleGroupsFilteredSelector,
  getItemsNames as getRoleGroupNamesSelector,
  getSearchString as searchStringSelector,
  isLoading as isLoadingSelector,
} from 'models/roleGroups/selectors';
import { removeFromGroup as removeRoleFromGroupAction } from 'models/roles/actions';
import { getItems as getRolesSelector } from 'models/roles/selectors';

import { useModalHandlers } from 'hooks/useModalHandlers';

import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import CreateModal from 'components/Modal/Create';
import RemoveModal from 'components/Modal/Remove';
import { AdminPluralLayout } from 'pages/PageAdmin/components/AdminPluralLayout';
import { AdminSearchWithControls } from 'pages/PageAdmin/components/AdminSearchWithControls';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';

import {
  COLUMNS,
  FORM_FIELDS,
} from './constants';

const StyledTable = styled(AdjustableTable)`
    &_entity {
      &_mobileConfig, &_mobileConfigSchema {
        .ant-table-cell {
          vertical-align: top;
        }
      }
    }
`;

const PageRoleGroupsPlural = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    removeModalItems,
    roleGroupNames,
    roleGroups,
    roles,
    searchString,
  } = useSelector(state => ({
    isLoading       : isLoadingSelector(state),
    removeModalItems: getRoleGroupsSelector(state),
    roleGroupNames  : getRoleGroupNamesSelector(state),
    roleGroups      : getRoleGroupsFilteredSelector(state),
    roles           : getRolesSelector(state),
    searchString    : searchStringSelector(state),
  }));

  const create = role => dispatch(createAction({ item: role }));
  const getRoleGroups = () => dispatch(getRoleGroupsAction({ force: true }));
  const removeRoleFromGroup = ({ id, roleGroupId }) => dispatch(removeRoleFromGroupAction({ id, roleGroupId }));
  const removeRoleGroupsDispatch = ids => dispatch(removeRoleGroupsAction({ ids }));
  const set = value => dispatch(setAction(value));
  const update = item => dispatch(updateAction({ id: item.id, item }));

  const {
    closeEditModal,
    isCreateModalOpen,
    isEditModalOpen,
    isRemoveModalOpen,
    itemEditing,
    onSelectChange,
    selectedRowKeys,
    setItemEditing,
    toggleCreateModal,
    toggleEditModal,
    toggleRemoveModal,
  } = useModalHandlers();

  const columns = COLUMNS({ removeRoleFromGroup, roles, setItemEditing, toggleEditModal });
  const formFields = FORM_FIELDS({ roleGroupNames, roles, itemEditing });

  const removeRoleGroups = () => {
    onSelectChange([]);
    removeRoleGroupsDispatch(_.filter(selectedRowKeys, id => id >= 0));
  };
  const setSearchString = e => set({ searchString: _.isString(e) ? e : e.target.value });

  useEffect(() => {
    getRoleGroups()
  }, []);

  return (
    <>
      <AdminSearchWithControls
        controls={[
          {
            isDisabled: isLoading,
            onClick   : () => {
              setSearchString(``);
              getRoleGroups();
            },
            title: `Очистить поиск и перезагрузить`,
            icon : isLoading ? <LoadingOutlined spin /> : <SyncOutlined />,
          },
          {
            isDisabled: _.isEmpty(selectedRowKeys),
            onClick   : () => toggleRemoveModal(),
            title     : `Удалить выбранные группы ролей`,
            icon      : <DeleteOutlined />,
          },
          {
            onClick: () => toggleCreateModal(),
            title  : `Добавить группу ролей`,
            icon   : <PlusOutlined />,
          },
        ]}
        search={{
          value      : searchString,
          onChange   : setSearchString,
          placeholder: `Поиск группы ролей`,
        }}
      />

      <AdminPluralLayout>
        <StyledTable
          columns={columns}
          dataSource={roleGroups}
          loading={isLoading}
          pagination={{
            hideOnSinglePage: true,
            pageSize        : _.size(roleGroups),
            size            : `small`,
          }}
          preferences={{
            path      : `admin.roleGroups`,
            cellRender: (value, record) => (
              <div>
                {record.id > 0 && (
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      setItemEditing(record);
                      toggleEditModal();
                    }}
                    type='primary'
                  />
                )}
              </div>
            ),
          }}
          rowKey='id'
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          scroll={{
            x: `max-content`,
            y: window.innerHeight - 300,
          }}
        />

        <CreateModal
          close={toggleCreateModal}
          create={create}
          formFields={formFields}
          title='Новая группа ролей'
          visible={isCreateModalOpen}
        />
        <CreateModal
          close={closeEditModal}
          formFields={formFields}
          item={itemEditing}
          mode='update'
          title={`Редактирование группы ролей '${_.get(itemEditing, `name`, ``)}'`}
          update={update}
          visible={isEditModalOpen}
        />
        <RemoveModal
          close={toggleRemoveModal}
          ids={_.filter(selectedRowKeys, id => id >= 0)}
          items={removeModalItems}
          modalTitle='Удаление групп ролей'
          namePath='name'
          onSubmit={removeRoleGroups}
          question='Вы действительно хотите удалить эти группы ролей?'
          visible={isRemoveModalOpen}
        />
      </AdminPluralLayout>
    </>
  );
};

export default PageRoleGroupsPlural;
