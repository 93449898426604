import * as React from 'react';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { CheckOutlined } from '@ant-design/icons';

const Wrapper = styled.div<{ isChecked?: boolean; }>`
  width: 16px;
  height: 16px;
  font-size: 9px;
  line-height: 9px;
  color: ${palette.whiteColor};

  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${p => (p.isChecked
    ? palette.primary500Color
    : palette.black20Color)
};

  border-radius: 50%;
`;

interface IComponentProps {
  isChecked: boolean;
}

export const CellCheckbox: React.FC<IComponentProps> = ({ isChecked }) => (
  <Wrapper isChecked={isChecked}>
    {isChecked && (<CheckOutlined />)}
  </Wrapper>
);
