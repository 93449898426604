import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(getState, ({ items }) => items);

export const getItem = createSelector(
  [getItems, (state, id) => id],
  (items, id) => _.find(items, { id }),
);

export const getItemsByIds = createSelector(
  [getItems, (state, ids) => ids],
  (items, ids) => _.filter(items, ({ id }) => _.includes(ids, id)),
);

export const getSearchString = createSelector(getState, ({ searchString }) => searchString);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);

export const isLoaded = createSelector(getState, ({ isLoaded: _isLoaded }) => _isLoaded);

export const getTableState = createSelector(getState, ({ tableState }) => tableState);

export const getFilters = createSelector(getTableState, ({ filters }) => filters || {});
export const getFilterOptions = createSelector(getState, ({ filters }) => filters || {});
