import React from 'react';
import { Link as LinkDefault } from 'dva/router';
import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
  shadows,
} from 'styles/theme';

import { Card } from 'antd';

const handleFontColor = (props: { $color: string }) => {
  const { $color } = props;

  switch ($color) {
    case `secondary`:
      return palette.secondaryAccentColor;
    case `primary`:
      return palette.primary500Color;
    case `black`:
      return getLightStyleOr(palette.black500Color, palette.white60Color);
    case `purple`:
      return palette.purple500Color;
    case `blue`:
      return palette.blue500Color;
    case `red`:
      return palette.alert600Color;
    case `yellow`:
      return palette.yellow500Color;
    case `gray`:
      return palette.primary700Color;
    case `alert`:
      return palette.alert500Color;
    default:
      return palette.primary500Color;
  }
};

const handleBackgroundColor = (props: { $color: string }) => {
  const { $color } = props;

  switch ($color) {
    case `secondary`:
      return palette.secondary5Color;
    case `primary`:
      return palette.primary5Color;
    case `black`:
      return getLightStyleOr(palette.black5Color, palette.white5Color);
    case `purple`:
      return palette.purple5Color;
    case `blue`:
      return palette.blue5Color;
    case `red`:
      return palette.red5Color;
    case `yellow`:
      return palette.yellow5Color;
    case `gray`:
      return palette.gray5Color;
    case `alert`:
      return palette.alert5Color;
    default:
      return palette.primary5Color;
  }
};

const Link = styled(LinkDefault)<{ $color: string }>`
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background-color: ${palette.backgroundColor};
  border: 1px solid ${palette.borderDefaultColor};
  box-shadow: ${shadows.image};
  height: 64px;
  width: 196px;
  margin-right: 24px;
  margin-bottom: 16px;

  &:last-of-type {
    margin-right: 0;
  }

  .ant-card-meta-title {
    color: ${getLightStyleOr(palette.black80Color, palette.black60Color)};
    font-weight: 700;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 16px;
  }

  &:hover {
    background-color: ${palette.primary5Color};
    border: 1px solid ${palette.primary500Color};

    .ant-card-meta-title {
      color: ${palette.textPrimaryColor};
    }
  }

  .ant-card, .ant-card-bordered, .ant-card-body {
    background-color: transparent;
    border-radius: 8px;
    border: unset;
    height: 100%;
  }

  .ant-card-body {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 8px 16px;
  }

  .ant-card-meta {
    align-items: center;
    display: flex;
    margin: 0 !important;

    &-avatar {
      width: 40px;
      height: 40px;
      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;
      background-color: ${props => handleBackgroundColor(props)};
      border-radius: 88px;
      padding: 0;
      margin-right: 16px;

      span {
        color: ${props => handleFontColor(props)};
      }
    }
  }

  @media screen and (max-width: 536px) {
    width: 100% !important;
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

interface IComponentProps {
  color:string;
  icon: any;
  path: string;
  testAttribute?: string;
  title: string;
}

const MenuItemAdminSmall: React.FC<IComponentProps> = ({
  color,
  icon,
  path,
  testAttribute,
  title,
}) => (
  <Link $color={color} data-test={testAttribute} to={path}>
    <Card>
      <Card.Meta avatar={icon(24)} title={title} />
    </Card>
  </Link>
);

export default MenuItemAdminSmall;
