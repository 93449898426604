import _ from 'lodash';
import { createSelector } from 'reselect';

import { JSONParse } from 'helper';

import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';
import { DEFAULT_TABLE_STATE } from 'models/task/constants';
import { parseCreditHistory } from 'models/task/helpers';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(getState, ({ items }) => items);

export const getItem = createSelector(
  [getItems, (state, id) => id],
  (items, id) => {
    const item = _.find(items, { id });
    return item
      ? {
        ...item,
        vector: _.isObject(item.vector)
          ? item.vector
          : (
            JSONParse(item.vector, null)
            || JSONParse(_.replace(_.replace(item.vector, /\n/gim, ``), /"\s+"/gim, `","`))),
      }
      : item;
  },
);

export const getItemsByIds = createSelector(
  [getItems, (state, ids) => ids],
  (items, ids) => _.filter(items, ({ id }) => _.includes(ids, id)),
);

export const getSearchString = createSelector(getState, ({ searchString }) => searchString);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);

export const isLoaded = createSelector(getState, ({ isLoaded: _isLoaded }) => _isLoaded);

export const getTableState = createSelector(getState, ({ tableState }) => tableState);
export const getTableStateCut = createSelector(
  [getTableState, (state, personId = `plural`) => personId],
  (tableState, personId) => tableState?.[personId] || DEFAULT_TABLE_STATE,
);

export const getFilters = createSelector(getTableStateCut, ({ filters }) => filters || {});
export const getFilterOptions = createSelector(getState, ({ filters }) => filters || {});

export const getTaskCreditHistory = createSelector(
  [getItem, state => getDictionarySelector(state, `creditHistoryField`)],
  (task, creditHistoryFields) => parseCreditHistory(task?.creditHistory, creditHistoryFields),
);

export const getTaskVector = createSelector(
  [getItem, state => getDictionarySelector(state, `creditHistoryField`)],
  (task, creditHistoryFields) => parseCreditHistory(task?.vector, creditHistoryFields),
);
