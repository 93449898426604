import { AxiosResponse } from 'axios';

import api from 'api/index';

import {
  Communication,
  IFilters,
} from './types';

const baseUrl = (personId: string) => `/communication/person/${personId}`;

export const create = (personId: string, data: Partial<Communication>):Promise<AxiosResponse<Communication>> => api.put(
  baseUrl(personId), data,
);
export const createWithPhone = (
  phone: string,
  data: Partial<Communication>,
):Promise<AxiosResponse<Communication>> => api.put(
  `/communication/phone/${phone}`, data,
);
export const getEditableId = (personId: string):Promise<AxiosResponse<number>> => api.get(
  `${baseUrl(personId)}/editable`,
);
export const remove = (personId: string, communicationId:number):Promise<AxiosResponse> => api.delete(
  `${baseUrl(personId)}/communication/${communicationId}`,
);
export const search = (personId: string, filters: IFilters):Promise<AxiosResponse<Communication[]>> => api.post(
  `${baseUrl(personId)}/search`, filters,
);
// eslint-disable-next-line max-len
export const update = (personId: string, communicationId: number, data: Partial<Communication>):Promise<AxiosResponse<Communication>> => api.patch(
  `${baseUrl(personId)}/communication/${communicationId}`, data,
);
