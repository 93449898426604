/* eslint-disable import/no-cycle */
import _ from 'lodash';
import app from '../../main'; // eslint-disable-line import/no-unresolved
import {jwtDecode as jwt} from 'jwt-decode';

import { getToken } from 'api';

import {
  logout,
  refreshToken,
} from './actions';

export const isAuth = () => {
  const token = getToken();
  if (!token) return false;
  const decodedToken = jwt(token);
  const currentDate = +(new Date()) / 1000;
  const expirationDate = (_.get(decodedToken, `exp`, 0));
  if (currentDate > expirationDate) {
    localStorage.clear();
    return false;
  }
  return true;
};

export const needRefreshToken = () => {
  if (!isAuth()) return false;

  const token = getToken();
  const decodedToken = jwt(token);
  const currentDate = +(new Date()) / 1000;
  const expirationDate = (_.get(decodedToken, `exp`, 0));

  return currentDate + 15 * 60 * 60 >= expirationDate;
};

export const logoutUserWithMessage = () => {
  const dispatch = _.get(app, `_store.dispatch`);
  const message = `Время Вашей сессии истекло. Пожалуйста, войдите в систему, используя данные, указанные Вами при регистрации.`; // eslint-disable-line max-len

  if (dispatch) {
    const logoutPayload = {
      message,
      showMessage: true,
      toastType  : `warning`,
    };
    dispatch(logout(logoutPayload));
  }
};

export const refreshAccessToken = () => {
  const dispatch = _.get(app, `_store.dispatch`);

  if (dispatch) {
    dispatch(refreshToken());
  }
};
