import * as React from 'react';
import { useState } from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { SettingOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Dropdown,
  Radio,
  Space,
  Tooltip,
} from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-group-small .ant-radio-button-wrapper {
    padding: 0 9px;
  }
`;

const TableControlsWrapper = styled.div`
  display: none;

  @media(min-width: ${breakpoints.md}) {
    display: flex;
    align-items: baseline;

    ${StyledRadioGroup} {
      margin-left: 8px;
    }
  }
`;

interface IComponentProps {
  columnKeysWithFilters?: string[];
  columns: { key: string; title: string; }[];
  tableSize: SizeType;
  visibleKeys: string[];
  onReset(): void;
  onSelect(info: MenuInfo): void;
  onSizeChange?(size: SizeType): void;
}

export const TableColumnSettings: React.FC<IComponentProps> = ({
  columns,
  columnKeysWithFilters = [],
  onReset,
  onSelect,
  onSizeChange,
  tableSize,
  visibleKeys,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const count = columns.length - visibleKeys.length;

  const handleClick = (info: MenuInfo) => {
    if (info.key === `reset`) return onReset();

    if (info.key === `size`) return _.noop();

    if (_.includes(columnKeysWithFilters, info.key)) return _.noop();

    return onSelect(info);
  };

  const handleSizeChange = (e: { target: { value: any; }; }) => {
    const newSize = e.target.value;

    if (onSizeChange) onSizeChange(newSize);
  };

  const columnsMenuItems = _.map(columns, column => {
    const isDisabled = _.includes(columnKeysWithFilters, column.key);
    const content = (
      <>
        <Checkbox
          checked={_.includes(visibleKeys, column.key)}
          disabled={isDisabled}
        />
        {column.title}
      </>
    );
    return {
      key  : column.key,
      label: (
        <Space>
          {isDisabled
            ? (<Tooltip placement='top' title='Колонку с активным фильтром нельзя скрыть'>{content}</Tooltip>)
            : (content)}
        </Space>
      ),
    };
  });

  const menuItems = [
    {
      key  : `size`,
      label: (
        <TableControlsWrapper>
          Таблица
          {/*@ts-ignore*/}
          <StyledRadioGroup onChange={handleSizeChange} size='small' value={tableSize}>
            <Radio.Button value='small'>S</Radio.Button>
            <Radio.Button value='middle'>M</Radio.Button>
            <Radio.Button value='large'>L</Radio.Button>
          </StyledRadioGroup>
        </TableControlsWrapper>
      ),
    },
    {
      key : `divider-1`,
      type: `divider`,
    },
    ...columnsMenuItems,
    {
      key : `divider-2`,
      type: `divider`,
    },
    {
      key  : `reset`,
      label: <Button>Сбросить</Button>,
    },
  ];

  return (
    <Dropdown
      //@ts-ignore
      menu={{ items: menuItems, onClick: handleClick }}
      onOpenChange={setIsOpen}
      open={isOpen}
      trigger={[`click`]}
    >
      <Badge count={count} dot>
        <Button icon={<SettingOutlined />} />
      </Badge>
    </Dropdown>
  );
};
