import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';

import { WOOPRA_PERSONAL_URL } from 'constants/WOOPRA';
import {
  formatDate,
  getCheckedField,
} from 'helper';

import { getClientMarketingBlacklist } from 'models/clients/actions';
import { getWoopraLink } from 'models/clients/helpers';
import { useClient } from 'models/clients/hooks';
import { useDictionaries } from 'models/dictionaries/hooks';
import {
  CLIENT_EDIT,
  SUPER_ADMIN,
} from 'models/roles/constants';

import {
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import CommunicationTypeDropdown from 'components/CommunicationTypeDropdown';
import { CarNumberPlate } from 'pages/PageClient/Single/components/CarNumberPlate/CarNumberPlate';
import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardRow } from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';
import { BlacklistModal } from 'pages/PageClient/Single/Info/BlacklistModal';

import ConditionModal from './ConditionModal';

const allowedRoles = [SUPER_ADMIN, CLIENT_EDIT];

const Other = ({ openEditModal, personId }) => {
  const dispatch = useDispatch();

  const [, client] = useClient(personId);
  const [, dictionaries] = useDictionaries([`clientCondition`, `marketingBlacklistType`]);
  const clientConditionItem = _.find(
    dictionaries.clientCondition,
    { id: client.clientConditionId },
  );

  const { carNumber, marketingBlacklist } = client;
  const marketingBlacklistType = _.find(
    dictionaries.marketingBlacklistType,
    { id: marketingBlacklist?.type },
  );

  const getBlacklists = useCallback(() => {
    dispatch(getClientMarketingBlacklist({ personId }));
  }, [personId, dispatch]);

  const clientConditionName = _.get(clientConditionItem, `name`, `не задано`);

  const [conditionModalVisible, setConditionModalVisible] = useState(false);
  const [blacklistModalVisible, setBlacklistModalVisible] = useState(false);
  const toggleConditionModal = () => setConditionModalVisible(prev => !prev);
  const toggleBlackListModal = () => setBlacklistModalVisible(prev => !prev);

  useEffect(() => {
    getBlacklists()
  }, []);

  const blacklistReason = marketingBlacklistType?.description || `Не указана`;
  const blacklistDateRange = (marketingBlacklist?.createDtm && marketingBlacklist?.endDtm)
    ? `${formatDate(marketingBlacklist.createDtm, `dd.MM.yyyy`)} - ${formatDate(marketingBlacklist.endDtm, `dd.MM.yyyy`)}` // eslint-disable-line max-len
    : `Не указан`;
  const marketingBlacklistEmpty = _.isEmpty(marketingBlacklist);

  const items = _.reject([
    {
      name : `Состояние клиента`,
      value: (
        <span>
          {clientConditionName}
          <EntityCardTitleControl actionTitle='Изменение данных' onClick={toggleConditionModal}>
            <EditOutlined />
          </EntityCardTitleControl>
        </span>
      ),
      text: clientConditionName,
    },
    {
      name : `Предпочтительный способ связи`,
      value: <CommunicationTypeDropdown icon={EditOutlined} noLabel personId={personId} />,
    },
    {
      name : `personId`,
      value: getCheckedField(personId),
      text : personId,
    },
    {
      name : `Причина нахождения в ЧС`,
      value: (
        <span>
          {blacklistReason}
          <EntityCardTitleControl
            actionTitle={marketingBlacklistEmpty ? `Добавить в ЧС` : `Редактировать запись в ЧС`}
            onClick={toggleBlackListModal}
          >
            {marketingBlacklistEmpty
              ? <PlusCircleOutlined />
              : <EditOutlined />}
          </EntityCardTitleControl>
        </span>
      ),
      text: blacklistReason,
    },
    {
      name : `Период нахождения`,
      value: blacklistDateRange,
      text : blacklistDateRange,
    },
    {
      name : `Woopra`,
      value: getWoopraLink(client),
      text : WOOPRA_PERSONAL_URL(personId),
    },
    {
      name : `Номер автомобиля`,
      value: <CarNumberPlate carNumber={carNumber} />,
      text : client.carNumber,
    },
    {
      name : `Автомобиль`,
      value: client.auto,
    },
    {
      name : `ИНН`,
      value: client.inn,
    },
    {
      name : `СНИЛС`,
      value: client.snils,
    },
  ], ({ value }) => _.isNil(value));

  if (_.isEmpty(items)) return null;

  return (
    <EntityCard>
      <EntityCardTitle>
        Прочие данные

        <EntityCardTitleControl
          actionTitle='Изменение данных'
          allowedRoles={allowedRoles}
          onClick={() => openEditModal()}
        >
          <EditOutlined />
        </EntityCardTitleControl>
      </EntityCardTitle>

      <EntityCardContent>
        {_.map(items, ({ name, text, value }, index) => (
          <EntityCardRow key={index} title={name} valueToCopy={text || value}>
            {value}
          </EntityCardRow>
        ))}
      </EntityCardContent>

      <ConditionModal
        entity='client'
        onClose={toggleConditionModal}
        personId={personId}
        visible={conditionModalVisible}
      />
      <BlacklistModal
        onClose={toggleBlackListModal}
        visible={blacklistModalVisible}
      />
    </EntityCard>

  );
};

export default Other;
