import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { hasIntersection } from 'helper';

import { useDictionaries } from 'models/dictionaries/hooks';
import {
  get as getItemsAction,
  getFilters as getFiltersAction,
  setTableState as setTableStateAction,
} from 'models/lead/actions';
import {
  getFilterOptions as getFilterOptionsSelector,
  getItems as getItemsSelector,
  getTableState as getTableStateSelector,
} from 'models/lead/selectors';
import { ILead } from 'models/lead/types';
import { useOperators } from 'models/operators/hooks';
import { getCurrent } from 'models/operators/selectors';
import {
  LEAD_ADMIN,
  LEAD_EDIT,
  SUPER_ADMIN,
} from 'models/roles/constants';
import { getItems as getRolesSelector } from 'models/roles/selectors';

import { useHasRoles } from 'hooks/useHasRoles';

import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Layout,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';
import { LeadModal } from 'pages/PageLead/Plural/LeadModal';

import { LeadsHeader } from './LeadsHeader';

import {
  ADMIN_ROLES,
  getColumns,
} from './constants';

const StyledLayout = styled(Layout)``;
const tableScrollY = (window.innerWidth < 769) ? (window.innerHeight - 320) : (window.innerHeight - 250);

interface IProps {
}

export const PageLeadPlural: React.FC<IProps> = () => {
  const dispatch = useDispatch();

  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editingItem, setEditingItem] = useState<ILead | null>(null);
  const onSelectChange = (keys: number[]) => setSelectedRowKeys(keys);

  const toggleModal = (lead?: ILead | null) => {
    // @ts-ignore
    setEditingItem(lead);
    setIsModalOpen(!isModalOpen);
  };

  const [isDictionariesLoading, dictionaries] = useDictionaries([`leadStatus`]);
  const [isOperatorsLoading, operators] = useOperators();

  const {
    currentOperator,
    filterOptions,
    items,
    roles,
    tableState,
  } = useSelector(state => ({
    currentOperator: getCurrent(state),
    filterOptions  : getFilterOptionsSelector(state),
    items          : getItemsSelector(state),
    roles          : getRolesSelector(state),
    tableState     : getTableStateSelector(state),
  }));

  const allowedRoleIds = _.map(
    _.filter(roles, ({ name }) => _.includes([LEAD_ADMIN, LEAD_EDIT, SUPER_ADMIN], name)),
    `id`,
  );

  const setTableState = useCallback(
      (state: any) => dispatch(setTableStateAction({ ...state })),
    [dispatch],
  );

  const getItems = useCallback(
    () => dispatch(getItemsAction()),
    [dispatch],
  );

  const getFilters = useCallback(
    () => dispatch(getFiltersAction()),
    [dispatch],
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onTableChange = useCallback((pagination: any, filters: any, sorter: any) => {
    setTableState({
      ...tableState,
      pagination: {
        ...pagination,
        showSizeChanger: true,
        defaultPageSize: 10,
        pageSizeOptions: [`5`, `10`, `25`, `50`, `100`, `200`],
      },
      filters: {
        ...filters,
      },
      sorter,
      callback: getItems,
    });
  }, [setTableState, tableState, getItems]);

  const onTableColumnsChange = (columns: any) => {
    setTableState({ ...tableState, columns });
  };
  const operatorsToSelect = _.filter(operators, ({ user }) => hasIntersection(user?.roleIds, allowedRoleIds));

  const columns = getColumns({
    filterOptions,
    tableState,
    dictionaries,
    operators,
    operatorsToSelect,
  });

  useEffect(() => {
    if (_.isEmpty(filterOptions)) getFilters();
    getItems();
  }, []);

  const hasRoles = useHasRoles();
  const isAdmin = hasRoles(ADMIN_ROLES);

  const isLoading = isDictionariesLoading || isOperatorsLoading;

  return (
    <>
      <LeadsHeader
        onCreate={() => toggleModal()}
        onTableChange={onTableChange}
        operatorsToSelect={operatorsToSelect}
        selectedRowKeys={selectedRowKeys}
        tableState={tableState}
      />
      <LeadModal
        // @ts-ignore
        item={editingItem}
        onClose={toggleModal}
        onSuccess={() => setEditingItem(null)}
        open={isModalOpen}
        operatorsToSelect={operatorsToSelect}
      />
      <StyledLayout>
        <AdjustableTable
          columns={columns}
          dataSource={items}
          defaultColumns={_.map(columns, `key`)}
          filters={tableState.filters}
          loading={isLoading}
          onChange={onTableChange}
          onTableColumnsChange={onTableColumnsChange}
          pagination={tableState.pagination}
          preferences={{
            path      : `leads`,
            cellRender: (_pass, item:ILead) => {
              if (isAdmin || item?.operatorId === currentOperator?.id) {
                return (
                  <DefaultTooltip placement='left' title='Редактировать'>
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => toggleModal(item)}
                      shape='circle'
                      type='primary'
                    />
                  </DefaultTooltip>
                );
              }
            },
          }}
          rowKey='id'
          rowSelection={{
            // @ts-ignore
            onChange: onSelectChange,
            selectedRowKeys,
          }}
          scroll={{
            scrollToFirstRowOnChange: true,
            x                       : _.isEmpty(items)
              ? undefined
              : `100%`,
            y: tableScrollY,
          }}
          scrollableHeader
        />
      </StyledLayout>
    </>
  );
};

