import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'dva';

import { isLoading as isLoadingSelector } from 'models/task/selectors';

import { Modal } from 'antd';
import { DefaultSpin } from 'components/DefaultSpin/DefaultSpin';
import { CreateTaskForm } from 'pages/PageTask/Single/components/CreateTaskModal/CreateTaskForm';
import { CreateTaskModalTitle } from 'pages/PageTask/Single/components/CreateTaskModal/CreateTaskModalTitle';

interface IComponentProps {
  entity?: string;
  personId: string;
  visible: boolean;
  close(): void;
}

export const CreateTaskModal: React.FC<IComponentProps> = ({ close, entity, personId, visible }) => {
  const isLoading = useSelector(state => isLoadingSelector(state));
  const [entityState, setEntity] = useState(entity);

  const Title = CreateTaskModalTitle({
    disabled: !!entity,
    onChange: v => {
      setEntity(v);
    },
    // @ts-ignore
    value: entityState,
  });

  return (
    <Modal
      centered
      destroyOnClose
      footer={null}
      maskClosable={false}
      onCancel={close}
      open={visible}
      title={Title}
    >
      <CreateTaskForm
        // @ts-ignore
        entityState={entityState}
        onCancel={close}
        onSubmit={close}
        personId={personId}
      />

      {isLoading && (
        <DefaultSpin />
      )}
    </Modal>
  );
};
