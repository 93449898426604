import React, { useMemo } from 'react';
import _ from 'lodash';

import { useDictionaries } from 'models/dictionaries/hooks';
import {
  IVerificationResult,
  IVerificationResultType,
} from 'models/dictionaries/types';
import {
  useCheckVerificationResultTypeForComplete,
} from 'models/verifications/hooks/useCheckVerificationResultTypeForComplete';
import {
  AnyVerificationResultValue,
  IVerificationResults,
  TextVerificationResultValue,
} from 'models/verifications/types';

import {
  Spin,
  Tooltip,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import {
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';

import { MultipleChoice } from './Fields/MultipleChoice';
import { SingleChoice } from './Fields/SingleChoice';
import { TextField } from './Fields/TextField';
import {
  EntityCard,
  IconComplete,
  IconUpload,
  IconWarn,
} from './styled';

interface ICheckListProps {
  isDisabled?: boolean;
  isLoading: boolean;
  openUploadModal: (documentTypeName: string) => void;
  results: IVerificationResults;
  setResults: (results: IVerificationResults) => void;
  showRequired?: boolean;
  updateResults: (options: { omitNotification?: boolean }) => void;
  verificationResultTypeId: IVerificationResult[`verificationResultTypeId`];
}

export const CheckList: React.FC<ICheckListProps> = ({
  isDisabled = false,
  isLoading,
  openUploadModal,
  results,
  setResults,
  showRequired = false,
  updateResults,
  verificationResultTypeId,
}) => {
  const onChange = (value: AnyVerificationResultValue) => {
    setResults({
      ...results,
      [verificationResultTypeId]: value,
    });
    setTimeout(() => updateResults({ omitNotification: true }), 200);
  };

  const [isDictionariesLoading, dictionaries] = useDictionaries([`verificationResultType`, `verificationResult`]);
  const value: AnyVerificationResultValue = results?.[verificationResultTypeId];

  const verificationResultType = _.find(
    dictionaries?.verificationResultType,
    { id: verificationResultTypeId },
  ) as IVerificationResultType;

  const { description, fieldType, name } = verificationResultType;
  const checkComplete = useCheckVerificationResultTypeForComplete(results);
  const isComplete = useMemo(() => checkComplete(verificationResultTypeId),
    [checkComplete, verificationResultTypeId]);

  if (_.isEmpty(verificationResultType)) return null;

  const verificationResults = _.filter(
    dictionaries?.verificationResult,
    { verificationResultTypeId },
  ) as IVerificationResult[];

  return (
    <Spin spinning={isLoading || isDictionariesLoading}>
      <EntityCard>
        <EntityCardTitle>
          {description || name}
          <div>
            {verificationResultType?.documentTypeName && (
              <Tooltip title={`Загрузить ${verificationResultType?.documentTypeName}`}>
                <IconUpload onClick={() => openUploadModal(verificationResultType?.documentTypeName)} />
              </Tooltip>
            )}
            {isComplete && <IconComplete />}
            {showRequired && !isComplete && (
              <DefaultTooltip title='Необходимо заполнить'>
                <IconWarn />
              </DefaultTooltip>
            )}
          </div>
        </EntityCardTitle>
        <EntityCardContent>
          {fieldType === 1 && (
            <SingleChoice
              isDisabled={isDisabled}
              onChange={onChange}
              value={value as number}
              verificationResults={verificationResults}
            />
          )}
          {fieldType === 2 && (
            <MultipleChoice
              isDisabled={isDisabled}
              onChange={onChange}
              value={value as number[]}
              verificationResults={verificationResults}
            />
          )}
          {fieldType === 3 && (
            <TextField
              isDisabled={isDisabled}
              onChange={onChange}
              value={value as TextVerificationResultValue}
              verificationResults={verificationResults}
            />
          )}
        </EntityCardContent>
      </EntityCard>
    </Spin>
  );
};
