import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import qs from 'query-string';

import { hasIntersection } from 'helper';

import {
  getClientApplications as getClientApplicationsAction,
  getClientCarInfo as getClientCarInfoAction,
  getClientCars as getClientCarsAction,
  setVinEditedCar as setVinEditedCarAction,
  updateClientCar as updateClientCarAction,
  updateClientGibddAndFnpService as updateClientGibddAndFnpServiceAction,
  updateClientRsaService as updateClientRsaServiceAction,
} from 'models/clients/actions';
import {
  APPLICATION_STATUS_WEIGHTS,
  APPLICATION_STATUSES,
} from 'models/clients/constants';
import {
  getClientCarsWithApplications as getClientCarsSelector,
  getIsLoading as isLoadingSelector,
  getIsLoadingCarInfo as getIsLoadingCarInfoSelector,
  getIsLoadingCarService as getIsLoadingCarServiceSelector,
  getVinEditedCars as getVinEditedCarsSelector,
} from 'models/clients/selectors';
import {
  CAR_EDIT,
  SUPER_ADMIN,
} from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { usePath } from 'hooks/usePath';
import { useQueryProps } from 'hooks/useQueryProps';

import { Empty } from 'antd';
import { CarCard } from 'pages/PageClient/Single/Car/CarCard';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import { EntityCardsGridWrapper } from 'pages/PageClient/Single/components/EntityCard/EntityCardsGridWrapper';
import { TabContentWrapper } from 'pages/PageClient/Single/components/TabContentWrapper/TabContentWrapper';

import EditModal from './Modal/Edit';

const PageClientSingleCar = () => {
  const dispatch = useDispatch();
  const { personId } = useContext(ClientMatchContext);

  const clientCars = useSelector(state => getClientCarsSelector(state, personId));
  const carsWithApplication = _.filter(clientCars, `application`);
  const carsWithoutApplications = _.reject(clientCars, `application`);

  const cars = [..._.orderBy(carsWithApplication, ({ application: { applicationDtm, applicationStatusId } }) => (
    (APPLICATION_STATUS_WEIGHTS[applicationStatusId] * 10000000000000) + applicationDtm
  ), `desc`), ...carsWithoutApplications];

  const isLoadingCarInfo = useSelector(getIsLoadingCarInfoSelector);
  const isLoadingCarService = useSelector(getIsLoadingCarServiceSelector);
  const userRoles = useSelector(getRoleNamesSelector);
  const vinEditedCars = useSelector(getVinEditedCarsSelector);
  const isLoading = useSelector(isLoadingSelector);

  const getClientApplications = callback => dispatch(getClientApplicationsAction({ callback, personId }));
  const getCarInfo = (vin, callback) => dispatch(getClientCarInfoAction({ callback, personId, vin }));
  const getCars = (force, callback) => dispatch(getClientCarsAction({ callback, force, personId }));
  const setVinEditedCar = value => dispatch(setVinEditedCarAction(value));
  const updateCarDispatch = (carId, carInfo) => dispatch(updateClientCarAction({ carId, carInfo, personId }));
  const updateGibddAndFnpService = vin => dispatch(updateClientGibddAndFnpServiceAction({ personId, vin }));
  const updateRsaService = ({ carNumber, vin }) => dispatch(updateClientRsaServiceAction({
    carNumber,
    personId,
    vin,
  }));
  const { applicationId, carEdit: carEditNumber } = useQueryProps() || {};
  const { replacePath } = usePath();

  const carNumberOnVerification = useMemo(() => _.find(
    clientCars,
    ({ application }) => application && application.applicationStatusId === APPLICATION_STATUSES.VERIFICATION
      && !application.applicationCloseDtm,
  )?.carNumber, [_.size(clientCars)]);

  const [editingCar, setEditingCar] = useState(null);
  const [isEditModalOpen, setIsModalOpen] = useState(false);

  const updateCar = carInfo => updateCarDispatch(personId, carInfo);

  const openEditModal = _cars => {
    setEditingCar(_.find(_cars, car => (
      (!applicationId || _.some(car.applicationIds, id => id === applicationId))
      && car.carNumber === carEditNumber
    )));
    setIsModalOpen(true);
  };
  const closeEditModal = () => setIsModalOpen(false);

  const getCarsWithApplications = (force, callback) => {
    getClientApplications(() => getCars(force, callback));
  };

  useEffect(() => {
    if (_.isEmpty(cars)) {
      getCarsWithApplications(false, (carsResult = []) => {
        if (carEditNumber) openEditModal(carsResult);
      });
    } else if (carEditNumber) {
      openEditModal(cars);
    }

    const query = qs.parse(window.location.search) || {};
    const nextQuery = qs.stringify(_.omit(query, [`carEdit`, `applicationId`]));
    replacePath(`${window.location.pathname}?${nextQuery}`);
  }, []);

  const allowedRoles = [SUPER_ADMIN, CAR_EDIT];
  const canEdit = hasIntersection(userRoles, allowedRoles);

  return (
    <ClientTab isReloading={isLoading} onReload={() => getCarsWithApplications(true)}>
      <TabContentWrapper>
        {_.isEmpty(cars)
          ? (
            <Empty
              description='Автомобили не найдены или не загружены - попробуйте загрузить вручную кнопкой сверху '
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )
          : (
            <EntityCardsGridWrapper>
              {_.map(cars, (car, index) => (
                <CarCard
                  allowedRoles={allowedRoles}
                  car={car}
                  isHighlighted={index === 0}
                  key={car.id}
                  onEditClick={() => {
                    setEditingCar(car);
                    setIsModalOpen(true);
                  }}
                />
              ))}
            </EntityCardsGridWrapper>
          )}

        {canEdit && (
          <EditModal
            car={editingCar}
            carNumberOnVerification={carNumberOnVerification}
            close={closeEditModal}
            getCarInfo={getCarInfo}
            isLoading={isLoadingCarInfo || isLoadingCarService}
            setVinEditedCar={setVinEditedCar}
            update={updateCar}
            updateGibddAndFnpService={updateGibddAndFnpService}
            updateRsaService={updateRsaService}
            vinEditedCars={vinEditedCars}
            visible={isEditModalOpen}
          />
        )}
      </TabContentWrapper>
    </ClientTab>
  );
};

export default PageClientSingleCar;
