import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { getClientApplicationGeolocation as getClientApplicationGeolocationAction } from 'models/clients/actions';
import {
  getClient as getClientSelector,
  getClientApplication as getClientApplicationSelector,
  getClientApplicationGeolocation as getClientApplicationGeolocationSelector,
  getIsLoading as isLoadingSelector,
} from 'models/clients/selectors';
import { getItem as getVerificationSelector } from 'models/verifications/selectors';

import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import clientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';

import Map from './Map';

import {
  ADDRESS_TITLES,
  ICONS,
} from './constants';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  max-height: calc(100vh - 125px);
  position: relative;
  width: 100%;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    overflow-y: scroll;
    min-height: 100%;
  }
`;

const Info = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid ${palette.black30Color};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  position: absolute;
  right: 24px;
  top: 24px;
  max-width: 400px;

  ${({ isInfoOpen }) => !isInfoOpen && `
    padding: 4px;
  `};

  @media screen and (max-width: 767px) {
    max-width: calc(100% - 48px);
    padding-left: 8px;

    ${({ isInfoOpen }) => isInfoOpen && `
      width: 100%;
    `};
  }
`;

const InfoWrapper = styled.div`
  @media screen and (max-width: 767px) {
    position: relative;
    height: auto;
  }
`;

const AddressTitle = styled.p`
  font-weight: 700;
`;

const GeolocationDenied = styled.p`
  color: ${palette.alert500Color};
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 4px;
  top: 4px;
  border: none;
  width: 28px;
  height: 28px;
  box-shadow: none;

  ${({ isInfoOpen }) => !isInfoOpen && `
    position: static;
  `};
`;

const AddressItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 16px;

  &+& {
    margin-top: 16px;
  }

  @media screen and (max-width: 767px) {
    &+& {
      margin-top: 12px;
    }
  }
`;

const Pin = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const Geolocation = () => {
  const dispatch = useDispatch();

  const { personId, verificationId } = useContext(clientMatchContext);

  const {
    applicationId,
    client,
    dealershipId,
    geolocationState,
    isLoading,
  } = useSelector(state => {
    const verification = getVerificationSelector(state, verificationId);
    const applicationIdInner = _.get(verification, `applicationId`);
    const application = getClientApplicationSelector(state, personId, applicationIdInner);

    return {
      applicationId   : applicationIdInner,
      client          : getClientSelector(state, personId),
      dealershipId    : _.get(application, `dealershipId`),
      geolocationState: getClientApplicationGeolocationSelector(state, personId, applicationIdInner),
      isLoading       : isLoadingSelector(state),
    };
  });

  const getApplicationGeolocation = () => dispatch(getClientApplicationGeolocationAction({
    applicationId,
    dealershipId,
    personId,
  }));

  const addresses = {
    addressFact: _.get(_.find(_.get(client, `addressesFact`), { applicationId }), `address`),
    addressReg : _.get(_.find(_.get(client, `addressesReg`), { applicationId }), `address`),
  };

  const geolocation = _.map(geolocationState, item => (_.includes([`addressFact`, `addressReg`], item.type) ? {
    address: addresses[item.type],
    ...item,
  } : item));

  const [addressHovered, setAddressHovered] = useState(null);
  const [addressSelected, setAddressSelected] = useState(null);
  const [isInfoOpen, setIsInfoOpen] = useState(true);

  const toggleInfoOpen = () => setIsInfoOpen(p => !p);
  const onAddressClick = address => setAddressSelected(address);
  const onAddressHoverOn = address => setAddressHovered(address);
  const onAddressHoverOff = () => setAddressHovered(null);

  useEffect(() => {
    getApplicationGeolocation()
  }, []);

  return (
    <ClientTab isReloading={isLoading} onReload={getApplicationGeolocation}>
      <Wrapper>
        <Map
          addressHovered={addressHovered}
          addressSelected={addressSelected}
          points={geolocation}
        />
        <InfoWrapper>
          <Info isInfoOpen={isInfoOpen}>
            <CloseButton
              icon={isInfoOpen ? <UpOutlined /> : <DownOutlined />}
              isInfoOpen={isInfoOpen}
              onClick={toggleInfoOpen}
            />
            {isInfoOpen && _.map(geolocation, ({ address, coordinates, type }) => {
              const isDenied = coordinates === `denied`;

              return (
                <AddressItem
                  key={type}
                  onClick={() => onAddressClick(type)}
                  onMouseEnter={() => onAddressHoverOn(type)}
                  onMouseLeave={() => onAddressHoverOff(type)}
                >
                  <Pin src={ICONS[type]} />
                  <div>
                    <AddressTitle isDenied={isDenied}>
                      {`${ADDRESS_TITLES[type]}${address ? `:` : ``}`}
                    </AddressTitle>
                    {isDenied && (
                      <GeolocationDenied>(Нет разрешения)</GeolocationDenied>
                    )}
                    <p>{address}</p>
                  </div>
                </AddressItem>
              );
            })}
          </Info>
        </InfoWrapper>
      </Wrapper>
    </ClientTab>
  );
};

export default Geolocation;
