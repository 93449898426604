import * as React from 'react';
import { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import { Link as LinkDefault } from 'dva/router';
import moment from 'moment';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { WOOPRA_PERSONAL_URL } from 'constants/WOOPRA';
import {
  copyToClipboard,
  getShortened,
} from 'helper';

import {
  getDateFormatted,
  getFullName,
  getGenderIconTooltip,
} from 'models/clients/helpers';
import { getClientsRecent } from 'models/clients/selectors';
import {
  ILoanClient,
  IRecent,
} from 'models/clients/types';

import {
  CopyOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { getItems } from 'pages/PageClient/Plural/components/ClientItem/helpers';
import {
  DropdownWrapper,
  EntityCardContentStyled,
  EntityCardRowStyled,
  EntityCardTitleControlStyled,
  EntityCardTitleStyled,
  RotateWrapper,
  WoopraIcon,
} from 'pages/PageClient/Plural/components/ClientItem/styled';
import { ClientItemDropdown } from 'pages/PageClient/Plural/components/ClientItemDropdown';
import { EntityCard } from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';

const Link = styled(LinkDefault)<{ $highlighted?: boolean }>`
  ${p => p.$highlighted && `
    color: ${palette.primaryColor(p)} !important;
    text-decoration: underline !important;
  `}
`;

interface IComponentProps {
  client: ILoanClient;
  isExpanded: boolean;
  onExpandedChange(): void;
}

export const ClientItem: React.FC<IComponentProps> = ({ client, isExpanded, onExpandedChange }) => {
  const { personId } = client;

  const fullName = useMemo(() => getFullName(client), [personId]);
  const items = useMemo(
    () => getItems(client),
    [personId],
  );
  const recent: IRecent[] = useSelector(getClientsRecent);
  const recentItem = _.find(recent, { personId });
  let isRecent = false;
  if (recentItem) {
    const updatedAt = moment(recentItem.updatedAt);
    const now = moment();
    const hoursDiff = now.diff(updatedAt, `hours`);
    isRecent = hoursDiff < 24;
  }

  const isOnlyPhone = !fullName && !client?.birthDate;
  const clientUrl = `/client/person/${personId}/info`;

  return (
    <EntityCard>
      <EntityCardTitleStyled $isExpanded={isExpanded && !isOnlyPhone}>
        {isOnlyPhone
          ? (
            <span>
              {getGenderIconTooltip(client.gender)}
              <Link $highlighted={isRecent} to={clientUrl}>
                {`${client?.phone} (ФИО не указано)`}
              </Link>
              {/*@ts-ignore*/}
              <EntityCardTitleControl
                actionTitle='Копировать телефон'
                onClick={e => {
                  e.stopPropagation();
                  copyToClipboard(client?.phone);
                }}
              >
                <CopyOutlined />
              </EntityCardTitleControl>
            </span>
          )
          : (
            <span>
              {getGenderIconTooltip(client.gender)}
              <Link $highlighted={isRecent} to={clientUrl}>
                {`${fullName} ${getDateFormatted(client, `birthDate`) || ``}`}
              </Link>
              {/*@ts-ignore*/}
              <EntityCardTitleControl
                actionTitle='Копировать ФИО'
                onClick={e => {
                  e.stopPropagation();
                  copyToClipboard(fullName);
                }}
              >
                <CopyOutlined />
              </EntityCardTitleControl>
            </span>
          )}

        <span>
          <Link $highlighted={isRecent} to={clientUrl}>
            {getShortened(personId)}
          </Link>
          {/*@ts-ignore*/}
          <EntityCardTitleControl
            actionTitle='Копировать personId'
            onClick={e => {
              e.stopPropagation();
              copyToClipboard(personId);
            }}
          >
            <CopyOutlined />
          </EntityCardTitleControl>
        </span>

        <DropdownWrapper>
          {/*@ts-ignore*/}
          <EntityCardTitleControlStyled
            actionTitle={isExpanded ? `Скрыть` : `Раскрыть`}
            onClick={e => {
              e.stopPropagation();
              onExpandedChange();
            }}
          >
            <RotateWrapper $up={isExpanded}>
              <RightOutlined />
            </RotateWrapper>
          </EntityCardTitleControlStyled>
          {/*@ts-ignore*/}
          <EntityCardTitleControlStyled
            actionTitle='Woopra'
            onClick={e => {
              e.stopPropagation();
              window.open(WOOPRA_PERSONAL_URL(personId), `_blank`);
            }}
          >
            <WoopraIcon />
          </EntityCardTitleControlStyled>
          {/*@ts-ignore*/}
          <EntityCardTitleControlStyled actionTitle='Действия'>
            <ClientItemDropdown client={client} />
          </EntityCardTitleControlStyled>
        </DropdownWrapper>
      </EntityCardTitleStyled>

      {!isOnlyPhone && isExpanded && (
        <EntityCardContentStyled>
          {_.map(items, ({ key, name, value }, index) => (
            // @ts-ignore
            <EntityCardRowStyled
              compact
              dark
              key={index}
              title={name}
              valueToCopy={value || key}
            >
              {value || key}
            </EntityCardRowStyled>
          ))}
        </EntityCardContentStyled>
      )}
    </EntityCard>
  );
};
