import * as React from 'react';
import {
  useCallback,
  useMemo,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  getFullNameInitials,
  getPlural,
} from 'helper';

import { useDictionaries } from 'models/dictionaries/hooks';
import {
  get as getAction,
  remove,
  reset as resetAction,
  setOperatorMultiple as setOperatorMultipleAction,
  setStatusMultiple as setStatusMultipleAction,
} from 'models/lead/actions';
import {
  DEFAULT_TABLE_STATE,
  LEAD_STATUS_NAMES,
} from 'models/lead/constants';
import { getCurrent as getCurrentOperatorSelector } from 'models/operators/selectors';
import { IOperator } from 'models/operators/types';

import { useHasRoles } from 'hooks/useHasRoles';

import {
  ClearOutlined,
  DeleteOutlined,
  IssuesCloseOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button as ButtonDefault,
  Checkbox,
  Modal,
  Select,
} from 'antd';
import { SubHeader } from 'components/SubHeader/SubHeader';
import { ADMIN_ROLES } from 'pages/PageLead/Plural/constants';

import { OnTableChange } from 'types/OnTableChange';
import { TableState } from 'types/TableState';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  @media(max-width: 1278px) {
    margin-bottom: 12px;
  }

  @media(max-width: 848px) {
    justify-content: center;
  }

  @media(max-width: 500px) {
    flex-wrap: wrap;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media(max-width: 848px) {
    justify-content: center;
  }

`;

const StyledCheckbox = styled(Checkbox)`
  color: ${palette.whiteColor};

  @media(max-width: 500px) {
    margin-bottom: 12px;
  }
`;

const StyledSelect = styled(Select)`
  min-width: 200px;
  width: 100%;
`;

const Button = styled(ButtonDefault)`
  &+& {
    margin-left: 8px;
  }

  @media(max-width: 848px) {
    margin-bottom: 12px;
    min-width: 200px;
  }
  @media(max-width: 500px) {
    width: 100%;
    margin-left: 0 !important;
  }`;

interface IProps {
  onCreate: () => void;
  onTableChange: OnTableChange;
  operatorsToSelect: IOperator[];
  selectedRowKeys: number[];
  tableState: TableState;
}

export const LeadsHeader: React.FC<IProps> = ({
  onCreate,
  onTableChange,
  operatorsToSelect,
  selectedRowKeys,
  tableState,
}) => {
  const dispatch = useDispatch();

  const [isOperatorModalOpen, setIsOperatorModalOpen] = React.useState<boolean>(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = React.useState<boolean>(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = React.useState<boolean>(false);
  const [leadStatusId, setLeadStatusId] = React.useState<number | null>(null);
  const [operatorId, setOperatorId] = React.useState<number | null>(null);

  const toggleOperatorModal = useCallback(() => setIsOperatorModalOpen(!isOperatorModalOpen), [isOperatorModalOpen]);
  const toggleRemoveModal = useCallback(() => setIsRemoveModalOpen(!isRemoveModalOpen), [isRemoveModalOpen]);
  const toggleStatusModal = useCallback(() => setIsStatusModalOpen(!isStatusModalOpen), [isStatusModalOpen]);

  const hasRoles = useHasRoles();
  const isAdmin = hasRoles(ADMIN_ROLES);

  const resetFilters = useCallback(() => {
    dispatch(resetAction());
    dispatch(getAction());
  }, [dispatch]);

  const currentOperator = useSelector(state => getCurrentOperatorSelector(state));
  const [, dictionaries] = useDictionaries([`leadStatus`]);

  const statusOptions = _.map(dictionaries?.leadStatus, leadStatus => ({
    label: leadStatus?.description || leadStatus?.name,
    value: leadStatus?.id,
  }));

  const operatorOptions = [
    { label: `Убрать оператора`, value: null },
    ..._.map(operatorsToSelect, operator => ({
      label: getFullNameInitials(operator?.user),
      value: operator?.id,
    }))];

  const newLeadStatusId = useMemo(
    () => _.find(dictionaries?.leadStatus, { name: LEAD_STATUS_NAMES.NEW })?.id,
    [dictionaries],
  );

  const changeStatus = () => {
    dispatch(setStatusMultipleAction({
      ids: selectedRowKeys,
      leadStatusId,
    }));
    toggleStatusModal();
  };

  const changeOperator = () => {
    dispatch(setOperatorMultipleAction({
      ids: selectedRowKeys,
      operatorId,
    }));
    toggleOperatorModal();
  };

  const removeLeads = () => {
    dispatch(remove({ ids: selectedRowKeys }));
    toggleRemoveModal();
  };

  const isOnlyCurrentOperatorLeadsShown = _.size(tableState?.filters?.operatorId) === 1
    && tableState?.filters?.operatorId?.[0] === currentOperator?.id;

  const isOnlyNewLeadsShown = _.size(tableState?.filters?.leadStatusId) === 1
    && tableState?.filters?.leadStatusId?.[0] === newLeadStatusId;

  const isOnlyNeedCallLeadsShown = _.size(tableState?.filters?.needCall) === 1
    && !!tableState?.filters?.needCall?.[0];

  const isFilterActive = (filterKey: string, filterValue: number | boolean) => (
    _.isArray(tableState?.filters?.[filterKey])
    && tableState?.filters?.[filterKey]?.[0] === filterValue
  );

  const changeFilters = (filterKey: string, filterValue: number | boolean) => {
    const newFilters = isFilterActive(filterKey, filterValue)
      ? _.omit(tableState?.filters, [filterKey])
      : {
        ...tableState?.filters,
        [filterKey]: [filterValue],
      };

    onTableChange(
      {
        ...DEFAULT_TABLE_STATE.pagination,
        pageSize: tableState?.pagination?.pageSize || DEFAULT_TABLE_STATE.pagination.pageSize,
      },
      // @ts-ignore
      newFilters,
      tableState?.sorter,
    );
  };

  const showOnlyCurrentOperatorLeads = useCallback(() => {
    changeFilters(`operatorId`, currentOperator?.id);
  }, [changeFilters, currentOperator?.id]);

  const showOnlyNewLeads = useCallback(() => {
    if (newLeadStatusId !== undefined) {
      changeFilters(`leadStatusId`, newLeadStatusId);
    }
  }, [changeFilters, newLeadStatusId]);

  const showOnlyNeedCallLeads = useCallback(() => {
    changeFilters(`needCall`, true);
  }, [changeFilters]);

  const selectedRowKeysSize = _.size(selectedRowKeys);
  const leadsPluralTitle = getPlural(selectedRowKeysSize, `лида`, `лидов`, `лидов`);

  return (
    <SubHeader>
      <Wrapper>
        <CheckboxWrapper>
          <StyledCheckbox
            checked={isOnlyCurrentOperatorLeadsShown}
            onChange={showOnlyCurrentOperatorLeads}
          >
            Cвои задания
          </StyledCheckbox>
          <StyledCheckbox
            checked={isOnlyNewLeadsShown}
            onChange={showOnlyNewLeads}
          >
            Новые задания
          </StyledCheckbox>
          <StyledCheckbox
            checked={isOnlyNeedCallLeadsShown}
            onChange={showOnlyNeedCallLeads}
          >
            Нужен звонок
          </StyledCheckbox>
        </CheckboxWrapper>
        <ButtonWrapper>

          <Button icon={<PlusOutlined />} onClick={onCreate}>Создать</Button>
          {isAdmin && (
          <>
            <Button
              disabled={!selectedRowKeysSize}
              icon={<IssuesCloseOutlined />}
              onClick={toggleStatusModal}
            >
              Изменить статус
            </Button>
            <Button
              disabled={!selectedRowKeysSize}
              icon={<UserOutlined />}
              onClick={toggleOperatorModal}
            >
              Изменить оператора
            </Button>
            <Button
              danger
              disabled={!selectedRowKeysSize}
              icon={<DeleteOutlined />}
              onClick={toggleRemoveModal}
              type='primary'
            >
              Удалить
            </Button>
            <Modal
              okButtonProps={{ type: `primary`, disabled: !leadStatusId }}
              onCancel={toggleStatusModal}
              onOk={changeStatus}
              open={isStatusModalOpen}
              title={`Смена статуса у ${selectedRowKeysSize} ${leadsPluralTitle}`}
            >
              <StyledSelect
                // @ts-ignore
                onChange={setLeadStatusId}
                options={statusOptions}
                value={leadStatusId}
              />
            </Modal>
            <Modal
              okButtonProps={{ danger: true, type: `primary` }}
              onCancel={toggleRemoveModal}
              onOk={removeLeads}
              open={isRemoveModalOpen}
              title={`Удаление ${selectedRowKeysSize} ${leadsPluralTitle}`}
            >
              <p>Вы уверены, что хотите удалить выбранные лиды?</p>
            </Modal>
            <Modal
              okButtonProps={{ type: `primary` }}
              onCancel={toggleOperatorModal}
              onOk={changeOperator}
              open={isOperatorModalOpen}
              title={`Смена оператора у ${selectedRowKeysSize} ${leadsPluralTitle}`}
            >
              <StyledSelect
                // @ts-ignore
                onChange={setOperatorId}
                options={operatorOptions}
                value={operatorId}
              />
            </Modal>
          </>
          )}
          <Button icon={<ClearOutlined />} onClick={resetFilters} type='primary'>Сбросить фильтры</Button>
        </ButtonWrapper>
      </Wrapper>
    </SubHeader>
  );
};
