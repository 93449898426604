import React from 'react';
import reactStringReplace from 'react-string-replace';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import api from 'api/index';
import { DADATA_CLEANER_URL } from 'constants/DADATA';

import { CopyIconButton } from 'components/CopyIconButton/CopyIconButton';

export const dadataCleanName = name => api.post(DADATA_CLEANER_URL, [name]);

const Link = styled.a`
  color: ${palette.primary500Color};
  font-weight: 600;

  &:hover {
    color: ${palette.primary600Color};
  }
`;

const Wrapper = styled.span`
  display: inline-flex;
`;

export const parseAssigneeDescription = description => {
  let replaced = reactStringReplace(
    description,
    /(Юридический|Тел|ИНН|КПП|ОГРН|р\/с|БИК|к\/с)/g,
    (match, index) => (
      <React.Fragment key={`${match}-${index}`}>
        <br />
        {match}
      </React.Fragment>
    ),
  );

  replaced = reactStringReplace(replaced, /(\+*[78]\s+\(\d{3}\)\s+\d{3}-\d{2}-\d{2})/g, (match, index) => (
    <Link href={`tel:${match}`} key={`${match}-${index}`}>
      {match}
    </Link>
  ));

  replaced = reactStringReplace(replaced, /([0-9]{9,20})/g, (match, index) => (
    <Wrapper key={`${match}-${index}`}>
      <span>{match}</span>
      <CopyIconButton as='span' valueToCopy={match} />
    </Wrapper>
  ));

  return replaced;
};
