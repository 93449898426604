import React from 'react';
import _ from 'lodash';

export enum EThemeName {
  LIGHT = `light`,
  DARK = `dark`,
}

export interface TThemeContext {
  setThemeName: (themeName: EThemeName) => void;
  themeName: EThemeName;
}

export const GlobalThemeContext = React.createContext<TThemeContext>({
  themeName   : EThemeName.LIGHT,
  setThemeName: _.noop,
});
