import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(getState, ({ items }) => items);

export const getCount = createSelector(getState, ({ count }) => count);

export const getIsLoading = createSelector(getState, ({ isLoading }) => isLoading);

export const getIsCountLoading = createSelector(getState, ({ isCountLoading }) => isCountLoading);

export const getNotificationsTotal = createSelector(getState, ({ total }) => total);

export const getNotificationsFilter = createSelector(getState, ({ filter }) => filter);
