import * as React from 'react';
import styled from 'styled-components';

import { Layout } from 'antd';
import { DefaultSpin } from 'components/DefaultSpin/DefaultSpin';
import { ScrollbarsDesktop } from 'components/ScrollbarsDesktop/ScrollbarsDesktop';
import ClientSubHeader from 'pages/PageClient/Single/components/ClientSubHeader/ClientSubHeader';

const Wrapper = styled(Layout)`
  position: relative;
  height: 100%;
`;

const Content = styled(Layout.Content)`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0 !important;
  position: relative;
`;

interface IComponentProps {
  children?: any;
  isReloading?: boolean;
  onReload?(): void;
}
export const ClientTab = React.forwardRef<HTMLDivElement, IComponentProps>((props, ref) => {
  const { children, isReloading, onReload } = props;
  return (
    <Wrapper ref={ref}>
      <ClientSubHeader isReloading={isReloading} onReload={onReload} />
      <Content>
        {isReloading && <DefaultSpin delay={900} />}
        <ScrollbarsDesktop>
          {children}
        </ScrollbarsDesktop>
      </Content>
    </Wrapper>
  );
});
