import React from 'react';
import _ from 'lodash';
import { intercom } from '@cashdrive/api/socket';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { logout } from 'models/user/actions';

import {
  Button,
  notification,
} from 'antd';

import {
  GET_LATEST,
  model as namespace,
  SAVE,
  SHOW_NOTIFICATION,
} from './actions';
import * as api from './api';

const initialState = {};

export default {
  namespace,
  state        : initialState,
  subscriptions: {
    setup({ dispatch }) {
      if (import.meta.env.VITE_NODE_ENV === `production`) {
        intercom.subscribe(`SOCKET_VERSION`, ({ payload: [type, payload] }) => {
          switch (type) {
            case `setVersion`:
              dispatch({
                payload: { callback: () => dispatch(logout()), commitHash: payload },
                type   : SHOW_NOTIFICATION,
              });
              break;

            default:
              break;
          }
        });
      }
    },
  },
  effects: {
    *[GET_LATEST](action, { call, put }) {
      const defaultError = `При загрузке последней версии произошла ошибка`;

      try {
        const latest = parseResponse({
          defaultError,
          response: yield call(api.getLatest),
        });

        if ((process.env.COMMIT_HASH !== latest.commitHash && new Date(process.env.COMMIT_DATE) > latest.createdAt) || _.isEmpty(latest)) {
          yield put({ type: SAVE, payload: { commitHash: process.env.COMMIT_HASH } });
        }

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      }
    },
    *[SAVE](action, { call }) {
      const { commitHash } = action.payload;
      const defaultError = `При сохранении версии произошла ошибка`;

      try {
        if (!commitHash) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.set, { commitHash }),
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      }
    },
    [SHOW_NOTIFICATION](action) {
      const { callback, commitHash } = action.payload;
      const defaultError = `При показе уведомления о версии '${commitHash}' произошла ошибка`;

      try {
        if (!commitHash || !_.isFunction(callback)) throw new Error(defaultError);

        if (process.env.COMMIT_HASH !== commitHash) {
          const key = `newVersion`;
          const onClose = () => {
            notification.close(key);
            callback();
            window.location.reload();
          };

          notification.info({
            btn: (
              <Button onClick={onClose}>Обновить</Button>
            ),
            duration: 0,
            key,
            message : `Обнаружена новая версия CRM`,
            onClose,
          });
        }
      } catch (error) {
        showError({ defaultError, error });
      }
    },
  },
};
