import React from 'react';
import _ from 'lodash';

import {
  formatDate,
  getColumn,
  getFullNameInitials,
  prettifyNumber,
} from 'helper';

import { getFullName } from 'models/clients/helpers';
import {
  CAMPAIGNS_WITH_SEARCH_PARTNER,
  TASK_BLOCK_TITLE,
} from 'models/task/constants';
import { getSegmentColor } from 'models/task/helpers';

import { SearchOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import DateFilterDropDown from 'components/DateFilterDropDown';
import { CellId } from 'components/DefaultTable/CellId/CellId';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import StringFilterDropDown from 'components/StringFilterDropDown';

const getSortOrder = (sorter, column) => (sorter?.columnKey === column ? sorter.order : undefined);
const getCampaignTypeColumn = ({ campaignTypes, tableState }) => ({
  title         : `Тип`,
  align         : `center`,
  dataIndex     : `campaignType`,
  filtered      : !_.isEmpty(tableState?.filters?.campaignTypeId),
  filteredValue : tableState?.filters?.campaignTypeId,
  filterMultiple: true,
  filters       : _.filter(_.map(campaignTypes, ({ campaignName: cn, description, id, name }) => ({
    text : `${description || name}${cn ? ` (${cn})` : ``}`,
    value: id,
  })), `value`),
  key   : `campaignTypeId`,
  render: campaignType => {
    if (!campaignType) return ``;
    // eslint-disable-next-line max-len
    return `${campaignType.description || campaignType.name}${campaignType.campaignName ? ` (${campaignType.campaignName})` : ``}`;
  },
  sorter   : true,
  sortOrder: getSortOrder(tableState.sorter, `campaignType`),
  width    : 180,
});

const getPriorityColumn = ({ filterOptions, tableState }) => ({
  title         : `Приоритет`,
  align         : `center`,
  filtered      : !_.isEmpty(tableState?.filters?.priority),
  filteredValue : tableState?.filters?.priority,
  filterMultiple: true,
  filters       : _.map(filterOptions.priorities, value => ({
    text: value,
    value,
  })),
  key      : `priority`,
  sorter   : true,
  sortOrder: getSortOrder(tableState.sorter, `priority`),
  width    : 110,
});

const getSegmentColumn = ({ filterOptions, tableState }) => ({
  title         : `Сегмент`,
  align         : `center`,
  filtered      : !_.isEmpty(tableState?.filters?.segment),
  filteredValue : tableState?.filters?.segment,
  filterMultiple: true,
  filters       : _.map(filterOptions.segments, value => ({
    text: value,
    value,
  })),
  key   : `segment`,
  render: segment => (
    <DefaultTag color={getSegmentColor(segment)}>{segment}</DefaultTag>
  ),
  sorter   : true,
  sortOrder: getSortOrder(tableState.sorter, `segment`),
  width    : 280,
});

const getTaskStatusColumn = ({ tableState, taskStatuses }) => ({
  title         : `Статус`,
  align         : `center`,
  dataIndex     : `taskStatus`,
  filtered      : !_.isEmpty(tableState?.filters?.taskStatusId),
  filteredValue : tableState?.filters?.taskStatusId,
  filterMultiple: true,
  filters       : _.filter(_.map(taskStatuses, taskStatus => ({
    text: (
      <DefaultTag
        color={taskStatus?.color}
      >
        {/* eslint-disable-next-line max-len */}
        {`${taskStatus?.description || taskStatus?.name || ``}${taskStatus?.campaignName ? ` (${taskStatus?.campaignName})` : ``}`}
      </DefaultTag>),
    value: taskStatus?.id || null,
  })), `value`),
  key   : `taskStatusId`,
  render: taskStatus => (
    <Tag color={taskStatus?.color}>
      {/* eslint-disable-next-line max-len */}
      {`${taskStatus?.description || taskStatus?.name || ``}${taskStatus?.campaignName ? ` (${taskStatus?.campaignName})` : ``}`}
    </Tag>
  ),
  sorter   : true,
  sortOrder: getSortOrder(tableState.sorter, `taskStatus`),
  width    : 270,
});

export const getTasksColumns = ({
  block,
  campaignName,
  campaignTypes,
  filterOptions,
  hmParkings,
  hmSearchPartners,
  operatorsToSelect,
  tableState,
  taskStatuses,
}) => {
  const isSearchPartnerColumnShown = _.includes(CAMPAIGNS_WITH_SEARCH_PARTNER, campaignName);

  return _.map([
    {
      title         : `Дата создания`,
      key           : `createdDtm`,
      filterDropdown: ({ confirm, selectedKeys, setSelectedKeys, visible }) => (
        <DateFilterDropDown
          confirm={confirm}
          isVisible={visible}
          placeholder={[`с`, `по`]}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      ),
      filtered     : !_.isEmpty(tableState?.filters?.createdDtm),
      filteredValue: tableState?.filters?.createdDtm,
      render       : v => formatDate(new Date(v), `dd.MM.yyyy`),
      sorter       : true,
      sortOrder    : getSortOrder(tableState.sorter, `createdDtm`),
      width        : 140,
    },
    {
      title    : `#`,
      align    : `right`,
      key      : `id`,
      sorter   : true,
      sortOrder: getSortOrder(tableState.sorter, `id`),
      width    : 70,
    },
    {
      title         : `personId`,
      align         : `center`,
      key           : `personId`,
      width         : 90,
      render        : personId => <CellId value={personId} />,
      filterDropdown: ({ confirm, selectedKeys, setSelectedKeys, visible }) => (
        <StringFilterDropDown
          confirm={confirm}
          isVisible={visible}
          placeholder='Поиск по personId заёмщика'
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      ),
      filtered     : !_.isEmpty(tableState?.filters?.personId),
      filteredValue: tableState?.filters?.personId,
      filterIcon   : filtered => (
        <SearchOutlined style={{ color: filtered ? `#1890ff` : undefined }} />
      ),
    },
    ...block === TASK_BLOCK_TITLE.sale
      ? [
        {
          title         : `Телефон`,
          align         : `left`,
          filterDropdown: ({ confirm, selectedKeys, setSelectedKeys, visible }) => (
            <StringFilterDropDown
              confirm={confirm}
              isVisible={visible}
              placeholder='Поиск по телефону заёмщика'
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
            />
          ),
          filtered     : !_.isEmpty(tableState?.filters?.phone),
          filteredValue: tableState?.filters?.phone,
          filterIcon   : filtered => (
            <SearchOutlined style={{ color: filtered ? `#1890ff` : undefined }} />
          ),
          dataIndex: [`client`, `phone`],
          key      : `phone`,
          width    : 120,
        },
        {
          title         : `ФИО`,
          align         : `left`,
          filterDropdown: ({ confirm, selectedKeys, setSelectedKeys, visible }) => (
            <StringFilterDropDown
              confirm={confirm}
              isVisible={visible}
              placeholder='Поиск по ФИО заёмщика'
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
            />
          ),
          filtered     : !_.isEmpty(tableState?.filters?.clientName),
          filteredValue: tableState?.filters?.clientName,
          filterIcon   : filtered => (
            <SearchOutlined style={{ color: filtered ? `#1890ff` : undefined }} />
          ),
          render: (v, task) => getFullName(task?.client),
          key   : `clientName`,
          width : 300,
        },
        {
          title : `Сумма`,
          align : `center`,
          key   : `amount`,
          render: prettifyNumber,
          width : 90,
        },
        {
          title    : `Таймзона`,
          align    : `center`,
          dataIndex: [`client`, `timeZone`],
          key      : `timeZone`,
          width    : 70,
        },
        getCampaignTypeColumn({ tableState, campaignTypes }),
        getSegmentColumn({ filterOptions, tableState }),
        getPriorityColumn({ filterOptions, tableState }),
        getTaskStatusColumn({ tableState, taskStatuses }),
      ]
      : [
        {
          title         : `ФИО`,
          align         : `left`,
          filterDropdown: ({ confirm, selectedKeys, setSelectedKeys, visible }) => (
            <StringFilterDropDown
              confirm={confirm}
              isVisible={visible}
              placeholder='Поиск по ФИО заёмщика'
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
            />
          ),
          filtered     : !_.isEmpty(tableState?.filters?.clientName),
          filteredValue: tableState?.filters?.clientName,
          filterIcon   : filtered => (
            <SearchOutlined style={{ color: filtered ? `#1890ff` : undefined }} />
          ),
          render: (v, task) => getFullName(task?.client),
          key   : `clientName`,
          width : 300,
        },
        {
          title    : `Таймзона`,
          align    : `center`,
          key      : `timeZone`,
          sorter   : true,
          sortOrder: getSortOrder(tableState.sorter, `timeZone`),
          width    : 80,
        },
        {
          title         : `Номер`,
          align         : `center`,
          filterDropdown: ({ confirm, selectedKeys, setSelectedKeys, visible }) => (
            <StringFilterDropDown
              confirm={confirm}
              isVisible={visible}
              placeholder='Поиск по номеру договора'
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
            />
          ),
          filtered     : !_.isEmpty(tableState?.filters?.contractNumber),
          filteredValue: tableState?.filters?.contractNumber,
          filterIcon   : filtered => (
            <SearchOutlined style={{ color: filtered ? `#1890ff` : undefined }} />
          ),
          key      : `contractNumber`,
          sorter   : true,
          sortOrder: getSortOrder(tableState.sorter, `contractNumber`),
          width    : 140,
        },
        {
          title         : `Дата следующего платежа`,
          align         : `center`,
          filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
            <DateFilterDropDown
              confirm={confirm}
              placeholder={[`с`, `по`]}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
            />
          ),
          filtered     : !_.isEmpty(tableState?.filters?.nextPaymentDate),
          filteredValue: tableState?.filters?.nextPaymentDate,
          key          : `nextPaymentDate`,
          render       : v => (v ? formatDate(new Date(v), `dd.MM.yyyy`) : ``),
          sorter       : true,
          sortOrder    : getSortOrder(tableState.sorter, `nextPaymentDate`),
          width        : 220,
        },
        {
          title         : `Продукт`,
          align         : `right`,
          filtered      : !_.isEmpty(tableState?.filters?.productName),
          filteredValue : tableState?.filters?.productName,
          filterMultiple: true,
          filters       : _.map(filterOptions.productNames, value => ({
            text: value,
            value,
          })),
          key      : `productName`,
          sorter   : true,
          sortOrder: getSortOrder(tableState.sorter, `productName`),
          width    : 220,
        },
        {
          title    : `Срок (дн)`,
          align    : `right`,
          key      : `overdueDebtTerm`,
          sorter   : true,
          sortOrder: getSortOrder(tableState.sorter, `overdueDebtTerm`),
          width    : 100,
        },
        {
          title    : `Сумма просроченного долга (₽)`,
          align    : `right`,
          key      : `totalOverdueDebt`,
          render   : prettifyNumber,
          sorter   : true,
          sortOrder: getSortOrder(tableState.sorter, `totalOverdueDebt`),
          width    : 220,
        },
        {
          title    : `Общий долг (₽)`,
          align    : `right`,
          key      : `totalDebt`,
          render   : prettifyNumber,
          sorter   : true,
          sortOrder: getSortOrder(tableState.sorter, `totalDebt`),
          width    : 130,
        },
        getSegmentColumn({ filterOptions, tableState }),
        getPriorityColumn({ filterOptions, tableState }),
        {
          title         : `Дата ИМХА`,
          align         : `center`,
          filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
            <DateFilterDropDown
              confirm={confirm}
              placeholder={[`с`, `по`]}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
            />
          ),
          filtered     : !_.isEmpty(tableState?.filters?.imhaDateIn),
          filteredValue: tableState?.filters?.imhaDateIn,
          key          : `imhaDateIn`,
          render       : v => (v ? formatDate(new Date(v), `dd.MM.yyyy`) : ``),
          sorter       : true,
          sortOrder    : getSortOrder(tableState.sorter, `imhaDateIn`),
          width        : 140,
        },
        {
          title         : `Дата выдачи ТС`,
          align         : `center`,
          filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
            <DateFilterDropDown
              confirm={confirm}
              placeholder={[`с`, `по`]}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
            />
          ),
          filtered     : !_.isEmpty(tableState?.filters?.imhaDateOut),
          filteredValue: tableState?.filters?.imhaDateOut,
          key          : `imhaDateOut`,
          render       : v => (v ? formatDate(new Date(v), `dd.MM.yyyy`) : ``),
          sorter       : true,
          sortOrder    : getSortOrder(tableState.sorter, `imhaDateOut`),
          width        : 140,
        },
        ...(isSearchPartnerColumnShown
          ? [
            {
              title         : `Партнер`,
              align         : `center`,
              dataIndex     : `searchPartner`,
              filtered      : !_.isEmpty(tableState?.filters?.searchPartnerId),
              filteredValue : tableState?.filters?.searchPartnerId,
              filterMultiple: true,
              filters       : _.filter(_.map(hmSearchPartners, ({ id, name }) => ({
                text : name,
                value: id,
              })), `value`),
              key   : `searchPartnerId`,
              render: searchPartner => {
                if (!searchPartner) return ``;
                return searchPartner.description || searchPartner.name;
              },
              width: 250,
            },
            {
              title         : `Стоянка`,
              align         : `center`,
              dataIndex     : `hmParkingId`,
              filtered      : !_.isEmpty(tableState?.filters?.hmParkingId),
              filteredValue : tableState?.filters?.hmParkingId,
              filterMultiple: true,
              filters       : _.filter(_.map(hmParkings, ({ id, name }) => ({
                text : name,
                value: id,
              })), `value`),
              key   : `hmParkingId`,
              render: hmParking => {
                if (!hmParking) return ``;
                return hmParking.description || hmParking.name;
              },
              width: 200,
            },
          ]
          : []),
        getCampaignTypeColumn({ tableState, campaignTypes }),
        getTaskStatusColumn({ tableState, taskStatuses }),
      ],
    {
      title         : `Сотрудник`,
      dataIndex     : `operatorName`,
      filtered      : !_.isEmpty(tableState?.filters?.operatorId),
      filteredValue : tableState?.filters?.operatorId,
      filterMultiple: true,
      filters       : _.filter(_.map(operatorsToSelect, operator => ({
        text : getFullNameInitials(_.get(operator, `user`, {})),
        value: operator.id,
      })), `value`),
      key  : `operatorId`,
      width: 200,
    },
    {
      title         : `Цель`,
      align         : `center`,
      filtered      : !_.isEmpty(tableState?.filters?.nextStep),
      filteredValue : tableState?.filters?.nextStep,
      filterMultiple: true,
      filters       : _.map(filterOptions.nextSteps, value => ({
        text: value,
        value,
      })),
      key      : `nextStep`,
      sorter   : true,
      sortOrder: getSortOrder(tableState.sorter, `nextStep`),
      width    : 200,
    },
    {
      title         : `Дата цели`,
      align         : `center`,
      filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
        <DateFilterDropDown
          confirm={confirm}
          placeholder={[`с`, `по`]}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      ),
      filtered     : !_.isEmpty(tableState?.filters?.nextStepDate),
      filteredValue: tableState?.filters?.nextStepDate,
      key          : `nextStepDate`,
      render       : v => (v ? formatDate(new Date(v), `dd.MM.yyyy`) : ``),
      sorter       : true,
      sortOrder    : getSortOrder(tableState.sorter, `nextStepDate`),
      width        : 140,
    },
    {
      title         : `Состояние клиента`,
      align         : `center`,
      filtered      : !_.isEmpty(tableState?.filters?.clientConditionName),
      filteredValue : tableState?.filters?.clientConditionName,
      filterMultiple: true,
      filters       : _.map(filterOptions.clientConditionNames, value => ({
        text: value,
        value,
      })),
      key      : `clientConditionName`,
      sorter   : true,
      sortOrder: getSortOrder(tableState.sorter, `clientConditionName`),
      width    : 200,
    },
    {
      title         : `Дата решения о признании банкротом`,
      align         : `center`,
      filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
        <DateFilterDropDown
          confirm={confirm}
          placeholder={[`с`, `по`]}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      ),
      filtered     : !_.isEmpty(tableState?.filters?.bankruptDecisionDtm),
      filteredValue: tableState?.filters?.bankruptDecisionDtm,
      key          : `bankruptDecisionDtm`,
      render       : v => (v ? formatDate(new Date(v), `dd.MM.yyyy`) : ``),
      sorter       : true,
      sortOrder    : getSortOrder(tableState.sorter, `bankruptDecisionDtm`),
      width        : 280,
    },
    {
      title         : `Дата судебного заседания`,
      align         : `center`,
      filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
        <DateFilterDropDown
          confirm={confirm}
          placeholder={[`с`, `по`]}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      ),
      filtered     : !_.isEmpty(tableState?.filters?.bankruptsyValidation),
      filteredValue: tableState?.filters?.bankruptsyValidation,
      key          : `bankruptsyValidation`,
      render       : v => (v ? formatDate(new Date(v), `dd.MM.yyyy`) : ``),
      sorter       : true,
      sortOrder    : getSortOrder(tableState.sorter, `bankruptsyValidation`),
      width        : 240,
    },
    {
      title         : `Дата последнего платежа`,
      align         : `center`,
      filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
        <DateFilterDropDown
          confirm={confirm}
          placeholder={[`с`, `по`]}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      ),
      filtered     : !_.isEmpty(tableState?.filters?.lastPaymentDate),
      filteredValue: tableState?.filters?.lastPaymentDate,
      key          : `lastPaymentDate`,
      render       : v => (v ? formatDate(new Date(v), `dd.MM.yyyy`) : ``),
      sorter       : true,
      sortOrder    : getSortOrder(tableState.sorter, `lastPaymentDate`),
      width        : 240,
    },

    {
      title    : `Сумма последнего платежа`,
      align    : `center`,
      key      : `lastPaymentAmount`,
      render   : prettifyNumber,
      width    : 190,
      sorter   : true,
      sortOrder: getSortOrder(tableState.sorter, `lastPaymentAmount`),
    },
  ], getColumn);
};
