import * as React from 'react';
import {
  useCallback,
  useState,
} from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import {
  getClientApplicationOffers as getClientApplicationOffersAction,
  getClientLoanDetails as getClientLoanDetailsAction,
  getClientLoanSchedule as getClientLoanScheduleAction,
} from 'models/clients/actions';

import { SettingOutlined } from '@ant-design/icons';
import {
  EntityCard,
  EntityCardContent,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { ExpandButton } from 'pages/PageClient/Single/components/EntityCard/EntityCardExpandButton';
import {
  EntityDetails,
  IRow,
} from 'pages/PageClient/Single/components/EntityDetails/EntityDetails';
import { LoansTableCellControl } from 'pages/PageClient/Single/Loan/LoansTable/LoansTableCellControl';
import { LoansTableExpand } from 'pages/PageClient/Single/Loan/LoansTable/LoansTableExpand';
import { ILoanCommon } from 'pages/PageClient/Single/Loan/types';

const StyledCardContent = styled(EntityCardContent)`
  padding: 0;
  overflow: hidden;
`;

const StyledExpandButton = styled(ExpandButton)`
  background: ${palette.primary400Color};
  border-radius: 0;
  padding-top: 4px;
  padding-bottom: 4px;

  .anticon {
    color: ${palette.whiteColor};
  }

  :hover {
    background: ${palette.primary500Color};

    .anticon {
      color: ${palette.whiteColor};
    }
  }

  :active {
    background: ${palette.primary600Color};

    .anticon {
      color: ${palette.whiteColor};
    }
  }
`;

const ExpandDataWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 16px;
  background: ${palette.black10Color};
  overflow-y: scroll;
  max-height: 50vh;
`;

const ActionsButtonWrapper = styled.button`
  border: none;
  padding: 4px 8px;
  background: ${palette.primary5Color};
  color: ${palette.black80Color};
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;

  .anticon {
    margin-right: 8px;
    color: ${palette.primary500Color};
  }
`;

const StyledEntityCard = styled(EntityCard)`
  width: calc(100vw - 16px);
  margin-right: 8px;
  margin-left: 8px;

  @media(min-width: ${breakpoints.sm}) {
    width: 320px;
    margin-right: 16px;
    margin-left: 16px;
  }
`;

interface IComponentProps extends ILoanCommon {
  loan: any;
  personId: string;
  visibleRows: IRow[];
}

export const LoanCard: React.FC<IComponentProps> = ({
  loan,
  onCancelInsurance,
  personId,
  toggleAgreementModal,
  toggleConditionModal,
  toggleLoanCloseModal,
  toggleRepaymentLinkModal,
  visibleRows,
}) => {
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);

  const { id: loanId } = loan;

  const getDetails = useCallback(
    ({ pdp, requestDtm }: { pdp?: boolean; requestDtm?: boolean; }) => dispatch(getClientLoanDetailsAction({
      loanId,
      pdp,
      personId,
      requestDtm,
    })), [personId, dispatch, loanId],
  );

  const getOffers = useCallback((applicationId: string) => {
    dispatch(getClientApplicationOffersAction({ personId, applicationId, hideMessage: true }));
  }, [personId, dispatch]);

  const getSchedule = useCallback(
    () => dispatch(getClientLoanScheduleAction({ personId, loanId })),
    [personId, dispatch, loanId],
  );

  const getAdditionalInfo = useCallback(() => {
    getDetails({});
    getSchedule();

    const applicationId = _.get(loan, `application.id`) || _.get(loan, `applicationId`);
    getOffers(applicationId);
  }, [getDetails, getOffers, getSchedule, loan]);

  const toggleCollapse = () => {
    if (!collapsed) {
      getAdditionalInfo();
    }

    setCollapsed(v => !v);
  };

  const handlers = useSwipeable({
    onSwipedUp: () => {
      if (!collapsed) {
        setCollapsed(true);
      }
    },
    delta: 100,
  });

  return (
    <StyledEntityCard {...handlers}>
      <StyledCardContent>
        <EntityDetails
          record={loan}
          rows={[
            ...visibleRows,
            {
              key        : `system_actions`,
              title      : `Действия`,
              valueToCopy: false,
              render     : (value, record) => (// @ts-ignore
                <LoansTableCellControl
                  loan={record}
                  personId={personId}
                  toggleAgreementModal={toggleAgreementModal}
                  toggleLoanCloseModal={toggleLoanCloseModal}
                  toggleRepaymentLinkModal={toggleRepaymentLinkModal}
                >
                  <ActionsButtonWrapper>
                    <SettingOutlined /> Действия
                  </ActionsButtonWrapper>
                </LoansTableCellControl>
              ),
            },
          ]}
        />

        <StyledExpandButton collapsed={!collapsed} onClick={() => toggleCollapse()} />

        {collapsed && (
          <ExpandDataWrapper>
            <LoansTableExpand
              getDetails={getDetails}
              loan={loan}
              onCancelInsurance={onCancelInsurance}
              personId={personId}
              toggleConditionModal={toggleConditionModal}
            />
          </ExpandDataWrapper>
        )}
      </StyledCardContent>
    </StyledEntityCard>
  );
};
