import React, { useContext } from 'react';
import { useSelector } from 'dva';

import { getClientFullName as getClientFullNameSelector } from 'models/clients/selectors';

import { useMessenger } from 'components/Messenger/hooks';
import { Messenger } from 'components/Messenger/Messenger';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';

interface IProps {}

const PageClientSingleComment: React.FC<IProps> = () => {
  const { personId } = useContext(ClientMatchContext) as { personId: string };

  const fullName = useSelector(state => getClientFullNameSelector(state, personId));
  const notificationTitle = `${fullName} - новый комментарий`;

  const { dialog } = useMessenger(personId);

  return (
    <ClientTab>
      <Messenger
        dialog={dialog}
        notificationTitle={notificationTitle}
      />
    </ClientTab>
  );
};

export default PageClientSingleComment;
