import _ from 'lodash';

import { parseResponse } from 'api/helpers';
import {
  b64toBlob,
  convertHeicToJpeg,
  validateFileType,
} from 'helper';

import { getFile } from 'models/fileStorage/api';

import { IInputAttachment } from 'components/Messenger/types';

import {
  ACCEPT,
  HEIC_HEIF,
} from './constants';

export interface IMessageFile {
  lastModified: number;
  name: string;
  size: number;
  type: string;
}

export interface IMessageFileObject {
  name: string;
  type: string;
}

export const getFileKey = (file: IInputAttachment) => `${file.name}-${file.size}-${file.lastModified}`;

export const getFileFromFileObject = async (fileObject: IMessageFileObject, dialogId: number) => {
  const url = `/message/${dialogId}/${fileObject.name}`;
  const b64 = parseResponse({
    dataPath    : `data.file`,
    defaultError: `При загрузке файла произошла ошибка`,
    response    : await getFile(url),
  });
  const blob = b64toBlob(b64, fileObject.type);

  // @ts-ignore
  return new File([blob], fileObject.name, { type: fileObject.type });
};

export const processFile = async (
  file: IMessageFile, files: IMessageFile[], callback: (file: IMessageFile | null) => void,
) => {
  // prevent uploading same files
  if (!file || _.find(files, { name: file?.name, size: file?.size, lastModified: file?.lastModified })) {
    return callback(null);
  }

  // check accepted mime-type
  if (!validateFileType(ACCEPT, file)) return callback(null);

  if (validateFileType(HEIC_HEIF, file)) {
    const convertedFile = await convertHeicToJpeg(file);
    // @ts-ignore
    return callback(convertedFile);
  }

  return callback(file);
};
