import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'dva';
import { Empty, Layout, Spin } from 'antd';

import { getClientCars as getClientCarsAction, getClientCarService as getClientCarServiceAction } from 'models/clients/actions';
import { APPLICATION_STATUSES } from 'models/clients/constants';
import { getClientCarService as getClientCarServiceSelector, getClientCarsWithApplications as getClientCarsSelector, getIsLoading as getIsLoadingClientSelector, getIsLoadingCarService as getIsLoadingCarServiceSelector } from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import { getItems } from './helpers';
import Info from './Info';
import { INFO } from './constants';

// Стили
const StyledLayout = styled(Layout)`
  height: 100%;
  padding: 16px 32px;
`;

const Spinner = styled(Spin)`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

const EmptyContainer = styled(Empty)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InfoBlock = styled.div`
  margin-bottom: 24px;
`;

const InfoBlockTitle = styled.div`
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  border-bottom: 1px solid #fff;
`;

const InfoBlockItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const PageClientSingleCarService = () => {
  const dispatch = useDispatch();
  const { personId } = useContext(ClientMatchContext);

  const cars = useSelector(state => getClientCarsSelector(state, personId));
  const car = _.find(cars, ({ application }) => application && application.applicationStatusId === APPLICATION_STATUSES.VERIFICATION && !application.applicationCloseDtm);
  const carService = useSelector(state => getClientCarServiceSelector(state, personId));
  const [isLoadingDictionaries, dictionaries] = useDictionaries(['carServiceTitle']);
  const isLoadingCarService = useSelector(getIsLoadingCarServiceSelector);
  const isLoadingClient = useSelector(getIsLoadingClientSelector);

  const getCars = (force, callback) => dispatch(getClientCarsAction({ callback, force, personId }));
  const getCarService = () => {
    if (!car?.vin || !car?.carNumber) return;
    dispatch(getClientCarServiceAction({ carNumber: car.carNumber, personId, vin: car?.vin }));
  };

  const isLoading = isLoadingDictionaries || isLoadingCarService || isLoadingClient;

  useEffect(() => {
    if (!_.isEmpty(car)) getCarService(true);
  }, [car?.vin, car?.carNumber]);

  const info = _.map(INFO, ({ key, name }) => ({
    items: getItems({
      carServiceTitles: dictionaries?.carServiceTitle,
      key,
      value: carService[key],
    }),
    title: name,
  }));

  useEffect(() => getCars(true), []);

  return (
    <ClientTab isReloading={isLoading} onReload={getCarService}>
      <StyledLayout>
        {isLoading
          ? <Spinner />
          : (
            (_.isEmpty(carService)
              ? <EmptyContainer description='Сервисы по авто не найдены' image={Empty.PRESENTED_IMAGE_SIMPLE} />
              : _.map(info, ({ items, title }) => (
            <InfoBlock key={title}>
              <InfoBlockTitle>{title}</InfoBlockTitle>
              <InfoBlockItems>
                {_.map(items, ({
                                 format,
                                 items: itemsInner,
                                 name,
                                 subtitle,
                                 value,
                               }, index) => (
                  <Info
                    format={format}
                    items={itemsInner}
                    key={index}
                    name={name}
                    subtitle={subtitle}
                    value={value}
                  />
                ))}
              </InfoBlockItems>
            </InfoBlock>
          ))
        ))}
      </StyledLayout>
    </ClientTab>
  );
};

export default PageClientSingleCarService;
