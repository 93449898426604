import _ from 'lodash';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { ISearchCallParams } from 'models/calls/types';

import {
  GET,
  model as namespace,
  SET,
  SET_CALLS,
  SET_LOADING,
  SET_TABLE_STATE,
} from './actions';
import * as api from './api';
import { getTableState as getTableStateSelector } from './selectors';

const initialState = {
  calls     : {},
  isLoading : false,
  tableState: {
    filters: {},
  },
};

export default {
  namespace,
  state  : initialState,
  effects: {
    *[GET](action: { payload: { callback: any; personId: any; phone: any; }; }, {call, put, select}: any) {
      const { callback, personId, phone } = action.payload;
      if (!personId) return;
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке звонков клиента c personId '${personId}' произошла ошибка`;

      try {
        const { filters } = yield select(getTableStateSelector);

        const data: ISearchCallParams = {
          startTimeFrom: filters?.startTime?.[0],
          startTimeTo  : filters?.startTime?.[1],
          phone,
          type         : filters?.type?.[0],
        };

        const calls = parseResponse({
          defaultError,
          // @ts-ignore
          response: yield call(api.get, personId, data),
        });

        yield put({ type: SET_CALLS, payload: { [personId]: calls } });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
        if (_.isFunction(callback)) callback();
      }
    },

    *[SET_TABLE_STATE](action: { payload: { [x: string]: any; callback: any; }; }, {put, select}: any) {
      const {
        callback,
        ...tableState
      } = action.payload;

      // @ts-ignore
      const currentTableState = yield select(getTableStateSelector);

      yield put({
        type   : SET,
        payload: {
          tableState: {
            ...currentTableState,
            ...tableState,
          },
        } });
      if (_.isFunction(callback) && !_.isEqual(currentTableState, tableState)) callback();
    },
  },
  reducers: {
    // @ts-ignore
    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        ...payload,
      };
    },

    // @ts-ignore
    [SET_CALLS](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        calls: {
          ...state.calls,
          ...payload,
        },
      };
    },

    // @ts-ignore
    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
};
