import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment, { Moment } from 'moment/moment';

import { editTrigger as editTriggerAction } from 'models/clients/actions';
import {
  getClientLoans,
  getIsLoading as isLoadingSelector,
  getTriggerById,
} from 'models/clients/selectors';
import {
  ILoan,
  ITrigger,
} from 'models/clients/types';
import { useDictionaries } from 'models/dictionaries/hooks';
import { getCurrent as getCurrentOperatorSelector } from 'models/operators/selectors';

import {
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
} from 'antd';

interface IProps {
  isOpen: boolean,
  personId: string,
  triggerId: string | null,
  onClose():void,
  onSuccess():void,
}

interface IValues extends ITrigger {
}

const defaultValues: IValues = {
  // @ts-ignore
  block      : null,
  callDate   : moment.utc(),
  comment    : ``,
  description: ``,
  // @ts-ignore
  loanId     : null,
  // @ts-ignore
  personId   : null,
  // @ts-ignore
  sobitie    : null,
  // @ts-ignore
  sobitieDate: null,
  // @ts-ignore
  source     : null,
  // @ts-ignore
  triggerId  : null,
};

export const TriggerModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  onSuccess,
  personId,
  triggerId,
}) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState<Partial<IValues>>(defaultValues);

  const isLoadingClient:boolean = useSelector(isLoadingSelector);
  const trigger:ITrigger = useSelector(state => getTriggerById(state, personId, triggerId));
  const loans:ILoan[] = useSelector(s => getClientLoans(s, personId));
  const operatorId:number = useSelector(getCurrentOperatorSelector)?.id;
  const [isLoadingDictionaries, dictionaries] = useDictionaries([`trigger`, `triggerBlock`]);

  const isLoading = isLoadingClient || isLoadingDictionaries;
  const callDate = moment.utc();

  useEffect(() => {
      if (isOpen && trigger) {
        setValues({
          block      : trigger.block,
          comment    : trigger.comment,
          description: trigger.description,
          loanId     : trigger.loanId,
          sobitie    : trigger.sobitie,
          // @ts-ignore
          sobitieDate: trigger.sobitieDate ? moment.utc(trigger.sobitieDate) : null,
          triggerId  : trigger.triggerId,
        });
      }
    },
    [operatorId, triggerId, isOpen],
  );

  const setValue = (field: string, data: ChangeEvent<HTMLInputElement> | Moment | string | number | boolean) => setValues({
    ...values,
    [field]: !_.isEmpty(data) && _.has(data, `target`) ? (data as ChangeEvent<HTMLInputElement>).target.value : data,
  });

  const handleClose = () => {
    setValues(defaultValues);
    onClose();
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const onSubmit = () => {
    dispatch(editTriggerAction({
      callback: handleSuccess,
      triggerId,
      ...values,
      callDate,
      personId,
      source  : operatorId,
    }));
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      confirmLoading={isLoading}
      okText='Сохранить'
      onCancel={handleClose}
      onOk={onSubmit}
      open={isOpen}
      title={triggerId ? `Редактировать последнее событие` : `Создать событие`}
    >
      <Form layout='vertical'>
        <Form.Item
          label='Договор'
          required
        >
          <Select
            onChange={v => setValue(`loanId`, v)}
            options={_.map(loans, loan => ({
              label: loan.contractNumber,
              value: loan.id,
            }))}
            value={values.loanId}
          />
        </Form.Item>
        <Form.Item
          label='Блок'
          required
        >
          <Select
            onChange={v => setValue(`block`, v)}
            options={_.map(dictionaries.triggerBlock, triggerBlock => ({
              label: triggerBlock.description || triggerBlock.name,
              value: triggerBlock.id,
            }))}
            value={values.block}
          />
        </Form.Item>
        <Form.Item label='Дата события'>
          <DatePicker
            // @ts-ignore
            onChange={v => setValue(`sobitieDate`, v)}
            value={values.sobitieDate}
          />
        </Form.Item>
        <Form.Item
          label='Событие'
          required
        >
          <Select
            onChange={v => setValue(`sobitie`, v)}
            options={_.map(dictionaries.trigger, t => ({
              label: t.description || t.name,
              value: t.id,
            }))}
            value={values.sobitie}
          />
        </Form.Item>
        <Form.Item label='Описание'>
          <Input
            onChange={v => setValue(`description`, v)}
            value={values.description}
          />
        </Form.Item>
        <Form.Item label='Комментарий'>
          <Input
            onChange={v => setValue(`comment`, v)}
            value={values.comment}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
