import * as React from 'react';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import {
  Arrow,
  Pagination,
} from '@egjs/flicking-plugins';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import { transparentize } from 'polished';
import { useHotkeys } from 'react-hotkeys-hook';
import { Document } from 'react-pdf';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { IDoc } from 'models/clients/types';

import { EyeOutlined } from '@ant-design/icons';
import { DocsContext } from 'pages/PageClient/Single/Docs';
import { usePagesQty } from 'pages/PageClient/Single/Docs/DocsCarousel/hooks';
import { ImagePreviewModalDesktop } from 'pages/PageClient/Single/Docs/DocsCarousel/ImagePreviewModalDesktop';
import { PageStyled } from 'pages/PageClient/Single/Docs/DocsCarousel/styled';

const Wrapper = styled.div``;

const ArrowIcon = styled.div`
  &.flicking-arrow-prev::before,
  &.flicking-arrow-prev::after,
  &.flicking-arrow-next::before,
  &.flicking-arrow-next::after {
    background-color: ${palette.whiteColor};
  }

  &.flicking-arrow-prev, &.flicking-arrow-next {
    height: 100%;
    opacity: 0.5;
    transition: opacity, background, 300ms ease;
    background: transparent;

    :hover {
      opacity: 1;
      background-color: ${p => transparentize(0.7, palette.blackColor(p))};
    }
  }

  &.flicking-arrow-prev {
    left: 0;
  }

  &.flicking-arrow-next {
    right: 0;
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  object-fit: contain;
  height: 100%;
  max-height: 576px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: zoom-in;
`;

const PdfWrapper = styled.div`
  width: 100%;
  object-fit: contain;
  height: 100%;
  max-height: 576px;
  overflow-y: auto;
`;

const ImageMask = styled.div<{ $isReduced?: boolean }>`
  position: absolute;
  background: ${p => transparentize(0.7, palette.blackColor(p))};
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity, 300ms ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  color: ${palette.whiteColor};

  :hover {
    opacity: 1;
  }

  ${({ $isReduced }) => $isReduced && `
    height: 150px;
  `}
`;

const PaginationWrapper = styled.div`
  &.flicking-pagination-bullets {
    .flicking-pagination-bullet {
      opacity: 0.5;
      transition: opacity, 300ms ease;
      background-color: ${palette.whiteColor};

      &.flicking-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
`;

interface IComponentProps {
  items: IDoc[]
}

export const CarouselDesktop: React.FC<IComponentProps> = ({ items }) => {
  const ref = useRef(null);

  const { url } = useContext(DocsContext);

  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [previewImageIndex, setPreviewImageIndex] = useState<number>(0);

  const { pagesQty, setPagesQty } = usePagesQty();

  const animationDuration = 0;

  const moreThanOneItem = items.length > 1;

  const prevImage = () => {
    if (moreThanOneItem) {
      // @ts-ignore
      ref.current.prev(animationDuration);
    }
  };

  const nextImage = () => {
    if (moreThanOneItem) {
      // @ts-ignore
      ref.current.next(animationDuration);
    }
  };

  useHotkeys(`LEFT`, prevImage, {}, [moreThanOneItem]);
  useHotkeys(`RIGHT`, nextImage, {}, [moreThanOneItem]);

  useHotkeys(`ESC`, () => (isPreviewMode ? setIsPreviewMode(false) : _.noop()), {}, [isPreviewMode]);
  useHotkeys(`Shift+F`, () => (setIsPreviewMode(v => !v)));

  useEffect(() => {
    if (url) {
      const index = _.findIndex(items, { url });

      if (index !== -1) {
        // @ts-ignore
        ref.current.moveTo(index);
      }
    }
  }, [url, items]);

  useEffect(() => {
    if (!ref?.current) return;
    // @ts-ignore
    ref.current.on(`ready`, e => {
      e.currentTarget.addPlugins(new Pagination({ type: `bullet` }));
    });
  }, []);

  return (
    <Wrapper>
      <Flicking
        align='prev'
        changeOnHold
        circular
        duration={animationDuration}
        inputType={[`touch`]}
        moveType='strict'
        onChanged={e => setPreviewImageIndex(e.index)}
        plugins={[
          new Arrow(),
        ]}
        ref={ref}
        renderOnSameKey
      >
        {_.map(items, (item, index) => (
          <ImageWrapper
            className='card-panel'
            key={`${item.name}_${index}`}
            onClick={() => setIsPreviewMode(true)}
          >
            {_.includes(item.contentType, `pdf`) && (
            <PdfWrapper>
              <Document
                file={item.url}
                //@ts-ignore
                onLoadSuccess={({ numPages }) => setPagesQty(index, numPages)}
              >
                {_.times(pagesQty[index], pageIndex => (
                  <PageStyled
                    key={pageIndex}
                    pageIndex={pageIndex}
                    renderAnnotationLayer={false}
                    // @ts-ignore
                    renderForms={false}
                    renderTextLayer={false}
                  />
                ))}
              </Document>
            </PdfWrapper>
            )}
            {_.includes(item.contentType, `image`) && <CarouselImage alt={item.name} src={item.url} />}
            <ImageMask $isReduced={_.includes(item.contentType, `pdf`)}>
              <div>{item.label}</div>
              <div>
                <EyeOutlined /> Превью [Shift + F]
              </div>
              <div />
            </ImageMask>
          </ImageWrapper>
        ))}
        <ViewportSlot>
          <PaginationWrapper className='flicking-pagination' />
          <ArrowIcon className='flicking-arrow-prev' />
          <ArrowIcon className='flicking-arrow-next' />
        </ViewportSlot>
      </Flicking>

      <ImagePreviewModalDesktop
        isVisible={isPreviewMode}
        item={items[previewImageIndex]}
        onCancel={() => setIsPreviewMode(false)}
        onClickNext={nextImage}
        onClickPrev={prevImage}
      />
    </Wrapper>
  );
};
