import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { Link } from 'dva/router';

import {
  formatDate,
  getColumn,
  getFullNameInitials,
  getSort,
  sortAlphabetically,
} from 'helper';

import { getClientTasksLastTouch as getClientTasksLastTouchAction } from 'models/clients/actions';
import {
  getTasksLastTouch as getTasksLastTouchSelector,
  isLoadingTasksLastTouch as isLoadingTasksLastTouchSelector,
  isTasksLastTouchEmpty as isTasksLastTouchEmptySelector,
} from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';
import { useOperators } from 'models/operators/hooks';
import { ILastTouch } from 'models/task/types';

import {
  CaretRightOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Spin,
} from 'antd';
import { DefaultTable } from 'components/DefaultTable/DefaultTable';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';

export const COLUMNS = _.map([
  {
    key   : `block`,
    title : `Блок`,
    sorter: getSort(`block`, sortAlphabetically),
    width : 100,
  },
  {
    key   : `campaignName`,
    title : `Кампания`,
    sorter: getSort(`campaignName`, sortAlphabetically),
    width : 240,
  },
  {
    key   : `updatedAt`,
    title : `Дата`,
    sorter: getSort(`updatedAt`, sortAlphabetically),
    width : 90,
  },
  {
    key   : `operatorName`,
    title : `Ответственный`,
    sorter: getSort(`operatorName`, sortAlphabetically),
    width : 120,
  },
  {
    key   : `statusName`,
    title : `Статус`,
    sorter: getSort(`statusName`, sortAlphabetically),
    render: (statusName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, record: { statusColor: string | (string & object) | undefined; }) => <DefaultTag color={record?.statusColor}>{statusName}</DefaultTag>,
    width : 100,
  },
  {
    key   : `href`,
    title : `Задача`,
    render: (href: string) => (
      <Link to={href}>
        <Button icon={<CaretRightOutlined />} shape='circle' type='primary' />
      </Link>
    ),
    width: 60,
  },
], getColumn);

interface IProps {
  personId: string;
}

interface IStoreValues {
  isLoadingTasksLastTouch: boolean;
  isTasksLastTouchEmpty: boolean;
  tasksLastTouch: ILastTouch[];
}

const TasksLastTouch:React.FC<IProps> = ({ personId }) => {
  const dispatch = useDispatch();

  const { isLoadingTasksLastTouch, isTasksLastTouchEmpty, tasksLastTouch }: IStoreValues = useSelector(state => ({
    isLoadingTasksLastTouch: isLoadingTasksLastTouchSelector(state),
    isTasksLastTouchEmpty  : isTasksLastTouchEmptySelector(state, personId),
    tasksLastTouch         : getTasksLastTouchSelector(state, personId),
  }));

  const getTasksLastTouch = useCallback(
    () => dispatch(getClientTasksLastTouchAction({ personId })),
    [dispatch, personId],
  );

  const [isLoadingDictionaries, dictionaries] = useDictionaries([`taskStatus`, `campaign`]);
  const [isLoadingOperators, operators] = useOperators();

  const onReload = useCallback(() => getTasksLastTouch(), [getTasksLastTouch]);

  useEffect(() => {
    if (isTasksLastTouchEmpty) onReload();
  }, []);

  const isLoading = isLoadingOperators || isLoadingDictionaries || isLoadingTasksLastTouch;
  const items = useMemo(() => {
    if (_.isEmpty(dictionaries)
      || _.isEmpty(dictionaries?.taskStatus)
      || _.isEmpty(dictionaries?.campaign)
      || _.isEmpty(operators)
      || isTasksLastTouchEmpty
    ) return [];
    return _.map(tasksLastTouch, lt => {
      const campaign = _.find(dictionaries?.campaign, { id: lt.campaignId });
      const taskStatus = _.find(dictionaries?.taskStatus, { id: lt.taskStatusId });
      const operator = _.find(operators, { id: lt.operatorId });

      return {
        block       : campaign?.block,
        campaignName: campaign?.name,
        updatedAt   : formatDate(lt?.updatedAt, `dd.MM.yyyy`),
        operatorName: getFullNameInitials(operator?.user),
        statusColor : taskStatus?.color,
        statusName  : taskStatus?.description,
        href        : `/tasks/task/${lt.taskId}/person/${personId}`,
      };
    });
  },
  [
    dictionaries?.taskStatus?.length,
    dictionaries?.campaign?.length,
    operators?.length,
    tasksLastTouch?.length,
  ]);

  return (
    <Spin spinning={isLoading}>
      <EntityCard>
        <EntityCardTitle>
          Взаимодействия
          <EntityCardTitleControl
            actionTitle='Обновить'
            onClick={onReload}
          >
            <ReloadOutlined />
          </EntityCardTitleControl>
        </EntityCardTitle>
        <EntityCardContent>
          <DefaultTable
            bordered={false}
            // @ts-ignore
            columns={COLUMNS}
            dataSource={items}
            pagination={{
              hideOnSinglePage: true,
              pageSize        : _.size(tasksLastTouch),
              size            : `small`,
            }}
            rowKey='taskId'
            scroll={{ x: `max-content`, y: isTasksLastTouchEmpty ? 150 : 200 }}
            scrollableHeader
            size='small'
          />
        </EntityCardContent>
      </EntityCard>
    </Spin>
  );
};

export default TasksLastTouch;
