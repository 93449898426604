import _ from 'lodash';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { isAuth } from 'models/user/helpers';

import {
  ASSIGN_TO_USERS,
  CREATE,
  GET,
  GET_SINGLE,
  model as namespace,
  REMOVE,
  REMOVE_FROM_USERS,
  REMOVE_SINGLE,
  RESET,
  SET,
  SET_LOADING,
  UPDATE,
} from './actions';
import * as api from './api';
import {
  getItems as getItemsSelector,
  isLoaded as isLoadedSelector,
} from './selectors';

const initialState = {
  isLoaded    : false,
  isLoading   : false,
  items       : [],
  searchString: ``,
};

export default {
  namespace,
  state        : initialState,
  subscriptions: {
    setup({ dispatch }) {
      if (isAuth()) setTimeout(() => dispatch({ type: GET }), 200);
    },
  },
  effects: {
    *[GET](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке групп ролей произошла ошибка`;
      const isLoaded = yield select(isLoadedSelector);
      if (isLoaded && !action.payload?.force) return;
      try {
        const items = parseResponse({
          defaultError,
          response: yield call(api.get),
        });

        yield put({ type: SET, payload: { items, isLoaded: true } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_SINGLE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id } = action.payload;
      const defaultError = `При загрузке группы ролей с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(defaultError);

        const item = parseResponse({
          defaultError,
          response: yield call(api.getSingle, id),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => (currentItem.id === id ? item : currentItem)),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { item } = action.payload;
      const defaultError = `При создании группы ролей произошла ошибка`;

      try {
        if (_.isEmpty(item)) throw new Error(defaultError);

        const newItem = parseResponse({
          defaultError,
          response: yield call(api.create, item),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.concat(currentItems, [newItem]),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids } = action.payload;
      const defaultError = `При удалении группы ролей с id '${ids}' произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.remove, ids),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.reject(currentItems, ({ id }) => _.includes(ids, id)),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE_SINGLE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id } = action.payload;
      const defaultError = `При удалении группы ролей с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.removeSingle, id),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.reject(currentItems, { id }),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id, item } = action.payload;
      const defaultError = `При редактировании группы ролей произошла ошибка`;

      try {
        if (!id || _.isEmpty(item)) throw new Error(defaultError);

        const updatedItem = parseResponse({
          defaultError,
          response: yield call(api.update, id, item),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => (currentItem.id === id ? updatedItem : currentItem)),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[ASSIGN_TO_USERS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id, userIds } = action.payload;
      const defaultError = `При назначении группы ролей с id '${id}' пользователям произошла ошибка`;

      try {
        if (!id || !_.isEmpty(userIds)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.assignToUsers, id, userIds),
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE_FROM_USERS](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id, userIds } = action.payload;
      const defaultError = `При снятии группы ролей с id '${id}' с пользователей произошла ошибка`;

      try {
        if (!id || !_.isEmpty(userIds)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.removeFromUsers, id, userIds),
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        ...payload,
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
};
