import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);

export const getFilters = createSelector(getState, ({ filters }) => (filters || {}));

export const getItems = createSelector(getState, ({ items }) => (items || {}));

export const getModal = createSelector(getState, ({ modal }) => (modal || {}));

export const getPersonId = createSelector(getModal, ({ personId }) => personId);

export const getSorter = createSelector(getState, ({ sorter }) => (sorter || {}));

export const getEditableId = createSelector(
  [
    getState,
    (state, personId) => personId,
  ],
  (state, personId) => _.get(state, `editableIds.${personId}`, null),
);

export const getItemsByClientId = createSelector(
  [
    getItems,
    (state, personId) => personId,
  ],
  _.get,
);

export const getItemsByPersonId = createSelector(
  [
    getItems,
    (state, personId) => personId,
  ],
  _.get,
);

export const getItemByPersonId = createSelector(
  [
    getItemsByClientId,
    (state, personId, id) => ({ id }),
  ],
  _.find,
);
