import { AxiosResponse } from 'axios';
import { Moment } from 'moment';

import api from 'api/index';

import {
  CheckDestinationsResponse,
  IDestination,
  ISendSmsBatch,
  SendTestEmailParams,
  SwitchPaymentGatewayParams,
  UploadVersionedDocumentParams,
} from 'models/admin/types';

export const getPaymentGateways = () => api.get(`/proxy/operation/channel`);
export const getShortLinkServices = () => api.get(`/proxy/sms/short-url-client/all`);
export const getSmsGateways = () => api.get(`/proxy/sms/operator/all`);
export const processTasks = (block: string) => api.post(`/tasks/process?block=${block}`);
export const runTriggers = () => api.post(`/proxy/vk/client-trigger`);
export const switchPaymentGateway = ({
  activeFlg,
  channelId,
  channelName,
}: SwitchPaymentGatewayParams) => api.post(`/proxy/operation/channel`, { activeFlg, channelId, channelName });
export const switchShortLinkService = (shortUrlClientName: string) => api.post(
  `/proxy/sms/short-url-client/switch/${shortUrlClientName}`,
);
export const switchSmsGateway = (operatorName: string) => api.post(`/proxy/sms/operator/switch/${operatorName}`);
export const sendTestEmail = ({ emails, html }: SendTestEmailParams) => {
  const formData = new FormData();
  formData.append(`html`, html);
  for (const email of emails) {
    formData.append(`emails[]`, email);
  }

  return api.post(
    `/test/email/send`,
    formData,
    {
      headers: { 'Content-Type': `multipart/form-data` },
    },
  );
};

export const uploadVersionedDocument = (values: UploadVersionedDocumentParams) => {
  const formData = new FormData();
  formData.append(`file`, values.file);

  return api.post(
    `/proxy/dfm/sd/upload?typeId=${values.documentTypeId}&createDtm=${values.date.utc().valueOf()}${values.productId ? `&productId=${values.productId}` : ``}`, // eslint-disable-line max-len
    formData,
    {
      headers: { 'Content-Type': `multipart/form-data` },
    },
  );
};

export const uploadDocumentTemplates = (files: any[]) => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append(`file`, file);
  });

  return api.post(
    `/proxy/dictionaries/document-template/batch/multipart`,
    formData,
    {
      headers: { 'Content-Type': `multipart/form-data` },
    },
  );
};

export const uploadDocumentTemplatesArchive = (files: any[]) => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append(`file`, file);
  });

  return api.post(
    `/proxy/dictionaries/document-template/batch`,
    formData,
    {
      headers: { 'Content-Type': `multipart/form-data` },
    },
  );
};

export const uploadDocumentTemplateSingle = (templateId: number, file: string | Blob | null) => {
  const formData = new FormData();
  // @ts-ignore
  formData.append(`file`, file);

  return api.post(
    `/proxy/dictionaries/document-template/v1?id=${templateId}`,
    formData,
    {
      headers: { 'Content-Type': `multipart/form-data` },
    },
  );
};

export const getShortLink = async (url: string):Promise<AxiosResponse<string> | null> => {
  if (!url) return null;
  return api.post(`/shorten`, { url });
};

export const generateStatbalanceReport = (requestDates:[Moment, Moment]) => api.post(`/proxy/schedule/stat-balance`, {
  requestDtmList: requestDates,
});

export const getSmsTags = (): Promise<AxiosResponse<string[]>> => api.get(`/proxy/f/sms/tags`);

export const sendSmsBatch = (data: ISendSmsBatch): Promise<AxiosResponse> => api.post(`/proxy/f/sms/send`, data);

export const checkSmsDestinations = (
  destinations: IDestination[],
): Promise<AxiosResponse<CheckDestinationsResponse>> => api.post(
  `/proxy/f/sms/send/status`,
  { destinations },
);
