import React from 'react';
import _ from 'lodash';

import DateFilterDropDown from 'components/DateFilterDropDown';

export const getDateRangeFilter = (key, tableState) => ({
  filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
    // @ts-ignore
    <DateFilterDropDown
      confirm={confirm}
      placeholder={[`с`, `по`]}
      selectedKeys={selectedKeys}
      setSelectedKeys={setSelectedKeys}
    />
  ),
  filtered     : !_.isEmpty(tableState?.filters?.[key]),
  filteredValue: tableState?.filters?.[key],
});
