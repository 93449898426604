import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import ExcelJS from 'exceljs';

import { parseResponse } from 'api/helpers';
import {
  getPlural,
  showError,
  validateUuid,
} from 'helper';

import {
  checkSmsDestinations,
  getSmsTags,
  sendSmsBatch,
} from 'models/admin/api';
import { IDestination } from 'models/admin/types';

import { useFileInput } from 'hooks/useFileInput';

import {
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  Dropdown,
  Input,
  Modal,
  notification,
  Spin,
  Tooltip,
} from 'antd';
import { CheckPhonesMessage } from 'components/Modal/SendSmsBatch/CheckPhonesMessage';
import {
  ACCEPTED_FILES,
  MAX_TEXT_SIZE,
  SMS_SIZE,
} from 'components/Modal/SendSmsBatch/constants';
import {
  ButtonsWrapper,
  Content,
  Controls,
  Counter,
  StyledButton,
  StyledCheckbox,
  StyledQuestionCircleOutlined,
  TopWrapper,
  UploadButton,
} from 'components/Modal/SendSmsBatch/styled';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SendSmsBatchModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const textRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [destinations, setDestinations] = useState<IDestination[]>([]);
  const [text, setText] = useState<string>(``);
  const [tags, setTags] = useState<string[]>([]);
  const [sendMore, setSendMore] = useState<boolean>(false);
  const [isDestinationsCorrect, setIsDestinationsCorrect] = useState<null | boolean>(null);

  const sendSms = useCallback(async (callback?:()=>void) => {
    setIsLoading(true);
    const defaultError = `При отправке SMS произошла ошибка`;

    try {
      parseResponse({
        defaultError,
        response: await sendSmsBatch({
          template: text,
          destinations,
        }),
      });

      if (_.isFunction(callback)) callback();
      notification.success({ message: `SMS отправлены` });
    } catch (error) {
      showError({ defaultError, error });
    } finally {
      setIsLoading(false);
    }
  },
  [destinations, text]);

  const getTags = useCallback(async () => {
    const defaultError = `При загрузке тегов произошла ошибка`;
    const tagsData = parseResponse({
      defaultError,
      response: await getSmsTags(),
    });
    setTags(tagsData);
  }, []);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const onCancel = useCallback(() => {
    onClose();
    setSendMore(false);
    setText(``);
    setIsDestinationsCorrect(null);
    setDestinations([]);
  }, [onClose]);

  const onSubmit = () => sendSms(!sendMore ? onCancel : undefined);

  const checkDestinations = async (destinationsToCheck: IDestination[]) => {
    const checkDestinationsResponse = parseResponse({
      response: await checkSmsDestinations(destinationsToCheck),
    });
    notification.warning({
      message    : `Список загружен`,
      description: <CheckPhonesMessage checkDestinationsResponse={checkDestinationsResponse} />,
      duration   : 0,
    });
  };

  const onFilesSelected = async (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    const file = files[0];

    try {
      const workbook = new ExcelJS.Workbook();
      // @ts-ignore
      await workbook.xlsx.load(file);

      const worksheet = workbook.getWorksheet(1);
      // @ts-ignore
      const headers = worksheet.getRow(1).values;

      const errors: string[] = [];
      const uniqueDestinations = new Map<string, IDestination>(); // Используем Map для уникальных значений

      if (!_.includes(headers, `personId`)) {
        errors.push(`Заголовок personId отсутствует.`);
      }

      // @ts-ignore
      worksheet.eachRow((row, rowIndex) => {
        if (rowIndex === 1) return; // Пропустить первую строку с заголовками

        const cellValues = row.values;

        // @ts-ignore
        const personIdIndex = headers.indexOf(`personId`);
        // @ts-ignore
        const loanIdIndex = headers.indexOf(`loanId`);
        // @ts-ignore
        const applicationIdIndex = headers.indexOf(`applicationId`);

        // @ts-ignore
        const personId = cellValues[personIdIndex];
        // @ts-ignore
        const loanId = cellValues[loanIdIndex];
        // @ts-ignore
        const applicationId = cellValues[applicationIdIndex];

        let errorString = ``;

        if (!personId) {
          errorString += `personId отсутствует. `;
        } else if (!validateUuid(personId)) {
          errorString += `personId имеет некорректный формат. `;
        }

        if (loanId && !validateUuid(loanId)) {
          errorString += `loanId имеет некорректный формат. `;
        }

        if (applicationId && !validateUuid(applicationId)) {
          errorString += `applicationId имеет некорректный формат. `;
        }

        if (errorString) {
          errors.push(`Ошибка в строке ${rowIndex}: ${errorString}`);
        } else {
          // Генерируем ключ для проверки уникальности
          const key = `${personId}-${loanId || ``}-${applicationId || ``}`;

          if (!uniqueDestinations.has(key)) {
            uniqueDestinations.set(key, {
              personId,
              loanId,
              applicationId,
            });
          }
        }
      });

      if (errors.length > 0) {
        // Показать ошибки через notification.error()
        const errorMessage = errors.join(`\n`);
        notification.error({
          message    : `Данные в файле имеют некорректный формат`,
          description: errorMessage,
        });
        setIsDestinationsCorrect(false);
      } else {
        const uniqueDestinationsArray = Array.from(uniqueDestinations.values()); // Преобразовать Map в массив
        await checkDestinations(uniqueDestinationsArray);
        setDestinations(uniqueDestinationsArray);
        setIsDestinationsCorrect(true);
      }
    } catch (error) {
      setIsDestinationsCorrect(false);
      console.error(`Ошибка при обработке файла:`, error); // eslint-disable-line no-console
      notification.error({
        message    : `Ошибка`,
        description: `При обработке файла произошла ошибка.`,
      });
    }
  };

  const textSize = _.size(text);
  const isTextTooBig = textSize >= MAX_TEXT_SIZE;
  const isSubmitDisabled = isLoading || isTextTooBig || !text;
  const smsCount = _.ceil(textSize / SMS_SIZE);

  const {
    ACCEPTED_FILES_STRING,
    handleFileSelect,
    inputRef,
    openFileInput,
    StyledInputFile,
  } = useFileInput({ acceptedFiles: ACCEPTED_FILES, onFilesSelected });

  const onTagSelect = (tag: string) => {
    setText(oldText => `${oldText} ${tag}`);
  };

  const tagMenu = {
    items: _.map(tags, tag => ({
      key    : tag,
      label  : tag,
      onClick: () => onTagSelect(tag),
    })),
  };

  if (!isOpen) return null;

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      onCancel={onCancel}
      open={isOpen}
      title='Списочная рассылка SMS'
    >
      <Content>
        <Spin spinning={isLoading}>
          <TopWrapper>
            <UploadButton onClick={openFileInput} type='primary'>Загрузить список</UploadButton>
            <Tooltip
              title={(
                <div>
                  Excel файл с тремя столбцами: personId, loanId, applicationId (порядок не важен)
                  <br />
                  <br />
                  personId и loanId обязательны для заполнения в каждой строке
                  <br />
                  applicationId - опционален
                </div>
            )}
            >
              <StyledQuestionCircleOutlined />
            </Tooltip>
            {_.isBoolean(isDestinationsCorrect) && (
              isDestinationsCorrect
                ? (
                  <>
                    <CheckOutlined style={{ color: `green`, marginRight: `8px` }} />
                    {`${_.size(destinations)} ${getPlural(_.size(destinations), `адресат`, `адресата`, `адресатов`)}`}
                  </>
                )
                : <CloseOutlined style={{ color: `red` }} />
            )}
          </TopWrapper>
          <StyledInputFile
            accept={ACCEPTED_FILES_STRING}
            multiple={false}
            onChange={handleFileSelect}
            ref={inputRef}
          />
          <Input.TextArea
            allowClear
            maxLength={MAX_TEXT_SIZE}
            onChange={e => setText(e.target.value)}
            ref={textRef}
            rows={5}
            value={text}
          />
          <Counter $alert={isTextTooBig}>{`${textSize} / ${MAX_TEXT_SIZE} (${smsCount} SMS)`}</Counter>
          <Controls>
            <StyledCheckbox checked={sendMore} onChange={() => setSendMore(!sendMore)}>
              Отправить ещё
            </StyledCheckbox>
            <ButtonsWrapper>
              <Dropdown menu={tagMenu} trigger={[`click`]}>
                <StyledButton>Вставить тег</StyledButton>
              </Dropdown>
              <StyledButton onClick={onCancel}>Отмена</StyledButton>
              <StyledButton disabled={isSubmitDisabled} onClick={onSubmit} type='primary'>Отправить</StyledButton>
            </ButtonsWrapper>
          </Controls>
        </Spin>
      </Content>
    </Modal>
  );
};
