import React from 'react';
import _ from 'lodash';

import { TDictionaryItem } from 'models/dictionaries/types';
import { TEventlogItem } from 'models/eventlogs/types';

import useMobileContext from 'hooks/useMobileContext';

import {
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ItemHead } from 'components/Eventlog/Item/ItemHead';

import {
  Arrow,
  Content,
  Description,
  Icon,
  Wrapper,
} from './styled';

interface IComponentProps extends TEventlogItem {
  date:string;
  operatorName?:string;
  operatorNext?: TDictionaryItem;
  operatorPrev?: TDictionaryItem;
}

export const Operator: React.FC<IComponentProps> = ({ date, operatorName, operatorNext, operatorPrev }) => {
  const { mobile } = useMobileContext();
  if (!operatorNext && !operatorPrev) return null;

  let color = `orange`;
  let title = `Изменён оператор`;
  let icon = <UserOutlined />;

  if (!operatorPrev) {
    color = `green`;
    title = `Назначен оператор`;
    icon = <UserAddOutlined />;
  }

  if (!operatorNext) {
    color = `red`;
    title = `Снят оператор`;
    icon = <UserDeleteOutlined />;
  }

  return (
    <Wrapper $isMobile={mobile}>
      <Icon $color={color}>{icon}</Icon>
      <Content>
        <ItemHead date={date} operatorName={operatorName} title={title} />
        <Description>
          {operatorPrev && (
            `${_.get(operatorPrev, `user.fullName`)} (${_.get(operatorPrev, `user.email`)}) `
          )}
          {operatorPrev && operatorNext && <Arrow />}
          {operatorNext && (
            `${_.get(operatorNext, `user.fullName`)} (${_.get(operatorNext, `user.email`)})`
          )}
        </Description>
      </Content>
    </Wrapper>
  );
};
