import * as React from 'react';
import { useState } from 'react';
import _ from 'lodash';
import Flicking from '@egjs/react-flicking';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { getSort } from 'helper';

import { useStatePreference } from 'hooks/useStatePreference';

import { FilterOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Checkbox,
  Dropdown,
  Empty,
  Space,
} from 'antd';
import { EntityCardTitle } from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import {
  IAssignee,
  ROWS,
} from 'pages/PageClient/Single/Loan/LoansCards/constants';
import { LoanCard } from 'pages/PageClient/Single/Loan/LoansCards/LoanCard';
import { ILoanCommon } from 'pages/PageClient/Single/Loan/types';

import '@egjs/react-flicking/dist/flicking.css';

const FiltersButton = styled.button`
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.textSecondaryColor};
  padding-top: 8px;
  padding-bottom: 8px;
  background: ${palette.black10Color};
  border: none;
  margin-bottom: 8px;

  .anticon {
    color: ${palette.textSecondaryColor};
  }
`;

const PaginationInfo = styled(EntityCardTitle)`
  margin: 8px;
  margin-left: 24px;
`;

interface IComponentProps extends ILoanCommon {
  assignees: IAssignee[],
  loans: any[];
  personId: string;
}

export const LoansCards: React.FC<IComponentProps> = ({
  assignees,
  loans,
  onCancelInsurance,
  personId,
  toggleAgreementModal,
  toggleConditionModal,
  toggleLoanCloseModal,
  toggleRepaymentLinkModal,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const rows = ROWS(assignees);

  const preferences = {
    path: `clientInfo.loans`,
  };

  const defaultColumnsKeys = _.map(rows, `key`);

  const [filteredColumnKeys, setFilteredColumnKeys] = useStatePreference(
    `ui.${preferences.path}.details.filteredColumnKeys`,
    defaultColumnsKeys,
  );

  const visibleColumns = _.isEmpty(filteredColumnKeys)
    ? rows
    : _.filter(rows, item => _.includes(filteredColumnKeys, item.key));

  const handleMenuClick = (info: MenuInfo) => {
    setFilteredColumnKeys(_.xor(filteredColumnKeys, [info.key]));
  };

  const onResetColumnKeys = () => {
    setFilteredColumnKeys(defaultColumnsKeys);
  };

  const count = rows.length - filteredColumnKeys.length;

  const handleClick = (info: MenuInfo) => {
    if (info.key === `reset`) {
      return onResetColumnKeys();
    }

    return handleMenuClick(info);
  };

  const options = _.map(rows, column => ({
    key  : column.key,
    label: (
      <Space>
        <Checkbox checked={_.includes(filteredColumnKeys, column.key)} />
        {column.title}
      </Space>
    ),
  }));

  const menuItems = [
    ...options,
    {
      key : `divider-1`,
      type: `divider`,
    },
    {
      key  : `reset`,
      label: <Button>Сбросить</Button>,
    },
  ];

  return (
    <>
      <Dropdown
        // @ts-ignore
        menu={{ items: menuItems, onClick: handleClick }}
        onOpenChange={setIsVisible}
        open={isVisible}
        trigger={[`click`]}
      >
        <FiltersButton>Фильтр <Badge count={count} dot><FilterOutlined /></Badge></FiltersButton>
      </Dropdown>

      {loans.length ? (
        <Flicking
          align='prev'
          changeOnHold
          moveType='strict'
          renderOnSameKey
        >
          {_.map(loans.sort((a, b) => getSort(`creditStartDtm`)(b, a)), (loan, index) => (
            <div
              key={loan.id}
              style={{ marginBottom: `24px` }}
            >
              <PaginationInfo>
                {index + 1}/{loans.length}
              </PaginationInfo>
              <LoanCard
                key={loan.id}
                loan={loan}
                onCancelInsurance={onCancelInsurance}
                personId={personId}
                toggleAgreementModal={toggleAgreementModal}
                toggleConditionModal={toggleConditionModal}
                toggleLoanCloseModal={toggleLoanCloseModal}
                toggleRepaymentLinkModal={toggleRepaymentLinkModal}
                visibleRows={visibleColumns}
              />
            </div>
          ))}
        </Flicking>
      ) : (
        <Empty
          description='Нет данных'
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </>
  );
};
