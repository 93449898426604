import React, {
  useCallback,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { get as getDictionaryAction } from 'models/dictionaries/actions';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';
import {
  create as createAction,
  get as getItemsAction,
  remove as removeItemsAction,
  set as setAction,
  update as updateAction,
} from 'models/operators/actions';
import {
  getItems as getItemsSelector,
  getItemsFiltered as getItemsFilteredSelector,
  getSearchString as searchStringSelector,
  isLoaded as isLoadedSelector,
  isLoading as isLoadingSelector,
} from 'models/operators/selectors';
import { downloadExcel as downloadExcelHelper } from './helpers'
import { getItems as getRoleGroupsSelector } from 'models/roleGroups/selectors';
import { removeFromUsers as removeRoleFromUsersAction } from 'models/roles/actions';
import { ROLE_NAMES } from 'models/roles/constants';
import { getItems as getRolesSelector } from 'models/roles/selectors';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { useModalHandlers } from 'hooks/useModalHandlers';
import { useSelectedRowKeys } from 'hooks/useSelectedRowKeys';

import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Button,
  Space,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import CreateModal from 'components/Modal/Create';
import RemoveModal from 'components/Modal/Remove';
import { AdminPluralLayout } from 'pages/PageAdmin/components/AdminPluralLayout';
import { AdminSearchWithControls } from 'pages/PageAdmin/components/AdminSearchWithControls';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';

import {
  getInitialValues as getInitialValuesHelper,
  getUpdateFields as getUpdateFieldsHelper,
} from './helpers';

import {
  COLUMNS,
  DISABLED_TITLE,
  FORM_FIELDS,
} from './constants';

const PageOperatorsPlural = () => {
  const dispatch = useDispatch();

  const {
    genders,
    isLoaded,
    isLoading,
    items,
    notificationLists,
    removeModalItems,
    roleGroups,
    roles,
    searchString,
    userRoles,
  } = useSelector(state => ({
    isLoaded         : isLoadedSelector(state),
    isLoading        : isLoadingSelector(state),
    items            : getItemsFilteredSelector(state),
    genders          : _.map(getDictionarySelector(state, `gender`), ({ id, name }) => ({ label: name, value: id })),
    notificationLists: _.reject(getDictionarySelector(state, `notificationList`), `isPersonal`),
    removeModalItems : getItemsSelector(state),
    roleGroups       : getRoleGroupsSelector(state),
    roles            : getRolesSelector(state),
    searchString     : searchStringSelector(state),
    userRoles        : getRoleNamesSelector(state),
  }));

  const create = item => dispatch(createAction({ item }));
  const getItems = () => dispatch(getItemsAction());
  const getDictionary = entity => dispatch(getDictionaryAction({ entity, force: true }));
  const removeItems = ids => dispatch(removeItemsAction({ ids }));
  const removeRoleFromUsers = ({ roleId, userId }) => dispatch(removeRoleFromUsersAction({
    id     : roleId,
    userIds: [userId],
  }));
  const set = value => dispatch(setAction(value));
  const update = item => dispatch(updateAction({ item, id: item.id }));

  const {
    closeEditModal,
    isCreateModalOpen,
    isEditModalOpen,
    isRemoveModalOpen,
    itemEditing,
    setItemEditing,
    toggleCreateModal,
    toggleEditModal,
    toggleRemoveModal,
  } = useModalHandlers();

  const toggleCopyModal = item => {
    setItemEditing({
      ..._.pick(item, [`isEmployed`]),
      email: `@${_.split(item?.user?.email, `@`)?.[1]}`,
      user: _.pick(item?.user, [`notificationListIds`, `roleIds`]),
    });
    toggleCreateModal();
  };

  const { onSelectChange, selectedRowKeys } = useSelectedRowKeys();

  const canEdit = _.includes(userRoles, ROLE_NAMES.SUPER_ADMIN);

  const getInitialValues = useCallback(getInitialValuesHelper(genders), [genders]);

  const getUpdateFields = useCallback(getUpdateFieldsHelper(genders), [genders]);

  const onRemoveItems = () => {
    onSelectChange([]);
    removeItems(_.filter(selectedRowKeys, id => id >= 0));
  };
  const setSearchString = e => set({ searchString: _.isString(e) ? e : e.target.value });

  useEffect(() => {
    if (!isLoaded) getItems();
    if (_.isEmpty(genders)) getDictionary(`gender`);
    getDictionary(`notificationList`);
  }, []);

  const columns = COLUMNS({
    canEdit,
    notificationLists,
    removeRoleFromUsers,
    roles,
    setItemEditing,
    toggleEditModal,
  });
  const downloadExcel = () => downloadExcelHelper({ columns, items });

  return (
    <>
      <AdminSearchWithControls
        controls={[
          {
            icon   : isLoading ? <LoadingOutlined spin /> : <FileExcelOutlined />,
            onClick: downloadExcel,
            title: `Скачать в Excel`,
          },
          {
            icon   : isLoading ? <LoadingOutlined spin /> : <SyncOutlined />,
            onClick: () => {
              setSearchString(``);
              getItems();
            },
            title: `Очистить поиск и перезагрузить`,
          },
          {
            icon        : <DeleteOutlined />,
            title       : `Удалить выбранных сотрудников`,
            tooltipTitle: canEdit ? `Удалить выбранных сотрудников` : DISABLED_TITLE,
            onClick     : toggleRemoveModal,
            isDisabled  : !canEdit || _.isEmpty(_.reject(selectedRowKeys, id => id < 0)),
          },
          {
            icon        : <PlusCircleOutlined />,
            title       : `Добавить сотрудника`,
            tooltipTitle: canEdit ? `Добавить сотрудника` : DISABLED_TITLE,
            onClick     : toggleCreateModal,
            isDisabled  : !canEdit,
          },
        ]}
        search={{
          onChange   : setSearchString,
          value      : searchString,
          placeholder: `Поиск по ФИО`,
        }}
      />

      <AdminPluralLayout>
        <AdjustableTable
          columns={COLUMNS({
            canEdit,
            notificationLists,
            removeRoleFromUsers,
            roles,
            setItemEditing,
            toggleEditModal,
          })}
          dataSource={items}
          loading={isLoading}
          pagination={{
            hideOnSinglePage: true,
            pageSize        : _.size(items),
            size            : `small`,
          }}
          preferences={{
            path      : `admin.operators`,
            cellRender: (value, record) => (
              <Space>
                {record.id > 0 && (
                  <DefaultTooltip
                    placement='left'
                    title={canEdit ? `Редактировать` : DISABLED_TITLE}
                  >
                    <Button
                      disabled={!canEdit}
                      icon={<EditOutlined />}
                      onClick={() => {
                        setItemEditing(record);
                        toggleEditModal();
                      }}
                      type='primary'
                    />
                  </DefaultTooltip>
                )}
                <DefaultTooltip
                  placement='left'
                  title={canEdit ? `Дублировать роли и списки рассылки` : DISABLED_TITLE}
                >
                  <Button
                    disabled={!canEdit}
                    icon={<CopyOutlined />}
                    onClick={() => toggleCopyModal(record)}
                    type='primary'
                  />
                </DefaultTooltip>
              </Space>
            ),
          }}
          preferencesWidth={88}
          rowKey='id'
          rowSelection={{
            fixed   : true,
            onChange: onSelectChange,
            selectedRowKeys,
          }}
          scroll={{ x: `max-content`, y: window.innerHeight - 300 }}
        />

        <CreateModal
          close={toggleCreateModal}
          create={create}
          formFields={FORM_FIELDS({ genders })}
          getInitialValues={getInitialValues}
          item={itemEditing}
          mode={itemEditing ? `copy` : `create`}
          notificationLists={notificationLists}
          roleGroups={roleGroups}
          roles={roles}
          title='Новый сотрудник'
          visible={isCreateModalOpen}
        />

        <CreateModal
          close={closeEditModal}
          formFields={FORM_FIELDS({ genders, omitRoleGroups: true })}
          getInitialValues={getInitialValues}
          getUpdateFields={getUpdateFields}
          isLoading={isLoading}
          item={itemEditing}
          mode='update'
          notificationLists={notificationLists}
          roles={roles}
          title={`Редактирование ${_.get(itemEditing, `user.fullName`, `сотрудника`)}`}
          update={update}
          visible={isEditModalOpen}
        />

        <RemoveModal
          close={toggleRemoveModal}
          ids={_.filter(selectedRowKeys, id => id >= 0)}
          items={removeModalItems}
          modalTitle='Удаление сотрудника'
          namePath='user.fullName'
          onSubmit={onRemoveItems}
          question='Вы действительно хотите удалить этих сотрудников?'
          visible={isRemoveModalOpen}
        />
      </AdminPluralLayout>
    </>
  );
};

export default PageOperatorsPlural;
