import { useState } from 'react';
import _ from 'lodash';

export function useAmountSplit() {
  const [amountSplit, setAmountSplit] = useState([]);

  const addAmountSplit = () => {
    // @ts-ignore
    setAmountSplit([...amountSplit, { amount: null, partnerRequisiteId: null, partnerRequisiteType: `all` }]);
  };

  const removeAmountSplit = (index: number) => {
    const newAmountSplit = [...amountSplit];
    newAmountSplit.splice(index, 1);
    setAmountSplit(newAmountSplit);
  };

  const editAmountSplit = ({ amount, index, partnerRequisiteId }: { amount: number; index: number; partnerRequisiteId: number }) => {
    const mappedAmountSplit = _.map(
      amountSplit,
      (split, i) => (i === index ? ({ amount, partnerRequisiteId }) : split),
    );
    // @ts-ignore
    setAmountSplit(mappedAmountSplit);
  };

  return { amountSplit, addAmountSplit, removeAmountSplit, editSplit: editAmountSplit };
}
