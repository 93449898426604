import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(getState, ({ items }) => items);

export const getItemsNames = createSelector(getItems, items => _.map(items, `name`));

export const getItem = createSelector(
  [getItems, (state, id) => id],
  (items, id) => _.find(items, { id }),
);

export const getItemOperators = createSelector(
  getItem,
  item => _.get(item, `operators`, []),
);

export const getItemsByNames = createSelector(
  [getItems, (state, names) => names],
  (items, names) => _.filter(items, ({ name }) => _.includes(names, name)),
);

export const getItemsIdsByNames = createSelector(
  getItemsByNames,
  itemsByNames => _.map(itemsByNames, `id`),
);

export const getSearchString = createSelector(getState, ({ searchString }) => searchString);

export const getItemsFiltered = createSelector(
  [getItems, getSearchString],
  (items, search) => (search ? _.filter(items, ({ name }) => _.includes(_.toLower(name), _.toLower(search))) : items),
);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);

export const isLoaded = createSelector(getState, ({ isLoaded: _isLoaded }) => _isLoaded);
