/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
  shadows,
} from 'styles/theme';

import {
  copyToClipboard,
  formatDate,
  getPlural,
  prettifyAmount,
} from 'helper';

import { getClientLoanRepayments as getClientLoanRepaymentsAction } from 'models/clients/actions';
import {
  getClient as getClientSelector,
  getClientLoanById as getLoanSelector,
} from 'models/clients/selectors';
import { getFullName as getFullNameSelector } from 'models/user/selectors';

import {
  CopyOutlined,
  DiffOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Tooltip as TooltipDefault } from 'antd';
import { ConditionDropdown } from 'components/ConditionDropdown';
import { CellId } from 'components/DefaultTable/CellId/CellId';

const Wrap = styled.div<{ $active: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 28px;
  margin-left: 8px;
  background-color: ${palette.primary5Color};
  border: 1px solid ${palette.borderDefaultColor};
  box-shadow: ${shadows.image};
  border-radius: 8px;
  overflow: hidden;
  max-width: 450px;
  width: calc(50% - 12px);
  margin-bottom: 16px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 100%;
    background-color: ${p => (
    p.$active
      ? palette.primary400Color(p)
      : palette.primary5Color(p)
  )};
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-bottom: 8px;
    margin-left: unset;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }
`;

const RowLabel = styled.div`
  font-size: 12px;
  line-height: 16px;

  @media (max-width: ${breakpoints.md}) {
    font-size: 14px;
  }
`;

const RowValue = styled.div`
  font-size: 12px;
  line-height: 16px;

  @media (max-width: ${breakpoints.md}) {
    font-size: 14px;
  }
`;

const Tooltip = styled(TooltipDefault)`
  margin-left: 8px;
`;

interface IComponentProps {
  active?: boolean;
  loanId: string;
  loansUpdatedAt?: Date;
  onUpdate?: () => void;
  personId: string;
}

const LoanItem: React.FC<IComponentProps> = ({
  active = false,
  personId,
  loanId,
  loansUpdatedAt,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  onUpdate = _.noop,
}) => {
  const dispatch = useDispatch();

  const client = useSelector(state => getClientSelector(state, personId)) || {};
  const loan = useSelector(state => getLoanSelector(state, personId, loanId)) || {};
  const operatorFullName = useSelector(getFullNameSelector) || `ФИО ОПЕРАТОРА`;

  useEffect(() => {
      dispatch(getClientLoanRepaymentsAction({personId, loanId}))
    },
    [personId, loanId],
  );

  const lastRepayment = _.maxBy(loan?.repayments?.payments, `paymentDtm`);
  const {
    closeSource,
    contractNumber,
    creditEndDtm,
    creditStartDtm,
    details = {},
    productName,
  } = loan;

  const {
    activeFlg,
    initialLoanTerm,
    interestRateStr,
    loanAmount,
    loanClosingAmount,
    nearestPaymentAmount,
    nearestPaymentDtm,
    overdueDebtPaymentDtm,
    overdueDebtTerm,
    recommendedPaymentAmount,
    totalOverdueDebt,
  } = details;

  let recommendedPaymentDate = nearestPaymentDtm;
  if (totalOverdueDebt) recommendedPaymentDate = new Date();

  let template: unknown;
  let templateConditional: unknown;
  if (overdueDebtTerm) {
    const templateInfoStr = `${contractNumber} от ${formatDate(creditStartDtm, `dd.MM.yyyy`)}г. в размере ${prettifyAmount(totalOverdueDebt)}.
  Период неоплаты составляет ${overdueDebtTerm} ${getPlural(overdueDebtTerm, `день`, `дня`, `дней`)}`;
    template = `Здравствуйте, ${client.name} ${client.patronymic}!
    Это ${operatorFullName} - специалист отдела взыскания ООО МФК «КЭШДРАЙВ» (Тел. для связи __________. Viber, WhatsApp, Telegram – __________).
  У Вас снова образовалась просроченная задолженность перед КЭШДРАЙВ по договору займа ${templateInfoStr}`;

    if (totalOverdueDebt > 0) {
      let templateProductName;
      if (productName === `Под залог автомобиля онлайн`) {
        templateProductName = `займа под залог авто`;
      }
      if (_.includes([`По паспорту онлайн`, `Легкий`], productName)) {
        templateProductName = `беззалогового займа `;
      }

      if (templateProductName) {
        templateConditional = `Здравствуйте, ${client.name} ${client.patronymic}!
  У Вас образовалась просроченная задолженность перед КЭШДРАЙВ по договору ${templateProductName} ${templateInfoStr}`;
      }
    }
  }

  const copyRegularTemplate = useCallback(() => {
    copyToClipboard(template);
  }, [template]);

  const copyConditionalTemplate = useCallback(() => {
    copyToClipboard(templateConditional);
  }, [templateConditional]);

  if (!loanId || _.isEmpty(loan)) return null;

  return (
    <Wrap $active={active}>
      {loansUpdatedAt && (
        <>
          <Row>
            <RowLabel>
              {`Данные обновлены  `}
              <Tooltip title='Загрузить данные из 1С'>
                <SyncOutlined onClick={onUpdate} style={{ color: `#6CD5C7` }} />
              </Tooltip>
            </RowLabel>
            <RowValue>
              {`${formatDate(loansUpdatedAt)} `}
            </RowValue>
          </Row>
          {active && !!overdueDebtTerm && (
            <Row>
              <RowLabel>
                {templateConditional ? `Шаблоны  ` : `Шаблон  `}
                {/*@ts-ignore*/}
                <Tooltip title={`Регулярный - Копировать: (${template})`}>
                  <CopyOutlined onClick={copyRegularTemplate} style={{ color: `#6CD5C7` }} />
                </Tooltip>
                {templateConditional && (
                  <Tooltip title={`Новый - Копировать: (${templateConditional})`}>
                    <DiffOutlined onClick={copyConditionalTemplate} style={{ color: `#6CD5C7` }} />
                  </Tooltip>
                )}
              </RowLabel>
              <RowValue />
            </Row>
          )}
        </>
      )}

      <Row>
        <RowLabel>Состояние</RowLabel>
        <RowValue><ConditionDropdown entity='contract' loanId={loanId} personId={personId} /></RowValue>
      </Row>

      <Row>
        <RowLabel>Номер договора</RowLabel>
        <RowValue>{contractNumber}</RowValue>
      </Row>

      {loanAmount && (
        <Row>
          <RowLabel>Сумма</RowLabel>
          <RowValue>{prettifyAmount(loanAmount)}</RowValue>
        </Row>
      )}

      {interestRateStr && (
        <Row>
          <RowLabel>Ставка</RowLabel>
          <RowValue>{interestRateStr}</RowValue>
        </Row>
      )}

      <Row>
        <RowLabel>Дата открытия</RowLabel>
        <RowValue>{formatDate(creditStartDtm, `dd.MM.yyyy`)}</RowValue>
      </Row>

      <Row>
        <RowLabel>Дата планового закрытия</RowLabel>
        <RowValue>{formatDate(creditEndDtm, `dd.MM.yyyy`)}</RowValue>
      </Row>

      {initialLoanTerm && (
        <Row>
          <RowLabel>Срок</RowLabel>
          <RowValue>{`${initialLoanTerm} мес.`}</RowValue>
        </Row>
      )}

      {!_.isNil(activeFlg) && (
        <Row>
          <RowLabel>Статус</RowLabel>
          <RowValue>{activeFlg ? `Открытый` : `Закрытый`}</RowValue>
        </Row>
      )}

      <Row>
        <RowLabel>Продукт</RowLabel>
        <RowValue>{productName}</RowValue>
      </Row>

      {!_.isNil(loanClosingAmount) && (
        <Row>
          <RowLabel>Внести для закрытия</RowLabel>
          <RowValue>{prettifyAmount(loanClosingAmount)}</RowValue>
        </Row>
      )}

      {!_.isNil(overdueDebtTerm) && (
        <Row>
          <RowLabel>Дни ПЗ</RowLabel>
          <RowValue>{overdueDebtTerm}</RowValue>
        </Row>
      )}

      {!!overdueDebtTerm && !_.isNil(totalOverdueDebt) && (
        <Row>
          <RowLabel>Просроченный</RowLabel>
          <RowValue>{`${prettifyAmount(totalOverdueDebt)} от ${formatDate(overdueDebtPaymentDtm, `dd MMM`)}`}</RowValue>
        </Row>
      )}

      {!_.isNil(nearestPaymentAmount) && (
        <Row>
          <RowLabel>Ближайший</RowLabel>
          <RowValue>{`${prettifyAmount(nearestPaymentAmount)}${nearestPaymentDtm ? ` до ${formatDate(nearestPaymentDtm, `dd MMM`)}` : ``}`}</RowValue>
        </Row>
      )}

      {!_.isNil(recommendedPaymentAmount) && (
        <Row>
          <RowLabel>К оплате</RowLabel>
          <RowValue>{`${prettifyAmount(recommendedPaymentAmount)} до ${formatDate(recommendedPaymentDate, `dd MMM`)}`}</RowValue>
        </Row>
      )}

      {!_.isNil(closeSource) && (
        <Row>
          <RowLabel>Источник закрытия</RowLabel>
          <RowValue>{closeSource}</RowValue>
        </Row>
      )}

      <Row>
        <RowLabel>Идентификатор</RowLabel>
        <RowValue><CellId value={loanId} /></RowValue>
      </Row>

      {!_.isEmpty(lastRepayment) && (
      <>
        <Row><RowLabel /></Row>
        <Row><RowLabel>Последнее погашение</RowLabel></Row>
        <Row>
          <RowLabel>Дата</RowLabel>
          {/*@ts-ignore*/}
          <RowValue>{formatDate(lastRepayment.paymentDtm)}</RowValue>
        </Row>
        <Row>
          <RowLabel>Сумма</RowLabel>
          {/*@ts-ignore*/}
          <RowValue>{prettifyAmount(lastRepayment.totalSum)}</RowValue>
        </Row>
      </>
      )}
    </Wrap>
  );
};

export default LoanItem;
