import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import BOOL_COLUMN from 'constants/BOOL_COLUMN';
import {
  copyToClipboard,
  formatDate,
  getColumn as getColumnDefault,
  getSort,
  prettifyAmount,
  prettifyNumber,
  sortAlphabetically,
} from 'helper';

import {
  CheckCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  SettingOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import { palette } from "styles/theme";

const CopyIcon = styled(CopyOutlined)`
  color: ${palette.primaryColor};
  font-size: 14px;
  cursor: pointer;
  margin-left: 4px;
`;

const OkIcon = styled(CheckCircleOutlined)`
  color: ${palette.primaryColor};
  cursor: pointer;
`;

const CancelIcon = styled(StopOutlined)`
  color: ${palette.alert600Color};
  cursor: pointer;
`;

const CloseIcon = styled(CloseOutlined)`
  color: ${palette.alert600Color};
  cursor: pointer;
`;

const getColumn = props => getColumnDefault({ sorter: getSort(props.key), ...props });

export const COLUMNS = ({ apply, close, decline }, dictionaries) => _.map(_.compact([
  {
    key   : `productId`,
    title : `Продукт`,
    sorter: getSort(`productId`, sortAlphabetically),
    width : 280,
    render: id => _.find(dictionaries?.product, { id })?.name,
  },
  {
    key   : `totalLimit`,
    render: prettifyNumber,
    title : `Общая сумма`,
    width : 120,
  },
  {
    key   : `limit`,
    render: prettifyAmount,
    title : `Сумма на руки`,
    width : 120,
  },
  {
    key  : `rate`,
    title: `Ставка`,
    width: 80,
  },
  {
    key   : `payment`,
    render: prettifyAmount,
    title : `Платеж`,
    width : 120,
  },
  {
    key  : `term`,
    title: `Срок, мес`,
    width: 80,
  },
  {
    key   : `insuranceProductId`,
    title : `Страховой продукт`,
    sorter: getSort(`insuranceProductId`, sortAlphabetically),
    width : 120,
    render: id => _.find(dictionaries?.product, { id })?.name,
  },
  {
    key   : `offerStatusId`,
    title : `Статус`,
    sorter: getSort(`offerStatusId`, sortAlphabetically),
    render: id => _.find(dictionaries?.offerStatus, { id })?.name,
    width : 150,
  },
  {
    key   : `typeId`,
    title : `Тип`,
    sorter: getSort(`typeId`, sortAlphabetically),
    render: id => _.find(dictionaries?.marketingOfferType, { id })?.name,
    width : 150,
  },
  {
    key   : `startDtm`,
    title : `Дата начала`,
    render: text => formatDate(text ? new Date(text) : ``, `dd.MM.yyyy`),
    width : 120,
  },
  {
    defaultSortOrder: `descend`,
    key             : `closeDtm`,
    title           : `Дата завершения`,
    render          : text => formatDate(text ? new Date(text) : ``, `dd.MM.yyyy`),
    width           : 130,
  },
  {
    key   : `statusUpdateDtm`,
    title : `Дата активности`,
    render: text => formatDate(text ? new Date(text) : ``, `dd.MM.yyyy`),
    width : 130,
  },
  {
    key  : `author`,
    title: `Автор`,
    width: 220,
  },
  {
    key   : `personId`,
    title : `Person ID`,
    width : 310,
    render: personId => (
      <span>
        {personId}<CopyIcon onClick={() => copyToClipboard(personId)} />
      </span>
    ),
  },
  {
    key   : `marketingOfferId`,
    title : `ID оффера`,
    width : 310,
    render: marketingOfferId => (
      <span>
        {marketingOfferId}<CopyIcon onClick={() => copyToClipboard(marketingOfferId)} />
      </span>
    ),
  },
  BOOL_COLUMN(`lkFlg`, `Отображается в ЛК клиента`),
  BOOL_COLUMN(`readFlg`, `Просмотрено клиентом`),
  {
    key   : `marketingOfferId`,
    title : `Действия`,
    width : 70,
    sorter: null,
    fixed : `right`,
    align : `center`,
    render: (marketingOfferId, marketingOffer) => {
      const menuItems = _.compact([
        _.isFunction(apply)
          ? {
            key    : 1,
            label  : <><OkIcon /> Отправить согласие</>,
            onClick: () => apply(marketingOfferId),
          }
          : null,
        _.isFunction(decline)
          ? {
            key    : 2,
            label  : <><CancelIcon /> Отправить отказ</>,
            onClick: () => decline(marketingOfferId),
          }
          : null,
        _.includes([`blue`, `green`], marketingOffer?.color) && _.isFunction(close)
          ? {
            key    : 3,
            label  : <><CloseIcon /> Закрыть</>,
            onClick: () => close(marketingOfferId),
          }
          : null,
      ]);
      if (!menuItems.length) {
        return null;
      }
      return (
        <Dropdown
          menu={{ items: menuItems }}
          placement='left'
          trigger='click'
        >
          <SettingOutlined />
        </Dropdown>
      );
    },

  },
]), getColumn);
