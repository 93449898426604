import moment from 'moment/moment';

import { DatePicker } from 'antd';

const DATE_FIELD = (key, title, restProps = {}) => ({
  Component            : DatePicker,
  format               : `DD.MM.YYYY`,
  key,
  title,
  transformInitialValue: v => (moment.isMoment(v) ? v : (v ? moment(v) : ``)),
  ...restProps,
});

export default DATE_FIELD;
