import React, {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { getClientApplicationDocs as getClientApplicationDocsAction } from 'models/clients/actions';
import {
  getClientApplicationDocs as getClientApplicationDocsSelector,
  getIsLoading as isClientLoadingSelector,
} from 'models/clients/selectors';
import { get as getDictionaryAction } from 'models/dictionaries/actions';
import { getItem as selectTaskById } from 'models/task/selectors';
import { IStatementDocument } from 'models/task/types';

import { Empty } from 'antd';
import ApplicationDocumentUploadModal from 'components/Modal/ApplicationDocumentUploadModal';
import { LegalClaimStatementCompose } from 'components/Modal/LegalClaimStatementCompose';

import {
  DocumentButton,
  DocumentControls,
  DocumentLabel,
  DocumentRow,
} from './styled';

interface IProps {
  documentIdsToCompose?: number[];
  documentTypes: IStatementDocument[];
  taskId: number;
}

export const ClaimStatements: React.FC<IProps> = ({ documentIdsToCompose, documentTypes, taskId }) => {
  const dispatch = useDispatch();

  const { applicationId, personId } = useSelector(state => selectTaskById(state, taskId));
  const docs = useSelector(state => getClientApplicationDocsSelector(state, personId, applicationId));
  const isClientLoading = useSelector(isClientLoadingSelector);
  const [documentTypeId, setDocumentTypeId] = useState<number | null>(null);
  const [documentTemplateId, setDocumentTemplateId] = useState<number | null>(null);
  const [isSkipModal, setIsSkipModal] = useState<boolean>(false);
  const [isComposeModalOpen, setIsComposeModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const getApplicationDocs = () => dispatch(getClientApplicationDocsAction({
    applicationId,
    force      : true,
    hideMessage: true,
    personId,
  }));

  useEffect(() => {
    if (applicationId && _.isEmpty(docs)) getApplicationDocs();
  }, [applicationId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleComposeClick = (id: number, templateId: number, skipModal?: boolean) => {
    setDocumentTypeId(id);
    setDocumentTemplateId(templateId);
    setIsSkipModal(skipModal);
    setIsComposeModalOpen(true);
  };

  const handleUploadClick = (id: number) => {
    setDocumentTypeId(id);
    setIsUploadModalOpen(true);
  };

  useEffect(() => {
      dispatch(getDictionaryAction({entity: `documentType`}))
    },
    [dispatch],
  );

  return (
      !_.isEmpty(documentTypes)
        ? (
          <>
            {_.map(documentTypes, ({ id, name, skipModal, templateId }) => (
              <DocumentRow key={id}>
                <DocumentLabel>{name}</DocumentLabel>
                <DocumentControls>
                  <DocumentButton
                    disabled={documentIdsToCompose && !_.includes(documentIdsToCompose, id)}
                    onClick={() => handleComposeClick(id, templateId, skipModal)}
                    type='primary'
                  >
                    Сформировать
                  </DocumentButton>
                  <DocumentButton
                    loading={isClientLoading}
                    onClick={() => handleUploadClick(id)}
                    type='primary'
                  >
                    {_.find(docs, { type: id }) ? `Заменить` : `Загрузить`}
                  </DocumentButton>
                </DocumentControls>
              </DocumentRow>
            ))}
            {!_.isNil(documentTypeId) && (
              <>
                <LegalClaimStatementCompose
                  documentTemplateId={documentTemplateId}
                  documentTypeId={documentTypeId}
                  isSkipModal={isSkipModal}
                  onClose={() => setIsComposeModalOpen(false)}
                  personId={personId}
                  taskId={taskId}
                  visible={isComposeModalOpen}
                />
                <ApplicationDocumentUploadModal
                  afterUpload={getApplicationDocs}
                  applicationId={applicationId}
                  close={() => setIsUploadModalOpen(false)}
                  docs={docs}
                  documentTypeId={documentTypeId}
                  isOpen={isUploadModalOpen}
                  personId={personId}
                />
              </>
            )}
          </>
        )
        : (
          <Empty description='Нет документов для формирования' />
        )
  );
};
