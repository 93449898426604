import _ from 'lodash';
import { intercom } from '@cashdrive/api/socket';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import {
  ADD_NOTIFICATIONS,
  GET,
  GET_COUNT,
  model as namespace,
  RESET,
  SET,
  SET_ALL_IS_READ,
  SET_ALL_NOTIFICATIONS_READ,
  SET_FILTER,
  SET_LOADING,
  SET_NOTIFICATION_ATTRIBUTE,
  UPDATE_IS_READ,
} from './actions';
import * as api from './api';
import {
  getCount,
  getItems,
  getNotificationsFilter,
} from './selectors';

import { LIMIT } from './constants';

const initialState = {
  count         : 0,
  isCountLoading: false,
  isLoading     : false,
  items         : [],
  total         : 0,
  filter        : [`unread`],
};

export default {
  namespace,
  state        : initialState,
  subscriptions: {
    setup({ dispatch }) {
      intercom.subscribe(`SOCKET_MESSAGE`, ({ payload: [type, notifications] }) => {
        if (type === `notificationsAdded`) {
          dispatch({ type: ADD_NOTIFICATIONS, payload: { notifications } });
        }
      });
    },
  },
  effects: {
    *[GET](action, { all, call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке уведомлений произошла ошибка`;
      try {
        const { clear = false, page = 0 } = action.payload;
        const [filter, prevItems] = yield all([
          select(getNotificationsFilter),
          select(getItems),
        ]);

        const { data: items, total } = parseResponse({
          defaultError,
          response: yield call(api.get, {
            limit : LIMIT,
            offset: page * LIMIT,
            filter,
          }),
        });

        yield put({ type: SET, payload: { items: clear ? items : [...prevItems, ...items], total } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_COUNT](action, { call, put }) {
      yield put({ type: SET, payload: { isCountLoading: true } });
      const defaultError = `При загрузке количества уведомлений произошла ошибка`;
      try {
        const { total } = parseResponse({
          defaultError,
          response: yield call(api.getCount),
        });

        yield put({ type: SET, payload: { count: total } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isCountLoading: false } });
      }
    },

    *[SET_ALL_IS_READ](action, { call, put }) {
      yield put({ type: SET, payload: { isReadLoading: true } });

      const defaultError = `При пометке всех уведомлений прочитанными произошла ошибка`;
      try {
        parseResponse({
          defaultError,
          response: yield call(api.setAllIsRead),
        });

        yield put({ type: SET_ALL_NOTIFICATIONS_READ });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isReadLoading: false } });
      }
    },

    *[UPDATE_IS_READ](action, { call, put, select }) {
      yield put({ type: SET, payload: { isReadLoading: true } });

      const { id, isRead } = action.payload;
      const defaultError = `При пометке уведомления ${isRead ? `` : `не`}прочитанным произошла ошибка`;
      try {
        parseResponse({
          defaultError,
          response: yield call(api.updateIsRead, { id, isRead }),
        });

        const currentCount = yield select(getCount);

        yield put({ type: SET_NOTIFICATION_ATTRIBUTE, payload: { id, isRead } });
        yield put({ type: SET, payload: { count: isRead ? currentCount - 1 : currentCount + 1 } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isReadLoading: false } });
      }
    },
  },
  reducers: {
    [ADD_NOTIFICATIONS](state, { payload = {} }) {
      const { notifications } = payload;

      return {
        ...state,
        count: state.count + _.size(notifications),
        items: [
          ...(_.orderBy(notifications, `createdAt`, `desc`)),
          ...(state.items || []),
        ],
      };
    },

    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        ...payload,
      };
    },

    [SET_ALL_NOTIFICATIONS_READ](state) {
      return {
        ...state,
        count: 0,
        items: _.map(state.items, item => ({ ...item, isRead: true })),
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },

    [SET_NOTIFICATION_ATTRIBUTE](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      const { id, ...attribute } = payload;

      return {
        ...state,
        items: _.map(state.items, item => (
          item.id === id
            ? { ...item, ...attribute }
            : item
        )),
      };
    },

    [SET_FILTER](state, { payload: [property, value] }) {
      return {
        ...state,
        filter: value
          ? _.uniq([...state.filter, property])
          : _.without(state.filter, property),
      };
    },
  },
};
