import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  CheckOutlined,
  DiffOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Select as SelectDefault } from 'antd';
import {
  EntityCard as EntityCardDefault,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';

export const Select = styled(SelectDefault)`
  width: 100%;
`;
export const EntityCard = styled(EntityCardDefault)`
  margin-bottom: 16px;
  ${EntityCardTitle} {
    justify-content: space-between;
  }
}
`;
export const IconUpload = styled(DiffOutlined)`
  margin-right: 8px;
  color: ${palette.black60Color};
`;
export const IconComplete = styled(CheckOutlined)`
  color: ${palette.primaryColor};
`;
export const IconWarn = styled(WarningOutlined)`
  color: ${palette.redColor};
`;
export const Label = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
`;
export const Row = styled.div`
  margin-bottom: 16px;
`;
