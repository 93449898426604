import React, {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  height,
  palette,
  shadows,
} from 'styles/theme';

import {
  get as getNotificationsAction,
  setAllIsRead as setAllNotificationsReadAction,
  setFilter as setNotificationsFilterAction,
} from 'models/notifications/actions';
import {
  getIsLoading as getIsLoadingSelector,
  getItems as getItemsSelector,
  getNotificationsFilter as getNotificationsFilterSelector,
  getNotificationsTotal as getNotificationsTotalSelector,
} from 'models/notifications/selectors';

import useMobileContext from 'hooks/useMobileContext';

import {
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  Button,
  Empty,
  Spin,
  Switch,
  Tabs,
  TabsProps,
} from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';

import NotificationItem from '../NotificationItem';

const Wrapper = styled.div<{ isVisible: boolean }>`
  box-shadow: ${shadows.basic};
  top: 54px;
  position: fixed;
  right: 0;
  left: 0;
  max-width: 100vw;

  flex-grow: 1;
  margin: 0;

  @media(min-width: ${breakpoints.md}) {
    top: 100%;
    position: absolute;
    left: unset;
    right: 12px;
    max-width: 540px;
  }

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 100ms ease;
  z-index: ${({ isVisible }) => (isVisible ? 4 : -1)};
  pointer-events: ${({ isVisible }) => (isVisible ? `auto` : `none`)};
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  box-shadow: none;
  color: ${palette.textSecondaryColor};

  :hover {
    color: ${palette.textPrimaryColor};
  }
`;

const FilterTabs = styled(Tabs)`
  flex-shrink: 0;

  &.ant-tabs {
    color: ${palette.textPrimaryColor};
  }

  &.ant-tabs-top > .ant-tabs-nav::before,
  &.ant-tabs-bottom > .ant-tabs-nav::before,
  &.ant-tabs-top > div > .ant-tabs-nav::before,
  &.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid ${palette.borderDefaultColor};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${palette.primary500Color};
  }

  .ant-tabs-ink-bar {
    background-color: ${palette.primary500Color};
  }

  .ant-tabs-tab:hover, .ant-tabs-tab:active, .ant-tabs-tab:focus {
    color: ${palette.primary500Color};
  }
`;

const StyledEmpty = styled(Empty)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledSpin = styled(Spin)<{ $grow: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 30px;

  ${props => props.$grow && `
    height: 100%;
  `}
`;

const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 16px;
  color: ${palette.primary500Color};
  cursor: pointer;
  margin-bottom: 8px;
  font-size: 14px;
`;

const StyledScrollbars = styled(Scrollbars)`
  padding-top: 6px;
  overflow-y: scroll;

  @media(min-width: ${breakpoints.md}) {
    padding-top: 0;
    overflow: auto;
  }
`;

const Content = styled.div`
  height: calc(100vh - ${height.headerMobile});
  padding: 16px 0 16px 16px;
  display: flex;
  flex-direction: column;
  background-color: ${palette.backgroundColor};
  border: 1px solid ${palette.borderDefaultColor};
  color: ${palette.textPrimaryColor};
  cursor: initial;
  max-width: 540px;
  width: 100vw;

  @media (min-width: ${breakpoints.md}) {
    height: calc(100vh - 110px);
    padding: 24px 0 24px 16px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: space-between;
  height: 62px;
  margin-bottom: 16px;

  @media(min-width: ${breakpoints.md}) {
    align-items: center;
    flex-direction: row;
    height: 22px;
    margin-right: 16px;
  }
`;

const Title = styled.h3`
  width: 100%;
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  color: ${palette.textPrimaryColor};

  @media(min-width: ${breakpoints.md}) {
    width: inherit;
  }
`;

const Switcher = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 8px;
  align-items: center;

  .ant-switch-checked {
    background-color: ${palette.primary500Color};
  }

  @media(min-width: ${breakpoints.md}) {
    flex-direction: row;
    margin-top: 0;
  }
`;

const SwitcherText = styled.p`
  margin-left: 8px;
  margin-right: 0;
  font-size: 14px;
  color: ${palette.textSecondaryColor};

  @media(min-width: ${breakpoints.md}) {
    margin-right: 8px;
    margin-left: 0;
  }
`;

interface IComponentProps {
  visible: boolean;
  toggleVisible(): void;
}

const TAB_ITEMS: TabsProps[`items`] = [
  {
    key  : `all`,
    label: `Все`,
  },
  {
    key  : `important`,
    label: `Важные`,
  },
  {
    key  : `personal`,
    label: `Персональные`,
  },
];

export const NotificationPanel = React.forwardRef<HTMLDivElement, IComponentProps>((
  { toggleVisible, visible }, ref,
) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const { mobile } = useMobileContext();

  const isLoading = useSelector(getIsLoadingSelector);
  const notifications = useSelector(getItemsSelector);
  const notificationsFilter = useSelector(getNotificationsFilterSelector);
  const notificationsTotal = useSelector(getNotificationsTotalSelector);

  const getNotifications = ({ clear = false, newPage = 0 } = {}) => {
    setPage(newPage);
    dispatch(getNotificationsAction({ clear, page: newPage }));
  };

  const setAllNotificationsRead = () => dispatch(setAllNotificationsReadAction());
  const setNotificationsFilter = (type: any[]) => dispatch(setNotificationsFilterAction(type));

  const getNextPage = _.debounce(({
    top,
  }) => {
    if (!isLoading && top > 0.85 && _.size(notifications) < notificationsTotal) {
      getNotifications({ newPage: page + 1 });
    }
  }, 50);

  const onScroll = _.debounce(({ top }) => {
    getNextPage({
      top,
    });
  }, 100);

  const onSwitchClick = (checked: any, e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    setNotificationsFilter([`unread`, checked]);
    getNotifications({ clear: true, newPage: 0 });
  };

  const onTabSelect = (tabName: string) => {
    setNotificationsFilter([`important`, tabName === `important`]);
    setNotificationsFilter([`personal`, tabName === `personal`]);
    getNotifications({ clear: true, newPage: 0 });
  };

  useEffect(() => {
    getNotifications({ clear: true, newPage: 0 });
  }, []);

  return (
    <Wrapper isVisible={visible} ref={ref}>
      <Content>
        <Header>
          {mobile
            ? (<CloseButton icon={<CloseOutlined />} onClick={toggleVisible} />)
            : (<Title>Уведомления</Title>)}
          <Switcher>
            <SwitcherText>
              Только непрочитанные
            </SwitcherText>
            <Switch
              checked={_.includes(notificationsFilter, `unread`)}
              checkedChildren={<CheckOutlined />}
              onClick={onSwitchClick}
              unCheckedChildren={<CloseOutlined />}
            />
          </Switcher>
        </Header>
        <Controls>
          <div onClick={setAllNotificationsRead}>
            Пометить все уведомления прочитанными
          </div>
        </Controls>
        <FilterTabs
          activeKey={_.includes(notificationsFilter, `important`)
            ? `important`
            : (_.includes(notificationsFilter, `personal`) ? `personal` : `all`)}
          items={TAB_ITEMS}
          onChange={onTabSelect}
        />
        <StyledScrollbars
          autoHide
          onScrollFrame={onScroll}
        >
          {_.isEmpty(notifications) ? (
            <StyledEmpty
              description='Уведомлений нет'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ) : _.map(notifications, ({
            attachments,
            createdAt,
            description,
            id,
            isRead,
            title,
            urls,
          }) => (
            <NotificationItem
              attachments={attachments}
              createdAt={createdAt}
              description={description}
              id={id}
              isRead={isRead}
              key={id}
              title={title}
              urls={urls}
            />
          ))}
          {isLoading && (
            <StyledSpin
              $grow={_.isEmpty(notifications)}
            />
          )}
        </StyledScrollbars>
      </Content>
    </Wrapper>
  );
});

