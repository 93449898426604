import * as React from 'react';
import moment from 'moment/moment';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { DatePicker } from 'antd';

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
  @media(min-width: ${breakpoints.lg}) {
    min-width: 300px;
  }
`;

interface IComponentProps {
  dateFrom: any;
  dateTo: any;
  setDateRange: (dates: any, dateStrings: any) => void;
}

const RANGES = {
  'За сутки'  : [moment().subtract(24, `hours`), moment()],
  'За неделю' : [moment().subtract(7, `days`), moment()],
  'За месяц'  : [moment().subtract(1, `month`), moment()],
  'За квартал': [moment().subtract(3, `months`), moment()],
}

export const VerificationPluralRangePicker: React.FC<IComponentProps> = ({
  dateFrom,
  dateTo,
  setDateRange,
}) => (// @ts-ignore
  <StyledRangePicker
    allowEmpty={[true, true]}
    className='controls-datepicker'
    format='DD.MM.YYYY HH:mm'
    onChange={setDateRange}
    placeholder={[`От`, `до`]}
    // @ts-ignore
    ranges={RANGES}
    showTime={{ format: `HH:mm` }}
    value={[dateFrom, dateTo]}
  />
);
