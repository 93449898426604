import { useCallback } from 'react';
import _ from 'lodash';

import { useDictionaries } from 'models/dictionaries/hooks';
import { IVerificationResultType } from 'models/dictionaries/types';
import {
  AnyVerificationResultValue,
  IVerificationResults,
  TextVerificationResultValue,
} from 'models/verifications/types';

type IsComplete = (verificationResultTypeId: number) => boolean;

export const useCheckVerificationResultTypeForComplete = (results: IVerificationResults): IsComplete => {
  const [, dictionaries] = useDictionaries([`verificationResultType`, `verificationResult`]);

  return useCallback((verificationResultTypeId: number) => {
    const value: AnyVerificationResultValue = results?.[verificationResultTypeId];
    const verificationResultType = _.find(
      dictionaries?.verificationResultType,
      { id: verificationResultTypeId },
    ) as IVerificationResultType;

    const { fieldType } = verificationResultType;

    if (!value) return false;
    if (fieldType === 1) return true;
    if (fieldType === 2) return !_.isEmpty(value);
    if (fieldType === 3) {
      const missingValueKeys = _.filter(_.map(results, `id`), id => !_.has(value, id));
      return _.isEmpty(missingValueKeys) && _.every(value as TextVerificationResultValue);
    }
    return true;
  }, [results, dictionaries?.verificationResultType]);
};
