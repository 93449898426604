import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { v4 } from 'uuid';

import {
  formatDate,
  getFullNameInitials,
  hasIntersection,
} from 'helper';

import { useClient } from 'models/clients/hooks';
import { useDictionaries } from 'models/dictionaries/hooks';
import { search as searchAction } from 'models/eventlogs/actions';
import {
  getItemsByEntities as getByEntitiesSelector,
  isItemsOverdue as isItemsOverdueSelector,
  isLoading as isLoadingSelector,
} from 'models/eventlogs/selectors';
import { useOperators } from 'models/operators/hooks';
import { IOperator } from 'models/operators/types';

import {
  Empty,
  Skeleton,
} from 'antd';

import { Item } from './Item';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IEntity {
  entity: string;
  entityId?: number;
  personId?: string;
}

interface IComponentPropsSingle extends IEntity {
  entities?: never;
}

interface IComponentPropsMultiple {
  entities: IEntity[];
  entity?: never;
  entityId?: never;
  personId?: never;
}

type IComponentProps = IComponentPropsSingle | IComponentPropsMultiple;

interface IAdditionalProps {
  client?: object;
  communicationTypeNext?: object;
  communicationTypePrev?: object;
  documentType?: object;
  hmSearchPartnerNext?: object;
  hmSearchPartnerPrev?: object;
  operator?: IOperator;
  operatorName?: string;
  operatorNext?: object;
  operatorPrev?: object;
  statusNext?: object;
  statusPrev?: object;
}

export const Eventlog: React.FC<IComponentProps> = ({
  entities: _entities,
  entity: _entity,
  entityId: _entityId,
  personId: _personId,
}) => {
  const dispatch = useDispatch();

  const uuid = useMemo(v4, []);
  // @ts-ignore
  const entities:IEntity[] = useMemo(() => (
    _.isEmpty(_entities)
      ? [{ entity: _entity, entityId: _entityId, personId: _personId, uuid }]
      : _.map(_entities, e => ({ ...e, uuid }))
  ), [_entities, _entity, _entityId, _personId, uuid]);

  // @ts-ignore
  const isAllEntitiesValid = _.every(entities, e => e.entity && (e.entityId || e.personId));
  const entitiesNames = _.map(entities, `entity`);

  const isItemsOverdue = useSelector(state => isItemsOverdueSelector(state, entities));

  const isItemsLoading = useSelector(isLoadingSelector);
  const items = useSelector(state => getByEntitiesSelector(state, entities));

  const [isClientLoading, client] = useClient(
    // @ts-ignore
    _.find(entities, e => _.includes([`client`, `user`], e.entity))?.personId,
    hasIntersection([`client`, `user`], entitiesNames),
  );

  const [isOperatorsLoading, operators] = useOperators(
    (isAllEntitiesValid
    && hasIntersection([`client`, `document`, `task`, `verification`], entitiesNames)),
    false,
  );

  const [isDictionariesLoading, dictionaries] = useDictionaries([
    `communicationType`,
    `documentType`,
    `hmSearchPartner`,
    `taskStatus`,
    `verificationStatus`,
  ]);

  // @ts-ignore
  const getItems = useCallback(item => {
    dispatch(searchAction(item));
  }, [dispatch]);

  useEffect(() => {
      if (!isAllEntitiesValid || isItemsLoading || !isItemsOverdue) return;
      _.each(entities, getItems);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getItems,
      isAllEntitiesValid,
      isItemsLoading,
      isItemsOverdue,
    ],
  );

  if (!isAllEntitiesValid) return null;

  const isLoading = isClientLoading
    || isItemsLoading
    || isDictionariesLoading
    || isOperatorsLoading;

  return (
    <Wrapper>
      <Skeleton active avatar loading={isLoading}>
        {_.isEmpty(items)
          ? (
            <Empty
              description='Нет данных'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )
          : (
            <Items>
              {_.map(items, item => {
                const additionalProps:IAdditionalProps = {};
                if (item.operatorId) {
                  additionalProps.operator = _.find(operators, { id: item.operatorId });
                  additionalProps.operatorName = getFullNameInitials(additionalProps.operator?.user);
                }

                if (item.documentTypeId) {
                  additionalProps.documentType = _.find(dictionaries.documentType, { id: item.documentTypeId });
                }

                if (item.type === `operator`) {
                  additionalProps.operatorPrev = _.find(operators, { id: item.prev });
                  additionalProps.operatorNext = _.find(operators, { id: item.next });
                }

                if (item.entity === `task`) {
                  if (item.type === `hmSearchPartner`) {
                    additionalProps.hmSearchPartnerPrev = _.find(dictionaries.hmSearchPartner, { id: item.prev });
                    additionalProps.hmSearchPartnerNext = _.find(dictionaries.hmSearchPartner, { id: item.next });
                  }

                  if (item.type === `status`) {
                    additionalProps.statusPrev = _.find(dictionaries.taskStatus, { id: item.prev });
                    additionalProps.statusNext = _.find(dictionaries.taskStatus, { id: item.next });
                  }
                }

                if (item.entity === `user`) {
                  additionalProps.client = client;

                  if (item.type === `communicationTypeUpdate`) {
                    additionalProps.communicationTypePrev = _.find(dictionaries.communicationType, { id: item.prev });
                    additionalProps.communicationTypeNext = _.find(dictionaries.communicationType, { id: item.next });
                  }
                }

                if (item.entity === `verification`) {
                  if (item.type === `status`) {
                    additionalProps.statusPrev = _.find(dictionaries.verificationStatus, { id: item.prev });
                    additionalProps.statusNext = _.find(dictionaries.verificationStatus, { id: item.next });
                  }
                }

                return (
                  <Item
                    {...item}
                    {...additionalProps}
                    date={formatDate(item.createdAt)}
                    isOperatorsLoading={isOperatorsLoading}
                    key={item.id}
                  />
                );
              })}
            </Items>
          )}
      </Skeleton>
    </Wrapper>
  );
};
