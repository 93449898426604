import addressFact from './img/addressFact.svg';
import addressReg from './img/addressReg.svg';
import carCockpitPhotoGeo from './img/carCockpitPhotoGeo.svg';
import dealership from './img/dealership.svg';
import passportPhotoGeo from './img/passportPhotoGeo.svg';

export const ICONS = {
  addressFact,
  addressReg,
  carCockpitPhotoGeo,
  dealership,
  passportPhotoGeo,
};

export const ADDRESS_TITLES = {
  addressFact       : `Адрес проживания из заявки`,
  addressReg        : `Адрес регистрации из заявки`,
  carCockpitPhotoGeo: `Гео-метка фото авто`,
  dealership        : `Адрес партнёра`,
  passportPhotoGeo  : `Гео-метка фото паспорта`,
};

export const ICON_IMAGE_SIZE = [32, 32];

export const ICON_HOVERED_IMAGE_SIZE = [40, 40];

export const ICON_HOVERED_OFFSET = [
  (ICON_IMAGE_SIZE[0] - ICON_HOVERED_IMAGE_SIZE[0]) / 2,
  ICON_IMAGE_SIZE[1] - ICON_HOVERED_IMAGE_SIZE[1],
];

export const Z_INDEX_HOVERED = 10;
