import _ from 'lodash';
import {
  intercom,
  socketEmit,
  socketState,
} from '@cashdrive/api/socket';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { notification } from 'antd';

import {
  ADD_MESSAGE,
  CONNECTION_RESTORED,
  CREATE,
  GET_MESSAGES,
  GET_MESSAGES_MULTIPLE,
  GET_SINGLE,
  MERGE_ITEMS,
  model as namespace,
  REMOVE,
  REMOVE_MESSAGE,
  REPLY,
  RESET,
  SEARCH,
  SEND_MESSAGE,
  SET,
  SET_INPUT_VALUE,
  SET_LOADING,
  SET_MESSAGE_READ,
  UPDATE,
  UPDATE_MESSAGE,
} from './actions';
import * as api from './api';
import { getItems as getItemsSelector } from './selectors';

const initialState = {
  inputValues: {},
  isLoaded   : false,
  isLoading  : false,
  items      : [],
};

let isSocketConnectionNotificationOpen = false;

export default {
  namespace,
  state        : initialState,
  subscriptions: {
    setup({ dispatch }) {
      intercom.subscribe(`SOCKET_CONNECT`, () => {
        if (isSocketConnectionNotificationOpen) {
          dispatch({ type: CONNECTION_RESTORED });
          notification.success({
            duration: 2,
            key     : `socketConnectionLost`,
            message : `Соединение восстановлено, работаем`,
            onClose : () => {
              isSocketConnectionNotificationOpen = false;
            },
          });
        }
      });

      intercom.subscribe(`SOCKET_CONNECT_ERROR`, () => {
        isSocketConnectionNotificationOpen = true;
        notification.warn({
          duration: 0,
          key     : `socketConnectionLost`,
          message : `CRM устал или обновляется. Это займёт около минуты, ждём-с.`,
          onClose : () => {
            isSocketConnectionNotificationOpen = false;
          },
        });
      });

      intercom.subscribe(`SOCKET_MESSAGE`, message => {
        const [type, payload] = message;
        switch (type) {
          case `messageAdded`:
            dispatch({ type: ADD_MESSAGE, payload: { id: payload.dialogId, message: payload.message } });
            break;

          case `messageEdited`:
          case `setRead`:
            dispatch({ type: UPDATE_MESSAGE, payload: { id: payload.id, message: payload.message } });
            break;

          case `messageDeleted`:
            dispatch({ type: REMOVE_MESSAGE, payload });
            break;

          default: break;
        }
      });
    },
  },
  effects: {
    *[CONNECTION_RESTORED](action, { put, select }) {
      const defaultError = `При загрузке диалогов произошла ошибка`;

      try {
        const ids = _.map(yield select(getItemsSelector), `id`);
        yield put({ type: GET_MESSAGES_MULTIPLE, payload: { ids } });
      } catch (error) {
        showError({ defaultError, error });
      }
    },

    *[CREATE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При создании диалога произошла ошибка`;

      try {
        const { dialog } = action.payload;
        if (_.isEmpty(dialog)) throw new Error(defaultError);

        const item = parseResponse({
          defaultError,
          response: yield call(api.create, dialog),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.concat(currentItems, item),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_MESSAGES](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id } = action.payload;
      const defaultError = `При загрузке сообщений диалога с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(defaultError);

        const messages = parseResponse({
          defaultError,
          response: yield call(api.getMessages, id),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => (currentItem.id === id
              ? { ...currentItem, messages }
              : currentItem
            )),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_MESSAGES_MULTIPLE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids } = action.payload;
      const defaultError = `При загрузке сообщений диалогов с id '${ids}' произошла ошибка`;

      try {
        if (_.isEmpty(ids)) return;

        const messages = parseResponse({
          defaultError,
          response: yield call(api.getMessagesMultiple, ids),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => ({
              ...currentItem,
              messages: (_.get(messages, currentItem.id, [])),
            })),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_SINGLE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id } = action.payload;
      const defaultError = `При загрузке диалога с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(defaultError);

        const item = parseResponse({
          defaultError,
          response: yield call(api.getSingle, id),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => (currentItem.id === id
              ? { ...currentItem, ...item }
              : currentItem
            )),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При удалении сообщения произошла ошибка`;

      try {
        const { dialogId, messageId } = action.payload;
        if (!dialogId || !messageId) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.remove, dialogId, messageId),
        });

        yield put({ type: REMOVE_MESSAGE, payload: action.payload });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REPLY](action, { put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При отправке сообщения произошла ошибка`;

      try {
        const {
          callback,
          id,
          message,
          messageId,
          title,
          url,
        } = action.payload;
        if (!id || !messageId || _.isEmpty(message)) throw new Error(defaultError);

        yield put({
          type   : SEND_MESSAGE,
          payload: {
            callback,
            id,
            message,
            replyToMessageId: messageId,
            title           : `Ответ на "${title}"`,
            url,
          },
        });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SEARCH](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При поиске диалогов произошла ошибка`;

      try {
        const { callback, shouldCreate = false, single = false, ...payload } = action.payload;

        if (_.isEmpty(payload)) throw new Error(defaultError);

        const items = parseResponse({
          defaultError,
          response: yield call(api.search, { ...payload, single }),
        });
        if (_.isEmpty(items) && shouldCreate) {
          yield put({ type: CREATE, payload: { dialog: payload } });
        } else {
          yield put({ type: MERGE_ITEMS, payload: { items } });
        }

        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SEND_MESSAGE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const {
        id,
        message,
        replyToMessageId,
        title,
        url,
      } = action.payload;
      const defaultError = `При отправке сообщения произошла ошибка`;

      try {
        if (!id || _.isEmpty(message)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.addMessage, { ...message, id, replyToMessageId, title, url }),
        });

        // yield put({ type: ADD_MESSAGE, payload: { id, message: newMessage } }); todo: из CRM-435 bugfix. Возникает коллизия.

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_MESSAGE_READ](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { dialogId, id } = action.payload;
      const defaultError = `При пометке сообщения с id ${id} прочитанным в диалоге с id '${dialogId}' произошла ошибка`;

      try {
        if (!id || !dialogId) throw new Error(defaultError);
        if (!socketState.connected) throw new Error(`Нет соединения с сервером`);

        const { payload: message } = yield call(socketEmit, `setRead`, { id, dialogId });
        yield put({ type: UPDATE_MESSAGE, payload: { id: dialogId, message } });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При редактировании сообщения произошла ошибка`;

      try {
        const { id: dialogId, message, messageId } = action.payload;
        if (!dialogId || !messageId || _.isEmpty(message)) throw new Error(defaultError);

        const updatedMessage = parseResponse({
          defaultError,
          response: yield call(api.update, dialogId, messageId, message),
        });

        yield put({
          type   : UPDATE_MESSAGE,
          payload: {
            id     : dialogId,
            message: updatedMessage,
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [ADD_MESSAGE](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      const { id, message } = payload;

      // prevent adding already added messages todo: CRM-448
      const dialogs = state.items;
      const currentDialog = _.find(dialogs, { id });

      if (_.isEmpty(currentDialog) || _.find(currentDialog.messages, { id: message.id })) {
        return state;
      }

      return {
        ...state,
        items: _.map(state.items, item => (item.id === id
          ? {
            ...item,
            messages: _.concat((item.messages || []), message),
          }
          : item
        )),
      };
    },

    [MERGE_ITEMS](state, { payload: { items } }) {
      if (_.isEmpty(items)) return state;

      return {
        ...state,
        items: [..._.reduce(items, (result, item) => {
          const i = _.findIndex(result, { id: item.id });
          if (i >= 0) {
            result[i] = { ...result[i], ...item }; // eslint-disable-line no-param-reassign
          } else {
            result.push(item);
          }
          return result;
        }, state.items)],
      };
    },

    [REMOVE_MESSAGE](state, { payload: { dialogId, messageId } }) {
      return {
        ...state,
        items: _.map(state.items, dialog => ({
          ...dialog,
          messages: dialog.id === dialogId
            ? _.reject(dialog.messages, { id: messageId })
            : dialog.messages,
        })),
      };
    },

    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        ...payload,
      };
    },

    [SET_INPUT_VALUE](state, { payload: { id, inputValue } }) {
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          [id]: inputValue,
        },
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },

    [UPDATE_MESSAGE](state, { payload: { id, message } }) {
      return {
        ...state,
        items: _.map(state.items, item => (item.id === id
          ? {
            ...item,
            messages: _.map(item.messages, m => (m.id === message.id ? { ...m, ...message } : m)),
          }
          : item
        )),
      };
    },
  },
};
