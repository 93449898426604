import api from 'api/index';
import { getLimitParams } from 'helper';

export const get = params => api.get(`/operator${getLimitParams(params)}`);
export const getCurrent = () => api.get(`/operator/current`);
export const getSingle = id => api.get(`/operator/single/${id}`);
export const remove = ids => api.delete(`/operator`, { data: { ids } });
export const removeSingle = id => api.delete(`/operator/single/${id}`);
export const update = (id, data) => api.patch(`/operator/single/${id}`, data);
export const create = data => api.put(`/operator`, data);
export const savePreferences = data => api.put(`/operator/preferences`, data);
