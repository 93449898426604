import * as React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { CloseOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
`;

const SearchInput = styled.input.attrs(props => ({
  placeholder: props.placeholder ? props.placeholder : `Поиск`,
  type       : `search`,
}))`
  padding: 4px 16px;
  padding-right: 32px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  flex: 1;

  color: ${rgba(0, 0, 0, 0.85)};

  ::placeholder {
    transition: color 300ms ease;
    color: ${rgba(0, 0, 0, 0.45)};
  }

  :focus {
    ::placeholder {
      color: ${rgba(0, 0, 0, 0.65)};
    }
  }

  @media(min-width: ${breakpoints.md}) {
    flex: 1;
  }
`;

const iconStylesMixin = `
  position: absolute;
  right: 16px;
  user-select: none;
`;

const SearchIcon = styled.span.attrs(props => ({
  className: `cd cd-search ${props.className}`,
}))`
  ${iconStylesMixin};
  pointer-events: none;

  :before {
    transition: color 300ms ease;
    color: ${rgba(0, 0, 0, 0.25)}
  }

  ${SearchInput}:focus + & {
    :before {
      color ${rgba(0, 0, 0, 0.45)}
    }
  }
`;

const CloseIcon = styled(CloseOutlined)`
  ${iconStylesMixin};

  svg {
    transition: color 300ms ease;
    color: ${rgba(0, 0, 0, 0.25)}
    vertical-align: inherit;
  }

  ${SearchInput}:focus + & {
    svg {
      color ${rgba(0, 0, 0, 0.45)}
    }
  }
`;

interface IComponentProps {
  allowClear?: boolean;
  className?: string;
  'data-test-search-button'?: string; // eslint-disable-line quotes
  'data-test-search-field'?: string; // eslint-disable-line quotes
  onChange: (value: string) => void;
  onPressEnter?: () => void;
  placeholder: string;
  value: string;
}

export const SubHeaderSearch: React.FC<IComponentProps> = ({
  allowClear,
  className,
  'data-test-search-button': dataTestSearchButton,
  'data-test-search-field': dataTestSearchField,
  onChange,
  onPressEnter,
  placeholder,
  value,
}) => {
  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === `Enter` && onPressEnter) {
      onPressEnter();
    }
  };

  return (
    <Wrapper className={className}>
      <SearchInput
        data-test={dataTestSearchField}
        onChange={event => onChange(event.target.value)}
        onKeyDown={handleOnKeyDown}
        placeholder={placeholder}
        value={value}
      />
      {(!allowClear || (allowClear && !value)) && <SearchIcon data-test={dataTestSearchButton} />}
      {allowClear && value && (<CloseIcon onClick={() => onChange(``)} />)}
    </Wrapper>
  );
};
