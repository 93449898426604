import * as React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment from 'moment';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  getClientApplicationOffers as getClientApplicationOffersAction,
  getClientLoanDetails as getClientLoanDetailsAction,
  getClientLoanRepaymentLinks as getClientLoanRepaymentLinksAction,
  getClientLoanSchedule as getClientLoanScheduleAction,
} from 'models/clients/actions';
import { LOAN_STATUSES } from 'models/clients/constants';
import { getIsLoadingLoanDetails as getIsLoadingLoanDetailsSelector } from 'models/clients/selectors';
import { ILoan } from 'models/clients/types';

import { useDynamicFilterValues } from 'hooks/useDynamicFilterValues';

import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';
import { COLUMNS } from 'pages/PageClient/Single/Loan/constants';
import { IAssignee } from 'pages/PageClient/Single/Loan/LoansCards/constants';
import { LoansTableCellControl } from 'pages/PageClient/Single/Loan/LoansTable/LoansTableCellControl';
import { LoansTableExpand } from 'pages/PageClient/Single/Loan/LoansTable/LoansTableExpand';
import { ILoanCommon } from 'pages/PageClient/Single/Loan/types';

const StyledAdjustableTable = styled(AdjustableTable)`
  .ant-table-expanded-row {
    > .ant-table-cell {
      background: ${palette.black10Color};
    }
  }

  .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }

  .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }

  @media screen and (max-width: 767px) {
    .ant-table-expanded-row {
      .ant-table-cell {
        box-shadow: none;
      }
    }

    .ant-table-expanded-row-fixed {
      overflow-x: scroll !important;
      width: auto !important;
    }
  }
`;

interface IComponentProps extends ILoanCommon {
  assignees: IAssignee[],
  contractConditions: any[],
  loans: ILoan[];
  personId: string;
}

export const LoansTable: React.FC<IComponentProps> = ({
  assignees,
  contractConditions,
  loans,
  onCancelInsurance,
  personId,
  toggleAgreementModal,
  toggleConditionModal,
  toggleLoanCloseModal,
  toggleRepaymentLinkModal,
}) => {
  const dispatch = useDispatch();

  const [productNames] = useDynamicFilterValues(loans, `productName`);

  const getDetails = ({
    loanId,
    pdp,
    requestDtm,
  }: { loanId: string, pdp?: boolean, requestDtm?: moment.Moment }) => dispatch(getClientLoanDetailsAction({
    personId,
    loanId,
    pdp,
    requestDtm,
  }));

  const getOffers = (applicationId: string | undefined) => {
    dispatch(getClientApplicationOffersAction({ personId, applicationId, hideMessage: true }));
  };

  const getSchedule = (loanId: string) => dispatch(getClientLoanScheduleAction({ personId, loanId }));

  const getRepaymentLinks = (loanId: string) => dispatch(getClientLoanRepaymentLinksAction({ personId, loanId }));

  const getAdditionalInfo = (loanId: string) => {
    const loan = _.find(loans, { id: loanId });

    if (loan?.loanStatusId !== LOAN_STATUSES.CLOSED) getSchedule(loanId);
    getDetails({ loanId });
    const applicationId = _.get(loan, `application.id`) || _.get(loan, `applicationId`);
    getOffers(applicationId);
    getRepaymentLinks(loanId);
  };

  const defaultColumns = COLUMNS({
    assignees,
    contractConditions,
    getAdditionalInfo: (loanId: string) => getAdditionalInfo(loanId),
    productNames,
  });

  const isDetailsLoading = useSelector(getIsLoadingLoanDetailsSelector);

  return (
    <StyledAdjustableTable
      columns={defaultColumns}
      dataSource={loans}
      expandable={{
        expandedRowRender: loan => (
          <LoansTableExpand
            getDetails={getDetails}
            loan={loan}
            onCancelInsurance={onCancelInsurance}
            personId={personId}
            toggleConditionModal={toggleConditionModal}
          />
        ),
        onExpand: (expanded, record) => expanded && getAdditionalInfo(record.id),
      }}
      loading={isDetailsLoading}
      pagination={{
        hideOnSinglePage: true,
        pageSize        : _.size(loans),
        size            : `small`,
      }}
      preferences={{
        cellRender: (text, loan) => (
          <LoansTableCellControl
            loan={loan}
            personId={personId}
            toggleAgreementModal={toggleAgreementModal}
            toggleLoanCloseModal={toggleLoanCloseModal}
            toggleRepaymentLinkModal={toggleRepaymentLinkModal}
          />
        ),
        path: `clientInfo.loans`,
      }}
      rowKey='id'
      scroll={{ x: `max-content`, y: window.innerHeight - 250 }}
    />
  );
};
