import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { formatDate, prettifyAmount } from 'helper';
import { palette } from "styles/theme";

const DATE_FORMAT = 'dd.MM.yyyy';

const LoanScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  max-width: 400px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const Head = styled.div`
  background-color: ${palette.darkBlueColor};
  color: ${palette.whiteColor};
  display: flex;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 16px;
`;

const HeadItem = styled.div`
  flex-basis: 50%;
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
`;

const HeadItemText = styled.p`
  color: ${({ theme, bottom }) => (bottom ? theme.black60Color : theme.whiteColor)};
  margin-top: ${({ bottom }) => (bottom ? '4px' : '0')};
`;

const Column = styled.div`
  flex-basis: 50%;
  line-height: 16px;
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
`;

const ColumnLeft = styled(Column)`
  align-items: center;
  display: flex;
  position: relative;

  &:before {
    background-color: ${palette.black30Color};
    content: '';
    height: calc(100% + 24px);
    left: 8px;
    position: absolute;
    width: 1px;
    z-index: 0;
  }
`;
const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-top: 8px;
  padding: 8px 16px 8px 12px;

  &:first-child ${ColumnLeft}:before {
    bottom: -12px;
    height: calc(100% + 24px / 2);
  }

  &:last-child {
    margin-bottom: 8px;

    ${ColumnLeft}:before {
      height: calc(100% + 24px / 2);
      top: -12px;
    }
  }

  &:only-child ${ColumnLeft}:before {
    display: none;
  }
`;


const ColumnNumber = styled.div`
  background-color: ${({ theme, status }) => {
  if (status === 'paid') return theme.primaryColor;
  if (status === 'overdue') return theme.alert500Color;
  return theme.black10Color;
}};
  border-radius: 50%;
  color: ${({ theme, status }) => (status ? theme.whiteColor : theme.black60Color)};
  font-size: 12px;
  line-height: 17px;
  height: 17px;
  margin-right: 13px;
  position: relative;
  text-align: center;
  width: 17px;
  z-index: 1;
`;

const ColumnText = styled.p`
  color: ${({ theme, bottom }) => (bottom ? theme.black60Color : theme.black80Color)};
  font-size: ${({ bottom }) => (bottom ? '12px' : '14px')};
  margin-top: ${({ bottom }) => (bottom ? '4px' : '0')};
`;

const LoanSchedule = ({ value = [] }) => {
  if (_.isEmpty(value)) return null;

  return (
    <LoanScheduleWrapper>
      <Head>
        <HeadItem>
          <HeadItemText>Рекомендуемая дата</HeadItemText>
          <HeadItemText bottom>Дата платежа</HeadItemText>
        </HeadItem>
        <HeadItem alignRight>
          <HeadItemText>Сумма платежа</HeadItemText>
          <HeadItemText bottom>Остаток основного долга</HeadItemText>
        </HeadItem>
      </Head>
      <Table>
        {_.map(value, (item = {}, index) => (
          <Row key={index}>
            <ColumnLeft>
              <ColumnNumber status={item.paymentStatus}>
                {item.paymentNumber || index + 1}
              </ColumnNumber>
              <div>
                <ColumnText>
                  {formatDate(new Date(item.paymentDtm - 3 * 24 * 60 * 60 * 1000), DATE_FORMAT)}
                </ColumnText>
                <ColumnText bottom>{formatDate(new Date(item.paymentDtm), DATE_FORMAT)}</ColumnText>
              </div>
            </ColumnLeft>
            <Column>
              <ColumnText>{prettifyAmount(item.paymentAmount)}</ColumnText>
              <ColumnText bottom>{prettifyAmount(item.mainDebtBalance)}</ColumnText>
            </Column>
          </Row>
        ))}
      </Table>
    </LoanScheduleWrapper>
  );
};

export default LoanSchedule;
