import * as React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { getFullNameInitials } from 'helper';

import { useOperators } from 'models/operators/hooks';
import { getItemsByCampaignAccessRight as getOperatorsByCampaignAccessRight } from 'models/operators/selectors';
import { setOperator as setOperatorAction } from 'models/task/actions';

import { Select } from 'antd';

interface IComponentProps {
  campaign: any;
  canEditTask: boolean;
  task: any;
  taskId: number;
}

export const SelectTaskOperator: React.FC<IComponentProps> = ({ campaign, canEditTask, task, taskId }) => {
  const dispatch = useDispatch();

  const [isOperatorsLoading, operators] = useOperators();
  const operatorsToSelect = useSelector(state => getOperatorsByCampaignAccessRight(state, campaign?.name, `write`));

  const operator = _.find(operators, { id: task.operatorId });

  const value = operator ? {
    label: `Ответственный: ${getFullNameInitials(operator?.user || {})}`,
    value: operator?.id,
  } : undefined;

  const handleSelect = (operatorId: number) => {
    dispatch(setOperatorAction({
      id: taskId,
      operatorId,
    }));
  };

  const options = _.map(operatorsToSelect, item => ({
    label: getFullNameInitials(item.user || {}),
    value: item.id,
  }));

  return (
    <Select
      disabled={!canEditTask || isOperatorsLoading}
      // @ts-ignore
      onSelect={handleSelect}
      optionFilterProp='label'
      options={options}
      placeholder='Ответственный:'
      showSearch
      style={{ width: `100%` }}
      value={value}
    />
  );
};
