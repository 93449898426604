import React, {
  useCallback,
  useContext,
} from 'react';
import { useDispatch } from 'dva';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { reloadLastSearch } from 'models/eventlogs/actions';

import { Layout } from 'antd';
import { Eventlog } from 'components/Eventlog/Eventlog';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';

const StyledLayout = styled(Layout)`
  padding: 16px;
  position: relative;
  min-height: 100%;

  @media(min-width: ${breakpoints.md}) {
    padding: 16px 32px;
  }
`;

const PageClientSingleEventlog = () => {
  const dispatch = useDispatch();

  const onReload = useCallback(() => dispatch(reloadLastSearch()), [dispatch]);
  const { verificationId } = useContext(ClientMatchContext);

  return (
    <ClientTab onReload={onReload}>
      <StyledLayout>
        <Eventlog entity='verification' entityId={verificationId} />
      </StyledLayout>
    </ClientTab>
  );
};

export default PageClientSingleEventlog;
