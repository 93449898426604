import * as React from 'react';
import _ from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { formatDate } from 'helper';

import {
  EntityCard,
  EntityCardContent,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardRow } from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';
import { EntityCardSection } from 'pages/PageClient/Single/components/EntityCard/EntityCardSection';
import { ExternalLink } from 'pages/PageClient/Single/Info/styled';

const DateWrapper = styled.span`
  margin-right: 8px;
`;

const TimeWrapper = styled.span`
  color: ${palette.black60Color};
`;

const OPERATOR_NAME = {
  BEELINE: `BEELINE`,
};

type TOperatorName = typeof OPERATOR_NAME[keyof typeof OPERATOR_NAME];

export interface ISms {
  closeDtm: number;
  comment?: string;
  createDtm: number;
  operator: TOperatorName;
  operatorStatusText?: string;
  phone?: string;
  smsExternalId: string;
  smsStatusId: number;
  smsStatusName: string;
  smsText: string;
}

interface IComponentProps {
  sms: ISms;
}

export const SmsCard: React.FC<IComponentProps> = ({ sms }) => {
  const {
    comment,
    createDtm,
    operator,
    operatorStatusText,
    phone,
    smsExternalId,
    smsStatusName,
    smsText,
  } = sms;

  const parsedPhone = phone && parsePhoneNumber(phone, `RU`);

  const formattedDate = formatDate(new Date(createDtm));
  const [date, time] = _.split(formattedDate, ` `);

  return (
    <EntityCard>
      <EntityCardContent>
        {parsedPhone ? (
          <EntityCardRow title='Телефон' valueToCopy={parsedPhone.number}>
            <ExternalLink href={parsedPhone.getURI()}>
              {parsedPhone.formatInternational()}
            </ExternalLink>
          </EntityCardRow>
        ) : (
          <EntityCardRow title='Телефон'>
            {phone}
          </EntityCardRow>
        )}

        <EntityCardSection title='Текст' valueToCopy={smsText}>
          {smsText}
        </EntityCardSection>
        <EntityCardRow title='Отправлено' valueToCopy={formattedDate}>
          <DateWrapper>
            {date}
          </DateWrapper>
          <TimeWrapper>
            {time}
          </TimeWrapper>
        </EntityCardRow>
        <EntityCardRow title='Оператор' valueToCopy={operator}>
          {operator}
        </EntityCardRow>
        <EntityCardRow title='Статус' valueToCopy={smsStatusName}>
          {smsStatusName}
        </EntityCardRow>
        <EntityCardRow title='Статус у оператора' valueToCopy={operatorStatusText}>
          {operatorStatusText}
        </EntityCardRow>
        <EntityCardRow title='Внешний идентификатор' valueToCopy={smsExternalId}>
          {smsExternalId}
        </EntityCardRow>
        <EntityCardRow title='Комментарий' valueToCopy={comment}>
          {comment}
        </EntityCardRow>
      </EntityCardContent>
    </EntityCard>
  );
};
