import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import {
  INSURANCE_STATUSES_COLORS,
  INSURANCE_STATUSES_NAMES,
} from 'constants/INSURANCE_STATUSES';
import {
  fallBackTableValue,
  formatDate,
  getColumn,
  getSort,
  prettifyNumber,
  sortAlphabetically,
} from 'helper';

import {
  LOAN_STATUS_ASSIGNED,
  LOAN_STATUSES,
  LOAN_STATUSES_COLORS,
  LOAN_STATUSES_NAMES,
} from 'models/clients/constants';

import {
  Popover,
  Tooltip,
} from 'antd';
import { CopyIconButton } from 'components/CopyIconButton/CopyIconButton';
import { CellId } from 'components/DefaultTable/CellId/CellId';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';

import { parseAssigneeDescription } from './helpers';

const CopyButton = styled(CopyIconButton)``;

const ContractNumberCell = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;

  @media(min-width: ${breakpoints.lg}) {
    ${CopyButton} {
      opacity: 0;
    }

    :hover {
      ${CopyButton} {
        opacity: 1;
      }
    }
  }
`;

const ContractNumberRow = styled.div`
  display: flex;
  color: ${palette.textPrimaryColor};
`;

const ContractNumberIdRow = styled.div`
  color: ${palette.textSecondaryColor};
`;

export const COLUMNS = ({ assignees, contractConditions, getAdditionalInfo, productNames }) => _.map([
  {
    key   : `contractNumber`,
    title : `Номер договора + ID`,
    render: (v, record) => (
      <ContractNumberCell>
        <ContractNumberRow>
          <Tooltip title='Кликните дважды, чтобы загрузить доп. инфо'>
            <div onDoubleClick={() => getAdditionalInfo(record.id)}>
              {record.contractNumber}
            </div>
          </Tooltip>
          <CopyButton valueToCopy={record.contractNumber} />
        </ContractNumberRow>
        <ContractNumberIdRow>
          <CellId value={record.id} />
        </ContractNumberIdRow>
      </ContractNumberCell>
    ),
  },
  {
    key   : `loanStatusId`,
    render: (loanStatusId, record) => {
      let color = LOAN_STATUSES_COLORS[loanStatusId] || `default`;

      if (record?.assignmentDtm) {
        const assigneeDescription = _.find(assignees, { id: record?.assigneeId })?.description;
        color = `yellow`;
        return (
          <>
            <Popover
              content={parseAssigneeDescription(assigneeDescription)}
              overlayStyle={{ maxWidth: `400px` }}
              placement='topLeft'
            >
              <DefaultTag $isPointer color={color}>
                Продан
              </DefaultTag>
            </Popover>
            <CopyIconButton as='span' title='Копировать описание' valueToCopy={assigneeDescription} />
          </>
        );
      }

      return (
        <DefaultTag color={color}>
          {_.upperFirst(record?.loanStatusName)}
        </DefaultTag>
      );
    },
    title  : `Статус`,
    filters: _.map(_.concat(_.values(LOAN_STATUSES), LOAN_STATUS_ASSIGNED), loanStatusId => (
      {
        text: (
          <DefaultTag color={LOAN_STATUSES_COLORS[loanStatusId] || `default`}>
            {_.upperFirst(LOAN_STATUSES_NAMES[loanStatusId])}
          </DefaultTag>
        ),
        value: loanStatusId,
      }
    )),
    filterMultiple: true,
    onFilter      : (value, record) => {
      // продано
      if (value === LOAN_STATUS_ASSIGNED) {
        return record?.loanStatusId === LOAN_STATUSES.ACTIVE && record?.assignmentDtm;
      }
      // активный
      if (value === LOAN_STATUSES.ACTIVE) {
        return record?.loanStatusId === LOAN_STATUSES.ACTIVE && !record?.assignmentDtm;
      }
      // остальные статусы
      return value === record?.loanStatusId;
    },
  },
  {
    key    : `productName`,
    title  : `Продукт`,
    render : fallBackTableValue(`productId`),
    sorter : getSort(`productName`, sortAlphabetically),
    filters: _.map(productNames, productName => ({
      text : productName,
      value: productName,
    })),
    filterMultiple: true,
    onFilter      : (value, record) => value === record?.productName,
  },
  {
    dataIndex: [`details`, `loanAmount`],
    key      : `loanAmount`,
    render   : prettifyNumber,
    title    : `Сумма`,
    width    : 100,
    align    : `right`,
  },
  {
    dataIndex: [`details`, `interestRateStr`],
    key      : `interestRateStr`,
    title    : `Ставка`,
    width    : 80,
    align    : `right`,
  },
  {
    dataIndex: [`details`, `initialLoanTerm`],
    key      : `initialLoanTerm`,
    title    : `Срок`,
    width    : 80,
    align    : `right`,
    render   : initialLoanTerm => <>{initialLoanTerm ? `${initialLoanTerm} мес` : ``} </>,
  },
  {
    dataIndex: [`insurance`, `insuranceStatusId`],
    key      : `insuranceStatusId`,
    title    : `Страховка`,
    render   : insuranceStatusId => (
      INSURANCE_STATUSES_NAMES[insuranceStatusId]
        ? (
          <DefaultTag color={INSURANCE_STATUSES_COLORS[insuranceStatusId] || `default`}>
            {_.upperFirst(INSURANCE_STATUSES_NAMES[insuranceStatusId]) || `-`}
          </DefaultTag>
        ) : `-`
    ),
    sorter: getSort(`insuranceStatusId`),
    align : `center`,
    width : 100,
  },
  {
    key   : `contractConditionId`,
    title : `Состояние договора`,
    render: id => {
      const contractConditionName = _.find(contractConditions, { id })?.name;
      return (
        contractConditionName ? (
          <DefaultTag color='default'>
            {contractConditionName}
          </DefaultTag>
        ) : `-`
      );
    },
    width: 140,
  },
  {
    defaultSortOrder: `descend`,
    key             : `closeSource`,
    title           : `Источник закрытия`,
    render          : text => text || `-`,
    sorter          : getSort(`closeSource`),
    width           : 100,
  },
  {
    defaultSortOrder: `descend`,
    key             : `creditStartDtm`,
    title           : `Начало кредита`,
    render          : text => formatDate(text, `dd.MM.yyyy`),
    sorter          : getSort(`creditStartDtm`),
    width           : 140,
  },
  {
    defaultSortOrder: `descend`,
    dataIndex       : [`details`, `creditEndDtm`],
    key             : `creditEndDtm`,
    title           : `Конец кредита`,
    render          : (creditEndDtm, record) => formatDate(creditEndDtm || record?.creditEndDtm, `dd.MM.yyyy`),
    sorter          : getSort(`creditEndDtm`),
    width           : 140,
  },
], getColumn);

export const DATE_FORMAT = `DD.MM.YYYY`;
