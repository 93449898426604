import React from 'react';
import { Link } from 'dva/router';
import styled from 'styled-components';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import logo from 'assets/img/logo-dark.svg';

// Стили
const Main = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 16px;
`;

const Logo = styled.img`
  width: 300px;
`;

const Title = styled.h1`
  margin: 0;
  color: ${(props: any) => props.theme.baseAccentColor};
  font-size: 203px;
  font-weight: 600;
  line-height: 203px;
`;

const Text = styled.p<{ bold?: boolean; link?: boolean }>`
  margin: 0 0 14px;
  color: ${(props: { link?: boolean, theme: any }) => props.link ? props.theme.lighterBlueColor : props.theme.grayColor};
  font-size: 14px;
  text-align: center;
  font-weight: ${(props: { bold?: boolean }) => props.bold ? 600 : 'normal'};
  text-decoration: ${(props: { link?: boolean }) => props.link ? 'none' : 'initial'};
`;

const StyledLink = styled(Link)`
  color: ${(props: any) => props.theme.lighterBlueColor};
  text-decoration: none;
`;

const PageNotFound = () => {
  return (
    <ClientTab>
      <Main>
        <Logo alt='logo' src={logo} />
        <Title>404</Title>
        <Text bold>Возможно Вы потерялись...</Text>
        <Text>Cейчас ведутся работы или страница была удалена.</Text>
        <StyledLink to='/'>
          Вернуться на главную
        </StyledLink>
      </Main>
    </ClientTab>
  );
};

export default PageNotFound;
