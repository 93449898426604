import _ from 'lodash';
import moment from 'moment';

import BOOL_COLUMN from 'constants/BOOL_COLUMN';
import BOOL_FIELD from 'constants/BOOL_FIELD';
import { REQUIRED } from 'constants/FORM_VALIDATION_RULES';
import {
  formatDate,
  getColumn as getColumnDefault,
  getField,
  getSort,
} from 'helper';

import { ROLE_NAMES } from 'models/roles/constants';

import { DatePicker } from 'antd';

const getColumn = props => getColumnDefault({ sorter: getSort(props.key), ...props });

export const DISABLED_TOOLTIP = `Доступно для роли ${ROLE_NAMES.ACTION_EDITOR}`;

export const COLUMNS = () => _.map([
  {
    key  : `id`,
    align: `center`,
    width: 50,
  },
  {
    key  : `name`,
    title: `Название`,
    width: 200,
  },
  {
    key  : `description`,
    title: `Описание`,
    width: 250,
  },
  {
    key   : `startDtm`,
    render: v => formatDate(v),
    title : `Дата начала`,
    width : 100,
  },
  {
    key   : `endDtm`,
    render: v => formatDate(v),
    title : `Дата окончания`,
    width : 100,
  },
  BOOL_COLUMN(`technicalActionFlg`, `Техническая`),
  {
    key  : `additionalInfo`,
    title: `Дополнительная информация`,
    width: 250,
  },
], getColumn);

export const FORM_FIELDS = _.map([
  {
    key         : `name`,
    title       : `Описание`,
    initialValue: ``,
    required    : true,
    rules       : [REQUIRED],
  },
  {
    key         : `description`,
    title       : `Описание`,
    initialValue: ``,
    required    : true,
    rules       : [REQUIRED],
  },
  {
    key         : `dates`,
    title       : `Даты`,
    Component   : DatePicker.RangePicker,
    format      : `DD-MM-YYYY`,
    initialValue: ``,
    placeholder : [`Дата начала`, `Дата окончания`],
    required    : true,
    rules       : [
      REQUIRED,
      {
        validator: (pass, value) => (moment.utc(_.head(value)).isSameOrAfter(moment.utc(), `day`)
          ? Promise.resolve()
          : Promise.reject(new Error(`Дата не может быть меньше текущей даты`))),
      },
    ],
  },
  {
    key         : `additionalInfo`,
    title       : `Дополнительная информация`,
    initialValue: ``,
  },
  BOOL_FIELD(`technicalActionFlg`, `Техническая акция`, {
    initialValue: false,
    required    : true,
    rules       : [REQUIRED],
  }),
], getField);

const ACCEPTED_FILES = [`application/vnd.ms-excel`, `.xls`, `.xlsx`, `.csv`];

export const ACCEPTED_FILES_STRING = _.join(ACCEPTED_FILES);
