export default {
  BACKSPACE: 8,
  CAPSLOCK : 20,
  DOWN     : 40,
  ENTER    : 13,
  ESCAPE   : 27,
  LEFT     : 37,
  RIGHT    : 39,
  UP       : 38,
};
