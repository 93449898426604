import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { validateEmail } from 'helper';

import { PlusOutlined } from '@ant-design/icons';
import {
  Input,
  Tag,
} from 'antd';

const Contents = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;

  >* {
    margin-bottom: 8px;
  }
`;

const AddTag = styled.div`
  border-style: dashed;
`;

const EmailTag = styled.div`
  user-select: none;
`;

const ErrorMessage = styled.p`
    color: ${palette.alert500Color};
    margin-right: 8px;
`;

const StyledInput = styled(Input)`
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
  margin-bottom: 8px !important;
`;

export const Emails = ({ onSetEmails }) => {
  const [tags, setTags] = useState([]);
  const [isErrorVisible, setError] = useState(false);
  const [isInputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState(``);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState(``);
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  useEffect(() => {
    if (isInputVisible) {
      inputRef.current?.focus();
    }
  }, [isInputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = removedTag => {
    const newTags = _.without(tags, removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setError(false);
    setInputVisible(true);
  };

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (validateEmail(inputValue)) {
      if (inputValue && !_.includes(tags, inputValue)) {
        setTags([...tags, inputValue]);
        onSetEmails([...tags, inputValue]);
      }
    } else setError(true);

    setInputVisible(false);
    setInputValue(``);
  };

  const handleEditInputChange = e => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue(``);
  };

  return (
    <Contents>
      {_.map(tags, (tag, index) => {
        if (editInputIndex === index) {
          return (
            <StyledInput
              key={tag}
              onBlur={handleEditInputConfirm}
              onChange={handleEditInputChange}
              onPressEnter={handleEditInputConfirm}
              ref={editInputRef}
              size='small'
              value={editInputValue}
            />
          );
        }
        const tagElem = (
          <EmailTag>
            <Tag
              closable
              key={tag}
              onClose={() => handleClose(tag)}
            >
              <span
                onDoubleClick={e => {
                  if (index) {
                    setEditInputIndex(index);
                    setEditInputValue(tag);
                    e.preventDefault();
                  }
                }}
              >
                {tag}
              </span>
            </Tag>
          </EmailTag>
        );
        return (tagElem);
      })}
      {isErrorVisible && (<ErrorMessage>Введите корректный email</ErrorMessage>)}
      {isInputVisible && (
        <StyledInput
          onBlur={handleInputConfirm}
          onChange={handleInputChange}
          onPressEnter={handleInputConfirm}
          ref={inputRef}
          size='small'
          type='text'
          value={inputValue}
        />
      )}
      {!isInputVisible && (
        <AddTag>
          <Tag onClick={showInput}><PlusOutlined /> Новый email</Tag>
        </AddTag>
      )}
    </Contents>
  );
};
