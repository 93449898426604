import React from 'react';
import _ from 'lodash';
import { Link } from 'dva/router';

import {
  formatDate,
  getColumn,
  prettifyAmount,
} from 'helper';

import { SearchOutlined } from '@ant-design/icons';
import { CellId } from 'components/DefaultTable/CellId/CellId';
import StringFilterDropDown from 'components/StringFilterDropDown';
import { getDateRangeFilter } from 'components/Table/getDateRangeFilter';

const getSortOrder = (sorter, column) => (sorter?.columnKey === column ? sorter.order : undefined);

const getStringFilter = (key, tableState) => ({
  filterDropdown: ({ confirm, selectedKeys, setSelectedKeys, visible }) => (
    <StringFilterDropDown
      confirm={confirm}
      isVisible={visible}
      selectedKeys={selectedKeys}
      setSelectedKeys={setSelectedKeys}
    />
  ),
  filtered     : !_.isEmpty(tableState?.filters?.[key]),
  filteredValue: tableState?.filters?.[key],
  filterIcon   : filtered => (
    <SearchOutlined style={{ color: filtered ? `#1890ff` : undefined }} />
  ),
});

export const getColumns = ({ dictionaries, tableState }) => _.map([
  {
    title   : `Клиентские данные`,
    key     : `clientInfo`,
    children: [
      {
        key  : `clientFullName`,
        title: `ФИО`,
        width: 200,
      },
      {
        key  : `inn`,
        title: `ИНН`,
        width: 160,
      },
      {
        key           : `productId`,
        title         : `Продукт`,
        render        : id => _.find(dictionaries?.product, { id })?.name,
        width         : 200,
        filtered      : !_.isEmpty(tableState?.filters?.productId),
        filteredValue : tableState?.filters?.productId,
        filterMultiple: true,
        filters       : _.map(dictionaries.product, value => ({
          text : value.name,
          value: value.id,
        })),
      },
      {
        key  : `contractNumber`,
        title: `Номер договора`,
        width: 150,
      },
      {
        key   : `loanCreateDtm`,
        title : `Дата договора`,
        render: v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`loanCreateDtm`, tableState),
        width : 150,
      },
    ],
  },
  {
    title   : `Федресурс`,
    key     : `fedresurs`,
    children: [
      {
        key  : `lastBankruptIdType`,
        title: `Тип последнего события Федресурс`,
        width: 250,
        ...getStringFilter(`lastBankruptIdType`, tableState),
      },
      {
        key      : `lastBankruptIdDate`,
        title    : `Дата последнего события Федресурс`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`lastBankruptIdDate`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `lastBankruptIdDate`),
        width    : 260,
      },
    ],
  },
  {
    title   : `Основная информация`,
    key     : `mainInfo`,
    children: [
      {
        key      : `caseNumber`,
        title    : `Номер дела`,
        ...getStringFilter(`caseNumber`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `caseNumber`),
        width    : 180,
      },
      {
        key      : `bankruptsyValidation`,
        title    : `Судебное заседание по проверке обоснованности заявления о банкротстве`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `bankruptsyValidation`),
        width    : 460,
      },
      {
        key      : `bankruptDecisionDtm`,
        title    : `Дата решения о признании банкротом`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`bankruptDecisionDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `bankruptDecisionDtm`),
        width    : 270,
      },
      {
        key      : `propertyRestructuringDtm`,
        title    : `Реструктуризация имущества`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`propertyRestructuringDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `propertyRestructuringDtm`),
        width    : 230,
      },
      {
        key      : `propertySaleDtm`,
        title    : `Реализация имущества`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`propertySaleDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `propertySaleDtm`),
        width    : 230,
      },
      {
        key           : `arbitrationCourtId`,
        title         : `Арбитражный суд`,
        render        : id => _.find(dictionaries?.arbitrationCourt, { id })?.name,
        width         : 200,
        filtered      : !_.isEmpty(tableState?.filters?.arbitrationCourtId),
        filteredValue : tableState?.filters?.arbitrationCourtId,
        filterMultiple: true,
        filters       : _.map(dictionaries.arbitrationCourt, value => ({
          text : value.name,
          value: value.id,
        })),
      },
      {
        key   : `arbitrationCourtId`,
        title : `Адрес арбитражного суда`,
        render: id => _.find(dictionaries?.arbitrationCourt, { id })?.address,
        width : 200,
      },
      {
        key      : `judge`,
        title    : `Судья`,
        ...getStringFilter(`judge`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `judge`),
        width    : 200,
      },
      {
        key      : `financialManager`,
        title    : `Финансовый управляющий`,
        ...getStringFilter(`financialManager`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `financialManager`),
        width    : 220,
      },
      {
        key      : `financialManagerAddress`,
        title    : `Адрес ФУ`,
        ...getStringFilter(`financialManagerAddress`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `financialManagerAddress`),
        width    : 200,
      },
      {
        key      : `financialManagerEmail`,
        title    : `Email ФУ`,
        ...getStringFilter(`financialManagerEmail`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `financialManagerEmail`),
        width    : 200,
      },
      {
        key  : `contacts`,
        title: `Контактные данные`,
        width: 200,
      },
      {
        key      : `szDtm`,
        title    : `Дата СЗ включения в реестр`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`szDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `szDtm`),
        width    : 230,
      },
      {
        key      : `registryInclusionDtm`,
        title    : `Дата включения в реестр`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`registryInclusionDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `registryInclusionDtm`),
        width    : 230,
      },
      {
        key      : `registryClaimAmount`,
        title    : `Сумма требований в реестре`,
        render   : prettifyAmount,
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `registryClaimAmount`),
        width    : 200,
      },
      {
        key  : `mainInformationComment`,
        title: `Комментарий`,
        width: 250,
      },
      {
        key      : `receivedAmount`,
        title    : `Полученная сумма`,
        render   : prettifyAmount,
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `receivedAmount`),
        width    : 150,
      },
      {
        key      : `bankruptEndDtm`,
        title    : `Дата завершения процедуры банкротства`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`bankruptEndDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `bankruptEndDtm`),
        width    : 290,
      },
    ],
  },
  {
    title   : `Наличие залога`,
    key     : `pledge`,
    children: [
      {
        key           : `pledgeStatusId`,
        title         : `Наличие залога`,
        render        : id => _.find(dictionaries?.pledgeStatus, { id })?.status,
        width         : 200,
        filtered      : !_.isEmpty(tableState?.filters?.pledgeStatusId),
        filteredValue : tableState?.filters?.pledgeStatusId,
        filterMultiple: true,
        filters       : _.map(dictionaries.pledgeStatus, value => ({
          text : value.status,
          value: value.id,
        })),
      },
    ],
  },
  {
    title   : `Торги`,
    key     : `auction`,
    children: [
      {
        key           : `auctionStatusId`,
        title         : `Статус торгов`,
        render        : id => _.find(dictionaries?.auctionStatus, { id })?.status,
        width         : 200,
        filtered      : !_.isEmpty(tableState?.filters?.auctionStatusId),
        filteredValue : tableState?.filters?.auctionStatusId,
        filterMultiple: true,
        filters       : _.map(dictionaries.auctionStatus, value => ({
          text : value.status,
          value: value.id,
        })),
      },
      {
        key      : `carEstimateCost`,
        title    : `Оценка ТС`,
        render   : prettifyAmount,
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `carEstimateCost`),
        width    : 150,
      },
      {
        key      : `auctionFirstStageDtm`,
        title    : `Первый этап торгов`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`auctionFirstStageDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `auctionFirstStageDtm`),
        width    : 190,
      },
      {
        key      : `auctionSecondStageDtm`,
        title    : `Второй этап торгов`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`auctionSecondStageDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `auctionSecondStageDtm`),
        width    : 190,
      },
      {
        key      : `auctionThirdStageDtm`,
        title    : `Третий этап торгов`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`auctionThirdStageDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `auctionThirdStageDtm`),
        width    : 190,
      },
      {
        key      : `balanceCarAcceptingDtm`,
        title    : `Принятие ТС на баланс`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`balanceCarAcceptingDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `balanceCarAcceptingDtm`),
        width    : 190,
      },
      {
        key      : `balanceCarCost`,
        title    : `Балансовая стоимость ТС`,
        render   : prettifyAmount,
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `balanceCarCost`),
        width    : 180,
      },
    ],
  },
  {
    title   : `Дополнительная информация`,
    key     : `additionalInfo`,
    children: [
      {
        key      : `mfoInDtm`,
        title    : `Дата поступления в МФО`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`mfoInDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `mfoInDtm`),
        width    : 200,
      },
      {
        key      : `rtkDtm`,
        title    : `Дата РТК`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`rtkDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `rtkDtm`),
        width    : 120,
      },
      {
        key      : `commersantDtm`,
        title    : `Дата Коммерсант`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`commersantDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `commersantDtm`),
        width    : 150,
      },
      {
        key      : `courtTaktikumRtkDtm`,
        title    : `Дата отправки в суд или в Тактикум`,
        render   : v => formatDate(v, `dd.MM.yyyy`),
        ...getDateRangeFilter(`courtTaktikumRtkDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `courtTaktikumRtkDtm`),
        width    : 300,
      },
      {
        key  : `comment`,
        title: `Комментарий`,
        width: 250,
      },
    ],
  },
  {
    title   : `Техническая информация`,
    key     : `technicalInfo`,
    children: [
      {
        key      : `operatorName`,
        title    : `ФИО автора`,
        ...getStringFilter(`operatorName`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `operatorName`),
        width    : 200,
      },
      {
        key  : `id`,
        title: `Внутренний номер банкрота`,
        width: 200,
      },
      {
        key      : `bankruptCreateDtm`,
        title    : `Дата создания`,
        render   : v => formatDate(v),
        ...getDateRangeFilter(`bankruptCreateDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `bankruptCreateDtm`),
        width    : 140,
      },
      {
        key   : `personId`,
        title : `Клиент`,
        width : 100,
        render: personId => (personId ? (
          <Link to={`/client/person/${personId}/info`}>
            <CellId value={personId} />
          </Link>
        ) : `-`),
        ...getStringFilter(`personId`, tableState),
      },
      {
        key   : `loanId`,
        title : `Номер займа в 1С`,
        width : 140,
        render: (loanId, { personId }) => (personId ? (
          <Link to={`/client/person/${personId}/loan`}>
            <CellId value={loanId} />
          </Link>
        ) : <CellId value={loanId} />),
        ...getStringFilter(`loanId`, tableState),
      },
      {
        key      : `updateOperatorName`,
        title    : `ФИО автора последнего обновления`,
        ...getStringFilter(`updateOperatorName`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `updateOperatorName`),
        width    : 280,
      },
      {
        key      : `updateDtm`,
        title    : `Дата последнего обновления`,
        render   : v => formatDate(v),
        ...getDateRangeFilter(`updateDtm`, tableState),
        sorter   : true,
        sortOrder: getSortOrder(tableState.sorter, `updateDtm`),
        width    : 220,
      },
    ],
  },
  // {
  //   key      : `parkingFlg`,
  //   title    : `Авто на стоянке`,
  //   sorter   : true,
  //   sortOrder: getSortOrder(tableState.sorter, `parkingFlg`),
  //   width    : 120,
  // },
], getColumn);
