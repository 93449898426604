import React, {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { darken } from 'polished';
import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import useMobileContext from 'hooks/useMobileContext';

import { Table } from 'antd';
import {ColumnProps} from "antd/lib/table";

const StyledTable = styled(Table)`
  padding-left: 0;
  padding-right: 0;

  .ant-table {
    color: ${palette.black80Color};
    background-color: ${palette.backgroundColor};
  }

  .ant-table .ant-table-thead .ant-table-cell {
    background: ${getLightStyleOr(
    palette.black10Color,
    p => darken(0.01, palette.black10Color(p)),
  )};
  }

  .ant-table-summary {
    background-color: ${palette.black10Color};
    border-top: 1px solid ${palette.primary500Color};

    tr > td {
      border-bottom-color: ${palette.primary500Color};
    }

    .ant-table-cell-fix-right {
      background-color: ${palette.black10Color};
    }
  }

  .ant-table .ant-table-cell {
    background: ${getLightStyleOr(palette.whiteColor, palette.backgroundColor)};
  }

  .ant-table td.ant-table-column-sort {
    background: ${getLightStyleOr(palette.black5Color, palette.black10Color)};
  }

  .ant-table-tbody > tr > td {
    border-color: ${getLightStyleOr(palette.black5Color, palette.black10Color)};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${getLightStyleOr(palette.black5Color, palette.black10Color)};
  }

  .ant-table-thead > tr > th {
    border-color: ${getLightStyleOr(palette.black5Color, palette.black10Color)};
    font-size: 12px;
    font-weight: 400;
    background: ${getLightStyleOr(palette.black10Color, palette.black10Color)};
    color: ${getLightStyleOr(palette.black80Color, palette.whiteColor)};
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: ${palette.backgroundColor};
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: ${getLightStyleOr(palette.black5Color, palette.black10Color)};
  }

  .ant-table-filter-trigger {
    :hover {
      background: ${getLightStyleOr(palette.black5Color, palette.black10Color)};
      color: ${getLightStyleOr(palette.black80Color, palette.whiteColor)};
    }
  }

  .ant-table-row-expand-icon {
    background: ${palette.backgroundColor};
    color: ${palette.textPrimaryColor};
    border-color: ${palette.borderAccentColor};

    :hover {
      color: ${palette.primary500Color};
      border-color: ${palette.primary500Color};
    }
  }

  div.ant-table-summary {
    box-shadow: ${getLightStyleOr(
    () => `0 -1px 0 #f5f7f9`,
    () => `0 -1px 0 #1f1f1f`,
  )
};
  }
`;

interface IComponentProps {
  className?:string;
  columns: ColumnProps<any>;
  dataSource: any[];
  expandable?: any;
  loading?: boolean;
  onChange?: any;
  onRow?: any;
  pagination?: any;
  rowClassName?: string;
  rowKey?: string;
  rowSelection?: any;
  scroll?: any;
  scrollableHeader?:boolean;
  size?: string;
  summary?: any;
}

export const DefaultTable: React.FC<IComponentProps> = ({
  className,
  columns,
  dataSource,
  expandable,
  loading,
  onChange,
  onRow,
  pagination,
  rowKey,
  rowClassName,
  scroll,
  scrollableHeader,
  rowSelection,
  size = `middle`,
  summary,
}) => {
  const { mobile } = useMobileContext();
  const [scrollX, setScrollX] = useState<number>(0);
  const antTableBody = !mobile && scrollableHeader
    ? _.first(document.getElementsByClassName(`ant-table-body`))
    : null;
  const antTableMeasureRow = !mobile && scrollableHeader
    ? _.first(document.getElementsByClassName(`ant-table-measure-row`))
    : null;
  const handleHeaderWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (!antTableBody) return;
    const fullWidth = antTableMeasureRow?.clientWidth;
    const currentWidth = antTableBody.clientWidth;
    const scrollMaxDelta = (fullWidth ?? 0) - currentWidth;
    if (e.deltaY !== 0) {
      e.preventDefault();
      setScrollX(prevScrollX => {
        if (prevScrollX < 0) return 0;
        if (prevScrollX >= scrollMaxDelta && e.deltaY > 0) return scrollMaxDelta;
        return prevScrollX + e.deltaY;
      });
    }
  };
  useEffect(() => {
    if (antTableBody) antTableBody.scrollLeft = scrollX;
  }, [scrollableHeader, scrollX]);

  return (
    // @ts-ignore
    <StyledTable
      className={className}
      columns={columns}
      dataSource={dataSource}
      expandable={expandable}
      loading={loading}
      onChange={onChange}
      onHeaderRow={() => ({
        onMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => {
          // @ts-ignore
          e.currentTarget.addEventListener(`wheel`, handleHeaderWheel, { passive: false });
        },
        onMouseLeave: (e: React.MouseEvent<HTMLDivElement>) => {
          // @ts-ignore
          e.currentTarget.removeEventListener(`wheel`, handleHeaderWheel);
        },
      })}
      onRow={onRow}
      pagination={pagination}
      rowClassName={rowClassName}
      rowKey={rowKey}
      rowSelection={rowSelection}
      scroll={scrollableHeader ? { ...(scroll || {}), x: scrollX } : scroll}
      size={mobile ? `small` : size}
      summary={summary}
    />
  );
};
