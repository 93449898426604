import { createAction } from 'redux-actions';

export const model = `eventlogs`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const RELOAD_LAST_SEARCH = `RELOAD_LAST_SEARCH`;
export const RESET = `RESET`;
export const SEARCH = `SEARCH`;
export const SET = `SET`;
export const SET_LAST_SEARCH = `SET_LAST_SEARCH`;
export const SET_LOADED_ITEMS = `SET_LOADED_ITEMS`;
export const SET_LOADING = `SET_LOADING`;

export const reloadLastSearch = ca(RELOAD_LAST_SEARCH);
export const reset = ca(RESET);
export const search = ca(SEARCH);
export const set = ca(SET);
export const setLoading = ca(SET_LOADING);
