import _ from 'lodash';
import { routerRedux } from 'dva/router';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { checkDictionary } from 'models/dictionaries/helpers';
import { getCurrent as getCurrentUserSelector } from 'models/user/selectors';

import { notification } from 'antd';

import {
  APPROVE,
  ASSIGN,
  CREATE_GUARANTEE_LETTER,
  DECLINE,
  GET,
  GET_SINGLE,
  model as namespace,
  REASSIGN,
  RESET,
  SEND_BLURRED_PHOTOS,
  SET,
  SET_DECISION,
  SET_LOADING,
  SET_OPERATOR,
  SET_RESULTS,
  SET_STATUS,
  UPDATE_RESULTS,
  UPDATE_USER_DATA,
  VERIFY_APPLICATION_INCOME,
} from './actions';
import * as api from './api';
import {
  getItems as getItemsSelector,
  getItemsByIds as getItemsByIdsSelector,
  getRequestParams as getRequestParamsSelector,
  getResults as getVerificationResultsSelector,
} from './selectors';

const initialState = {
  isLoaded            : false,
  isLoading           : false,
  items               : [],
  searchString        : ``,
  total               : 0,
  userId              : null,
  verificationStatusId: null,
};

export default {
  namespace,
  state        : initialState,
  subscriptions: {},
  effects      : {
    *[APPROVE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { callback, details, id } = action.payload;
      const defaultError = `При одобрении задания на верификацию с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(`${defaultError}: не указан идентификатор задания`);

        parseResponse({
          defaultError,
          response: yield call(api.approve, { details, id }),
        });

        yield put({ type: GET_SINGLE, payload: { id } });

        if (_.isFunction(callback)) callback();
        notification.success({ message: `Успешно одобрено` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[ASSIGN](action, { all, put, select, take }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids } = action.payload;
      const defaultError = `При назначении заданий на верификацию произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(`${defaultError}: не указаны идентификаторы заданий для назначения`);
        const currentUser = yield select(getCurrentUserSelector);
        const verificationStatuses = yield checkDictionary(`verificationStatus`, { put, select, take });
        const verificationStatusId = _.get(_.find(verificationStatuses, { name: `inprogress` }), `id`);

        yield all(
          _.flatten(
            _.map(ids, id => ([
              put({
                type   : SET_OPERATOR,
                payload: {
                  id,
                  omitNotification: true,
                  userId          : currentUser.id,
                },
              }),
              put({
                type   : SET_STATUS,
                payload: {
                  id,
                  omitNotification: true,
                  verificationStatusId,
                },
              }),
            ])),
          ),
        );

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        notification.success({ message: `Успешно назначено` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE_GUARANTEE_LETTER](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { partnerId } = action.payload;
      const defaultError = `При формировании гарантийного письма для партнера с partnerId ${partnerId} произошла ошибка`; // eslint-disable-line max-len

      try {
        if (!partnerId) throw new Error(`${defaultError}: не указан partnerId`);

        const status = parseResponse({
          dataPath: `data.status`,
          defaultError,
          response: yield call(api.createGuaranteeLetter, partnerId),
        });

        if (status !== 200) throw new Error(`${defaultError}: ошибка при взаимодействии с API`);

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        notification.success({
          message: `Гарантийное письмо для партнера с partnerId ${partnerId} успешно сформировано`,
        });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[DECLINE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id } = action.payload;
      const defaultError = `При отказе по заданию на верификацию с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(`${defaultError}: не указан идентификатор задания`);

        parseResponse({
          defaultError,
          response: yield call(api.decline, id),
        });

        yield put({ type: GET_SINGLE, payload: { id } });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        notification.success({ message: `Успешно отклонено` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      yield put({ type: SET, payload: { isItemsLoading: true } });
      const defaultError = `При загрузке заданий на верификацию произошла ошибка`;

      try {
        const params = yield select(state => getRequestParamsSelector(state, !!action.payload?.isSale));
        if (_.trim(params.fullName) && _.size(_.split(params.fullName, ` `)) < 2) {
          notification.warn({
            message  : `Укажите имя, поиск только по фамилии сильно нагружает сервисы`,
            placement: `bottomRight`,
          });
          return;
        }

        if (_.trim(params.fullName)) {
          const [surname, name, patronymic] = _.split(_.trim(params.fullName), ` `);
          const searchObj = { surname, name, patronymic };

          const validateName = _.filter(
            [
              { key: `name`, label: `имени` },
              { key: `surname`, label: `фамилии` },
            ], ({ key, label }) => {
              if (_.has(searchObj, key) && _.size(searchObj[key]) < 2) {
                notification.warn({
                  message  : `В ${label} менее двух символов, добавьте, чтобы выполнить поиск`,
                  placement: `bottomRight`,
                });
                return true;
              }
            },
          );
          if (!_.isEmpty(validateName)) return;
        }

        const { data: items, total } = parseResponse({
          defaultError,
          response: yield call(api.get, null, null, params), // limit, offset, params
        });
        yield put({ type: SET, payload: { items, isLoaded: true, total } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
        yield put({ type: SET, payload: { isItemsLoading: false } });
      }
    },

    *[GET_SINGLE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id } = action.payload;
      const defaultError = `При загрузке задания на верификацию с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(defaultError);

        const item = parseResponse({
          defaultError,
          response: yield call(api.getSingle, id),
        });

        const webDataVector = parseResponse({
          defaultError,
          response: yield call(api.getWebData, item?.applicationId, {
            key: [
              `passportSelfyIsMoving`,
              `passportSelfyCameraNames`,
              `passportSelfyPermission`,
              `carRightFrontIsMoving`,
              `carRightFrontCameraNames`,
              `carRightFrontPermission`,
              `carVINIsMoving`,
              `carVINCameraNames`,
              `carVINPermission`,
              `referrerType`,
              `referrerUrl`,
            ],
          }),
        });

        const currentItems = yield select(getItemsSelector) || [];
        yield put({
          type   : SET,
          payload: {
            items: _.find(currentItems, { id })
              ? _.map(currentItems, currentItem => (currentItem.id === id
                ? { ...currentItem, ...item, webDataVector }
                : currentItem))
              : _.concat(currentItems, [item]),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REASSIGN](action, { all, put, select, take }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids } = action.payload;
      const defaultError = `При снятии заданий на верификацию с оператора произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(`${defaultError}: не указаны идентификаторы заданий для снятия`);
        const items = yield select(state => getItemsByIdsSelector(state, ids));
        const verificationStatuses = yield checkDictionary(
          `verificationStatus`,
          { put, select, take },
        );
        const verificationStatusId = _.get(_.find(verificationStatuses, { name: `queue` }), `id`);

        const idsToChangeStatus = _.map(_.filter(items, ({ verificationStatus }) => (
          _.get(verificationStatus, `name`) === `inprogress`
        )), `id`);

        yield all(
          _.flatten(
            _.compact(_.map(ids, id => ([
              put({
                type   : SET_OPERATOR,
                payload: {
                  id,
                  omitNotification: true,
                  userId          : null,
                },
              }),
              _.includes(idsToChangeStatus, id)
                ? put({
                  type   : SET_STATUS,
                  payload: {
                    id,
                    omitNotification: true,
                    verificationStatusId,
                  },
                })
                : null,
            ]))),
          ),
        );

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        notification.success({ message: `Успешно снято с сотрудника` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SEND_BLURRED_PHOTOS](action, { call, put, select, take }) {
      yield put({ type: SET_LOADING, payload: true });
      const { documentTypeIds, id } = action.payload;
      const defaultError = `При отправке фото на переснятие по заданию на верификацию с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(`${defaultError}: не указан идентификатор задания`);
        if (_.isEmpty(documentTypeIds)) {
          throw new Error(`${defaultError}: не указаны фотографии для отправки на переснятие`);
        }

        parseResponse({
          defaultError,
          response: yield call(api.sendBlurredPhotos, id, documentTypeIds),
        });

        const verificationStatuses = yield checkDictionary(
          `verificationStatus`,
          { put, select, take },
        );
        const verificationStatusId = _.get(_.find(verificationStatuses, { name: `photosBlurred` }), `id`);
        yield put({
          type   : SET_STATUS,
          payload: {
            id,
            omitNotification: true,
            verificationStatusId,
          },
        });
        yield put({ type: GET });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        notification.success({ message: `Успешно отправлено на переснятие` });
        yield put(routerRedux.push(`/verification`));
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_DECISION](action, { call, put, select, take }) {
      yield put({ type: SET_LOADING, payload: true });
      const { decision, id } = action.payload;
      const defaultError = `При отправке решения по заданию на верификацию с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(`${defaultError}: не указан идентификатор задания`);
        if (_.isEmpty(decision)) throw new Error(`${defaultError}: не указано решение`);

        parseResponse({
          defaultError,
          response: yield call(api.setDecision, id, decision),
        });

        const verificationStatuses = yield checkDictionary(
          `verificationStatus`,
          { put, select, take },
        );
        const verificationStatusId = _.get(_.find(verificationStatuses, { name: `done` }), `id`);
        yield put({
          type   : SET_STATUS,
          payload: {
            callback        : action.payload.callback,
            id,
            omitNotification: true,
            verificationStatusId,
          },
        });
        yield put({ type: GET });

        notification.success({ message: `Успешно сохранено` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_OPERATOR](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id, omitNotification, operatorId, userId } = action.payload;
      const defaultError = `При назначении задания с id '${id}' оператору произошла ошибка`;

      try {
        if (!id) throw new Error(`${defaultError}: не указан идентификатор задания`);

        const verificationData = parseResponse({
          defaultError,
          response: yield call(api.setOperator, id, { operatorId, userId }),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => (currentItem.id === id
              ? { ...currentItem, operatorId: verificationData.operatorId }
              : currentItem
            )),
          },
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Оператор изменен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SET_STATUS](action, { all, call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id, ids, omitNotification, verificationStatusId } = action.payload;
      const defaultError = `При изменении статуса задания с id '${id}' произошла ошибка`;

      try {
        if (!id && _.isEmpty(ids)) throw new Error(`${defaultError}: не указан идентификатор задания`);
        if (!verificationStatusId) throw new Error(`${defaultError}: не указан идентификатор статуса задания`);

        let data;
        if (id) {
          data = parseResponse({
            defaultError,
            response: yield call(api.setStatus, id, verificationStatusId),
          });
        } else {
          const responses = yield all(_.map(ids, _id => call(api.setStatus, _id, verificationStatusId)));
          data = _.map(responses, response => parseResponse({ defaultError, response }));
        }

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => ((currentItem.id === id || _.includes(ids, currentItem.id))
              ? {
                ...currentItem,
                verificationStatus: _.isArray(data)
                  ? _.get(_.find(data, { id: currentItem.id }), `verificationStatus`)
                  : data.verificationStatus,
              } : currentItem
            )),
          },
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!omitNotification) notification.success({ message: `Статус задания изменен` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE_USER_DATA](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При обновлении данных клиентов произошла ошибка`;

      try {
        parseResponse({
          defaultError,
          response: yield call(api.updateUserData),
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE_RESULTS](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { clear = false, verificationId } = action.payload;
      const defaultError = `При сохранении результатов проверок произошла ошибка`;

      try {
        if (!verificationId) throw new Error(`${defaultError}: не указан идентификатор задания`);
        const results = clear ? {} : yield select(state => getVerificationResultsSelector(state, verificationId));

        const verification = parseResponse({
          defaultError,
          response: yield call(api.setResults, verificationId, results),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => (currentItem.id === verificationId
              ? verification
              : currentItem
            )),
          },
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
        if (!action.payload.omitNotification) {
          notification.success({ message: `Результаты проверок ${clear ? `сброшены` : `сохранены`}` });
        }
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[VERIFY_APPLICATION_INCOME](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { applicationId, callback, hideMessage, verifiedIncome } = action.payload;
      const defaultError = `При сохранении дохода клиента по заявке ${applicationId} произошла ошибка`;

      try {
        if (!applicationId) throw new Error(`Нет номера заявки`);
        if (!_.isNumber(verifiedIncome)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.verifyApplicationIncome, { applicationId, verifiedIncome }),
        });

        if (!hideMessage) notification.success({ message: `Доход успешно сохранён` });
        if (_.isFunction(callback)) callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      if (_.has(payload, `items`)) {
        return {
          ...state,
          ...payload,
          items: _.reduce(payload.items, (result, payloadItem) => {
            const stateItem = _.find(state.items, { id: payloadItem.id });
            if (!stateItem) {
              result.push(payloadItem);
              return result;
            }
            result.push({
              ...stateItem,
              ...payloadItem,
              application: _.isEmpty(payloadItem.application) ? stateItem.application : payloadItem.application,
            });
            return result;
          }, []),
        };
      }
      return {
        ...state,
        ...payload,
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },

    [SET_RESULTS](state, { payload: { results, verificationId } }) {
      return {
        ...state,
        items: _.map(state.items, item => (item.id === verificationId ? { ...item, results } : item)),
      };
    },
  },
};
