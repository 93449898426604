import React from 'react';
import _ from 'lodash';

import { ROLE_NAMES } from 'models/roles/constants';

import { CellDateTime } from 'components/DefaultTable/CellDateTime/CellDateTime';
import {AnyDictionaryInterface, TDictionaryItem} from "models/dictionaries/types";
import {ILoan} from "models/clients/types";
import {IOperator} from "models/operators/types";

export const WRITE_ALLOWED_ROLES = [
  ROLE_NAMES.SUPER_ADMIN,
  ROLE_NAMES.TRIGGER_ADMIN,
  ROLE_NAMES.TRIGGER_WRITE,
];

export const getColumns = (dictionaries: Record<string, (TDictionaryItem | AnyDictionaryInterface)[]>, loans: ILoan[], operators: IOperator[]) => [
  // {
  //   dataIndex: `triggerId`,
  //   key      : `triggerId`,
  //   title    : `ID`,
  // },
  // {
  //   dataIndex: `personId`,
  //   key      : `personId`,
  //   title    : `personId`,
  // },
  {
    dataIndex: `loanId`,
    key      : `loanId`,
    title    : `Номер договора`,
    render   : (id: string) => _.find(loans, { id })?.contractNumber,
  },
  {
    dataIndex: `callDate`,
    key      : `callDate`,
    title    : `Дата записи`,
    render   : (v: number | undefined) => <CellDateTime value={v} />,
  },
  {
    dataIndex: `block`,
    key      : `block`,
    title    : `Блок`,
    render   : (id: any) => _.find(dictionaries.triggerBlock, { id })?.description,
  },
  {
    dataIndex: `sobitieDate`,
    key      : `sobitieDate`,
    title    : `Дата события`,
    render   : (v: number | undefined) => <CellDateTime value={v} />,
  },
  {
    dataIndex: `sobitie`,
    key      : `sobitie`,
    title    : `Событие`,
    render   : (id: any) => _.find(dictionaries.trigger, { id })?.description,
  },
  {
    dataIndex: `description`,
    key      : `description`,
    title    : `Описание`,
  },
  {
    dataIndex: `comment`,
    key      : `comment`,
    title    : `Комментарий`,
  },
  {
    dataIndex: `source`,
    key      : `source`,
    title    : `Оператор`,
    render   : (id: any) => _.find(operators, { id })?.user?.fullName || `-`,
  },
];
