import styled from 'styled-components';
import { palette } from 'styles/theme';

import { RightOutlined } from '@ant-design/icons';

export const Head = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
`;

export const Icon = styled.div<{ $color: string }>`
  margin-right: 12px;
  ${p => p.$color === `green` && `color: ${palette.greenColor(p)}`}
  ${p => p.$color === `orange` && `color: ${palette.orangeColor(p)}`}
  ${p => p.$color === `red` && `color: ${palette.redColor(p)}`}
`;

export const Arrow = styled(RightOutlined)`
  margin-right: 8px;
  color: ${palette.black60Color};
`;

export const Title = styled.div`
  color: ${palette.black80Color};
  font-weight: 600;
  margin-right: 24px;
`;

export const Content = styled.div`
  flex-grow: 1;
  font-size: 14px;
  width: 100%;
`;

export const Description = styled.div`
  flex-grow: 1;
  width: 100%;
  margin-top: 8px;
  color: ${palette.textPrimaryColor};
`;

export const Date = styled.div`
  color: ${palette.black60Color};
  font-size: 12px;
  font-family: Consolas, Menlo, Courier, monospace;
  white-space: nowrap;
  text-align: right;
  line-height: 16px;
`;

export const Wrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 0;

  &+& {
    border-top: 1px solid ${palette.black30Color};
  }
`;
