import * as React from 'react';
import { Link } from 'dva/router';
import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { IRecent } from 'models/clients/types';

const Wrapper = styled.div`
  border: 1px solid ${getLightStyleOr(palette.transparentBlackColor, palette.black10Color)};
  cursor: pointer;
  padding: 8px;

  background: ${getLightStyleOr(() => `transparent`, palette.black10Color)};

  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 8px;

  color: ${getLightStyleOr(palette.whiteColor, palette.black60Color)};

  :hover {
    color: ${getLightStyleOr(palette.primary500Color, palette.black100Color)};
  }
`;

interface IComponentProps extends IRecent {
}

export const RecentItem: React.FC<IComponentProps> = ({ label, personId }) => (
  <Link to={`client/person/${personId}/info`}>
    <Wrapper>
      {label}
    </Wrapper>
  </Link>
);
