import * as React from 'react';
import styled from 'styled-components';

import {
  Alert,
  Button,
} from 'antd';

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
  max-width: 450px;
`;

interface IComponentProps {
  message: string;
  onButtonClick?: () => void;
}

export const Warning: React.FC<IComponentProps> = ({ message, onButtonClick }) => (
  <StyledAlert
    action={onButtonClick ? (
      <Button
        onClick={onButtonClick}
        size='small'
        // @ts-ignore
        type='ghost'
      >
        Перейти
      </Button>
    ) : undefined}
    message={message}
    type='error'
  />
);
