import React, { useState } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { hasIntersection } from 'helper';

import { createClientActiveEmployment as updateClientActiveEmploymentAction } from 'models/clients/actions';
import {
  getClientActiveEmployment as getClientActiveEmploymentSelector,
  getClientActiveEmployments as getClientActiveEmploymentsSelector,
  getIsLoadingInfo as isLoadingInfoSelector,
} from 'models/clients/selectors';
import {
  CLIENT_EDIT,
  SUPER_ADMIN,
} from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { EditOutlined } from '@ant-design/icons';
import CreateModal from 'components/Modal/Create';
import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';
import { WorkInfoItem } from 'pages/PageClient/Single/Info/WorkInfoItem';

import { EDIT_WORK_FORM_FIELDS } from './constants';

export const WorkInfo = ({ personId }) => {
  const dispatch = useDispatch();
  const { activeEmployment, activeEmployments, isLoading, userRoles } = useSelector(state => ({
    activeEmployment : getClientActiveEmploymentSelector(state, personId),
    activeEmployments: getClientActiveEmploymentsSelector(state, personId),
    userRoles        : getRoleNamesSelector(state),
    isLoading        : isLoadingInfoSelector(state),
  }));

  const update = data => dispatch(updateClientActiveEmploymentAction({ data, personId }));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(p => !p);

  const allowedRoles = [SUPER_ADMIN, CLIENT_EDIT];
  const canEditWork = hasIntersection(userRoles, allowedRoles);

  if (_.isEmpty(activeEmployments)) return null;

  return (
    <>
      <EntityCard>
        <EntityCardTitle>
          Работа
          {!_.isEmpty(activeEmployment) && (
            <EntityCardTitleControl
              actionTitle='Редактировать активное трудоустройство клиента'
              allowedRoles={allowedRoles}
              onClick={toggleModal}
            >
              <EditOutlined />
            </EntityCardTitleControl>
          )}
        </EntityCardTitle>

        <EntityCardContent>
          {_.map(activeEmployments, (item, index) => <WorkInfoItem index={index} key={index} {...item} />)}
        </EntityCardContent>
      </EntityCard>

      {canEditWork && (
        <CreateModal
          close={toggleModal}
          formFields={EDIT_WORK_FORM_FIELDS}
          isLoading={isLoading}
          item={activeEmployment}
          mode='update'
          title='Редактирование активного трудоустройства клиента'
          update={update}
          visible={isModalOpen}
        />
      )}
    </>
  );
};
