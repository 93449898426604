import * as React from 'react';
import {
  useEffect,
  useRef,
} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  CloseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { AttachmentFileThumbnail } from 'components/Messenger/Attachment/AttachmentFileThumbnail';
import { AttachmentPreviewModal } from 'components/Messenger/Attachment/AttachmentPreviewModal';
import { AttachmentsTitle } from 'components/Messenger/Attachment/AttachmentsTitle';
import { AttachmentsWrapper } from 'components/Messenger/Attachment/AttachmentsWrapper';
import { useAttachmentsPreview } from 'components/Messenger/Attachment/useAttachmentsPreview';
import { ACCEPT_COMMON_IMAGES } from 'components/Messenger/constants';
import { getFileKey } from 'components/Messenger/helpers';
import { InputImageThumbnail } from 'components/Messenger/Input/InputImageThumbnail';
import { IInputAttachment } from 'components/Messenger/types';

const Wrapper = styled.div`
`;

const IconWrapper = styled.div`
  font-size: 16px;
`;

const IconWrapperDanger = styled(IconWrapper)`
  transition: color 200ms ease;
  cursor: pointer;

  :hover {
    color: ${palette.alert600Color};
  }
`;

interface IComponentProps {
  clearFiles: () => void;
  files: File[];
  isProcessing: boolean;
  onRemoveFile: (index: number) => void;
}

export const InputAttachments: React.FC<IComponentProps> = ({ clearFiles, files, isProcessing, onRemoveFile }) => {
  const attachments: IInputAttachment[] = _.map(files, file => {
    const { lastModified, name, size, type } = file;

    if (!_.includes(ACCEPT_COMMON_IMAGES, file.type)) {
      return {
        name,
        size,
        lastModified,
        type,
      };
    }

    return {
      src: URL.createObjectURL(file as unknown as Blob),
      name,
      size,
      lastModified,
      type,
    };
  });

  const {
    closePreviewMode,
    isPreviewMode,
    previewImageIndex,
    selectImageByIndex,
    selectNextImage,
    selectPrevImage,
  } = useAttachmentsPreview(attachments);

  const previewImage = isPreviewMode
    // @ts-ignore
    ? attachments[previewImageIndex]
    : null;

  const quantity = _.size(files);
  const previousQuantity = useRef(quantity);
  const attachmentsEndRef = useRef(null);

  useEffect(() => {
    const isItemAdded = quantity > previousQuantity.current;

    if (attachmentsEndRef.current && isItemAdded) {
      setTimeout(() => {
        // @ts-ignore
        attachmentsEndRef.current.scrollIntoView({ behavior: `smooth` });
      }, 0);
    }

    previousQuantity.current = quantity;
  }, [quantity]);

  return (
    <Wrapper>
      <AttachmentsTitle files={attachments} isProcessing={isProcessing}>
        <DefaultTooltip title='Удалить всё'>
          <IconWrapperDanger onClick={() => clearFiles()}>
            <DeleteOutlined />
          </IconWrapperDanger>
        </DefaultTooltip>
      </AttachmentsTitle>

      <AttachmentsWrapper>
        {_.map(attachments, (file, index) => (
          <div key={getFileKey(file)} onClick={() => selectImageByIndex(index)}>
            {!_.includes(ACCEPT_COMMON_IMAGES, file.type) ? (
              <AttachmentFileThumbnail
                action={{
                  onClick: () => onRemoveFile(index),
                  title  : `Убрать файл`,
                  icon   : <CloseOutlined />,
                  type   : `danger`,
                }}
                file={file}
              />
            ) : (
              <InputImageThumbnail
                file={file}
                onRemoveFile={() => onRemoveFile(index)}
                src={file.src}
              />
            )}
          </div>
        ))}
        <div ref={attachmentsEndRef} />
      </AttachmentsWrapper>

      {isPreviewMode && (
        <AttachmentPreviewModal
          image={previewImage}
          // @ts-ignore
          imageIndex={previewImageIndex}
          isVisible={isPreviewMode}
          onCancel={closePreviewMode}
          onClickNext={selectNextImage}
          onClickPrev={selectPrevImage}
          quantity={attachments.length}
        />
      )}
    </Wrapper>
  );
};
