import {
  useCallback,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { IClient } from 'models/clients/types';

import {
  getClient as getClientAction,
  getClientApplications as getClientApplicationsAction,
  getClientLoans as getClientLoansAction,
} from './actions';
import {
  getClient as getClientSelector,
  getIsLoading as isLoadingSelector,
} from './selectors';

export const useClient = (
  personId:string,
  additionalCheck?: boolean,
  shouldGetApplications?: boolean,
  shouldGetLoans?: boolean,
  caller?: string,
):[isLoading:boolean, client:IClient] => {
  const dispatch = useDispatch();

  const client:IClient = useSelector(state => getClientSelector(state, personId));
  const isLoading = useSelector(isLoadingSelector);

  if (caller) {
    console.log(`useClient called by ${caller}`); // eslint-disable-line no-console
  }

  const getApplications = useCallback(
    () => dispatch(getClientApplicationsAction({ personId })),
    [dispatch, personId],
  );

  const getLoans = useCallback(
    () => dispatch(getClientLoansAction({ personId })),
    [dispatch, personId],
  );

  const getClient = useCallback(
    () => dispatch(getClientAction({
      personId,
      callback: () => {
        if (shouldGetApplications) getApplications();
        if (shouldGetLoans) getLoans();
      },
    })),
    [dispatch, personId],
  );

  useEffect(() => {
      if (!personId || (_.isBoolean(additionalCheck) && !additionalCheck) || isLoading || !_.isEmpty(client)) return;
      getClient();
    },
    [
      additionalCheck,
      personId,
      getClient,
    ],
  );
  return [isLoading, client];
};
