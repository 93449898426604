import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';
import { Link } from 'dva/router';
import styled, { css } from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import {
  formatDate,
  getFileSize,
} from 'helper';

import { downloadFile as downloadFileHelper } from 'models/fileStorage/helpers';
import { updateIsRead } from 'models/notifications/actions';

import {
  DownloadOutlined,
  LinkOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import {
  Button,
  Tooltip,
} from 'antd';

const Wrapper = styled.div`
  color: ${palette.textPrimaryColor};
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
  padding-right: 16px;
  padding-top: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;

  @media(min-width: ${breakpoints.md}) {
    align-items: center;
  }
`;

const HeaderTexts = styled.div`
  display: flex;
  flex-direction: column;
  @media(min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`;

const Title = styled.p`
  font-weight: 700;
  line-height: 1.4;
  margin-right: 8px;
`;

const Description = styled.p`
  margin-bottom: 8px;
  margin-top: 4px;
`;

const NotificationDate = styled.span`
  color: ${palette.black60Color};
  flex-shrink: 0;
  font-weight: 400;
  margin-left: 6px;
`;

const ReadButton = styled(Button)<{ $isRead: boolean }>`
    background-color: ${palette.primary500Color};
    border: 10px solid ${palette.backgroundColor};
    border-radius: 50%;
    box-shadow: none;
    height: 30px;
    margin-left: auto;
    margin-top: -6px;
    min-width: 30px;
    padding: 0;
    visibility: visible !important;
    width: 30px;

    &:hover,
    &:focus {
      background-color: ${palette.primary600Color};
      border-color: ${palette.borderDefaultColor};
      transform: scale(1.1);
    }

    &:focus {
      border-color: ${palette.borderAccentColor};
    }

    ${p => p.$isRead && `
      background-color: ${palette.backgroundColor(p)};
      border-color: ${palette.backgroundColor(p)};

      &:hover, &:focus {
        background-color: ${palette.borderDefaultColor(p)};
      }

      &:focus {
        background-color: ${palette.borderAccentColor(p)};
      }
    `}
`;

const UrlsWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const UrlsIcon = styled(LinkOutlined)`
  margin-right: 8px;
  margin-top: -8px;
`;

const UrlsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const linkMixin = css`
  color: ${palette.primary500Color};
  cursor: pointer;
  font-weight: 700;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: ${palette.primary600Color};
  }
`;

const UrlItem = styled(Link)`
  ${linkMixin};
  margin-bottom: 8px;
  margin-right: 16px;
`;

const DownloadIcon = styled(DownloadOutlined)`
  cursor: pointer;
  margin-left: 4px;
`;

const AttachmentsWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const AttachmentsIcon = styled(PaperClipOutlined)`
  margin-right: 8px;
  margin-top: -8px;
`;

const AttachmentsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AttachmentItem = styled.div`
  margin-bottom: 8px;
  margin-right: 16px;
`;

interface IUrl {
  title: string;
  url: string;
}

interface IProps {
  attachments: any[];
  createdAt: string;
  description: string;
  id: number;
  isRead: boolean;
  title: string;
  urls: IUrl[];
}

const NotificationItem: React.FC<IProps> = ({
  attachments = [],
  createdAt,
  description,
  id,
  isRead,
  title,
  urls = [],
}) => {
  const dispatch = useDispatch();

  const formattedCreatedAt = formatDate(new Date(createdAt));
  const formattedUrls = _.map(urls, item => ({
    title: item.title,
    url  : new URL(item.url).pathname,
  }));

  const handleDownloadFile = ({ file, url }: { file: File; url: string }) => downloadFileHelper({ file, url });
  const handleOnRead = () => dispatch(updateIsRead({ id, isRead: !isRead }));

  return (
    <Wrapper>
      <Header>
        <HeaderTexts>
          <Title>
            {title}
            <NotificationDate>{formattedCreatedAt}</NotificationDate>
          </Title>
        </HeaderTexts>
        <Tooltip placement='left' title={`Отметить как ${isRead ? `не` : ``}прочитанное`}>
          <ReadButton $isRead={isRead} onClick={handleOnRead} />
        </Tooltip>
      </Header>
      <Description>{description}</Description>

      {!_.isEmpty(formattedUrls) && (
        <UrlsWrapper>
          <UrlsIcon />

          <UrlsList>
            {_.map(formattedUrls, ({ title: _title, url }) => (
              <UrlItem key={url} to={url}>{_title}</UrlItem>
            ))}
          </UrlsList>
        </UrlsWrapper>
      )}

      {!_.isEmpty(attachments) && (
        <AttachmentsWrapper>
          <AttachmentsIcon />

          <AttachmentsList>
            {_.map(attachments, ({
              url,
              ...file
            }) => (
              <AttachmentItem>
                {`${file.name} (${getFileSize(file.size)})`}
                <DownloadIcon onClick={() => handleDownloadFile({ file, url })} />
              </AttachmentItem>
            ))}
          </AttachmentsList>
        </AttachmentsWrapper>
      )}

    </Wrapper>
  );
};

export default NotificationItem;

