import * as React from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';
import { v4 as uuidV4 } from 'uuid';

import { REQUIRED } from 'constants/FORM_VALIDATION_RULES';
import { getFullNameInitials } from 'helper';

import {
  getClient as getClientAction,
  getClientLoans as getClientLoansAction,
} from 'models/clients/actions';
import {
  getClient as getClientSelector,
  getClientActiveLoans as getClientActiveLoansSelector,
  getClientLoans as getClientLoansSelector,
} from 'models/clients/selectors';
import {
  ILoan,
  ILoanClient,
} from 'models/clients/types';
import { getMultiple as getDictionariesAction } from 'models/dictionaries/actions';
import {
  getBlockCampaigns,
  getSortedByNameItems as getDictionarySelector,
} from 'models/dictionaries/selectors';
import { get as getOperatorsAction } from 'models/operators/actions';
import {
  getCurrent as getCurrentOperatorSelector,
  getItemsByCampaignAccessRight as getOperatorsByCampaignSelector,
} from 'models/operators/selectors';
import { create as createTaskAction } from 'models/task/actions';
import { TASK_BLOCK_TITLE } from 'models/task/constants';

import {
  Button,
  Checkbox,
  Form,
  Select,
  Space,
} from 'antd';

const LinkButton = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  color: ${palette.primary600Color};
  transition: color 100ms ease;
  padding: 8px 0;

  :hover {
    color: ${palette.primary500Color};
  }
`;

interface IComponentProps {
  entityState: string;
  personId: string;
  onCancel(): void;
  onSubmit(): void;
}

export const CreateTaskForm: React.FC<IComponentProps> = ({ entityState, onCancel, onSubmit, personId }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const campaignId = Form.useWatch(`campaignId`, form);

  const clientLoans = useSelector(state => getClientLoansSelector(state, personId));
  const clientActiveLoans = useSelector(state => getClientActiveLoansSelector(state, personId));

  const loans:ILoan[] = entityState === `collection` ? clientLoans : clientActiveLoans;

  // @ts-ignore
  const campaigns = useSelector(state => getBlockCampaigns(state, TASK_BLOCK_TITLE[entityState]));
  const campaignTypes = useSelector(state => getDictionarySelector(state, `campaignType`));
  const currentOperator = useSelector(getCurrentOperatorSelector);
  const client:ILoanClient = useSelector(state => getClientSelector(state, personId));

  const createTask = (data: { birthDate: Date; campaign: any; campaignType: any; communicationTypeId: number; contractNumber: string | undefined; createdAt: Date; createdBy: any; createdDtm: Date; gender: number; limit: null; loanId: any; name: string; operatorId: any; patronymic: string; personId: string; phone: number; segment: null; surname: string; taskId: string; updatedAt: Date; }) => dispatch(createTaskAction(data));
  const getLoans = () => dispatch(getClientLoansAction({ personId }));
  const getOperators = () => dispatch(getOperatorsAction());
  const getClient = (callback: () => void) => dispatch(getClientAction({ personId, callback }));
  const getDictionaries = () => dispatch(getDictionariesAction({
    entities: [`campaign`, `campaignType`],
    force   : true,
  }));

  const campaignTypeIdOptions = _.filter(campaignTypes, { campaignId });
  const campaignName = _.get(_.find(campaigns, { id: campaignId }), `name`);
  const operatorsByCampaign = useSelector(state => getOperatorsByCampaignSelector(state, campaignName));
  const operatorIdOptions = _.map(
    operatorsByCampaign,
    ({ id, user }) => ({ label: getFullNameInitials(user), value: id }),
  );

  const isAbleToAssignToSelf = _.includes(_.map(operatorIdOptions, `value`), currentOperator.id);

  const assignToMe = () => {
    form.setFieldsValue({ operatorId: currentOperator.id });
  };

  const create = (values: { loanId: any; campaignId: any; campaignTypeId: any; operatorId: any; }) => {
    const loan = _.find(loans, { id: values.loanId });
    const campaign = _.find(campaigns, { id: values.campaignId });
    const campaignType = _.find(campaignTypes, { id: values.campaignTypeId });

    createTask({
      birthDate          : client.birthDate,
      campaign,
      campaignType,
      communicationTypeId: 2,
      contractNumber     : loan?.contractNumber,
      createdAt          : new Date(),
      createdBy          : currentOperator.id,
      createdDtm         : new Date(),
      gender             : client.gender,
      limit              : null,
      loanId             : values.loanId,
      name               : client.name,
      operatorId         : values.operatorId,
      patronymic         : client.patronymic,
      personId           : client.personId,
      phone              : client.phone,
      segment            : null,
      surname            : client.surname,
      taskId             : uuidV4(),
      updatedAt          : new Date(),
    });
  };

  const onFinish = (values: { createAnother?: any; loanId?: any; campaignId?: any; campaignTypeId?: any; operatorId?: any; }) => {
    // @ts-ignore
    create(values);
    form.resetFields();

    if (!values.createAnother) {
      onSubmit();
    } else {
      form.setFieldsValue({ createAnother: true });
    }
  };

  useEffect(() => {
    getOperators();
    getDictionaries();
    getClient(() => {
      getLoans();
    });
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [entityState]);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      scrollToFirstError={{ behavior: `smooth` }}
      validateTrigger={[`onChange`, `onBlur`]}
    >
      <Form.Item
        label='Номер договора'
        name='loanId'
      >
        <Select
          className='select select_tag'
          fieldNames={{
            label: `contractNumber`,
            value: `id`,
          }}
          options={loans}
        />
      </Form.Item>
      <Form.Item
        label='Кампания'
        name='campaignId'
        required
        rules={[REQUIRED]}
      >
        <Select
          className='select select_tag'
          disabled={!entityState}
          fieldNames={{
            label: `name`,
            value: `id`,
          }}
          onChange={() => form.resetFields([`campaignTypeId`])}
          options={campaigns}
        />
      </Form.Item>
      <Form.Item
        label='Тип кампании'
        name='campaignTypeId'
        required
        rules={[REQUIRED]}
      >
        <Select
          className='select select_tag'
          disabled={_.isNil(campaignId)}
          fieldNames={{
            label: `name`,
            value: `id`,
          }}
          options={campaignTypeIdOptions}
        />
      </Form.Item>
      <Form.Item
        label='Ответственный сотрудник'
      >
        <Form.Item name='operatorId' noStyle>
          <Select
            className='select select_tag'
            disabled={_.isNil(campaignId)}
            // @ts-ignore
            filterOption={(input, option) => (_.includes(_.toLower(option.label), _.toLower(input)))}
            options={operatorIdOptions}
            showSearch
          />
        </Form.Item>

        {isAbleToAssignToSelf && (
          <LinkButton onClick={() => assignToMe()} type='button'>Назначить себе</LinkButton>
        )}
      </Form.Item>
      <Form.Item
        name='createAnother'
        valuePropName='checked'
      >
        <Checkbox>Создать ещё</Checkbox>
      </Form.Item>
      <Form.Item style={{
        display       : `flex`,
        justifyContent: `flex-end`,
      }}
      >
        <Space>
          <Button htmlType='button' onClick={onCancel}>
            Отмена
          </Button>
          <Button htmlType='submit' type='primary'>
            Сохранить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
