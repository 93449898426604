import styled from 'styled-components';

import { horizontalPaddingMixin } from 'components/mixins/horizontal-padding-mixin';

export const TabContentWrapper = styled.div`
  ${horizontalPaddingMixin};
  margin-top: 24px;
  padding-bottom: 24px;
`;

