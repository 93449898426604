import React, { useState } from 'react';
import { useSelector } from 'dva';

import { getActiveTabPath as getActiveTabPathSelector } from 'models/ui/selectors';

import useMobileContext from 'hooks/useMobileContext';

import { OperatorPreferences } from 'components/Modal/OperatorPreferences';
import { HeaderDesktop } from 'pages/Layout/Header/HeaderDesktop';
import { HeaderMobile } from 'pages/Layout/Header/HeaderMobile';

import {getNavigation, MainNav} from './helpers';

const Header = () => {
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const { mobile } = useMobileContext();
  const activeTabPath = useSelector(getActiveTabPathSelector);
  const nav = useSelector(getNavigation) as MainNav;

  return (
    <>
      {mobile
        ? (
          <HeaderMobile
            activeTabPath={activeTabPath}
            nav={nav}
            onPreferencesOpen={() => setShowPreferencesModal(true)}
          />
        ) : (
          <HeaderDesktop
            activeTabPath={activeTabPath}
            nav={nav}
            onPreferencesOpen={() => setShowPreferencesModal(true)}
          />
        )}
      <OperatorPreferences onClose={() => setShowPreferencesModal(false)} visible={showPreferencesModal} />
    </>
  );
};

export default Header;
