import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { Popconfirm } from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

const IconWrapper = styled.div`
  cursor: pointer;

  :hover{
    .anticon {
      color: ${palette.primary500Color};
    }
  }

  :not(:last-child) {
    padding-right: 8px;
  }

  :not(:first-child) {
    padding-left: 8px;
  }

  .anticon {
    font-size: 14px;
    transition: color 200ms ease;
    color: ${palette.black60Color};
  }
`;

interface IComponentProps {
  children: React.ReactNode;
  isDangerous?: boolean;
  onClick: () => void;
  title: string;
}

export const MessageAction: React.FC<IComponentProps> = ({ children, isDangerous, onClick, title }) => (
  <DefaultTooltip title={title}>
    {isDangerous ? (
      <Popconfirm
        cancelText='Нет'
        okButtonProps={{ danger: true }}
        okText='Да'
        onConfirm={onClick}
        title={`Точно ${_.toLower(title)}?`}
      >
        <IconWrapper>
          {children}
        </IconWrapper>
      </Popconfirm>
    ) : (
      <IconWrapper onClick={onClick}>
        {children}
      </IconWrapper>
    )}
  </DefaultTooltip>
);
