import React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { copyToClipboard } from 'helper';

import {
  CopyOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { subHeaderButtonDefaultMixin } from 'components/SubHeader/SubHeaderButtonDefault';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${breakpoints.sm}) {
    justify-content: flex-start;
  }
`;

const ClientName = styled.div`
  cursor: pointer;
  font-family: PT Root UI, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: ${palette.white95Color};
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;

  transition: color 200ms ease;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  max-width: 268px;

  .anticon {
    margin-right: 4px;
    transition: color 200ms ease;
    color: ${palette.black60Color};
  }

  :hover {
    color: ${palette.whiteColor};
    .anticon {
      color: ${palette.whiteColor};
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    font-size: 18px;
    line-height: 20px;
    max-width: 340px;

    margin-top: 6px;
    margin-bottom: 6px;
  }

  @media(min-width: ${breakpoints.xl}) {
    max-width: 400px;
  }

  @media(min-width: ${breakpoints.xxl}) {
    max-width: 600px;
  }
`;

const CopyButton = styled.button`
  ${subHeaderButtonDefaultMixin};

  border-radius: 8px;
  margin-left: 8px;

  @media(min-width: ${breakpoints.lg}) {
    border-radius: 24px;
  }

  .anticon:first-child {
    margin-right: 0;
  }
`;

interface IProps {
  className?: string;
  fullName: string;
}

const HeaderClientName: React.FC<IProps> = ({ className, fullName }) => (
  <Wrapper className={className}>
    <ClientName onClick={() => window.history.back()} title={fullName}>
      <LeftOutlined />
      {fullName}
    </ClientName>
    <CopyButton onClick={() => copyToClipboard(fullName)}>
      <CopyOutlined />
    </CopyButton>
  </Wrapper>
);

export default HeaderClientName;

