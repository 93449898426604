import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import { Link } from 'dva/router';
import styled from 'styled-components';
import {
  palette,
  shadows,
} from 'styles/theme';

import { parseResponse } from 'api/helpers';
import {
  downloadBlob,
  formatDate,
  getShortened,
  showError,
} from 'helper';

import { getCommunicationCallRecord } from 'models/clients/api';
import { Communication } from 'models/communications/types';
import { useDictionaries } from 'models/dictionaries/hooks';
import { useOperators } from 'models/operators/hooks';
import { TASK_BLOCK_TITLE } from 'models/task/constants';

import useMobileContext from 'hooks/useMobileContext';

import {
  DownloadOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

import {
  Label,
  StyledButton,
  Value,
} from './styled';

type MobileProps = {
  $mobile?: boolean;
};

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &+& {
    margin-top: 16px;
  }
`;

const Section = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 16px 24px 0;
  margin-top: 16px;
  margin-bottom: 16px;
  border-right: 1px solid ${palette.black30Color};

  &:first-child {
    margin-left: -24px;
  }

  &:last-child {
    border-right: none;
    padding-right: 0;
  }

  ${({ $mobile }) => $mobile && `
    padding: 0;
    margin-top: 0;
    border-right: none !important;

    &:first-child {
      margin-left: 0;
    }
  `}
`;

const Sections = styled.div<MobileProps>`
  display: flex;

  ${({ $mobile }) => $mobile && `
    flex-direction: column;
    width: 100%;
  `}
`;

const CommentWrapper = styled.div`
  padding-top: 16px;
  border-top: 1px solid ${palette.black30Color};
  width: 100%;

  ${Label} {
    align-self: flex-start;
  }
`;

const SectionTitle = styled.div<MobileProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 16px 16px 48px;
  border-radius: 8px;
  border: 1px solid ${palette.black30Color};
  box-shadow: ${shadows.basic};

  ${({ $mobile }) => $mobile && `
    border-radius: 8px 8px 0 0;
    padding: 16px;
    flex-direction: column;
  `}
`;

const SectionDetails = styled.div<MobileProps>`
  background-color: ${palette.black10Color};
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 24px;
  min-width: 100%;

  &:last-of-type {
    border-radius: 0 0 8px 8px;
    padding-top: 0;
  }

  ${({ $mobile }) => $mobile && `
    padding: 16px 16px;
    flex-direction: column;

    &:last-of-type {
      border-radius: 0;
    }
  `}
`;

const Wrapper = styled.div<MobileProps>`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;
  flex-shrink: 0;

  >* {
    flex-grow: 1;
  }

  ${({ $mobile }) => $mobile && `padding: 0 16px;`}
`;

const StyledButtonWide = styled(StyledButton)`
  display: flex;
  width: 100%;
  position: relative;
  color: ${palette.white100Color};
  background-color: ${palette.primaryColor};
  border-radius: 0 0 8px 8px;
  margin-left: 0;
  justify-content: center;

  &:focus {
    color: ${palette.white100Color};
    background-color: ${palette.primaryColor};
  }
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleButtonsWrapper = styled(ValueWrapper)`
  margin-left: 16px;
`;

const TitleButton = styled(Button)`
  color: ${palette.black50Color};
  &+& {
    margin-left: 8px;
  }
`;

interface IComponentProps extends Communication {
  hideTitleLabels?: string[];
  isMobile?: boolean;
}

const chunkArray = (array: any[], size: number) => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
};

const Item :React.FC<IComponentProps> = ({
  appealSubjectId,
  applicationDate,
  campaignBlock,
  campaignName,
  campaignTypeName,
  clientCharacteristicId,
  clientName,
  collectionPromiseStatusId,
  collectionServiceId,
  comment,
  commentWithLabel,
  communicationFeedbackId,
  communicationTypeId,
  createdAt,
  hideTitleLabels = [],
  id,
  isCallRecordAvailable,
  isCommunicationsDetailedView,
  isMobile,
  notification,
  notificationDate,
  operator,
  operatorId,
  parkingId,
  paymentDate,
  personId,
  phone,
  promiseDate,
  proposalAnnounced,
  proposalReactionId,
  saleFeedbackStatusId,
  serviceDate,
  takeDoc,
  takeDocDate,
  task,
  taskId,
}) => {
  const { mobile: contextMobile } = useMobileContext();
  const mobile = contextMobile || isMobile;
  const [isItemOpen, setItemOpen] = useState(false);
  useEffect(() => {
    // @ts-ignore
    setItemOpen(isCommunicationsDetailedView)
  }, [isCommunicationsDetailedView]);
  const downloadCallRecordFn = async () => {
    try {
      const blob = parseResponse({
        defaultError: `Не удалось загрузить запись звонка`,
        response    : await getCommunicationCallRecord(id),
      });
      downloadBlob(blob, `Запись звонка от ${formatDate(new Date(createdAt))}.mp3`);
    } catch (error) {
      showError({ error });
    }
  };

  const downloadCallRecord = useCallback(downloadCallRecordFn, [id]);

  const [isDictionariesLoading, dictionaries] = useDictionaries([
    `appealSubject`,
    `clientCharacteristic`,
    `collectionPromiseStatus`,
    `collectionService`,
    `communicationFeedback`,
    `communicationType`,
    `hmParking`,
    `proposalReaction`,
    `saleFeedbackStatus`,
  ]);

  const [isOperatorsLoading, operators] = useOperators(undefined, false);

  const sectionTitleRows = useMemo(() => {
    if (isOperatorsLoading || isDictionariesLoading) return [];
    return [
      {
        label: `Дата`,
        value: formatDate(new Date(createdAt)) || `-`,
      },
      {
        label: `Блок`,
        value: campaignBlock || `-`,
      },
      {
        label: `Кампания`,
        value: campaignName || `-`,
      },
      {
        label: `Оператор`,
        value: operator || _.get(_.find(operators, { id: operatorId }), `user.fullName`) || `-`,
      },
      {
        label: `Тип коммуникации`,
        value: _.get(_.find(dictionaries.communicationType, { id: communicationTypeId }), `description`) || `-`,
      },
    ].filter(({ label }) => !hideTitleLabels.includes(label));
  },
  [
    campaignBlock,
    campaignName,
    communicationTypeId,
    dictionaries?.communicationType?.length,
    createdAt,
    hideTitleLabels?.length,
    isDictionariesLoading,
    isOperatorsLoading,
    JSON.stringify(operator),
    operatorId,
    operators?.length,
  ]);

  const sectionDetailRows = useMemo(() => {
    if (isOperatorsLoading || isDictionariesLoading) return [];
    return chunkArray([
      {
        label: `Коммуникация`,
        value: (
          <ValueWrapper>
            {getShortened(_.toString(id))}

            {isCallRecordAvailable && (
              <TitleButtonsWrapper>
                <DefaultTooltip title='Скачать запись звонка'>
                  <TitleButton icon={<DownloadOutlined />} onClick={downloadCallRecord} />
                </DefaultTooltip>
              </TitleButtonsWrapper>
            )}
          </ValueWrapper>
        ),
      },
      { label: `Тип кампании`, value: campaignTypeName || `-` },
      {
        label: `Задача взыскание`,
        value: (taskId && campaignBlock === TASK_BLOCK_TITLE.collection)
          ? <Link to={`/tasks/task/${taskId}/person/${personId}`}>{taskId}</Link>
          : `-`,
      },
      {
        label: `Дата создания`,
        // @ts-ignore
        value: _.isEmpty(task) ? `-` : formatDate(new Date(task.createdAt), `dd.MM.yyyy`),
      },
      {
        label: `Дата обновления`,
        // @ts-ignore
        value: _.isEmpty(task) ? `-` : formatDate(new Date(task.updatedAt), `dd.MM.yyyy`),
      },
      {
        label: `Дата обещания`,
        value: promiseDate ? formatDate(new Date(promiseDate), `dd.MM.yyyy`) : `-`,
      },
      {
        isBold: true,
        label : `Дата обещ платежа`,
        value : paymentDate ? formatDate(new Date(paymentDate), `dd.MM.yyyy`) : `-`,
      },
      {
        isBold: true,
        label : `Сервис`,
        value : _.find(dictionaries?.collectionService, { id: collectionServiceId })?.description || `-`,
      },
      {
        label: `Статус обещания`,
        value: _.find(dictionaries?.collectionPromiseStatus, { id: collectionPromiseStatusId })?.description || `-`,
      },
      {
        label: `Дата сервиса`,
        value: serviceDate ? formatDate(new Date(serviceDate), `dd.MM.yyyy`) : `-`,
      },
      {
        isBold: true,
        label : `Обратная связь`,
        value : _.find(dictionaries?.communicationFeedback, { id: communicationFeedbackId })?.description || `-`,
      },
      {
        isBold: true,
        label : `Тема обращения`,
        value : _.find(dictionaries?.appealSubject, { id: appealSubjectId })?.description || `-`,
      },
      {
        label: `Обратная связь (продажи)`,
        value: _.find(dictionaries?.saleFeedbackStatus, { id: saleFeedbackStatusId })?.description || `-`,
      },
      {
        isBold: true,
        label : `Характеристика клиента`,
        value : _.find(dictionaries?.clientCharacteristic, { id: clientCharacteristicId })?.description || `-`,
      },
      {
        label: `Задача продажи`,
        value: (taskId && campaignBlock === TASK_BLOCK_TITLE.sale)
          ? <Link to={`/tasks/task/${taskId}/person/${personId}`}>{taskId}</Link>
          : `-`,
      },
      { label: `Предложение озвучено`, value: proposalAnnounced ? `Да` : (_.isNil(proposalAnnounced) ? `-` : `Нет`) },
      {
        label: `Реакция на предложение`,
        value: _.find(dictionaries?.proposalReaction, { id: proposalReactionId })?.description || `-`,
      },
      {
        label: `Дата заведения заявки`,
        value: applicationDate ? formatDate(new Date(applicationDate), `dd.MM.yyyy`) : `-`,
      },
      {
        label: `Парковка`,
        value: _.find(dictionaries?.hmParking, { id: parkingId })?.name || `-`,
      },
      { label: `Уведомление`, value: notification ? `Да` : (_.isNil(notification) ? `-` : `Нет`) },
      {
        label: `Дата уведомления`,
        value: notificationDate ? formatDate(new Date(notificationDate), `dd.MM.yyyy`) : `-`,
      },
      { label: `Документы переданы`, value: takeDoc ? `Да` : (_.isNil(takeDoc) ? `-` : `Нет`) },
      {
        label: `Дата передачи документов`,
        value: takeDocDate ? formatDate(new Date(takeDocDate), `dd.MM.yyyy`) : `-`,
      },
      ...(communicationTypeId === _.find(dictionaries?.communicationType, { name: `calibriChat` })?.id ? [
        {
          label: `Имя в чате`,
          value: clientName,
        },
        {
          label: `Телефон в чате`,
          value: phone,
        },
      ] : []),
      // @ts-ignore
    ].filter(({ value }) => ![`-`, `Нет`].includes(value)),
    5);
  }, [
    appealSubjectId,
    applicationDate,
    campaignBlock,
    campaignTypeName,
    clientCharacteristicId,
    clientName,
    collectionPromiseStatusId,
    collectionServiceId,
    communicationFeedbackId,
    communicationTypeId,
    id,
    isDictionariesLoading,
    isOperatorsLoading,
    notification,
    notificationDate,
    parkingId,
    paymentDate,
    personId,
    phone,
    promiseDate,
    proposalAnnounced,
    proposalReactionId,
    saleFeedbackStatusId,
    serviceDate,
    takeDoc,
    takeDocDate,
    task,
    taskId,
  ]);

  return (
    <Wrapper $mobile={mobile}>
      <SectionTitle $mobile={mobile}>
        {_.map(sectionTitleRows, ({ label, value }, key) => (
          <Row key={key}>
            {mobile && (<Label>{label}</Label>)}
            {/*@ts-ignore*/}
            <Value>{value}</Value>
          </Row>
        ))}
        {!mobile && (
          <StyledButton
            icon={isItemOpen ? (<UpOutlined />) : (<DownOutlined />)}
            onClick={() => setItemOpen(!isItemOpen)}
          />
        )}
      </SectionTitle>
      {isItemOpen && (
        <>
          <SectionDetails $mobile={mobile}>
            <Sections $mobile={mobile}>
              {_.map(sectionDetailRows, (rows, key) => (
                <Section $mobile={mobile} key={key}>
                  {_.map(rows, ({ isBold, label, value }, k) => (
                    <Row key={k}>
                      <Label>{label}</Label>
                      <Value $bold={isBold}>{value}</Value>
                    </Row>
                  ))}
                </Section>
              ))}
            </Sections>
          </SectionDetails>
          <SectionDetails $mobile={mobile}>
            <CommentWrapper>
              {commentWithLabel || (
              <Row>
                <Label $isComment>Комментарий</Label>
                <Value>{comment}</Value>
              </Row>
              )}
            </CommentWrapper>
          </SectionDetails>
        </>
      )}
      {mobile && (
        <StyledButtonWide
          icon={isItemOpen ? <UpOutlined /> : <DownOutlined />}
          onClick={() => setItemOpen(!isItemOpen)}
        />
      )}
    </Wrapper>
  );
};

export default React.memo(Item);
