import * as React from 'react';
import _ from 'lodash';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { CopyIconButton } from 'components/CopyIconButton/CopyIconButton';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { entityCardRowTextMixin } from 'pages/PageClient/Single/components/EntityCard/EntityCard';

const CopyButton = styled(CopyIconButton)<{ $dark?: boolean }>`
  ${p => (p.$dark && `
    color: ${palette.whiteColor(p)};
 `)}
`;

const Wrapper = styled.div<{ $color?: boolean | string; $compact?: boolean }>`
  display: flex;
  justify-content: space-between;

  :not(:last-child) {
    margin-bottom: 8px;
  }

  @media(min-width: ${breakpoints.lg}) {
    :not(:last-child) {
      margin-bottom: 16px;
    }

    ${CopyButton}  {
      opacity: 0;
    }

    :hover {
      ${CopyButton}  {
        opacity: 1;
      }
    }
  }

  ${({ $compact }) => $compact && css`
    flex: 1;
    justify-content: flex-start;
    margin-right: 8px;
    margin-bottom: 8px !important;

    :not(:last-child) {
      margin-bottom: 0;
    }
  `};

  ${p => p.$color && css`
    background: ${transparentize(0.8, p.$color as string)};
    margin-left: -16px;
    margin-right: -16px;
    padding: 4px 16px;
  `};
`;

const Title = styled.span<{ $compact?: boolean, $dark?: boolean }>`
  ${entityCardRowTextMixin};

  color: ${p => getLightStyleOr(p.$dark ? palette.whiteColor : palette.black80Color, palette.black40Color)(p)};

  max-width: 50%;
  ${({ $compact }) => $compact && `
    max-width: unset;
    white-space: nowrap;
  `}
`;

const Value = styled.div<{ $compact?: boolean, $dark?: boolean }>`
  ${entityCardRowTextMixin};

  color: ${p => (p.$dark ? palette.whiteColor(p) : palette.black100Color(p))};
  margin-left: 8px;
  text-align: right;
  display: flex;

  @media(min-width: ${breakpoints.lg}) {
    margin-left: 16px;
  }

  @media(max-width: 430px) {
    white-space: normal;
  }

  ${({ $compact }) => $compact && `
    max-width: unset;
    white-space: nowrap;
  `}
`;

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  margin-left: 8px;
`;

interface IComponentProps {
  children?: React.ReactNode;
  color?: string | boolean;
  compact?: boolean;
  dark?: boolean;
  note?: string;
  title: string;
  valueToCopy?: string | number | Date | JSX.Element;
}

export const IconWithTooltip = ({ text }: { text?: string }) => {
  if (!text) return null;

  return (
    <DefaultTooltip overlayInnerStyle={{ whiteSpace: `pre-line`, width: 300 }} placement='right' title={text}>
      <StyledQuestionCircleOutlined />
    </DefaultTooltip>
  );
};

export const EntityCardRow: React.FC<IComponentProps> = ({
  children,
  color,
  compact,
  dark,
  note,
  title,
  valueToCopy,
}) => !_.isNil(children) && (
  <Wrapper $color={color} $compact={compact}>
    <Title $compact={compact} $dark={dark}>
      {title}:<IconWithTooltip text={note} />
    </Title>
    <Value $compact={compact} $dark={dark}>
      {children}

      {Boolean(valueToCopy) && (// @ts-ignore
        <CopyButton $dark={dark} valueToCopy={valueToCopy} />
      )}
    </Value>
  </Wrapper>
);
