import React from 'react';
import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';

const Wrapper = styled.button`
  width: 100%;
  background: ${getLightStyleOr(palette.black10Color, palette.backgroundColor)};
  border: none;
  cursor: pointer;
  transition: background 100ms ease;
  border-radius: 2px;

  .anticon {
    color: ${getLightStyleOr(palette.black60Color, palette.black40Color)};
  }

  :hover {
    background: ${getLightStyleOr(palette.black20Color, palette.black5Color)};
    .anticon {
      transition: color 100ms ease;
      color: ${palette.textPrimaryColor};
    }
  }

  :active {
    background: getLightStyleOr(palette.black20Color, palette.black5Color);
  }
`;

interface IComponentProps {
  className?: string;
  collapsed: boolean;
  onClick(): void;
}

export const ExpandButton: React.FC<IComponentProps> = ({ className, collapsed, onClick }) => (
  <Wrapper className={className} onClick={onClick}>
    {collapsed ? <DownOutlined /> : <UpOutlined />}
  </Wrapper>
);
