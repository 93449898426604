import React from 'react';
import _ from 'lodash';
import ExcelJS from 'exceljs';
import moment from 'moment';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { WOOPRA_PERSONAL_URL } from 'constants/WOOPRA';
import {
  formatDate,
  validateCellValue,
} from 'helper';

import {
  ManOutlined,
  WomanOutlined,
} from '@ant-design/icons';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

import {
  INSURANCE_PRODUCT_IDS,
  MARKETING_OFFERS_EXCEL_COLUMNS,
} from './constants';
import {ILoanClient, IClient, IOffer, IApplication} from 'models/clients/types';
// FIXME to new data structure

const StyledManOutlined = styled(ManOutlined)`
  color: ${palette.black60Color};
  margin-right: 8px;
`;

const StyledWomanOutlined = styled(WomanOutlined)`
  color: ${palette.black60Color};
  margin-right: 8px;
`;

export const getDateFormatted = (loanClient: ILoanClient, dateKey: string) => {
  const dateRaw = _.get(loanClient, dateKey);
  if (!dateRaw) return null;
  const date = new Date(dateRaw);
  if (!_.isDate(date)) return null;
  return formatDate(date, `dd.MM.yyyy`);
};

export const getAge = (loanClient: IClient) => {
  const birthDate = _.get(loanClient, `birthDate`);
  if (!birthDate || !_.isDate(new Date(birthDate))) return null;
  return moment().diff(moment(new Date(birthDate)), `years`);
};

// @ts-ignore
export const getDocDownloadName = ({doc, loan, surname}) => {
  const fileName = doc.name.replaceAll(` `, `_`) || ``;
  if (_.includes([35, 18, 36, 37], doc.type)) {
    const contractNumber = loan?.contractNumber.replaceAll(`/`, `_`) || `?`;
    if (
      (loan?.productId === 4 && doc.type === 35)
      || (loan?.productId !== 4 && doc.type === 18)
      || (_.includes([36, 37], doc.type))
    ) return `${fileName}_${contractNumber}_${doc.type}`;
  }
  return `${fileName}_${surname}_${doc.type}`;
};

export const getGenderTitle = (gender: number, defaultValue = `?`) => (
  _.isNil(gender)
    ? defaultValue
    : (gender === 1 ? `Мужской` : `Женский`)
);

export const getGenderIcon = (gender: number, defaultValue = `?`) => (_.isNil(gender)
  ? defaultValue
  : (gender === 1) ? <StyledManOutlined/> : <StyledWomanOutlined/>);

export const getGenderIconTooltip = (gender: number, defaultValue = `?`) => (_.isNil(gender)
  ? defaultValue
  : <DefaultTooltip title={getGenderTitle(gender)}>{getGenderIcon(gender)}</DefaultTooltip>);

export const getWoopraLink = (loanClient: IClient, linkLabel = `Woopra`) => {
  if (!_.get(loanClient, `personId`)) return null;

  return (
    <a
      href={WOOPRA_PERSONAL_URL(loanClient.personId)}
      rel='noreferrer'
      style={{ textDecoration: `underline` }}
      target='_blank'
    >
      {linkLabel}
    </a>
  );
};

export const getFullName = (client: { noPatronymicFlg?: boolean, personId?: string } = {}) => {
  const fields = client && client.noPatronymicFlg ? [`surname`, `name`] : [`surname`, `name`, `patronymic`];

  return _.join(_.compact(_.map(fields, field => _.get(client, field, ``))), ` `);
};

export const getOfferInsurance = (offer: IOffer) => (
  _.find(_.get(offer, `supplements`, []), ({ productId }) => _.includes(INSURANCE_PRODUCT_IDS, productId))
);

export const getHasSupplement = (application: IApplication) => (application.offers
  // @ts-ignore
  ? !!getOfferInsurance(_.first(application.offers))
  : `?`);

export const validateMarketingOffersFile = async (file: File): Promise<void> => {
  if (!file || !file?.size) throw new Error(`Не выбран файл для загрузки офферов`);

  // Валидация файла:
  const workbook = new ExcelJS.Workbook();
  // @ts-ignore
  await workbook.xlsx.load(file);
  const worksheet = workbook.getWorksheet(1);

  // @ts-ignore
  const columnNames = worksheet.getRow(1).values;
  // @ts-ignore
  const errors = [];

  // @ts-ignore
  for (let i = 2; i <= worksheet.rowCount; i += 1) {
    // @ts-ignore
    const rowData = worksheet.getRow(i).values;

    // @ts-ignore
    for (let j = 1; j <= columnNames.length; j += 1) {
      // @ts-ignore
      const columnName = columnNames[j];
      if (columnName) {
        const expectedFormat = _.find(MARKETING_OFFERS_EXCEL_COLUMNS, { name: columnName })?.format;

        // @ts-ignore
        const cellValue = rowData[j];

        // Проверка на формат данных
        if (!validateCellValue(cellValue, expectedFormat)) {
          // @ts-ignore
          _.update(errors, i, error => ({
            rowNumber     : i,
            invalidColumns: _.concat(_.get(error, `invalidColumns`, []), columnName),
          }));
        }
      }
    }
  }

  // Фильтрация пустых ошибок
  // @ts-ignore
  const filteredErrors = _.filter(errors, error => !_.isEmpty(error?.invalidColumns));

  // Если есть ошибки валидации, выбросить исключение:
  if (!_.isEmpty(filteredErrors)) {
    throw new Error(`Некорректно внесены данные: \n ${_.join(_.map(filteredErrors, ({
      invalidColumns,
      rowNumber,
    }) => `Строка ${rowNumber}: ${invalidColumns.join(`, `)}`), `\n`)}`);
  }
};
