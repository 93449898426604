import React from 'react';

import { TEventlogItem } from 'models/eventlogs/types';

import useMobileContext from 'hooks/useMobileContext';

import { LogoutOutlined } from '@ant-design/icons';
import { ItemHead } from 'components/Eventlog/Item/ItemHead';

import {
  Content,
  Icon,
  Wrapper,
} from '../styled';

interface IComponentProps extends TEventlogItem {
  date:string;
  operatorName?:string;
}

const title = `Задача создана`;
export const TaskCreated: React.FC<IComponentProps> = ({ date, operatorName }) => {
  const { mobile } = useMobileContext();

  return (
    <Wrapper $isMobile={mobile}>
      <Icon $color='green'><LogoutOutlined /></Icon>
      <Content>
        <ItemHead date={date} operatorName={operatorName} title={title} />
      </Content>
    </Wrapper>
  );
};
