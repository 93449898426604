import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment from 'moment';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { getClient as getClientAction } from 'models/clients/actions';
import { getFullName } from 'models/clients/helpers';
import { getClient as getClientByPersonIdSelector } from 'models/clients/selectors';
import {
  create as createAction,
  resetModal as resetModalAction,
  setModal as setModalAction,
  update as updateAction,
} from 'models/communications/actions';
import { getModal as getModalSelector } from 'models/communications/selectors';
import { getMultiple as getDictionariesAction } from 'models/dictionaries/actions';
import { getItemsForSelect as getDictionarySelector } from 'models/dictionaries/selectors';

import {
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Collapse as CollapseDefault,
  DatePicker,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import {
  Control,
  Label,
  Row,
  Section,
} from 'components/Modal/Communications/Section';

const { Panel: PanelDefault } = CollapseDefault;

const ModalStyled = styled(Modal)`
  ${p => p?.$isMinimized && `
    position: fixed;
    left: 2px;
    bottom: 2px;
    top: unset !important;
    height: 56px;
    overflow: hidden;
    border: 3px solid ${palette.primaryColor(p)};
    border-radius: 8px;
    background-color: ${palette.primary100Color(p)} !important;
    box-shadow: 0px -4px 8px rgba(192,205,217,0.18),0px 0px 2px rgba(192,205,217,0.12),0px 0px 1px rgba(0,0,0,0.04);
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledSpin = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Controls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 16px;
  justify-content: flex-end;

  @media (max-width: 390px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0;
  }
`;

const StyledButton = styled(Button)`
  &+& {
    margin-left: 8px;
  }
`;

const MinimizeButton = styled(Button)`
  position: absolute;
  top: -45px;
  right: 42px;
  border: none !important;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 390px) {
    flex-direction: column;
    align-items: center;
    width: 100%;

    ${StyledButton} {
      min-width: 100%;
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

const Panel = styled(PanelDefault)`
  padding: 0;
`;

const Collapse = styled(CollapseDefault)`
  .ant-collapse-content-box {
    padding: 0;
  }
`;

const Col = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  * {
    min-width: 100%;
  }

  textarea {
    margin-right: 0 !important;
    width: 100% !important;
    transform: none !important;
  }

  ${Label} {
    margin-bottom: 8px;
  }
`;

const formatDate = value => (value
  ? (moment.isMoment(value)
    ? value
    : moment(value))
  : null);

export const Communications = () => {
  const dispatch = useDispatch();

  const {
    appealSubjectId,
    block,
    clientCharacteristicId,
    collectionPromiseStatusId,
    collectionServiceId,
    comment,
    communicationFeedbackId,
    // eslint-disable-next-line no-unused-vars
    communicationId,
    communicationTypeId,
    id,
    ipCallExternalId,
    isLoading,
    isMinimized,
    isMttCall,
    isOpen,
    mode,
    notification,
    operatorId,
    parkingId,
    personId,
    phone,
    proposalAnnounced,
    proposalReactionId,
    saleFeedbackStatusId,
    takeDoc,
    taskId,
    ...other
  } = useSelector(getModalSelector);

  const applicationDate = formatDate(other.applicationDate);
  const notificationDate = formatDate(other.notificationDate);
  const serviceDate = formatDate(other.serviceDate);
  const takeDocDate = formatDate(other.takeDocDate);
  const promiseDate = formatDate(other.promiseDate);
  const paymentDate = formatDate(other.paymentDate);

  const {
    appealSubjects,
    client,
    clientCharacteristics,
    collectionPromiseStatuses,
    collectionServices,
    communicationFeedbacks,
    communicationTypes,
    parkings,
    proposalReactions,
    saleFeedbackStatuses,
  } = useSelector(state => ({
    client                   : getClientByPersonIdSelector(state, personId),
    appealSubjects           : getDictionarySelector(state, `appealSubject`),
    clientCharacteristics    : getDictionarySelector(state, `clientCharacteristic`),
    collectionPromiseStatuses: getDictionarySelector(state, `collectionPromiseStatus`),
    collectionServices       : getDictionarySelector(state, `collectionService`),
    communicationFeedbacks   : getDictionarySelector(state, `communicationFeedback`),
    communicationTypes       : getDictionarySelector(state, `communicationType`),
    parkings                 : getDictionarySelector(state, `hmParking`, items => _.map(items, item => ({
      label: item?.name,
      value: item?.id,
    }))),
    proposalReactions   : getDictionarySelector(state, `proposalReaction`),
    saleFeedbackStatuses: getDictionarySelector(state, `saleFeedbackStatus`),
  }));

  const close = () => dispatch(setModalAction({ isOpen: false }));
  const toggleMinimize = () => dispatch(setModalAction({ isMinimized: !isMinimized }));
  const create = data => dispatch(createAction(data));
  const getClient = () => dispatch(getClientAction({ personId }));
  const getDictionaries = force => dispatch(getDictionariesAction({
    entities: [
      `appealSubject`,
      `clientCharacteristic`,
      `campaignType`,
      `campaign`,
      `collectionPromiseStatus`,
      `collectionService`,
      `communicationFeedback`,
      `communicationType`,
      `hmParking`,
      `proposalReaction`,
      `saleFeedbackStatus`,
    ],
    force,
  }));
  const set = data => dispatch(setModalAction(data));
  const reset = data => dispatch(resetModalAction(data));
  const update = data => dispatch(updateAction(data));

  useEffect(() => {
    getClient();
    getDictionaries();
  }, []);

  if ((!personId && !isMttCall) || !isOpen) {
    return null;
  }

  const onCancel = () => {
    close();
    set({ isMttCall: false });
  };

  const onReset = () => {
    reset({ isOpen, personId, mode });
  };

  const mapDataToSubmit = () => {
    const data = {
      appealSubjectId,
      applicationDate,
      clientCharacteristicId,
      collectionPromiseStatusId,
      collectionServiceId,
      comment,
      communicationFeedbackId,
      communicationTypeId,
      id,
      ipCallExternalId,
      notificationDate,
      notification,
      operatorId,
      parkingId,
      paymentDate,
      personId,
      phone,
      promiseDate,
      proposalAnnounced,
      proposalReactionId,
      saleFeedbackStatusId,
      serviceDate,
      takeDocDate,
      takeDoc,
      taskId,
    };

    return {
      callback: onCancel,
      item    : _.omitBy(data, _.isNil),
      phone,
      personId,
    };
  };

  const onSubmit = () => {
    const data = mapDataToSubmit();

    switch (mode) {
      case `create`:
        return create(data);
      case `update`:
        return update(data);
      default:
        throw new Error(`Unknown mode: ${mode}`);
    }
  };

  const fullName = getFullName(client);

  const isSubmitDisabled = isLoading;
  const title = mode === `create`
    ? `Новая коммуникация (${fullName || phone})`
    : `Редактирование коммуникации (${fullName})`;

  return (
    <ModalStyled
      $isMinimized={isMinimized}
      bodyStyle={{ padding: 0 }}
      centered
      footer={null}
      mask={!isMinimized}
      maskClosable={false}
      onCancel={onCancel}
      open={isOpen}
      title={title}
      wrapClassName={isMinimized ? `ant-minimized-modal-wrap` : undefined}
      zIndex={isMinimized ? 0 : 1000}
    >
      <Content>
        <DefaultTooltip title={isMinimized ? `Развернуть` : `Свернуть`}>
          <MinimizeButton
            icon={isMinimized ? <PlusOutlined /> : <MinusOutlined />}
            onClick={toggleMinimize}
            type='text'
          />
        </DefaultTooltip>
        {isLoading && <StyledSpin />}
        <Section label='Тип'>
          <Select
            allowClear
            onChange={v => set({ communicationTypeId: v })}
            options={communicationTypes}
            value={communicationTypeId}
          />
        </Section>

        <Section label='Дата обещанного платежа'>
          <DatePicker
            onChange={v => set({ paymentDate: v })}
            value={paymentDate}
          />
        </Section>

        <Section label='Обратная связь'>
          <Select
            allowClear
            onChange={v => set({ communicationFeedbackId: v })}
            options={communicationFeedbacks}
            value={communicationFeedbackId}
          />
        </Section>

        <Collapse
          activeKey={_.castArray(block)}
          defaultActiveKey={_.castArray(block)}
          onChange={v => set({ block: v })}
        >
          <Panel header='Взыскание' key='Взыскание'>
            <Section label='Статус обещания'>
              <Select
                allowClear
                onChange={v => set({ collectionPromiseStatusId: v })}
                options={collectionPromiseStatuses}
                value={collectionPromiseStatusId}
              />
            </Section>
            <Section label='Характеристика клиента'>
              <Select
                allowClear
                onChange={v => set({ clientCharacteristicId: v })}
                options={clientCharacteristics}
                value={clientCharacteristicId}
              />
            </Section>

            {collectionServiceId && (
              <Section label='Дата сервиса'>
                <DatePicker
                  onChange={v => set({ serviceDate: v })}
                  value={serviceDate}
                />
              </Section>
            )}
            <Section label='Сервис'>
              <Select
                allowClear
                onChange={v => set({ collectionServiceId: v })}
                options={collectionServices}
                value={collectionServiceId}
              />
            </Section>
            <Section label='Уведомление' noGrow>
              <Switch
                checked={notification}
                onChange={v => set({ notification: v })}
              />
            </Section>

            {notification && (
              <Section label='Дата уведомления'>
                <DatePicker
                  onChange={v => set({ notificationDate: v })}
                  value={notificationDate}
                />
              </Section>
            )}

            <Section label='Документы переданы' noGrow>
              <Switch
                checked={takeDoc}
                onChange={v => set({ takeDoc: v })}
              />
            </Section>

            {takeDoc && (
              <Section label={`Дата передачи `}>
                <DatePicker
                  onChange={v => set({ takeDocDate: v })}
                  value={takeDocDate}
                />
              </Section>
            )}

            <Section label='Парковка'>
              <Select
                allowClear
                onChange={v => set({ parkingId: v })}
                options={parkings}
                value={parkingId}
              />
            </Section>
          </Panel>

          <Panel header='Продажи' key='Продажи'>
            <Section label='Предложение озвучено' noGrow>
              <Switch
                checked={proposalAnnounced}
                onChange={v => set({ proposalAnnounced: v })}
              />
            </Section>

            {proposalAnnounced && (
              <Section label='Реакция на предложение'>
                <Select
                  allowClear
                  onChange={v => set({ proposalReactionId: v })}
                  options={proposalReactions}
                  value={proposalReactionId}
                />
              </Section>
            )}
            {proposalAnnounced && (
              <Section label='ОС на предложение'>
                <Select
                  allowClear
                  onChange={v => set({ saleFeedbackStatusId: v })}
                  options={saleFeedbackStatuses}
                  value={saleFeedbackStatusId}
                />
              </Section>
            )}

            <Section label='Предпол. дата завед. заявки'>
              <DatePicker
                onChange={v => set({ applicationDate: v })}
                value={applicationDate}
              />
            </Section>
          </Panel>

          <Panel header='Сервис' key='Сервис'>
            <Section label='Тема обращения'>
              <Select
                allowClear
                onChange={v => set({ appealSubjectId: v })}
                options={appealSubjects}
                value={appealSubjectId}
              />
            </Section>
          </Panel>
        </Collapse>
        <Col>
          <Label>Комментарий</Label>
          <Control>
            <Input.TextArea
              onChange={v => set({ comment: v.target.value })}
              value={comment}
            />
          </Control>
        </Col>
        <Controls>
          <ButtonsWrapper>
            <StyledButton onClick={toggleMinimize}>Свернуть</StyledButton>
            <StyledButton onClick={onCancel}>Отмена</StyledButton>
            <StyledButton danger onClick={onReset}>Очистить</StyledButton>
            <StyledButton disabled={isSubmitDisabled} onClick={onSubmit} type='primary'>Сохранить</StyledButton>
          </ButtonsWrapper>
        </Controls>
      </Content>
    </ModalStyled>
  );
};
