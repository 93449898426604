import styled from 'styled-components';

import {
  Collapse as CollapseDefault,
  Form,
  InputNumber,
  Modal,
} from 'antd';

const { Panel: PanelDefault } = CollapseDefault;
export const StyledInputNumber = styled(InputNumber)`width: 200px;`;
export const Panel = styled(PanelDefault)`
  padding: 0;
`;

export const Collapse = styled(CollapseDefault)`
  .ant-collapse-content-box {
    padding: 0;
    padding-top: 16px;
  }
  margin-bottom: 16px;
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 24px 0;
  }
`;

export const FormItemStyled = styled(Form.Item)`
  padding: 0 24px;
`;
