/* eslint-disable max-len */
import _ from 'lodash';
import { AxiosResponse } from 'axios';

import api from 'api/index';
import MULTIPART_CONFIG from 'constants/MULTIPART_CONFIG';

import {
  IFilesMeta,
  IMessage,
  ISearchDialogsRequest,
} from 'models/dialogs/types';

const getFormData = ({ files, filesMeta, message }: { files: File[], filesMeta: IFilesMeta[], message: IMessage }) => {
  const data = new FormData();
  if (!_.isEmpty(files)) {
    _.each(files, file => {
      if (file instanceof File) data.append(`files`, file, file.name);
    });
    data.append(`filesMeta`, JSON.stringify(filesMeta));
  }
  _.each(message, (value, key) => data.append(key, value));
  return data;
};

export const addMessage = ({
  files,
  filesMeta,
  id,
  ...message
}: { files: File[], filesMeta: IFilesMeta[], id: string | number, message: IMessage }): Promise<AxiosResponse> => api.post(
  `/dialog/${id}/message`,
  getFormData({ files, filesMeta, message }),
  MULTIPART_CONFIG,
);
export const create = (data: any): Promise<AxiosResponse> => api.put(`/dialog`, data);
export const getMessages = (id: number): Promise<AxiosResponse> => api.get(`/dialog/${id}/messages`);
export const getMessagesMultiple = (ids: number[]): Promise<AxiosResponse> => api.post(`/dialog/messages`, { ids });
export const getSingle = (id: number): Promise<AxiosResponse> => api.get(`/dialog/${id}`);
export const remove = (dialogId: number, messageId: number): Promise<AxiosResponse> => api.delete(`/dialog/${dialogId}/message/${messageId}`);
export const search = (data: ISearchDialogsRequest): Promise<AxiosResponse> => api.post(`/dialog/search`, data);
export const update = (dialogId: number, messageId: number, {
  files,
  filesMeta,
  ...message
}: { files: File[], filesMeta: IFilesMeta[], message: IMessage }): Promise<AxiosResponse> => api.patch(
  `/dialog/${dialogId}/message/${messageId}`,
  getFormData({ files, filesMeta, message }),
  MULTIPART_CONFIG,
);

