import React from 'react';
import _ from 'lodash';
import { Link } from 'dva/router';

import {
  fallBackTableValue,
  getColumn as getColumnDefault,
  getSort,
  prettifyNumber,
  sortAlphabetically,
} from 'helper';

import { getFullName } from 'models/clients/helpers';
import { COMISSIONS } from 'models/verifications/constants';
import { getInsuranceValue } from 'models/verifications/helpers';

import { CaretRightOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
} from 'antd';
import { CellDateTime } from 'components/DefaultTable/CellDateTime/CellDateTime';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';

const getColumn = props => getColumnDefault({ align: `center`, width: 150, ...props });

export const COLUMNS = (partners, isSale) => _.map([
  {
    key   : `verificationStatus`,
    title : `Статус`,
    render: (v = {}) => <DefaultTag color={v.color}>{v.description}</DefaultTag>,
    sorter: getSort(`verificationStatus.description`, sortAlphabetically),
  },
  {
    key   : `client`,
    title : `Клиент`,
    render: (client, { personId }) => (
      <Link to={`/client/person/${personId}/info`}>
        {getFullName(client)}
      </Link>
    ),
  },
  {
    key   : `id`,
    title : ``,
    render: (id, { applicationId, personId }) => (
      <Link to={`/${isSale ? `sale` : `verification`}/${id}/person/${personId}/info?applicationId=${applicationId}`}>
        <Button icon={<CaretRightOutlined />} shape='circle' type='primary' />
      </Link>
    ),
    width: 45,
  },
  {
    key   : `approved`,
    title : `Одобрено`,
    render: v => <Checkbox checked={v} />,
    width : 90,
  },
  {
    key   : `insurance`,
    title : `Страховка`,
    render: getInsuranceValue,
  },
  {
    key   : `insurance`,
    title : `Страховка есть`,
    render: insurance => <Checkbox checked={_.isBoolean(insurance) && insurance} />,
    width : 120,
  },
  {
    dataIndex: [`application`, `productName`],
    key      : `productName`,
    title    : `Продукт`,
  },
  {
    dataIndex: [`application`, `requestedLoan`],
    key      : `requestedLoan`,
    title    : `Сумма`,
    render   : prettifyNumber,
    width    : 100,
  },
  {
    dataIndex: [`application`, `requestedTerm`],
    key      : `requestedTerm`,
    title    : `Срок`,
    width    : 90,
  },
  {
    dataIndex: `comission`,
    key      : `comission`,
    title    : `Комиссия`,
    width    : 110,
    render   : value => _.find(COMISSIONS, { value })?.label || ``,
  },
  {
    key             : `createdAt`,
    title           : `Поступила в СРМ`,
    defaultSortOrder: `descend`,
    align           : `left`,
    render          : v => <CellDateTime value={v} />,
    sorter          : getSort(`createdAt`, sortAlphabetically),
  },
  {
    key   : `partnerId`,
    title : `Партнёр`,
    render: value => _.get(_.find(partners, { value }), `label`),
    width : 110,
  },
  {
    dataIndex: [`application`, `dealershipName`],
    key      : `dealershipName`,
    title    : `Дилер`,
    render   : fallBackTableValue(`application.dealershipId`),
    width    : 110,
  },
  {
    key  : `operatorName`,
    title: `В работе у сотрудника`,
    width: 180,
  },
], getColumn);
