import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { parseResponse } from 'api/helpers';
import {
  inputNumberFormatter,
  inputNumberParser,
} from 'helper';

import { getRequisitesByDealershipId } from 'models/dictionaries/api';
import { REQUISITE_TYPES } from 'models/dictionaries/constants';

import {
  DeleteOutlined,
  MailOutlined,
} from '@ant-design/icons';
import {
  Button,
  InputNumber,
  Select,
  Tooltip,
} from 'antd';
import {palette} from "styles/theme";

const AmountSplitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 16px;
  margin-top: 8px;

  & + & {
    margin-top: 16px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }

  &:after {
    content: '';
    position: absolute;
    width: 360px;
    left: 50%;
    border: 1px dashed ${palette.primaryColor};
    border-bottom: none;
    height: 1px;
    transform: translateX(-50%);
    top: -16px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

const Index = styled.div`
  position: absolute;
  left: -32px;
  color: ${palette.primaryColor};
  font-weight: 600;
  top: 16px;
  transform: translateY(-50%);

  @media (max-width: 767px) {
    position: relative;
    top: 4px;
    left: unset;
    flex: 1;
  }
`;

const Col = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
  }

  &:first-child {
    margin-left: 48px;

    @media (max-width: 767px) {
      margin-left: 0;
    }
  }

  & + & {
    margin-left: 16px;
    justify-content: flex-end;

    @media (max-width: 767px) {
      flex-direction: row;
      margin-left: 0;
    }
  }
`;

const StyledInputNumber = styled(InputNumber)`
  width: 360px;
  margin-bottom: 16px;
  order: 2;

  @media (max-width: 767px) {
    order: 3;
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  width: 360px;
  margin-bottom: 16px;
  order: 2;

  @media (max-width: 767px) {
    order: 3;
    width: 100%;
  }
`;

const ButtonWrapper = styled(Button)`
  margin-bottom: 16px;

  @media (max-width: 767px) {
    margin-left: 16px;
  }
`;

const SelectOption = styled(Select.Option)`
  position: relative;

  &-row {
    max-width: 100%;
    white-space: pre-wrap;

    &:first-child {
      font-weight: bold;
    }
  }

  &:nth-child(odd) {
    background-color: ${({ theme }) => `rgba(${theme.primaryColor}, .1)`};
  }

  &:hover {
    background-color: ${({ theme }) => `rgba(${theme.primaryColor}, .3)`};
  }

  .ant-select-item-option-content {
    white-space: pre-wrap !important;
    overflow: visible !important;
    text-overflow: unset !important;
  }
`;

const AmountSplit = ({
                       amount,
                       className = ``,
                       createGuaranteeLetter,
                       dealershipId,
                       index,
                       partnerRequisiteId,
                       remove,
                       set,
                     }) => {
  const [requisites, setRequisites] = useState([]);

  const setProp = prop => set({
    amount,
    index,
    partnerRequisiteId,
    ...prop,
  });

  const getRequisites = async () => {
    const requisitesResponse = parseResponse({
      defaultError: `При запросе реквизитов произошла ошибка`,
      response    : await getRequisitesByDealershipId(dealershipId),
    });

    setRequisites(_.filter(requisitesResponse, { activeFlg: true }));
  };

  useEffect(() => {
    getRequisites()
  }, [dealershipId]);

  const onAmountChange = v => setProp({ amount: v });
  const onPartnerChange = v => setProp({ partnerRequisiteId: v });
  const onRemoveSplit = () => remove(index);
  const onCreateGuaranteeLetter = () => createGuaranteeLetter(partnerRequisiteId);

  return (
    <AmountSplitWrapper className={className}>
      <Col>
        <Index>{index + 1}</Index>
        <StyledSelect
          onChange={onPartnerChange}
          placeholder='Реквизиты партнёра'
          value={partnerRequisiteId}
        >
          {_.map(requisites, ({ description, id, requisiteType }) => (
            <SelectOption key={id} value={id}>
              <div className="select-option-row">
                {_.get(_.find(REQUISITE_TYPES, { value: requisiteType }), `label`, requisiteType)}
              </div>
              {description && <div className="select-option-row">{`Описание: ${description}`}</div>}
            </SelectOption>
          ))}
        </StyledSelect>
        <StyledInputNumber
          formatter={inputNumberFormatter}
          onChange={onAmountChange}
          parser={inputNumberParser}
          placeholder='Сумма, ₽'
          precision={2}
          value={amount}
        />
      </Col>
      <Col>
        <ButtonWrapper
          danger
          icon={<DeleteOutlined />}
          onClick={onRemoveSplit}
        />
        <Tooltip title='Сформировать гарантийное письмо'>
          <ButtonWrapper
            disabled={!partnerRequisiteId}
            icon={<MailOutlined />}
            onClick={onCreateGuaranteeLetter}
            type='primary'
          />
        </Tooltip>
      </Col>
    </AmountSplitWrapper>
  );
};

export default AmountSplit;
