import React, {
  useEffect,
  useState,
} from 'react';
import moment from 'moment';

import { normalizeMomentToStartOfDay } from 'helper';

import { getFullName } from 'models/clients/helpers';
import { ILoanClient } from 'models/clients/types';

import { LoadingOutlined } from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Typography,
} from 'antd';

import { dadataCleanName } from './helpers';

import { DATE_FORMAT } from './constants';

const { Text } = Typography;

const VEHICLE_CATEGORIES = [`Легковой`, `Грузовой`, `Мотоцикл/мотороллер`];

interface IProps {
  client: ILoanClient;
  close: () => void;
  onSubmit: (params: {
    autoType: string;
    fioDat: string;
    pdpDate: moment.Moment;
  }) => void;
  visible: boolean;
}

const CreateAgreementModal: React.FC<IProps> = ({ client, close, onSubmit, visible }) => {
  const [pdpDate, setPdpDate] = useState<moment.Moment>(moment());
  const [fioDat, setFioDat] = useState<string>(``);
  const [autoType, setAutoType] = useState<string>(VEHICLE_CATEGORIES[0]);
  const [fioDatLoading, setFioDatLoading] = useState<boolean>(false);

  const handleFieldChange = (field: string, value: any) => {
    switch (field) {
      case `pdpDate`:
        setPdpDate(value);
        break;
      case `fioDat`:
        setFioDat(value);
        break;
      case `autoType`:
        setAutoType(value);
        break;
    }
  };

  const cleanName = async () => {
    try {
      const fullName = getFullName(client);
      setFioDatLoading(true);
      setFioDat(fullName);
      const { data, status } = await dadataCleanName(fullName);
      if (status !== 200) throw new Error();
      setFioDatLoading(false);
      setFioDat(data[0].result_dative);
    } catch (error) {
      setFioDatLoading(false);
      console.error(error); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    if (visible && !fioDat) {
      cleanName();
    }
  }, [visible]);

  const handleSubmit = () => {
    if (fioDatLoading) return;
    onSubmit({
      pdpDate: normalizeMomentToStartOfDay(pdpDate),
      fioDat,
      autoType,
    });
    close();
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      okText='Сформировать'
      onCancel={close}
      onOk={handleSubmit}
      open={visible}
      title='Сформировать согласие на реализацию'
    >
      <Form layout='vertical'>
        <Form.Item label='Дата ПДП'>
          <DatePicker
            allowClear={false}
            format={DATE_FORMAT}
            onChange={v => handleFieldChange(`pdpDate`, v)}
            // @ts-ignore
            value={pdpDate}
          />
        </Form.Item>
        <Form.Item label='Тип ТС'>
          <Select
            allowClear
            defaultValue={autoType}
            // @ts-ignore
            name='autoType'
            onChange={v => handleFieldChange(`autoType`, v)}
            placeholder='Выберите'
            showArrow
          >
            {VEHICLE_CATEGORIES.map(category => (
              <Select.Option key={category} value={category}>
                {category}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Text>
          Проверьте корректность ФИО в дательном падеже. При необходимости –
          отредактируйте.
        </Text>
        <Form.Item label='ФИО'>
          <Input
            allowClear={false}
            disabled={fioDatLoading}
            // @ts-ignore
            format={DATE_FORMAT}
            onChange={e => handleFieldChange(`fioDat`, e.target.value)}
            prefix={fioDatLoading && <LoadingOutlined />}
            value={fioDat}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateAgreementModal;
