import React, { useState } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { validateFileType } from 'helper';

import { sendTestEmail as sendTestEmailAction } from 'models/admin/actions';
import { isLoading as isLoadingSelector } from 'models/admin/selectors';

import {
  CheckCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  Modal,
  Upload,
} from 'antd';
import { Emails } from 'components/Emails/Emails';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  >* {
    min-width: 100%;
  }
`;

const StyledUpload = styled(Upload.Dragger)`
  border-color: ${palette.primaryColor} !important;
  border-radius: 8px !important;
`;

const UploadIcon = styled.p`
  color: ${palette.primaryColor};
  font-size: 32px;
`;

const UploadText = styled.p`
  color: ${palette.primaryColor};
`;

interface TestEmailModalProps {
  isVisible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const TestEmailModal: React.FC<TestEmailModalProps> = ({ isVisible, onClose, onSuccess = () => _.noop() }) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [html, setFile] = useState<any>(null);

  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const reset = () => {
    setEmails([]);
    setFile(null);
    if (onClose) onClose();
  };

  const handleUpload = () => {
    dispatch(
      sendTestEmailAction({
        callback: () => {
          reset();
          onSuccess();
        },
        emails,
        html,
      }),
    );
  };

  const isSubmitDisabled = _.isEmpty(emails) || !html || isLoading;

  return (
    <Modal
      cancelText='Отмена'
      centered
      closable={false}
      maskClosable={false}
      okButtonProps={{ disabled: isSubmitDisabled }}
      okText='Отправить'
      onCancel={reset}
      onOk={handleUpload}
      open={isVisible}
      title='Отправка тестовых Email'
    >
      <Content>
        <Emails onSetEmails={setEmails} />
        <StyledUpload
          customRequest={({ file }) => setFile(validateFileType(`.html`, file) ? file : null)}
          multiple={false}
          showUploadList={false}
        >
          {html ? (
            <>
              <UploadIcon>
                <CheckCircleOutlined />
              </UploadIcon>
              <UploadText>{html.name}</UploadText>
            </>
          ) : (
            <>
              <UploadIcon>
                <InboxOutlined />
              </UploadIcon>
              <UploadText>
                Кликните или перетащите сюда html файл тестового Email для загрузки
              </UploadText>
            </>
          )}
        </StyledUpload>
      </Content>
    </Modal>
  );
};

export default TestEmailModal;
