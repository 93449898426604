import { useCallback } from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { usePath } from 'hooks/usePath';

import { setTableState as setTableStateAction } from './actions';
import { getTableState as getTableStateSelector } from './selectors';

export const useSearchInBankrupts = () => {
  const dispatch = useDispatch();
  const tableState = useSelector(getTableStateSelector);
  const { pushPath } = usePath();

  return useCallback((personId: string) => {
    dispatch(setTableStateAction({
      ...tableState,
      filters: {
        personId: personId ? [personId] : null,
      },
      callback: () => pushPath(`/bankrupt`),
    }));
  }, [dispatch, pushPath, tableState]);
};
