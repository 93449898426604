import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { usePath } from 'hooks/usePath';

import { TablePaginationConfig } from 'antd';
import {
  FilterValue,
  SorterResult,
} from 'antd/es/table/interface';

import { setTableState as setTableStateAction } from './actions';
import { getTableState as getTableStateSelector } from './selectors';

export const useSearchInCourts = () => {
  const dispatch = useDispatch();
  const tableState = useSelector(getTableStateSelector);
  const { pushPath } = usePath();

  return useCallback((personId: string) => {
    dispatch(setTableStateAction({
      ...tableState,
      filters: {
        personId: personId ? [personId] : null,
      },
      callback: () => pushPath(`/court`),
    }));
  }, [dispatch, pushPath, tableState]);
};

export const useTableState = (personId?:string) => {
  const dispatch = useDispatch();

  const tableState = useSelector(getTableStateSelector);

  const setTableState = useCallback(state => dispatch(setTableStateAction(state)), [dispatch]);

  const emptyFilters = useMemo(() => (personId
    ? { personIds: [personId] }
    : {}), [personId]);

  const onTableChange = useCallback((
    pagination:TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter:SorterResult<any>,
  ) => {
    setTableState({
      pagination: {
        ...pagination,
        showSizeChanger: true,
      },
      filters: _.isNil(filters)
        ? emptyFilters
        : {
          ...(_.isEmpty(filters) ? tableState.filters : filters),
          ...(personId ? { personIds: [personId] } : {}),
        },
      sorter,
    });
  }, [dispatch, tableState, emptyFilters, personId]);

  useEffect(() => {
    if (personId) {
      onTableChange(tableState.pagination, tableState.filters, tableState.sorter);
    }
  }, []);

  return [tableState, onTableChange];
};

