import React, {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { parseResponse } from 'api/helpers';
import {
  b64toBlob,
  downloadBlob,
  getFullNameInitials,
  inputNumberFormatter,
  inputNumberParser,
  showError,
  validateEmail,
  validatePhone,
} from 'helper';

import { useClient } from 'models/clients/hooks';
import {
  getClientCars as getClientCarsSelector,
  getClientLoanById as getClientLoanByIdSelector,
} from 'models/clients/selectors';
import {
  ICar,
  ILoan,
} from 'models/clients/types';
import { generateLegalDocument } from 'models/task/api';
import { isLoading as isLoadingTaskSelector } from 'models/task/selectors';
import {
  IGenerateLegalDocument,
  IGenerateLegalDocumentAllData,
  IGenerateLegalDocumentData,
  IGenerateLegalDocumentValues,
} from 'models/task/types';
import { getCurrent as getCurrentUserSelector } from 'models/user/selectors';

import {
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
} from 'antd';
import LegalNotificationDownload from 'components/Modal/LegalNotificationDownload';

const Error = styled.div`
  color: ${palette.redColor};
`;

interface IProps {
  documents: IGenerateLegalDocument[];
  documentTemplateId: number,
  loanId: string,
  personId: string,
  taskId: number,
  title: string;
  visible: boolean
  onClose():void,
}

interface IErrors {
  caseNumber?: string;
  enforcementProceedings?: string;
  executionWirt?: string;
  managerEmail?: string;
  managerFullName?: string;
  managerInt?: string;
  managerPhone?: string;
  payedFee?: string;
}

const defaultValues: IGenerateLegalDocumentValues = {
  caseNumber            : ``,
  enforcementProceedings: `string`,
  executionWirt         : ``,
  managerEmail          : ``,
  managerPhone          : ``,
  managerFullName       : ``,
  managerInt            : ``,
  // @ts-ignore
  payedFee              : null,
};

const MANUAL_FIELDS = [
  `managerFullName`,
  `managerEmail`,
  `managerPhone`,
  `managerInt`,
  `caseNumber`,
  `enforcementProceedings`,
  `executionWirt`,
  `payedFee`,
];

interface IFile {
  file: string;
  mediaType: string;
  name: string;
}

const LegalDocumentGenerate: React.FC<IProps> = ({
  documents,
  documentTemplateId,
  loanId,
  onClose,
  personId,
  taskId,
  title,
  visible,
}) => {
  const isTaskLoading = useSelector(isLoadingTaskSelector);
  const currentUser = useSelector(getCurrentUserSelector);
  const [,client] = useClient(personId);
  const loan:ILoan = useSelector(state => getClientLoanByIdSelector(state, personId, loanId)) || {};
  const { applicationId } = loan;
  const cars:ICar[] = useSelector(state => getClientCarsSelector(state, personId));
  // const clientSurname:string = useSelector(state => getClientSurnameSelector(state, personId));
  const car = _.find(cars, ({ applicationIds }) => _.includes(applicationIds, applicationId));
  const [values, setValues] = useState<IGenerateLegalDocumentValues>(defaultValues);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);

  const setValue = (field: string, value: string) => setValues({ ...values, [field]: value });
  const setValueObj = (newValues: React.SetStateAction<IGenerateLegalDocumentValues>) => setValues({ ...values, ...newValues });

  useEffect(() => {
    if (!currentUser) return;
    // @ts-ignore
    setValueObj({
      managerFullName: currentUser.fullName,
      managerEmail   : currentUser.email,
      managerPhone   : currentUser.phone ? currentUser.phone : ``,
      managerInt     : getFullNameInitials(currentUser),
    });
  }, [currentUser.id]);

  const document = _.find(documents, { templateId: documentTemplateId });
  const errors: IErrors = {};
  const allData: IGenerateLegalDocumentAllData = {
    // @ts-ignore
    car,
    client,
    loan,
    values,
  };

  const submitData: IGenerateLegalDocumentData = {
    applicationId,
    documentTemplateId,
    loanId,
    personId,
    params: {
      applicationId,
      loanId,
      personId,
    },
  };
  const fields = _.keys(document?.params || {});
  // OPTION 1
  // validate each field
  // _.each(document?.params || {}, (value: (IGenerateLegalDocumentAllData) => number | string, key) => {
  //   if (value(allData)) {
  //     submitData.params[key] = value(allData);
  //   } else {
  //     errors[key] = `Не удалось получить значение поля ${key}`;
  //   }
  // });

  // OPTION 2
  // fill empty fields with 6 spaces
  // @ts-ignore
  _.each(document?.params || {}, (value: (IGenerateLegalDocumentAllData) => number | string, key) => {
    // @ts-ignore
    submitData.params[key] = _.isNil(value(allData)) ? `      ` : value(allData);
  });

  if (_.includes(fields, `managerFullName`) && !/^[а-я ]+$/gim.test(values.managerFullName)) {
    errors.managerFullName = `Кириллица, пробелы`;
  }
  if (_.includes(fields, `managerEmail`) && !validateEmail(values.managerEmail)) {
    errors.managerEmail = `Некорректный e-mail`;
  }

  if (_.includes(fields, `managerPhone`) && values.managerPhone && !validatePhone(values.managerPhone)) {
    errors.managerPhone = `Некорректный телефон`;
  }

  if (_.includes(fields, `managerInt`) && !/^[а-я .]+$/gim.test(values.managerInt)) {
    errors.managerInt = `Кириллица, пробелы, точки`;
  }
  if (_.includes(fields, `caseNumber`) && !values.caseNumber) {
    errors.caseNumber = `Необходимо заполнить`;
  }
  if (_.includes(fields, `enforcementProceedings`) && !values.enforcementProceedings) {
    errors.enforcementProceedings = `Необходимо заполнить`;
  }
  if (_.includes(fields, `executionWirt`) && !values.executionWirt) {
    errors.executionWirt = `Необходимо заполнить`;
  }
  if (_.includes(fields, `payedFee`) && !values.payedFee) {
    // @ts-ignore
    errors.payedFee = `Необходимо заполнить`;
  }

  const onSubmit = async () => {
    const defaultError = `При формировании документа произошла ошибка`;
    try {
      const file: IFile = parseResponse({
        dataPath: `data.file`,
        defaultError,
        response: await generateLegalDocument(submitData),
      });
      if (_.isEmpty(file)) {
        // @ts-ignore
        throw new Error(`В ответе от сервиса генерации документов нет File`);
      }

      // https://ccredit.atlassian.net/browse/CRM-991
      // const fileNameSplit = _.split(file.name, `.`);
      // const extension = _.last(fileNameSplit);
      // const fileNameArray = _.dropRight(fileNameSplit);
      // const fileNameExtended = `${_.join(fileNameArray, `.`)}_${clientSurname}.${extension}`;

      const blob = b64toBlob(file.file, file.mediaType);
      // https://ccredit.atlassian.net/browse/CRM-991
      // downloadBlob(blob, fileNameExtended);
      downloadBlob(blob, file.name);
      notification.success({ message: `Сформированный документ скачан. Заполните форму уведомления.` });
      setIsNotificationModalOpen(true);
    } catch (error) {
      showError({ defaultError, error });
    }
  };

  return (
    <>
      <Modal
        cancelText='Отмена'
        centered
        confirmLoading={isTaskLoading}
        okButtonProps={{ disabled: !_.isEmpty(errors) }}
        okText='Сформировать'
        onCancel={onClose}
        onOk={onSubmit}
        open={visible}
        title={title}
      >
        <Form layout='vertical'>
          {_.includes(fields, `managerFullName`) && (
            <Form.Item
              help={errors.managerFullName}
              label='Полное имя сотрудника'
              required
              validateStatus={errors.managerFullName ? `error` : `success`}
            >
              <Input
                onChange={e => setValue(`managerFullName`, e.target.value)}
                value={values.managerFullName}
              />
            </Form.Item>
          )}
          {_.includes(fields, `managerEmail`) && (
            <Form.Item
              help={errors.managerEmail}
              label='Почта сотрудника'
              required
              validateStatus={errors.managerEmail ? `error` : `success`}
            >
              <Input
                onChange={e => setValue(`managerEmail`, e.target.value)}
                value={values.managerEmail}
              />
            </Form.Item>
          )}
          {_.includes(fields, `managerPhone`) && (
            <Form.Item
              help={errors.managerPhone}
              label='Телефон сотрудника'
              validateStatus={errors.managerPhone ? `error` : `success`}
            >
              <Input
                onChange={e => setValue(`managerPhone`, e.target.value)}
                value={values.managerPhone}
              />
            </Form.Item>
          )}

          {_.includes(fields, `managerInt`) && (
            <Form.Item
              help={errors.managerInt}
              label='Фамилия и инициалы'
              required
              validateStatus={errors.managerInt ? `error` : `success`}
            >
              <Input
                onChange={e => setValue(`managerInt`, e.target.value)}
                value={values.managerInt}
              />
            </Form.Item>
          )}
          {_.includes(fields, `caseNumber`) && (
            <Form.Item
              help={errors.caseNumber}
              label='Номер дела'
              required
              validateStatus={errors.caseNumber ? `error` : `success`}
            >
              <Input
                onChange={e => setValue(`caseNumber`, e.target.value)}
                value={values.caseNumber}
              />
            </Form.Item>
          )}
          {_.includes(fields, `enforcementProceedings`) && (
            <Form.Item
              help={errors.enforcementProceedings}
              label='№ исполнительного производства'
              required
              validateStatus={errors.enforcementProceedings ? `error` : `success`}
            >
              <Input
                onChange={e => setValue(`enforcementProceedings`, e.target.value)}
                value={values.enforcementProceedings}
              />
            </Form.Item>
          )}
          {_.includes(fields, `executionWirt`) && (
            <Form.Item
              help={errors.executionWirt}
              label='Исполнительный лист'
              required
              validateStatus={errors.executionWirt ? `error` : `success`}
            >
              <Input
                onChange={e => setValue(`executionWirt`, e.target.value)}
                value={values.executionWirt}
              />
            </Form.Item>
          )}
          {_.includes(fields, `payedFee`) && (
            <Form.Item
              help={errors.payedFee}
              label='Уплаченная пошлина'
              required
              validateStatus={errors.payedFee ? `error` : `success`}
            >
              <InputNumber
                formatter={inputNumberFormatter}
                onChange={value => setValue(`payedFee`, value)}
                parser={inputNumberParser}
                precision={2}
                value={values.payedFee}
              />
            </Form.Item>
          )}

          {_.map(_.omit(errors, MANUAL_FIELDS), (error, key) => (
            <Error key={key}>{error}</Error>
          ))}
        </Form>
      </Modal>
      <LegalNotificationDownload
        onClose={() => {
          onClose();
          setIsNotificationModalOpen(false);
        }}
        personId={personId}
        taskIds={[taskId]}
        visible={visible && isNotificationModalOpen}
      />
    </>
  );
};

export default LegalDocumentGenerate;
