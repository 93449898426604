/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import Flicking from '@egjs/react-flicking';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import {
  getColumn,
  getColumnSum,
  prettifyAmount,
} from 'helper';

import useMobileContext from 'hooks/useMobileContext';

import {
  Table,
  Tooltip,
} from 'antd';
import { CellDateTime } from 'components/DefaultTable/CellDateTime/CellDateTime';
import { CellId } from 'components/DefaultTable/CellId/CellId';
import { DefaultTable } from 'components/DefaultTable/DefaultTable';
import { LoanRepaymentCard } from 'components/LoanRepayments/LoanRepaymentCard';
import {
  SummaryHeadline,
  SummaryValue,
} from 'pages/PageClient/Single/components/AdjustableTable/Summary/Summary';
import { EntityCardTitle } from 'pages/PageClient/Single/components/EntityCard/EntityCard';

import '@egjs/react-flicking/dist/flicking.css';

const MainTable = styled(DefaultTable)`
  border: 1px solid ${palette.black30Color};
`;

const NoData = styled.div`
  margin-left: 16px;
  margin-right: 16px;

  @media(min-width: ${breakpoints.md}) {
    margin-right: 24px;
    margin-left: 24px;
  }
`;

const PaginationInfo = styled(EntityCardTitle)`
  margin: 8px;
  //margin-left: 24px;
`;

const CardSummary = styled.div`
  display: flex;
  margin: 0 8px 8px;
  align-items: center;
`;

const CardSummaryValue = styled.p`
  margin-left: 8px;
`;

export const LoanRepayments = ({ isLoading, repayments }) => {
  const result = _.get(repayments, `result`, `ОК`) === `ОК` ? null : repayments.result;
  const dataSource = _.map(
    _.orderBy(_.get(repayments, `payments`, []), `paymentDtm`, `desc`),
    item => ({
      ...item,
      paymentForm: _.get(item, `details[0].paymentForm`) || _.get(item, `details[0].absPaymentType`),
    }),
  );

  const { mobile } = useMobileContext();

  return mobile ? (
      !_.isEmpty(dataSource) ? (
        <>
          <Flicking
            align='prev'
            changeOnHold
            moveType='strict'
            nested
            renderOnSameKey
          >
            {_.map(dataSource, (repayment, index) => (
              <div
                key={index}
                style={{ marginBottom: `24px` }}
              >
                <PaginationInfo>
                  {index + 1}/{dataSource.length}
                </PaginationInfo>
                <LoanRepaymentCard repayment={repayment} />
              </div>
            ))}
          </Flicking>
          <CardSummary>
            <SummaryHeadline>Σ</SummaryHeadline>
            <CardSummaryValue>
              {prettifyAmount(getColumnSum(`totalSum`)(dataSource))}
            </CardSummaryValue>
          </CardSummary>
        </>
      ) : (
        <NoData>
          {result}
        </NoData>
      )
  ) : (
    <div>
      {!_.isEmpty(dataSource) ? (
        <MainTable
          bordered
          columns={_.map([
            {
              key   : `paymentDtm`,
              title : `Дата`,
              render: v => <CellDateTime value={v} />,
            },
            {
              key   : `totalSum`,
              align : `right`,
              title : `Сумма`,
              render: prettifyAmount,
            },
            {
              key  : `paymentForm`,
              title: `Форма оплаты`,
            },
            {
              key   : `paymentId`,
              title : `id`,
              render: v => <CellId value={v} />,
            },
          ], getColumn)}
          dataSource={dataSource}
          expandable={{
            expandedRowRender: ({ details }) => (
              <DefaultTable
                columns={_.map([
                  {
                    align : `right`,
                    key   : `sum`,
                    title : `Сумма`,
                    render: prettifyAmount,
                    width : 130,
                  },
                  {
                    align: `left`,
                    key  : `type`,
                    title: `Тип`,
                  },
                ], getColumn)}
                dataSource={details}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize        : _.size(details),
                  size            : `small`,
                }}
                size='small'
              />
            ),
            rowExpandable: repayment => !_.isEmpty(repayment.details),
          }}
          loading={isLoading}
          pagination={{
            hideOnSinglePage: true,
            pageSize        : _.size(dataSource),
            size            : `small`,
          }}
          rowKey='paymentId'
          size='small'
          summary={d => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell align='center' index={1}>
                  <SummaryHeadline>Σ</SummaryHeadline>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3}>
                  <Tooltip title='Сумма'>
                    <SummaryValue align='right'>
                      {prettifyAmount(getColumnSum(`totalSum`)(d))}
                    </SummaryValue>
                  </Tooltip>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} />
                <Table.Summary.Cell index={5} />
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      ) : (
        <NoData>
          {result}
        </NoData>
      )}
    </div>
  );
};
