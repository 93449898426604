import React from 'react';
import _ from 'lodash';
import Ajv from 'ajv';

import BOOL_COLUMN from 'constants/BOOL_COLUMN';
import BOOL_FIELD from 'constants/BOOL_FIELD';
import DATE_COLUMN from 'constants/DATE_COLUMN';
import DATE_FIELD from 'constants/DATE_FIELD';
import {
  JSON_VALUE,
  REQUIRED,
  UUID,
} from 'constants/FORM_VALIDATION_RULES';
import JSON_COLUMN from 'constants/JSON_COLUMN';
import JSON_FIELD from 'constants/JSON_FIELD';
import TAG_COLORS from 'constants/TAG_COLORS';
import TEST_ATTRIBUTES from 'constants/TEST_ATTRIBUTES';
import {
  copyToClipboard,
  getSort,
  hasIntersection,
  prettifyAmount,
  sortAlphabetically,
} from 'helper';

import {
  downloadTemplates as downloadTemplatesAction,
  downloadTemplateSingle as downloadTemplateAction,
  get as getDictionary,
} from 'models/dictionaries/actions';
import {
  ENTITIES_WITH_NOT_UNIQUE_NAMES,
  FIELD_TYPES,
  REQUISITE_TYPES,
} from 'models/dictionaries/constants';
import { getDocumentTypes as getDocumentTypesSelector } from 'models/dictionaries/selectors';
import { get as loadOperators } from 'models/operators/actions';
import { getItem as getOperatorSelector } from 'models/operators/selectors';
import { get as getRolesAction } from 'models/roles/actions';
import { ROLE_NAMES } from 'models/roles/constants';
import { getItems as getRolesSelector } from 'models/roles/selectors';

import {
  DownloadOutlined,
  EnvironmentOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Input,
  InputNumber,
  Select,
} from 'antd';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

import {
  getRolesOptions,
  getTaskStatusOwnersSelector,
} from './helper';

const ajv = new Ajv();

const COLOR_COLUMN = {
  key   : `color`,
  render: v => (v ? <DefaultTag color={v}>{v}</DefaultTag> : null),
  title : `Цвет`,
  width : 80,
};

const COLOR_FIELD = {
  key      : `color`,
  title    : `Цвет отображения`,
  Component: Select,
  options  : _.map(TAG_COLORS, value => ({ label: <DefaultTag color={value}>{value}</DefaultTag>, value })),
};

const ID_COLUMN = {
  key  : `id`,
  align: `center`,
  width: 60,
};

const EXTERNAL_ID_COLUMN = {
  key  : `externalId`,
  title: `Внешний id`,
};

const CREDIT_HISTORY_TYPES = [
  {
    label: `Числовой, отделять пробелом разряд, округлять до целого числа`,
    value: `amount`,
  },
  {
    label: `Числовой, выводить как есть`,
    value: `number`,
  },
  {
    label: `Бинарный`,
    value: `boolean`,
  },
  {
    label: `Строка, выводить как есть`,
    value: `string`,
  },
];

const FORMAT_COLUMN = {
  align : `left`,
  key   : `type`,
  render: value => _.get(_.find(CREDIT_HISTORY_TYPES, { value }), `label`, value),
  title : `Формат данных`,
};

const ROLE_COLUMN = (key, title, restProps = {}) => ({
  key,
  title,
  render: role => role && <DefaultTag color={role.color}>{role.name}</DefaultTag>,
  width : 140,
  ...restProps,
});

const ROLE_FIELD = (key, title, state) => ({
  key,
  title,
  Component: Select,
  options  : getRolesOptions(state),
});

const FORMAT_FIELD = {
  key      : `type`,
  title    : `Формат данных`,
  Component: Select,
  options  : CREDIT_HISTORY_TYPES,
};

export const SOURCES_BY_ENTITY = {
  campaignType: {
    campaign: {
      key             : `campaignId`,
      title           : `Кампания`,
      Component       : Select,
      showSearch      : true,
      optionFilterProp: `label`,
      optionsRender   : v => v.description || v.name,
    },
    communicationType: {
      key          : `communicationTypeId`,
      title        : `Тип взаимодействия`,
      Component    : Select,
      optionsRender: v => <DefaultTag color={v.color}>{v.description || v.name}</DefaultTag>,
    },
  },
  dealership: {
    city: {
      key             : `cityId`,
      title           : `Город`,
      rules           : [REQUIRED],
      Component       : Select,
      showSearch      : true,
      optionFilterProp: `label`,
      optionsRender   : v => `${v.cityName || ` - `} (${v.id})`,
      testAttribute   : TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_ADD_CITY_FIELD,
    },
    partner: {
      key             : `partnerId`,
      title           : `Партнёр`,
      rules           : [REQUIRED],
      Component       : Select,
      showSearch      : true,
      optionFilterProp: `label`,
      optionsRender   : v => `${v.name || ` - `} (${v.id})`,
      testAttribute   : TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_ADD_PARTNER_FIELD,
    },
  },
  documentAlias: {
    documentType: {
      key      : `typeId`,
      title    : `Тип документа`,
      Component: props => (
        <Select
          {...props}
          allowClear
          optionFilterProp='label'
          showSearch
          value={props.value || undefined}
        />
      ),
      getOptions: ({ sourceItems: { documentType } }) => _.map(
        _.sortBy(documentType, `id`),
        ({ id, name }) => ({ value: id, label: `${name} (${id})` }),
      ),
    },
  },
  documentGroup: {
    documentType: {
      key      : `typeIds`,
      title    : `Типы документов`,
      Component: props => (
        <Select
          {...props}
          allowClear
          mode='multiple'
          optionFilterProp='label'
          showSearch
          value={props.value || undefined}
        />
      ),
      getOptions: ({ sourceItems: { documentType } }) => _.map(
        _.sortBy(documentType, `id`),
        ({ id, name }) => ({ value: id, label: `${name} (${id})` }),
      ),
    },
  },
  documentPackage: {
    documentType: {
      key      : `typeIds`,
      title    : `Типы документов`,
      Component: props => (
        <Select
          {...props}
          allowClear
          mode='multiple'
          optionFilterProp='label'
          showSearch
          value={props.value || undefined}
        />
      ),
      getOptions: ({ sourceItems: { documentType } }) => _.map(
        _.sortBy(documentType, `id`),
        ({ id, name }) => ({ value: id, label: `${name} (${id})` }),
      ),
    },
  },
  hmParking: {
    region: {
      key             : `regionId`,
      title           : `Регион`,
      Component       : Select,
      required        : true,
      rules           : [REQUIRED],
      showSearch      : true,
      optionFilterProp: `label`,
      optionsRender   : v => `${v.name || ` - `} (${v.id})`,
    },
  },
  hmSearchPartner: {
    region: {
      key             : `regionId`,
      title           : `Регион`,
      required        : true,
      Component       : Select,
      showSearch      : true,
      optionFilterProp: `label`,
      optionsRender   : v => `${v.name || ` - `} (${v.id})`,
    },
  },
  mobileConfig: {
    mobileConfigSchema: {
      key          : `schema`,
      sourceId     : 1,
      title        : ``,
      hidden       : true,
      Component    : Select,
      optionsRender: () => [],
    },
  },
  partnerRate: {
    partner: {
      key             : `partnerId`,
      title           : `Партнёр`,
      Component       : Select,
      showSearch      : true,
      optionFilterProp: `label`,
      optionsRender   : v => `${v.name || ` - `} (${v.id})`,
      testAttribute   : TEST_ATTRIBUTES.DICTIONARY_PARTNER_RATE_PARTNER_FIELD,
    },
  },
  taskStatus: {
    campaign: {
      key             : `campaignId`,
      title           : `Кампания`,
      // Component       : props => (_.isNil(props.value) ? <Select {...props} /> : <Select {...props} disabled />),
      Component       : props => <Select {...props} />,
      showSearch      : true,
      optionFilterProp: `label`,
      optionsRender   : v => v.description || v.name,
    },
    taskStatus: {
      key      : `possibleNextStatusIds`,
      title    : `Следующие возможные статусы`,
      Component: props => (
        <Select
          {...props}
          allowClear
          mode='multiple'
          value={props.value || undefined}
        />
      ),
      getOptions: ({ itemEditing, sourceItems: { taskStatus } }) => _.map(
        _.filter(taskStatus, { campaignId: itemEditing.campaignId }),
        ({ description, id, name }) => ({ value: id, label: description || name }),
      ),
      optionsRender: v => (v.name),
    },
  },
  trigger: {
    triggerBlock: {
      key             : `triggerBlockId`,
      title           : `Блок`,
      Component       : Select,
      showSearch      : true,
      optionFilterProp: `label`,
      optionsRender   : v => v.description || v.name || `-`,
    },
  },
  verificationResult: {
    verificationResultType: {
      key             : `verificationResultTypeId`,
      title           : `Список`,
      Component       : Select,
      showSearch      : true,
      optionFilterProp: `label`,
      optionsRender   : v => v.description || v.name || `-`,
    },
  },
  verificationResultType: {
    verificationStatus: {
      key      : `saleVerificationStatusIds`,
      title    : `Показывать в продажах при переходе в статусы`,
      Component: props => (
        <Select
          {...props}
          allowClear
          mode='multiple'
          value={props.value || undefined}
        />
      ),
      getOptions: ({ sourceItems: { verificationStatus } }) => _.map(
        verificationStatus,
        ({ description, id, name }) => ({ value: id, label: description || name }),
      ),
      optionsRender: v => (v.name),
    },
  },
};

export const CUSTOM_DATA_BY_ENTITY = {
  campaign: {
    checkIsLoaded: state => !_.isEmpty(getRolesSelector(state)),
    getFormFields: state => ([
      ROLE_FIELD(`roleReadId`, `Роль с правом чтения`, state),
      ROLE_FIELD(`roleWriteId`, `Роль с правом записи`, state),
      ROLE_FIELD(`roleBatchCloseId`, `Роль с правом массового удаления`, state),
      ROLE_FIELD(`roleBatchLoadId`, `Роль с правом массовой загрузки`, state),
      ROLE_FIELD(`roleAdminId`, `Роль администратора кампании`, state),
    ]),
    enrichItems: (state, items) => _.map(
      items,
      item => ({
        ...item,
        roleAdmin     : _.find(getRolesSelector(state), { id: item.roleAdminId }),
        roleBatchClose: _.find(getRolesSelector(state), { id: item.roleBatchCloseId }),
        roleBatchLoad : _.find(getRolesSelector(state), { id: item.roleBatchLoadId }),
        roleRead      : _.find(getRolesSelector(state), { id: item.roleReadId }),
        roleWrite     : _.find(getRolesSelector(state), { id: item.roleWriteId }),
      }),
    ),
    load: dispatch => dispatch(getRolesAction({ force: true })),
  },
  documentAlias: {
    checkIsLoaded: state => !_.isEmpty(getDocumentTypesSelector(state)),
    getFormFields: () => ([]),
    load         : dispatch => dispatch(getDictionary({ entity: `documentType` })),
  },
  documentGroup: {
    checkIsLoaded: state => !_.isEmpty(getDocumentTypesSelector(state)),
    getFormFields: () => ([]),
    load         : dispatch => dispatch(getDictionary({ entity: `documentType` })),
  },
  documentPackage: {
    checkIsLoaded: state => !_.isEmpty(getDocumentTypesSelector(state)),
    getFormFields: () => ([]),
    load         : dispatch => dispatch(getDictionary({ entity: `documentType` })),
  },
  taskStatus: {
    checkIsLoaded: () => true,
    enrichItems  : (state, items) => _.map(
      items,
      item => {
        const operator = getOperatorSelector(state, item.operatorId);
        if (!operator) return item;
        return {
          ...item,
          operatorFullName: operator.user?.fullName,
        };
      },
    ),
    getFormFields: state => ([
      {
        key             : `operatorId`,
        title           : `Ответственный`,
        Component       : Select,
        showSearch      : true,
        optionFilterProp: `label`,
        options         : getTaskStatusOwnersSelector(state),
        optionsRender   : value => _.get(value, `fullName`),
      },
    ]),
    load: dispatch => dispatch(loadOperators()),
  },
};

export const COLUMNS_BY_ENTITY = {
  appealSubject      : [COLOR_COLUMN],
  applicationFallback: [BOOL_COLUMN(`isActive`, `Активна`)],
  arbitrationCourt   : [
    ID_COLUMN,
    { key: `name`, title: `Наименование`, width: 150 },
    { key: `address`, title: `Адрес`, width: 200 },
    { key: `phoneNumberFax`, title: `Факс`, width: 100 },
    { key: `email`, title: `Email`, width: 120 },
    { key: `webAddress`, title: `Сайт`, width: 150 },
    { key: `chairman`, title: `Председатель`, width: 150 },
  ],
  assignee: [
    { key: `name`, title: `Название`, width: 200 },
    { key: `description`, title: `Описание` },
  ],
  auctionStatus: [
    ID_COLUMN,
    { key: `status`, title: `Статус`, width: 150 },
  ],
  biddingStatus: [
    { key: `name`, title: `Название`, width: 200 },
  ],
  carServiceTitle: [
    BOOL_COLUMN(`hidden`, `Скрывать поле`),
    BOOL_COLUMN(`isObjectArray`, `Является массивом объектов`),
    BOOL_COLUMN(`isStringArray`, `Является массивом строк`),
  ],
  campaign: [
    EXTERNAL_ID_COLUMN,
    { key: `block`, title: `Блок` },
    ROLE_COLUMN(`roleRead`, `Роль на чтение`),
    ROLE_COLUMN(`roleWrite`, `Роль на запись`),
    ROLE_COLUMN(`roleBatchClose`, `Роль массового закрытия`),
    ROLE_COLUMN(`roleBatchLoad`, `Роль загрузки XLS`),
    ROLE_COLUMN(`roleAdmin`, `Роль админа`),
  ],
  campaignType: [
    BOOL_COLUMN(`isService`, `Сервисный`),
    EXTERNAL_ID_COLUMN,
    {
      dataIndex: `campaign`,
      key      : `campaignId`,
      title    : `Кампания`,
      render   : campaign => {
        if (!campaign) return `-`;
        return campaign.description || campaign.name;
      },
    },
    {
      dataIndex: `communicationType`,
      key      : `communicationTypeId`,
      title    : `Тип взаимодействия`,
      render   : communicationType => {
        if (!communicationType || _.isEmpty(communicationType)) return `-`;
        return (
          <DefaultTag
            color={communicationType.color}
          >{communicationType.description || communicationType.name}
          </DefaultTag>
        );
      },
    },
  ],
  clientCharacteristic: [COLOR_COLUMN],
  clientCondition     : [
    ID_COLUMN,
    { key: `name`, title: `Наименование` },
    { key: `description`, title: `Описание` },
  ],
  collectionPromiseStatus: [
    BOOL_COLUMN(`isOpen`, `Открытый статус`),
    BOOL_COLUMN(`isTerminal`, `Терминальный статус`),
    COLOR_COLUMN,
  ],
  collectionService    : [COLOR_COLUMN],
  communicationFeedback: [COLOR_COLUMN],
  communicationType    : [COLOR_COLUMN],
  contractCondition    : [
    ID_COLUMN,
    { key: `name`, title: `Наименование` },
    { key: `description`, title: `Описание` },
  ],
  creditHistoryField: [FORMAT_COLUMN],
  dealership        : [
    ID_COLUMN,
    { key: `name`, title: `Наименование`, width: 150 },
    { key: `address`, title: `Адрес`, width: 150 },
    { key: `geoLat`, title: `Широта` },
    { key: `geoLon`, title: `Долгота` },
    {
      key   : `utm`,
      title : `UTM метка`,
      render: text => (
        <DefaultTooltip title={text}>
          <Button
            icon={<EnvironmentOutlined />}
            onClick={() => copyToClipboard(text)}
            style={{ marginLeft: `8px` }}
            type='primary'
          />
        </DefaultTooltip>
      ),
    },
    {
      dataIndex: `partner`,
      key      : `partnerId`,
      title    : `Партнёр`,
      render   : partner => _.get(partner, `name`, `-`),
    },
    {
      dataIndex: `city`,
      key      : `cityId`,
      title    : `Город`,
      render   : city => _.get(city, `cityName`, `-`),
    },
    BOOL_COLUMN(`hiddenFlg`, `Скрытый`),
  ],
  documentAlias: [
    {
      dataIndex: `documentType`,
      key      : `typeId`,
      title    : `Тип документа`,
      render   : docType => <DefaultTag key={docType?.id}>{docType?.name} ({docType?.id})</DefaultTag>,
      width    : 300,
    },
    BOOL_COLUMN(`isVersioned`, `Версионный`),
  ],
  documentGroup: [
    {
      dataIndex: `documentType`,
      key      : `typeIds`,
      title    : `Типы документов`,
      render   : values => _.map(values, (v, index) => (
        <DefaultTag key={`${v?.id}_${index}`}>{v?.name} ({v?.id})</DefaultTag>
      )),
      width: 300,
    },
    {
      dataIndex: `order`,
      key      : `order`,
      title    : `Порядок`,
      width    : 100,
    },
    BOOL_COLUMN(`isRecaptured`, `Переснятие`),
    BOOL_COLUMN(`isHidden`, `Скрытый`),
  ],
  documentPackage: [
    { key: `process`, title: `Процесс`, width: 150 },
    BOOL_COLUMN(`isAdditional`, `Дополнительный`),
    BOOL_COLUMN(`isAdditionalAvailable`, `Доступен доп`),
    {
      dataIndex: `documentType`,
      key      : `typeIds`,
      title    : `Типы документов`,
      render   : values => _.map(values, (v, index) => (
        <DefaultTag key={`${v?.id}_${index}`}>{v?.name} ({v?.id})</DefaultTag>
      )),
      width: 300,
    },
  ],
  documentTemplate: [
    {
      ...ID_COLUMN,
      sorter: getSort(`id`),
    },
    { key: `name`, title: `Название шаблона` },
    { key: `description`, title: `Описание шаблона` },
    {
      dataIndex: `mediaType`,
      key      : `mediaType`,
      title    : `Тип файла`,
    },
  ],
  enforcementProceedingStatus: [COLOR_COLUMN],
  hmParking: [
    ID_COLUMN,
    { key: `name`, title: `Название`, width: 140 },
    { key: `description`, title: `Описание`, width: 140 },
    {
      dataIndex: `region`,
      key      : `regionId`,
      title    : `Регион`,
      render   : region => _.get(region, `name`, `-`),
      width    : 140,
    },
    { key: `address`, title: `Адрес`, width: 140 },
    { key: `geoLat`, title: `Широта`, width: 80 },
    { key: `geoLon`, title: `Долгота`, width: 80 },
    { key: `phone`, title: `Телефон`, width: 140 },
    { key: `contractor`, title: `Контактное лицо`, width: 140 },
    { key: `parkingType`, title: `Тип`, width: 140 },
    { key: `openingHours`, title: `Режим работы`, width: 200 },
    { key: `price`, title: `Стоимость`, width: 100 },
  ],
  hmSearchPartner: [
    ID_COLUMN,
    { key: `name`, title: `Название`, width: 140 },
    { key: `description`, title: `Описание`, width: 140 },
    {
      dataIndex: `region`,
      key      : `regionId`,
      title    : `Регион`,
      render   : region => _.get(region, `name`, `-`),
      width    : 140,
    },
    { key: `address`, title: `Адрес`, width: 140 },
    { key: `phone`, title: `Телефон`, width: 140 },
    { key: `email`, title: `Email`, width: 140 },
    { key: `priority`, title: `Приоритет`, width: 80 },
  ],
  info: [
    { key: `group`, title: `Группа`, width: 140 },
    { key: `url`, title: `Ссылка`, width: 140 },
    { key: `login`, title: `Логин`, width: 100 },
    { key: `password`, title: `Пароль`, width: 80 },
  ],
  leadStatus            : [COLOR_COLUMN],
  marketingBlacklistType: [
    ID_COLUMN,
    { key: `name`, title: `Название`, width: 140 },
    { key: `description`, title: `Описание` },
    { key: `term`, title: `Срок, д`, width: 180 },
  ],
  marketingOfferType: [
    ID_COLUMN,
    { key: `name`, title: `Тип`, width: 140 },
    BOOL_COLUMN(`appFlg`, `Флаг анкета`),
    BOOL_COLUMN(`lkFlg`, `Флаг ЛК`),
  ],
  mobileConfig: [
    { key: `buildNumber`, title: `Номер сборки`, width: 100 },
    BOOL_COLUMN(`prod`, `PROD`),
    BOOL_COLUMN(`demo`, `DEMO`),
    JSON_COLUMN(`parameters`, `Конфигурация`),
  ],
  mobileConfigSchema: [
    JSON_COLUMN(`schema`, `JSON schema`),
  ],
  notificationList: [BOOL_COLUMN(`isPersonal`, `Индивидуальный`)],
  partner         : [
    { ...ID_COLUMN, testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_ID_COLUMN },
    { key: `name`, title: `Название`, testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_NAME_COLUMN },
  ],
  partnerRate: [
    ID_COLUMN,
    {
      dataIndex: `partner`,
      key      : `partnerId`,
      title    : `Партнёр`,
      render   : partner => partner?.name || partner?.id || `-`,
      width    : 200,
    },
    { key: `minConditionRate`, title: `Минимальная ставка`, width: 150, align: `center` },
    { key: `maxConditionRate`, title: `Максимальная ставка`, width: 150, align: `center` },
    { key: `price`, title: `Сумма`, width: 150, align: `right`, render: prettifyAmount },
    DATE_COLUMN(`startConditionDtm`, `Дата начала`, { width: 200 }),
    DATE_COLUMN(`endConditionDtm`, `Дата окончания`, { width: 200 }),
  ],
  //
  partnerRequisite: [
    ID_COLUMN,
    { key: `name`, title: `Наименование ЮЛ`, width: 150 },
    { key: `description`, title: `Описание`, width: 150 },
    { key: `inn`, title: `ИНН` },
    { key: `account`, title: `Номер счета` },
    { key: `bik`, title: `БИК банка` },
    { key: `bankName`, title: `Название банка`, width: 150 },
    { key: `corrAccount`, title: `Кор. счёт банка` },
    { key: `absPaymentFormId`, title: `Идентификатор АБС`, width: 285 },
    {
      key   : `requisiteType`,
      render: v => _.get(_.find(REQUISITE_TYPES, { value: v }), `label`, `?`),
      title : `Тип`,
      width : 150,
    },
  ],
  phoneType   : [],
  pledgeStatus: [ // REMOVABLE
    ID_COLUMN,
    { key: `status`, title: `Статус`, width: 150 },
  ],
  proposalReaction       : [COLOR_COLUMN],
  saleAdditionalInfoField: [FORMAT_COLUMN],
  saleFeedbackStatus     : [
    BOOL_COLUMN(`isOpen`, `Открытый статус`),
    BOOL_COLUMN(`isTerminal`, `Терминальный статус`),
    COLOR_COLUMN,
  ],
  smsTemplate: [
    ID_COLUMN,
    { key: `description`, title: `Описание`, width: 300 },
    { key: `text`, title: `Текст` },
    BOOL_COLUMN(`activeFlg`, `Активен`),
  ],
  taskStatus: [
    {
      dataIndex: `campaign`,
      key      : `campaignId`,
      title    : `Кампания`,
      render   : (campaign, { campaignId }) => {
        if (!campaignId) return `-`;
        if (!campaign) return `${campaignId} (кампания с таким id не найдена)`;
        return campaign.description || campaign.name;
      },
      sorter: getSort(`campaign.name`, sortAlphabetically),
      width : 100,
    },
    {
      dataIndex: `operatorFullName`,
      key      : `operatorFullName`,
      title    : `Ответственный`,
      width    : 140,
    },
    COLOR_COLUMN,
    BOOL_COLUMN(`isOpen`, `Открытый статус`),
    BOOL_COLUMN(`isTerminal`, `Терминальный статус`),
    {
      dataIndex: `taskStatus`,
      key      : `possibleNextStatusIds`,
      title    : `Следующие возможные статусы`,
      render   : values => _.map(values, ({ color, description, id, name }, index) => (
        <DefaultTag color={color} key={`${id}_${index}`}>{description || name}</DefaultTag>
      )),
      width: 140,
    },
  ],
  trigger: [
    {
      dataIndex: `triggerBlock`,
      key      : `triggerBlockId`,
      title    : `Блок`,
      render   : triggerBlock => {
        if (!triggerBlock || _.isEmpty(triggerBlock)) return `-`;
        return (triggerBlock.description || triggerBlock.name);
      },
    },
  ],
  triggerBlock: [],
  vectorTitle : [
    { key: `note`, title: `Примечание` },
    BOOL_COLUMN(`hidden`, `Скрывать поле`),
    BOOL_COLUMN(`isTitle`, `Заголовок блока`),
    {
      key  : `blockName`,
      title: `Блок данных`,
      align: `center`,
      width: 100,
    },
    {
      key  : `dataSource`,
      title: `Источник данных`,
      align: `center`,
      width: 120,
    },
    {
      key  : `fieldPath`,
      title: `Путь к полю`,
      align: `center`,
      width: 150,
    },
    {
      key  : `format`,
      title: `Формат значения поля`,
      align: `center`,
      width: 130,
    },
    {
      key  : `blockOrder`,
      title: `Порядок поля в блоке`,
      align: `center`,
      width: 100,
    },
  ],
  verificationLimit: [
    {
      key  : `productId`,
      title: `Продукт`,
      align: `center`,
      width: 80,
    },
    {
      key  : `segment`,
      title: `Сегмент`,
      align: `center`,
      width: 80,
    },
    {
      key  : `min`,
      title: `Минимальное значение`,
      align: `center`,
      width: 260,
    },
    {
      key  : `max`,
      title: `Максимальное значение`,
      align: `center`,
      width: 260,
    },
  ],
  verificationResult: [
    {
      key  : `weight`,
      title: `Вес`,
      align: `center`,
      width: 60,
    },
    {
      dataIndex: `verificationResultType`,
      key      : `verificationResultTypeId`,
      title    : `Список`,
      render   : verificationResultType => {
        if (!verificationResultType || _.isEmpty(verificationResultType)) return `-`;
        return (verificationResultType.description || verificationResultType.name);
      },
    },
  ],
  verificationResultType: [
    {
      key   : `productId`,
      title : `Продукты`,
      align : `center`,
      render: v => (_.isNil(v) ? `Все` : _.map(v, (i, key) => <DefaultTag key={key}>{i}</DefaultTag>)),
      width : 200,
    },
    {
      key   : `scriptIds`,
      title : `Идентификаторы скриптов`,
      align : `center`,
      render: v => (_.isNil(v) ? `Все` : _.map(v, (i, key) => <DefaultTag key={key}>{i}</DefaultTag>)),
      width : 200,
    },
    {
      key   : `fieldType`,
      render: v => _.get(_.find(FIELD_TYPES, { value: v }), `label`, `?`),
      title : `Тип полей`,
      width : 150,
    },
    {
      key  : `order`,
      title: `Порядок в общем списке`,
      width: 200,
    },
    {
      key  : `documentTypeName`,
      title: `Название типа документа`,
      width: 200,
    },
    BOOL_COLUMN(`fileFlg`, `Загрузка файла`),
    BOOL_COLUMN(`isRequiredSale`, `Обязательный в продажах при смене статуса`, { width: 180 }),
    {
      dataIndex: `verificationStatus`,
      key      : `saleVerificationStatusIds`,
      title    : `Показывать в продажах при переходе в статусы`,
      render   : values => _.map(values, ({ color, description, id, name }, index) => (
        <DefaultTag color={color} key={`${id}_${index}`}>{description || name}</DefaultTag>
      )),
      width: 200,
    },
  ],
  verificationStatus: [COLOR_COLUMN],
};

export const FIELDS_COMMON = (itemNames = [], itemEditing, entity) => [
  {
    key          : `name`,
    title        : `Название`,
    Component    : Input,
    initialValue : ``,
    required     : true,
    testAttribute: TEST_ATTRIBUTES.DICTIONARY_COMMON_FIELD_NAME,
    rules        : [
      REQUIRED,
      {
        validator: (pass, value) => {
          if (
            _.includes(ENTITIES_WITH_NOT_UNIQUE_NAMES, entity)
            || _.get(itemEditing, `name`) === value
            || !_.includes(itemNames, value)
          ) return Promise.resolve();
          return Promise.reject(new Error(`Такое название уже есть, придумайте другое`));
        },
      },
    ],
  },
  {
    key          : `description`,
    title        : `Описание`,
    Component    : Input.TextArea,
    initialValue : ``,
    testAttribute: TEST_ATTRIBUTES.DICTIONARY_COMMON_FIELD_DESCRIPTION,
  },
];

export const FIELDS_BY_ENTITY = {
  appealSubject      : [COLOR_FIELD],
  applicationFallback: [BOOL_FIELD(`isActive`, `Активна`)],
  arbitrationCourt   : [
    {
      key     : `name`,
      title   : `Наименование`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key  : `address`,
      title: `Адрес`,
    },
    {
      key  : `phoneNumberFax`,
      title: `Факс`,
    },
    {
      key  : `email`,
      title: `Email`,
    },
    {
      key  : `webAddress`,
      title: `Сайт`,
    },
    {
      key  : `chairman`,
      title: `Председатель`,
    },
  ],
  assignee: [
    {
      key          : `name`,
      title        : `Название`,
      required     : true,
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_CESSIONARY_NAME_FIELD,
      rules        : [
        REQUIRED,
        {
          validator: (pass, value) => {
            if (!_.trim(value)) return Promise.reject(new Error(`Не может состоять из одних пробелов`));
            return Promise.resolve();
          },
        },
      ],
    },
    {
      key          : `description`,
      title        : `Описание`,
      Component    : Input.TextArea,
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_CESSIONARY_DESCRIPTION_FIELD,
      rules        : [
        {
          validator: (pass, value) => {
            if (value && !_.trim(value)) return Promise.reject(new Error(`Не может состоять из одних пробелов`));
            return Promise.resolve();
          },
        },
      ],
    },
  ],
  auctionStatus: [
    {
      key     : `status`,
      title   : `Статус`,
      required: true,
      rules   : [REQUIRED],
    },
  ],
  biddingStatus: [
    {
      key     : `name`,
      title   : `Название`,
      required: true,
      rules   : [
        REQUIRED,
        {
          validator: (pass, value) => {
            if (!_.trim(value)) return Promise.reject(new Error(`Не может состоять из одних пробелов`));
            return Promise.resolve();
          },
        },
      ],
    },
  ],
  carServiceTitle: [
    BOOL_FIELD(`hidden`, `Скрывать`),
    BOOL_FIELD(`isObjectArray`, `Массив объектов`),
    BOOL_FIELD(`isStringArray`, `Массив строк`),
    {
      key      : `format`,
      title    : `Формат значения`,
      Component: Select,
      options  : [
        { label: `Строка`, value: `string` },
        { label: `Сумма`, value: `amount` },
        { label: `Логический`, value: `boolean` },
        { label: `Дата и время`, value: `datetime` },
      ],
    },
  ],
  campaign: [
    { key: `externalId`, title: `Внешний id` },
    { key: `block`, title: `Блок` },
  ],
  campaignType: [
    { key: `externalId`, title: `Внешний id` },
    BOOL_FIELD(`isService`, `Сервисный`),
  ],
  clientCharacteristic: [COLOR_FIELD],
  clientCondition     : [
    {
      key  : `name`,
      title: `Наименование`,
    },
    {
      key  : `description`,
      title: `Описание`,
    },
  ],
  communicationFeedback  : [COLOR_FIELD],
  communicationType      : [COLOR_FIELD],
  collectionPromiseStatus: [
    BOOL_FIELD(`isOpen`, `Открытый статус`),
    BOOL_FIELD(`isTerminal`, `Терминальный статус`),
    COLOR_FIELD,
  ],
  collectionService: [
    BOOL_FIELD(`isOpen`, `Открытый статус`),
    BOOL_FIELD(`isTerminal`, `Терминальный статус`),
    COLOR_FIELD,
  ],
  contractCondition: [
    {
      key  : `name`,
      title: `Наименование`,
    },
    {
      key  : `description`,
      title: `Описание`,
    },
  ],
  creditHistoryField: [FORMAT_FIELD],
  dealership        : [
    {
      key          : `name`,
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_ADD_NAME_FIELD,
      title        : `Наименование`,
      Component    : Input.TextArea,
      required     : true,
      rules        : [REQUIRED],
    },
    {
      key          : `address`,
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_ADD_ADDRESS_FIELD,
      title        : `Адрес`,
      Component    : Input.TextArea,
      required     : true,
      rules        : [REQUIRED],
    },
    {
      key          : `geoLat`,
      title        : `Широта`,
      required     : true,
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_ADD_LATITUDE_FIELD,
    },
    {
      key          : `geoLon`,
      title        : `Долгота`,
      required     : true,
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_ADD_LONGITUDE_FIELD,
    },
    {
      key          : `utm`,
      title        : `UTM метка`,
      required     : true,
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_ADD_UTM_FIELD,
    },
    BOOL_FIELD(
      `hiddenFlg`,
      `Скрытый`,
      { testAttribute: TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_ADD_HIDDEN_FIELD },
    ),
  ],
  documentAlias: [
    BOOL_FIELD(`isVersioned`, `Версионный`),
  ],
  documentGroup: [
    {
      key      : `order`,
      title    : `Порядок сортировки`,
      Component: InputNumber,
    },
    BOOL_FIELD(`isRecaptured`, `Переснятие`),
    BOOL_FIELD(`isHidden`, `Скрытый`),
  ],
  documentPackage: [
    {
      key     : `process`,
      title   : `Процесс`,
      required: true,
      rules   : [REQUIRED],
    },
    BOOL_FIELD(`isAdditional`, `Дополнительный`),
    BOOL_FIELD(`isAdditionalAvailable`, `Доступен доп`),
  ],
  enforcementProceedingStatus: [COLOR_FIELD],
  hmParking: [
    {
      key     : `name`,
      title   : `Название`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key      : `description`,
      title    : `Описание`,
      Component: Input.TextArea,
    },
    {
      key     : `address`,
      title   : `Адрес`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key     : `geoLat`,
      title   : `Широта`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key     : `geoLon`,
      title   : `Долгота`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key     : `phone`,
      title   : `Телефон`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key     : `contractor`,
      title   : `Контактное лицо`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key     : `parkingType`,
      title   : `Тип`,
      required: true,
      rules   : [
        REQUIRED,
        {
          validator: (pass, value) => {
            if (_.isInteger(_.toNumber(value))) return Promise.resolve();
            return Promise.reject(new Error(`Тип должен быть целым числом`));
          },
        },
      ],
    },
    {
      key     : `openingHours`,
      title   : `Режим работы`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key     : `price`,
      title   : `Стоимость`,
      required: true,
      rules   : [REQUIRED],
    },
  ],
  hmSearchPartner: [
    {
      key     : `name`,
      title   : `Название`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key      : `description`,
      title    : `Описание`,
      Component: Input.TextArea,
    },
    {
      key     : `address`,
      title   : `Адрес`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key     : `phone`,
      title   : `Телефон`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key     : `email`,
      title   : `Email`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key     : `priority`,
      title   : `Приоритет`,
      required: true,
      rules   : [REQUIRED],
    },
  ],
  info: [
    {
      key  : `group`,
      title: `Группа, к которой принадлежит запись`,
    },
    {
      key  : `url`,
      title: `Ссылка на ресурс`,
    },
    {
      key  : `login`,
      title: `Логин`,
    },
    {
      key  : `password`,
      title: `Пароль`,
    },
  ],
  leadStatus            : [COLOR_FIELD],
  marketingBlacklistType: [
    {
      key     : `name`,
      title   : `Название`,
      required: true,
      rules   : [REQUIRED],
    },
    {
      key      : `description`,
      title    : `Описание`,
      Component: Input.TextArea,
    },
    {
      key      : `term`,
      title    : `Срок, дней`,
      Component: InputNumber,
    },
  ],
  marketingOfferType: [
    {
      key     : `name`,
      title   : `Тип`,
      required: true,
      rules   : [REQUIRED],
    },
    BOOL_FIELD(`appFlg`, `Флаг анкета`),
    BOOL_FIELD(`lkFlg`, `Флаг ЛК`),
  ],
  mobileConfig: ({ itemEditing, items }) => [
    {
      key      : `buildNumber`,
      title    : `Номер сборки`,
      Component: InputNumber,
      required : true,
      rules    : [
        {
          validator: (pass, v) => {
            if (_.find(items, ({ buildNumber, id }) => buildNumber === v && id !== itemEditing?.id)) {
              return Promise.reject(new Error(`Такой номер сборки уже используется`));
            }
            return Promise.resolve();
          },
        },
        REQUIRED,
      ],
    },
    BOOL_FIELD(`prod`, `PROD`),
    BOOL_FIELD(`demo`, `DEMO`),
    JSON_FIELD(`parameters`, `Конфигурация`, {
      required: true,
      rules   : [
        {
          validator: (pass, v) => {
            try {
              if (!itemEditing?.mobileConfigSchema?.schema) return Promise.resolve();
              const validate = ajv.compile(itemEditing.mobileConfigSchema.schema);
              const isValid = validate(_.isObject(v) ? v : JSON.parse(v));
              if (isValid) return Promise.resolve();
              return Promise.reject(validate.errors);
            } catch (e) {
              return Promise.reject(e);
            }
          },
        },
        JSON_VALUE,
        REQUIRED,
      ],
    }),
  ],
  mobileConfigSchema: [
    JSON_FIELD(`schema`, `Схема`, {
      required: true,
      rules   : [
        {
          validator: (pass, _v) => {
            try {
              const v = _.isObject(_v) ? _v : JSON.parse(_v);
              const isValid = ajv.validateSchema(v);
              if (isValid) return Promise.resolve();
              return Promise.reject(ajv.errors);
            } catch (e) {
              return Promise.reject(e);
            }
          },
        },
        JSON_VALUE,
        REQUIRED,
      ] }),
  ],
  notificationList: [BOOL_FIELD(
    `isPersonal`,
    `Индивидуальный`,
    { testAttribute: TEST_ATTRIBUTES.DICTIONARY_NOTIFICATION_LIST_INDIVIDUAL_FIELD },
  )],
  partner: [{
    key          : `name`,
    title        : `Название`,
    required     : true,
    rules        : [REQUIRED],
    testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_ADD_NAME_FIELD,
  }],
  partnerRate: [
    {
      key          : `minConditionRate`,
      title        : `Минимальная ставка`,
      Component    : InputNumber,
      required     : true,
      rules        : [REQUIRED],
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_RATE_MIN_RATE_FIELD,
    },
    {
      key          : `maxConditionRate`,
      title        : `Максимальная ставка`,
      Component    : InputNumber,
      required     : true,
      rules        : [REQUIRED],
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_RATE_MAX_RATE_FIELD,
    },
    {
      key          : `price`,
      title        : `Сумма`,
      Component    : InputNumber,
      required     : true,
      rules        : [REQUIRED],
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_RATE_SUM_FIELD,
    },
    DATE_FIELD(
      `startConditionDtm`,
      `Дата начала`,
      { testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_RATE_DATE_START_FIELD },
    ),
    DATE_FIELD(
      `endConditionDtm`,
      `Дата окончания`,
      { testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_RATE_DATE_END_FIELD },
    ),
  ],
  partnerRequisite: ({ itemEditing, userRoles }) => _.compact([
    {
      key          : `name`,
      title        : `Наименование ЮЛ`,
      Component    : Input.TextArea,
      required     : true,
      rules        : [REQUIRED],
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_ADD_COMPANY_NAME_FIELD,
    },
    {
      key          : `description`,
      title        : `Описание`,
      Component    : Input.TextArea,
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_ADD_DESCRIPTION_FIELD,
    },
    {
      key          : `inn`,
      title        : `ИНН`,
      required     : true,
      rules        : [REQUIRED],
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_ADD_INN_FIELD,
    },
    {
      key          : `account`,
      title        : `Номер счета`,
      required     : true,
      rules        : [REQUIRED],
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_ADD_ACCOUNT_NUMBER_FIELD,
    },
    {
      key          : `bik`,
      title        : `БИК банка`,
      required     : true,
      rules        : [REQUIRED],
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_ADD_BIK_FIELD,
    },
    {
      key          : `bankName`,
      title        : `Название банка`,
      required     : true,
      rules        : [REQUIRED],
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_ADD_BANK_NAME_FIELD,
    },
    {
      key          : `corrAccount`,
      title        : `Кор. счёт банка`,
      required     : true,
      rules        : [REQUIRED],
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_ADD_CORRESPONDENT_ACCOUNT_FIELD,
    },
    ((_.isEmpty(itemEditing) || hasIntersection(userRoles, [`superAdmin`, `partnerRequisiteAbsEditor`]))
      ? {
        key          : `absPaymentFormId`,
        title        : `Идентификатор АБС`,
        rules        : [UUID],
        testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_ADD_ABS_ID_FIELD,
      }
      : null
    ),
    {
      key          : `requisiteType`,
      title        : `Тип`,
      Component    : Select,
      options      : REQUISITE_TYPES,
      optionsRender: value => _.get(_.find(REQUISITE_TYPES, { value }), `label`, `?`),
      required     : true,
      rules        : [REQUIRED],
      testAttribute: TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_ADD_TYPE_FIELD,
    },
  ]),
  phoneType   : [],
  pledgeStatus: [
    {
      key     : `status`,
      title   : `Статус`,
      required: true,
      rules   : [REQUIRED],
    },
  ],
  proposalReaction       : [COLOR_FIELD],
  saleAdditionalInfoField: [FORMAT_FIELD],
  saleFeedbackStatus     : [
    BOOL_FIELD(`isOpen`, `Открытый статус`),
    BOOL_FIELD(`isTerminal`, `Терминальный статус`),
    COLOR_FIELD,
  ],
  smsTemplate: [
    {
      key  : `description`,
      title: `Описание`,
    },
    {
      key      : `text`,
      title    : `Текст шаблона`,
      Component: Input.TextArea,
      rows     : 9,
    },
    BOOL_FIELD(`activeFlg`, `Активен`),
  ],
  taskStatus: [
    BOOL_FIELD(`isOpen`, `Открытый статус`),
    BOOL_FIELD(`isTerminal`, `Терминальный статус`),
    COLOR_FIELD,
  ],
  trigger     : [],
  triggerBlock: [],
  vectorTitle : [
    { key: `note`, title: `Примечание` },
    BOOL_FIELD(`hidden`, `Скрывать`),
    BOOL_FIELD(`isTitle`, `Заголовок блока`),
    {
      key      : `blockName`,
      title    : `Блок данных`,
      Component: Select,
      options  : [
        { label: `Фрод индикаторы`, value: `fraud` },
        { label: `БКИ`, value: `bki` },
        { label: `Авто`, value: `auto` },
        { label: `Внутренние данные`, value: `internal` },
        { label: `Фрод индикаторы: устройство пользователя`, value: `fraudDevice` },
      ],
    },
    {
      key      : `dataSource`,
      title    : `Источник данных`,
      Component: Select,
      options  : [
        { label: `Вектор`, value: `vector` },
        { label: `Авто`, value: `auto` },
      ],
    },
    { key: `fieldPath`, title: `Путь к полю (например, applicationCalc.fmsGoodFlag)` },
    {
      key  : `blockOrder`,
      title: `Порядок поля в блоке`,
      rules: [
        {
          validator: (pass, value) => {
            if (!value || (_.isInteger(_.toNumber(value)) && _.toNumber(value) >= 1)) return Promise.resolve();
            return Promise.reject(new Error(`Порядок должен быть натуральным числом`));
          },
        },
      ],
    },
    {
      key      : `format`,
      title    : `Формат значения`,
      Component: Select,
      options  : [
        { label: `Строка`, value: `string` },
        { label: `Сумма`, value: `amount` },
        { label: `Логический`, value: `boolean` },
      ],
    },
  ],
  verificationLimit: [
    { key: `productId`, title: `Продукт`, Component: InputNumber },
    { key: `segment`, title: `Сегмент`, Component: InputNumber },
    { key: `min`, title: `Минимальное значение`, Component: InputNumber },
    { key: `max`, title: `Максимальное значение`, Component: InputNumber },
  ],
  verificationResult    : [{ key: `weight`, title: `Вес`, Component: InputNumber }],
  verificationResultType: [
    { key: `productId`, title: `Продукты (productId через запятую, без пробелов)` },
    { key: `scriptIds`, title: `Скрипты (scriptId через запятую, без пробелов)` },
    {
      key          : `fieldType`,
      title        : `Тип полей в списке`,
      Component    : Select,
      options      : FIELD_TYPES,
      optionsRender: value => _.get(_.find(REQUISITE_TYPES, { value }), `label`, `?`),
      required     : true,
      rules        : [REQUIRED],
    },
    { key: `order`, title: `Порядок сортировки в общем списке`, Component: InputNumber },
    { key: `documentTypeName`, title: `Название типа документа` },
    BOOL_FIELD(`fileFlg`, `Загрузка файла`),
    BOOL_FIELD(`isRequiredSale`, `Обязательный в продажах при смене статуса`),
  ],
  verificationStatus: [
    {
      ...COLOR_FIELD,
      required: true,
      rules   : [REQUIRED],
    },
  ],
};

export const CELL_CONTROLS_BY_ENTITY = ({ canEdit, onUploadSingleClick }) => ({
  documentTemplate: [
    {
      icon   : <DownloadOutlined />,
      onClick: (dispatch, record) => dispatch(downloadTemplateAction({ name: record.name, templateId: record.id })),
      title  : `Скачать`,
      type   : `primary`,
    },
    {
      disabled: !canEdit,
      icon    : <UploadOutlined />,
      onClick : onUploadSingleClick,
      title   : canEdit ? `Обновить` : `Доступно для роли ${ROLE_NAMES.TEMPLATE_EDIT}`,
      type    : `default`,
    },
  ],
});

export const CUSTOM_CONTROLS_BY_ENTITY = ({
  canEdit,
  dispatch,
  selectedRowKeys,
  setUploadDocumentTemplateModalOpen,
}) => ({
  documentTemplate: [
    {
      isDisabled: _.isEmpty(selectedRowKeys),
      icon      : <DownloadOutlined />,
      onClick   : () => dispatch(downloadTemplatesAction({ templateIds: selectedRowKeys })),
      title     : `Скачать архив`,
    },
    {
      icon    : <UploadOutlined />,
      isHidden: !canEdit,
      onClick : () => setUploadDocumentTemplateModalOpen(true),
      title   : `Обновить шаблоны`,
    },
  ],
});

export const SELECTABLE_UNREMOVABLE_ENTITIES = [`documentTemplate`];
export const UNADDABLE_ENTITIES = [`documentTemplate`, `mobileConfigSchema`];
export const UNEDITABLE_ENTITIES = [`documentTemplate`, `partner`, `partnerRate`];
export const UNSEARCHABLE_ENTITIES = [`partnerRate`, `auctionStatus`, `pledgeStatus`];
export const UNREMOVABLE_ENTITIES = [
  `assignee`,
  `arbitrationCourt`,
  `biddingStatus`,
  `dealership`,
  `documentTemplate`,
  `marketingBlacklistType`,
  `mobileConfig`,
  `mobileConfigSchema`,
  `partner`,
  `partnerRate`,
  `smsTemplate`,
];
export const LINKABLE_ENTITIES = {
  dealership: {
    entity  : `partnerRequisite`,
    sourceId: `dealershipIds`,
    targetId: `requisiteIds`,
    title   : `Связать с реквизитами`,
  },
  partnerRequisite: {
    entity  : `dealership`,
    sourceId: `requisiteIds`,
    targetId: `dealershipIds`,
    title   : `Связать с салонами`,
  },
};
export const OMIT_COLUMNS_ENTITIES = {
  partnerRate: [`id`],
};

export const INACTIVE_VISIBLE_ENTITITES = [
  `smsTemplate`,
];

export const TEST_ATTRIBUTES_SEARCH_BY_ENTITY = {
  partner: {
    searchField  : TEST_ATTRIBUTES.DICTIONARY_PARTNER_SEARCH_FIELD,
    searchButton : TEST_ATTRIBUTES.DICTIONARY_PARTNER_SEARCH_BUTTON,
    searchRefresh: TEST_ATTRIBUTES.DICTIONARY_PARTNER_SEARCH_REFRESH_BUTTON,
    addButton    : TEST_ATTRIBUTES.DICTIONARY_PARTNER_ADD_BUTTON,
  },
  dealership: {
    searchField  : TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_SEARCH_FIELD,
    searchButton : TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_SEARCH_BUTTON,
    searchRefresh: TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_SEARCH_REFRESH_BUTTON,
    addButton    : TEST_ATTRIBUTES.DICTIONARY_DEALERSHIP_ADD_BUTTON,
  },
  partnerRequisite: {
    searchField  : TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_SEARCH_FIELD,
    searchButton : TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_SEARCH_BUTTON,
    searchRefresh: TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_SEARCH_REFRESH_BUTTON,
    addButton    : TEST_ATTRIBUTES.DICTIONARY_PARTNER_REQUISITE_ADD_BUTTON,
  },
  assignee: {
    searchField  : TEST_ATTRIBUTES.DICTIONARY_CESSIONARY_SEARCH_FIELD,
    searchButton : TEST_ATTRIBUTES.DICTIONARY_CESSIONARY_SEARCH_BUTTON,
    searchRefresh: TEST_ATTRIBUTES.DICTIONARY_CESSIONARY_SEARCH_REFRESH_BUTTON,
    addButton    : TEST_ATTRIBUTES.DICTIONARY_CESSIONARY_ADD_BUTTON,
  },
  partnerRate: {
    searchField  : TEST_ATTRIBUTES.DICTIONARY_PARTNER_RATE_SEARCH_FIELD,
    searchButton : TEST_ATTRIBUTES.DICTIONARY_PARTNER_RATE_SEARCH_BUTTON,
    searchRefresh: TEST_ATTRIBUTES.DICTIONARY_PARTNER_RATE_SEARCH_REFRESH_BUTTON,
    addButton    : TEST_ATTRIBUTES.DICTIONARY_PARTNER_RATE_ADD_BUTTON,
  },
  notificationList: {
    searchField  : TEST_ATTRIBUTES.DICTIONARY_NOTIFICATION_LIST_SEARCH_FIELD,
    searchButton : TEST_ATTRIBUTES.DICTIONARY_NOTIFICATION_LIST_SEARCH_BUTTON,
    searchRefresh: TEST_ATTRIBUTES.DICTIONARY_NOTIFICATION_LIST_SEARCH_REFRESH_BUTTON,
    addButton    : TEST_ATTRIBUTES.DICTIONARY_NOTIFICATION_LIST_ADD_BUTTON,
  },
};

export const EDITOR_ROLES_BY_ENTITY = {
  documentTemplate      : ROLE_NAMES.TEMPLATE_EDIT,
  verificationResult    : ROLE_NAMES.VERIFICATION_ADMIN,
  verificationResultType: ROLE_NAMES.VERIFICATION_ADMIN,
};
