import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { formatDate } from 'helper';

import { getLegalRegistries as getLegalRegistriesAction } from 'models/clients/actions';
import { getLegalRegistries as getLegalRegistriesSelector } from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';

import { Button as ButtonDefault } from 'antd';
import { DefaultTable } from 'components/DefaultTable/DefaultTable';
import LegalRegistryUpload from 'components/Modal/LegalRegistryUpload';

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Button = styled(ButtonDefault)`
  max-width: 180px;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  margin-bottom: 16px;
`;

const Table = styled(DefaultTable)`
  border: 1px solid ${palette.black30Color};
`;

interface IProps {
  loanId: string;
  personId: string;
}

const LegalRegistries: React.FC<IProps> = ({ loanId, personId }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const registries = useSelector(state => getLegalRegistriesSelector(state, personId));
  const [isDictionariesLoading, { registryType: registryTypes }] = useDictionaries([`registryType`]);

  const getRegistries = useCallback(() => {
    dispatch(getLegalRegistriesAction({ personId, query: { personId } }));
  }, [personId, dispatch, personId]);
  useEffect(() => {
    getRegistries()
  }, [getRegistries]);

  const mappedRegistries = _.map(registries, (r, index) => ({
    ...r,
    createDtm             : formatDate(r.createDtm, `dd.MM.yyyy`),
    externalRegistryDtm   : r.externalRegistryDtm ? formatDate(new Date(r.externalRegistryDtm)) : `-`,
    externalRegistryNumber: r.externalRegistryNumber || `-`,
    index                 : registries.length - index,
    registryTypeName      : _.find(registryTypes, { id: r.registryTypeId })?.description || `-`,
  }));

  return (
    <Wrap>
      <Button onClick={() => setIsModalOpen(true)} type='primary'>
        Загрузить реестр
      </Button>
      <LegalRegistryUpload
        loanId={loanId}
        onClose={() => setIsModalOpen(false)}
        personId={personId}
        visible={isModalOpen}
      />

      <Title>Список отправленных документов по клиенту:</Title>
      <Table
        // @ts-ignore
        columns={[
          {
            dataIndex: `createDtm`,
            key      : `createDtm`,
            title    : `Дата`,
          },
          {
            dataIndex: `loanId`,
            key      : `loanId`,
            title    : `Договор`,
          },
          {
            dataIndex: `index`,
            key      : `index`,
            title    : `№ документа`,
          },
          {
            dataIndex: `registryNumber`,
            key      : `registryNumber`,
            title    : `№ реестра`,
          },
          {
            dataIndex: `externalRegistryNumber`,
            key      : `externalRegistryNumber`,
            title    : `№ почтового реестра`,
          },
          {
            dataIndex: `externalRegistryDtm`,
            key      : `externalRegistryDtm`,
            title    : `Дата почтового реестра`,
          },
          {
            dataIndex: `registryTypeName`,
            key      : `registryTypeName`,
            title    : `Тип реестра`,
          },
        ]}
        dataSource={mappedRegistries}
        loading={isDictionariesLoading}
        rowKey='registryNumber'
      />
    </Wrap>
  );
};

export default LegalRegistries;
