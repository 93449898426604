import { createAction } from 'redux-actions';

export const model = `roleGroups`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const ASSIGN_TO_USERS = `ASSIGN_TO_USERS`;
export const CREATE = `CREATE`;
export const GET = `GET`;
export const GET_SINGLE = `GET_SINGLE`;
export const REMOVE = `REMOVE`;
export const REMOVE_SINGLE = `REMOVE_SINGLE`;
export const REMOVE_FROM_USERS = `REMOVE_FROM_USERS`;
export const RESET = `RESET`;
export const SET = `SET`;
export const SET_LOADING = `SET_LOADING`;
export const UPDATE = `UPDATE`;

export const assignToUsers = ca(ASSIGN_TO_USERS);
export const create = ca(CREATE);
export const get = ca(GET);
export const getSingle = ca(GET_SINGLE);
export const remove = ca(REMOVE);
export const removeSingle = ca(REMOVE_SINGLE);
export const removeFromUsers = ca(REMOVE_FROM_USERS);
export const reset = ca(RESET);
export const set = ca(SET);
export const setLoading = ca(SET_LOADING);
export const update = ca(UPDATE);
