import * as React from 'react';
import { useContext } from 'react';
import { Property } from 'csstype';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { WOOPRA_PERSONAL_URL } from 'constants/WOOPRA';
import { getShortened } from 'helper';

import {
  CopyOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { SubHeader } from 'components/SubHeader/SubHeader';
import { SubHeaderButtonDefault } from 'components/SubHeader/SubHeaderButtonDefault';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import ClientMenu from 'pages/PageClient/Single/components/ClientMenu/ClientMenu';
import ClientInfoWidget from 'pages/PageClient/Single/components/ClientSubHeader/ClientInfoWidget';
import ClientSubHeaderControls from
  'pages/PageClient/Single/components/ClientSubHeader/ClientSubHeaderControls/ClientSubHeaderControls';
import HeaderClientName from 'pages/PageClient/Single/components/ClientSubHeader/HeaderClientName';
import { getSubheaderLinks } from 'pages/PageClient/Single/components/ClientSubHeader/helpers';

const TopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;
`;

const TopBlockInfo = styled.div`
  display: flex;
  align-items: center;
`;

const ClientInfoWidgetsWrapper = styled.div`
  display: flex;
  margin-right: 24px;

  > * {
    :not(:first-child) {
      margin-left: 16px;
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    margin-right: 40px;

    > * {
      :not(:first-child) {
        margin-left: 24px;
      }
    }
  }
`;

const BottomBlock = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  a {
    text-decoration: none !important;
  }
`;

const displayAfterLgMixin = (initialDisplay: Property.Display) => css`
  display:none;

  @media(min-width: ${breakpoints.lg}) {
    display: ${initialDisplay};
  }
`;

const SecondBlockControlsWrapper = styled.div`
  ${displayAfterLgMixin(`flex`)};

  @media(min-width: ${breakpoints.lg}) {
    margin-bottom: 8px;
    margin-left: 16px;
    color: ${palette.black60Color};
  }
`;

const PersonIdWrapper = styled.div`
  font-family: PT Root UI, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;

  display: flex;
  align-items: center;

  margin-right: 8px;
`;

const PersonIdButton = styled.button`
  margin-left: 4px;
  background: transparent;
  display: flex;
  border: 1px solid ${palette.borderAccentColor};
  border-radius: 16px;
  cursor: pointer;

  padding: 3px 8px;

  transition: 100ms ease;

  .anticon:first-child {
    margin-left: 4px;
    color: ${palette.black30Color};
  }

  :hover {
    background: ${p => rgba(palette.whiteColor(p), 0.1)};

    .anticon:first-child {
      color: ${palette.whiteColor};
    }
  }

  :active {
    background: ${p => rgba(palette.whiteColor(p), 0.2)};
  }
`;

const WoopraButton = styled(SubHeaderButtonDefault)`
  display: flex;
  color: ${palette.black40Color};
  border-radius: 24px;
  padding: 4px 8px;

  .anticon:first-child {
    margin-right: 4px;
  }
`;

interface IComponentProps {
  applicationsSize: number;
  fullName: string;
  isReloading?: boolean;
  loansSize: number;
  onCopyPersonId(): void;
  onReload(): void;
}

const ClientSubHeaderDesktop: React.FC<IComponentProps> = ({
  applicationsSize,
  fullName,
  isReloading,
  loansSize,
  onCopyPersonId,
  onReload,
}) => {
  const { personId, verificationId } = useContext(ClientMatchContext) as { personId: string, verificationId: number };
  const { applications, loans } = getSubheaderLinks({ personId, verificationId });
  const personIdShort = getShortened(personId);
  const personalWoopraUrl = WOOPRA_PERSONAL_URL(personId);

  return (
    <SubHeader thinBottom>
      <TopBlock>
        <HeaderClientName fullName={fullName} />
        <TopBlockInfo>
          <ClientInfoWidgetsWrapper>
            <ClientInfoWidget link={applications} number={applicationsSize}>
              Заявок:
            </ClientInfoWidget>
            <ClientInfoWidget link={loans} number={loansSize}>
              Займов:
            </ClientInfoWidget>
          </ClientInfoWidgetsWrapper>

          <ClientSubHeaderControls
            isReloading={isReloading}
            onReload={onReload}
          />
        </TopBlockInfo>
      </TopBlock>

      <BottomBlock>
        <ClientMenu />
        <SecondBlockControlsWrapper>
          <PersonIdWrapper title={personId}>
            PersonId:
            <PersonIdButton onClick={() => onCopyPersonId()}>
              {personIdShort} <CopyOutlined />
            </PersonIdButton>
          </PersonIdWrapper>

          <a
            href={personalWoopraUrl}
            rel='noreferrer'
            style={{ textDecoration: `underline` }}
            target='_blank'
          >
            <WoopraButton>
              <LinkOutlined />
              Woopra
            </WoopraButton>
          </a>
        </SecondBlockControlsWrapper>
      </BottomBlock>
    </SubHeader>
  );
};

export default ClientSubHeaderDesktop;
