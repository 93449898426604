import * as React from 'react';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import { Pagination } from '@egjs/flicking-plugins';
import Flicking, {
  FlickingError,
  ViewportSlot,
} from '@egjs/react-flicking';
import { Document } from 'react-pdf';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { IDoc } from 'models/clients/types';

import { DocsContext } from 'pages/PageClient/Single/Docs';
import { usePagesQty } from 'pages/PageClient/Single/Docs/DocsCarousel/hooks';
import { ImagePreviewModalMobile } from 'pages/PageClient/Single/Docs/DocsCarousel/ImagePreviewModalMobile';
import { PageStyled } from 'pages/PageClient/Single/Docs/DocsCarousel/styled';

const Wrapper = styled.div`
  background: ${palette.blackestColor};
  border-radius: 16px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const CarouselImage = styled.img`
  width: 100%;
  object-fit: contain;
  height: 100%;
  max-height: 576px;
  border-radius: 16px;
  cursor: pointer;
`;

const PdfWrapper = styled.div`
  width: 100%;
  object-fit: contain;
  height: 100%;
  max-height: 576px;
  overflow-y: auto;
`;

const PaginationWrapper = styled.div`
  &.flicking-pagination-bullets {
    .flicking-pagination-bullet {
      opacity: 0.5;
      transition: opacity, 300ms ease;
      background-color: ${palette.whiteColor};

      &.flicking-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
`;

interface IComponentProps {
  items: IDoc[];
}

export const CarouselMobile: React.FC<IComponentProps> = ({ items }) => {
  const ref = useRef(null);

  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [previewImageIndex, setPreviewImageIndex] = useState(0);

  const { pagesQty, setPagesQty } = usePagesQty();

  const { url } = useContext(DocsContext);

  useEffect(() => {
    // @ts-ignore
    ref.current.on(`ready`, e => {
      e.currentTarget.addPlugins(new Pagination({ type: `bullet` }));
    });
  }, []);

  const animationDuration = 0;
  const flickingErrorHandler = (err: any) => {
    if (err instanceof FlickingError) return;
    throw err;
  };

  // @ts-ignore
  const prevImage = () => ref.current.prev(animationDuration).catch(flickingErrorHandler);
  // @ts-ignore
  const nextImage = () => ref.current.next(animationDuration).catch(flickingErrorHandler);

  useEffect(() => {
    if (url) {
      const index = _.findIndex(items, { url });

      if (index !== -1) {
        // @ts-ignore
        ref.current.moveTo(index).catch((err: Error) => {
          if (err instanceof FlickingError) return;
          throw err;
        });
      }
    }
  }, [url, items]);

  return (
    <Wrapper>
      <Flicking
        align='prev'
        changeOnHold
        circular
        duration={200}
        inputType={[`touch`]}
        moveType='strict'
        onChanged={e => setPreviewImageIndex(e.index)}
        ref={ref}
        renderOnSameKey
      >
        {_.map(items, (item, index) => (
          <ImageWrapper
            className='card-panel'
            key={`${item.name}_${index}`}
            onClick={() => {
              setIsPreviewMode(true);
              setPreviewImageIndex(index);
            }}
          >
            {_.includes(item.contentType, `pdf`) && (
              <PdfWrapper>
                <Document
                  file={item.url}
                  onLoadSuccess={({ numPages }) => setPagesQty(index, numPages)}
                >
                  {_.times(pagesQty[index], pageIndex => (
                    <PageStyled
                      key={pageIndex}
                      pageIndex={pageIndex}
                      renderAnnotationLayer={false}
                      //@ts-ignore
                      renderForms={false}
                      renderTextLayer={false}
                    />
                  ))}
                </Document>
              </PdfWrapper>
            )}
            {_.includes(item.contentType, `image`) && <CarouselImage alt={item.name} src={item.url} />}
          </ImageWrapper>
        ))}
        <ViewportSlot>
          <PaginationWrapper className='flicking-pagination' />
        </ViewportSlot>
      </Flicking>

      <ImagePreviewModalMobile
        isVisible={isPreviewMode}
        items={items}
        onClickNext={nextImage}
        onClickPrev={prevImage}
        onClose={() => setIsPreviewMode(false)}
        previewItemIndex={previewImageIndex}
      />
    </Wrapper>
  );
};

