import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from "styles/theme";
import MobileContext from 'pages/Layout/MobileContext';

import {
  ADDRESS_TITLES,
  ICON_HOVERED_IMAGE_SIZE,
  ICON_HOVERED_OFFSET,
  ICON_IMAGE_SIZE,
  ICONS,
  Z_INDEX_HOVERED,
} from './constants';

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 16px;
  width: 100%;

  @media screen and (max-width: 767px) {
    width: 100%;
    min-height: 500px;
  }
`;

const Container = styled.div`
  border: 1px solid ${palette.black30Color};
  border-radius: 8px;
  height: 100%;
`;

class Map extends React.Component {
  componentDidMount() {
    const { points } = this.props;

    if (!_.isEmpty(points)) {
      this.loadYMaps();
    }
  }

  componentDidUpdate(prevProps) {
    const { addressHovered, addressSelected, points } = this.props;
    const { mobile } = this.context;

    if (_.isEmpty(prevProps.points) && !_.isEmpty(points)) {
      this.loadYMaps();
    }

    if (prevProps.addressSelected !== addressSelected && addressSelected && this.map) {
      const center = _.get(_.find(points, { type: addressSelected }), `coordinates`);
      const zoom = this.map.getZoom();
      if (_.isArray(center)) this.map.setCenter(center, zoom < 13 ? 15 : zoom);
    }

    if (!mobile && prevProps.addressHovered !== addressHovered) {
      const indexHoveredOn = _.findIndex(points, { type: addressHovered });
      const indexHoveredOff = _.findIndex(points, { type: prevProps.addressHovered });

      if (addressHovered) {
        const markerToHoverOn = _.find(this.markers, marker => {
          if (marker.options.get(`zIndex`) === indexHoveredOn) return marker;
        });
        if (markerToHoverOn) {
          markerToHoverOn.options.set({
            iconImageSize: ICON_HOVERED_IMAGE_SIZE,
            iconOffset   : ICON_HOVERED_OFFSET,
            zIndex       : Z_INDEX_HOVERED,
          });
        }
      }
      if (prevProps.addressHovered) {
        const markerToHoverOff = _.find(this.markers, marker => {
          if (marker.options.get(`zIndex`) === Z_INDEX_HOVERED) return marker;
        });
        if (markerToHoverOff) {
          markerToHoverOff.options.set({
            iconImageSize: ICON_IMAGE_SIZE,
            iconOffset   : [0, 0],
            zIndex       : indexHoveredOff,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.map) this.map.destroy();
  }

  loadYMaps = () => {
    const { points } = this.props;
    const { mobile } = this.context;

    /* eslint-disable no-undef */
    ymaps.ready(() => {
      // подключаем модули
      ymaps.modules.require([
        `GeoObject`,
        `GeoObjectCollection`,
        `control.ZoomControl`,
        `geoObject.Hint`,
      ]).spread((GeoObject, GeoObjectCollection, ZoomControl, Hint) => {
        // создаём карту
        this.map = new ymaps.Map(`map`, {
          center: _.isArray(_.first(points).coordinates) ? _.first(points).coordinates : [55.7, 37.6],
          zoom  : 16,
        }, {
          maxZoom: 20,
          minZoom: 4,
        });

        // создаём маркеры
        this.markers = _.reject(_.map(points, ({ coordinates, type }, index) => (_.isObject(coordinates)
          ? new GeoObject({
            geometry  : { coordinates, type: `Point` },
            properties: { hintContent: ADDRESS_TITLES[type] },
          }, {
            iconImageHref: ICONS[type],
            iconImageSize: ICON_IMAGE_SIZE,
            iconLayout   : `default#image`,
            zIndex       : index,
          }) : null)), _.isNil);

        if (!mobile) {
          _.forEach(this.markers, marker => new Hint(marker));
        }

        // создаём коллекцию объектов и добавляем её на карту
        const geoObjectsCollection = new GeoObjectCollection({ children: this.markers });
        this.map.geoObjects.add(geoObjectsCollection);

        if (mobile) {
          this.map.margin.addArea({
            height: 260,
            left  : 0,
            top   : 0,
            width : `100%`,
          });
        }

        // Установка центра и масштаба карты таким образом, чтобы вся коллекция была видна.
        this.map.setBounds(geoObjectsCollection.getBounds(), { useMapMargin: true });

        // добавляем zoom-контролы
        const zoomControl = new ZoomControl({
          options: {
            position: {
              bottom: 120,
              left  : `auto`,
              right : 10,
              top   : `auto`,
            },
            size: `small`,
          },
        });
        this.map.controls.add(zoomControl);
        /* eslint-enable */
      }, error => {
        console.log(`ymaps.modules.require error: `, error); // eslint-disable-line no-console
      }, this);
    });
  }

  render() {
    return (
      <Wrapper>
        <Container id='map' />
      </Wrapper>
    );
  }
}

Map.contextType = MobileContext;
export default Map;
