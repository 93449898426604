import { TableState } from 'types/TableState';

export const DEFAULT_TABLE_STATE: TableState = {
  filters   : {},
  pagination: {
    defaultCurrent : 1,
    defaultPageSize: 25,
    // @ts-ignore
    page           : 1,
    pageSize       : 25,
    pageSizeOptions: [10, 25, 50, 100, 200],
    showTotal      : (total, range) => `${range[0]}-${range[1]} из ${total} лидов`,
    size           : `small`,
    total          : 0,
  },
  sorter: {
    columnKey: `id`,
    field    : `id`,
    order    : `descend`,
  },
};

export const LEAD_STATUS_NAMES = {
  NEW: `new`,
};
