import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { GlobalOutlined } from '@ant-design/icons';
import {
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardRow } from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';

export const DropdownWrapper = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 830px) and (min-width: ${breakpoints.md}) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  @media (max-width: 495px) {
    justify-content: flex-start;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`;
export const EntityCardTitleStyled = styled(EntityCardTitle)<{ $isExpanded: boolean }>`
  background-color: ${palette.backgroundAccentColor};
  padding: 16px;
  margin-bottom: 0;

  ${({ $isExpanded }) => ($isExpanded
    ? `border-radius: 8px 8px 0 0;`
    : `border-radius: 8px;`)
}
  a {
    color: ${palette.whiteColor} !important;
  }

  @media (max-width: 920px) and (min-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 830px) and (min-width: ${breakpoints.md}) {
    padding-bottom: 24px;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 495px) {
    padding-bottom: 32px;
  }

  @media (max-width: 430px) {
    padding-right: 42px;
  }
`;
export const EntityCardContentStyled = styled(EntityCardContent)`
  background-color: ${palette.backgroundAccentColor};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -8px !important;
  border-radius: 0 0 8px 8px;
  border: none;
`;
export const EntityCardRowStyled = styled(EntityCardRow)`
  margin-bottom: 8px !important;
`;
export const EntityCardTitleControlStyled = styled(EntityCardTitleControl)`
  @media (max-width: 830px) and (min-width: ${breakpoints.md}) {
    padding: 0 2px 2px 0;

    &:not(:last-child) {
      padding-right: 4px;
    }
  }

  @media (max-width: 495px) {
    padding: 0 2px 0 0;
    &:not(:last-child) {
      padding-right: 4px;
      margin-top: 4px;
    }
  }
`;
export const RotateWrapper = styled.div<{ $up: boolean }>`
  transform: rotate(${({ $up }) => ($up ? `270deg` : `90deg`)});
  transition: transform .2s ease-out;
`;
export const WoopraIcon = styled(GlobalOutlined)`
  color: ${palette.primaryColor};
`;
