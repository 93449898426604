import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { EThemeName } from 'styles/GlobalThemeContext';
import { palette } from 'styles/theme';

import { parseResponse } from 'api/helpers';
import {
  downloadBlob,
  showError,
} from 'helper';

import {
  downloadFile,
  removeFile,
  uploadFile,
} from 'models/fileStorage/api';
import {
  getCurrent as getOperatorAction,
  savePreferences as savePreferencesAction,
} from 'models/operators/actions';
import {
  getCurrentNotificationLists as getNotificationLists,
  getCurrentPreferences as getPreferences,
  isLoading as isLoadingSelector,
} from 'models/operators/selectors';
import {
  VERIFICATION_SEND,
  VERIFICATION_WRITE,
} from 'models/roles/constants';
import { getRolesTitle as getRolesTitleSelector } from 'models/user/selectors';

import { useHasRoles } from 'hooks/useHasRoles';
import { useStatePreference } from 'hooks/useStatePreference';

import {
  BorderBottomOutlined,
  BorderTopOutlined,
  PushpinOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  SoundOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  List,
  Modal,
  notification,
  Select,
  Spin,
  Switch,
  Tooltip,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { InputUploadButton } from 'components/Messenger/Input/InputControls/InputUploadButton';
import MobileContext from 'pages/Layout/MobileContext';

const StyledSpin = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const NotificationRow = styled(List.Item)`
  display: flex;
  flex-wrap: nowrap;
`;

const NotificationDesc = styled.span`
  flex-grow: 1;
  word-break: break-word;
`;

const ToggleButton = styled(Button)`
  flex-shrink: 0;
  margin-left: 6px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SwitcherRow = styled(Row)`
  margin-bottom: 16px;
`;

const StyledSelect = styled(Select)`
  width: 250px;
`;

const StyledOption = styled(Select.Option)`
  display: flex;
`;

const OptionText = styled.span`
  margin-left: 8px;
`;

const DownloadLink = styled.span<{ $mobile: boolean }>`
  cursor: pointer;
  color: ${palette.primaryColor};

  &:hover {
    text-decoration: underline;
  }

  ${({ $mobile }) => $mobile && `
    text-decoration: underline;
  `}
`;

const NOTIFICATION_PLACEMENTS = [
  {
    icon : RadiusUprightOutlined,
    label: `Сверху справа`,
    value: `topRight`,
  },
  {
    icon : BorderTopOutlined,
    label: `Сверху`,
    value: `top`,
  },
  {
    icon : RadiusUpleftOutlined,
    label: `Сверху слева`,
    value: `topLeft`,
  },
  {
    icon : RadiusBottomrightOutlined,
    label: `Снизу справа`,
    value: `bottomRight`,
  },
  {
    icon : BorderBottomOutlined,
    label: `Снизу`,
    value: `bottom`,
  },
  {
    icon : RadiusBottomleftOutlined,
    label: `Снизу слева`,
    value: `bottomLeft`,
  },
];

interface IComponentProps {
  visible: boolean;
  onClose(): void;
}

export const OperatorPreferences: React.FC<IComponentProps> = ({ onClose, visible }) => {
  const dispatch = useDispatch();
  const hasRoles = useHasRoles();
  const mobile = useContext(MobileContext);

  const getOperator = useCallback(() => dispatch(getOperatorAction()), [dispatch]);

  const isLoading = useSelector(isLoadingSelector);
  const initialPreferences = useSelector(getPreferences);
  const notificationLists = useSelector(getNotificationLists);
  const userRolesTitle = useSelector(getRolesTitleSelector);

  const [preferences, setPreferences] = useState(initialPreferences);

  const [themeName, setThemeName] = useStatePreference(`ui.themeName`, EThemeName.LIGHT);

  const { attorney } = preferences;
  const {
    importantNotificationList,
    placement: notificationPlacement = `topRight`,
    telegramBellNotificationList,
  } = preferences.notifications;
  const { isEmptyAutoSend, isResultsIncompleteSend } = preferences.verification || {};

  const toggleNotification = (list: string, id: any) => () => {
    setPreferences({
      ...preferences,
      notifications: {
        ...preferences.notifications,
        [list]: _.includes(preferences.notifications[list], id)
          ? _.without(preferences.notifications[list], id)
          : [...preferences.notifications[list], id],
      },
    });
  };

  const toggleVerificationEmptyAutoSend = () => {
    setPreferences({
      ...preferences,
      verification: {
        ...preferences.verification,
        isEmptyAutoSend: !isEmptyAutoSend,
      },
    });
  };

  const toggleVerificationResultsIncompleteSend = () => {
    setPreferences({
      ...preferences,
      verification: {
        ...preferences.verification,
        isResultsIncompleteSend: !isResultsIncompleteSend,
      },
    });
  };

  const onNotificationPlacementChange = (placement: any) => {
    setPreferences({
      ...preferences,
      notifications: {
        ...preferences.notifications,
        placement,
      },
    });
  };

  const onAttorneyChange = (attorneyUrl:string) => {
    setPreferences({
      ...preferences,
      attorney: attorneyUrl,
    });
  };
  const attorneyFileName = _.last(_.split(attorney, `/`));

  const [prevAttorney, setPrevAttorney] = useState<string>(attorney);

  const onDownloadAttorney = async () => {
    const defaultError = `При скачивании доверенности произошла ошибка`;
    try {
      const blob = parseResponse({
        defaultError,
        response: await downloadFile(attorney),
      });
      downloadBlob(blob, attorneyFileName);
    } catch (error) {
      showError({ defaultError, error });
    }
  };

  const onRemovePrevAttorney = async () => {
    if (!prevAttorney) return;
    const defaultError = `При удалении прежней доверенности произошла ошибка`;
    try {
      parseResponse({
        defaultError,
        response: await removeFile(prevAttorney),
      });
    } catch (error) {
      showError({ defaultError, error });
    }
  };

  const onUploadAttorney = async (file: any) => {
    setPrevAttorney(attorney);
    const defaultError = `При загрузке файла произошла ошибка`;
    const notificationKey = `uploadAttorney`;
    try {
      notification.info({
        message: `Доверенность загружается...`,
        key    : notificationKey,
      });
      const attorneyUrl = parseResponse({
        dataPath: `data.url`,
        defaultError,
        response: await uploadFile({ directory: `operatorsAttorneys`, file }),
      });
      notification.success({
        message: `Доверенность загружена, нажмите "Сохранить", чтобы новая доверенность была привязана к вам`,
        key    : notificationKey,
      });
      onAttorneyChange(attorneyUrl);
    } catch (error) {
      showError({ defaultError, error, key: notificationKey });
    }
  };

  const savePreferences = () => {
    dispatch(savePreferencesAction({
      preferences,
      callback: async () => {
        await onRemovePrevAttorney();
        onClose();
      },
    }));
    notification.config({ placement: preferences?.notifications?.placement || `topRight` });
  };

  useEffect(() => {
      if (visible) {
        getOperator();
      }
    },
    [getOperator, visible],
  );

  return (
    <Modal
      okButtonProps={{
        disabled: isLoading,
      }}
      okText='Сохранить'
      onCancel={() => {
        onClose();
        setPreferences(initialPreferences);
      }}
      onOk={savePreferences}
      open={visible}
      title='Настройки пользователя'
    >
      <Divider orientation='left'>Роли</Divider>
      <p>{userRolesTitle}</p>
      {!_.isEmpty(notificationLists) && (
        <>
          <Divider orientation='left'>Уведомления</Divider>
          <List
            dataSource={notificationLists}
            // @ts-ignore
            renderItem={({ description, id, isPersonal, name }) => (
              <NotificationRow key={id}>
                <NotificationDesc>
                  {!isPersonal ? description : `${name} (персональный тэг)`}
                </NotificationDesc>
                <Tooltip title='Звук в Telegram для уведомлений с этим тэгом'>
                  <ToggleButton
                    icon={<SoundOutlined />}
                    onClick={toggleNotification(`telegramBellNotificationList`, id)}
                    type={_.includes(telegramBellNotificationList, id) ? `primary` : `default`}
                  />
                </Tooltip>
                <Tooltip title='Уведомления с этим тэгом во вкладке "Важное"'>
                  <ToggleButton
                    icon={<PushpinOutlined />}
                    onClick={toggleNotification(`importantNotificationList`, id)}
                    type={_.includes(importantNotificationList, id) ? `primary` : `default`}
                  />
                </Tooltip>
              </NotificationRow>
            )}
          />
        </>
      )}
      {hasRoles([VERIFICATION_SEND, VERIFICATION_WRITE]) && (
        <>
          <Divider orientation='left'>Верификация</Divider>
          <SwitcherRow>
            Решение без данных авто
            <Switch
              checked={isEmptyAutoSend}
              onClick={toggleVerificationEmptyAutoSend}
            />
          </SwitcherRow>
          <SwitcherRow>
            Решение без заполненных чек-листов
            <Switch
              checked={isResultsIncompleteSend}
              onClick={toggleVerificationResultsIncompleteSend}
            />
          </SwitcherRow>
        </>
      )}
      <Divider orientation='left'>Системные уведомления</Divider>
      <StyledSelect onChange={onNotificationPlacementChange} value={notificationPlacement}>
        {_.map(NOTIFICATION_PLACEMENTS, ({ icon: Icon, label, value }) => (
          <StyledOption key={value} value={value}>
            <Icon />
            <OptionText>{label}</OptionText>
          </StyledOption>
        ))}
      </StyledSelect>
      {isLoading && (
        <StyledSpin />
      )}
      <Divider orientation='left'>Интерфейс</Divider>
      <StyledSelect onChange={setThemeName} value={themeName}>
        <StyledOption key='light' value={EThemeName.LIGHT}>Светлая тема</StyledOption>
        <StyledOption key='dark' value={EThemeName.DARK}>Темная тема</StyledOption>
      </StyledSelect>
      <Divider orientation='left'>Доверенность</Divider>
      <Row>
        {attorney
          ? <DownloadLink $mobile={mobile} onClick={onDownloadAttorney}>{attorneyFileName}</DownloadLink>
          : `Доверенность не загружена`}
        <DefaultTooltip title='Загрузить доверенность'>
          <InputUploadButton accept='application/pdf' customRequest={onUploadAttorney} />
        </DefaultTooltip>
      </Row>
    </Modal>
  );
};
