import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { transparentize } from 'polished';
import Draggable from 'react-draggable';
import { useHotkeys } from 'react-hotkeys-hook';
import { Document } from 'react-pdf';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { IDoc } from 'models/clients/types';

import {
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import {
  useRotation,
  useScale,
} from 'pages/PageClient/Single/Docs/DocsCarousel/hooks';
import { PageStyled } from 'pages/PageClient/Single/Docs/DocsCarousel/styled';

const Wrapper = styled.div<{ isVisible: boolean; }>`
  width: 100%;
  position: fixed;
  height: 100%;
  background: ${p => transparentize(0.5, palette.blackColor(p))};
  top: 0;
  z-index: 5;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? `visible` : `hidden`)};
  transition: opacity, 200ms ease;
`;

const ImageWrapper = styled.div`
  padding-right: 70px;
  padding-left: 70px;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: zoom-out;
  overflow: scroll;
`;

const ItemName = styled.div`
  position: absolute;
  color: ${palette.whiteColor};
  top: 0;
  width: 100%;
  text-align: center;
  padding: 8px;
  margin-top: 8px;
`;

const ControlsBar = styled.div`
  width: 100%;
  height: 50px;
  background: ${p => transparentize(0.7, palette.blackColor(p))};
  position: absolute;
  top: 0;
  z-index: 1;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;

  ${ItemName} {
    position: relative;
  }
`;

const Actions = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
`;

const IconWrapper = styled.div<{ isDisabled?: boolean; }>`
  .anticon {
    user-select: none;
    padding: 16px;
    font-size: 18px;
    color: ${palette.whiteColor};
    cursor: pointer;

    ${({ isDisabled }) => isDisabled && `
      opacity: 0.5;
      cursor: not-allowed;
    `};
  }
`;

const Arrow = styled.div`
  position: absolute;
  color: ${palette.whiteColor};
  height: calc(100% - 50px);
  width: 100px;
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  transition: background, 300ms ease;
  z-index: 1;

  :hover {
    background: ${p => transparentize(0.7, palette.blackColor(p))};

    .anticon {
      opacity: 1;
    }
  }

  .anticon {
    transition: opacity 300ms ease;
    opacity: 0.5;
    font-size: 40px;
  }
`;

const ArrowLeft = styled(Arrow)`
  left: 0;
`;

const ArrowRight = styled(Arrow)`
  right: 0;
`;

const GalleryImage = styled.img<{ rotation: number, scale: number }>`
  transform: ${({ rotation, scale }) => `rotate(${rotation}deg) scale3d(${scale}, ${scale}, 1)`};
  transition: transform, 300ms ease;
  cursor: grab;
  user-select: none;
  max-width: 100%;
`;

const GalleryPdf = styled.div`
  max-width: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  background-color: ${palette.whiteColor};
`;

interface IComponentProps {
  isVisible: boolean;
  item: IDoc;
  onCancel(): void;
  onClickNext(): void;
  onClickPrev(): void;
}

export const ImagePreviewModalDesktop: React.FC<IComponentProps> = ({
  isVisible,
  item,
  onCancel,
  onClickNext,
  onClickPrev,
}) => {
  const initialScale = 1;
  const {
    downscale,
    isDownscaleDisabled,
    isUpscaleDisabled,
    resetScale,
    scale,
    upscale,
  } = useScale(initialScale);
  const [pagesQty, setPagesQty] = useState<number | null>(null);

  const initialPosition = { x: 0, y: 0 };

  const [position, setPosition] = useState(initialPosition);

  const { resetRotation, rotateLeft, rotateRight, rotation } = useRotation();

  const handleDrag = (e: any, ui: { deltaX: number; deltaY: number; }) => {
    if (scale > initialScale) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      setPosition(value => ({ x: value.x + ui.deltaX, y: value.y + ui.deltaY }));
    } else {
      setPosition(initialPosition);
    }
  };

  useEffect(() => {
    if (scale === initialScale) {
      setPosition(initialPosition);
    }
  }, [scale]); // eslint-disable-line react-hooks/exhaustive-deps

  useHotkeys(`=, num_add`, upscale, {}, [scale]);
  useHotkeys(`-, num_subtract`, downscale, {}, [scale]);
  useHotkeys(`[`, rotateLeft);
  useHotkeys(`]`, rotateRight);

  useEffect(() => {
    resetRotation();
    resetScale();
  }, [isVisible, item]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper isVisible={isVisible}>
      {isVisible && (
      <>
        <ControlsBar>
          <div />
          <ItemName>{item.label}</ItemName>
          <Actions>
            <DefaultTooltip placement='bottom' title='Повернуть влево "["'>
              <IconWrapper onClick={rotateLeft}>
                <RotateLeftOutlined />
              </IconWrapper>
            </DefaultTooltip>
            <DefaultTooltip placement='bottom' title='Повернуть вправо "]"'>
              <IconWrapper onClick={rotateRight}>
                <RotateRightOutlined />
              </IconWrapper>
            </DefaultTooltip>
            <DefaultTooltip placement='bottom' title='Отдалить [-]'>
              <IconWrapper
                isDisabled={isDownscaleDisabled}
                onClick={downscale}
              >
                <ZoomOutOutlined />
              </IconWrapper>
            </DefaultTooltip>
            <DefaultTooltip placement='bottom' title='Приблизить [+]'>
              <IconWrapper
                isDisabled={isUpscaleDisabled}
                onClick={upscale}
              >
                <ZoomInOutlined />
              </IconWrapper>
            </DefaultTooltip>
            <DefaultTooltip placement='bottomRight' title='Закрыть [Esc]'>
              <IconWrapper onClick={onCancel}>
                <CloseOutlined />
              </IconWrapper>
            </DefaultTooltip>
          </Actions>
        </ControlsBar>
        <ArrowLeft onClick={() => onClickPrev()}>
          <LeftOutlined />
        </ArrowLeft>
        <ArrowRight onClick={() => onClickNext()}>
          <RightOutlined />
        </ArrowRight>
        <ImageWrapper onClick={onCancel}>
          {_.includes(item.contentType, `pdf`) && (
          <GalleryPdf onClick={e => e.stopPropagation()}>
            <Document file={item.url} onLoadSuccess={({ numPages }) => setPagesQty(numPages)}>
              {/*@ts-ignore*/}
              {_.times(pagesQty, pageIndex => (
                <PageStyled
                  key={pageIndex}
                  pageIndex={pageIndex}
                  renderAnnotationLayer={false}
                  //@ts-ignore
                  renderForms={false}
                  renderTextLayer={false}
                  rotate={rotation}
                  scale={scale}
                />
              ))}
            </Document>
          </GalleryPdf>
          )}
          {_.includes(item.contentType, `image`) && (
            <Draggable onDrag={handleDrag} position={position}>
              <span>
                <GalleryImage
                  alt={item.name}
                  draggable='false'
                  onClick={e => e.stopPropagation()}
                  rotation={rotation}
                  scale={scale}
                  src={item.url}
                />
              </span>
            </Draggable>
          )}
        </ImageWrapper>
      </>
      )}
    </Wrapper>
  );
};
