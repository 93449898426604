import React from 'react';
import _ from 'lodash';

import { REQUIRED } from 'constants/FORM_VALIDATION_RULES';
import TAG_COLORS from 'constants/TAG_COLORS';
import {
  getColumn,
  getField,
  getSort,
  sortAlphabetically,
} from 'helper';

import {
  Input,
  Select,
} from 'antd';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';

export const COLUMNS = _.map([
  {
    key   : `id`,
    sorter: getSort(`id`),
  },
  {
    key   : `name`,
    title : `Название`,
    sorter: getSort(`name`, sortAlphabetically),
  },
  {
    key   : `description`,
    title : `Описание`,
    sorter: getSort(`description`, sortAlphabetically),
    width : 200,
  },
  {
    key   : `color`,
    title : `Цвет`,
    render: color => <DefaultTag color={color}>{color}</DefaultTag>,
  },
], getColumn);

export const FORM_FIELDS = (roleNames = [], roleEditing) => _.map([
  {
    key         : `name`,
    title       : `Название`,
    initialValue: ``,
    required    : true,
    rules       : [
      REQUIRED,
      {
        validator: (pass, value) => {
          if (_.get(roleEditing, `name`) === value || !_.includes(roleNames, value)) return Promise.resolve();
          return Promise.reject(new Error(`Такое имя роли уже есть, придумайте другое`));
        },
      },
    ],
  },
  {
    key         : `description`,
    title       : `Описание`,
    Component   : Input.TextArea,
    initialValue: ``,
  },
  {
    key          : `color`,
    title        : `Цвет`,
    Component    : Select,
    initialValue : ``,
    options      : _.map(TAG_COLORS, value => ({ label: value, value })),
    optionsRender: ({ value }) => <DefaultTag color={value}>{value}</DefaultTag>,
    required     : true,
    rules        : [REQUIRED],
  },
  {
    key          : `roleGroupId`,
    title        : `Группа ролей`,
    Component    : Select,
    editable     : false,
    initialValue : ``,
    optionsSource: `roleGroups`,
  },
], getField);
