import * as React from 'react';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { transparentize } from 'polished';
import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
  shadows,
} from 'styles/theme';

import { getFullName } from 'models/clients/helpers';
import {
  remove as removeMessageAction,
  setMessageRead as setMessageReadAction,
} from 'models/dialogs/actions';
import { getId as getCurrentUserIdSelector } from 'models/user/selectors';

import useOnScreen from 'hooks/useOnScreen';

import {
  DeleteOutlined,
  EditOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { LONG_MESSAGE_TRESHOLD } from 'components/Messenger/constants';
import { MessageAction } from 'components/Messenger/Message/MessageAction';
import { MessageAttachments } from 'components/Messenger/Message/MessageAttachments';
import { MessageReplyTo } from 'components/Messenger/Message/MessageReplyTo';
import { MessageStatusIcon } from 'components/Messenger/Message/MessageStatusIcon';
import { MessageText } from 'components/Messenger/Message/MessageText';
import { MessageTime } from 'components/Messenger/Message/MessageTime';
import { MessageToggleCollapseButton } from 'components/Messenger/Message/MessageToggleCollapseButton';
import { IMessage } from 'components/Messenger/types';

const ActionsWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const Actions = styled.div`
  display: flex;
  margin-right: 8px;

  @media(min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const Info = styled.div`
  display: flex;
`;

const Time = styled(MessageTime)``;

const Wrapper = styled.div`
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  width: 100%;

  transition: background-color 3000ms ease;

  &.highlighted {
    transition: background-color 300ms ease;
    // background: ${p => transparentize(0.5, palette.primary100Color(p))}; //todo;
    background: ${getLightStyleOr(
    p => transparentize(0.5, palette.primary100Color(p)),
    p => transparentize(0.5, palette.primary500Color(p)),
  )};
  }

  @media(min-width: ${breakpoints.md}) {
    padding-left: 24px;
    padding-right: 24px;


    :hover {
      ${Info} {
        display: none;
        opacity: 0;
      }

      ${Actions} {
        display: flex;
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AuthorWrapper = styled.div`
  display: flex;
  overflow: hidden;
  margin-right: 8px;
`;

const Author = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${palette.textPrimaryColor};
  font-weight: 500;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  margin-bottom: 8px;
  margin-right: 16px;
`;

const ContentWrapper = styled.div`
  background: ${palette.backgroundColor};

  border: 1px solid ${getLightStyleOr(palette.black5Color, palette.black10Color)};
  box-shadow: ${shadows.image};
  border-radius: 8px;
  overflow: hidden;
`;

const Content = styled.div`
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
  width: 100%;
  color: ${palette.textPrimaryColor};

  white-space: pre-wrap;

  padding: 12px 12px;

  a {
    text-decoration: underline;
  }
`;

const IsUpdatedBadge = styled.div`
  color: ${palette.black60Color};//todo:
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  background-color: ${palette.black10Color}; //todo
  border-radius: 56px;
  padding: 0 4px;
  margin-right: 8px;

  @media(min-width: ${breakpoints.md}) {
    margin-right: 16px;
  }
`;

interface IComponentProps {
  dialog: any;
  message: IMessage;
  onDelete: () => void;
  onEdit: () => void;
  onReply: () => void;
}

export const Message: React.FC<IComponentProps> = ({
  dialog,
  message,
  onDelete,
  onEdit,
  onReply,
}) => {
  const dispatch = useDispatch();

  const ref = React.useRef();
  const isVisible = useOnScreen(ref);
  const currentUserId = useSelector(getCurrentUserIdSelector);
  const isReadSend = _.get(message, `isRead`, true);
  const isMine = _.get(message, `authorId`) === currentUserId;
  const createdAt = new Date(message.createdAt);
  const updatedAt = new Date(message.updatedAt);
  // @ts-ignore
  const isUpdated = (updatedAt - createdAt) > 2000;

  const removeMessage = () => dispatch(removeMessageAction({ dialogId: dialog.id, messageId: message.id }));

  const setMessageRead = useCallback(
    () => dispatch(setMessageReadAction({ id: message.id, dialogId: dialog.id })),
    [dialog.id, dispatch, message.id],
  );

  useEffect(() => {
    if (isVisible && !isMine && !isReadSend) {
      setMessageRead();
    }
  }, [isVisible, isMine, isReadSend]);

  const scrollToMessage = (messageId: string) => {
    const el = document.getElementById(`message_${messageId}`);
    const removeHighlightDelay = 1000;

    if (el) {
      el.scrollIntoView({ behavior: `smooth` });
      el.classList.add(`highlighted`);

      setTimeout(() => {
        el.classList.remove(`highlighted`);
      }, removeHighlightDelay);
    }
  };

  const [isCollapsed, setIsCollapsed] = useState(false);
  const isLongMessage = _.size(message.raw) > LONG_MESSAGE_TRESHOLD;
  const author = isMine
    ? `Я`
    : message.author
      // @ts-ignore
      ? getFullName(_.get(message, `author`))
      : `—`;

  return (
    // @ts-ignore
    <Wrapper id={`message_${message.id}`} ref={ref}>
      <Header>
        <AuthorWrapper>
          <Author>
            {author}
          </Author>
          <MessageStatusIcon message={message} />
        </AuthorWrapper>
        <ActionsWrapper>
          <Actions>
            {isMine && (
              // @ts-ignore
              <MessageAction
                isDangerous
                onClick={() => {
                  removeMessage();
                  onDelete();
                }}
                title='Удалить'
              >
                <DeleteOutlined />
              </MessageAction>
            )}

            {isMine && (
              // @ts-ignore
              <MessageAction onClick={() => onEdit()} title='Редактировать'>
                <EditOutlined />
              </MessageAction>
            )}

            {/*@ts-ignore*/}
            <MessageAction onClick={() => onReply()} title='Ответить'>
              <RollbackOutlined />
            </MessageAction>
          </Actions>

          <Info>
            {isUpdated && (
              <IsUpdatedBadge>Изменено</IsUpdatedBadge>
            )}

            <Time createdAt={message.createdAt} />
          </Info>
        </ActionsWrapper>
      </Header>

      <ContentWrapper onDoubleClick={() => onReply()}>
        <Content>
          {message.replyTo && (
            <MessageReplyTo
              messageToReply={message.replyTo}
              onClick={() => scrollToMessage(_.get(message.replyTo, `id`))}
            />
          )}

          {message.raw && (
            <MessageText hasFiles={!_.isEmpty(message.files)} isCollapsed={isCollapsed} rawText={message.raw} />
          )}

          {message.files && (
            <MessageAttachments message={message} />
          )}
        </Content>

        {isLongMessage && (
          <MessageToggleCollapseButton
            isCollapsed={isCollapsed}
            onClick={() => setIsCollapsed(v => !v)}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
