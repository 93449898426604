import { createAction } from 'redux-actions';

export const model = `operators`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const CREATE = `CREATE`;
export const GET = `GET`;
export const GET_CURRENT = `GET_CURRENT`;
export const GET_SINGLE = `GET_SINGLE`;
export const REMOVE = `REMOVE`;
export const REMOVE_SINGLE = `REMOVE_SINGLE`;
export const RESET = `RESET`;
export const SAVE_PREFERENCES = `SAVE_PREFERENCES`;
export const SET_PREFERENCES = `SET_PREFERENCES`;
export const SET = `SET`;
export const SET_LOADING = `SET_LOADING`;
export const UPDATE = `UPDATE`;

export const create = ca(CREATE);
export const get = ca(GET);
export const getCurrent = ca(GET_CURRENT);
export const getSingle = ca(GET_SINGLE);
export const remove = ca(REMOVE);
export const removeSingle = ca(REMOVE_SINGLE);
export const reset = ca(RESET);
export const savePreferences = ca(SAVE_PREFERENCES);
export const setPreferences = ca(SET_PREFERENCES);
export const set = ca(SET);
export const setLoading = ca(SET_LOADING);
export const update = ca(UPDATE);
