import _ from 'lodash';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { isAuth } from 'models/user/helpers';

import { notification } from 'antd';

import {
  CREATE,
  GET,
  GET_CURRENT,
  GET_SINGLE,
  model as namespace,
  REMOVE,
  REMOVE_SINGLE,
  RESET,
  SAVE_PREFERENCES,
  SET,
  SET_LOADING,
  SET_PREFERENCES,
  UPDATE,
} from './actions';
import * as api from './api';
import {
  getCurrentPreferences as getCurrentPreferencesSelector,
  getItems as getItemsSelector,
} from './selectors';

const initialState = {
  current: {
    id      : null,
    settings: null,
    user    : {
      notificationLists: [],
    },
  },
  isLoaded    : false,
  isLoading   : false,
  items       : [],
  searchString: ``,
  preferences : null,
};

export default {
  namespace,
  state        : initialState,
  subscriptions: {
    setup({ dispatch }) {
      if (isAuth()) setTimeout(() => dispatch({ type: GET_CURRENT }), 200);
    },
  },
  effects: {
    *[GET](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке сотрудников произошла ошибка`;
      try {
        const items = parseResponse({
          defaultError,
          response: yield call(api.get),
        });

        yield put({ type: SET, payload: { items, isLoaded: true } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CURRENT](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке сотрудника произошла ошибка`;

      try {
        const current = parseResponse({
          defaultError,
          response: yield call(api.getCurrent),
        });

        yield put({ type: SET, payload: { current } });

        const preferences = yield select(getCurrentPreferencesSelector);
        notification.config({
          placement: preferences?.notifications?.placement || `topRight`,
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_SINGLE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id } = action.payload;
      const defaultError = `При загрузке сотрудника с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(defaultError);

        const item = parseResponse({
          defaultError,
          response: yield call(api.getSingle, id),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => (currentItem.id === id
              ? { ...currentItem, ...item }
              : currentItem
            )),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { item } = action.payload;
      const defaultError = `При создании сотрудника произошла ошибка`;

      try {
        if (_.isEmpty(item)) throw new Error(defaultError);

        const newItem = parseResponse({
          defaultError,
          response: yield call(api.create, item),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.concat(currentItems, [newItem]),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { ids } = action.payload;
      const defaultError = `При удалении сотрудников с id '${ids}' произошла ошибка`;

      try {
        if (_.isEmpty(ids)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.remove, ids),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.reject(currentItems, ({ id }) => _.includes(ids, id)),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE_SINGLE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id } = action.payload;
      const defaultError = `При удалении сотрудника с id '${id}' произошла ошибка`;

      try {
        if (!id) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.removeSingle, id),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.reject(currentItems, { id }),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { id, item } = action.payload;
      const defaultError = `При редактировании сотрудника произошла ошибка`;

      try {
        if (!id || _.isEmpty(item)) throw new Error(defaultError);

        const updatedItem = parseResponse({
          defaultError,
          response: yield call(api.update, id, item),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => (currentItem.id === id ? updatedItem : currentItem)),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[SAVE_PREFERENCES](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { preferences } = action.payload;
      const defaultError = `При редактировании настроек сотрудника произошла ошибка`;

      try {
        parseResponse({
          defaultError,
          response: yield call(api.savePreferences, preferences),
        });

        yield put({ type: SET_PREFERENCES, payload: preferences });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        ...payload,
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
    [SET_PREFERENCES](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        current: {
          ...state.current,
          preferences: payload,
        },
      };
    },
  },
};
