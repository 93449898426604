import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  ClearOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Space,
} from 'antd';

const DateFilterDropDown = ({
  confirm,
  onSearchRef,
  placeholder,
  selectedKeys,
  setSelectedKeys,
}) => (
  <div style={{ padding: 8 }}>
    <DatePicker.RangePicker
      allowClear={false}
      format='DD.MM.YYYY'
      onChange={([from, to]) => setSelectedKeys([moment.utc(from).startOf(`day`), moment.utc(to).endOf(`day`)])}
      onPressEnter={() => confirm()}
      placeholder={placeholder}
      ref={onSearchRef}
      style={{ marginBottom: 8, width: 250 }}
      value={_.map(selectedKeys, item => (moment.isMoment(item) ? item : moment(item)))}
    />
    <Space style={{ display: `flex` }}>
      <Button
        icon={<SearchOutlined />}
        onClick={() => confirm()}
        size='small'
        style={{ width: 120 }}
        type='primary'
      />
      <Button
        icon={<ClearOutlined />}
        onClick={() => {
          setSelectedKeys([]);
          confirm();
        }}
        size='small'
        style={{ width: 120 }}
      />
    </Space>
  </div>
);

export default DateFilterDropDown;
