import * as React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled, { css } from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import {
  copyToClipboard,
  prettifyAmount,
} from 'helper';

import { useClient } from 'models/clients/hooks';
import { getItem as getTaskSelector } from 'models/task/selectors';

import { useStatePreference } from 'hooks/useStatePreference';

import { CopyOutlined } from '@ant-design/icons';
import CommunicationTypeDropdown from 'components/CommunicationTypeDropdown';
import { ConditionDropdown } from 'components/ConditionDropdown';
import MttPhoneButton from 'components/MttPhoneButton';

const MttPhoneButtonStyled = styled(MttPhoneButton)`
  margin-left: 8px;
`;
const Wrap = styled.ul<{ noPadding?: boolean, }>`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: -16px;
  height: auto;
  width: auto;
  padding-right: ${({ noPadding }) => (noPadding ? `0` : `32px`)};
  max-width: 50%;

  @media (max-width: ${breakpoints.md}) {
    box-sizing: border-box;
    max-width: unset;
    min-width: 100%;
    margin-left: 0;
    margin-right: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Item = styled.li<{ noPadding?: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: ${({ noPadding }) => (noPadding ? `8px 0` : `8px 16px`)};
  padding-right: 0;

  & + & {
    border-top: solid 1px ${palette.borderDefaultColor};
  }

  @media (max-width: ${breakpoints.md}) {
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin: 8px 0;
    padding: 0;
    min-width: 100%;

    & + & {
      border-top: none;
    }
  }
`;

const ItemLabel = styled.div`
  display: block;
  width: 50%;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.textSecondaryColor};
  flex-grow: 1;
  margin-right: 8px;
  margin-bottom: 4px;

  @media (max-width: ${breakpoints.md}) {
    display: unset;
    margin-bottom: 0;
  }
`;

const CopyIcon = styled(CopyOutlined)`
  color: ${palette.primary500Color};
  font-size: 14px;
  position: relative;
  margin-left: 8px;

  @media (max-width: ${breakpoints.md}) {
    bottom: 2px;
    margin-left: 4px;
    font-size: 16px;
  }
`;

const itemValueStyles = css`
  display: block;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${palette.textPrimaryColor};

  @media (max-width: ${breakpoints.md}) {
    display: unset;
    font-size: 14px;
  }
`;

const ItemValuePlain = styled.span`${itemValueStyles}`;
const ItemValueLink = styled.a`${itemValueStyles}`;
const ItemValueConditionDropdown = styled(ConditionDropdown)`${itemValueStyles}`;

const PhoneWrapper = styled.div`
  display: flex;
`;

interface IProps {
  noPadding?: boolean;
  personId: string;
  taskId: number;
}

export const ClientInfo: React.FC<IProps> = ({ noPadding, personId, taskId }) => {
  const [isOpen] = useStatePreference(
    `ui.collection.isTaskClientInfoOpen`,
    false,
  );

  const [, client] = useClient(personId);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const task = useSelector(state => getTaskSelector(state, taskId));

  const isAddressSame = client.addressFact === client.addressReg;
  const mainPhone = `+${client.phone}`;

  const clientContacts = [
    {
      label : `Основной телефон:`,
      value : mainPhone,
      href  : `tel:${mainPhone}`,
      // @ts-ignore
      button: <MttPhoneButtonStyled phone={mainPhone} />,
    },
    {
      label        : `E-mail:`,
      value        : client.email,
      href         : `mailto:${client.email}`,
      hiddenOnClose: true,
    },
    ..._.map(_.uniqBy(client.additionalPhones, `value`), ({ value: phone }) => ({
      label        : `Доп. телефон:`,
      value        : `+${phone}`,
      href         : `tel:+${phone}`,
      // @ts-ignore
      button       : <MttPhoneButtonStyled phone={`+${phone}`} />,
      hiddenOnClose: true,
    })),
    {
      label : isAddressSame ? `Адреса проживания и регистрации:` : `Адрес проживания:`,
      value : client.addressFact,
      isWide: true,
    },
    ...(isAddressSame ? [] : [{
      label        : `Адрес регистрации:`,
      value        : client.addressReg,
      isWide       : true,
      hiddenOnClose: true,
    }]),
    ...(task?.limit ? [{
      label        : `Лимит повторного/дополнительного кредитования:`,
      value        : prettifyAmount(task?.limit),
      isWide       : true,
      hiddenOnClose: true,
    }] : []),
  ];

  return (
    <Wrap noPadding={noPadding}>
      {_.map(isOpen ? clientContacts : _.reject(clientContacts, `hiddenOnClose`),
        ({ button, href, label, value }, key) => (
          <Item key={key} noPadding={noPadding}>
            <ItemLabel>{label}</ItemLabel>
            <PhoneWrapper>
              {href
                ? <ItemValueLink href={href}>{value}</ItemValueLink>
                : <ItemValuePlain>{value}</ItemValuePlain>}
              {button}
            </PhoneWrapper>
            <CopyIcon onClick={() => copyToClipboard(value)} />
          </Item>
        ))}
      {isOpen && (
        <>
          <Item noPadding={noPadding}>
            <ItemLabel>Состояние клиента:</ItemLabel>
            <ItemValueConditionDropdown entity='client' personId={personId} />
          </Item>
          <CommunicationTypeDropdown personId={personId} />
        </>
      )}
    </Wrap>
  );
};
