import React, {
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';
import {
  Redirect,
  Route,
  Switch,
} from 'dva/router';
import qs from 'query-string';
import styled from 'styled-components';
import { GlobalThemeContext } from 'styles/GlobalThemeContext';
import {
  height,
  palette,
} from 'styles/theme';

import { hasIntersection } from 'helper';

import { ROLE_NAMES } from 'models/roles/constants';
import { set as setUiAction } from 'models/ui/actions';
import {
  emailConfirm as emailConfirmAction,
  refreshToken as refreshTokenAction,
} from 'models/user/actions';
import {
  isAuth,
  needRefreshToken,
} from 'models/user/helpers';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';
import { getLatest as getVersionLatestAction } from 'models/version/actions';

import useDetectMobile from 'hooks/useDetectMobile';
import { useStatePreference } from 'hooks/useStatePreference';

import { Layout } from 'antd';
import SendSmsModal from 'components/Modal/SendSms';
import { GlobalHotkeys } from 'pages/Layout/GlobalHotkeys/GlobalHotkeys';
import PageAdmin from 'pages/PageAdmin';
import {
  ACTION_ROLES,
  DICTIONARY_ROLES,
} from 'pages/PageAdmin/constants';
import PageAuth from 'pages/PageAuth';
import PageBankruptPlural from 'pages/PageBankrupt/Plural';
import PageClientPlural from 'pages/PageClient/Plural';
import PageClientSingle from 'pages/PageClient/Single';
import PageCourtPlural from 'pages/PageCourt/Plural';
import PageInfoPlural from 'pages/PageInfo/Plural';
import { PageLeadPlural } from 'pages/PageLead/Plural';
import PageNotFound from 'pages/PageNotFound';
import PageTaskPlural from 'pages/PageTask/Plural';
import PageTaskSingle from 'pages/PageTask/Single';
import PageVerificationPlural from 'pages/PageVerification/Plural';

import Header from './Header/Header';
import MobileContext from './MobileContext';

const checkIsAdminAllowed = (userRoles: string[]) => hasIntersection(userRoles, [
  ROLE_NAMES.SUPER_ADMIN,
  ROLE_NAMES.SMS_GATEWAY_OPERATOR,
  ROLE_NAMES.SMS_LIST_SENDER,
  ROLE_NAMES.CAR_BLACK_LIST_UPLOADER,
  ROLE_NAMES.STAT_BALANCE_RELOADER,
  ...ACTION_ROLES,
  ...DICTIONARY_ROLES,
]);

const checkIsBankruptsAllowed = (userRoles: string[]) => hasIntersection(userRoles, [
  ROLE_NAMES.SUPER_ADMIN,
  ROLE_NAMES.COLLECTION_READ,
  ROLE_NAMES.COLLECTION_WRITE,
  ROLE_NAMES.COLLECTION_ADMIN,
  ROLE_NAMES.SALE_READ,
  ROLE_NAMES.SALE_WRITE,
  ROLE_NAMES.SALE_ADMIN,
]);

const checkIsCourtsAllowed = (userRoles: string[]) => hasIntersection(userRoles, [
  ROLE_NAMES.SUPER_ADMIN,
  ROLE_NAMES.COLLECTION_READ,
  ROLE_NAMES.COLLECTION_WRITE,
  ROLE_NAMES.COLLECTION_ADMIN,
  ROLE_NAMES.SALE_READ,
  ROLE_NAMES.SALE_WRITE,
  ROLE_NAMES.SALE_ADMIN,
]);

const checkIsTasksAllowed = (userRoles: string[]) => hasIntersection(userRoles, [
  ROLE_NAMES.SUPER_ADMIN,
  ROLE_NAMES.COLLECTION_READ,
  ROLE_NAMES.COLLECTION_WRITE,
  ROLE_NAMES.COLLECTION_ADMIN,
  ROLE_NAMES.SALE_READ,
  ROLE_NAMES.SALE_WRITE,
  ROLE_NAMES.SALE_ADMIN,
]);

const checkIsLeadsAllowed = (userRoles: string[]) => hasIntersection(userRoles, [
  ROLE_NAMES.SUPER_ADMIN,
  ROLE_NAMES.LEAD_ADMIN,
  ROLE_NAMES.LEAD_EDIT,
]);

const checkIsVerificationAllowed = (userRoles: string[]) => hasIntersection(userRoles, [
  ROLE_NAMES.SUPER_ADMIN,
  ROLE_NAMES.VERIFICATION_ADMIN,
  ROLE_NAMES.VERIFICATION_SEND,
  ROLE_NAMES.VERIFICATION_WRITE,
  ROLE_NAMES.VERIFICATION_READ,
]);

const checkIsSaleAllowed = (userRoles: string[]) => hasIntersection(userRoles, [
  ROLE_NAMES.SUPER_ADMIN,
  ROLE_NAMES.VERIFICATION_ADMIN,
  ROLE_NAMES.VERIFICATION_SEND,
  ROLE_NAMES.VERIFICATION_WRITE,
  ROLE_NAMES.VERIFICATION_READ,
  ROLE_NAMES.SALE_SEND,
]);

const StyledLayout = styled(Layout)`
  overflow: visible;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${palette.backgroundColor};

  .ant {
    &-layout {
      background: ${palette.backgroundColor};

      &-content {
        padding: 16px 32px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .ant {
      &-layout {
        &-content {
          padding: 16px;
        }
      }
    }
  }
`;

const StyledMain = styled.main`
  position: relative;
  width: 100%;
  height: calc(100% - ${height.headerMobile});

  @media(min-width: 768px) {
    height: calc(100% - ${height.headerTablet});
  }
`;

interface IProps {
  location: {
    pathname: string;
    search : string;
  }
}

const PageLayout: React.FC<IProps> = ({ location }) => {
  const mobile = useDetectMobile();
  const dispatch = useDispatch();
  const userRoles = useSelector(getRoleNamesSelector);

  const emailConfirm = (data: { confirmToken: string | (string | null)[]; id: string | (string | null)[]; }) => dispatch(emailConfirmAction(data));
  const getVersionLatest = () => dispatch(getVersionLatestAction());
  const refreshToken = () => dispatch(refreshTokenAction());
  const setUi = (data: { redirectAfterLogin: string; }) => dispatch(setUiAction(data));

  const isAdminAllowed = useMemo(() => checkIsAdminAllowed(userRoles), [userRoles]);
  const isBankruptsAllowed = useMemo(() => checkIsBankruptsAllowed(userRoles), [userRoles]);
  const isCourtsAllowed = useMemo(() => checkIsCourtsAllowed(userRoles), [userRoles]);
  const isTasksAllowed = useMemo(() => checkIsTasksAllowed(userRoles), [userRoles]);
  const isLeadsAllowed = useMemo(() => checkIsLeadsAllowed(userRoles), [userRoles]);
  const isSaleAllowed = useMemo(() => checkIsSaleAllowed(userRoles), [userRoles]);
  const isVerificationAllowed = useMemo(() => checkIsVerificationAllowed(userRoles), [userRoles]);

  const { setThemeName, themeName } = useContext(GlobalThemeContext);

  const [theme] = useStatePreference(`ui.themeName`, themeName);

  useEffect(() => {
    if (theme) setThemeName(theme);
  }, [theme]);
  useEffect(() => {
    const redirectAfterLogin = location.pathname + location.search;
    if (redirectAfterLogin && !isAuth()) setUi({ redirectAfterLogin });

    const query = qs.parse(window.location.search) || {};

    // email confirm
    if (query.confirmToken && query.id) emailConfirm({ confirmToken: query.confirmToken, id: query.id });

    if (needRefreshToken()) refreshToken();

    if (import.meta.env.NODE_ENV === `production` && isAuth()) getVersionLatest(); // проверяем, не появилась ли новая версия приложения
  }, []);

  return (
    <StyledLayout>
      <MobileContext.Provider value={mobile}>
        {!mobile && <GlobalHotkeys />}
        {isAuth() && <Header />}
        <StyledMain>
          <Switch>
            <Route component={PageAuth} exact path='/auth' />
            {isAuth() ? (
              <Switch>
                <Redirect exact from='/' to='/client' />
                <Redirect exact from='/client/person/:personId' to='/client/person/:personId/info' />
                <Redirect
                  exact
                  from='/verification/:verificationId/person/:personId'
                  to='/verification/:verificationId/person/:personId/info'
                />
                <Route component={PageClientSingle} exact path='/client/person/:personId/:tab' />
                <Route component={PageClientPlural} exact path='/client' />
                {isBankruptsAllowed && (
                  <Route component={PageBankruptPlural} exact path='/bankrupt' />
                )}
                {isCourtsAllowed && (
                  <Route component={PageCourtPlural} exact path='/court' />
                )}
                {isTasksAllowed && (
                  <Route component={PageTaskSingle} path='/tasks/task/:taskId/person/:personId' />
                )}
                {isTasksAllowed && (
                  <Route component={PageTaskSingle} path='/tasks/task/:taskId' />
                )}
                {isTasksAllowed && (
                  <Route component={PageTaskPlural} path='/tasks/:campaign' />
                )}
                {isTasksAllowed && (
                  <Route component={PageTaskPlural} exact path='/tasks' />
                )}
                {isLeadsAllowed && (
                  <Route component={PageLeadPlural} exact path='/leads' />
                )}
                {isAdminAllowed && (
                  <Route component={PageAdmin} path='/admin' />
                )}
                {isVerificationAllowed && [
                  <Route
                    component={PageClientSingle}
                    key='verificationSingle'
                    path='/verification/:verificationId/person/:personId/:tab'
                  />,
                  <Route
                    component={PageVerificationPlural}
                    exact
                    key='verificationPlural'
                    path='/verification'
                  />,
                ]}
                {isSaleAllowed && [
                  <Route
                    component={PageClientSingle}
                    key='saleSingle'
                    path='/sale/:verificationId/person/:personId/:tab'
                  />,
                  <Route
                    component={PageVerificationPlural}
                    exact
                    key='salePlural'
                    path='/sale'
                  />,
                ]}
                <Route
                  component={PageInfoPlural}
                  exact
                  key='infoPlural'
                  path='/info'
                />
                <Route component={PageNotFound} />
              </Switch>
            ) : (
              <Redirect to='/auth' />
            )}
          </Switch>
          <SendSmsModal />
        </StyledMain>
      </MobileContext.Provider>
    </StyledLayout>
  );
};

export default PageLayout;
