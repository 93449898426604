import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
} from 'styles/theme';

export const subHeaderButtonDefaultMixin = css`
  font-family: PT Root UI, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  cursor: pointer;
  border: none;

  transition: background 100ms ease;

  border-radius: 8px;
  padding: 4px 12px;
  color: ${palette.whiteColor};
  background: ${getLightStyleOr(p => rgba(palette.whiteColor(p), 0.1), palette.black10Color)};

  :hover {
    background: ${getLightStyleOr(
    p => rgba(palette.whiteColor(p), 0.2),
    p => rgba(palette.black10Color(p), 0.8),
  )};
  }

  :active {
    background: ${getLightStyleOr(
    p => rgba(palette.whiteColor(p), 0.3),
    p => rgba(palette.black10Color(p), 0.7),
  )};
  }

  :disabled {
    background: ${getLightStyleOr(
    p => rgba(palette.blackColor(p), 0.1),
    p => rgba(palette.black10Color(p), 0.9),
  )};
    color: ${p => rgba(palette.whiteColor(p), 0.5)};
    cursor: not-allowed;
  }

  .anticon:first-child {
    margin-right: 8px;
  }

  @media(min-width: ${breakpoints.lg}) {
    font-size: 14px;
    padding: 8px 16px;

    .anticon:first-child {
      margin-right: 12px;
    }
  }
`;

export const SubHeaderButtonDefault = styled.button`
  ${subHeaderButtonDefaultMixin};
`;
