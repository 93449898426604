import * as React from 'react';
import {
  useEffect,
  useRef,
} from 'react';
import _ from 'lodash';
import { isThisYear } from 'date-fns';
import { useSelector } from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { formatDate } from 'helper';

import { isLoading as isLoadingSelector } from 'models/dialogs/selectors';

import useMobileContext from 'hooks/useMobileContext';

import { LoadingOutlined } from '@ant-design/icons';
import {
  Empty,
  Spin,
} from 'antd';
import { Message } from 'components/Messenger/Message/Message';
import {
  IDialog,
  IMessage,
} from 'components/Messenger/types';
import { ScrollbarsDesktop } from 'components/ScrollbarsDesktop/ScrollbarsDesktop';

const Wrapper = styled(ScrollbarsDesktop)``;

const Days = styled.div`
  margin-top: 24px;
`;

const Day = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const DateTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: ${palette.textSecondaryColor};

  padding: 4px 8px;
  background: ${palette.black10Color};
  border-radius: 16px;
`;

const EmptyWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const formatDateToTitle = (date: string) => (isThisYear(new Date(date))
  ? formatDate(date, `dd MMMM`)
  : formatDate(date, `dd MMMM yyyy`)
);

interface IComponentProps {
  dialog: IDialog;
  onDelete: (message: IMessage) => void;
  onEdit: (message: IMessage) => void;
  onReply: (message: IMessage) => void;
}

export const MessagesList: React.FC<IComponentProps> = ({ dialog, onDelete, onEdit, onReply }) => {
  const messages = dialog ? _.map(dialog.messages, message => ({
    ...message,
    replyTo: _.find(dialog.messages, { id: _.get(message, `replyToMessageId`) }),
  })) : [];

  const isLoading = useSelector(isLoadingSelector);

  const quantity = _.size(messages);
  const previousQuantity = useRef(quantity);
  const scrollRef = useRef(null);
  const messagesEndRef = useRef(null);
  const { mobile } = useMobileContext();

  const days = _.map(
    _.groupBy(messages, mappedMessage => new Date(mappedMessage.createdAt).toLocaleDateString()),
    (groupedMessages, day) => ({ day, groupedMessages }),
  );

  const scrollToBottom = isSmoothly => {
    if (mobile) {
      if (messagesEndRef.current) {
        const behavior = isSmoothly ? `smooth` : `auto`;
        setTimeout(() => {
          messagesEndRef.current.scrollIntoView({ behavior });
        }, 0);
      }
    } else if (scrollRef.current && scrollRef.current.scrollToBottom) {
      const bottom = scrollRef.current.getScrollHeight();
      const behavior = isSmoothly ? `smooth` : `auto`;
      scrollRef.current.view.scroll({
        top : bottom,
        left: 0,
        behavior,
      });
    }
  };

  useEffect(() => {
    if (quantity > 0) {
      const isItemAdded = quantity > previousQuantity.current && previousQuantity.current !== 0;

      if (isItemAdded) {
        scrollToBottom(true);
      } else {
        scrollToBottom(false);
      }
    }
  }, [quantity]);

  return (
    <Wrapper ref={scrollRef}>
      {!_.isEmpty(messages) ? (
        <Days>
          {_.map(days, ({ day, groupedMessages }) => (
            <Day key={day}>
              <DateTitle>{formatDateToTitle(groupedMessages[0].createdAt)}</DateTitle>
              {_.map(groupedMessages, message => (
                <Message
                  dialog={dialog}
                  key={message.id}
                  message={message}
                  onDelete={() => onDelete(message)}
                  onEdit={() => onEdit(message)}
                  onReply={() => onReply(message)}
                />
              ))}
              <div ref={messagesEndRef} />
            </Day>
          ))}
        </Days>
      ) : (
        <EmptyWrapper>
          {isLoading ? (
            <Spin indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />} />
          ) : (
            <Empty
              description='Комментариев пока нет'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </EmptyWrapper>
      )}
    </Wrapper>
  );
};
