import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';
import moment, { Moment } from 'moment';
import styled from 'styled-components';

import { postMarketingBlacklist } from 'models/clients/actions';
import { useClient } from 'models/clients/hooks';
import { useDictionaries } from 'models/dictionaries/hooks';

import {
  DatePicker,
  Form,
  Modal,
  Select as AntSelect,
} from 'antd';
import clientMatchContext from 'pages/PageClient/Single/ClientMatchContext';

const Select = styled(AntSelect)`
  width: 100%;
`;

interface IValues {
  createDtm: moment.Moment | null;
  endDtm: moment.Moment | null;
  type: number | null;
}

type SetValue = (key: keyof IValues, value: any) => void;

interface IProps {
  onClose: () => void;
  visible: boolean;
}

const getMomentValue = (v: Moment | number | string | Date | null) => {
  if (!v) return null;
  if (moment.isMoment(v)) return v;
  return moment(v);
};

export const BlacklistModal: React.FC<IProps> = ({ onClose, visible }) => {
  const dispatch = useDispatch();

  const { personId } = useContext(clientMatchContext) as { personId: string };

  const [, dictionaries] = useDictionaries([`marketingBlacklistType`]);
  const [, client] = useClient(personId);

  const {
    marketingBlacklist = {
      createDtm: moment(),
      endDtm   : null,
      type     : null,
    },
  } = client;

  const [values, setValues] = useState<IValues>({
    ...marketingBlacklist,
    createDtm: marketingBlacklist.createDtm ? moment(marketingBlacklist.createDtm) : moment(),
    endDtm   : getMomentValue(marketingBlacklist.endDtm),
    type     : marketingBlacklist.type || null,
  });
  const setValue:SetValue = (key: string, value: any) => setValues({ ...values, [key]: value });

  const setInitialValues = useCallback(() => {
    setValues({
      ...marketingBlacklist,
      // @ts-ignore
      createDtm: marketingBlacklist.createDtm ? moment(marketingBlacklist.createDtm) : moment(),
      // @ts-ignore
      endDtm   : getMomentValue(marketingBlacklist.endDtm),
      // @ts-ignore
      type     : marketingBlacklist.type || null,
      ...values,
    });
  // @ts-ignore
  }, [marketingBlacklist?.marketingBlacklistId]);

  useEffect(() => {
    setInitialValues()
  }, [setInitialValues]);

  const postBlacklist = useCallback(
    () => {
      dispatch(postMarketingBlacklist({
        personId,
        values: {
          ...values,
          birthDtm : client.birthDate ? moment(client.birthDate).valueOf() : null,
          // @ts-ignore
          createDtm: values.createDtm.valueOf(),
          // @ts-ignore
          endDtm   : values.endDtm.valueOf(),
        },
      }));
    },
    [personId, values, dispatch],
  );

  const onSubmit = useCallback(() => {
    postBlacklist();
    onClose();
  }, [postBlacklist, onClose]);

  const isDisabled = !values.type || !values.createDtm || !values.endDtm;

  const setType = (type: number) => {
    if (!values.endDtm) {
      const marketingBlacklistTypeDictionary = _.find(dictionaries?.marketingBlacklistType, { id: type });
      if (marketingBlacklistTypeDictionary?.term) {
        setValues({
          ...values,
          endDtm: moment(values.createDtm).add(marketingBlacklistTypeDictionary?.term, `days`),
          type,
        });
      }
    } else {
      setValue(`type`, type);
    }
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      okButtonProps={{ disabled: isDisabled }}
      okText='Ok'
      onCancel={onClose}
      onOk={onSubmit}
      open={visible}
      title='Маркетинговые ЧС'
    >
      <Form layout='vertical'>
        <Form.Item label='Причина нахождения в ЧС' required>
          <Select
            autoFocus
            // @ts-ignore
            onChange={setType}
            value={values.type}
          >
            {_.map(dictionaries?.marketingBlacklistType, ({ description, id, name }) => (
              <Select.Option key={id} value={id}>{`${name}${description ? ` (${description})` : ``}`}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Дата занесения' required>
          <DatePicker
            format='DD.MM.YYYY'
            onChange={value => setValue(`createDtm`, value)}
            // @ts-ignore
            value={getMomentValue(values.createDtm)}
          />
        </Form.Item>
        <Form.Item label='Дата выхода' required>
          <DatePicker
            format='DD.MM.YYYY'
            onChange={value => setValue(`endDtm`, value)}
            // @ts-ignore
            value={getMomentValue(values.endDtm)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
