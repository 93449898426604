import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { RightOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  color: ${palette.black80Color};
  font-size: 14px;
  font-weight: 400
`;

const Header = styled.div<{ isCollapsible: boolean }>`
  margin-top: 4px;
  margin-bottom: 4px;
  padding-top: 4px;
  padding-bottom: 4px;

  display: inline-flex;
  align-items: center;

  border-radius: 8px;

  border: 1px solid transparent;
  transition: border-color 200ms ease, background 200ms ease;

  ${p => p.isCollapsible && `
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;

    :hover {
      border-color: ${palette.borderAccentColor(p)};
      background: ${palette.backgroundColor(p)};
    }
  `};

  @media(min-width: ${breakpoints.md}) {
    display: flex;
    padding-top: 8px;
    margin-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
`;

const Title = styled.div`
  font-weight: 500;

  letter-spacing: 0;
  text-align: left;
  color: ${palette.black100Color};
  display: flex;

  font-size: 14px;
  line-height: 14px;

  @media(min-width: ${breakpoints.md}) {
    line-height: 16px;
    font-size: 16px;
  }
`;

const Controls = styled.div`
  margin-left: 8px;

  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  @media(min-width: ${breakpoints.md}) {
    margin-left: 16px;
    > *:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const Content = styled.div<{ isCollapsed: boolean }>`
  margin-top: 4px;
  height: 0;
  opacity: 0;
  overflow: hidden;


  ${p => p.isCollapsed && `
    opacity: 1;
    height: auto;
  `}

  transition: opacity 200ms ease;

  margin-bottom: 16px;

  @media(min-width: ${breakpoints.md}) {
    margin-bottom: 24px;
  }
`;

const CollapseButton = styled.button<{ isCollapsed: boolean }>`
  width: 14px;
  height: 14px;

  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  margin: 0 8px 0 0;

  transition: transform .24s;

  ${p => p.isCollapsed && `
    transform: rotate(90deg);
  `}
  .anticon {
    font-size: 14px;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-right: 16px;

    width: 16px;
    height: 16px;
    .anticon {
      font-size: 16px;
    }
  }
`;

interface IComponentProps {
  children?: React.ReactNode;
  collapsed?: boolean;
  controls?: React.ReactNode;
  title: React.ReactNode;
  onCollapse?(): void;
}

export const LoansTableExpandItem: React.FC<IComponentProps> = ({
  children,
  collapsed = false,
  controls,
  onCollapse,
  title,
}) => {
  const isCollapsible = !!children;
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  useEffect(() => {
    if (isCollapsed && onCollapse) onCollapse();
  }, [onCollapse, isCollapsed]);

  useEffect(() => {
    if (!_.isUndefined(collapsed)) setIsCollapsed(collapsed);
  }, [collapsed]);

  return (
    <Wrapper>
      <Header
        isCollapsible={isCollapsible}
        onClick={() => setIsCollapsed(v => !v)}
      >
        <Title>
          {isCollapsible && (
            <CollapseButton isCollapsed={isCollapsed}>
              <RightOutlined />
            </CollapseButton>
          )}

          {title}

        </Title>

        {controls && (
          <Controls onClick={e => e.stopPropagation()}>
            {controls}
          </Controls>
        )}
      </Header>

      {children && isCollapsed && (
        <Content isCollapsed={isCollapsed}>
          {children}
        </Content>
      )}
    </Wrapper>
  );
};
