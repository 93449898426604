import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment from 'moment/moment';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { getFileSize } from 'helper';

import { getLegalRegistries as getLegalRegistriesAction } from 'models/clients/actions';
import { getLegalRegistriesByLoanId as getLegalRegistriesByLoanIdSelector } from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';
import { getItemsForSelect as getDictionaryOptions } from 'models/dictionaries/selectors';
import { uploadLegalRegistry as uploadLegalRegistryAction } from 'models/task/actions';
import { REGISTRY_ID_DOCUMENT_ID_MAP } from 'models/task/constants';
import { isLoading as isLoadingTaskSelector } from 'models/task/selectors';

import {
  CheckCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Upload,
} from 'antd';

const StyledUpload = styled(Upload.Dragger)<{ $error?: boolean }>`
  border-color: ${p => (p.$error ? palette.errorColor(p) : palette.primaryColor(p))} !important;
  border-style: ${p => (p.$error ? `solid` : `dashed`)} !important;
  border-radius: 8px !important;
`;

const UploadIcon = styled.p`
  color: ${palette.primaryColor};
  font-size: 32px;
`;

const UploadText = styled.p`
  color: ${palette.primaryColor};
`;

const Error = styled.div`
  color: ${palette.redColor};
`;

interface IProps {
  loanId: string,
  onClose: () => void,
  personId: string,
  visible: boolean
}

interface IValues {
  externalRegistryDtm?: moment.Moment | null,
  externalRegistryNumber?: string,
  file ?: File | null,
  registryNumber ?: string | null,
  registryTypeId ?: number | null,
}

interface IErrors {
  externalRegistryDtm?: string,
  externalRegistryNumber?: string,
  file ?: string,
  registryNumber ?: string,
  registryTypeId ?: string,
}

const defaultValues: IValues = {
  externalRegistryDtm   : null,
  externalRegistryNumber: ``,
  file                  : null,
  registryNumber        : null,
  registryTypeId        : null,
};

const LegalRegistryUpload: React.FC<IProps> = ({ loanId, onClose, personId, visible }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(defaultValues);

  const isTaskLoading = useSelector(isLoadingTaskSelector);
  const registryTypes = useSelector(state => getDictionaryOptions(state, `registryType`));
  const [isDictionariesLoading] = useDictionaries([`registryType`]);

  const getRegistries = useCallback(() => {
    dispatch(getLegalRegistriesAction({
      personId,
      query: {
        loanId,
        // startDtm: normalizeMomentToStartOfDay(moment()).valueOf(),
      },
    }));
  }, [personId, dispatch, loanId]);
  const uploadRegistry = useCallback((data: any) => {
    dispatch(uploadLegalRegistryAction({
      data,
      callback: () => {
        getRegistries();
        onClose();
      },
    }));
  }, [dispatch, getRegistries, onClose]);

  const registries = _.map(
    // @ts-ignore
    useSelector(state => getLegalRegistriesByLoanIdSelector(state, personId, loanId)) || [],
    ({ registryNumber, registryTypeId }) => ({
      label: `${registryNumber} (${_.find(registryTypes, { value: registryTypeId })?.label || registryTypeId})`,
      registryTypeId,
      value: registryNumber,
    }),
  );
  useEffect(() => {
    getRegistries()
  }, [getRegistries]);
  const setValue = useCallback((field: any, value: any) => setValues({ ...values, [field]: value }), [setValues, values]);

  useEffect(() => {
    // @ts-ignore
    setValue(`registryTypeId`, _.find(registries, { value: values.registryNumber })?.registryTypeId);
  }, [loanId, values.registryNumber]);

  const onSubmit = () => uploadRegistry({
    ...values,
    // @ts-ignore
    documentTypeId     : REGISTRY_ID_DOCUMENT_ID_MAP[values.registryTypeId] || 80,
    // @ts-ignore
    externalRegistryDtm: values.externalRegistryDtm.format(`DD.MM.yyyy HH:mm:ss`),
    file               : values.file,
  });

  const errors: IErrors = {};
  if (!values.registryNumber) errors.registryNumber = `Необходимо выбрать`;
  if (!values.registryTypeId) errors.registryTypeId = `Не удалось получить идентификатор типа реестра`;
  if (!values.externalRegistryNumber) errors.externalRegistryNumber = `Необходимо указать`;
  if (values.externalRegistryNumber && !(/^[^a-z\s]+$/gi.test(values.externalRegistryNumber))) {
    errors.externalRegistryNumber = `Русские буквы, цифры, спецсимволы`;
  }
  if (!values.externalRegistryDtm) errors.externalRegistryDtm = `Необходимо указать`;
  if (!values.file) errors.file = `Необходимо указать`;

  return (
    <Modal
      cancelText='Отмена'
      centered
      confirmLoading={isTaskLoading || isDictionariesLoading}
      okButtonProps={{ disabled: !_.isEmpty(errors) }}
      okText='Загрузить'
      onCancel={onClose}
      onOk={onSubmit}
      open={visible}
      title='Загрузка почтового реестра'
    >
      <Form layout='vertical'>
        <Form.Item
          label='Номер реестра'
          required
          validateStatus={errors.registryNumber ? `error` : `success`}
        >
          <Select
            onChange={value => setValue(`registryNumber`, value)}
            options={registries}
            placeholder='Выберите номер реестра'
            value={values.registryNumber}
          />
        </Form.Item>
        <Form.Item
          help={errors.externalRegistryNumber}
          label='Номер почтового реестра'
          required
          validateStatus={errors.externalRegistryNumber ? `error` : `success`}
        >
          <Input
            onChange={e => setValue(`externalRegistryNumber`, e.target.value)}
            value={values.externalRegistryNumber}
          />
        </Form.Item>
        <Form.Item
          label='Дата отправки'
          required
          validateStatus={errors.externalRegistryDtm ? `error` : `success`}
        >
          <DatePicker
            onChange={value => setValue(`externalRegistryDtm`, value)}
            // @ts-ignore
            value={values.externalRegistryDtm}
          />
        </Form.Item>
        <Form.Item>
          <StyledUpload
            $error={!!errors.file}
            accept='.pdf, application/pdf'
            customRequest={value => setValue(`file`, value?.file)}
            multiple={false}
            showUploadList={false}
          >
            {values.file
              ? (
                <>
                  <UploadIcon><CheckCircleOutlined /></UploadIcon>
                  <UploadText>{`${values.file.name} (${getFileSize(values.file.size)})`}</UploadText>
                </>
              )
              : (
                <>
                  <UploadIcon><InboxOutlined /></UploadIcon>
                  <UploadText>Кликните или перетащите сюда PDF файл</UploadText>
                </>
              )}
          </StyledUpload>
        </Form.Item>
        {errors.registryNumber && <Error>{errors.registryNumber}</Error>}
      </Form>
    </Modal>
  );
};

export default LegalRegistryUpload;
