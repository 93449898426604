import React, { useState } from 'react';
import { Moment } from 'moment';
import moment from 'moment/moment';
import styled from 'styled-components';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { generateStatbalanceReport } from 'models/admin/api';

import {
  DatePicker as DatePickerDefault,
  Modal,
  notification,
} from 'antd';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
`;

const DatePicker = styled(DatePickerDefault.RangePicker)`
  margin-bottom: 16px;
`;

interface IProps {
  isOpen: boolean;
  onClose: ()=> void;
}

const StatBalance: React.FC<IProps> = ({ isOpen, onClose }) => {
  const [requestDates, setRequestDates] = useState<null | [Moment, Moment]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onGenerate = async () => {
    const defaultError = `При перезагрузке статбаланса произошла ошибка`;
    setIsLoading(true);
    try {
      // @ts-ignore
      parseResponse({
        defaultError,
        errorPath: `data.message`,
        response : await generateStatbalanceReport(requestDates),
      });
      notification.success({ message: `Статбаланс успешно перезагружен` });
    } catch (error) {
      showError({ defaultError, error: error.message });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      cancelText='Отмена'
      centered
      confirmLoading={isLoading}
      okButtonProps={{ disabled: !requestDates }}
      okText='Перезагрузить'
      onCancel={onClose}
      onOk={onGenerate}
      open={isOpen}
      title='Перезагрузка статбаланса'
    >
      <Wrapper>
        <DatePicker
          format='MM/DD/YYYY'
          onChange={([from, to]) => setRequestDates([moment.utc(from).startOf(`day`), moment.utc(to).startOf(`day`)])}
          value={requestDates}
        />
      </Wrapper>
    </Modal>
  );
};

export default StatBalance;
