/* eslint-disable max-len */
import {
  formatDate as formatDateRaw,
  prettifyAmount,
} from 'helper';

export const formatDate = v => formatDateRaw(v, `dd.MM.yyyy`);

export const FIELDS = [
  // activeFlg                           : `Флаг того, что договор открыт`,
  // contractNumber                      : `Номер договора в 1С`,
  // creditStartDtm                      : `Дата открытия договора`,
  // initialLoanTerm                     : `Срок займа по договору (месяцев)`,
  // loanAmount                          : `Сумма займа`,
  // creditEndDtm                        : `Дата планового закрытия договора`,
  {
    label : `Информация`,
    fields: {
      interestRateStr  : { label: `Ставка по договору` },
      loanClosingAmount: {
        label    : `Сколько нужно внести для закрытия договора`,
        normalize: prettifyAmount,
      },
      loanId: { label: `Идентификатор займа` },
    },
  },
  {
    label : `Кредитные каникулы`,
    fields: {
      creditHolidayFlg: {
        label    : `Активны`,
        normalize: v => (v ? `Да` : `Нет`),
      },
      creditHolidayStartDtm: {
        label    : `От`,
        normalize: formatDate,
      },
      creditHolidayEndDtm: {
        label    : `До`,
        normalize: formatDate,
      },
    },
  },
  {
    label : `Общие суммы`,
    fields: {
      currentAccountAmount: {
        label    : `Сумма остатка на текущем счете (переплата)`,
        normalize: prettifyAmount,
      },
      totalIssueAmount: {
        label    : `Общая сумма выдач по договору`,
        normalize: prettifyAmount,
      },
      recommendedPaymentAmount: {
        label    : `Сколько мы рекомендуем внести (с учетом просрочки, планового графика и тд)`,
        normalize: prettifyAmount,
      },
      totalDebt: {
        label    : `Сумма всей задолженности по договору`,
        normalize: prettifyAmount,
      },
      totalDebtCommission: {
        label    : `Сумма задолженности по договору (только комиссии)`,
        normalize: prettifyAmount,
      },
      totalDebtFine: {
        label    : `Сумма задолженности по договору (только штрафы)`,
        normalize: prettifyAmount,
      },
      totalDebtInterest: {
        label    : `Сумма задолженности по договору (только проценты)`,
        normalize: prettifyAmount,
      },
      totalDebtMembershipFee: {
        label    : `Сумма задолженности по договору (только членские взносы)`,
        normalize: prettifyAmount,
      },
      totalDebtPenalty: {
        label    : `Сумма задолженности по договору (только пени)`,
        normalize: prettifyAmount,
      },
      totalDebtStateDuty: {
        label    : `Сумма задолженности по договору (только пени)`,
        normalize: prettifyAmount,
      },
      totalMainDebt: {
        label    : `Сумма задолженности по договору (только основной долг)`,
        normalize: prettifyAmount,
      },
    },
  },
  {
    label : `Просрочки`,
    fields: {
      overdueDebtCommissionPaymentCount: {
        label: `Просрочено платежей`,
      },
      debtInterestTerm: {
        label: `Срок просрочки процентов (дней)`,
      },
      mainDebtTerm: {
        label: `Срок просрочки основного долга (дней)`,
      },
      overdueDebtCommission: {
        label    : `Сумма просроченной задолженности по договору (только комиссии)`,
        normalize: prettifyAmount,
      },
      overdueDebtFine: {
        label    : `Сумма просроченной задолженности по договору (только штрафы)`,
        normalize: prettifyAmount,
      },
      overdueDebtFinePaymentCount: {
        label: `Просрочено платежей по штрафам`,
      },
      overdueDebtFinePaymentDtm: {
        label    : `Дата просроченного платежа по штрафам`,
        normalize: formatDate,
      },
      overdueDebtInterest: {
        label    : `Сумма просроченной задолженности по договору (только проценты)`,
        normalize: prettifyAmount,
      },
      overdueDebtInterestPaymentCount: {
        label: `Просрочено платежей по процентам`,
      },
      overdueDebtInterestPaymentDate: {
        label    : `Дата просроченного платежа по процентам`,
        normalize: formatDate,
      },
      overdueDebtCommissionPaymentDtm: {
        label    : `Дата просроченного платежа по комиссиям`,
        normalize: formatDate,
      },
      overdueDebtMembershipFeePaymentCount: {
        label: `Просрочено платежей по членским взносам`,
      },
      overdueDebtMembershipFeePaymentDtm: {
        label    : `Дата просроченного платежа по членским взносам`,
        normalize: formatDate,
      },
      overdueDebtPaymentCount: {
        label: `Просрочено платежей`,
      },
      overdueDebtPaymentDtm: {
        label    : `Дата просроченного платежа`,
        normalize: formatDate,
      },
      overdueDebtPenalty: {
        label    : `Сумма просроченной задолженности по договору (только пени)`,
        normalize: prettifyAmount,
      },
      overdueDebtPenaltyPaymentCount: {
        label: `Просрочено платежей по пеням`,
      },
      overdueDebtPenaltyPaymentDtm: {
        label    : `Дата просроченного платежа по пеням`,
        normalize: formatDate,
      },
      overdueDebtStateDuty: {
        label    : `Сумма просроченной задолженности по договору (только госпошлины)`,
        normalize: prettifyAmount,
      },
      overdueDebtStateDutyPaymentCount: {
        label: `Просрочено платежей по госпошлинам`,
      },
      overdueDebtStateDutyPaymentDtm: {
        label    : `Дата просроченного платежа по гос пошлинам`,
        normalize: formatDate,
      },
      overdueDebtTerm: {
        label: `Срок просрочки (дней)`,
      },
      overdueMainDebt: {
        label    : `Сумма просроченной задолженности по договору (только основной долг)`,
        normalize: prettifyAmount,
      },
      totalOverdueDebt: {
        label    : `Сумма просроченной задолженности по договору`,
        normalize: prettifyAmount,
      },
      overdueMainDebtInterest: {
        label: `Проценты, начисленные дополнительно к графику платежей на просроченный основной долг`,
      },
      overdueDebtMembershipFee: {
        label    : `Сумма просроченной задолженности по договору (только членские взносы)`,
        normalize: prettifyAmount,
      },
      overdueMainDebtPaymentDtm: {
        label    : `Дата просроченного платежа по основному долгу`,
        normalize: formatDate,
      },
    },
  },
  {
    label : `Ближайший платёж`,
    fields: {
      nearestPaymentAmount: {
        label    : `Сумма ближайшего платежа по графику платежей`,
        normalize: prettifyAmount,
      },
      nearestPaymentDtm: {
        label    : `Дата ближайшего платежа по графику платежей`,
        normalize: formatDate,
      },
      nearestRecommendedPaymentAmount: {
        label    : `Сколько мы рекомендуем внести в ближайший платеж (с учетом просрочки, планового графика и тд)`,
        normalize: prettifyAmount,
      },
      nearestPaymentCommissionAmount: {
        label    : `Сумма ближайшего платежа по графику платежей (только комиссии)`,
        normalize: prettifyAmount,
      },
      nearestPaymentFineAmount: {
        label    : `Сумма ближайшего платежа по графику платежей (только штрафы)`,
        normalize: prettifyAmount,
      },
      nearestPaymentMainDebt: {
        label    : `Сумма ближайшего платежа по графику платежей (только основной долг)`,
        normalize: prettifyAmount,
      },
      nearestPaymentMembershipFeeAmount: {
        label    : `Сумма ближайшего платежа по графику платежей (только членские взносы)`,
        normalize: prettifyAmount,
      },
      nearestPaymentPenaltyAmount: {
        label    : `Сумма ближайшего платежа по графику платежей (только пени)`,
        normalize: prettifyAmount,
      },
      nearestPaymentPercentAmount: {
        label    : `Сумма ближайшего платежа по графику платежей (только проценты)`,
        normalize: prettifyAmount,
      },
    },
  },
  {
    label : `Погашения`,
    fields: {
      totalPaymentAmount: {
        label    : `Погашено всего по договору`,
        normalize: prettifyAmount,
      },
      mainDebtPaymentAmount: {
        label    : `Погашено основного долга по договору`,
        normalize: prettifyAmount,
      },
      interestPaymentAmount: {
        label    : `Погашено процентов по договору`,
        normalize: prettifyAmount,
      },
      commissionPaymentAmount: {
        label    : `Погашено комиссий по договору`,
        normalize: prettifyAmount,
      },
      membershipFeePaymentAmount: {
        label    : `Погашено членских взносов по договору`,
        normalize: prettifyAmount,
      },
    },
  },
  {
    label : `Непросроченное`,
    fields: {
      totalUrgentDebt: {
        label    : `Сумма непросроченной задолженности по договору`,
        normalize: prettifyAmount,
      },
      urgentMainDebt: {
        label    : `Сумма непросроченной задолженности по договору (только основной долг)`,
        normalize: prettifyAmount,
      },
      urgentDebtCommission: {
        label    : `Сумма непросроченной задолженности по договору (только комиссии)`,
        normalize: prettifyAmount,
      },
      urgentDebtFine: {
        label    : `Сумма непросроченной задолженности по договору (только штрафы)`,
        normalize: prettifyAmount,
      },
      urgentDebtInterest: {
        label    : `Сумма непросроченной задолженности по договору (только проценты)`,
        normalize: prettifyAmount,
      },
      urgentDebtMembershipFee: {
        label    : `Сумма непросроченной задолженности по договору (только членские взносы)`,
        normalize: prettifyAmount,
      },
      urgentDebtPenalty: {
        label    : `Сумма непросроченной задолженности по договору (только штрафы)`,
        normalize: prettifyAmount,
      },
      urgentDebtStateDuty: {
        label    : `Сумма непросроченной задолженности по договору (только гос пошлины)`,
        normalize: prettifyAmount,
      },
    },
  },
];
