import {
  validatePhone,
  validateUuid,
} from 'helper';

export const REQUIRED = {
  message : `Необходимо указать`,
  required: true,
};

export const PHONE = {
  validator: (pass, value) => {
    if (validatePhone(value)) return Promise.resolve();
    return Promise.reject(new Error(`Введите корректный телефон (11 цифр)`));
  },
};
export const UUID = {
  validator: (pass, value) => {
    if (!value || validateUuid(value)) return Promise.resolve();

    return Promise.reject(new Error(`Введите корректный uuid`));
  },
};

export const JSON_VALUE = {
  validator: (pass, value) => {
    if (!value) return Promise.resolve();
    try {
      JSON.parse(value);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
