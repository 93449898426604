import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { Mentions } from 'antd';
import {OptionProps} from "antd/es/mentions";

const Wrapper = styled(Mentions)`
  color: ${palette.black80Color};
  font-size: 16px;
  line-height: 19.6px;
  font-weight: 400;
  background: ${palette.backgroundColor};
  resize: none;

  border: none;
  border-radius: 8px;
  width: 100%;

  ::placeholder {
    transition: color 200ms ease;
    color: ${palette.black60Color};
  }

  :focus {
    ::placeholder {
      color: ${palette.black40Color};
    }
  }

  &.ant-mentions:hover, &.ant-mentions-focused {
    border: none;
    box-shadow: none;
  }

  &.ant-mentions > textarea {
    padding: 8px;
    background: ${palette.backgroundColor};
  }

  @media(min-width: ${breakpoints.md}) {
    &.ant-mentions > textarea {
      padding: 14px 16px;
    }
  }
`;

interface IComponentProps {
  inputRef: React.RefObject<HTMLTextAreaElement>;
  mentionOptions: { id: string; title: string; }[];
  message: string;
  onChange: (message: string) => void;
  onPressEnter: (e: any) => void;
  // @ts-ignore
  onSelectMention: ({ key: any }) => void;
}

export const InputTextarea: React.FC<IComponentProps> = ({
  inputRef,
  mentionOptions,
  message,
  onChange,
  onPressEnter,
  onSelectMention,
}) => (
  // @ts-ignore
  <Wrapper
    autoFocus
    autoSize={{ minRows: 1, maxRows: 6 }}
    onChange={onChange}
    onPressEnter={onPressEnter}
    onSelect={(option: OptionProps) => onSelectMention({ key: option.id })}
    placeholder='Напишите текст...'
    // @ts-ignore
    ref={inputRef}
    value={message}
  >
    {_.map(mentionOptions, option => (
      <Mentions.Option key={option.id} value={option.title}>
        {option.title}
      </Mentions.Option>
    ))}
  </Wrapper>
);
