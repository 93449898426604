import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

interface State {
  [key: string]: any;
}

interface Item {
  [key: string]: any;
}

export const getState = (state: State) => state?.[model] || {};

export const getItems = createSelector(getState, ({ items }: { items: Item[] }) => items);

export const getItem = createSelector(
  [getItems, (state: State, id: number) => id],
  (items: Item[], id: number) => _.find(items, { id }),
);

export const getItemByPersonId = createSelector(
  [
    getItems,
    (state: State, personId: string) => personId,
  ],
  (items: Item[], personId: string) => _.find(items, { personId }),
);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }: { isLoading: boolean }) => _isLoading);
