import React from 'react';
import _ from 'lodash';

import { IFedresursItem } from 'models/clients/types';

import { Collapse } from 'antd';
import { FIELDS_VIEW } from 'components/Modal/BankruptModal/FedResurs/FIELDS_VIEW';
import { FedResursItem } from 'components/Modal/BankruptModal/FedResurs/Item';
import {
  Column,
  StyledCollapse,
} from 'components/Modal/BankruptModal/FedResurs/styled';

interface IItemsList {
  data: IFedresursItem[];
}

const ItemsList: React.FC<IItemsList> = React.memo(({ data }) => (
  <StyledCollapse>
    <Collapse.Panel header='Федресурс' key='Федресурс'>
      <Collapse>
        <Column>
          {_.map(data, (item, index) => _.map(FIELDS_VIEW, (metaItem, metaIndex) => (
            // @ts-ignore
            <FedResursItem key={`${index}_${metaIndex}`} {...metaItem} data={item} />
          )))}
        </Column>
      </Collapse>
    </Collapse.Panel>
  </StyledCollapse>
));

export default ItemsList;
