import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useSelector } from 'dva';
import { parseResponse } from 'api/helpers';
import { hasIntersection } from 'helper';
import { getRequisitesByDealershipId } from 'models/dictionaries/api';
import { DEALERSHIP_EDITOR, PARTNER_REQUISITE_EDITOR, SUPER_ADMIN } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Select } from 'antd';

// Стили
const LinkedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  padding-bottom: 16px;
`;

const LinkedTitle = styled.div`
  font-size: 16px;
  margin-bottom: 12px;
`;

const LinkedItems = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`;

const LinkedItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 4px;

  &:first-child {
    margin-top: 0;
  }
`;

const LinkedItemId = styled.span`
  margin-bottom: 2px;
  margin-right: 6px;
  font-weight: bold;
`;

const LinkedItemDescription = styled.span`
  margin-bottom: 2px;
  margin-right: auto;
`;

const UnlinkButton = styled(Button)`
  flex-shrink: 0;
  margin-left: 4px;
  opacity: 0;

  @media screen and (max-width: 767px) {
    opacity: 1;
  }
`;

const SelectStyled = styled(Select)`
  width: 100%;
`;

const LinkModal = ({
                     entity,
                     close,
                     modalTitle = 'Связать',
                     item,
                     targetItems: _targetItems,
                     onSubmit,
                     onUnlink,
                     visible,
                   }) => {
  const userRoles = useSelector(getRoleNamesSelector);

  let targetItems = _.map(_targetItems, ({ id: _id, name }) => ({ label: name, value: _id }));
  const grantRequisiteUnlink = hasIntersection(userRoles, [SUPER_ADMIN, DEALERSHIP_EDITOR, PARTNER_REQUISITE_EDITOR]);
  const [linkedRequisites, setLinkedRequisites] = useState([]);
  const [value, onChange] = useState([]);
  const [itemUnlinking, setItemUnlinking] = useState(null);

  const getLinkedRequisites = async dealershipId => {
    const result = parseResponse({
      defaultError: 'При запросе реквизитов произошла ошибка',
      response: await getRequisitesByDealershipId(dealershipId),
    });

    setLinkedRequisites(_.filter(result, { activeFlg: true }));
  };

  const submit = () => {
    const { id, link } = item;
    const { entity: targetEntity, sourceId, targetId } = link;
    onSubmit({
      data: {
        [sourceId]: [id],
        [targetId]: value,
      },
      entity,
      targetEntity,
    });
    onChange([]);
    close();
  };

  const unlink = () => {
    setItemUnlinking(null);
    onUnlink({
      data: { dealershipId: item.id, requisiteId: itemUnlinking },
      entity,
      targetEntity: item.link?.entity,
      callback: () => getLinkedRequisites(item.id),
    });
  };

  useEffect(() => {
    if (entity === 'dealership' && item?.id) getLinkedRequisites(item.id);
  }, [entity, item?.id]);

  targetItems = _.reject(targetItems, ({ value }) => !!_.find(linkedRequisites, { id: value }));

  return (
    <Modal
      cancelText='Отмена'
      centered
      maskClosable={false}
      okText='Связать'
      onCancel={close}
      onOk={submit}
      open={visible}
      title={modalTitle}
    >
      {!_.isEmpty(linkedRequisites) && (
        <LinkedContainer>
          <LinkedTitle>Привязанные реквизиты</LinkedTitle>
          <LinkedItems>
            {_.map(linkedRequisites, ({ description, id: _id }) => {
              const showPopover = _id === itemUnlinking;
              return (
                <LinkedItem key={_id}>
                  <LinkedItemId>{`${_id}. `}</LinkedItemId>
                  <LinkedItemDescription>{description}</LinkedItemDescription>
                  {grantRequisiteUnlink && (
                    <Popconfirm
                      cancelText='Нет'
                      icon={<DeleteOutlined style={{ color: 'red' }} />}
                      okText='Отвязать'
                      onCancel={() => setItemUnlinking(null)}
                      onConfirm={unlink}
                      open={showPopover}
                      title='Отвязать реквизиты?'
                    >
                      <UnlinkButton
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => setItemUnlinking(_id)}
                        size='small'
                        title='Отвязать реквизиты'
                        type='text'
                      />
                    </Popconfirm>
                  )}
                </LinkedItem>
              );
            })}
          </LinkedItems>
        </LinkedContainer>
      )}
      <SelectStyled
        mode='multiple'
        onChange={onChange}
        options={targetItems}
        value={value}
      />
    </Modal>
  );
};

export default LinkModal;
