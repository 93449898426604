import _ from 'lodash';
import { createSelector } from 'reselect';

import { getItems as getOperatorsSelector } from 'models/operators/selectors';
import { getItems as getRolesSelector } from 'models/roles/selectors';

export const getTaskStatusOwnersSelector = createSelector(
  getOperatorsSelector,
  operators => ([
    { value: null, label: `Отсутствует` },
    ..._.sortedUniqBy(
      _.sortBy(
        _.map(
          operators,
          ({ id, user: { fullName } }) => ({ value: id, label: fullName }),
        ),
        ({ label }) => label,
      ),
      ({ label }) => label,
    ),
  ]),
);

export const getRolesOptions = createSelector(
  getRolesSelector,
  roles => [
    {
      value: null,
      label: `Нет`,
    },
    ..._.map(_.sortBy(roles, ({ name }) => name), ({ description, id, name }) => ({
      value: id,
      label: `${name}${description ? ` (${description})` : ``}`,
    })),
  ],
);
