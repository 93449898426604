import {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';

import {
  convertHeicToJpeg,
  validateFileType,
} from 'helper';

import { RcFile } from 'antd/lib/upload';
import {
  ACCEPT,
  HEIC_HEIF,
} from 'components/Messenger/constants';

const isFileExist = (file: File, files: File[]) => _.find(
  files,
  { name: file?.name, size: file?.size, lastModified: file?.lastModified },
);

export const useInputFiles = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const clearFiles = () => {
    setFiles([]);
  };

  const removeFileByIndex = (index: number) => {
    setFiles(_.filter(files, (file, i) => i !== index));
  };

  const addFile = (file?: RcFile | File) => {
    // @ts-ignore
    if (isFileExist(file, files) || !file) {
      return;
    }

    if (validateFileType(HEIC_HEIF, file)) {
      setIsProcessing(true);

      convertHeicToJpeg(file)
        .then(convertedFile => {
          // @ts-ignore
          setFiles(v => [...v, convertedFile]);
          setIsProcessing(false);
        })
        .catch(() => {
          setIsProcessing(false);
        });
    } else if (validateFileType(ACCEPT, file)) {
      return setFiles(v => [...v, file]);
    }
  };

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      // @ts-ignore
      const { items } = event.clipboardData;
      const filesToPaste = _.filter(items, { kind: `file` });

      if (filesToPaste.length) {
        event.preventDefault();
      }

      _.forEach(filesToPaste, file => {
        addFile(file.getAsFile());
      });
    };

    window.addEventListener(`paste`, handlePaste);

    return () => {
      window.removeEventListener(`paste`, handlePaste);
    };
  }, []);

  return { files, clearFiles, removeFileByIndex, addFile, isProcessing };
};
