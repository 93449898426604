import _ from 'lodash';

import { useStatePreference } from 'hooks/useStatePreference';

export function useSendButtonDisabled({
  amountSplit,
  approved,
  carDataWarningShow,
  decisionHasBeenMade,
  isResultsComplete,
  showAddSplitButton,
  showAmountSplitWarning,
}: any) {
  const [isEmptyAutoSend] = useStatePreference(`verification.isEmptyAutoSend`, false);
  const [isResultsIncompleteSend] = useStatePreference(`verification.isResultsIncompleteSend`, false);

  // блокируем отправку повторного одобрение
  if (decisionHasBeenMade && approved) {
    return true;
  }

  // блокируем отправку одобрения при незаполненных данных авто, если в настройках не выбрано "Решение без данных авто"
  if (approved && (carDataWarningShow && !isEmptyAutoSend) && (!isResultsComplete && !isResultsIncompleteSend)) {
    return true;
  }

  if (showAmountSplitWarning) {
    // сумма разбивок не совпадает с суммой выдачи
    return true;
  }

  if (showAddSplitButton && _.isEmpty(amountSplit)) {
    // доступна разбивка, но не заполнена
    return true;
  }

  if (!_.isEmpty(amountSplit)) {
    // в какой-либо разбивке не указан партнер или сумма
    return _.some(amountSplit, s => (!s.partnerRequisiteId || !s.amount));
  }

  return false;
}
