import { useContext } from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';

import { getClientCars as getClientCarsSelector } from 'models/clients/selectors';
import { useVerification } from 'models/verifications/hooks/useVerification';

import { usePath } from 'hooks/usePath';

import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';

interface IUseVerificationPathProps {
  firstPathBlock?: string;
  verificationId?: number;
}

interface IUseVerificationPath {
  isSale: boolean;
  onCarEditClick: () => void;
  onResultsClick: () => void;
}
export const useVerificationPath = ({
  firstPathBlock: firstPathBlockProps,
  verificationId: verificationIdProps,
}: IUseVerificationPathProps): IUseVerificationPath => {
  const { replacePath } = usePath();
  const { personId, verificationId: verificationIdContext } = useContext(ClientMatchContext) as { personId: string; verificationId: number; };
  const verificationId = verificationIdContext || verificationIdProps;

  // @ts-ignore
  const [, verification] = useVerification(verificationId);
  const cars = useSelector(state => getClientCarsSelector(state, personId));

  const { applicationId } = verification;
  const car = _.find(cars, ({ applicationIds }) => _.includes(applicationIds, applicationId));
  const carNumber = car?.carNumber || ``;

  const pathBlocks = _.split(window.location.pathname, `/`);
  const firstPathBlock = firstPathBlockProps || pathBlocks[1];
  const isSale = firstPathBlock === `sale`;
  const basePath = `/${firstPathBlock}/${verificationId}/person/${personId}`;

  const onCarEditClick = () => {
    replacePath(`${basePath}/car?carEdit=${carNumber}&applicationId=${applicationId}`);
  };

  const onResultsClick = () => {
    replacePath(`${basePath}/check`);
  };

  return {
    isSale,
    onCarEditClick,
    onResultsClick,
  };
};
