import _ from 'lodash';

import { IVerificationResult } from 'models/dictionaries/types';

export const mapResultsForSelect = (verificationResults: IVerificationResult[]) => _.map(
  verificationResults,
  verificationResult => ({
    label: verificationResult.description || verificationResult.name,
    value: verificationResult.id,
  }),
);
