import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { parseResponse } from 'api/helpers';
import {
  downloadBlob,
  showError,
} from 'helper';

import { getClientLoans } from 'models/clients/actions';
import { generateDocumentPackage } from 'models/clients/api';
import {
  getClientApplication,
  getClientFullName,
  getClientLoanByApplicationId,
} from 'models/clients/selectors';
import {
  IDoc,
  ILoan,
} from 'models/clients/types';
import { useDictionaries } from 'models/dictionaries/hooks';
import {
  IDocumentGroup,
  IDocumentPackage,
  IDocumentType,
} from 'models/dictionaries/types';
import { ATTORNEY_TYPE_ID } from 'models/operators/constants';
import { getAttorney } from 'models/operators/selectors';

import {
  Form,
  Modal,
  notification,
  Select,
} from 'antd';

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

const StyledForm = styled(Form)`
  .ant-form-item-label>label {
    white-space: pre-wrap;
  }
`;

interface IValues {
  additionalPackageId: number | null;
  mainPackageId: number | null;
}

interface IErrors {
  mainPackageId?: string;
}

interface IDictionaries {
  documentGroup: IDocumentGroup[];
  documentPackage: IDocumentPackage[];
  documentType: IDocumentType[];
}

interface IProps {
  applicationId: string;
  docs: IDoc[];
  isVisible: boolean;
  personId: string;
  onClose?(): void;
  onSuccess?(): void;
}

const defaultValues: IValues = {
  additionalPackageId: null,
  mainPackageId      : null,
};

const validateValues = (values: IValues): IErrors => {
  const errors: IErrors = {};
  if (!values.mainPackageId || !Number.isInteger(values.mainPackageId)) {
    errors.mainPackageId = `Выберите тип основного пакета`;
  }
  return errors;
};

const getMissingClientDocTypeIds = (
  documentPackage: IDocumentPackage,
  clientDocs: IDoc[],
  omitDocumentTypeIds: number[] = [],
  attorney?: string,
): number[] => {
  const clientDocTypes = new Set(clientDocs.map(doc => doc.type));
  const omitDocumentTypeIdsSet = new Set(omitDocumentTypeIds);
  return documentPackage?.typeIds?.filter(
    typeId => (
      (
        !clientDocTypes.has(typeId) && !omitDocumentTypeIdsSet.has(typeId))
      || (typeId === ATTORNEY_TYPE_ID && attorney)
    ),
  ) ?? [];
};

const getDocumentTypeLabels = (documentTypes: IDocumentType[], typeIds: number[]) => {
  const typeIdSet = new Set(typeIds);
  return documentTypes
    .filter(docType => typeIdSet.has(docType.id))
    .map(docType => ({ key: docType.id, label: docType.name }));
};

const DocumentPackageDownloadModal: React.FC<IProps> = ({
  applicationId,
  docs,
  isVisible = false,
  onClose = _.noop,
  onSuccess = _.noop,
  personId,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrors>({});
  const [values, setValues] = useState(defaultValues);
  const [isDictionariesLoading, dictionaries] = useDictionaries<IDictionaries>([
    `documentGroup`,
    `documentPackage`,
    `documentType`,
  ], true, true);

  const getLoans = () => dispatch(getClientLoans({ personId }));
  useEffect(() => {
    if (personId) getLoans();
  }, [personId]);

  const loanByApplicationId:ILoan = useSelector(state => getClientLoanByApplicationId(state, personId, applicationId));
  const clientFullName = useSelector(state => getClientFullName(state, personId));
  const application = useSelector(state => getClientApplication(state, personId, applicationId));
  const attorney = useSelector(getAttorney);

  const documentTypeLookup = useMemo(
    () => Object.fromEntries(
      dictionaries?.documentType?.map(({ id, name }) => [id, name]) ?? [],
    ),
    [dictionaries.documentType],
  );

  const versionedDocumentTypeIds = useMemo(
    () => dictionaries?.documentGroup
      ?.find(({ name }) => name === `versioned`)
      ?.typeIds ?? [],
    [dictionaries.documentGroup, documentTypeLookup],
  );

  const { additionalPackages, mainPackages } = _.reduce(
    dictionaries.documentPackage,
    (result, documentPackage) => {
      if (documentPackage.isAdditional) {
        // @ts-ignore
        result.additionalPackages.push(documentPackage);
      } else {
        // @ts-ignore
        result.mainPackages.push(documentPackage);
      }
      return result;
    },
    { additionalPackages: [], mainPackages: [] },
  );

  const setValue = useCallback(
    (field: keyof IValues, data: number) => {
      setValues(prevValues => ({
        ...prevValues,
        [field]: data,
      }));
    },
    [],
  );

  useEffect(() => {
    setErrors(validateValues(values))
  }, [values]);

  const additionalPackage = useMemo(
    () => _.find(additionalPackages, { id: values.additionalPackageId }),
    [values.additionalPackageId],
  );
  const mainPackage = useMemo(
    () => _.find(mainPackages, { id: values.mainPackageId }),
    [values.mainPackageId],
  );

  const missingDocTypeIdsAdditionalPackage = useMemo(
    // @ts-ignore
    () => getMissingClientDocTypeIds(additionalPackage, docs, versionedDocumentTypeIds, attorney),
    [values.additionalPackageId, docs?.length, dictionaries.documentType?.length, attorney],
  );
  const missingDocTypeIdsMainPackage = useMemo(
    // @ts-ignore
    () => getMissingClientDocTypeIds(mainPackage, docs, versionedDocumentTypeIds, attorney),
    [values.mainPackageId, docs?.length, dictionaries.documentType?.length, attorney],
  );
  const missingDocTypesAdditionalPackage = useMemo(
    () => getDocumentTypeLabels(dictionaries.documentType, missingDocTypeIdsAdditionalPackage),
    [missingDocTypeIdsAdditionalPackage, dictionaries.documentType?.length],
  );
  const missingDocTypesMainPackage = useMemo(
    () => getDocumentTypeLabels(dictionaries.documentType, missingDocTypeIdsMainPackage),
    [missingDocTypeIdsMainPackage, dictionaries.documentType?.length],
  );

  const reset = useCallback(() => setValues(defaultValues), []);

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const handleSuccess = useCallback(() => {
    onSuccess();
    handleClose();
  }, [handleClose, onSuccess]);

  const docsToSend = _.filter(docs, ({ type }) => (
    // @ts-ignore
    _.includes(mainPackage?.typeIds, type) || _.includes(additionalPackage?.typeIds, type)
  ));
  const versionedDocumentTypeIdsToSend = _.filter(versionedDocumentTypeIds, type => (
    // @ts-ignore
    _.includes(mainPackage?.typeIds, type) || _.includes(additionalPackage?.typeIds, type)
  ));

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    const defaultError = `При формировании пакета ГАС произошла ошибка.`;
    try {
      const blob = parseResponse({
        defaultError,
        errorPath: `data.message`,
        // @ts-ignore
        response : await generateDocumentPackage({
          ...values,
          applicationId,
          personId,
          docs                    : docsToSend,
          loanCreatedAt           : new Date(loanByApplicationId?.createDtm),
          productId               : application?.productId,
          versionedDocumentTypeIds: versionedDocumentTypeIdsToSend,
        }),
      });
      // @ts-ignore
      downloadBlob(blob, `${mainPackage.description} ${clientFullName}`);
      handleSuccess();
      notification.success({ message: `Пакет сформирован` });
    } catch (e) {
      // @ts-ignore
      showError({ error: e.toString() || defaultError });
    } finally {
      setIsLoading(false);
    }
  }, [handleSuccess, values]);

  const isDocumentsEmpty = _.isEmpty(docsToSend) && _.isEmpty(versionedDocumentTypeIdsToSend);
  const isSubmitDisabled = isLoading || isDictionariesLoading || Object.keys(errors).length > 0 || isDocumentsEmpty;

  return (
    <Modal
      cancelText='Отмена'
      centered
      maskClosable={false}
      okButtonProps={{ disabled: isSubmitDisabled }}
      okText='Сформировать'
      onCancel={handleClose}
      onOk={onSubmit}
      open={isVisible}
      title='Формирование пакета ГАС'
    >
      <StyledForm layout='vertical'>
        <Form.Item
          help={errors.mainPackageId}
          label='Основной пакет'
          required
          validateStatus={errors.mainPackageId ? `error` : `success`}
        >
          <StyledSelect
            fieldNames={{
              label: `description`,
              value: `id`,
            }}
            // @ts-ignore
            onChange={(value: number) => setValue(`mainPackageId`, value)}
            optionFilterProp='label'
            options={mainPackages}
            placeholder='Основной пакет'
            showSearch
            value={values.mainPackageId}
          />
        </Form.Item>

        {!_.isEmpty(missingDocTypesMainPackage) && (
          <Form.Item
            label='В основном пакете у клиента не хватает:'
          >
            <ul>
              {_.map(missingDocTypesMainPackage, ({ key, label }) => (
                <li key={key}>{label}</li>
              ))}
            </ul>
          </Form.Item>
        )}

        {// @ts-ignore
          mainPackage?.isAdditionalAvailable && (
          <Form.Item
            label='Дополнительный пакет'
          >
            <StyledSelect
              fieldNames={{
                label: `description`,
                value: `id`,
              }}
              // @ts-ignore
              onChange={(value: number) => setValue(`additionalPackageId`, value)}
              optionFilterProp='label'
              options={additionalPackages}
              placeholder='Дополнительный пакет'
              showSearch
              value={values.additionalPackageId}
            />
          </Form.Item>
        )}

        {!_.isEmpty(missingDocTypesAdditionalPackage) && (
          <Form.Item
            label='В дополнительном пакете у клиента не хватает:'
          >
            <ul>
              {_.map(missingDocTypesAdditionalPackage, ({ key, label }) => (
                <li key={key}>{label}</li>
              ))}
            </ul>
          </Form.Item>
        )}
        {values.mainPackageId && isDocumentsEmpty && (
          <Form.Item
            label={`В выбранном пакете отсутствуют документы - формирование невозможно.\n
Они могут отсутствовать в справочнике пакетов документов, либо не загружены у клиента к данной заявке`}
          />
        )}
      </StyledForm>
    </Modal>
  );
};

export default DocumentPackageDownloadModal;
