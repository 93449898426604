import React, { useState } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { REQUIRED } from 'constants/FORM_VALIDATION_RULES';
import {
  getNumber,
  prettifyNumber,
} from 'helper';

import { generateRepaymentLink as generateRepaymentLinkAction } from 'models/clients/actions';
import { getIsLoading as isLoadingSelector } from 'models/clients/selectors';

import {
  Form,
  InputNumber,
  Modal,
  Select,
} from 'antd';
import { CopyIconButton } from 'components/CopyIconButton/CopyIconButton';

const PAYMENT_GATEWAYS = [
  {
    label: `Cloud`,
    value: 1,
  },
  {
    label: `Ecommpay`,
    value: 7,
  },
];

interface IComponentProps {
  isVisible: boolean;
  loanId: string;
  personId: string;
  onClose(loanId: string): void;
}

interface IValues {
  paymentGatewayId: number;
  sum: number;
}

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const LinkWrapper = styled.div`
  display: flex;
`;

const StyledLink = styled.a`
  max-width: calc(100% - 32px);
`;

const RepaymentLinkGenerateModal: React.FC<IComponentProps> = ({ isVisible, loanId, onClose, personId }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [link, setLink] = useState<string>(``);

  const generateLink = (values: IValues) => dispatch(generateRepaymentLinkAction({
    ...values,
    loanId,
    personId,
    setLink,
  }));

  const isLoading = useSelector(isLoadingSelector);

  return (
    <Modal
      cancelText='Отмена'
      centered
      confirmLoading={isLoading}
      okText='Сформировать'
      onCancel={() => {
        form.resetFields();
        setLink(``);
        onClose(loanId);
      }}
      onOk={async () => {
        const values = await form.validateFields();
        setLink(``);
        generateLink(values);
      }}
      open={isVisible}
      title='Ссылка на оплату'
    >
      <Form form={form} layout='vertical'>
        <Form.Item
          label='Платёжный шлюз'
          name='paymentGatewayId'
          required
          rules={[REQUIRED]}
        >
          <Select options={PAYMENT_GATEWAYS} />
        </Form.Item>
        <Form.Item
          label='Сумма'
          name='sum'
          required
          rules={[
            REQUIRED,
            {
              validator: (pass, value) => {
                if (_.toNumber(value) > 0) return Promise.resolve();
                return Promise.reject(new Error(`Сумма должна быть больше нуля`));
              },
            },
          ]}
        >
          <StyledInputNumber
            formatter={prettifyNumber}
            // @ts-ignore
            parser={getNumber}
            placeholder='Сумма, ₽'
            precision={2}
          />
        </Form.Item>
      </Form>
      {link && (
        <LinkWrapper>
          <StyledLink href={link} rel='noopener noreferrer' target='_blank'>{link}</StyledLink>
          <CopyIconButton valueToCopy={link} />
        </LinkWrapper>
      )}
    </Modal>
  );
};

export default RepaymentLinkGenerateModal;
