
import {
  useCallback,
  useMemo,
} from 'react';
import {
  routerRedux,
  useDispatch,
} from 'dva';

export function usePath() {
  const dispatch = useDispatch();
  const pushPath = useCallback((path: string) => dispatch(routerRedux.push(path)), [dispatch]);
  const replacePath = useCallback((path: string) => dispatch(routerRedux.replace(path)), [dispatch]);

  return useMemo(() => ({
    pushPath,
    replacePath,
  }), [pushPath, replacePath]);
}
