import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { getLegalCourtResolutions as getLegalCourtResolutionsAction } from 'models/clients/actions';
import {
  getIsLoading as isClientLoadingSelector,
  getLegalCourtResolutions,
} from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';
import { getItems as getDictionaryOptions } from 'models/dictionaries/selectors';
import { getItem as selectTaskById } from 'models/task/selectors';

import {
  Button,
  Space,
} from 'antd';
import { CourtResolutionModal } from 'components/Modal/CourtResolutionModal';
import { CourtTable } from 'pages/PageCourt/Plural/CourtTable';
import { getResolutionsColumns } from 'pages/PageTask/Single/Courts/constants';
import {
  Table,
  Title,
  Wrap,
} from 'pages/PageTask/Single/Courts/styled';

interface IProps {
  taskId: number;
}

const Courts: React.FC<IProps> = ({ taskId }) => {
  const dispatch = useDispatch();
  const { loanId, personId } = useSelector(state => selectTaskById(state, taskId));

  const loadCourtResolutions = useCallback(
    () => dispatch(getLegalCourtResolutionsAction({ personId })),
    [personId, dispatch],
  );

  useEffect(() => {
      if (personId) loadCourtResolutions();
    },
    [loadCourtResolutions, personId],
  );

  const resolutions = useSelector(state => getLegalCourtResolutions(state, personId));
  const resolutionGroupTypes = useSelector(state => getDictionaryOptions(state, `resolutionGroupType`));
  const resolutionTypes = useSelector(state => getDictionaryOptions(state, `resolutionType`));
  const [isDictionariesLoading] = useDictionaries([`resolutionType`, `resolutionGroupType`]);
  const isLoading = useSelector(isClientLoadingSelector) || isDictionariesLoading;
  const [isResolutionModalOpen, setIsResolutionModalOpen] = useState(false);
  const [currentResolutionId, setCurrentResolutionId] = useState<string | null>(null);

  const openCourtResolutionModal = (last: boolean) => {
    setIsResolutionModalOpen(true);
    setCurrentResolutionId(last ? resolutions?.[0]?.resolutionId : null);
  };

  return (
    <Wrap>
      <Space>
        <Button onClick={() => openCourtResolutionModal(false)}>
          Создать историю хода исполнительного производства
        </Button>
        <Button
          disabled={_.isEmpty(resolutions)}
          onClick={() => openCourtResolutionModal(true)}
        >
          Редактировать последнюю историю
        </Button>
      </Space>
      <Title>Судебные производства</Title>
      <CourtTable loanId={loanId} personId={personId} tableScrollY={400} />
      <Title>Постановления</Title>
      <Table
        // @ts-ignore
        columns={getResolutionsColumns(resolutionGroupTypes, resolutionTypes)}
        dataSource={resolutions}
        loading={isLoading}
        pagination={false}
        rowKey='resolutionId'
      />
      <CourtResolutionModal
        isVisible={isResolutionModalOpen}
        loanId={loanId}
        onClose={() => setIsResolutionModalOpen(false)}
        onSuccess={loadCourtResolutions}
        personId={personId}
        resolutionId={currentResolutionId}
      />
    </Wrap>
  );
};

export default Courts;
