import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment from 'moment';

import { editLegalCourtResolution } from 'models/clients/actions';
import {
  getIsLoading as isLoadingSelector,
  getLegalCourtResolutionById,
  getLegalCourtsWithEPNForSelect,
} from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';
import {
  getCourtResolutionTypes,
  getItemsForSelect as getDictionaryOptions,
} from 'models/dictionaries/selectors';

import {
  DatePicker,
  Form,
  Modal,
  Select,
} from 'antd';

interface IProps {
  isVisible: boolean
  loanId: string,
  personId: string,
  resolutionId: string | null,
  onClose():void,
  onSuccess():void,
}

interface IValues {
  createDtm: moment.Moment | null;
  enforcementProceedingsNumber: string;
  resolutionGroupId: number;
  resolutionTypeId: number;
}

interface IErrors {
  createDtm?: string;
  enforcementProceedingsNumber?: string;
  resolutionGroupId?: string;
  resolutionTypeId?: string;
}

const validateValues = (values: IValues) => {
  const errors: IErrors = {};
  if (!values.enforcementProceedingsNumber) errors.enforcementProceedingsNumber = `Необходимо выбрать`;
  if (!values.resolutionTypeId) errors.resolutionTypeId = `Необходимо выбрать`;
  if (!values.resolutionGroupId) errors.resolutionGroupId = `Необходимо выбрать`;
  if (!values.createDtm || !moment.isMoment(values.createDtm)) errors.createDtm = `Необходимо выбрать`;
  return errors;
};

const defaultValues: IValues = {
  createDtm                   : null,
  // @ts-ignore
  enforcementProceedingsNumber: null,
  // @ts-ignore
  resolutionTypeId            : null,
  // @ts-ignore
  resolutionGroupId           : null,
};

export const CourtResolutionModal: React.FC<IProps> = ({
  isVisible,
  loanId,
  onClose,
  onSuccess,
  personId,
  resolutionId,
}) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(defaultValues);

  const isLoading = useSelector(isLoadingSelector);
  const resolution = useSelector(state => getLegalCourtResolutionById(state, personId, resolutionId));
  const courts = useSelector(getLegalCourtsWithEPNForSelect);

  const resolutionGroupTypes = useSelector(state => getDictionaryOptions(state, `resolutionGroupType`));
  const resolutionTypes = useSelector(state => getCourtResolutionTypes(state, values.resolutionGroupId));
  useDictionaries([`resolutionType`, `resolutionGroupType`]);
  const setValue = useCallback((field: any, value: any) => setValues({ ...values, [field]: value }), [setValues, values]);

  useEffect(() => {
      if (isVisible && resolution) {
        setValues({
          createDtm                   : resolution.createDtm ? moment.utc(resolution.createDtm) : null,
          enforcementProceedingsNumber: resolution.enforcementProceedingsNumber,
          resolutionTypeId            : resolution.resolutionTypeId,
          resolutionGroupId           : resolution.resolutionGroupId,
        });
      }
    },
    [resolutionId, resolution, isVisible],
  );

  const handleClose = () => {
    setValues(defaultValues);
    onClose();
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const onSubmit = () => {
    dispatch(editLegalCourtResolution({
      callback: handleSuccess,
      resolutionId,
      loanId,
      personId,
      ...values,
    }));
  };

  const errors = validateValues(values);

  // @ts-ignore
  return (
    <Modal
      cancelText='Отмена'
      centered
      confirmLoading={isLoading}
      okButtonProps={{ disabled: !_.isEmpty(errors) }}
      okText='Сохранить'
      onCancel={handleClose}
      onOk={onSubmit}
      open={isVisible}
      title='Ход исполнительного производства'
    >
      <Form layout='vertical'>
        <Form.Item
          label='Группа постановления'
          required
        >
          <Select
            onChange={value => setValue(`resolutionGroupId`, value)}
            options={resolutionGroupTypes}
            value={values.resolutionGroupId}
          />
        </Form.Item>
        <Form.Item
          label='Тип постановления'
          required
        >
          <Select
            disabled={!resolutionTypes.length}
            onChange={value => setValue(`resolutionTypeId`, value)}
            optionFilterProp='label'
            options={resolutionTypes}
            showSearch
            value={values.resolutionTypeId}
          />
        </Form.Item>
        <Form.Item
          label='Номер исполнительного производства'
          required
        >
          <Select
            // @ts-ignore
            onChange={value => setValue(`enforcementProceedingsNumber`, _.find(courts, { value }).label)}
            options={courts}
            value={values.enforcementProceedingsNumber}
          />
        </Form.Item>
        <Form.Item
          label='Дата ИП'
          required
        >
          <DatePicker
            onChange={value => setValue(`createDtm`, value)}
            // @ts-ignore
            value={values.createDtm}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
