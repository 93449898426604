import React, {
  useContext,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import {
  getClientApplications as getClientApplicationsAction,
  getClientCars as getClientCarsAction,
  getClientDebts as getClientDebtsAction,
  getClientLoans as getClientLoansAction,
} from 'models/clients/actions';
import {
  getClientApplications as getClientApplicationsSelector,
  getClientCars as getClientCarsSelector,
  getClientDebts as getClientDebtsSelector,
  getClientLoansWithApplications as getClientLoansWithApplicationsSelector,
  getClientOpenLoanIds as getClientOpenLoanIdsSelector,
  getIsLoadingDebts as getIsLoadingDebtsSelector,
} from 'models/clients/selectors';

import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import { SUMMARY_FIELDS } from 'pages/PageClient/Single/Debt/Summary/constants';

import { DEBT_COLUMNS } from './constants';

const PageClientSingleDebt = ({ isWrapped = true }) => {
  const dispatch = useDispatch();
  const { personId } = useContext(ClientMatchContext);

  const applications = useSelector(state => getClientApplicationsSelector(state, personId));
  const cars = useSelector(state => getClientCarsSelector(state, personId));
  const loanDebts = useSelector(state => getClientDebtsSelector(state, personId));
  const loanIds = useSelector(state => getClientOpenLoanIdsSelector(state, personId));
  const loans = useSelector(state => getClientLoansWithApplicationsSelector(state, personId));
  const isLoading = useSelector(getIsLoadingDebtsSelector);

  const getApplications = () => dispatch(getClientApplicationsAction({ personId }));
  const getCars = () => dispatch(getClientCarsAction({ personId }));
  const getDebts = _loanIds => dispatch(getClientDebtsAction({ personId, loanIds: _loanIds }));
  const getLoans = callback => dispatch(getClientLoansAction({ callback, personId }));

  const onReload = () => getLoans(_loanIds => getDebts(_loanIds));

  useEffect(() => {
    if (!_.isEmpty(loanIds)) getDebts(loanIds);
    if (_.isEmpty(loanDebts)) onReload();
    if (_.isEmpty(applications)) getApplications();
    if (_.isEmpty(cars)) getCars();
  }, []);

  const loanDebtsNormalized = _.map(loanDebts, loanDebt => {
    const loan = _.find(loans, { id: loanDebt.loanId });
    const car = _.find(cars, ({ applicationIds }) => _.includes(applicationIds, loan?.applicationId));
    return {
      ...loanDebt,
      carInfo: car ? _.join(_.compact([car.make, car.model, car.carNumber]), ` `) : ``,
    };
  });

  const content = (
    <AdjustableTable
      columns={DEBT_COLUMNS}
      dataSource={loanDebtsNormalized}
      pagination={{
        hideOnSinglePage: true,
        pageSize        : _.size(loanDebtsNormalized),
        size            : `small`,
      }}
      preferences={{
        path: `clientInfo.debt`,
      }}
      scroll={{
        x: `max-content`,
        y: window.innerHeight - 450,
      }}
      summary={{
        columns: SUMMARY_FIELDS,
      }}
    />
  );

  if (isWrapped) {
    return <ClientTab isReloading={isLoading} onReload={onReload}>{content}</ClientTab>;
  }

  return content;
};

export default PageClientSingleDebt;
