import React from 'react';
import _ from 'lodash';

import {
  getColumn as getColumnDefault,
  prettifyNumber,
} from 'helper';

import { CellDateTime } from 'components/DefaultTable/CellDateTime/CellDateTime';

const getSort = (fieldName, sorter) => (a, c) => (_.isFunction(sorter)
  ? sorter(a[fieldName], c[fieldName])
  : a[fieldName] - c[fieldName]
);

const sortAlphabetically = (a, c) => (a || ``).localeCompare(c || ``);

const getColumn = props => getColumnDefault({ sorter: getSort(props.key), ...props });

export const COLUMNS = ({ accountRatings, accountTypes, affiliations, filteredInfo }) => _.map([
  {
    key   : `businessCategory`,
    title : `Тип орг.`,
    sorter: getSort(`businessCategory`, sortAlphabetically),
    width : 140,
  },
  {
    key             : `openedDate`,
    defaultSortOrder: `descend`,
    title           : `Открыт`,
    render          : v => <CellDateTime value={v} />,
    width           : 100,
  },
  {
    key   : `lastPaymentDate`,
    title : `Посл. платеж`,
    render: v => (v > 0
      ? (<CellDateTime value={v} />)
      : ``),
    width: 100,
  },
  {
    key   : `closedDate`,
    title : `Закрыт`,
    render: v => <CellDateTime value={v} />,
    width : 100,
  },
  {
    key   : `reportingDate`,
    title : `Обновлен`,
    render: v => <CellDateTime value={v} />,
    width : 100,
  },
  {
    key    : `affiliation`,
    title  : `Заём`,
    sorter : getSort(`affiliation`, sortAlphabetically),
    width  : 100,
    filters: _.map(affiliations, affiliation => ({
      text : affiliation,
      value: affiliation,
    })),
    filteredValue : filteredInfo.affiliation,
    filterMultiple: true,
  },
  {
    key    : `accountType`,
    title  : `Тип кредита`,
    sorter : getSort(`accountType`, sortAlphabetically),
    width  : 100,
    filters: _.map(accountTypes, accountType => ({
      text : accountType,
      value: accountType,
    })),
    filteredValue : filteredInfo.accountType,
    filterMultiple: true,
  },
  {
    align : `right`,
    key   : `creditLimit`,
    title : `∑ кредита`,
    render: prettifyNumber,
    width : 100,
  },
  {
    align : `right`,
    key   : `currentBalanceAmount`,
    title : `Погашено`,
    render: prettifyNumber,
    width : 100,
  },
  {
    align : `right`,
    key   : `amountPastDue`,
    title : `Просрочка`,
    render: text => (
      <span
        className={text > 0 ? `table-cell_red` : `table-cell`}
      >{prettifyNumber(text)}
      </span>
    ),
    width: 100,
  },
  {
    align : `right`,
    key   : `monthlyPayment`,
    title : `Платёж`,
    render: prettifyNumber,
    width : 100,
  },
  {
    align : `right`,
    key   : `remainingDebt`,
    title : `Тек. остаток`,
    render: prettifyNumber,
    width : 100,
  },
  {
    key     : `paymentPat`,
    ellipsis: true,
    title   : `История платежей`,
    textWrap: `break-all`,
    width   : 300,
  },
  {
    key   : `accountRating`,
    title : `Статус кредита`,
    render: text => (
      <span className={_.toLower(text) === `счет закрыт` ? `table-cell_gray` : `table-cell`}>
        {text}
      </span>
    ),
    sorter : getSort(`accountRating`, sortAlphabetically),
    width  : 200,
    filters: _.map(accountRatings, accountRating => ({
      text : accountRating,
      value: accountRating,
    })),
    filteredValue : filteredInfo.accountRating,
    filterMultiple: true,
  },
  {
    align: `right`,
    key  : `normRate`,
    title: `Ставка`,
    width: 90,
  },
], getColumn);
