import {
  useCallback,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import {
  AnyDictionaryInterface,
  TDictionaryItem,
} from 'models/dictionaries/types';

import { getMultiple as getDictionariesAction } from './actions';
import {
  getItemsMultiple as getDictionariesSelector,
  getLoadedDictionaries as getLoadedDictionariesSelector,
  isLoading as isDictionariesLoading,
} from './selectors';

export const useDictionaries = <T = Record<string, Array<TDictionaryItem | AnyDictionaryInterface>>>(
  entities: string[],
  additionalCheck?: boolean,
  force?: boolean,
): [boolean, T] => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isDictionariesLoading);
  const items = useSelector(state => getDictionariesSelector(state, entities)) as T;
  const loaded = useSelector(getLoadedDictionariesSelector);

  const getDictionaries = useCallback(
      (_entities: any) => {
      dispatch(getDictionariesAction({ entities: _entities, force }));
    },
    [dispatch, force],
  );

  useEffect(() => {
    const unloaded = _.filter(entities, entity => !_.includes(loaded, entity));
    if ((_.isBoolean(additionalCheck) && !additionalCheck) || (!force && _.isEmpty(unloaded))) return;
    getDictionaries(force ? entities : unloaded);
  }, [additionalCheck, force, getDictionaries]);

  return [isLoading, items];
};

