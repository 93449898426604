import { useState } from 'react';

interface ModalHandlers {
  closeEditModal: () => void;
  initialValues: { [key: string]: any };
  isCreateModalOpen: boolean;
  isEditModalOpen: boolean;
  isRemoveModalOpen: boolean;
  itemEditing: { [key: string]: any };
  onSelectChange: (keys: number[]) => void;
  selectedRowKeys: number[];
  setCreateModalOpen: (isOpen: boolean) => void;
  setEditModalOpen: (isOpen: boolean) => void;
  setInitialValues: (values: { [key: string]: any }) => void;
  setItemEditing: (item: { [key: string]: any }) => void;
  setRemoveModalOpen: (isOpen: boolean) => void;
  toggleCreateModal: () => void;
  toggleEditModal: () => void;
  toggleRemoveModal: () => void;
}

export function useModalHandlers(): ModalHandlers {
  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>({});
  const [itemEditing, setItemEditing] = useState<{ [key: string]: any }>({});
  const [selectedRowKeys, onSelectChange] = useState<number[]>([]);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);

  const toggleCreateModal = () => setCreateModalOpen(!isCreateModalOpen);
  const toggleEditModal = () => setEditModalOpen(!isEditModalOpen);
  const toggleRemoveModal = () => setRemoveModalOpen(!isRemoveModalOpen);

  const closeEditModal = () => {
    setItemEditing({}); // очистка полей, которые используются в проверке на уникальность (названия) в форме Create
    toggleEditModal();
  };

  return {
    closeEditModal,
    initialValues,
    isCreateModalOpen,
    isEditModalOpen,
    isRemoveModalOpen,
    itemEditing,
    onSelectChange,
    selectedRowKeys,
    setCreateModalOpen,
    setEditModalOpen,
    setInitialValues,
    setItemEditing,
    setRemoveModalOpen,
    toggleCreateModal,
    toggleEditModal,
    toggleRemoveModal,
  };
}
