import React from 'react';
import styled from 'styled-components';

import useMobileContext from 'hooks/useMobileContext';

import { Scrollbars } from 'react-custom-scrollbars';

const StyledScrollbars = styled(Scrollbars)`
  height: 100%;
`;

const MobileScrollbars = styled.div`
  height: 100%;
  overflow: scroll;
`;

interface IComponentProps {
  autoHeight?: boolean;
  autoHide?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const ScrollbarsDesktop = React.forwardRef<HTMLDivElement, IComponentProps>(({
  autoHeight,
  autoHide = true,
  children,
  className,
}, ref) => {
  const { isSafari, mobile } = useMobileContext();

  return mobile || isSafari
    ? <MobileScrollbars className={className} ref={ref}>{children}</MobileScrollbars>
    : (
      <StyledScrollbars
        autoHeight={autoHeight}
        autoHide={autoHide}
        className={className}
        // @ts-ignore
        ref={ref}
      >
        {children}
      </StyledScrollbars>
    );
});
