import { useEffect } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { getSingle as getVerificationAction } from 'models/verifications/actions';
import {
  getItem as getVerificationSelector,
  isLoading as isLoadingSelector,
} from 'models/verifications/selectors';
import { IVerification } from 'models/verifications/types';

export const useVerification = (verificationId: number, additionalCheck?: boolean): [boolean, IVerification] => {
  const dispatch = useDispatch();

  const verification = useSelector(state => getVerificationSelector(state, verificationId));
  const isLoading = useSelector(isLoadingSelector);

  useEffect(() => {
      if (!verificationId
        || isLoading
        || (_.isBoolean(additionalCheck) && !additionalCheck)
        || !_.isEmpty(verification)) return;
      dispatch(getVerificationAction({ id: verificationId }));
    },
    [
      additionalCheck,
      verification,
      verificationId,
      isLoading,
    ],
  );
  return [isLoading, verification];
};
