import React, { useMemo } from 'react';
import _ from 'lodash';

import {
  formatDate,
  getShortened,
  prettifyNumber,
} from 'helper';

import { IApplication } from 'models/clients/types';
import { IVerification } from 'models/verifications/types';

import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardRow } from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';

interface IComponentProps {
  application: IApplication;
  verification: IVerification;
}

const Application: React.FC<IComponentProps> = ({ application, verification }) => {
  const items = useMemo(() => _.reject<{ isCopyAllowed?: boolean; name: string; text?: string; value: string }>([
    {
      name : `Создана`,
      value: formatDate(application?.applicationDtm, `dd.MM.yyyy (HH:mm)`),
    },
    {
      name : `id`,
      text : application?.id,
      value: getShortened(application?.id),
    },
    {
      name : `Продукт`,
      value: application?.productName || application?.productId,
    },
    {
      name : `Сумма`,
      value: prettifyNumber(application?.requestedLoan),
    },
    {
      name : `Доход`,
      value: prettifyNumber(application?.monthlyIncome),
    },
    {
      name : `Нагрузка`,
      value: prettifyNumber(application?.loanBurden),
    },
    {
      name : `Партнер`,
      value: application?.partnerName || application?.partnerId || ``,
    },
    {
      name : `Тип перехода`,
      value: (verification?.webDataVector?.referrerType
        ? _.replace(verification?.webDataVector?.referrerType, /"/gi, ``)
        : `-`) || `-`,
    },
    {
      name : `Перешёл с URL`,
      value: (verification?.webDataVector?.referrerUrl
        ? _.replace(verification?.webDataVector?.referrerUrl, /"/gi, ``)
        : null) || `-`,
    },
  ], ({ value }) => _.isNil(value)),
  [application, verification]);

  return (
    <EntityCard>
      <EntityCardTitle>Данные заявки</EntityCardTitle>
      <EntityCardContent>
        {_.map(items, ({ isCopyAllowed = true, name, text, value }, index) => (
          <EntityCardRow key={index} title={name} {...isCopyAllowed ? { valueToCopy: text || value } : {}}>
            {value}
          </EntityCardRow>
        ))}
      </EntityCardContent>
    </EntityCard>
  );
};

export default Application;
