import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const getPaymentGateways = createSelector(getState, ({ paymentGateways = [] }) => paymentGateways);

export const getIsProcessTasksLoading = createSelector(getState, ({ isProcessTasksLoading }) => isProcessTasksLoading);

export const getIsTriggersLoading = createSelector(getState, ({ isTriggersLoading }) => isTriggersLoading);

export const getShortLinkServices = createSelector(getState, ({ shortLinkServices = [] }) => shortLinkServices);

export const getSmsGateways = createSelector(getState, ({ smsGateways = [] }) => smsGateways);

export const isLoading = createSelector(getState, state => _.get(state, `isLoading`, false));
