import {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { get as getDictionaryAction } from 'models/dictionaries/actions';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';
import { get as getOperatorsAction } from 'models/operators/actions';
import { getItems as getOperatorsSelector } from 'models/operators/selectors';
import { getId as getCurrentUserIdSelector } from 'models/user/selectors';

export const useMentions = () => {
  const dispatch = useDispatch();

  const [mentionOptions, setMentionOptions] = useState([]);

  const authorId = useSelector(getCurrentUserIdSelector);
  const operators = useSelector(getOperatorsSelector);
  const notificationLists = useSelector(state => getDictionarySelector(state, `notificationList`));

  useEffect(() => {
    const options = _.uniqBy(_.sortBy(_.compact(_.map(notificationLists, notificationListItem => {
      if (notificationListItem?.isPersonal) {
        const operator = _.find(operators, o => _.get(o, `user.email`) === notificationListItem?.name);

        if (operator?.userId === authorId) {
          return null;
        }

        return {
          id        : notificationListItem?.id,
          isPersonal: notificationListItem?.isPersonal,
          title     : operator?.user?.fullName || notificationListItem?.description || notificationListItem?.name || ``,
        };
      }

      return {
        id        : notificationListItem?.id,
        isPersonal: notificationListItem?.isPersonal,
        title     : notificationListItem?.description || notificationListItem?.name || ``,
      };
    })), `isPersonal`), `title`);

    // @ts-ignore
    setMentionOptions(options);
  }, [authorId, notificationLists, operators]);

  useEffect(() => {
    const getNotificationLists = () => dispatch(getDictionaryAction({ entity: `notificationList`, force: true }));
    const getOperators = () => dispatch(getOperatorsAction());

    getOperators();
    getNotificationLists();
  }, [dispatch]);

  return {
    mentionOptions,
  };
};
