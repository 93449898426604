import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment, { Moment } from 'moment/moment';

import {
  formatDate,
  inputNumberFormatter,
  inputNumberParser,
  normalizeMomentToStartOfDay,
  prettifyAmount,
} from 'helper';

import {
  deleteEnforcementProceeding as deleteEnforcementProceedingAction,
  post as postAction,
} from 'models/courts/actions';
import { defaultValues } from 'models/courts/constants';
import {
  getItemById,
  isLoading as isLoadingSelector,
} from 'models/courts/selectors';
import {
  ICourtData,
  IEnforcementProceeding,
  IValues,
} from 'models/courts/types';

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Space,
  TimePicker,
} from 'antd';
import { ConditionDropdown } from 'components/ConditionDropdown';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { EnforcementProceedingModal } from 'components/Modal/EnforcementProceedingModal/EnforcementProceedingModal';

import {
  Collapse,
  FormItemHeaderStyled,
  FormItemStyled,
  ModalStyled,
  Panel,
  PanelHeader,
  PanelHeaderButton,
  PanelHeaderControls,
  PanelHeaderTitle,
  StyledInputNumber,
} from './styled';

interface IProps {
  courtId: string | null,
  isVisible: boolean,
  loanId: string,
  personId: string,
  onClose():void,
  onSuccess():void,
}

const ENFORECEMENT_PROCEEDINGS_FIELDS = {
  finishStatus: {
    label: `Итоговый статус по ИП`,
    type : `string`,
  },
  arrestBankAccountFlg: {
    label: `Арест РС`,
    type : `boolean`,
  },
  restrictionsTravelFlg: {
    label: `Ограничение выезда за пределы РФ`,
    type : `boolean`,
  },
  collectionWagesFlg: {
    label: `Обращение на заработную плату`,
    type : `boolean`,
  },
  autoInjunctionFlg: {
    label: `Запрет на ТС`,
    type : `boolean`,
  },
  autoArrestFlg: {
    label: `Арест ТС`,
    type : `boolean`,
  },
  autoSearchFlg: {
    label: `Исполнительный розыск ТС`,
    type : `boolean`,
  },
  sumEnforcementProceedingsInitiationDtm: {
    label: `Дата возбуждения ИП (сумма)`,
    type : `date`,
  },
  sumEnforcementProceedingsNumber: {
    label: `Номер ИП (сумма)`,
    type : `string`,
  },
  autoEnforcementProceedingsInitiationDtm: {
    label: `Дата возбуждения ИП (ТС)`,
    type : `date`,
  },
  autoEnforcementProceedingsNumber: {
    label: `Номер ИП (ТС)`,
    type : `string`,
  },
  auctionEstimateSum: {
    label: `Оценка сумма`,
    type : `number`,
  },
  auctionAcceptanceOnBalanceDtm: {
    label: `Принятие на баланс (дата)`,
    type : `date`,
  },
  auctionAcceptanceOnBalanceSum: {
    label: `Принятие на баланс (сумма)`,
    type : `number`,
  },
  auctionProgress: {
    label: `Ход торгов`,
    type : `string`,
  },
  sumEnforcementProceedingsEndDtm: {
    label: `Дата окончания ИП (сумма)`,
    type : `date`,
  },
  sumEnforcementProceedingsEndArticle: {
    label: `Статья окончания ИП (сумма)`,
    type : `string`,
  },
  autoEnforcementProceedingsEndDtm: {
    label: `Дата окончания ИП (залог)`,
    type : `date`,
  },
  autoEnforcementProceedingsEndArticle: {
    label: `Статья окончания ИП (залог)`,
    type : `string`,
  },
  resubmissionFlg: {
    label: `Требуется повторная подача ИЛ`,
    type : `boolean`,
  },
  comment: {
    label: `Комментарий`,
    type : `string`,
  },
  updateDtm: {
    label: `Дата редактирования`,
    type : `date`,
  },
};

const renderEnforcementProceedingsField = (key: string, enforcementProceeding: IEnforcementProceeding) => {
  const { label, type } = ENFORECEMENT_PROCEEDINGS_FIELDS[key];
  const value = enforcementProceeding[key];
  if (_.isNil(value) || (type !== `boolean` && !value)) return null;
  let children = value;
  if (type === `boolean`) {
    children = value ? `✅` : `❌`;
  }
  if (type === `number`) {
    children = prettifyAmount(value);
  }
  if (type === `date`) {
    children = formatDate(value);
  }

  return (
    <FormItemStyled key={key} label={label}>
      {children}
    </FormItemStyled>
  );
};

export const CourtModal: React.FC<IProps> = ({
  courtId,
  isVisible,
  loanId,
  onClose,
  onSuccess,
  personId,
}) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState<IValues>(defaultValues);
  const [isEnforcementProceedingsModalOpen, setIsEnforcementProceedingsModalOpen] = useState<boolean>(false);
  const [enforcementProceedingsId, setEnforcementProceedingsId] = useState<string>(null);
  const { court, isLoading } = useSelector(state => ({
    court    : getItemById(state, courtId),
    isLoading: isLoadingSelector(state),
  })) as {
    court: ICourtData,
    isLoading: boolean,
  };

  const { enforcementProceedings } = court || { enforcementProceedings: [] };

  useEffect(
    () => {
      if (isVisible && court) {
        setValues({
          appeal                : court.appeal,
          autoImplementationName: court.autoImplementationName,
          annulmentJudgmentDtm  : court.annulmentJudgmentDtm
            ? moment.utc(court.annulmentJudgmentDtm) : null,
          bailiffComplaints           : court.bailiffComplaints,
          cancelDefaultDecision       : court.cancelDefaultDecision,
          cashClient                  : court.cashClient,
          cashThirdPerson             : court.cashThirdPerson,
          cashFSSP                    : court.cashFSSP,
          cassation                   : court.cassation,
          chComplaint                 : court.chComplaint,
          comment                     : court.comment,
          cost                        : court.cost,
          courtCase                   : court.courtCase,
          courtCaseWorldJudge         : court.courtCaseWorldJudge,
          courtComments               : court.courtComments,
          courtName                   : court.courtName,
          courtOrderDtm               : court.courtOrderDtm ? moment.utc(court.courtOrderDtm) : null,
          courtOrderFSSPSendDtm       : court.courtOrderFSSPSendDtm ? moment.utc(court.courtOrderFSSPSendDtm) : null,
          courtOrderNumber            : court.courtOrderNumber,
          courtOrderSubject           : court.courtOrderSubject,
          courtProceedWritExecutionDtm: court.courtProceedWritExecutionDtm
            ? moment.utc(court.courtProceedWritExecutionDtm) : null,
          courtSentDtm              : court.courtSentDtm ? moment.utc(court.courtSentDtm) : null,
          courtSessionDtm           : court.courtSessionDtm ? moment.utc(court.courtSessionDtm) : null,
          DPD                       : court.DPD,
          judgment                  : court.judgment,
          judgmentAppeal            : court.judgmentAppeal,
          judgmentDtm               : court.judgmentDtm ? moment.utc(court.judgmentDtm) : null,
          judgmentEffectiveDtm      : court.judgmentEffectiveDtm ? moment.utc(court.judgmentEffectiveDtm) : null,
          newOwnerFio               : court.newOwnerFio,
          paidDuty                  : court.paidDuty,
          performanceListComment    : court.performanceListComment,
          performanceListDtm        : court.performanceListDtm ? moment.utc(court.performanceListDtm) : null,
          performanceListFSSPSendDtm: court.performanceListFSSPSendDtm
            ? moment.utc(court.performanceListFSSPSendDtm) : null,
          performanceListNumber     : court.performanceListNumber,
          performanceListSubject    : court.performanceListSubject,
          provisionalMeasuresAppeal : court.provisionalMeasuresAppeal,
          provisionalMeasuresDetails: court.provisionalMeasuresDetails,
          provisionalMeasuresDtm    : court.provisionalMeasuresDtm ? moment.utc(court.provisionalMeasuresDtm) : null,
          provisionalMeasuresResult : court.provisionalMeasuresResult,
          returnDefinition          : court.returnDefinition,
          returnedDuty              : court.returnedDuty,
          returnStateDutyDtm        : court.returnStateDutyDtm
            ? moment.utc(court.returnStateDutyDtm) : null,
          SSPContact  : court.SSPContact,
          SSPName     : court.SSPName,
          supremeCourt: court.supremeCourt,
          totalDebt   : court.totalDebt,
        });
      }
    },
    [courtId, court, isVisible],
  );

  const setValue = (field, data: ChangeEvent<HTMLInputElement> | Moment | string | boolean) => setValues({
    ...values,
    [field]: !_.isEmpty(data) && _.has(data, `target`) ? (data as ChangeEvent<HTMLInputElement>).target.value : data,
  });

  const handleClose = () => {
    setValues(defaultValues);
    onClose();
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const onSubmit = () => {
    dispatch(postAction({
      callback: handleSuccess,
      courtId,
      loanId,
      personId,
      ...values,
    }));
  };

  const openEnforcementProceedingsModal = (_enforcementProceedingsId: string | null) => {
    setEnforcementProceedingsId(_enforcementProceedingsId);
    setIsEnforcementProceedingsModalOpen(true);
  };

  const closeEnforcementProceedingsModal = () => {
    setEnforcementProceedingsId(null);
    setIsEnforcementProceedingsModalOpen(false);
  };

  const deleteEnforcementProceeding = (_enforcementProceedingsId: string) => {
    dispatch(deleteEnforcementProceedingAction({ enforcementProceedingsId: _enforcementProceedingsId }));
  };

  return (
    <ModalStyled
      cancelText='Отмена'
      centered
      confirmLoading={isLoading}
      maskClosable={false}
      okText='Сохранить'
      onCancel={handleClose}
      onOk={onSubmit}
      open={isVisible}
      title='Судебное производство'
    >
      <Form layout='vertical'>
        <FormItemStyled label='Состояние договора'>
          <ConditionDropdown entity='contract' loanId={loanId} personId={personId} />
        </FormItemStyled>
        <FormItemStyled label='Состояние клиента'>
          <ConditionDropdown entity='client' personId={personId} />
        </FormItemStyled>
        <FormItemStyled label='Сумма задолженности на дату формирования заявления/иска в суд'>
          <StyledInputNumber
            formatter={inputNumberFormatter}
            min={0}
            // @ts-ignore
            onChange={event => setValue(`totalDebt`, event)}
            parser={inputNumberParser}
            placeholder='Сумма, ₽'
            precision={2}
            value={values.totalDebt}
          />
        </FormItemStyled>
        <FormItemStyled label='Оплаченная государственная пошлина'>
          <StyledInputNumber
            formatter={inputNumberFormatter}
            min={0}
            // @ts-ignore
            onChange={event => setValue(`paidDuty`, event)}
            parser={inputNumberParser}
            placeholder='Сумма, ₽'
            precision={2}
            value={values.paidDuty}
          />
        </FormItemStyled>

        <Collapse>
          <Panel header='Общие данные Суды' key='courtCommon'>
            <FormItemStyled label='ФИО нового собственника транспортного средства (ТС)'>
              <Input
                onChange={event => setValue(`newOwnerFio`, event)}
                value={values.newOwnerFio}
              />
            </FormItemStyled>
            <FormItemStyled label='Заявление в суд о возврате госпошлины (дата)'>
              <DatePicker
                onChange={value => setValue(`returnStateDutyDtm`, value)}
                // @ts-ignore
                value={values.returnStateDutyDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Возвращенная государственная пошлина'>
              <StyledInputNumber
                formatter={inputNumberFormatter}
                min={0}
                // @ts-ignore
                onChange={event => setValue(`returnedDuty`, event)}
                parser={inputNumberParser}
                placeholder='Сумма, ₽'
                precision={2}
                value={values.returnedDuty}
              />
            </FormItemStyled>
            <FormItemStyled label='Оценка ТС согласно отчета оценщика'>
              <StyledInputNumber
                formatter={inputNumberFormatter}
                min={0}
                // @ts-ignore
                onChange={event => setValue(`cost`, event)}
                parser={inputNumberParser}
                placeholder='Сумма, ₽'
                precision={2}
                value={values.cost}
              />
            </FormItemStyled>
            <FormItemStyled label='ТС передано истцу в натуре/продажа ТС'>
              <Input
                onChange={event => setValue(`autoImplementationName`, event)}
                value={values.autoImplementationName}
              />
            </FormItemStyled>
            <FormItemStyled label='Жалобы на приставов'>
              <Input
                onChange={event => setValue(`bailiffComplaints`, event)}
                value={values.bailiffComplaints}
              />
            </FormItemStyled>
            <FormItemStyled label='Размер поступивших ДС заемщик'>
              <StyledInputNumber
                formatter={inputNumberFormatter}
                min={0}
                // @ts-ignore
                onChange={event => setValue(`cashClient`, event)}
                parser={inputNumberParser}
                placeholder='Сумма, ₽'
                precision={2}
                value={values.cashClient}
              />
            </FormItemStyled>
            <FormItemStyled label='Размер поступивших ДС 3 лицо'>
              <StyledInputNumber
                formatter={inputNumberFormatter}
                min={0}
                // @ts-ignore
                onChange={event => setValue(`cashThirdPerson`, event)}
                parser={inputNumberParser}
                placeholder='Сумма, ₽'
                precision={2}
                value={values.cashThirdPerson}
              />
            </FormItemStyled>
            <FormItemStyled label='Размер поступивших ДС ФССП'>
              <StyledInputNumber
                formatter={inputNumberFormatter}
                min={0}
                // @ts-ignore
                onChange={event => setValue(`cashFSSP`, event)}
                parser={inputNumberParser}
                placeholder='Сумма, ₽'
                precision={2}
                value={values.cashFSSP}
              />
            </FormItemStyled>
          </Panel>
          <Panel header='Суд' key='Суд'>
            <FormItemStyled label='Наименование суда'>
              <Input
                onChange={event => setValue(`courtName`, event)}
                value={values.courtName}
              />
            </FormItemStyled>
            <FormItemStyled label='Дата направления в суд'>
              <DatePicker
                onChange={value => setValue(`courtSentDtm`, value)}
                // @ts-ignore
                value={values.courtSentDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Номер дела (районный суд)'>
              <Input
                onChange={event => setValue(`courtCase`, event)}
                value={values.courtCase}
              />
            </FormItemStyled>
            <FormItemStyled label='Номер дела (мировой суд)'>
              <Input
                onChange={event => setValue(`courtCaseWorldJudge`, event)}
                value={values.courtCaseWorldJudge}
              />
            </FormItemStyled>
            <FormItemStyled label='Дата и время судебного заседания'>
              <Space>
                <DatePicker
                  onChange={value => setValue(`courtSessionDtm`, normalizeMomentToStartOfDay(value))}
                  // @ts-ignore
                  value={values.courtSessionDtm}
                />
                <TimePicker
                  onChange={value => setValue(`courtSessionDtm`, value)}
                  // @ts-ignore
                  value={values.courtSessionDtm}
                />
              </Space>
            </FormItemStyled>
            <FormItemStyled label='Замечания от суда'>
              <Input
                onChange={event => setValue(`courtComments`, event)}
                value={values.courtComments}
              />
            </FormItemStyled>
            <FormItemStyled label='Определение о возврате заявления/искового заявления'>
              <Input
                onChange={event => setValue(`returnDefinition`, event)}
                value={values.returnDefinition}
              />
            </FormItemStyled>
            <FormItemStyled label='Частная жалоба'>
              <Input
                onChange={event => setValue(`chComplaint`, event)}
                value={values.chComplaint}
              />
            </FormItemStyled>
            <FormItemStyled label='Обеспечительные меры (дата)'>
              <DatePicker
                onChange={value => setValue(`provisionalMeasuresDtm`, value)}
                // @ts-ignore
                value={values.provisionalMeasuresDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Обеспечительные меры (результат рассмотрения)'>
              <Input
                onChange={event => setValue(`provisionalMeasuresResult`, event)}
                value={values.provisionalMeasuresResult}
              />
            </FormItemStyled>
            <FormItemStyled label='Обеспечительные меры (детали)'>
              <Input
                onChange={event => setValue(`provisionalMeasuresDetails`, event)}
                value={values.provisionalMeasuresDetails}
              />
            </FormItemStyled>
            <FormItemStyled label='Обжалование определения суда об отказе в принятии обеспечительных мер'>
              <Input
                onChange={event => setValue(`provisionalMeasuresAppeal`, event)}
                value={values.provisionalMeasuresAppeal}
              />
            </FormItemStyled>
            <FormItemStyled label='Итоговое решение суда (Дата)'>
              <DatePicker
                onChange={value => setValue(`judgmentDtm`, value)}
                // @ts-ignore
                value={values.judgmentDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Итоговое решение суда'>
              <Input
                onChange={event => setValue(`judgment`, event)}
                value={values.judgment}
              />
            </FormItemStyled>
            <FormItemStyled label='Заявление об отмене заочного решения (детали)'>
              <Input
                onChange={event => setValue(`cancelDefaultDecision`, event)}
                value={values.cancelDefaultDecision}
              />
            </FormItemStyled>
            <FormItemStyled label='Аппеляция (детали)'>
              <Input
                onChange={event => setValue(`appeal`, event)}
                value={values.appeal}
              />
            </FormItemStyled>
            <FormItemStyled label='Кассация (детали)'>
              <Input
                onChange={event => setValue(`cassation`, event)}
                value={values.cassation}
              />
            </FormItemStyled>
            <FormItemStyled label='Верховный суд (детали)'>
              <Input
                onChange={event => setValue(`supremeCourt`, event)}
                value={values.supremeCourt}
              />
            </FormItemStyled>
            <FormItemStyled label='Количество дней просрочки на дату формирования заявления/иска'>
              <StyledInputNumber
                formatter={inputNumberFormatter}
                min={0}
                // @ts-ignore
                onChange={event => setValue(`DPD`, event)}
                parser={inputNumberParser}
                precision={0}
                value={values.DPD}
              />
            </FormItemStyled>
            <FormItemStyled label='Обжалование решения суда'>
              <Input
                onChange={event => setValue(`judgmentAppeal`, event)}
                value={values.judgmentAppeal}
              />
            </FormItemStyled>
          </Panel>
          <Panel header='Судебный приказ' key='Судебный приказ'>
            <FormItemStyled label='Дата вступления решения в законную силу'>
              <DatePicker
                onChange={value => setValue(`judgmentEffectiveDtm`, value)}
                // @ts-ignore
                value={values.judgmentEffectiveDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Судебный приказ (дата)'>
              <DatePicker
                onChange={value => setValue(`courtOrderDtm`, value)}
                // @ts-ignore
                value={values.courtOrderDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Судебный приказ (номер)'>
              <Input
                onChange={event => setValue(`courtOrderNumber`, event)}
                value={values.courtOrderNumber}
              />
            </FormItemStyled>
            <FormItemStyled label='Судебный приказ (предмет взыскания)'>
              <Input
                onChange={event => setValue(`courtOrderSubject`, event)}
                value={values.courtOrderSubject}
              />
            </FormItemStyled>
            <FormItemStyled label='Определение об отмене Судебного приказа (дата)'>
              <DatePicker
                onChange={value => setValue(`annulmentJudgmentDtm`, value)}
                // @ts-ignore
                value={values.annulmentJudgmentDtm}
              />
            </FormItemStyled>
          </Panel>
          <Panel header='Исполнительный лист' key='Исполнительный лист'>
            <FormItemStyled label='Исполнительный лист (номер)'>
              <Input
                onChange={event => setValue(`performanceListNumber`, event)}
                value={values.performanceListNumber}
              />
            </FormItemStyled>
            <FormItemStyled label='Исполнительный лист (дата)'>
              <DatePicker
                onChange={value => setValue(`performanceListDtm`, value)}
                // @ts-ignore
                value={values.performanceListDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Исполнительный лист (предмет взыскания)'>
              <Input
                onChange={event => setValue(`performanceListSubject`, event)}
                value={values.performanceListSubject}
              />
            </FormItemStyled>
            <FormItemStyled label='Исполнительный лист (комментарии)'>
              <Input
                onChange={event => setValue(`performanceListComment`, event)}
                value={values.performanceListComment}
              />
            </FormItemStyled>
          </Panel>
          <Panel header='ССП' key='ССП'>
            <FormItemStyled label='Наименование ССП'>
              <Input
                onChange={event => setValue(`SSPName`, event)}
                value={values.SSPName}
              />
            </FormItemStyled>

            <FormItemStyled label='Контакты ССП'>
              <Input
                onChange={event => setValue(`SSPContact`, event)}
                value={values.SSPContact}
              />
            </FormItemStyled>
          </Panel>
          <Panel header='ФССП' key='ФССП'>
            <FormItemStyled label='Дата направления Судебного приказа в ФССП'>
              <DatePicker
                onChange={value => setValue(`courtOrderFSSPSendDtm`, value)}
                // @ts-ignore
                value={values.courtOrderFSSPSendDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Дата направления Исполнительного листа'>
              <DatePicker
                onChange={value => setValue(`performanceListFSSPSendDtm`, value)}
                // @ts-ignore
                value={values.performanceListFSSPSendDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Дата получения Судебного приказа/Исполнительного листа в ФССП'>
              <DatePicker
                onChange={value => setValue(`courtProceedWritExecutionDtm`, value)}
                // @ts-ignore
                value={values.courtProceedWritExecutionDtm}
              />
            </FormItemStyled>
          </Panel>
          {courtId && (
            <>
              <EnforcementProceedingModal
                courtId={courtId}
                enforcementProceedingsId={enforcementProceedingsId}
                isVisible={isEnforcementProceedingsModalOpen}
                onClose={closeEnforcementProceedingsModal}
              />
              <Panel
                collapsible={_.isEmpty(enforcementProceedings) ? `disabled` : `header`}
                header={(
                  <PanelHeader>
                    <PanelHeaderTitle>ИП</PanelHeaderTitle>
                    <PanelHeaderControls>
                      <DefaultTooltip title='Добавить'>
                        <PanelHeaderButton
                          icon={<PlusOutlined />}
                          onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            openEnforcementProceedingsModal(null);
                          }}
                          shape='circle'
                          type='primary'
                        />
                      </DefaultTooltip>
                    </PanelHeaderControls>
                  </PanelHeader>
              )}
                key='ИП'
                showArrow={!_.isEmpty(enforcementProceedings)}
              >
                <Collapse>
                  {!_.isEmpty(enforcementProceedings) && _.map(enforcementProceedings, enforcementProceeding => (
                    <Panel
                      header={(
                        <PanelHeader>
                          <PanelHeaderTitle>{enforcementProceeding?.sumEnforcementProceedingsNumber}</PanelHeaderTitle>
                          <PanelHeaderControls>
                            <DefaultTooltip title='Удалить'>
                              <Popconfirm
                                okButtonProps={{ danger: true, type: `primary` }}
                                okText='Да'
                                onConfirm={event => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  deleteEnforcementProceeding(
                                    enforcementProceeding.enforcementProceedingsId,
                                  );
                                }}
                                title='Удалить ИП?'
                              >
                                <PanelHeaderButton
                                  danger
                                  icon={<DeleteOutlined />}
                                  shape='circle'
                                  type='primary'
                                />
                              </Popconfirm>
                            </DefaultTooltip>
                            <DefaultTooltip title='Редактировать'>
                              <PanelHeaderButton
                                icon={<EditOutlined />}
                                onClick={event => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  openEnforcementProceedingsModal(
                                    enforcementProceeding.enforcementProceedingsId,
                                  );
                                }}
                                shape='circle'
                                type='primary'
                              />
                            </DefaultTooltip>
                          </PanelHeaderControls>
                        </PanelHeader>
                    )}
                      key={enforcementProceeding.enforcementProceedingsId}
                    >
                      <FormItemHeaderStyled label='Основная информация' />
                      {_.map([
                        `finishStatus`,
                        `arrestBankAccountFlg`,
                        `restrictionsTravelFlg`,
                        `collectionWagesFlg`,
                        `autoInjunctionFlg`,
                        `autoArrestFlg`,
                        `autoSearchFlg`,
                        `sumEnforcementProceedingsInitiationDtm`,
                        `sumEnforcementProceedingsNumber`,
                        `autoEnforcementProceedingsInitiationDtm`,
                        `autoEnforcementProceedingsNumber`,
                      ], key => renderEnforcementProceedingsField(key, enforcementProceeding))}

                      <FormItemHeaderStyled label='Торги' />
                      {_.map([
                        `auctionEstimateSum`,
                        `auctionAcceptanceOnBalanceDtm`,
                        `auctionAcceptanceOnBalanceSum`,
                        `auctionProgress`,
                      ], key => renderEnforcementProceedingsField(key, enforcementProceeding))}
                      <FormItemHeaderStyled label='Доп. инфо' />
                      {_.map([
                        `sumEnforcementProceedingsEndDtm`,
                        `sumEnforcementProceedingsEndArticle`,
                        `autoEnforcementProceedingsEndDtm`,
                        `autoEnforcementProceedingsEndArticle`,
                        `resubmissionFlg`,
                        `comment`,
                        `updateDtm`,
                      ], key => renderEnforcementProceedingsField(key, enforcementProceeding))}
                    </Panel>
                  ))}
                </Collapse>
              </Panel>
            </>
          )}
        </Collapse>

        <FormItemStyled label='Комментарии'>
          <Input
            onChange={event => setValue(`comment`, event)}
            value={values.comment}
          />
        </FormItemStyled>
      </Form>
    </ModalStyled>
  );
};
