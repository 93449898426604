import { useCallback } from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { setTableState as setTableStateAction } from 'models/task/actions';
import { getTableStateCut as getTableStateCutSelector } from 'models/task/selectors';

import { usePath } from 'hooks/usePath';

export const useSearchInTasks = () => {
  const dispatch = useDispatch();
  const tableState = useSelector(state => getTableStateCutSelector(state, `plural`));
  const { pushPath } = usePath();

  return useCallback((personId: string) => {
    dispatch(setTableStateAction({
      ...tableState,
      filters: {
        campaignId: null,
        personId  : personId ? [personId] : null,
      },
      callback: () => pushPath(`/tasks`),
    }));
  }, [dispatch, pushPath, tableState]);
};
