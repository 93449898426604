import { css } from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

export const DefaultDropdownStyles = css`
  .ant-dropdown {
    .ant-dropdown-menu {
      background-color: ${palette.backgroundColor};
    }

    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
      color: ${palette.textPrimaryColor};

      .anticon:first-child {
        margin-right: 12px;
      }
    }

    .ant-dropdown-menu-item-divider {
      background-color: ${getLightStyleOr(palette.black30Color, palette.black10Color)};
    }

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
    .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
    .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
    .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
      background: ${palette.black10Color};
    }

    .ant-dropdown-menu-item-danger {
      color: ${palette.alert600Color};

      :hover {
        color: ${getLightStyleOr(palette.whiteColor, palette.black100Color)};
        background-color: ${getLightStyleOr(palette.alert600Color, palette.alert500Color)};
      }
    }

    .ant-dropdown-menu-item-primary {
      color: ${palette.primary500Color};

      :hover {
        color: ${getLightStyleOr(palette.whiteColor, palette.black100Color)};
        background-color: ${palette.primary500Color};
      }
    }

    .ant-dropdown-menu-item-disabled,
    .ant-dropdown-menu-submenu-title-disabled,
    .ant-dropdown-menu-item-disabled:hover {
      color: ${palette.black40Color};
    }

    .ant-table-filter-dropdown {
      background-color: ${palette.backgroundColor};
      min-width: 134px;
    }

    .ant-table-filter-dropdown-btns {
      border-color: ${palette.borderDefaultColor};
    }
  }
`;
