import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { Link } from 'dva/router';
import styled from 'styled-components';

import {
  getFullName,
  getFullNameInitials,
} from 'helper';

import { getMultiple as getDictionariesAction } from 'models/dictionaries/actions';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';
import { get as getOperatorsAction } from 'models/operators/actions';
import {
  getItems as getOperatorsSelector,
  getItemsByCampaignAccessRight as getOperatorsByCampaignAccessRight,
} from 'models/operators/selectors';
import {
  get as getTasksAction,
  getFilters as getFiltersAction,
  setTableState as setTableStateAction,
} from 'models/task/actions';
import {
  getFilterOptions as getFilterOptionsSelector,
  getFilters as getFiltersSelector,
  getItems as getTasksSelector,
  getTableStateCut as getTableStateCutSelector,
} from 'models/task/selectors';

import { CaretRightOutlined } from '@ant-design/icons';
import {
  Button,
  Layout,
} from 'antd';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';
import { getTasksColumns } from 'pages/PageTask/Plural/constants';

import { TaskPluralHeader } from './components/TaskPluralHeader';

const StyledLayout = styled(Layout)``;
const tableScrollY = (window.innerWidth < 769) ? (window.innerHeight - 320) : (window.innerHeight - 250);

const getSortedEnrichedDictionary = (dictionary: any[], campaigns: any) => _.orderBy(_.map(dictionary, item => ({
  ...item,
  campaignName: _.find(campaigns, { id: item.campaignId })?.name,
})), [`campaignName`, `description`, `name`], [`asc`, `asc`, `asc`]);

interface IProps {
  personId?: string;
}

const PageTaskPlural: React.FC<IProps> = ({ personId = `plural` }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (keys: React.SetStateAction<never[]>) => setSelectedRowKeys(keys);

  const hmSearchPartners = useSelector(state => getDictionarySelector(state, `hmSearchPartner`));
  const campaigns = useSelector(state => getDictionarySelector(state, `campaign`));
  const campaignTypes = useSelector(state => getDictionarySelector(state, `campaignType`));
  const communicationTypes = useSelector(state => getDictionarySelector(state, `communicationType`));
  const hmParkings = useSelector(state => getDictionarySelector(state, `hmParking`));
  const taskStatuses = useSelector(state => _.orderBy(getDictionarySelector(state, `taskStatus`), `id`, `desc`));
  const tableState = useSelector(state => getTableStateCutSelector(state, personId));

  const campaignId = tableState?.filters?.campaignId;
  const campaignName = _.find(campaigns, { id: campaignId })?.name || `all`;
  const campaignTaskStatuses = campaignId
    ? _.filter(taskStatuses, { campaignId })
    : getSortedEnrichedDictionary(taskStatuses, campaigns);
  const campaignSubTypes = campaignId
    ? _.filter(campaignTypes, { campaignId })
    : getSortedEnrichedDictionary(campaignTypes, campaigns);

  const operatorsToSelect = useSelector(state => getOperatorsByCampaignAccessRight(state, campaignName, `write`));

  const block = _.find(campaigns, { id: campaignId })?.block;

  const dispatch = useDispatch();

  const tasks = useSelector(getTasksSelector);
  const filters = useSelector(state => getFiltersSelector(state, personId));
  const filterOptions = useSelector(getFilterOptionsSelector);
  const operators = useSelector(getOperatorsSelector);

  const setTableState = (state: any) => dispatch(setTableStateAction({ ...state, personId }));
  useEffect(() => {
    if (personId !== `plural`) {
      setTableState({
        ...tableState,
        filters: {
          ...filters,
          personId: [personId],
        },
      });
    }
  }, [personId]);

  const getTasks = useCallback(
    () => dispatch(getTasksAction({ personId })),
    [dispatch],
  );

  const getDictionaries = useCallback(
    (entities: string[], force: boolean) => dispatch(getDictionariesAction({ entities, force })),
    [dispatch],
  );

  const mappedTasks = useMemo(() => _.map(tasks, collectionTask => {
    const campaignType = _.find(campaignTypes, { id: collectionTask?.campaignTypeId });
    const campaign = _.find(campaigns, { id: campaignType?.campaignId });
    const communicationType = _.find(communicationTypes, { id: campaignType?.communicationTypeId });
    const taskStatus = _.find(taskStatuses, { id: collectionTask?.taskStatusId });
    const searchPartner = _.find(hmSearchPartners, { id: collectionTask?.searchPartnerId });

    const operator = _.find(operators, { id: _.get(collectionTask, `operatorId`) });

    return {
      ...collectionTask,
      clientName  : getFullName(_.get(collectionTask, `client`, {})),
      campaignId  : campaign?.id,
      campaign,
      campaignType,
      taskStatus,
      communicationType,
      operatorName: getFullNameInitials(_.get(operator, `user`, {}), `-`),
      searchPartner,
    };
  }), [
    campaignTypes,
    campaigns,
    tasks,
    communicationTypes,
    hmSearchPartners,
    operators,
    taskStatuses,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setTableState({
      ...tableState,
      pagination: {
        ...pagination,
        showSizeChanger: true,
        defaultPageSize: 10,
        pageSizeOptions: [`5`, `10`, `25`, `50`, `100`, `200`],
      },
      filters: {
        ...filters,
        campaignId,
      },
      sorter,
      callback: getTasks,
    });
  };

  const onTableColumnsChange = (newColumns: any) => {
    const columns = tableState.columns || {};
    columns[campaignName] = newColumns;

    setTableState({
      ...tableState,
      columns,
    });
  };

  const onSelectedCampaignChange = (newCampaign: string) => setTableState({
    ...tableState,
    filters: {
      campaignId: (newCampaign === `all`)
        ? null
        : _.get(_.find(campaigns, { name: newCampaign }), `id`, null),
    },
    callback: getTasks,
  });

  const columns = getTasksColumns({
    block,
    campaignTypes: campaignSubTypes,
    filterOptions,
    campaignName,
    hmParkings,
    hmSearchPartners,
    operatorsToSelect,
    tableState,
    taskStatuses : campaignTaskStatuses,
  });

  useEffect(() => {
    if (_.isEmpty(filterOptions)) dispatch(getFiltersAction());
    if (_.isEmpty(operators)) dispatch(getOperatorsAction());
    getDictionaries(
      [
        `campaignType`,
        `campaign`,
        `taskStatus`,
        `communicationType`,
        `hmParking`,
        `hmSearchPartner`,
      ],
      true,
    );
    getTasks();
  }, []);

  return (
    <>
      <TaskPluralHeader
        campaignId={campaignId}
        campaignName={campaignName}
        onChange={onSelectedCampaignChange}
        selectedRowKeys={selectedRowKeys}
        tasks={mappedTasks}
      />
      <StyledLayout>
        <AdjustableTable
          columns={columns}
          dataSource={mappedTasks}
          defaultColumns={_.map(columns, `key`)}
          filters={tableState.filters}
          onChange={onTableChange}
          onTableColumnsChange={onTableColumnsChange}
          pagination={tableState.pagination}
          preferences={{
            path      : `tasks.${campaignName}`,
            cellRender: (...all) => {
              const taskId = _.get(_.last(all), `id`);
              const personIdInner = _.get(_.last(all), `personId`);

              return (
                <Link to={personIdInner ? `/tasks/task/${taskId}/person/${personIdInner}` : `/tasks/task/${taskId}`}>
                  <Button icon={<CaretRightOutlined />} shape='circle' type='primary' />
                </Link>
              );
            },
          }}
          rowKey='id'
          rowSelection={{
            // @ts-ignore
            onChange: onSelectChange,
            selectedRowKeys,
          }}
          scroll={{
            scrollToFirstRowOnChange: true,
            x                       : _.isEmpty(mappedTasks)
              ? undefined
              : `100%`,
            y: tableScrollY,
          }}
          scrollableHeader
        />
      </StyledLayout>
    </>
  );
};

export default PageTaskPlural;
