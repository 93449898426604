import api from 'api/index';
import { getLimitParams } from 'helper';

export const get = params => api.get(`/role-group?${getLimitParams(params)}`);
export const getSingle = id => api.get(`/role-group/${id}`);
export const remove = ids => api.delete(`/role-group`, { data: { ids } });
export const removeSingle = id => api.delete(`/role-group/${id}`);
export const update = (id, data) => api.patch(`/role-group/${id}`, data);
export const create = data => api.put(`/role-group`, data);
export const assignToUsers = (id, userIds) => api.post(`/role-group/${id}/assign`, { userIds });
export const removeFromUsers = (id, userIds) => api.post(`/role-group/${id}/remove`, { userIds });
