import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { formatDate } from 'helper';

import {
  getClientApplicationVector as getClientApplicationVectorAction,
  getClientPhones as getClientPhonesAction,
} from 'models/clients/actions';
import {
  getClientApplication as getClientApplicationSelector,
  getClientApplicationAuto as getClientApplicationAutoSelector,
  getClientApplicationVector as getClientApplicationVectorSelector,
} from 'models/clients/selectors';
import { get as getDictionaryAction } from 'models/dictionaries/actions';
import { getItems as getDictionary } from 'models/dictionaries/selectors';
import { getSingle as getVerificationAction } from 'models/verifications/actions';
import {
  getItem as getVerificationSelector,
  isLoading as isLoadingSelector,
} from 'models/verifications/selectors';

import { DefaultSpin } from 'components/DefaultSpin/DefaultSpin';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import { EntityCard } from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { TabContentWrapper } from 'pages/PageClient/Single/components/TabContentWrapper/TabContentWrapper';
import { AdditionalPhones } from 'pages/PageClient/Single/Info/AdditionalPhones';

import Application from './Application';
import ClientInfo from './ClientInfo';
import VectorCard from './VectorCard';

import { VECTOR_WEBDATA_NOTE } from './constants';

const Column = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px 0;

  margin-right: -8px;
  margin-left: -8px;

  ${EntityCard} {
    padding-right: 8px;
    padding-left: 8px;

    :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media(min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    gap: 16px 8px;
  }

  @media(min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Delimiter = styled.div`
  border-bottom: 1px solid ${palette.borderAccentColor};
  margin: 24px 0;
`;

const StyledTabContentWrapper = styled(TabContentWrapper)`
  position: relative;
`;

const PageClientSingleVector = () => {
  const dispatch = useDispatch();

  const { personId, verificationId } = useContext(ClientMatchContext);

  const isLoading = useSelector(isLoadingSelector);
  const vectorTitles = useSelector(state => getDictionary(state, `vectorTitle`));
  const verification = useSelector(state => getVerificationSelector(state, verificationId));

  const { applicationId } = verification;
  const application = useSelector(state => getClientApplicationSelector(state, personId, applicationId));
  const vector = useSelector(state => getClientApplicationVectorSelector(state, personId, applicationId));
  const auto = useSelector(state => getClientApplicationAutoSelector(state, personId, applicationId));
  const { applicationDtm } = application;

  const getVerification = () => dispatch(getVerificationAction({ id: verificationId }));
  const getVectorTitles = () => dispatch(getDictionaryAction({ entity: `vectorTitle`, force: true }));
  const getVector = () => dispatch(getClientApplicationVectorAction({ applicationId, personId }));
  const getClientPhones = useCallback(() => dispatch(getClientPhonesAction({ personId })), [dispatch, personId]);

  useEffect(() => {
    if (_.isEmpty(vector)) getVector();
    if (_.isEmpty(vectorTitles)) getVectorTitles();
    getClientPhones();
  }, []);

  const onReload = useCallback(() => {
    getVector();
    getVerification();
    getVectorTitles();
    getClientPhones();
  }, [getVector, getVerification]);

  return (
    <ClientTab isReloading={isLoading} onReload={onReload}>
      <StyledTabContentWrapper>
        {isLoading && <DefaultSpin />}

        <Wrapper>
          <Column>
            <ClientInfo
              applicationDate={formatDate(applicationDtm)}
              applicationId={applicationId}
              personId={personId}
            />
          </Column>

          <Column>
            <AdditionalPhones personId={personId} />
          </Column>

          <Column>
            <Application application={application} verification={verification} />
          </Column>
        </Wrapper>

        <Delimiter />

        <Wrapper>
          <Column>
            <VectorCard
              auto={auto}
              blockName='fraud'
              title='Фрод индикаторы'
              vector={vector}
              vectorTitles={vectorTitles}
            />

            <VectorCard
              auto={auto}
              blockName='fraudDevice'
              title='Фрод индикаторы: устройство пользователя'
              titleNote={VECTOR_WEBDATA_NOTE}
              vector={vector}
              vectorTitles={vectorTitles}
            />
          </Column>

          <Column>
            <VectorCard
              auto={auto}
              blockName='bki'
              title='БКИ'
              vector={vector}
              vectorTitles={vectorTitles}
            />

            <VectorCard
              auto={auto}
              blockName='internal'
              title='Внутренние данные'
              vector={vector}
              vectorTitles={vectorTitles}
            />
          </Column>

          <Column>
            <VectorCard
              auto={auto}
              blockName='auto'
              title='Информация по автомобилю'
              vector={vector}
              vectorTitles={vectorTitles}
            />
          </Column>
        </Wrapper>
      </StyledTabContentWrapper>
    </ClientTab>
  );
};

export default PageClientSingleVector;
