import _ from 'lodash';

import {
  get as getAction,
  model,
  SET_ITEMS,
} from './actions';
import { getItems as getItemsSelector } from './selectors';

export function *checkDictionary(entity, { put, select, take }) {
  const items = yield select(state => getItemsSelector(state, entity));
  if (!_.isEmpty(items)) return items;
  yield put(getAction({ entity, force: true }));
  yield take(action => action.type === `${model}/${SET_ITEMS}` && _.get(action, `payload.entity`) === entity);
  return yield select(state => getItemsSelector(state, entity));
}
