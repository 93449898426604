import React from 'react';

import {
  Date,
  Head,
  Title,
} from 'components/Eventlog/Item/styled';

interface IProps {
  date: string;
  operatorName?:string;
  title: string;
}
export const ItemHead: React.FC<IProps> = ({ date, operatorName, title }) => (
  <Head>
    <Title>{title}</Title>
    <Date>
      <div>{date}</div>
      <div>{operatorName}</div>
    </Date>
  </Head>
);
