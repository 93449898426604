import * as React from 'react';
import {
  useCallback,
  useContext,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { useHotkeys } from 'react-hotkeys-hook';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { WOOPRA_PERSONAL_URL } from 'constants/WOOPRA';
import {
  copyToClipboard,
  hasIntersection,
} from 'helper';

import {
  createPersonAction,
  get as getActionsAction,
} from 'models/actions/actions';
import {
  getItems as getActionsSelector,
  getPersonActionsByPersonId as getPersonActionsByClientIdSelector,
} from 'models/actions/selectors';
import { useSearchInBankrupts } from 'models/bankrupts/hooks';
import {
  createClientActiveEmployment as createClientActiveEmploymentAction,
  resetSms as resetSmsAction,
  switchProfile as switchProfileAction,
} from 'models/clients/actions';
import {
  getClient as getClientSelector,
  getClientActiveEmployment as getClientActiveEmploymentSelector,
  getIsLoadingInfo as isLoadingInfoSelector,
  isClientLoaded as isClientLoadedSelector,
} from 'models/clients/selectors';
import { useSearchInCourts } from 'models/courts/hooks';
import {
  ACTION_CLIENT_EDITOR,
  CLIENT_EDIT,
  CLIENT_PROFILE_CLOSER,
  OFFER_UPLOADER,
  SMS_SENDER,
  SUPER_ADMIN,
  TASK_CREATOR,
} from 'models/roles/constants';
import { useSearchInTasks } from 'models/task/hooks';
import { setModal as setModalAction } from 'models/ui/actions';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { usePath } from 'hooks/usePath';

import {
  AuditOutlined,
  CopyOutlined,
  EditOutlined,
  ExportOutlined,
  ImportOutlined,
  IssuesCloseOutlined,
  LinkOutlined,
  LoadingOutlined,
  MailOutlined,
  MoreOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { DefaultDropdown } from 'components/DefaultDropdown/DefaultDropdown';
import CreateModal from 'components/Modal/Create';
import CreateMarketingOfferModal from 'components/Modal/MarketingOfferCreate';
import {
  SubHeaderButtonDefault,
  subHeaderButtonDefaultMixin,
} from 'components/SubHeader/SubHeaderButtonDefault';
import { SubHeaderButtonPrimary } from 'components/SubHeader/SubHeaderButtonPrimary';
import { getHotkeyTitle } from 'pages/Layout/GlobalHotkeys/helpers';
import { CREATE_PERSON_ACTION_FORM_FIELDS } from 'pages/PageClient/Single/Action/constants';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { EditClientModal } from 'pages/PageClient/Single/components/EditClientModal/EditClientModal';
import {
  EDIT_WORK_FORM_FIELDS,
  getAllowedForTitle,
} from 'pages/PageClient/Single/Info/constants';
import { CreateTaskModal } from 'pages/PageTask/Single/components/CreateTaskModal/CreateTaskModal';

const Wrapper = styled.div``;

const ControlsWrapper = styled.div`
  display: flex;

  ${SubHeaderButtonDefault} {
    display: none;
    :not(:first-child) {
      margin-left: 12px;
    }
  }

  @media(min-width: ${breakpoints.md}) {
    ${SubHeaderButtonPrimary} {
      display: block;
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    ${SubHeaderButtonDefault} {
      display: block;

      :not(:first-child) {
        margin-left: 16px;
      }
    }
  }
`;

const ShowMoreControlsButton = styled.button`
  ${subHeaderButtonDefaultMixin};
  margin-left: 12px;

  .anticon:first-child {
    margin-right: 0;
  }

  @media(min-width: ${breakpoints.lg}) {
    margin-left: 16px;
  }
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Hotkey = styled.div`
  display: none;

  @media(min-width: ${breakpoints.md}) {
    display: block;
    margin-left: 16px;
    color: ${palette.black80Color};
  }
`;

interface IProps {
  isReloading?: boolean;
  onReload?(): void;
}

const ClientSubHeaderControls: React.FC<IProps> = ({ isReloading, onReload }) => {
  const { personId } = useContext(ClientMatchContext) as { personId: string };
  const dispatch = useDispatch();
  const { pushPath } = usePath();

  const actions = useSelector(state => getActionsSelector(state));
  const activeEmployment = useSelector(state => getClientActiveEmploymentSelector(state, personId));
  const loanClient = useSelector(state => getClientSelector(state, personId));
  const isLoadingInfo = useSelector(state => isLoadingInfoSelector(state));
  // @ts-ignore
  const personActions = useSelector(state => getPersonActionsByClientIdSelector(state, personId));
  const userRoles = useSelector(state => getRoleNamesSelector(state));

  const lkAccessFlg = loanClient?.lkAccessFlg;
  const showEditEmployment = !activeEmployment && !_.isEmpty(loanClient);

  const createEmployment = (data: any) => dispatch(createClientActiveEmploymentAction({ data, personId }));
  const getActions = () => dispatch(getActionsAction());
  const openSendSmsModal = () => dispatch(setModalAction({ personId, isOpen: true, modalName: `sendSms` }));
  const resetSms = () => dispatch(resetSmsAction({ personId }));
  const switchProfile = () => dispatch(switchProfileAction({ accessFlg: !lkAccessFlg, personId }));
  const updatePersonAction = ({ actionIds }: { actionIds: any }) => dispatch(createPersonAction({
    actionIds,
    personId,
  }));

  const [editClientModalVisible, setEditClientModalVisible] = useState(false);
  const toggleEditClientModalVisible = () => setEditClientModalVisible(!editClientModalVisible);

  const [editClientWorkModalVisible, setEditClientWorkModalVisible] = useState(false);
  const toggleEditClientWorkModalVisible = () => setEditClientWorkModalVisible(!editClientWorkModalVisible);

  const [createTaskModalVisible, setCreateTaskModalVisible] = useState(false);
  const toggleCreateTaskModal = () => setCreateTaskModalVisible(!createTaskModalVisible);

  const [createMarketingOfferModalVisible, setCreateMarketingOfferModalVisible] = useState(false);
  const toggleCreateMarketingOfferModal = () => setCreateMarketingOfferModalVisible(!createMarketingOfferModalVisible);

  const [createPersonActionModalVisible, setCreatePersonActionModalVisible] = useState(false);
  const toggleCreatePersonActionModal = () => setCreatePersonActionModalVisible(!createPersonActionModalVisible);
  const openCreatePersonActionModal = () => {
    if (_.isEmpty(actions)) {
      getActions();
    }
    setCreatePersonActionModalVisible(true);
  };

  const allowedToEditClientRoles = [SUPER_ADMIN, CLIENT_EDIT];
  const canEditClient = hasIntersection(userRoles, allowedToEditClientRoles);
  const editClientTitle = getAllowedForTitle(allowedToEditClientRoles);

  const allowedToSendSmsRoles = [SUPER_ADMIN, SMS_SENDER];
  const canSendSms = hasIntersection(userRoles, allowedToSendSmsRoles);
  const sendSmsTitle = getAllowedForTitle(allowedToSendSmsRoles);

  const allowedToSwitchProfileRoles = [SUPER_ADMIN, CLIENT_PROFILE_CLOSER];
  const canSwitchProfile = hasIntersection(userRoles, allowedToSwitchProfileRoles);
  const switchProfileTitle = getAllowedForTitle(allowedToSwitchProfileRoles);

  const allowedToCreateTaskRoles = [SUPER_ADMIN, TASK_CREATOR];
  const canCreateTask = hasIntersection(userRoles, allowedToCreateTaskRoles);
  const createTaskTitle = getAllowedForTitle(allowedToCreateTaskRoles);

  const allowedToCreateMarketingOfferRoles = [SUPER_ADMIN, OFFER_UPLOADER];
  const canCreateMarketingOffer = hasIntersection(userRoles, allowedToCreateMarketingOfferRoles);
  const createMarketingOfferTitle = getAllowedForTitle(allowedToCreateMarketingOfferRoles);

  // @ts-ignore
  const personalWoopraUrl = WOOPRA_PERSONAL_URL(personId);

  // @ts-ignore
  const isClientLoaded = useSelector(state => isClientLoadedSelector(state, personId));

  const goToVerification = () => {
    pushPath(`/verification?personId=${personId}`);
  };

  const searchInTasks = useSearchInTasks();
  const searchInBankrupts = useSearchInBankrupts();
  const searchInCourts = useSearchInCourts();

  const goToTasks = useCallback(() => searchInTasks(personId), [searchInTasks, personId]);
  const goToBankrupts = useCallback(() => searchInBankrupts(personId), [searchInBankrupts, personId]);
  const goToCourts = useCallback(() => searchInCourts(personId), [searchInCourts, personId]);

  const isActionsTab = window.location.pathname.includes(`action`);

  const allowedToEditClientActions = [SUPER_ADMIN, ACTION_CLIENT_EDITOR];
  const canCreatePersonAction = hasIntersection(userRoles, allowedToEditClientActions);
  const createPersonActionTitle = getAllowedForTitle(allowedToEditClientActions);

  const menuItems = [
    {
      hidden : !onReload,
      key    : `reload-client`,
      primary: true,
      onClick: onReload,
      icon   : isReloading ? <LoadingOutlined spin /> : <ReloadOutlined />,
      label  : (<Label>Перезагрузить <Hotkey>{getHotkeyTitle(`shift+R`)}</Hotkey></Label>),
    },
    {
      key     : `edit-client`,
      onClick : toggleEditClientModalVisible,
      icon    : <EditOutlined />,
      label   : `Изменить данные`,
      tooltip : !canEditClient ? editClientTitle : undefined,
      disabled: !canEditClient,
    },
    {
      key     : `toggle-create-task-modal`,
      disabled: !canCreateTask,
      onClick : toggleCreateTaskModal,
      tooltip : !canCreateTask ? createTaskTitle : undefined,
      label   : `Создать задачу`,
      icon    : <PlusOutlined />,
    },
    {
      key     : `toggle-create-marketing-offer-modal`,
      disabled: !canCreateMarketingOffer,
      onClick : toggleCreateMarketingOfferModal,
      tooltip : !canCreateMarketingOffer ? createMarketingOfferTitle : undefined,
      label   : `Создать оффер`,
      icon    : <PlusOutlined />,
    },
    {
      hidden  : !isActionsTab,
      key     : `open-create-person-action-modal`,
      onClick : openCreatePersonActionModal,
      icon    : <PlusOutlined />,
      label   : `Добавить акции`,
      tooltip : !canCreatePersonAction ? createPersonActionTitle : undefined,
      disabled: !canCreatePersonAction,
    },
    {
      key : `divider-1`,
      type: `divider`,
    },
    {
      key     : `go-to-verification`,
      onClick : goToVerification,
      icon    : <AuditOutlined />,
      disabled: !isClientLoaded,
      label   : (<Label>Искать на верификации <Hotkey>{getHotkeyTitle(`shift+V`)}</Hotkey></Label>),
    },
    {
      key     : `go-to-collection`,
      onClick : goToTasks,
      icon    : <IssuesCloseOutlined />,
      disabled: !isClientLoaded,
      label   : (<Label>Искать на взыскании <Hotkey>{getHotkeyTitle(`shift+T`)}</Hotkey></Label>),
    },
    {
      key     : `go-to-bankrupts`,
      onClick : goToBankrupts,
      icon    : <IssuesCloseOutlined />,
      disabled: !isClientLoaded,
      label   : (<Label>Искать в банкротах</Label>),
    },
    {
      key     : `go-to-courts`,
      onClick : goToCourts,
      icon    : <AuditOutlined />,
      disabled: !isClientLoaded,
      label   : (<Label>Искать в судах</Label>),
    },
    {
      key : `divider-2`,
      type: `divider`,
    },
    {
      key    : `reset-sms`,
      onClick: resetSms,
      icon   : <ReloadOutlined />,
      label  : `Сбросить счетчик СМС`,
    },
    {
      key    : `copy-to-clipboard`,
      onClick: () => copyToClipboard(personId),
      icon   : <CopyOutlined />,
      label  : `Скопировать ID`,
    },
    {
      key     : `open-send-sms-modal`,
      disabled: !canSendSms,
      onClick : openSendSmsModal,
      tooltip : !canSendSms ? sendSmsTitle : undefined,
      icon    : <MailOutlined />,
      label   : `Отправить SMS`,
    },
    {
      key  : `woopra-link`,
      label: (
        <a
          href={personalWoopraUrl}
          rel='noreferrer'
          style={{ textDecoration: `underline` }}
          target='_blank'
        >
          <LinkOutlined />
          Woopra
        </a>
      ),
    },
    {
      hidden  : !showEditEmployment,
      key     : `toggle-edit-client-work-modal`,
      disabled: !canEditClient,
      onClick : () => toggleEditClientWorkModalVisible(),
      tooltip : !canEditClient ? editClientTitle : undefined,
      icon    : <PlusOutlined />,
      label   : `Добавить место работы`,
    },
    {
      key : `divider-3`,
      type: `divider`,
    },
    {
      key     : `switch-profile`,
      disabled: !canSwitchProfile,
      onClick : switchProfile,
      tooltip : !canSwitchProfile ? switchProfileTitle : undefined,
      icon    : lkAccessFlg ? <ImportOutlined /> : <ExportOutlined />,
      label   : lkAccessFlg ? `Закрыть ЛК` : `Открыть ЛК`,
    },
  ];

  useHotkeys(`shift+R`, () => onReload && onReload(), {}, [onReload]);
  useHotkeys(`shift+V`, goToVerification, {}, []);
  useHotkeys(`shift+T`, goToTasks, {}, []);

  return (
    <Wrapper>
      <ControlsWrapper>
        <SubHeaderButtonDefault
          disabled={!canCreateTask}
          onClick={() => toggleCreateTaskModal()}
          title={createTaskTitle}
        >
          <PlusOutlined />Создать задачу
        </SubHeaderButtonDefault>
        <SubHeaderButtonDefault
          disabled={!canEditClient}
          onClick={() => toggleEditClientModalVisible()}
          title={editClientTitle}
        >
          <EditOutlined />Изменить данные
        </SubHeaderButtonDefault>

        {onReload && (
          <SubHeaderButtonPrimary disabled={isReloading} onClick={() => onReload()}>
            {isReloading ? (
              <LoadingOutlined spin />
            ) : (
              <ReloadOutlined />
            )}
            Перезагрузить
          </SubHeaderButtonPrimary>
        )}

        <DefaultDropdown
          // @ts-ignore
          menu={{ items: menuItems }}
          trigger={[`click`]}
        >
          <ShowMoreControlsButton>
            <MoreOutlined />
          </ShowMoreControlsButton>
        </DefaultDropdown>
      </ControlsWrapper>

      <EditClientModal
        isOpen={editClientModalVisible}
        personId={personId}
        toggleOpen={toggleEditClientModalVisible}
      />

      {canEditClient && editClientWorkModalVisible && (
        <CreateModal
          close={toggleEditClientWorkModalVisible}
          create={createEmployment}
          formFields={EDIT_WORK_FORM_FIELDS}
          isLoading={isLoadingInfo}
          mode='create'
          title='Добавление активного трудоустройства клиента'
          visible={editClientWorkModalVisible}
        />
      )}

      {canCreateTask && (
        <CreateTaskModal
          close={toggleCreateTaskModal}
          personId={personId}
          visible={createTaskModalVisible}
        />
      )}

      {canCreateMarketingOffer && (
        <CreateMarketingOfferModal
          onClose={toggleCreateMarketingOfferModal}
          personId={personId}
          visible={createMarketingOfferModalVisible}
        />
      )}

      {canCreatePersonAction && (
        <CreateModal
          close={toggleCreatePersonActionModal}
          formFields={CREATE_PERSON_ACTION_FORM_FIELDS({ actions, personActions })}
          mode='update'
          title='Добавление акций клиенту'
          update={updatePersonAction}
          visible={createPersonActionModalVisible}
        />
      )}
    </Wrapper>
  );
};

export default ClientSubHeaderControls;
