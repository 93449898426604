import _ from 'lodash';

export const checkStatus = (status:number): boolean => status >= 200 && status < 400;

interface ParseResponse {
  dataPath?: string;
  defaultError?: string;
  emptyStatus?: number;
  emptyValue?: any;
  errorPath?: string;
  normalize?: (arg0: any) => any;
  response?: any;
}

export const parseResponse = ({
  dataPath = `data`,
  defaultError,
  emptyStatus,
  emptyValue,
  errorPath = `data.error`,
  normalize,
  response,
}: ParseResponse): any => {
  const status = _.get(response, `status`);
  const statusProxy = _.get(response, `data.error.statusCode`, 200);
  if (status === emptyStatus) return _.isFunction(normalize) ? normalize(emptyValue) : emptyValue;
  const error = _.get(response, errorPath, defaultError);
  if (!checkStatus(status) || !checkStatus(statusProxy) || _.get(response, `data.error.message`)) throw error;
  const result = _.get(response, dataPath);
  return _.isFunction(normalize) ? normalize(result) : result;
};
