import _ from 'lodash';
import { v4 } from 'uuid';

import { parseResponse } from 'api/helpers';
import {
  downloadBlob,
  showError,
  sleep,
} from 'helper';

import { isAuth } from 'models/user/helpers';

import { notification } from 'antd';
import { INACTIVE_VISIBLE_ENTITITES } from 'pages/PageAdmin/Dictionaries/Plural/constants';

import {
  CREATE,
  CREATE_EXTERNAL,
  DOWNLOAD_TEMPLATE_SINGLE,
  DOWNLOAD_TEMPLATES,
  GET,
  GET_MULTIPLE,
  GET_SINGLE,
  LINK_EXTERNAL,
  model as namespace,
  REMOVE,
  REMOVE_EXTERNAL,
  REMOVE_SINGLE,
  REMOVE_SINGLE_EXTERNAL,
  RESET,
  RESET_FINISHED,
  SET,
  SET_FINISHED,
  SET_ITEMS,
  SET_LOADING,
  SET_SEARCH_STRING,
  UNLINK_EXTERNAL,
  UPDATE,
  UPDATE_EXTERNAL,
  UPLOAD_EXTERNAL,
} from './actions';
import * as api from './api';
import {
  getFinished as getFinishedSelector,
  getItems,
  getItemsByIds,
  isLoaded as isLoadedSelector,
} from './selectors';

import {
  EXTERNAL,
  EXTERNAL_CREATE_URLS,
  EXTERNAL_UPDATE_URLS,
  PERSIST_TIMEOUT,
  PERSISTED_DICTIONARIES,
} from './constants';

const initialState = {
  finished : {},
  isLoading: false,
  items    : {
    carServiceTitle       : [],
    clientCondition       : [],
    contractCondition     : [],
    documentAlias         : [],
    documentGroup         : [],
    documentPackage       : [],
    notificationList      : [],
    phoneType             : [],
    verificationResult    : [],
    verificationResultType: [],
    verificationStatus    : [],
  },
  loaded      : [],
  searchString: {
    notificationList      : ``,
    phoneType             : ``,
    verificationResult    : ``,
    verificationResultType: ``,
    verificationStatus    : ``,
  },
};

export default {
  namespace,
  state        : initialState,
  subscriptions: {
    setup({ dispatch }) {
      if (isAuth()) setTimeout(() => dispatch({ type: GET, payload: { entity: `campaign`, force: true } }), 200);
    },
  },
  effects: {
    *[GET](action, { call, put, select, take }) {
      const defaultError = `При запросе справочника ${_.get(action, `payload.entity`)} произошла ошибка.`;
      const { entity, force, setLoading = true, uuid } = action.payload;
      const isExternal = _.has(EXTERNAL, entity);
      try {
        if (!entity) throw new Error(defaultError);

        if (setLoading) yield put({ type: SET_LOADING, payload: true });
        if (!force) {
          // eslint-disable-next-line no-underscore-dangle
          const isRehydrated = yield select(state => {
            return state._persist?.rehydrated
          });
          if (!isRehydrated) yield take(`persist/REHYDRATE`);
          const isLoaded = yield select(state => isLoadedSelector(state, entity));
          if (isLoaded) return;
        }

        let items;
        if (!force
            && _.includes(PERSISTED_DICTIONARIES, entity)
            && (+(new Date()) - PERSIST_TIMEOUT) <= +(localStorage[`persistedAt_${entity}`] || 0)
            && localStorage[`persistedDictionary_${entity}`]
        ) {
          items = JSON.parse(localStorage[`persistedDictionary_${entity}`]);
        } else {
          items = parseResponse({
            defaultError,
            response: yield call(isExternal ? api.getExternal : api.get, entity),
          });
          if (entity === `city`) items = _.orderBy(items, `cityName`, `asc`);

          localStorage[`persistedDictionary_${entity}`] = JSON.stringify(items);
          localStorage[`persistedAt_${entity}`] = +(new Date());
        }
        if (entity === `smsTemplate`) items = items?.smsTemplates;
        if (isExternal && !_.includes(INACTIVE_VISIBLE_ENTITITES, entity)) {
          items = _.filter(items, item => (
            !_.has(item, `activeFlg`)
          || item.activeFlg));
        }
        yield put({ type: SET_ITEMS, payload: { entity, items } });
        if (_.isFunction(action.payload.callback)) action.payload.callback(items);
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        if (setLoading) yield put({ type: SET_LOADING, payload: false });
        if (uuid) yield put({ type: SET_FINISHED, payload: uuid });
      }
    },

    *[GET_MULTIPLE](action, { all, call, put, select }) {
      const defaultError = `При запросе справочников ${_.get(action, `payload.entities`)} произошла ошибка.`;
      try {
        yield put({ type: SET_LOADING, payload: true });
        const { entities, force } = action.payload;
        if (_.isEmpty(entities)) throw new Error(defaultError);
        const uuid = v4();
        yield all(_.map(entities, entity => put({
          type   : GET,
          payload: {
            entity,
            force,
            setLoading: false,
            uuid,
          },
        })));
        let finished = yield select(state => getFinishedSelector(state, uuid));
        while (finished < entities.length) {
          yield call(sleep, 100);
          finished = yield select(state => getFinishedSelector(state, uuid));
        }

        yield put({ type: RESET_FINISHED, payload: uuid });
        if (_.isFunction(action.payload.callback)) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_SINGLE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { entity, id } = action.payload;
      const defaultError = `При загрузке справочника '${entity}' с id '${id}' произошла ошибка`;

      try {
        if (!entity || !id) throw new Error(defaultError);

        const item = parseResponse({
          defaultError,
          response: yield call(api.getSingle, id),
        });

        const currentItems = yield select(state => getItems(state, entity));

        yield put({
          type   : SET_ITEMS,
          payload: {
            entity,
            items: _.map(currentItems, currentItem => (currentItem.id === id
              ? { ...currentItem, ...item }
              : currentItem
            )),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE](action, { call, put, select }) {
      const { entity, item } = action.payload;
      if (_.has(EXTERNAL, entity)) return yield put({ ...action, type: CREATE_EXTERNAL });
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При создании записи справочника '${entity}' произошла ошибка`;

      try {
        if (!entity || _.isEmpty(item)) throw new Error(defaultError);

        const newItem = parseResponse({
          dataPath: `data[0]`,
          defaultError,
          response: yield call(api.create, entity, [item]),
        });

        const currentItems = yield select(state => getItems(state, entity));

        yield put({
          type   : SET_ITEMS,
          payload: {
            entity,
            items: _.concat(currentItems, [newItem]),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE_EXTERNAL](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { entity, item } = action.payload;
      const defaultError = `При создании записи справочника '${entity}' произошла ошибка`;

      try {
        if (!entity || _.isEmpty(item)) throw new Error(defaultError);

        const isCustomCreateUrl = _.has(EXTERNAL_CREATE_URLS, entity);
        const isCustomUpdateUrl = _.has(EXTERNAL_UPDATE_URLS, entity);
        if (isCustomCreateUrl) {
          let createItem = { ...item };
          if (entity === `smsTemplate`) {
            createItem = {
              ...item,
              activeFlg: !!item.activeFlg,
            };
          }
          parseResponse({
            dataPath: `data[0]`,
            defaultError,
            response: yield call(
              api.postExternalCustomPath,
              `/proxy/${EXTERNAL_CREATE_URLS[entity]}`,
              createItem,
            ),
          });
        } else if (isCustomUpdateUrl || entity === `partnerRate`) {
          let createItem = { ...item };
          if (entity === `marketingOfferType`) {
            createItem = {
              ...item,
              activeFlg: _.isNil(item.activeFlg) ? true : item.activeFlg,
            };
          }
          if (entity === `smsTemplate`) {
            createItem = {
              ...item,
              activeFlg: !!item.activeFlg,
            };
          }
          parseResponse({
            dataPath: `data[0]`,
            defaultError,
            response: yield call(
              api.postExternalCustomPath,
              isCustomUpdateUrl
                ? `/proxy/${EXTERNAL_UPDATE_URLS[entity]}`
                : `/proxy/${EXTERNAL[entity]}/${item.partnerId}`,
              createItem,
            ),
          });
        } else {
          parseResponse({
            dataPath: `data[0]`,
            defaultError,
            response: yield call(api.postExternal, entity, { ...item, activeFlg: true }),
          });
        }

        yield put({ type: GET, payload: { entity, force: true } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[DOWNLOAD_TEMPLATE_SINGLE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { name, templateId } = action.payload;
      const defaultError = `При загрузке шаблона произошла ошибка`;

      try {
        if (!templateId) throw new Error(defaultError);

        const blob = parseResponse({
          defaultError,
          response: yield call(api.downloadTemplateById, templateId),
        });

        downloadBlob(blob, name || `Шаблон ${templateId}.docx`);

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[DOWNLOAD_TEMPLATES](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { templateIds } = action.payload;
      const defaultError = `При загрузке шаблонов произошла ошибка`;

      try {
        if (_.isEmpty(templateIds)) throw new Error(defaultError);

        const blob = parseResponse({
          defaultError,
          response: yield call(api.downloadTemplates, templateIds),
        });

        downloadBlob(blob, `Шаблоны ${_.join(_.sortBy(templateIds), `, `)}.zip`);

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[LINK_EXTERNAL](action, { all, call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { data, entity, targetEntity } = action.payload;
      const defaultError = `При связывании записей произошла ошибка`;

      try {
        if (!entity || _.isEmpty(data)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.linkExternal, entity, data),
        });

        yield all([
          put({ type: GET, payload: { entity, force: true } }),
          put({ type: GET, payload: { entity: targetEntity, force: true } }),
        ]);
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE](action, { call, put, select }) {
      const { entity, ids } = action.payload;
      if (_.has(EXTERNAL, entity)) return yield put({ ...action, type: REMOVE_EXTERNAL });
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При удалении записей справочника '${entity}' с id '${ids}' произошла ошибка`;

      try {
        if (!entity || _.isEmpty(ids)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.remove, entity, ids),
        });

        const currentItems = yield select(state => getItems(state, entity));

        yield put({
          type   : SET_ITEMS,
          payload: {
            entity,
            items: _.reject(currentItems, ({ id }) => _.includes(ids, id)),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE_EXTERNAL](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { entity, ids } = action.payload;
      const defaultError = `При удалении записей справочника '${entity}' с id '${ids}' произошла ошибка`;

      try {
        if (!entity || _.isEmpty(ids)) throw new Error(defaultError);

        const itemsByIds = yield select(state => getItemsByIds(state, entity, ids));
        const customUpdateUrl = EXTERNAL_UPDATE_URLS[entity];
        const itemsToRemove = _.map(itemsByIds, item => ({
          ...item,
          activeFlg: false,
        }));
        if (customUpdateUrl) {
          parseResponse({
            dataPath: `data[0]`,
            defaultError,
            response: yield call(
              api.postExternalCustomPath,
              `/proxy/${customUpdateUrl}`,
              itemsToRemove,
            ),
          });
        } else {
          parseResponse({
            defaultError,
            response: yield call(api.postExternal, entity, itemsToRemove),
          });
        }
        yield put({ type: GET, payload: { entity, force: true } });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE_SINGLE](action, { call, put, select }) {
      const { entity, id } = action.payload;
      if (_.has(EXTERNAL, entity)) return yield put({ ...action, type: REMOVE_SINGLE_EXTERNAL });
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При удалении записи справочника '${entity}' с id '${id}' произошла ошибка`;

      try {
        if (!entity || !id) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.removeSingle, entity, id),
        });

        const currentItems = yield select(state => getItems(state, entity));

        yield put({
          type   : SET,
          payload: {
            entity,
            items: _.reject(currentItems, { id }),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE_SINGLE_EXTERNAL](action, { put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { entity, id } = action.payload;
      const defaultError = `При удалении записи справочника '${entity}' с id '${id}' произошла ошибка`;

      try {
        if (!entity || !id) throw new Error(defaultError);

        yield put({
          type   : REMOVE_EXTERNAL,
          payload: {
            ...action.payload,
            ids: [id],
          },
        });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UNLINK_EXTERNAL](action, { all, call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { data, entity, targetEntity } = action.payload;
      const defaultError = `При удалении привязки записей произошла ошибка`;

      try {
        if (!entity || !targetEntity || _.isEmpty(data)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.unlinkExternal, entity, data),
        });

        yield all([
          put({ type: GET, payload: { entity, force: true } }),
          put({ type: GET, payload: { entity: targetEntity, force: true } }),
        ]);
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE](action, { call, put, select }) {
      const { entity, id, item } = action.payload;
      if (_.has(EXTERNAL, entity)) return yield put({ ...action, type: UPDATE_EXTERNAL });
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При редактировании записи справочника '${entity}' произошла ошибка`;

      try {
        if (!entity || !id || _.isEmpty(item)) throw new Error(defaultError);

        const updatedItem = parseResponse({
          defaultError,
          response: yield call(api.update, entity, id, item),
        });

        const currentItems = yield select(state => getItems(state, entity));

        yield put({
          type   : SET_ITEMS,
          payload: {
            entity,
            items: _.map(currentItems, currentItem => (currentItem.id === id
              ? { ...currentItem, ...updatedItem }
              : currentItem
            )),
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE_EXTERNAL](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { entity, id, item } = action.payload;
      const defaultError = `При редактировании записи справочника '${entity}' произошла ошибка`;

      try {
        if (!entity || !id || _.isEmpty(item)) throw new Error(defaultError);

        let updateItem = { ...item };
        if (
          entity === `marketingOfferType`
          || (!_.has(EXTERNAL_UPDATE_URLS, entity) && entity !== `marketingBlacklistType`)
        ) {
          updateItem = {
            ...updateItem,
            activeFlg: _.isNil(updateItem.activeFlg) ? true : updateItem.activeFlg,
          };
        }

        if (_.has(EXTERNAL_UPDATE_URLS, entity)) {
          const updatePath = _.isFunction(EXTERNAL_UPDATE_URLS[entity])
            ? `/proxy/${EXTERNAL_UPDATE_URLS[entity](id)}`
            : `/proxy/${EXTERNAL_UPDATE_URLS[entity]}`;
          parseResponse({
            dataPath: `data[0]`,
            defaultError,
            response: yield call(api.postExternalCustomPath, updatePath, updateItem),
          });
        } else {
          parseResponse({
            defaultError,
            response: yield call(api.postExternal, entity, updateItem),
          });
        }

        yield put({ type: GET, payload: { entity, force: true } });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPLOAD_EXTERNAL](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { file, label, path, type } = action.payload;
      const defaultError = `При загрузке ЧС '${type}' произошла ошибка`;
      const notificationKey = `${+(new Date())}_BL_${type}`;

      try {
        if (!path || !type || !file) throw new Error(defaultError);

        notification.info({
          duration: 0,
          key     : notificationKey,
          message : `ЧС загружается. Это может занять до 5 минут, не закрывайте вкладку`,
        });
        parseResponse({
          defaultError,
          response: yield call(api.uploadExternal, path, file),
        });

        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();

        notification.close(notificationKey);
        notification.success({
          duration: 0,
          message : `ЧС ${label ? `'${label}'` : `с типом '${type}'`} успешно загружен`,
        });
      } catch (error) {
        notification.close(notificationKey);
        showError({ defaultError, duration: 0, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [RESET]() {
      return { ...initialState };
    },

    [RESET_FINISHED](state, { payload: uuid }) {
      if (!uuid) return state;
      return {
        ...state,
        finished: _.omit(state.finished || {}, [uuid]),
      };
    },

    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        ...payload,
        loaded: _.uniq(_.concat(_.keys(payload), state.loaded)),
      };
    },

    [SET_FINISHED](state, { payload: uuid }) {
      if (!uuid) return state;
      return {
        ...state,
        finished: {
          ...(state.finished || {}),
          [uuid]: (state.finished?.[uuid] || 0) + 1,
        },
      };
    },

    [SET_ITEMS](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        items: {
          ...state.items,
          [payload.entity]: payload.items,
        },
        loaded: _.uniq(_.concat(_.castArray(payload.entity), state.loaded)),
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },

    [SET_SEARCH_STRING](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        searchString: {
          ...state.searchString,
          [payload.entity]: payload.searchString,
        },
      };
    },
  },
};
