import {
  useEffect,
  useState,
} from 'react';

export default function useOnScreen(ref, initialValue) {
  const [isIntersecting, setIntersecting] = useState(initialValue || false);

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
  );

  useEffect(() => {
    setIntersecting(initialValue || false);
  }, [initialValue]);

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isIntersecting;
}
