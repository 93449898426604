import { createAction } from 'redux-actions';

export const model = `notifications`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const ADD_NOTIFICATIONS = `ADD_NOTIFICATIONS`;
export const GET = `GET`;
export const GET_COUNT = `GET_COUNT`;
export const RESET = `RESET`;
export const SET = `SET`;
export const SET_ALL_IS_READ = `SET_ALL_IS_READ`;
export const SET_ALL_NOTIFICATIONS_READ = `SET_ALL_NOTIFICATIONS_READ`;
export const SET_LOADING = `SET_LOADING`;
export const SET_NOTIFICATION_ATTRIBUTE = `SET_NOTIFICATION_ATTRIBUTE`;
export const SET_FILTER = `SET_FILTER`;
export const UPDATE_IS_READ = `UPDATE_IS_READ`;

export const addNotifications = ca(ADD_NOTIFICATIONS);
export const get = ca(GET);
export const getCount = ca(GET_COUNT);
export const reset = ca(RESET);
export const set = ca(SET);
export const setAllIsRead = ca(SET_ALL_IS_READ);
export const setAllNotificationsRead = ca(SET_ALL_NOTIFICATIONS_READ);
export const setLoading = ca(SET_LOADING);
export const setNotificationAttribute = ca(SET_NOTIFICATION_ATTRIBUTE);
export const setFilter = ca(SET_FILTER);
export const updateIsRead = ca(UPDATE_IS_READ);
