import React from 'react';
import styled from 'styled-components';
import useDetectMobile from 'hooks/useDetectMobile';

const {BRANCH, COMMIT_DATE, COMMIT_HASH, VERSION } = process?.env || {};
console.log(`process.env`, process?.env);

const PageVersion: React.FC = () => {
  const mobile = useDetectMobile();
  return (
    <Container mobile={mobile}>
      <div className="row">
        <Label>Version:</Label>
        <Value>{VERSION}</Value>
      </div>
      <div className="row">
        <Label>Commit Hash:</Label>
        <Value link>
          {COMMIT_HASH}
        </Value>
      </div>
      <div className="row">
        <Label>Commit Date:</Label>
        <Value link>
          {COMMIT_DATE}
        </Value>
      </div>
      <div className="row">
        <Label>Branch:</Label>
        <Value link>
          {BRANCH}
        </Value>
      </div>
    </Container>
  );
};

export default PageVersion;

const Container = styled.div<{ mobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;

  .row {
    display: flex;
    min-width: 50%;
    margin-bottom: 20px;
    justify-content: space-between;
  }
`;

const Label = styled.div`
  color: #000; /* $blackColor */
`;

const Value = styled.div<{ link?: boolean }>`
  color: #888; /* $grayColor */

  ${({ link }) =>
  link &&
  `
    text-decoration: underline;
    color: #007bff; /* $baseAccentColor */
  `}

  a {
    color: inherit;
    text-decoration: none;
  }
`;
