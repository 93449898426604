import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import {
  CaretRightOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Popover,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

const DownloadButton = styled(Button)`
  &+& {
    margin-left: 4px;
  }

  @media(max-width: ${breakpoints.md}) {
    &+& {
      margin-left: 0;
      margin-top: 4px;
    }
  }
`;

interface IProps {
  audioSrc: string | null;
  downloadCallRecord: (url: string, recordTitle: string) => void;
  handleAudioPopoverState: (state: boolean) => void;
  isCallRecordPlayerOpen: boolean;
  recordTitle: string;
  showCallRecordPlayer: (url: string) => void;
  url: string;
}

export const CallRecordControls: React.FC<IProps> = ({
  audioSrc,
  downloadCallRecord,
  handleAudioPopoverState,
  isCallRecordPlayerOpen,
  recordTitle,
  showCallRecordPlayer,
  url,
}) => (
  <>
    <DefaultTooltip key={`play_${url}`} title='Прослушать запись'>
      <Popover
        content={isCallRecordPlayerOpen && audioSrc && (
          <audio autoPlay controls src={audioSrc}>
            <track default kind='captions' />
          </audio>
        )}
        onOpenChange={handleAudioPopoverState}
        open={isCallRecordPlayerOpen}
        placement='left'
        title={recordTitle}
        trigger='click'
      >
        <DownloadButton
          icon={<CaretRightOutlined />}
          onClick={() => showCallRecordPlayer(url)}
          shape='circle'
          type='primary'
        />
      </Popover>
    </DefaultTooltip>
    <DefaultTooltip key={url} title='Скачать запись'>
      <DownloadButton
        icon={<DownloadOutlined />}
        onClick={() => downloadCallRecord(url, recordTitle)}
        shape='circle'
        type='primary'
      />
    </DefaultTooltip>
  </>
);
