import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment, { Moment } from 'moment/moment';
import styled from 'styled-components';

import {
  getNumber,
  inputNumberFormatter,
  inputNumberParser,
  normalizeMomentToStartOfDay,
} from 'helper';

import { createMarketingOffer } from 'models/clients/actions';
import { getIsLoading as getIsLoadingSelector } from 'models/clients/selectors';
import { IMarketingOffer } from 'models/clients/types';
import { useDictionaries } from 'models/dictionaries/hooks';
import { getFullName } from 'models/user/selectors';

import {
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Select,
  Spin,
} from 'antd';

const StyledInputNumber = styled(InputNumber)`
  width: 200px;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

interface IProps {
  onClose: () => void,
  personId: string,
  visible: boolean
}

interface IErrors {
  author?: string;
  closeDtm?: string;
  createDtm?: string;
  limit?: string;
  payment?: string;
  personId?: string;
  productId?: string;
  rate?: string;
  startDtm?: string;
  term?: string;
  totalLimit?: string;
  typeId?: string;
}

// @ts-ignore
const defaultValues: IMarketingOffer = {
  author            : ``,
  // @ts-ignore
  closeDtm          : null,
  // @ts-ignore
  createDtm         : null,
  insuranceAmount   : 0,
  insuranceFlg      : false,
  insuranceProductId: 0,
  insuranceTerm     : 0,
  // @ts-ignore
  limit             : null,
  offerStatusId     : 1,
  // @ts-ignore
  payment           : null,
  personId          : ``,
  // @ts-ignore
  productId         : null,
  // @ts-ignore
  rate              : null,
  // @ts-ignore
  startDtm          : null,
  // @ts-ignore
  term              : null,
  // @ts-ignore
  totalLimit        : null,
  // @ts-ignore
  typeId            : null,
};

const termOptions = _.map([3, 12, 24, 36, 48, 60, 84], value => ({
  value,
  label: `${value} мес`,
}));

const validateForm = (values: IMarketingOffer) => {
  const errors: IErrors = {};
  if (!values.productId) errors.productId = `Обязательно для заполнения`;
  if (!values.totalLimit || values.totalLimit < 1 || values.totalLimit > 1000000) {
    errors.totalLimit = `Введите от 1 до 1 млн`;
  }
  if (!values.term) errors.term = `Обязательно для заполнения`;
  if (!values.rate || values.rate < 1 || values.rate > 200) {
    errors.rate = `Введите корректную ставку. Целое число от 1 до 200`;
  }
  if (!values.payment || values.payment < 1 || values.payment > 1000000) errors.payment = `Введите от 1 до 1 млн`;
  if (!values.startDtm) errors.startDtm = `Обязательно для заполнения`;
  if (!values.closeDtm) errors.closeDtm = `Обязательно для заполнения`;
  if (!values.typeId) errors.typeId = `Выберите тип оффера`;

  return errors;
};

const MarketingOfferCreate: React.FC<IProps> = ({ onClose, personId, visible }) => {
  const dispatch = useDispatch();

  const author = useSelector(getFullName);
  const isLoadingClient = useSelector(getIsLoadingSelector);

  const [values, setValues] = useState(defaultValues);
  const [isLoadingDictionaries, dictionaries] = useDictionaries([`marketingOfferType`, `product`]);

  const setValue = (
    field:string,
    data: ChangeEvent<HTMLInputElement> | Moment | string | number,
  ) => setValues({
    ...values,
    // @ts-ignore
    [field]: !_.isEmpty(data) && data?.target ? (data as ChangeEvent<HTMLInputElement>).target.value : data,
  });

  const handleClose = () => {
    onClose();
  };

  const onSubmit = useCallback(() => {
    dispatch(createMarketingOffer({
      callback: handleClose,
      values  : {
        ...values,
        author,
        closeDtm  : normalizeMomentToStartOfDay(values.closeDtm),
        createDtm : normalizeMomentToStartOfDay(moment()),
        limit     : getNumber(values.limit),
        payment   : getNumber(values.payment),
        personId,
        startDtm  : normalizeMomentToStartOfDay(values.startDtm),
        totalLimit: getNumber(values.totalLimit),
      },
    }));
  }, [values, handleClose, dispatch]);

  const products = useMemo(() => _.map(dictionaries.product, p => ({
    value: p.id,
    label: p.name,
  })), [dictionaries?.product?.length]);

  const marketingOfferTypes = useMemo(() => _.map(
    _.filter(dictionaries.marketingOfferType, { activeFlg: true }),
    p => ({
      value: p.id,
      label: p.name,
    }),
  ), [dictionaries?.marketingOfferType?.length]);

  useEffect(() => {
    setValue(`limit`, values.totalLimit)
  }, [values?.totalLimit]);

  const errors = validateForm(values);
  const isLoading = isLoadingDictionaries || isLoadingClient;

  return (
    <Modal
      cancelText='Отмена'
      centered
      confirmLoading={isLoading}
      okButtonProps={{ disabled: isLoading || !_.isEmpty(errors) }}
      okText='Загрузить оффер'
      onCancel={onClose}
      onOk={onSubmit}
      open={visible}
      title='Маркетинговый оффер'
    >
      <Spin spinning={isLoading}>
        <Form layout='vertical'>
          <Form.Item
            help={errors.productId}
            label='Продукт'
            required
            validateStatus={errors.productId ? `error` : `success`}
          >
            <StyledSelect
              // @ts-ignore
              onChange={(value:number) => setValue(`productId`, value)}
              options={products}
              placeholder='Выберите продукт'
              value={values.productId}
            />
          </Form.Item>
          <Form.Item
            help={errors.totalLimit}
            label='Лимит общий'
            required
            validateStatus={errors.totalLimit ? `error` : `success`}
          >
            <StyledInputNumber
              formatter={inputNumberFormatter}
              min={1}
              // @ts-ignore
              onChange={event => setValue(`totalLimit`, event)}
              parser={inputNumberParser}
              placeholder='От 1 до 1млн ₽'
              precision={0}
              value={values.totalLimit}
            />
          </Form.Item>
          <Form.Item
            help={errors.term}
            label='Срок займа'
            required
            validateStatus={errors.term ? `error` : `success`}
          >
            <StyledSelect
              // @ts-ignore
              onChange={(value:number) => setValue(`term`, value)}
              options={termOptions}
              placeholder='Выберите срок'
              value={values.term}
            />
          </Form.Item>
          <Form.Item
            help={errors.rate}
            label='Ставка'
            required
            validateStatus={errors.rate ? `error` : `success`}
          >
            <StyledInputNumber
              formatter={inputNumberFormatter}
              min={1}
              // @ts-ignore
              onChange={event => setValue(`rate`, event)}
              parser={inputNumberParser}
              placeholder='От 1 до 200 %'
              precision={0}
              value={values.rate}
            />
          </Form.Item>
          <Form.Item
            help={errors.payment}
            label='Платёж'
            required
            validateStatus={errors.payment ? `error` : `success`}
          >
            <StyledInputNumber
              formatter={inputNumberFormatter}
              min={1}
              // @ts-ignore
              onChange={event => setValue(`payment`, event)}
              parser={inputNumberParser}
              placeholder='От 1 до 1млн ₽'
              precision={2}
              value={values.payment}
            />
          </Form.Item>
          <Form.Item
            help={errors.startDtm}
            label='Дата начала оффера'
            required
            validateStatus={errors.startDtm ? `error` : `success`}
          >
            <DatePicker
              onChange={value => setValue(`startDtm`, value)}
              // @ts-ignore
              value={values.startDtm}
            />
          </Form.Item>
          <Form.Item
            help={errors.closeDtm}
            label='Дата завершения оффера'
            required
            validateStatus={errors.closeDtm ? `error` : `success`}
          >
            <DatePicker
              onChange={value => setValue(`closeDtm`, value)}
              // @ts-ignore
              value={values.closeDtm}
            />
          </Form.Item>
          <Form.Item
            help={errors.typeId}
            label='Тип оффера'
            required
            validateStatus={errors.typeId ? `error` : `success`}
          >
            <StyledSelect
              // @ts-ignore
              onChange={(value:number) => setValue(`typeId`, value)}
              options={marketingOfferTypes}
              placeholder='Выберите тип'
              value={values.typeId}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default MarketingOfferCreate;
