import * as React from 'react';
import { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { uploadDocumentTemplateSingle } from 'models/admin/api';

import { InboxOutlined } from '@ant-design/icons';
import {
  Modal,
  notification,
  Upload,
} from 'antd';

const ContentWrapper = styled.div`
  .ant-upload-list-text {
    color: ${palette.textPrimaryColor};
  }

  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: ${palette.black10Color};
  }

  .ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    color: ${palette.textSecondaryColor};
  }

  .ant-upload-list-item-card-actions .anticon {
    color: ${palette.textSecondaryColor};
  }
`;

const StyledUpload = styled(Upload.Dragger)`
  border-color: ${palette.primaryColor} !important;
  border-radius: 8px !important;
`;

const UploadIcon = styled.p`
  color: ${palette.primaryColor};
  font-size: 32px;
`;

const UploadText = styled.p`
  color: ${palette.primaryColor};
`;

const ACCEPT = [
  `application/msword`,
  `.docx`,
];

const ACCEPT_STR = _.join(ACCEPT);

interface IComponentProps {
  isVisible: boolean;
  onClose: () => void;
  onReload: () => void;
  templateId: number;
}

export const DocumentTemplateSingleUploadModal: React.FC<IComponentProps> = ({
  isVisible,
  onClose,
  onReload,
  templateId,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload = async (callback: { (): void; (): void; }) => {
    const notificationKey = `documentTemplateSingleUploadNotification`;

    notification.info({
      key    : notificationKey,
      message: `Загрузка документа...`,
    });

    const defaultError = `При загрузке документа произошла ошибка`;

    try {
      parseResponse({
        defaultError,
        errorPath: `data.message`,
        response : await uploadDocumentTemplateSingle(templateId, selectedFile),
      });
      notification.success({
        key    : notificationKey,
        message: `Шаблон обновлён`,
      });

      if (_.isFunction(callback)) callback();
    } catch (error) {
      showError({ defaultError, error });
    }
  };

  const handleFileChange = (info: { file: any; }) => {
    const { file } = info;
    setSelectedFile(file);
  };

  const handleClose = () => {
    onClose();
    setSelectedFile(null);
  };

  const handleSubmit = () => {
    handleUpload(onReload);
    handleClose();
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      maskClosable={false}
      okButtonProps={{ disabled: !selectedFile }}
      okText='Загрузить'
      onCancel={handleClose}
      onOk={handleSubmit}
      open={isVisible}
      title={`Обновление шаблона ${templateId}`}
    >
      <ContentWrapper>
        <StyledUpload
          accept={ACCEPT_STR}
          beforeUpload={() => false}
          customRequest={_.noop}
          maxCount={1}
          multiple={false}
          onChange={handleFileChange}
        >
          <UploadIcon><InboxOutlined /></UploadIcon>
          <UploadText>Кликните или перетащите сюда шаблон для загрузки</UploadText>
        </StyledUpload>
      </ContentWrapper>
    </Modal>
  );
};
