import { useEffect } from 'react';

export function useClickOutsideHandler(ref: any, onClickOutside: any, exceptionRef?: any) {
  useEffect(() => {
    function handleClickOutside(event: { target: any; }) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (exceptionRef) {
          if (exceptionRef.current && !exceptionRef.current.contains(event.target)) {
            return onClickOutside(event);
          }
        } else {
          return onClickOutside(event);
        }
      }
    }

    document.addEventListener(`mousedown`, handleClickOutside);
    return () => {
      document.removeEventListener(`mousedown`, handleClickOutside);
    };
  }, [ref, onClickOutside]);
}
