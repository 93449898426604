import {
  useEffect,
  useMemo,
  useRef,
} from 'react';
import _ from 'lodash';

export function useEffectDebounced(func: () => void, wait: number) {
  const ref = useRef();

  useEffect(() => {
    // @ts-ignore
    ref.current = func;
  }, [func]);

  return useMemo(
      // @ts-ignore
    () => _.debounce(() => ref.current?.(), wait),
    [wait],
  );
}
