import * as React from 'react';
import {
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled, { css } from 'styled-components';
import { palette } from 'styles/theme';

import {
  copyToClipboard,
  hasIntersection,
} from 'helper';

import { updateClientAddress as updateClientAddressAction } from 'models/clients/actions';
import { getIsLoadingInfo as isLoadingInfoSelector } from 'models/clients/selectors';
import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import {
  CopyOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import CreateModal from 'components/Modal/Create';
import { EntityCardTitle } from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';
import { getAddressTitle } from 'pages/PageClient/Single/Info/Address/helpers';
import { EDIT_ADDRESS_FORM_FIELDS } from 'pages/PageClient/Single/Info/constants';

const Wrapper = styled.div<{ $showApplicationId: boolean }>`
   ${p => p.$showApplicationId && css`
    :not(:last-child) {
      padding-bottom: 7px;
      border-bottom: 1px solid  ${palette.black10Color};
    }
  `}

  :not(:first-child) {
    margin-top: 8px;
  }
`;

const AddressTitle = styled(EntityCardTitle)<{ $showApplicationId?: boolean }>`
  font-weight: 400;
  display: flex;
  justify-content: space-between;

  ${p => !p.$showApplicationId && css`
    margin-bottom: 0;
  `}
`;

const ApplicationId = styled(AddressTitle)`
  color: ${palette.textSecondaryColor};
  margin-bottom: 0;
  justify-content: flex-start;
`;

interface IComponentProps {
  item: {
    applicationDate: string;
    applicationId: string;
    contractNumber?: string;
    fact?: string;
    factId?: string;
    reg?: string;
    regId?: string;
  };
  personId: string;
  showApplicationId?: boolean;
  type: `fact` | `reg`;
}

const ALLOWED_ROLES = [
  ROLE_NAMES.SUPER_ADMIN,
  ROLE_NAMES.CLIENT_EDIT,
];

export const AddressItem: React.FC<IComponentProps> = ({ item, personId, showApplicationId = true, type }) => {
  const dispatch = useDispatch();
  const userRoles = useSelector(getRoleNamesSelector);
  const canEdit = hasIntersection(userRoles, ALLOWED_ROLES);

  const isLoading = useSelector(state => isLoadingInfoSelector(state));

  const update = (data: any) => dispatch(updateClientAddressAction({ data, personId }));
  const [visible, setVisible] = useState(false);

  const addressTitle = useMemo(() => getAddressTitle(type), [type]);
  if (!item?.[type]) return null;
  const itemToEdit = {
    address  : item[type],
    addressId: item[`${type}Id`],
  };

  const addressMeta = `№ ...*${item.applicationId.slice(item.applicationId.length - 4)} от ${item.applicationDate} ${item.contractNumber || `?`}`; // eslint-disable-line max-len

  return (
    <Wrapper $showApplicationId={showApplicationId}>
      <AddressTitle $showApplicationId={showApplicationId}>
        <div>
          <EntityCardTitleControl
            actionTitle='Копировать'
            onClick={() => copyToClipboard(item[type])}
          >
            <CopyOutlined />
          </EntityCardTitleControl>
          {item[type]}
        </div>
        {canEdit && (
          <EntityCardTitleControl
            actionTitle='Редактировать'
            onClick={() => setVisible(true)}
          >
            <EditOutlined />
          </EntityCardTitleControl>
        )}
      </AddressTitle>

      {showApplicationId && (
        <ApplicationId>
          <EntityCardTitleControl
            actionTitle='Копировать'
            onClick={() => copyToClipboard(item.applicationId)}
          >
            <CopyOutlined />
          </EntityCardTitleControl>
          <Tooltip title='applicationId'>{addressMeta}</Tooltip>
        </ApplicationId>
      )}

      {canEdit && (
        <CreateModal
          close={() => setVisible(false)}
          formFields={EDIT_ADDRESS_FORM_FIELDS}
          isLoading={isLoading}
          item={itemToEdit}
          mode='update'
          title={`Редактирование адреса ${addressTitle} клиента по заявке ${addressMeta}`}
          update={update}
          visible={visible}
        />
      )}
    </Wrapper>
  );
};
