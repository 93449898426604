import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { formatDate } from 'helper';

// Стили
const InfoBlockItemsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  padding: 8px;
  background: ${({ theme, isOdd }) => (isOdd ? theme.white100Color : 'transparent')};
`;

const InfoBlockItemsRowLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  width: 400px;
  line-height: 24px;
`;

const InfoBlockItemsRowValue = styled.div`
  flex: 1;
  margin-left: 32px;
`;

const InfoBlockItemsRowSubtitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
  text-decoration: underline;
  text-underline-position: under;
`;

const InfoBlockItemsRowSubtitleContainer = styled(InfoBlockItemsRow)`
  flex-direction: column;
  padding: 16px;
`;

// Компоненты
const InfoRow = ({ format, name, value, isOdd }) => (
  <InfoBlockItemsRow isOdd={isOdd} key={name}>
    <InfoBlockItemsRowLabel>
      {name}
    </InfoBlockItemsRowLabel>
    <InfoBlockItemsRowValue>
      {format === 'boolean' && (
        <Checkbox checked={value} />
      )}
      {format === 'datetime' && formatDate(new Date(value))}
      {!['datetime', 'boolean'].includes(format) && value}
    </InfoBlockItemsRowValue>
  </InfoBlockItemsRow>
);

const Info = ({
                format,
                items,
                name,
                subtitle,
                value,
              }) => (
  subtitle ? (
      _.isEmpty(items) ? (
        <InfoRow
          format={format}
          key={subtitle}
          name={subtitle}
          value='—'
        />
      ) : (
        <InfoBlockItemsRowSubtitleContainer key={name}>
          <InfoBlockItemsRowSubtitle>
            {subtitle}
          </InfoBlockItemsRowSubtitle>
          {_.map(items, ({
                           format: formatInner,
                           items: itemsInner,
                           name: nameInner,
                           subtitle: subtitleInner,
                           value: valueInner,
                         }, index) => (subtitleInner ? (
            <Info
              format={formatInner}
              items={itemsInner}
              key={index}
              name={nameInner}
              subtitle={subtitleInner}
              value={valueInner}
            />
          ) : (
            <InfoRow
              format={formatInner}
              key={index}
              name={nameInner}
              value={valueInner}
              isOdd={index % 2 === 0}
            />
          )))}
        </InfoBlockItemsRowSubtitleContainer>
      )
  ) : (
    <InfoRow
      format={format}
      key={name}
      name={name}
      value={value}
    />
  )
);

export default Info;
