import { useState } from 'react';

export function useSelectedRowKeys() {
  const [selectedRowKeys, onSelectChange] = useState([]);

  return {
    onSelectChange,
    selectedRowKeys,
  };
}
