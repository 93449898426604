import * as React from 'react';
import { useMemo } from 'react';
import { Link } from 'dva/router';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import {
  copyToClipboard,
  formatDate,
  formatDateToNowStrict,
  getFullName,
} from 'helper';

import { useClient } from 'models/clients/hooks';
import { getSegmentColor } from 'models/task/helpers';

import useMobileContext from 'hooks/useMobileContext';
import { useStatePreference } from 'hooks/useStatePreference';

import {
  CalendarOutlined,
  CopyOutlined,
  DownOutlined,
  FieldTimeOutlined,
  ManOutlined,
  PushpinOutlined,
  UpOutlined,
  WomanOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

const Wrap = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: ${({ $isOpen }) => ($isOpen ? `24px` : `0`)};
    padding: 24px 24px 24px 16px;
    border-bottom: solid 1px ${palette.borderDefaultColor};
    flex-wrap: nowrap;
  }
`;

const BlockLeft = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;

  @media (min-width: ${breakpoints.md}) {
    width: auto;
    justify-content: flex-end;
    margin-right: 16px;
    margin-bottom: 0;
  }
`;

const BlockRight = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    width: auto;
  }
`;

const ClientLink = styled(Link)`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-right: 16px;
  color: ${palette.textSecondaryColor};

  @media (min-width: ${breakpoints.md}) {
    font-size: 18px;
    line-height: 22px;
    color: ${palette.textPrimaryColor};
  }
`;

const CopyButton = styled(Button)`
  height: 24px;
  padding: 4px 8px;
  border: 0;
  background-color: ${palette.black10Color};
  color: ${palette.textSecondaryColor};

  .anticon,
  svg {
    display: block;
  }
`;

const StyledButton = styled(CopyButton)`
  margin-left: 16px;
`;

const IconWrap = styled.div<{ $margin?: boolean }>`
  display: flex;
  white-space: nowrap;
  ${({ $margin }) => ($margin ? `margin-left: 24px;` : ``)}
  color: ${palette.textSecondaryColor};
  font-size: 12px;
  line-height: 16px;

  .anticon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: ${palette.black60Color};
    margin-right: 8px;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 14px;
  }
`;

const ColorCircle = styled.div<{ $color: string }>`
  // if no color display none
  display: ${({ $color }) => ($color ? `block` : `none`)};
  background-color: ${({ $color }) => $color};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 16px;
`;

interface IComponentProps {
  isPinned: boolean;
  personId: string;
  segment: string | undefined;
  togglePinnedMode: () => void;
}

export const ClientHeader: React.FC<IComponentProps> = ({ isPinned, personId, segment, togglePinnedMode }) => {
  const [isOpen, setIsOpen] = useStatePreference(
    `ui.collection.isTaskClientInfoOpen`,
    false,
  );

  // @ts-ignore
  const segmentColor = useMemo(() => getSegmentColor(segment), [segment]) as string;

  const [, client] = useClient(personId);
  const clientBirthDateDtm = new Date(client?.birthDate);
  const clientBirthDate = formatDate(clientBirthDateDtm, `dd.MM.yyyy`);
  const clientAge = formatDateToNowStrict(clientBirthDateDtm, { unit: `year`, roundingMethod: `floor` });
  const clientFullName = getFullName(client);
  const clientGender = client?.gender;
  const clientTimeZone = client?.timeZone;
  const { mobile } = useMobileContext();

  return (
    <Wrap $isOpen={isOpen}>
      <BlockLeft>
        <ClientLink to={`/client/person/${personId}/info`}>{clientFullName}</ClientLink>
        <CopyButton icon={<CopyOutlined />} onClick={() => copyToClipboard(clientFullName)} />
        <StyledButton
          icon={isOpen ? (<UpOutlined />) : (<DownOutlined />)}
          onClick={() => setIsOpen(!isOpen)}
        />
        {
          !mobile && (
            <DefaultTooltip title={isPinned ? `Открепить` : `Закрепить`}>
              <StyledButton
                icon={<PushpinOutlined />}
                onClick={() => togglePinnedMode()}
              />
            </DefaultTooltip>
          )
        }
        <DefaultTooltip title={`Сегмент: ${segment}`}>
          <ColorCircle $color={segmentColor} />
        </DefaultTooltip>
      </BlockLeft>
      <BlockRight>
        <IconWrap title='Пол'>
          {clientGender === 1
            ? (<><ManOutlined />М</>)
            : (<><WomanOutlined />Ж</>)}
        </IconWrap>

        <IconWrap $margin title='Дата рождения (возраст)'>
          <CalendarOutlined />
          {`${clientBirthDate} (${clientAge})`}
        </IconWrap>

        {clientTimeZone && (
        <IconWrap $margin title='Таймзона'>
          <FieldTimeOutlined />
          {clientTimeZone >= 0 ? `+${clientTimeZone}` : `-${clientTimeZone}`}
        </IconWrap>
        )}
      </BlockRight>
    </Wrap>
  );
};
