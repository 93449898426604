import { css } from 'styled-components';
import { palette } from 'styles/theme';

export const DefaultModalStyles = css`
  .ant-modal-root {
    .ant-modal {
      .ant-modal-content {
        color: ${palette.textPrimaryColor};

        background-color: ${palette.backgroundColor};

        .ant-modal-close {
          color: ${palette.textSecondaryColor};

          :hover {
            color: ${palette.textPrimaryColor};
          }
        }

        .ant-modal-header {
          background-color: ${palette.backgroundColor};
          border-color: ${palette.borderDefaultColor};

          .ant-modal-title {
            color: ${palette.textPrimaryColor};
          }
        }

        .ant-modal-body {

        }

        .ant-modal-footer {
          border-color: ${palette.borderDefaultColor};
        }
      }
    }
  }
`;
