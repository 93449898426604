import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import {
  ClearOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button as ButtonDefault,
  Input,
  TablePaginationConfig,
} from 'antd';
import {
  FilterValue,
  SorterResult,
} from 'antd/es/table/interface';
import { SubHeader } from 'components/SubHeader/SubHeader';

const Button = styled(ButtonDefault)`
  &+& {
    margin-left: 16px;

    @media (max-width: ${breakpoints.md}) {
      margin-left: 0;
      margin-top: 12px;
    }
  }
`;

const FiltersItem = styled.div`
  display: flex;
  flex: 1;
  max-width: 400px;
  margin-right: 16px;
`;

const Filters = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

`;

const StyledSubheader = styled(SubHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;

    ${Filters} {
      flex-direction: column;
      width: 100%;

      ${FiltersItem} {
        max-width: 100%;
        min-width: 100%;
        margin-right: 0;
        margin-bottom: 12px;

        input {
          min-width: 100%;
        }
      }
    }

    ${Button} {
      min-width: calc(100% - 8px);
      margin-right: 8px;
    }
  }
`;

interface IValues {
  clientFullName: string | null;
  contractNumber: string | null;
}

interface IProps {
  tableState: any;
  onResetFilters(): void;
  onTableChange(
    pagination:TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter:SorterResult<any>
  ): void;
}

export const BankruptSubHeader: React.FC<IProps> = ({ onResetFilters, onTableChange, tableState }) => {
  const [values, setValues] = useState<IValues>({
    clientFullName: tableState?.filters?.clientFullName?.[0],
    contractNumber: tableState?.filters?.contractNumber?.[0],
  });
  useEffect(() => {
    setValues({
      clientFullName: tableState?.filters?.clientFullName?.[0],
      contractNumber: tableState?.filters?.contractNumber?.[0],
    })
  }, [tableState?.filters?.clientFullName?.[0], tableState?.filters?.contractNumber?.[0]]);

  const setValue = (value: string, key:string) => {
    setValues(_values => ({
      ..._values,
      [key]: value ? [value] : null,
    }));
  };

  const setFilters = () => {
    onTableChange(
      tableState.pagination,
      {
        ...tableState.filters,
        clientFullName: values.clientFullName ? _.castArray(values.clientFullName) : null,
        contractNumber: values.contractNumber ? _.castArray(values.contractNumber) : null,
      },
      tableState.sorter,
    );
  };

  return (
    <StyledSubheader>
      <Filters>
        <FiltersItem>
          <Input
            onChange={e => setValue(e.target.value, `clientFullName`)}
            onPressEnter={setFilters}
            placeholder='ФИО'
            // @ts-ignore
            value={values.clientFullName}
          />
        </FiltersItem>
        <FiltersItem>
          <Input
            onChange={e => setValue(e.target.value, `contractNumber`)}
            onPressEnter={setFilters}
            placeholder='Номер договора'
            // @ts-ignore
            value={values.contractNumber}
          />
        </FiltersItem>
      </Filters>
      <Button icon={<ClearOutlined />} onClick={onResetFilters}>Сбросить фильтры</Button>
      <Button icon={<SearchOutlined />} onClick={setFilters} type='primary'>Поиск</Button>
    </StyledSubheader>
  );
};
