import * as React from 'react';
import { useState } from 'react';

import { SmileOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { InputEmojiPicker } from 'components/Messenger/Input/InputEmojiPicker';
import { InputIconButton } from 'components/Messenger/Input/InputIconButton';

interface IComponentProps {
  disabled?: boolean;
  onClose?: () => void;
  onSelect: (emoji: string) => void;
}

export const InputEmojiPickerButton: React.FC<IComponentProps> = ({ disabled, onClose, onSelect }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => {
    setIsVisible(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <Popover
      arrowPointAtCenter
      content={(
        <InputEmojiPicker
          onClose={handleClose}
          onSelect={onSelect}
        />
      )}
      onOpenChange={setIsVisible}
      open={isVisible}
      overlayInnerStyle={{
        borderRadius: `12px`,
      }}
      overlayStyle={{
        borderRadius: `12px`,
        maxWidth    : `100vw`,
        width       : `352px`,
      }}
      placement='topRight'
      trigger='click'
    >
      <InputIconButton disabled={disabled}>
        <SmileOutlined />
      </InputIconButton>
    </Popover>
  );
};
