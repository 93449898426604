import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
} from 'styles/theme';

import { Button as ButtonDefault } from 'antd';

export const Block = styled.div`
  margin-bottom: 24px;
`;

export const BlockTitle = styled.div`
  flex: auto;
  overflow: hidden;
  color: ${palette.textSecondaryColor};
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  border-bottom: 1px solid ${palette.borderDefaultColor};
`;

export const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
  padding: 8px;

  &:nth-child(odd) {
    background: ${getLightStyleOr(palette.white100Color, palette.black10Color)};
  }

  @media(min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`;

export const RowLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  max-width: 100%;
  font-weight: 600;
  width: 400px;
  line-height: 24px;

  @media(min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }
`;

export const RowValue = styled.div`
  flex: 1;
  margin-left: 0;

  a {
    color: ${palette.lightBlueColor};
    text-decoration: underline;
  }

  span {
    a {
      margin-left: 24px;
    }
  }

  @media(min-width: ${breakpoints.md}) {
    margin-left: 32px;
  }
`;

export const DocumentButton = styled(ButtonDefault)`
  max-width: 180px;
`;

export const DocumentControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 8px;

  ${DocumentButton} {
    margin-left: 8px;
  }
`;

export const DocumentLabel = styled.div`
  font-weight: 600;
  max-width: 500px;
  flex-grow: 1;
`;

export const DocumentRow = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: 16px;
  align-items: center;
  margin-top: 8px;

  @media (max-width: 590px) {
    flex-direction: column;
    align-items: flex-start;

    border-radius: 0;
    background: ${palette.backgroundColor} !important;
    &+& {
      border-top: 1px solid ${getLightStyleOr(palette.white100Color, palette.black10Color)};
    }

    ${DocumentLabel} {
      margin-bottom: 16px;
    }

    ${DocumentControls} {
      margin-left: -8px;
    }
  }

  &:first-child {
    margin-top: -8px;
  }

  &:nth-child(even) {
    background: ${getLightStyleOr(palette.white100Color, palette.black10Color)};
  }
`;
