import React, {
  useEffect,
  useRef,
} from 'react';

import {
  ClearOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Input,
  Space,
} from 'antd';

const StringFilterDropDown = ({
  confirm,
  isVisible,
  placeholder = ``,
  selectedKeys,
  setSelectedKeys,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => ref.current.focus(), 100);
    }
  }, [isVisible]);

  return (
    <div style={{ padding: 8 }}>
      <Input
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        placeholder={placeholder}
        ref={ref}
        style={{ marginBottom: 8, display: `block` }}
        value={selectedKeys[0]}
      />
      <Space>
        <Button
          icon={<SearchOutlined />}
          onClick={() => confirm()}
          size='small'
          style={{ width: 90 }}
          type='primary'
        />
        <Button
          icon={<ClearOutlined />}
          onClick={() => {
            setSelectedKeys([]);
            confirm();
          }}
          size='small'
          style={{ width: 90 }}
        />
      </Space>
    </div>
  );
};

export default StringFilterDropDown;
