import * as React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import {
  copyToClipboard,
  getShortened,
} from 'helper';

import { CopyOutlined } from '@ant-design/icons';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

const Wrapper = styled.div`
  background: ${palette.black10Color};
  border: 1px solid ${palette.borderAccentColor};
  border-radius: 16px;

  display: flex;
  align-items: center;

  cursor: pointer;
  color: ${palette.textSecondaryColor};

  font-size: 12px;
  line-height: 14px;
  font-weight: 400;

  padding: 4px 8px;

  transition: 100ms ease;

  .anticon:first-child {
    font-size: 14px;
    margin-left: 4px;
    color: ${palette.textSecondaryColor};
  }

  :hover {
    background: ${palette.black30Color};
  }

  :active {
    background: ${palette.black30Color};
  }

  @media(min-width: ${breakpoints.md}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

interface IComponentProps {
  className?: string;
  description: string;
  id: string;
}

export const IdTagButton: React.FC<IComponentProps> = ({ className, description, id }) => {
  const idShort = getShortened(id);

  const title = description ? `${description}: ${id}` : id;

  return (
    <DefaultTooltip title={title}>
      <Wrapper className={className} onClick={() => copyToClipboard(id)}>
        {idShort}
        <CopyOutlined />
      </Wrapper>
    </DefaultTooltip>
  );
};
