import * as React from 'react';
import _ from 'lodash';

import {
  INSURANCE_STATUSES_COLORS,
  INSURANCE_STATUSES_NAMES,
} from 'constants/INSURANCE_STATUSES';
import {
  fallBackTableValue,
  formatDate,
  getShortened,
  prettifyNumber,
} from 'helper';

import { LOAN_STATUSES_COLORS } from 'models/clients/constants';

import { Popover } from 'antd';
import { CopyIconButton } from 'components/CopyIconButton/CopyIconButton';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import { IRow } from 'pages/PageClient/Single/components/EntityDetails/EntityDetails';

import { parseAssigneeDescription } from '../helpers';

export interface IAssignee {
  description: string;
  id: number;
  name: string;
}

export const ROWS: ((assignees: IAssignee[]) => IRow[]) = assignees => [
  {
    key  : `contractNumber`,
    title: `№ договора`,
  },
  {
    key   : `id`,
    title : `ID`,
    render: value => getShortened(value),
  },
  {
    key        : `loanStatusId`,
    title      : `Статус`,
    valueToCopy: false,
    render     : (value, record) => {
      let color = LOAN_STATUSES_COLORS[value] || `default`;

      if (record?.assignmentDtm) {
        const assigneeDescription = _.find(assignees, { id: record?.assigneeId })?.description;
        color = `yellow`;
        return (
          <>
            <Popover
              content={parseAssigneeDescription(assigneeDescription)}
              overlayStyle={{ maxWidth: `400px` }}
              trigger='click'
            >
              <DefaultTag color={color}>
                Продан
              </DefaultTag>
            </Popover>
            <CopyIconButton
              as='span'
              // @ts-ignore
              valueToCopy={assigneeDescription}
            />
          </>
        );
      }

      return (
        <DefaultTag color={color}>
          {_.upperFirst(record?.loanStatusName)}
        </DefaultTag>
      );
    },
  },
  {
    key        : `productName`,
    title      : `Продукт`,
    render     : fallBackTableValue(`productId`),
    valueToCopy: false,
  },
  {
    dataIndex  : [`details`, `loanAmount`],
    key        : `loanAmount`,
    render     : prettifyNumber,
    title      : `Сумма`,
    valueToCopy: false,
  },
  {
    dataIndex  : [`details`, `interestRateStr`],
    key        : `interestRateStr`,
    title      : `Ставка`,
    valueToCopy: false,
  },
  {
    dataIndex  : [`details`, `initialLoanTerm`],
    key        : `initialLoanTerm`,
    title      : `Срок`,
    render     : initialLoanTerm => <>{initialLoanTerm ? `${initialLoanTerm} мес` : ``} </>,
    valueToCopy: false,
  },
  {
    dataIndex: [`insurance`, `insuranceStatusId`],
    key      : `insuranceStatusId`,
    title    : `Страховка`,
    render   : insuranceStatusId => (
      INSURANCE_STATUSES_NAMES[insuranceStatusId]
        ? (
          <DefaultTag color={INSURANCE_STATUSES_COLORS[insuranceStatusId] || `default`}>
            {_.upperFirst(INSURANCE_STATUSES_NAMES[insuranceStatusId]) || `-`}
          </DefaultTag>
        ) : `-`
    ),
    valueToCopy: false,
  },
  {
    key   : `contractConditionName`,
    title : `Состояние договора`,
    render: text => (
      text ? (
        <DefaultTag color='default'>
          {text}
        </DefaultTag>
      ) : `-`
    ),
    valueToCopy: false,
  },
  {
    key   : `closeSource`,
    title : `Источник закрытия`,
    render: text => text || `-`,
  },
  {
    key        : `creditStartDtm`,
    title      : `Начало кредита`,
    render     : text => formatDate(text, `dd.MM.yyyy`),
    valueToCopy: false,
  },
  {
    key        : `creditEndDtm`,
    title      : `Конец кредита`,
    render     : text => formatDate(text, `dd.MM.yyyy`),
    valueToCopy: false,
  },
];
