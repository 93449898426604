import React from 'react';
import _ from 'lodash';

import { CheckDestinationsResponse } from 'models/admin/types';

import {
  GreenText,
  RedText,
  StyledLi,
} from 'components/Modal/SendSmsBatch/styled';

interface ICheckPhonesMessage {
  checkDestinationsResponse: CheckDestinationsResponse;
}

export const CheckPhonesMessage: React.FC<ICheckPhonesMessage> = ({ checkDestinationsResponse }) => (
  <div>
    <div>Пройдена валидация формата файла на уровне CRM</div>
    <br />
    <div><b>Результат валидации наличия телефонов:</b></div>
    <GreenText>Будет принято в работу: {checkDestinationsResponse?.successCount} записи</GreenText>
    <RedText>
      Не будет принято в работу: {checkDestinationsResponse?.declined?.noPhonesPersons?.length} записи
    </RedText>
    <br />
    <div><b>Нет телефонов у следующих клиентов:</b></div>
    <div>(кликните для перехода к клиенту)</div>
    <ul>
      {_.map(checkDestinationsResponse?.declined?.noPhonesPersons, personId => (
        <StyledLi key={personId} onClick={() => window.open(`/client/person/${personId}/info`, `_blank`)}>
          {personId}
        </StyledLi>
      ))}
    </ul>
  </div>
);
