import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment, { Moment } from 'moment/moment';
import { transparentize } from 'polished';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { hasIntersection } from 'helper';

import {
  getItems as getDictionarySelector,
  getItemsForSelect as getDictionaryItemsForSelectSelector,
} from 'models/dictionaries/selectors';
import { get as getRolesAction } from 'models/roles/actions';
import { ROLE_NAMES } from 'models/roles/constants';
import { update as updateAction } from 'models/task/actions';
import {
  CAMPAIGN_NAMES,
  SHOW_SEARCH_PARTNER_CAMPAIGNS,
} from 'models/task/constants';
import { getItem as getTaskSelector } from 'models/task/selectors';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Input,
  Select,
  Tooltip,
} from 'antd';
import { CreateTaskModal } from 'pages/PageTask/Single/components/CreateTaskModal/CreateTaskModal';
import { SelectTaskOperator } from 'pages/PageTask/Single/components/SelectTaskOperator';
import { SelectTaskSearchPartner } from 'pages/PageTask/Single/components/SelectTaskSearchPartner';
import { SelectTaskStatus } from 'pages/PageTask/Single/components/SelectTaskStatus';

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  padding: 32px;
  z-index: 2;
  color: ${palette.whiteColor};
  position: relative;

  @media(max-width: 851px) {
    padding: 32px 16px;
  }

  @media(max-width: 800px) {
    padding: 32px 8px;
  }

  @media(max-width: 785px) {
    padding: 32px 4px;
  }

  @media(max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    padding: 16px;
    background-color: ${palette.backgroundAccentColor};
  }
`;

const BackButton = styled(Button)`
  background-color: transparent;
  color: ${palette.whiteColor};
  margin-right: 16px;

  @media (max-width: 995px) {
    margin-right: 0;
  }
`;

const BackLabel = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;

  &:before {
    content: '';
    border-left: 1px solid ${palette.whiteColor};
    margin-right: 16px;
  }

  @media (max-width: 995px) {
    display: none;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const Middle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-right: 16px;

  .ant-dropdown-trigger {
    flex: 1;
    white-space: nowrap;
    display: flex;
    align-items: center;
    min-width: 200px;
    width: calc(100% / 3 - 48px);
    color: ${palette.blackColor};

    .anticon {
      color: ${palette.primaryColor};
    }
  }

  @media(max-width: ${breakpoints.md}) {
    order: 3;
    width: 100%;
    margin-top: 10px;
    margin-right: 0;

    .ant-dropdown-trigger {
      min-width: unset;
    }
  }
`;

const MiddleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
  gap: 8px;
  margin-top: 8px;

  @media(max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;

const CustomInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-width: 340px;
  min-height: 36px;
  position: relative;
  border-radius: 8px;
  background: ${palette.whiteColor};

  @media(max-width: 851px) {
    min-width: 280px;
  }

  @media(max-width: ${breakpoints.md}) {
    min-width: 100%;

    &+& {
      margin-left: 0;
    }
  }
`;

const Label = styled.div`
  margin-left: 8px;
  color: ${palette.blackColor};
  font-size: 14px;
  padding-left: 4px;

  @media(max-width: ${breakpoints.md}) {
    flex: 1;
    padding-top: 4px;
    padding-left: 12px;
    margin-left: 0;
    margin-bottom: 8px;
  }
`;

const InputStyled = styled(Input)`
  border: none;

  @media(max-width: ${breakpoints.md}) {
    width: 100%;
    flex: 1;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  border: none;
  flex: 1;
  @media(max-width: ${breakpoints.md}) {
    min-height: 100%;
    min-width: 150px;
  }
`;

const SaveIcon = styled(SaveOutlined)<{ $isDisabled?: boolean, $isPadded?: boolean }>`
  font-size: 14px;
  color: ${palette.primaryColor};
  cursor: pointer;
  ${p => p.$isDisabled && `color: ${palette.black80Color(p)};`}
  ${p => p.$isPadded && `padding-right: 12px;`}
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 100%;

  @media(max-width: ${breakpoints.md}) {
    order: 2;
    flex-direction: row-reverse;
  }
`;

const RightControlLabel = styled.div`
  margin-right: 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  transition: color 0.1s ease;

  @media(max-width: 1101px) {
    display: none;
  }
`;

const RightControl = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &+& {
    margin-top: 8px;
  }

  :hover {
    ${RightControlLabel} {
      color: ${p => transparentize(0.2, palette.whiteColor(p))};
    }
  }

  @media(max-width: ${breakpoints.md}) {
    &+& {
      margin-top: 0;
      margin-right: 8px;
    }
  }
`;

const RightControlButton = styled(Button)`
  background-color: transparent;
  color: ${palette.whiteColor};
  min-width: 32px;

  @media(max-width: ${breakpoints.md}) {
    margin-right: 0;
  }
`;

const StyledSelect = styled(Select)`
  border: none;
  flex: 1;
  margin: 0 8px;

  @media(max-width: ${breakpoints.md}) {
    min-height: 100%;
    min-width: 150px;
  }

  .ant-select-selector {
    border: none !important;
  }
`;

interface IProps {
  canEditTask: boolean;
  personId: string;
  taskId: number;
  goBack(): void;
}

const TopControls: React.FC<IProps> = ({ canEditTask, goBack, personId, taskId }) => {
  const dispatch = useDispatch();

  const getRoles = useCallback(() => dispatch(getRolesAction()), [dispatch]);

  const task = useSelector(state => getTaskSelector(state, taskId) || {});

  const campaigns = useSelector(state => _.orderBy(getDictionarySelector(state, `campaign`), `id`, `desc`));
  const campaignTypes = useSelector(state => _.orderBy(getDictionarySelector(state, `campaignType`), `id`, `desc`));
  const hmParkingOptions = useSelector(state => _.orderBy(
    getDictionaryItemsForSelectSelector(state, `hmParking`, (items: any) => _.map(items, item => ({
      label: item?.name,
      value: item?.id,
    }))),
    `label`,
    `desc`,
  ));

  const campaignType = _.find(campaignTypes, { id: task.campaignTypeId });
  const campaign = _.find(campaigns, { id: campaignType?.campaignId });

  const userRoles = useSelector(getRoleNamesSelector);

  const canCreateTask = hasIntersection(userRoles, [ROLE_NAMES.SUPER_ADMIN, ROLE_NAMES.TASK_CREATOR]);
  const isShowSearchPartner = _.includes(SHOW_SEARCH_PARTNER_CAMPAIGNS, campaign?.name);

  useEffect(() => {
    getRoles();
  }, []);

  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
  const toggleCreateTaskModal = useCallback(() => setIsCreateModalVisible(i => !i), []);
  const [nextStep, setNextStep] = useState<string | null>(task.nextStep);
  const [nextStepDate, setNextStepDate] = useState<Moment | null>(task.nextStepDate
    ? moment(task.nextStepDate)
    : null);
  const [imhaDateIn, setImhaDateIn] = useState<Moment | null>(task.imhaDateIn
    ? moment(task.imhaDateIn)
    : null);
  const [imhaDateOut, setImhaDateOut] = useState<Moment | null>(task.imhaDateOut
    ? moment(task.imhaDateOut)
    : null);
  const [hmParkingId, setHmParkingId] = useState<number | null>(task.hmParkingId || null);

  const updateTaskFields = useCallback((fieldKey:string) => {
    dispatch(updateAction({
      taskId,
      item: {
        hmParkingId : hmParkingId || null,
        imhaDateIn  : imhaDateIn ? imhaDateIn.toDate() : null,
        imhaDateOut : imhaDateOut ? imhaDateOut.toDate() : null,
        nextStep    : nextStep || null,
        nextStepDate: nextStepDate ? nextStepDate.toDate() : null,
        ..._.pick(
          task,
          _.without([
            `nextStep`,
            `nextStepDate`,
            `hmParkingId`,
            `imhaDateIn`,
            `imhaDateOut`,
          ], fieldKey),
        ),
      },
    }));
  }, [dispatch, taskId, nextStep, nextStepDate, hmParkingId, imhaDateIn, imhaDateOut]);

  const isNextStepEq = _.isEqual(nextStep || ``, task.nextStep || ``);
  const isNextStepDateEq = _.isEqual(
    nextStepDate ? nextStepDate.valueOf() : null,
    +new Date(task.nextStepDate) || null,
  );
  const isHmParkingIdEq = _.isEqual(hmParkingId || null, task.hmParkingId || null);
  const isImhaDateInEq = _.isEqual(imhaDateIn ? imhaDateIn.valueOf() : null, +new Date(task.imhaDateIn) || null);
  const isImhaDateOutEq = _.isEqual(imhaDateOut ? imhaDateOut.valueOf() : null, +new Date(task.imhaDateOut) || null);

  return (
    <Wrapper>

      <Left>
        <BackButton
          icon={<ArrowLeftOutlined />}
          onClick={goBack}
        />
        <BackLabel>{`Задача №${taskId}`}</BackLabel>
      </Left>

      <Middle>
        <MiddleRow>
          <SelectTaskStatus
            campaign={campaign}
            canEditTask={canEditTask}
            task={task}
            taskId={taskId}
          />

          <SelectTaskOperator
            campaign={campaign}
            canEditTask={canEditTask}
            task={task}
            taskId={taskId}
          />

          {isShowSearchPartner && (
            <SelectTaskSearchPartner
              canEditTask={canEditTask}
              task={task}
              taskId={taskId}
            />
          )}
        </MiddleRow>

        {campaign?.name !== CAMPAIGN_NAMES.IMHA && (
          <MiddleRow>
            <CustomInput>
              <InputStyled
                allowClear
                onChange={e => setNextStep(e.target.value)}
                placeholder='Укажите'
                prefix='Цель:'
                suffix={(canEditTask && !isNextStepEq) ? (
                  <Tooltip placement='left' title='Сохранить цель'>
                    <SaveIcon onClick={() => updateTaskFields(`nextStep`)} />
                  </Tooltip>
                ) : undefined}
                // @ts-ignore
                value={nextStep}
              />
            </CustomInput>
            <CustomInput>
              <Label>Дата цели:</Label>
              {/* @ts-ignore */}
              <DatePickerStyled format='DD.MM.YYYY' onChange={setNextStepDate} value={nextStepDate} />
              {(canEditTask && !isNextStepDateEq) && (
                <Tooltip placement='left' title='Сохранить дату цели'>
                  <SaveIcon $isPadded onClick={() => updateTaskFields(`nextStepDate`)} />
                </Tooltip>
              )}
            </CustomInput>
          </MiddleRow>
        )}
        {campaign?.name === CAMPAIGN_NAMES.IMHA && (
          <MiddleRow>
            <CustomInput>
              <Label>Дата ИМХА:</Label>
              {/* @ts-ignore */}
              <DatePickerStyled format='DD.MM.YYYY' onChange={setImhaDateIn} value={imhaDateIn} />
              {(canEditTask && !isImhaDateInEq) && (
                <Tooltip placement='left' title='Сохранить дату ИМХА'>
                  <SaveIcon $isPadded onClick={() => updateTaskFields(`imhaDateIn`)} />
                </Tooltip>
              )}
            </CustomInput>
            <CustomInput>
              <Label>Дата возврата ТС:</Label>
              {/* @ts-ignore */}
              <DatePickerStyled format='DD.MM.YYYY' onChange={setImhaDateOut} value={imhaDateOut} />
              {(canEditTask && !isImhaDateOutEq) && (
                <Tooltip placement='left' title='Сохранить дату возврата ТС'>
                  <SaveIcon $isPadded onClick={() => updateTaskFields(`imhaDateOut`)} />
                </Tooltip>
              )}
            </CustomInput>
          </MiddleRow>
        )}
        {campaign?.name === CAMPAIGN_NAMES.IMHA && (
          <MiddleRow>
            <CustomInput>
              <Label>Стоянка:</Label>
              <StyledSelect
                disabled={!canEditTask}
                // @ts-ignore
                onSelect={setHmParkingId}
                optionFilterProp='label'
                options={hmParkingOptions}
                placeholder='Выберите'
                style={{ width: `100%` }}
                value={hmParkingId}
              />
              {(canEditTask && !isHmParkingIdEq) && (
                <Tooltip placement='left' title='Сохранить стоянку'>
                  <SaveIcon $isPadded onClick={() => updateTaskFields(`hmParkingId`)} />
                </Tooltip>
              )}
            </CustomInput>
          </MiddleRow>
        )}
      </Middle>

      <Right>
        <RightControl onClick={goBack}>
          <RightControlLabel>Закрыть</RightControlLabel>
          <Tooltip placement='left' title='Закрыть'>
            <RightControlButton
              icon={<CloseOutlined />}
            />
          </Tooltip>
        </RightControl>

        {canCreateTask && (
          <RightControl onClick={toggleCreateTaskModal}>
            <RightControlLabel>Создать задачу</RightControlLabel>
            <Tooltip placement='left' title='Создать задачу'>
              <RightControlButton
                icon={<PlusOutlined />}
              />
            </Tooltip>
          </RightControl>
        )}
      </Right>

      <CreateTaskModal
        close={toggleCreateTaskModal}
        entity='collection'
        personId={personId}
        visible={isCreateModalVisible}
      />
    </Wrapper>
  );
};
export default TopControls;
