import React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { getItems as getRoleGroupsSelector } from 'models/roleGroups/selectors';
import { group as groupRolesAction } from 'models/roles/actions';
import { getItems as getRolesSelector } from 'models/roles/selectors';

import {
  Form,
  Modal,
  Select,
} from 'antd';

const GroupRoleModal = ({ close, ids, roleGroupId, visible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const roles = useSelector(getRolesSelector);
  const roleGroups = useSelector(getRoleGroupsSelector);

  const groupRoles = data => dispatch(groupRolesAction(data));

  return (
    <Modal
      cancelText='Отмена'
      centered
      maskClosable={false}
      okText='Группировать'
      onCancel={() => {
        form.resetFields();
        close();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            groupRoles(_.pick(values, [`ids`, `roleGroupId`]));
            form.resetFields();
            if (!values.createAnother) {
              close();
            } else {
              form.setFieldsValue({ createAnother: true });
            }
          });
      }}
      open={visible}
      title='Группировка ролей'
    >
      <Form
        form={form}
        initialValues={{ ids, roleGroupId }}
        layout='vertical'
      >
        <Form.Item
          label='Роли'
          name='ids'
          rules={[
            {
              message : `Необходимо выбрать роли`,
              required: true,
            },
            {
              validator: (pass, value) => {
                if (!_.isEmpty(value)) return Promise.resolve();
                return Promise.reject(new Error(`Необходимо выбрать роли`));
              },
            },
          ]}
        >
          <Select mode='multiple'>
            {_.map(roles, ({ id, name }) => (
              <Select.Option key={`role${id}`} value={id}>{name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Группа ролей'
          name='roleGroupId'
          rules={[{
            message : `Необходимо выбрать группу ролей`,
            required: true,
          }]}
        >
          <Select>
            {_.map(roleGroups, ({ id, name }) => (
              <Select.Option key={id} value={id}>{name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GroupRoleModal;
