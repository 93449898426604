import * as React from 'react';
import { ReactNode } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { getFileSize } from 'helper';

import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { IInputAttachment } from 'components/Messenger/types';

const Action = styled.div<{ type: `danger` | `success` }>`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -4px;
  right: -4px;
  border-radius: 40px;
  border: 1px solid ${palette.borderAccentColor};
  background: ${palette.backgroundColor};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;

  :hover {
    ${p => p.type === `danger` && `
      border: 1px solid ${palette.alert500Color(p)};
    `}

    ${p => p.type === `success` && `
      border: 1px solid ${palette.primary500Color(p)};
    `}
  }
`;

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  background: ${palette.backgroundColor};
  border-radius: 8px;
  border: 1px solid ${palette.borderDefaultColor};
  padding: 12px 16px;

  height: 64px;
  width: 148px;

  transition: all 200ms ease;
  margin-right: 8px;

  :hover {
    border: 1px solid ${palette.borderAccentColor};
    background: ${palette.black10Color};

    ${Action} {
      opacity: 1;
    }
  }

  ${Action} {
    transition: all 100ms ease;
    opacity: 1;

    @media(min-width: ${breakpoints.md}) {
      opacity: 0;
    }
  }
`;

const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${palette.textSecondaryColor};
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 8px;
`;

const FileInfoAdditional = styled.div`
  color: ${palette.black60Color};
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
`;

const Extension = styled.span`
  text-transform: uppercase;
`;

const IconWrapper = styled.div<{ type: `danger` | `success` }>`
  .anticon {
    font-size: 16px;

    ${p => p.type === `danger` && `
      color: ${palette.alert500Color(p)};
    `}

    ${p => p.type === `success` && `
      color: ${palette.primary500Color(p)};
    `}
  }
`;

interface IComponentProps {
  action?: {
    icon: ReactNode;
    // @ts-ignore
    onClick: (event) => void;
    title: string;
    type: `danger` | `success`;
  }
  file: IInputAttachment;
    // @ts-ignore
  onClick?: (event) => void;
}

export const AttachmentFileThumbnail: React.FC<IComponentProps> = ({ action, file, onClick }) => {
  const title = file.name || ``;
  const fileSize = getFileSize(file?.size);
  const extension = _.last(_.split(file?.name || ``, `.`));

  return (
    <Wrapper onClick={onClick}>
      <FileInfo>
        <DefaultTooltip title={title}>
          <Title>{title}</Title>
        </DefaultTooltip>

        <FileInfoAdditional>
          <Extension>
            {extension}
          </Extension>
          {` `}
          ({fileSize})
        </FileInfoAdditional>
      </FileInfo>

      {action && (
        <Action onClick={action.onClick} type={action.type}>
          <DefaultTooltip title={action.title}>
            <IconWrapper type={action.type}>
              {action.icon}
            </IconWrapper>
          </DefaultTooltip>
        </Action>
      )}
    </Wrapper>
  );
};
