export const HEIC_HEIF = [
  `.heif`,
  `image/heif`,
  `.heic`,
  `image/heic`,
];

export const ACCEPT_COMMON_IMAGES = [
  ...HEIC_HEIF,
  `.jpg`,
  `.jpeg`,
  `image/jpeg`,
  `.png`,
  `image/png`,
];

export const ACCEPT_IMAGES = [
  ...ACCEPT_COMMON_IMAGES,
  `.gif`,
  `image/gif`,
  `.svg`,
  `image/svg+xml`,
  `.tif`,
  `.tiff`,
  `image/tiff`,
  `.webp`,
  `image/webp`,
];

export const ACCEPT = [
  ...ACCEPT_IMAGES,
  // PDF
  `.pdf`,
  `application/pdf`,
  // text
  `.txt`,
  `text/plain`,
  // MS Word
  `.doc`,
  `.dot`,
  `application/msword`,
  `.docx`,
  `application/vnd.openxmlformats-officedocument.wordprocessingml.document`,
  `.dotx`,
  `application/vnd.openxmlformats-officedocument.wordprocessingml.template`,
  `.docm`,
  `application/vnd.ms-word.document.macroEnabled.12`,
  `.dotm`,
  `application/vnd.ms-word.template.macroEnabled.12`,
  `.rtf`,
  `application/rtf`,
  `.xml`,
  `application/xml`,
  `text/xml`,
  // MS Excel
  `.xls`,
  `.xlt`,
  `.xla`,
  `application/vnd.ms-excel`,
  `.xlsx`,
  `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
  `.xlst`,
  `application/vnd.openxmlformats-officedocument.spreadsheetml.template`,
  `.xlsm`,
  `application/vnd.ms-excel.sheet.macroEnabled.12`,
  `.xltm`,
  `application/vnd.ms-excel.template.macroEnabled.12`,
  `.xlam`,
  `application/vnd.ms-excel.addin.macroEnabled.12`,
  `.xlsb`,
  `application/vnd.ms-excel.sheet.binary.macroEnabled.12`,
  // PowerPoint
  `.ppt`,
  `.pot`,
  `.pps`,
  `.ppa`,
  `application/vnd.ms-powerpoint`,
  `.pptx`,
  `application/vnd.openxmlformats-officedocument.presentationml.presentation`,
  `.potx`,
  `application/vnd.openxmlformats-officedocument.presentationml.template`,
  `.ppsx`,
  `application/vnd.openxmlformats-officedocument.presentationml.slideshow`,
  `.ppam`,
  `application/vnd.ms-powerpoint.addin.macroEnabled.12`,
  `.pptm`,
  `application/vnd.ms-powerpoint.presentation.macroEnabled.12`,
  `.potm`,
  `application/vnd.ms-powerpoint.template.macroEnabled.12`,
  `.ppsm`,
  `application/vnd.ms-powerpoint.slideshow.macroEnabled.12`,
  // OpenDocument
  `.odt`,
  `application/vnd.oasis.opendocument.text`,
  `.ods`,
  `application/vnd.oasis.opendocument.spreadsheet`,
  // iWork
  `.numbers`,
  `application/vnd.apple.numbers`,
  `.pages`,
  `application/vnd.apple.pages`,
  `.keynote`,
  `application/vnd.apple.keynote`,
  // archives
  `.7z`,
  `application/x-7z-compressed`,
  `.gz`,
  `application/gzip`,
  `.zip`,
  `application/zip`,
  `.tar`,
  `application/x-tar`,
  `.rar`,
  `application/vnd.rar`,
];

export const LONG_MESSAGE_TRESHOLD = 500;
