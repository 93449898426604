import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { getColumn } from 'helper';

import { REPAYMENT_STATUSES } from 'models/clients/constants';

import { CellDateTime } from 'components/DefaultTable/CellDateTime/CellDateTime';
import { DefaultTable } from 'components/DefaultTable/DefaultTable';

const StyledTable = styled(DefaultTable)`
  max-width: 250px;
`;

interface IRepaymentLink {
  createDtm: number;
  statusId: number;
}

interface IComponentProps {
  repaymentLinks: IRepaymentLink[];
}

const RepaymentLinkList: React.FC<IComponentProps> = ({ repaymentLinks }) => (
  <StyledTable
    bordered
    // @ts-ignore
    columns={_.map([
      {
        key   : `createDtm`,
        title : `Дата`,
        render: (v: number | undefined) => <CellDateTime value={v} />,
      },
      {
        key   : `statusId`,
        align : `right`,
        title : `Статус`,
        // @ts-ignore
        render: (v: string | number) => REPAYMENT_STATUSES[v] || v,
      },
    ], getColumn)}
    dataSource={repaymentLinks}
    pagination={{
      hideOnSinglePage: true,
      pageSize        : _.size(repaymentLinks),
      size            : `small`,
    }}
    rowKey='id'
    size='small'
  />
);

export default RepaymentLinkList;
