import React, {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment from 'moment';
import qs from 'query-string';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { get as getDictionaryAction } from 'models/dictionaries/actions';
import { getItems as getDictionary } from 'models/dictionaries/selectors';
import { get as getOperatorsAction } from 'models/operators/actions';
import { getItems as getOperatorsSelector } from 'models/operators/selectors';
import {
  getCurrent as getCurrentUserSelector,
  getRoleNames as getRoleNamesSelector,
} from 'models/user/selectors';
import {
  assign as assignAction,
  get as getVerificationsAction,
  reassign as reassignAction,
  set as setAction,
  setStatus as setStatusAction,
  updateUserData as updateUserDataAction,
} from 'models/verifications/actions';
import { SALE_STATUS_NAMES } from 'models/verifications/constants';
import {
  getItems as getVerificationsSelector,
  getTotal as getTotalSelector,
  isItemsLoading as isItemsLoadingSelector,
  isLoading as isLoadingSelector,
} from 'models/verifications/selectors';

import { usePath } from 'hooks/usePath';
import { useQueryProps } from 'hooks/useQueryProps';
import { useStatePreference } from 'hooks/useStatePreference';

import {
  ClockCircleOutlined,
  LoadingOutlined,
  SearchOutlined,
  SolutionOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Checkbox,
  Select,
  Tag,
} from 'antd';
import { DefaultPageLayout } from 'components/layouts/DefaultPageLayout';
import { SubHeaderSearch } from 'components/SubHeader/SubHeaderSearch';
import {
  VerificationPluralControlsButtons,
} from 'pages/PageVerification/Plural/components/VerificationPluralControlsButtons';
import { VerificationPluralRangePicker } from 'pages/PageVerification/Plural/components/VerificationPluralRangePicker';
import { VerificationPluralTable } from 'pages/PageVerification/Plural/components/VerificationPluralTable';
import {TDictionaryItem} from "models/dictionaries/types";
import { ActionMeta } from 'redux-actions';

const Controls = styled.div`
  display: grid;

  padding: 16px;
  gap: 8px;

  justify-content: space-between;
  background-color: ${palette.backgroundAccentColor};

  @media (min-width: ${breakpoints.md}) {
    justify-content: flex-start;
    align-items: flex-start;

    padding-right: 40px;
    padding-left: 40px;
  }

  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 60px;
    justify-content: flex-start;
  }
`;

const ControlsSearch = styled(SubHeaderSearch)`
  width: 100%;
  margin-bottom: 0;
  margin-right: 0;
`;

const ControlsSelect = styled(Select)`
  width: 100%;
`;

const ControlsCheckbox = styled(Checkbox)`
  color: ${palette.whiteColor};
  line-height: 32px;
`;

const tagRender = (verificationStatuses: TDictionaryItem[]) => ({closable, onClose, value}: {
  closable: boolean,
  onClose: () => void,
  value: number
}) => {
  const verificationStatus = _.find(verificationStatuses, {id: _.toInteger(value)});
  if (!verificationStatus) return null;

  const onPreventMouseDown = (event: { preventDefault: () => void; stopPropagation: () => void; }) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      closable={closable}
      color={verificationStatus.color}
      onClose={onClose}
      onMouseDown={onPreventMouseDown}
      style={{marginRight: 3}}
    >
      {verificationStatus.description}
    </Tag>
  );
};

const PageVerificationPlural = () => {
  const dispatch = useDispatch();
  const {replacePath} = usePath();
  const isSale = _.startsWith(window.location.pathname, `/sale`);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const isLoading = useSelector(isLoadingSelector);
  const operators = useSelector(getOperatorsSelector);
  const partners = useSelector(state => getDictionary(state, `partner`));
  const total = useSelector(getTotalSelector);
  const user = useSelector(getCurrentUserSelector);
  const userRoles = useSelector(getRoleNamesSelector);
  const verifications = useSelector(getVerificationsSelector);
  const verificationStatusesAll = useSelector(state => getDictionary(state, `verificationStatus`));

  const verificationStatuses = isSale
    ? _.filter(verificationStatusesAll, vs => _.includes(SALE_STATUS_NAMES, vs?.name))
    : verificationStatusesAll;

  const assign = (ids: string[]) => dispatch(assignAction({ids}));
  const getOperators = () => dispatch(getOperatorsAction());
  const getPartners = () => dispatch(getDictionaryAction({entity: `partner`, force: true}));
  const getVerifications = (callback?: () => void) => dispatch(getVerificationsAction({callback, isSale}));

  const getVerificationStatuses = () => dispatch(getDictionaryAction({entity: `verificationStatus`, force: true}));
  const reassign = (ids: string[]) => dispatch(reassignAction({ids}));

  const set = _.debounce(value => dispatch(setAction(value)), 200, {leading: true});

  const setStatus = ({
                       id,
                       ids,
                       verificationStatusId,
                     }: {
    id?: number;
    ids?: number[];
    verificationStatusId: number;
  }) => dispatch(setStatusAction({id, ids, verificationStatusId}));

  const updateUserData = (callback: () => ActionMeta<any, any>) => dispatch(updateUserDataAction({ callback }));

  const { queryProps } = useQueryProps();

  const userId = queryProps.userId ? _.toInteger(queryProps.userId) : null;

  const isItemsLoading = useSelector(isItemsLoadingSelector);
  const dateFrom = queryProps.dateFrom
    ? moment(_.toInteger(queryProps.dateFrom))
    : moment().subtract(12, `d`);

  const dateTo = queryProps.dateTo
    ? moment(_.toInteger(queryProps.dateTo))
    : null;

  // @ts-ignore
  const fullName:string = queryProps.fullName || ``;
  // @ts-ignore
  const personId:string = queryProps.personId || ``;

  const [preferencesPageSize, setPreferencesPageSize] = useStatePreference(
    `ui.verification.table.pagination.pageSize`,
    10,
  );

  const pageSize = queryProps.pageSize
    ? _.toInteger(queryProps.pageSize)
    : preferencesPageSize;

  const page = (queryProps.page ? _.toInteger(queryProps.page) : null) || 1;

  const partnerId = queryProps.partnerId ? _.toInteger(queryProps.partnerId) : null;
  const mappedPartners = _.map(_.sortBy(partners, `name`), ({ id, name }) => ({ label: name, value: id }));

  const showOnlyOwnedTasks = user.id === userId;
  const mappedVerifications = _.map(verifications, verification => ({
    ...verification,
    operatorName: _.get(_.find(operators, { id: verification.operatorId }), `user.fullName`),
  }));
  // @ts-ignore
  const verificationStatusId = queryProps.verificationStatusId ? _.split(queryProps.verificationStatusId, `,`) : [];
  const verificationStatusIdStr = queryProps.verificationStatusId;

  const dateFromMs = moment.isMoment(dateFrom) ? dateFrom.valueOf() : dateFrom;
  const dateToMs = moment.isMoment(dateTo) ? dateTo.valueOf() : dateTo;

  const basePath = isSale ? `sale` : `verification`;

  const getPath = (
    query: _.Dictionary<any>,
    isArray?: boolean,
  // @ts-ignore
  ) => `/${basePath}?${qs.stringify(query, isArray ? { arrayFormat: `comma` } : null)}`;

  const handleAssign = () => assign(selectedRowKeys);

  const onPaginationChange = (newPage: number, newPageSize: number) => {
    set({ page: newPage, pageSize: newPageSize });
    setPreferencesPageSize(newPageSize);
    replacePath(getPath(_.omitBy({ ...queryProps, page: newPage, pageSize: newPageSize }, _.isNil)));
  };

  const postpone = () => {
    const postponedStatusId = _.get(_.find(verificationStatuses, { name: `postponed` }), `id`);
    setStatus({ ids: selectedRowKeys, verificationStatusId: postponedStatusId });
  };

  const handleReassign = () => {
    reassign(selectedRowKeys);
  };

  const setDateRange = (dates: moment.MomentInput[]) => {
    const [dateFromMoment, dateToMoment] = dates || [];
    const newDateFrom = dateFromMoment ? dateFromMoment.valueOf() : null;
    const newDateTo = dateToMoment ? dateToMoment.valueOf() : null;

    replacePath(getPath(_.omitBy({ ...queryProps, dateFrom: newDateFrom, dateTo: newDateTo }, _.isNil)));
    set({ dateFrom: dateFromMoment, dateTo: dateToMoment });
  };

  const setFullName = (newFullName: string) => {
    const newQueryProps = _.omit(queryProps, `personId`);
    replacePath(getPath(newFullName
      ? { ...newQueryProps, fullName: newFullName }
      : _.omit(newQueryProps, `fullName`), true));
    set({ fullName: newFullName });
  };

  const setPartner = (newPartnerId: any) => {
    replacePath(getPath({ ...queryProps, partnerId: newPartnerId }, true));
    set({ partnerId });
  };

  const setVerificationStatus = (newVerificationStatusId: any) => {
    const statusId = _.uniq(_.map(newVerificationStatusId, _.toInteger));
    replacePath(getPath({ ...queryProps, verificationStatusId: statusId }, true));
    set({ verificationStatusId: statusId });
  };

  const toggleUserId = () => {
    const newUserId = showOnlyOwnedTasks ? null : user.id;
    replacePath(getPath(newUserId ? { ...queryProps, userId: newUserId } : _.omit(queryProps, `userId`)));
    set({ userId: newUserId });
  };

  const updateUsers = () => updateUserData(() => getVerifications());

  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  useEffect(() => {
    set({
      fullName,
      page,
      pageSize,
      partnerId,
      personId,
      userId,
      verificationStatusId,
    });
  }, [fullName, page, pageSize, partnerId, userId, verificationStatusIdStr]);

  useEffect(() => {
    const setData = {};

    if (dateFrom && dateFromMs && Math.abs(dateFromMs - dateFrom.valueOf()) > 1000) {
      // @ts-ignore
      setData.dateFrom = dateFrom;
    }

    if (dateTo && dateToMs && Math.abs(dateFromMs - dateTo.valueOf()) > 1000) {
      // @ts-ignore
      setData.dateTo = dateTo;
    }

    if (!_.isEmpty(setData)) set(setData);
  }, [dateFromMs, dateToMs]);

  useEffect(() => {
    if (_.isEmpty(verificationStatuses)) getVerificationStatuses();
    if (_.isEmpty(operators)) getOperators();
    if (_.isEmpty(partners)) getPartners();
    if (_.isEmpty(verifications)) {
      getVerifications(() => setInitiallyLoaded(true));
    } else {
      setInitiallyLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (initiallyLoaded) {
      getVerifications();
    }
  }, [page, pageSize, initiallyLoaded]);

  return (
    <>
      <Controls>
        <ControlsSearch
          allowClear
          className='controls-search'
          onChange={setFullName}
          onPressEnter={() => getVerifications()}
          placeholder='Поиск по ФИО клиента'
          value={fullName}
        />
        <ControlsSelect
          allowClear
          mode='multiple'
          onChange={setVerificationStatus}
          placeholder='Все статусы'
          // @ts-ignore
          tagRender={tagRender(verificationStatuses)}
          value={_.uniq(verificationStatusId)}
        >
          {_.map(verificationStatuses, ({ color, description, id }) => (
            <Select.Option key={id} value={id}>
              <Tag color={color}>
                {description}
              </Tag>
            </Select.Option>
          ))}
        </ControlsSelect>
        <ControlsSelect
          allowClear
          onChange={setPartner}
          optionFilterProp='label'
          options={mappedPartners}
          placeholder='Партнёр'
          showSearch
          value={partnerId}
        />

        <VerificationPluralRangePicker dateFrom={dateFrom} dateTo={dateTo} setDateRange={setDateRange} />

        <ControlsCheckbox
          checked={showOnlyOwnedTasks}
          onChange={toggleUserId}
        >
          Только свои задания
        </ControlsCheckbox>

        <VerificationPluralControlsButtons
          controls={[
            {
              title  : `Обновить данные клиентов`,
              icon   : <UserOutlined />,
              key    : `refresh`,
              onClick: updateUsers,
            },
            {
              title     : `Искать`,
              icon      : isLoading ? <LoadingOutlined spin /> : <SearchOutlined />,
              onClick   : () => getVerifications(),
              type      : `primary`,
              key       : `search`,
              isDisabled: isItemsLoading || isLoading,
            },
            {
              title     : `В доработку`,
              type      : `danger`,
              isDisabled: _.isEmpty(selectedRowKeys),
              icon      : <ClockCircleOutlined />,
              onClick   : postpone,
              isHidden  : !_.includes(userRoles, `verificationWrite`),
            },
            {
              title     : `Вернуть в очередь`,
              onClick   : handleReassign,
              isDisabled: _.isEmpty(selectedRowKeys),
              icon      : <UsergroupDeleteOutlined />,
              isHidden  : !_.includes(userRoles, `verificationWrite`),
            },
            {
              tooltipTitle: _.isEmpty(selectedRowKeys)
                ? `Нужно выбрать хотя бы одну строку`
                : !_.includes(userRoles, `verificationWrite`)
                  ? `Нужна роль verificationWrite`
                  : `Взять себе в работу`,
              title     : `Взять себе в работу`,
              key       : `assign-to-me`,
              onClick   : handleAssign,
              isDisabled: _.isEmpty(selectedRowKeys) || !_.includes(userRoles, `verificationWrite`),
              icon      : <SolutionOutlined />,
            },
          ]}
        />
      </Controls>
      <DefaultPageLayout>
        <VerificationPluralTable
          isSale={isSale}
          // @ts-ignore
          onPaginationChange={onPaginationChange}
          // @ts-ignore
          onSelectChange={setSelectedRowKeys}
          page={page}
          pageSize={pageSize}
          partners={mappedPartners}
          selectedRowKeys={selectedRowKeys}
          total={total}
          verifications={mappedVerifications}
        />
      </DefaultPageLayout>
    </>
  );
};

export default PageVerificationPlural;
