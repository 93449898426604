import React from 'react';
import { Link } from 'dva/router';
import styled, { useTheme } from 'styled-components';

// @ts-ignore
import logoDark from 'assets/img/logo-dark.svg';
// @ts-ignore
import logoLight from 'assets/img/logo-light.svg';

const Wrapper = styled(Link)`
  position: relative;
`;

const Img = styled.img`
  display: block;
  height: 24px;
  width: 89px;
`;

interface IComponentProps {
  className?: string;
  to: string;
}

export const HeaderLogo: React.FC<IComponentProps> = ({ className, to = `/` }) => {
  const { info } = useTheme();

  return (
    <Wrapper className={className} to={to}>
      <Img alt='КэшДрайв' src={info.name === `light` ? logoDark : logoLight} />
    </Wrapper>
  );
};
