import _ from 'lodash';

import { getDataFromPath } from 'models/ui/helpers';

import {
  model,
  RESET,
  SET,
  SET_ACTIVE_TAB,
  SET_LOADING,
  SET_MODAL,
} from './actions';

const initialState = {
  activeTabPath     : `/client`,
  breadcrumbs       : [],
  isSendSmsModalOpen: false,
  isLoading         : false,
  modal             : {
    sendSms: {
      isOpen  : false,
      personId: null,
    },
  },
  redirectAfterLogin: ``,
};

export default {
  namespace    : model,
  state        : initialState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ location }) => {
        const { modalData, path } = getDataFromPath(location);
        if (path) dispatch({ type: SET_ACTIVE_TAB, payload: { path } });
        dispatch({
          type   : SET_MODAL,
          payload: {
            modalName: `sendSms`,
            ...modalData,
          },
        });
      });
    },
  },
  effects: {},

  reducers: {
    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload = {} }) {
      return {
        ...state,
        ...payload,
      };
    },

    [SET_ACTIVE_TAB](state, { payload : { path: activeTabPath } }) {
      return {
        ...state,
        activeTabPath,
      };
    },

    [SET_LOADING](state, { payload: isLoading = false }) {
      return {
        ...state,
        isLoading,
      };
    },

    [SET_MODAL](state, { payload = {} }) {
      if (!payload?.modalName) return state;

      return {
        ...state,
        modal: {
          ...state.modal,
          [payload.modalName]: {
            ...(state.modal[payload.modalName] || {}),
            ..._.omit(payload, [`modalName`]),
          },
        },
      };
    },

  },
};
