import * as React from 'react';
import styled from 'styled-components';

import { useMessenger } from 'components/Messenger/hooks';
import { Messenger } from 'components/Messenger/Messenger';

const Wrapper = styled.div<{ isCompact?: boolean; }>`
  height: ${({ isCompact }) => (isCompact ? `100%` : `800px`)};
`;

interface IComponentProps {
  clientFullName: string;
  isCompact?: boolean;
  personId: string;
}

export const PageTaskCommentSingle: React.FC<IComponentProps> = ({ clientFullName, isCompact, personId }) => {
  const { dialog } = useMessenger(personId);

  return (
    <Wrapper isCompact={isCompact}>
      <Messenger
        dialog={dialog}
        notificationTitle={`${clientFullName} - новый комментарий от коллекторов`}
      />
    </Wrapper>
  );
};
