import _ from 'lodash';

import { getDateFormatted } from 'models/clients/helpers';
import { ILoanClient } from 'models/clients/types';

export const getItems = (loanClient: ILoanClient) => _.filter([
  {
    key : loanClient.phone,
    name: `Номер телефона`,
  },
  {
    key  : loanClient.passportSerial || loanClient.passportNumber,
    name : `Серия номер паспорта`,
    value: `${loanClient.passportSerial} ${loanClient.passportNumber}`,
  },
  {
    key  : loanClient.passportIssuedDate || loanClient.passportIssuer,
    name : `Выдан`,
    value: `${getDateFormatted(loanClient, `passportIssuedDate`)} ${loanClient.passportIssuer}`,
  },
], `key`);
