import * as React from 'react';
import {
  useCallback,
  useContext,
  useMemo,
} from 'react';
import _ from 'lodash';

import { useHasRoles } from 'hooks/useHasRoles';
import { usePath } from 'hooks/usePath';

import { SubHeaderMenu } from 'components/SubHeader/Menu';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import {
  NAV,
  VERIFICATION_NAV,
} from 'pages/PageClient/Single/components/ClientMenu/constants';

interface IProps {}

const ClientMenu: React.FC<IProps> = () => {
  const { personId, verificationId } = useContext(ClientMatchContext);
  const { replacePath } = usePath();

  const { pathname } = window.location;

  const isVerification = !!verificationId;

  const pathBlocks = _.split(pathname, `/`);
  const activeTab = _.last(pathBlocks);
  const basePath = pathBlocks?.[1];

  const goToTab = useCallback((tabKey: string) => replacePath(
    `/${basePath}${isVerification ? `/${verificationId}` : ``}/person/${personId}/${tabKey}`,
  ), [personId, isVerification, replacePath, verificationId]);

  const hasRoles = useHasRoles();

  let nav = useMemo(() => _.reject(
    isVerification ? VERIFICATION_NAV : NAV,
    // @ts-ignore
    ({ roles }) => !_.isEmpty(roles) && !hasRoles(roles),
  ), [isVerification]);
  if (import.meta.env.VITE_REACT_APP_ENV === `production`) nav = _.reject(nav, [`path`, `sms`]);

  return (
    <SubHeaderMenu
      activeKey={activeTab}
      items={nav}
      onTabClick={goToTab}
      size='small'
    />
  );
};

export default ClientMenu;
