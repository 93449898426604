
import React, {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import {
  getColumn,
  getRandomTagColor,
} from 'helper';

import { get as getRoleGroupsAction } from 'models/roleGroups/actions';
import { getItems as getRoleGroupsSelector } from 'models/roleGroups/selectors';
import {
  create as createAction,
  get as getRolesAction,
  remove as removeRolesAction,
  removeFromGroup as removeRoleFromGroupAction,
  set as setAction,
  update as updateAction,
} from 'models/roles/actions';
import {
  getItems as getRolesSelector,
  getItemsFiltered as getRolesFilteredSelector,
  getItemsNames as getRoleNamesSelector,
  getSearchString as searchStringSelector,
  isLoading as isLoadingSelector,
} from 'models/roles/selectors';
import { isSuperAdmin as isSuperAdminSelector } from 'models/user/selectors';

import { useModalHandlers } from 'hooks/useModalHandlers';

import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  SyncOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { DefaultTag } from 'components/DefaultTag/DefaultTag';
import CreateModal from 'components/Modal/Create';
import RemoveModal from 'components/Modal/Remove';
import { AdminPluralLayout } from 'pages/PageAdmin/components/AdminPluralLayout';
import { AdminSearchWithControls } from 'pages/PageAdmin/components/AdminSearchWithControls';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';

import {
  COLUMNS,
  FORM_FIELDS,
} from '../constants';
import GroupRolesModal from '../Modal/Group';

const PageRolesPlural = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    isSuperAdmin,
    removeModalItems,
    roleGroups,
    roleNames,
    roles,
    searchString,
  } = useSelector(state => ({
    isLoading       : isLoadingSelector(state),
    isSuperAdmin    : isSuperAdminSelector(state),
    removeModalItems: getRolesSelector(state),
    roleGroups      : getRoleGroupsSelector(state),
    roleNames       : getRoleNamesSelector(state),
    roles           : getRolesFilteredSelector(state),
    searchString    : searchStringSelector(state),
  }));

  const create = role => dispatch(createAction({ item: role }));
  const getRoleGroups = () => dispatch(getRoleGroupsAction({ force: true }));
  const getRoles = () => dispatch(getRolesAction({ force: true }));
  const removeRoleFromGroup = ({ id, roleGroupId }) => dispatch(removeRoleFromGroupAction({ id, roleGroupId }));
  const removeRolesDispatch = ids => dispatch(removeRolesAction({ ids }));
  const set = value => dispatch(setAction(value));
  const update = item => dispatch(updateAction({ id: item.id, item }));

  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const toggleGroupModal = () => setIsGroupModalOpen(p => !p);

  const {
    closeEditModal,
    isCreateModalOpen,
    isEditModalOpen,
    isRemoveModalOpen,
    itemEditing,
    onSelectChange,
    selectedRowKeys,
    setItemEditing,
    toggleCreateModal,
    toggleEditModal,
    toggleRemoveModal,
  } = useModalHandlers();

  const formFields = FORM_FIELDS(roleNames, itemEditing);

  const namePath = ({ color, name }) => (
    <div style={{ marginTop: `8px` }}>
      <DefaultTag color={color} key={name}>{name}</DefaultTag>
    </div>
  );
  const removeRoles = () => {
    onSelectChange([]);
    removeRolesDispatch(_.filter(selectedRowKeys, id => isSuperAdmin || id >= 0));
  };
  const setSearchString = e => set({ searchString: _.isString(e) ? e : e.target.value });

  useEffect(() => {
    getRoles();
    if (_.isEmpty(roleGroups)) getRoleGroups();
  }, []);

  return (
    <>
      <AdminSearchWithControls
        controls={[
          {
            icon   : isLoading ? <LoadingOutlined spin /> : <SyncOutlined />,
            onClick: () => {
              setSearchString(``);
              getRoles();
            },
            title: `Очистить поиск и перезагрузить`,
          },
          {
            isDisabled: _.isEmpty(selectedRowKeys),
            onClick   : toggleGroupModal,
            title     : `Группировать выбранные роли`,
            icon      : <TeamOutlined />,
          },
          {
            isDisabled: _.isEmpty(_.reject(selectedRowKeys, id => !isSuperAdmin && id < 0)),
            onClick   : toggleRemoveModal,
            title     : `Удалить выбранные роли`,
            icon      : <DeleteOutlined />,

          },
          {
            onClick: toggleCreateModal,
            title  : `Добавить роль`,
            icon   : <PlusCircleOutlined />,
          },
        ]}
        search={{
          placeholder: `Поиск роли`,
          value      : searchString,
          onChange   : setSearchString,
        }}
      />

      <AdminPluralLayout>
        <AdjustableTable
          columns={[
            ...COLUMNS,
            getColumn({
              dataIndex: `roleGroups`,
              key      : `id`,
              render   : (v, role) => _.map(v, ({ id, name }) => (
                <DefaultTag
                  closable
                  closeIcon={<DeleteOutlined />}
                  color={getRandomTagColor()}
                  key={name}
                  onClose={() => removeRoleFromGroup({ id: role.id, roleGroupId: id })}
                >
                  {name}
                </DefaultTag>
              )),
              title: `Группы`,
            }),
          ]}
          dataSource={roles}
          loading={isLoading}
          pagination={{
            hideOnSinglePage: true,
            pageSize        : _.size(roles),
            size            : `small`,
          }}
          preferences={{
            path      : `admin.roles`,
            cellRender: (value, role) => (
              <div>
                {(role.id > 0 || isSuperAdmin) && (
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      setItemEditing(role);
                      toggleEditModal();
                    }}
                    type='primary'
                  />
                )}
              </div>
            ),
          }}
          rowKey='id'
          rowSelection={{
            onChange: onSelectChange,
            selectedRowKeys,
          }}
          scroll={{ x: `max-content`, y: window.innerHeight - 300 }}
        />
        <CreateModal
          close={toggleCreateModal}
          create={create}
          formFields={formFields}
          roleGroups={roleGroups}
          title='Новая роль'
          visible={isCreateModalOpen}
        />
        <CreateModal
          close={closeEditModal}
          formFields={formFields}
          item={itemEditing}
          mode='update'
          roleGroups={roleGroups}
          title={`Редактирование роли ${_.get(itemEditing, `name`)
            ? `'${_.get(itemEditing, `name`)}'`
            : ``}`}
          update={update}
          visible={isEditModalOpen}
        />
        <GroupRolesModal
          close={toggleGroupModal}
          ids={selectedRowKeys}
          visible={isGroupModalOpen}
        />
        <RemoveModal
          close={toggleRemoveModal}
          ids={_.filter(selectedRowKeys, id => isSuperAdmin || id >= 0)}
          items={removeModalItems}
          modalTitle='Удаление ролей'
          namePath={namePath}
          onSubmit={removeRoles}
          question='Вы действительно хотите удалить эти роли?'
          visible={isRemoveModalOpen}
        />
      </AdminPluralLayout>
    </>
  );
};

export default PageRolesPlural;
