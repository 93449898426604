// @ts-nocheck
import React from 'react';
import dva from 'dva';
// @ts-ignore
import createLoading from 'dva-loading';
import { Router, Route, Switch } from 'dva/router';
import { createBrowserHistory } from 'history';
import moment from 'moment';
// @ts-ignore
import momentLocale from 'moment/locale/ru';
import { pdfjs } from 'react-pdf';
import { persistReducer, persistStore } from 'redux-persist';
import stateReconciler from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import * as smoothscroll from 'smoothscroll-polyfill';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from 'styles/global-styles';
import { EThemeName, GlobalThemeContext } from 'styles/GlobalThemeContext';
import { darkTheme, lightTheme } from 'styles/theme';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import 'normalize.css';
import './assets/style/reset.scss';
import 'antd/dist/antd.css';

import Layout from "./pages/Layout";

// Import your models and components
import actionsModel from 'models/actions/reducers';
import adminModel from 'models/admin/reducers';
import bankruptsModel from 'models/bankrupts/reducers';
import callsModel from 'models/calls/reducers';
import clientsModel from 'models/clients/reducers';
import communicationsModel from 'models/communications/reducers';
import courtsModel from 'models/courts/reducers';
import dialogsModel from 'models/dialogs/reducers';
import dictionariesModel from 'models/dictionaries/reducers';
import eventlogsModel from 'models/eventlogs/reducers';
import leadModel from 'models/lead/reducers';
import mttModel from 'models/mtt/reducers';
import notificationsModel from 'models/notifications/reducers';
import operatorsModel from 'models/operators/reducers';
import roleGroupsModel from 'models/roleGroups/reducers';
import rolesModel from 'models/roles/reducers';
import taskModel from 'models/task/reducers';
import uiModel from 'models/ui/reducers';
import userModel from 'models/user/reducers';
import verificationsModel from 'models/verifications/reducers';
import versionModel from 'models/version/reducers';

import PageVersion from "./pages/PageVersion";

// Initialize smoothscroll
smoothscroll.polyfill();

// Configure moment
moment.locale('ru', momentLocale);
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Configure Redux Persist
const persistConfig = {
  blacklist: ['ui', 'eventlogs'],
  key: 'root',
  stateReconciler,
  storage,
};

const app = dva({
  history: createBrowserHistory(),
  onError(error) {
    console.error(error); // eslint-disable-line
  },
  onReducer(reducer) {
    return persistReducer(persistConfig, reducer);
  },
});

// Add models
// @ts-ignore
app.model(actionsModel);
// @ts-ignore
app.model(adminModel);
// @ts-ignore
app.model(bankruptsModel);
// @ts-ignore
app.model(callsModel);
// @ts-ignore
app.model(clientsModel);
// @ts-ignore
app.model(communicationsModel);
// @ts-ignore
app.model(courtsModel);
// @ts-ignore
app.model(dialogsModel);
// @ts-ignore
app.model(dictionariesModel);
// @ts-ignore
app.model(eventlogsModel);
// @ts-ignore
app.model(leadModel);
// @ts-ignore
app.model(mttModel);
// @ts-ignore
app.model(notificationsModel);
// @ts-ignore
app.model(operatorsModel);
// @ts-ignore
app.model(rolesModel);
// @ts-ignore
app.model(roleGroupsModel);
// @ts-ignore
app.model(taskModel);
// @ts-ignore
app.model(uiModel);
// @ts-ignore
app.model(userModel);
// @ts-ignore
app.model(verificationsModel);
// @ts-ignore
app.model(versionModel);

app.use(createLoading({ global: false, models: {} }));

// Configure router
// @ts-ignore
app.router(({ _app, history }: { _app: any, history: any }) => {
  const initialTheme = (window.localStorage.getItem('themeName') as EThemeName) || EThemeName.LIGHT;
  const [themeName, setThemeName] = React.useState<EThemeName>(initialTheme);

  const onThemeChange = (newThemeName: EThemeName) => {
    setThemeName(newThemeName);
    window.localStorage.setItem('themeName', newThemeName);
  };

  return (
    <GlobalThemeContext.Provider value={{ themeName, setThemeName: onThemeChange } as { themeName: EThemeName, setThemeName: React.Dispatch<React.SetStateAction<string>> }}>
      <ThemeProvider theme={themeName === EThemeName.LIGHT ? lightTheme : darkTheme}>
        <GlobalStyles />
        <ConfigProvider locale={locale}>
          <Router history={history}>
            <Switch>
              <Route component={PageVersion} exact path='/v' />
              <Route component={Layout} path='/' />
            </Switch>
          </Router>
        </ConfigProvider>
      </ThemeProvider>
    </GlobalThemeContext.Provider>
  );
});

app.start('#app');
// @ts-ignore
persistStore(app._store); // eslint-disable-line
export default app
