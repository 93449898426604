import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { copyToClipboard } from 'helper';

import { entityCardRowTextMixin } from 'pages/PageClient/Single/components/EntityCard/EntityCard';

const Wrapper = styled.div<{ isInteractive: boolean }>`
  display: flex;
  flex-direction: column;

  background: ${palette.black5Color};
  padding: 16px 12px;

  margin-right: -12px;
  margin-left: -12px;
  border-bottom: 1px solid ${palette.black10Color};
  border-top: 1px solid ${palette.black10Color};

  :not(:last-child) {
    margin-bottom: 8px;
  }

  ${p => p.isInteractive && `
    :active {
      cursor: pointer;
      background: ${palette.black10Color(p)};
    }
  `};

  @media (min-width: ${breakpoints.lg}) {
    :not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const Title = styled.span`
  ${entityCardRowTextMixin};
  color: ${palette.black80Color};
  margin-bottom: 8px;
`;
export const Message = styled.p`
  ${entityCardRowTextMixin};
  color: ${palette.black100Color};
`;

interface IComponentProps {
  children?: React.ReactNode;
  title: string;
  valueToCopy?: string | number;
}

export const EntityCardSection: React.FC<IComponentProps> = ({ children, title, valueToCopy }) => {
  const onClickHandler = valueToCopy ? () => copyToClipboard(valueToCopy) : () => _.noop();

  return (
    <Wrapper isInteractive={Boolean(valueToCopy)} onClick={onClickHandler}>
      <Title>
        {title}
      </Title>
      <Message>
        {children}
      </Message>
    </Wrapper>
  );
};
