/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import {
  formatDate,
  prettifyNumber,
} from 'helper';

import { LinkOutlined } from '@ant-design/icons';
import {
  EntityDetails,
  IRow,
} from 'pages/PageClient/Single/components/EntityDetails/EntityDetails';
import {ILoan} from "models/clients/types";

const ROWS: IRow[] = [
  {
    key   : `loanClosingAmount`,
    title : `Сумма для полного закрытия, ₽`,
    render: v => prettifyNumber(v),
  },
  {
    key   : `recommendedPaymentAmount`,
    title : `Рекомендуемый платёж, ₽`,
    render: v => prettifyNumber(v),
  },
  {
    key   : `nearestPaymentAmount`,
    title : `Сумма ближайшего платежа, ₽`,
    render: v => prettifyNumber(v),
  },
  {
    key   : `nearestPaymentDtm`,
    title : `Дата ближайшего платежа`,
    render: (v, record) => {
      const isClosedOverdue = record?.nearestPaymentDtm < 0 && record?.activeFlg;
      if (v > 0) return formatDate(v, `dd.MM.yyyy`);

      return isClosedOverdue
        ? `Просрочка завершенного договора. Погашение в любой день`
        : (v > 0 ? v : `-`);
    },
  },
  {
    key  : `overdueDebtTerm`,
    title: `Срок просрочки`,
  },
  {
    key   : `totalOverdueDebt`,
    title : `Сумма просрочки, ₽`,
    render: v => prettifyNumber(v),
  }, {
    key   : `currentAccountAmount`,
    title : `Переплата, ₽`,
    render: v => prettifyNumber(v),
  },
];

const CREDIT_HOLIDAY_ROWS: IRow[] = [
  {
    key   : `creditHolidayFlg`,
    title : `Флаг КК`,
    render: v => (v ? `Да` : `Нет`),
  }, {
    key   : `creditHolidayStartDtm`,
    title : `Дата начала КК`,
    render: (v, record) => (record.creditHolidayFlg ? formatDate(v, `dd.MM.yyyy`) : undefined),
  }, {
    key   : `creditHolidayEndDtm`,
    title : `Дата конца КК`,
    render: (v, record) => (record.creditHolidayFlg ? formatDate(v, `dd.MM.yyyy`) : undefined),
  },
];

const OPERATIONS_ROWS: IRow[] = [
  {
    key   : `cardMask`,
    title : `Карта`,
    render: (v: string, record: any) => {
      if (v) return v;
      if (record.CardFirstSix || record.CardLastFour) return `${record.CardFirstSix || `**** **`}** **** ${record.CardLastFour || `****`}`;
      return null;
    },
  },
  {
    key   : `ofdReceiptUrl`,
    title : `Ссылка`,
    render: (v?: string): any => {
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      if (v) return (<a href={v} rel='noreferrer' target='_blank'><LinkOutlined /></a>);
      return null;
    },
  },
];

const StyledEntityDetails = styled(EntityDetails)`
  margin-left: 16px;
  margin-right: 16px;

  @media(min-width: ${breakpoints.md}) {
    margin-right: 24px;
    margin-left: 24px;
  }
`;

interface IProps {
  details: ILoan['details'];
  operations: any[];
}

export const LoanDetails: React.FC<IProps> = ({ details, operations }) => {
  const operationsDetails = _.flatMap(operations, `details`);
  const operationsParsed = _.reduce(operationsDetails, (result, detail) => {
    if (_.includes([`cardMask`, `CardFirstSix`, `CardLastFour`, `ofdReceiptUrl`], detail.key)) {
      return {
        ...result,
        [detail.key]: detail.value,
      };
    }

    return result;
  }, {});
  return (
    <>
      <StyledEntityDetails record={details} rows={ROWS} />
      <StyledEntityDetails record={details} rows={CREDIT_HOLIDAY_ROWS} />
      <StyledEntityDetails record={operationsParsed} rows={OPERATIONS_ROWS} />
    </>
  );
};

