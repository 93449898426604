import React, {
  useCallback,
  useState,
} from 'react';
import { useDispatch } from 'dva';

import { downloadExcel as downloadExcelAction } from 'models/courts/actions';

import { Layout } from 'antd';
import { CourtTable } from 'pages/PageCourt/Plural/CourtTable';

import { CourtSubHeader } from './CourtSubHeader';

const tableScrollY = (window.innerWidth < 769) ? (window.innerHeight - 400) : (window.innerHeight - 250);

const PageCourtPlural = () => {
  const dispatch = useDispatch();

  const [courtIds, setCourtIds] = useState<string[]>([]);

  const onDownloadExcel = useCallback(() => dispatch(downloadExcelAction({ courtIds })), [dispatch, courtIds]);

  return (
    <>
      <CourtSubHeader courtIds={courtIds} onDownloadExcel={onDownloadExcel} />
      <Layout>
        <CourtTable setCourtIds={setCourtIds} tableScrollY={tableScrollY} />
      </Layout>
    </>
  );
};

export default PageCourtPlural;
