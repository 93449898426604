import React, {
  useRef,
  useState,
} from 'react';
import { useSelector } from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  height,
} from 'styles/theme';

import { getIsLoading as isLoadingSelector } from 'models/clients/selectors';

import { useClickOutsideHandler } from 'hooks/useClickOutside';
import useMobileContext from 'hooks/useMobileContext';

import { DefaultSpin } from 'components/DefaultSpin/DefaultSpin';
import { SubHeader } from 'components/SubHeader/SubHeader';
import { ClientSearch } from 'pages/PageClient/Plural/components/ClientSearch';
import { ClientsList } from 'pages/PageClient/Plural/components/ClientsList';
import { RecentMobile } from 'pages/PageClient/Plural/components/Recent/RecentMobile';
import { RecentMobileContent } from 'pages/PageClient/Plural/components/Recent/RecentMobileContent';

import { RecentDesktop } from './components/Recent/RecentDesktop';

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  height: calc(100vh - ${height.headerMobile});
  min-width: 100vw;
  max-width: 100vw;

  @media(min-width: ${breakpoints.md}) {
    height: calc(100vh - ${height.headerTablet});
  }
`;

const PageContent = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  max-width: 100vw;
`;

const StyledSubheader = styled(SubHeader)`
  position: relative;
  top: 0;
  display: flex;
`;

const SearchWrapper = styled.div`
  flex-grow: 1;
`;
const RecentWrapper = styled.div``;

const PageClientPlural = () => {
  const [isRecentOpen, setIsRecentOpen] = useState(false);

  const isLoading = useSelector(isLoadingSelector);

  const { mobile } = useMobileContext();

  const wrapperRef = useRef(null);
  const exceptionRef = useRef(null);

  useClickOutsideHandler(wrapperRef, () => {
    if (isRecentOpen) {
      setIsRecentOpen(false);
    }
  }, exceptionRef);

  return (
    <PageWrapper>
      <SearchWrapper>
        <StyledSubheader>
          <ClientSearch />
          {mobile && (
            <RecentMobile
              isRecentOpen={isRecentOpen}
              ref={exceptionRef}
              setIsRecentOpen={setIsRecentOpen}
            />
          )}
        </StyledSubheader>
        <PageContent>
          {isLoading && <DefaultSpin delay={900} />}
          <ClientsList />
          <RecentMobileContent isOpen={isRecentOpen} ref={wrapperRef} />
        </PageContent>
      </SearchWrapper>
      {!mobile && (
        <RecentWrapper>
          <RecentDesktop />
        </RecentWrapper>
      )}
    </PageWrapper>
  );
};
export default PageClientPlural;
