import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { getClientCars as getClientCarsAction } from 'models/clients/actions';
import { getClientCarsWithApplications as getClientCarsSelector } from 'models/clients/selectors';
import { ICar } from 'models/clients/types';
import {
  CAMPAIGN_NAMES,
  CAR_FIELDS,
} from 'models/task/constants';

import {
  Empty,
  Switch,
} from 'antd';
import { CarCard } from 'pages/PageClient/Single/Car/CarCard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const StyledSwitch = styled(Switch)`
  .ant-switch-inner {
    font-size: 16px;
  }
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.mm}) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

const ItemWrapper = styled.div<{ $isHighlighted: boolean; }>`
  padding: 8px;
  border-radius: 8px;
  background-color: ${p => (p.$isHighlighted ? palette.primary100Color(p) : palette.whiteColor(p))};

  @media (max-width: ${breakpoints.mm}) {
    max-width: unset;
  }

  &+& {
    @media (max-width: ${breakpoints.mm}) {
      margin-top: 16px;
      margin-left: 0;
    }

    margin-left: 16px;
  }
`;

interface IProps {
  applicationId?: string;
  campaignName: string;
  personId: string;
}

export const TaskCars:React.FC<IProps> = ({ applicationId, campaignName, personId }) => {
  const dispatch = useDispatch();
  const [isAllCarsShown, setIsAllCarsShown] = useState<boolean>(campaignName === CAMPAIGN_NAMES.FOLLOW_UP);

  const cars:ICar[] = useSelector(state => getClientCarsSelector(state, personId));
  const getCars = useCallback(() => dispatch(getClientCarsAction({ force: true, personId })), [dispatch]);

  const car = useMemo(
    () => {
      const carByApplicationId = _.find(cars, ({ applicationIds }) => _.includes(applicationIds, applicationId));
      if (_.isEmpty(carByApplicationId)) return null;
      return _.pick(carByApplicationId, CAR_FIELDS);
    },
    [cars, applicationId],
  );

  useEffect(() => {
    getCars()
  }, []);

  const items = useMemo(() => (_.compact(isAllCarsShown
      ? [car, ..._.reject(cars, { id: car?.id })]
      : [car])),
    [cars, car, isAllCarsShown]);

  return (
    <Wrapper>
      <Controls>
        <StyledSwitch
          checked={isAllCarsShown}
          checkedChildren='Все авто'
          onChange={setIsAllCarsShown}
          unCheckedChildren='Авто по заявке из договора'
        />
      </Controls>
      {_.isEmpty(cars) && (
        <Empty description='Авто не найдено' />
      )}
      {(!_.isEmpty(cars) && _.isEmpty(car) && !isAllCarsShown) && (
        <Empty description='Авто по заявке из договора не найдено' />
      )}
      {!_.isEmpty(items) && (
        <Items>
          {_.map(items, (item, index) => {
            if (_.isEmpty(item)) return null;
            return (
              <ItemWrapper $isHighlighted={item?.id === car?.id} key={index}>
                <CarCard
                  car={item}
                  isCollapsed={false}
                  isEditHidden
                  isHighlighted={item?.id === car?.id}
                />
              </ItemWrapper>
            )
          })}
        </Items>
      )}
    </Wrapper>
  );
};
