import _ from 'lodash';

import TEST_ATTRS from 'constants/TEST_ATTRIBUTES';

import { getAllowedCampaigns } from 'models/dictionaries/selectors';
import { ROLE_NAMES } from 'models/roles/constants';

import { ACTION_ROLES } from 'pages/PageAdmin/constants';

export const NAV = [
  {
    name         : `Клиенты`,
    path         : `/client`,
    testAttribute: TEST_ATTRS.NAV_BAR_CLIENTS_TAB,
  },
  {
    name : `Верификация`,
    path : `/verification`,
    roles: [
      ROLE_NAMES.VERIFICATION_READ,
      ROLE_NAMES.VERIFICATION_SEND,
      ROLE_NAMES.VERIFICATION_WRITE,
    ],
    testAttribute: TEST_ATTRS.NAV_BAR_VERIFICATION_TAB,
  },
  {
    name : `Продажи`,
    path : `/sale`,
    roles: [
      ROLE_NAMES.SALE_READ,
      ROLE_NAMES.SALE_SEND,
    ],
    testAttribute: TEST_ATTRS.NAV_BAR_SALE_TAB,
  },
  {
    name            : `Задачи`,
    path            : `/tasks`,
    checkIsAvailable: state => !_.isEmpty(getAllowedCampaigns(state)),
    roles           : [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.COLLECTION_READ,
      ROLE_NAMES.COLLECTION_WRITE,
      ROLE_NAMES.SALE_ADMIN,
      ROLE_NAMES.SALE_READ,
      ROLE_NAMES.SALE_WRITE,
    ],
    testAttribute: TEST_ATTRS.NAV_BAR_TASKS_TAB,
  },
  {
    name : `Банкроты`,
    path : `/bankrupt`,
    roles: [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.COLLECTION_READ,
      ROLE_NAMES.COLLECTION_WRITE,
      ROLE_NAMES.SALE_ADMIN,
      ROLE_NAMES.SALE_READ,
      ROLE_NAMES.SALE_WRITE,
    ],
    testAttribute: TEST_ATTRS.NAV_BAR_BANKRUPT_TAB,
  },
  {
    name : `Суды`,
    path : `/court`,
    roles: [
      ROLE_NAMES.COLLECTION_ADMIN,
      ROLE_NAMES.COLLECTION_READ,
      ROLE_NAMES.COLLECTION_WRITE,
      ROLE_NAMES.SALE_ADMIN,
      ROLE_NAMES.SALE_READ,
      ROLE_NAMES.SALE_WRITE,
    ],
    testAttribute: TEST_ATTRS.NAV_BAR_COURT_TAB,
  },
  {
    name : `Лиды`,
    path : `/leads`,
    roles: [
      ROLE_NAMES.LEAD_EDIT,
      ROLE_NAMES.LEAD_ADMIN,
    ],
    testAttribute: TEST_ATTRS.NAV_BAR_LEADS_TAB,
  },
  {
    name         : `Инфо`,
    path         : `/info`,
    testAttribute: TEST_ATTRS.NAV_BAR_INFO_TAB,
  },
  {
    name : `Администрирование`,
    path : `/admin`,
    roles: [
      ROLE_NAMES.CAR_BLACK_LIST_UPLOADER,
      ROLE_NAMES.DICTIONARY_EDITOR,
      ROLE_NAMES.DICTIONARY_VIEWER,
      ROLE_NAMES.PARTNER_EDITOR,
      ROLE_NAMES.PARTNER_REQUISITE_EDITOR,
      ROLE_NAMES.PARTNER_REQUISITE_VIEWER,
      ROLE_NAMES.PARTNER_VIEWER,
      ROLE_NAMES.SMS_GATEWAY_OPERATOR,
      ROLE_NAMES.SMS_LIST_SENDER,
      ROLE_NAMES.VERIFICATION_ADMIN,
      ...ACTION_ROLES,
    ],
    testAttribute: TEST_ATTRS.NAV_BAR_ADMIN_TAB,
  },
];
