import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(getState, ({ items }) => items);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);

export const getTableState = createSelector(getState, ({ tableState }) => tableState);

export const getRequestParams = createSelector(
  getTableState,
  tableState => {
    const { filters, pagination, sorter } = tableState || {};
    const [lastName, firstName, patronymicName] = _.split(_.trim(filters?.clientFullName?.[0]), ` `);

    return _.omitBy({
      caseNumber        : filters?.caseNumber ? filters.caseNumber[0] : null,
      contractNumber    : filters?.contractNumber ? filters.contractNumber[0] : null,
      financialManager  : filters?.financialManager ? filters.financialManager[0] : null,
      firstName         : firstName || null,
      lastBankruptIdType: filters?.lastBankruptIdType ? filters.lastBankruptIdType[0] : null,
      lastName          : lastName || null,
      loanIds           : filters?.loanId ? [filters.loanId] : null,
      operatorName      : filters?.operatorName ? filters.operatorName[0] : null,
      patronymicName    : patronymicName || null,
      personIds         : filters?.personId ? filters.personId : null,
      productIds        : filters?.productId ? filters.productId : null,
      ..._.reduce(
        [
          `bankruptCreateDtm`,
          `bankruptDecisionDtm`,
          `bankruptEndDtm`,
          `commersantDtm`,
          `courtTaktikumRtkDtm`,
          `lastBankruptIdDate`,
          `loanCreateDtm`,
          `mfoInDtm`,
          `registryInclusionDtm`,
          `rtkDtm`,
          `szDtm`,
          `updateDtm`,
        ],
        (result, key) => {
          if (!filters?.[key] || _.isEmpty(filters?.[key])) return result;
          return {
            ...result,
            [`${key}From`]: filters[key][0],
            [`${key}To`]  : filters[key][1],
          };
        },
        {},
      ),
      sort: _.isEmpty(sorter)
        ? null
        : {
          [sorter.columnKey]: {
            order: sorter.order === `ascend` ? `ASC` : `DESC`,
            index: 0,
          },
        },
      ...(_.isEmpty(pagination) || _.isNil(pagination)
        ? {}
        : {
          limit : pagination.pageSize,
          offset: pagination.pageSize * (pagination.current - 1) || null,
        }
      ),
    }, _.isNil);
  },
);
