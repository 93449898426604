import styled from 'styled-components';
import { palette } from 'styles/theme';

export const InputIconButton = styled.button`
  padding: 8px;
  cursor: pointer;
  transition: background 200ms ease;
  border-radius: 8px;
  border: none;
  background: transparent;

  .anticon {
    color: ${palette.black60Color};
    transition: color 200ms ease;
    font-size: 16px;
  }

  :not(:last-child) {
    margin-right: 8px;
  }

  :hover {
    background: ${palette.primary5Color};

    .anticon {
      color: ${palette.primary500Color};
    }
  }

  :disabled {
    cursor: not-allowed;
  }
`;
