import * as React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

interface IComponentProps {
  hotkey: string;
  callback(): void;
}

export const HotkeyListener: React.FC<IComponentProps> = ({ callback, hotkey }) => {
  useHotkeys(hotkey, callback, {}, [hotkey]);

  return null;
};
