import * as React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { CopyIconButton } from 'components/CopyIconButton/CopyIconButton';
import { entityCardRowTextMixin } from 'pages/PageClient/Single/components/EntityCard/EntityCard';

const CopyButton = styled(CopyIconButton)``;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;


  @media(min-width: ${breakpoints.lg}) {
    ${CopyButton}  {
      opacity: 0;
    }

    :hover {
      ${CopyButton}  {
        opacity: 1;
      }
    }
  }
`;

const cellMixin = css`
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;

  @media(min-width: ${breakpoints.lg}) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const TitleCell = styled.div`
  ${cellMixin};
  background: ${palette.black10Color};
  padding-left: 16px;
`;

const Title = styled.span`
  ${entityCardRowTextMixin};
  color: ${palette.black80Color};
`;

const ValueCell = styled.div`
  ${cellMixin};

  padding-left: 16px;
  padding-right: 8px;
`;

const Value = styled.div`
  ${entityCardRowTextMixin};

  color: ${palette.black100Color};
  display: flex;

  @media(min-width: ${breakpoints.lg}) {
    margin-left: 16px;
  }
`;

interface IComponentProps {
  children?: React.ReactNode;
  className?: string;
  title: string;
  valueToCopy?: string | number;
}

export const EntityDetailsRowHorizontal: React.FC<IComponentProps> = props => {
  const { children, className, title, valueToCopy } = props;

  return !_.isNil(children) && (
    <Wrapper className={className}>
      <TitleCell>
        <Title>
          {title}:
        </Title>
      </TitleCell>
      <ValueCell>
        <Value>
          {children}

          {Boolean(valueToCopy) && ( // @ts-ignore
            <CopyButton valueToCopy={valueToCopy} />
          )}
        </Value>
      </ValueCell>
    </Wrapper>
  );
};
