import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from "styles/theme";
import {
  downloadBlob,
  formatDate,
  getColumn,
  showError,
} from 'helper';

import { getDoc as getDocApi } from 'models/clients/api';
import { SIGN_DOCUMENT_STATUSES } from 'models/clients/constants';
import { Tooltip, } from 'antd';
import { SignDocumentActionControls } from "pages/PageClient/Single/Docs/SignDocuments/SignDocumentActionControls";

const DocLink = styled.span`
  color: ${palette.primary500Color};
  cursor: pointer;
  text-decoration: underline;
`;


// NEW, DRAFT, ERROR
export const CLOSE_BUTTON_STATUSES = [1, 2, 6, 999];

export const COLUMNS = ({
  closeSignDocument,
  finalizeSignDocument,
  fileStatuses,
  isCloseSignDocumentButton,
  isFinalizeDocumentButton,
}) => [
  getColumn({
    key   : `documentName`,
    render: (text, { documentName, fileStatusId, url }) => (
      _.includes(
        [
          ...SIGN_DOCUMENT_STATUSES.DRAFT,
          ...SIGN_DOCUMENT_STATUSES.NEW,
          ...SIGN_DOCUMENT_STATUSES.SIGNED
        ],
        fileStatusId
      )
        ? (
          <DocLink
            onClick={async () => {
              const defaultError = `Не удалось скачать документ`;
              try {
                const docUrl = `/proxy${_.split(url, `/api`)?.[1]}`;
                const { data, status } = await getDocApi(docUrl);
                if (status !== 200 || !data) throw new Error(defaultError);
                downloadBlob(new Blob([data]), `${documentName}.${data?.doc?.extension || `pdf`}`);
              } catch (error) {
                showError({ defaultError, error });
              }
            }}
          >
            {documentName}
          </DocLink>
        )
        : documentName),
    title: `Название`,
  }),
  getColumn({
    key   : `fileStatusId`,
    render: id => {
      const status = _.find(fileStatuses, { id });
      if (!status) return null;
      if (status.description) {
        return (
          <Tooltip placement='left' title={status.description}>
            {status.name}
          </Tooltip>
        );
      }
      return status.name;
    },
    title: `Статус`,
    width: `22%`,
  }),
  getColumn({
    key   : `createDate`,
    render: v => formatDate(new Date(v)),
    title : `Дата создания`,
    width : `22%`,
  }),
  ...((isFinalizeDocumentButton || isCloseSignDocumentButton) ? [{
    key   : `id`,
    width : 50,
    title : `Действия`,
    align  : `center`,
    render: ({ id, fileStatusId }) => (
      <SignDocumentActionControls
        closeSignDocument={closeSignDocument}
        fileStatusId={fileStatusId}
        id={id}
        finalizeSignDocument={finalizeSignDocument}
        isCloseSignDocumentButton={isCloseSignDocumentButton}
        isFinalizeDocumentButton={isFinalizeDocumentButton}
      />
    )
  }] : []),
];
