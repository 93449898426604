import styled from 'styled-components';
import { palette } from 'styles/theme';

import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
} from 'antd';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const StyledCheckbox = styled(Checkbox)`
  flex: 1;
`;
export const Counter = styled.div<{ $alert?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ $alert, theme }) => ($alert ? theme.palette.alert600Color : theme.palette.white60Color)};
`;
export const Controls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;

  @media (max-width: 390px) {
    flex-direction: column;
    align-items: flex-end;

    ${StyledCheckbox} {
      margin-bottom: 16px;
      margin-top: -8px;
    }
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const TopWrapper = styled(ButtonsWrapper)`
  margin-bottom: 16px;
`;
export const StyledButton = styled(Button)`
  &+& {
    margin-left: 8px;
  }
`;
export const UploadButton = styled(Button)`
  margin-right: 8px;
  width: 200px;
`;
export const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  margin-right: 8px;
  color: ${palette.primaryColor};
  font-size: 20px;
`;
export const GreenText = styled.div`
  color: green;
`;
export const RedText = styled.div`
  color: red;
`;
export const StyledLi = styled.li`
  color: #40BAB5;
  text-decoration: underline;
  cursor: pointer;
`;
