import * as React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import {
  getAllowedBlockCampaigns,
  getAllowedCampaignBlocks,
  getAllowedCampaigns,
} from 'models/dictionaries/selectors';

import {
  Button as ButtonDefault,
  Select,
} from 'antd';
import { SubHeader } from 'components/SubHeader/SubHeader';
import TaskControls from 'components/TaskControls';

const CampaignSelect = styled(Select)`
  min-width: 200px;
  max-width: 200px;
  margin-right: 16px;
  margin-bottom: 0;

  @media(max-width: ${breakpoints.md}) {
    min-width: calc(50% - 8px);
    max-width: calc(50% - 8px);
    margin-bottom: 16px;

    &+& {
      margin-right: 0;
    }
  }

  @media(max-width: ${breakpoints.sm}) {
    min-width: 100%;
    margin-right: 16px;
    &+& {
      margin-bottom: 8px;
    }
  }
`;

const Button = styled(ButtonDefault)`
  @media(max-width: ${breakpoints.md}) {
    margin-top: 8px;
    width: calc(100% - 44px);
  }
`;

interface IComponentProps {
  campaignId?: number;
  campaignName?: string;
  selectedRowKeys?: number[];
  tasks?: { id: number }[];
  onChange(value: string): void;
}

export const TaskPluralHeader: React.FC<IComponentProps> = ({
  campaignId,
  campaignName,
  onChange,
  selectedRowKeys,
  tasks,
}) => {
  const blocks = useSelector(getAllowedCampaignBlocks);
  const campaigns = useSelector(getAllowedCampaigns);
  const currentBlock = _.get(_.find(campaigns, ({ name }) => name === campaignName), `block`, campaignName);
  const blockCampaigns = useSelector(state => getAllowedBlockCampaigns(state, currentBlock));
  const blocksOptions = _.map(blocks, _block => ({ value: _block, label: _block }));

  const campaignsOptions = _.map(
    _.isEmpty(blockCampaigns) ? [] : [...blockCampaigns, { name: `all` }],
    ({ description, name }) => ({ value: name, label: description || name }),
  );

  return (
    <SubHeader>
      <CampaignSelect
        disabled={_.isEmpty(blocks)}
        onChange={_value => onChange(_.get(_.find(campaigns, { block: _value }), `name`))}
        options={blocksOptions}
        value={currentBlock}
      />
      <CampaignSelect
        disabled={_.isEmpty(campaignsOptions)}
        // @ts-ignore
        onChange={onChange}
        options={campaignsOptions}
        value={campaignName}
      />
      <Button onClick={() => onChange(`all`)} type='primary'>Сбросить фильтры</Button>
      <TaskControls
        campaignId={campaignId}
        campaignName={campaignName}
        selectedRowKeys={selectedRowKeys}
        tasks={tasks}
      />
    </SubHeader>
  );
};
