import { Checkbox } from 'antd';

const BOOL_FIELD = (key, title, restProps = {}) => ({
  key,
  title,
  Component    : Checkbox,
  labelAsChild : true,
  valuePropName: `checked`,
  ...restProps,
});

export default BOOL_FIELD;
