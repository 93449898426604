import * as React from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
  shadows,
} from 'styles/theme';

import {
  ClockCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import {
  EMOJI_LIST,
  EMOJI_RECENTLY_MAX,
} from 'components/Messenger/Input/constants';
import { Scrollbars } from 'react-custom-scrollbars';

const EmojiClose = styled(Button)`
  border: none;
  box-shadow: none;
  color: #a6a6a6;
  position: absolute;
  right: 12px;
  top: 15px;
  z-index: 1;

  &:hover,
  &:focus {
    box-shadow: ${shadows.basic};
    color: #a6a6a6;
  }
`;

const EmojiList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  font-size: 24px;
`;

const Wrapper = styled.div<{ noEmojiRecently?: boolean }>`
  ${props => props.noEmojiRecently && `
    ${EmojiClose} {
      top: 5px;
      right: 2px;
    }

    ${EmojiList} {
      padding-top: 20px;
    }
  `}
`;

const StyledScrollbars = styled(Scrollbars)`
  color: ${palette.blackestColor};
  height: 250px !important;
  width: 100% !important;

  @media(min-width: ${breakpoints.md}) {
    height: 450px !important;
    width: 322px !important;
  }
`;

const EmojiRecently = styled(ClockCircleOutlined)`
  color: #a6a6a6;
  font-size: 20px;
  margin: 10px 0;
  width: 38px;
`;

const Emoji = styled.li`
  flex-shrink: 0;
  margin-right: 8px;
`;

const EmojiButton = styled(Button)`
  align-items: center;
  border-radius: 6px;
  color: #000;
  display: flex;
  font-size: inherit;
  height: auto;
  max-height: 40px;
  max-width: 40px;
  padding: 0 6px;

  &:hover,
  &:focus {
    background-color: ${palette.white150Color};
    color: #000;
  }
`;

const EmojiDelimiter = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 5px 0;
`;

interface IComponentProps {
  onClose?: () => void;
  onSelect: (emoji: string) => void;
}

export const InputEmojiPicker: React.FC<IComponentProps> = ({ onClose, onSelect }) => {
  const [emojiRecently, setEmojiRecently] = React.useState([]);

  // @ts-ignore
  const handleSelect = emoji => {
    onSelect(emoji);

    const nextEmojiRecently = _.slice(_.uniq(_.concat([emoji], emojiRecently)), 0, EMOJI_RECENTLY_MAX);
    // @ts-ignore
    setEmojiRecently(nextEmojiRecently);
    localStorage.setItem(`emoji`, _.join(nextEmojiRecently, `,`));
  };

  const noEmojiRecently = _.isEmpty(emojiRecently);

  useEffect(() => {
    const recently = _.compact(_.split(localStorage.getItem(`emoji`), `,`));
    // @ts-ignore
    setEmojiRecently(recently);
  }, []);

  return (
    <Wrapper noEmojiRecently={noEmojiRecently}>
      <EmojiClose
        icon={<CloseOutlined />}
        onClick={onClose}
        shape='circle'
        // @ts-ignore
        type='ghost'
      />

      <StyledScrollbars autoHide>
        {!_.isEmpty(emojiRecently) && (
          <>
            <EmojiRecently />
            <EmojiList>
              {_.map(emojiRecently, emoji => (
                <Emoji key={emoji}>
                  <EmojiButton onClick={() => handleSelect(emoji)} type='text'>{emoji}</EmojiButton>
                </Emoji>
              ))}
            </EmojiList>
            <EmojiDelimiter />
          </>
        )}

        <EmojiList>
          {_.map(EMOJI_LIST, emoji => (
            <Emoji key={emoji}>
              <EmojiButton onClick={() => handleSelect(emoji)} type='text'>{emoji}</EmojiButton>
            </Emoji>
          ))}
        </EmojiList>
      </StyledScrollbars>
    </Wrapper>
  );
};
