import _ from 'lodash';
import moment from 'moment';

import {
  PHONE,
  REQUIRED,
} from 'constants/FORM_VALIDATION_RULES';
import {
  normalizeMomentToStartOfDay,
  validateEmail,
  validatePassportNumber,
  validatePassportSerial,
  validateSubDivisonCode,
} from 'helper';

import { TRoleName } from 'models/roles/constants';

import {
  Checkbox,
  DatePicker,
  Input,
  Select,
} from 'antd';
import InputSuggestions from 'components/InputSuggestions';
import { ICreateModalFormField } from 'components/Modal/Create/helpers';

export const VALIDATE_DATE_RULE = {
  validator: (pass: any, v: any) => {
    if (!v) {
      return Promise.reject(new Error(`Обязательное поле`));
    } if (!moment.isMoment(v)) {
      return Promise.reject(new Error(`Некорректная дата`));
    } return Promise.resolve();
  },
};

export const VALIDATE_NAME_RULE = (required = true) => ({
  validator: (pass: any, v: string) => {
    if ((required || v) && !/^[а-яА-ЯёЁ]+[\s-]?[а-яА-ЯёЁ]+$/gi.test(v)) {
      return Promise.reject(new Error(`Русские буквы`));
    }
    return Promise.resolve();
  },
});

// @ts-ignore
export const FORM_FIELDS: (({ genders: any }) => ICreateModalFormField[]) = ({ genders }) => [
  {
    Component: Input,
    key      : `lastName`,
    rules    : [VALIDATE_NAME_RULE()],
    title    : `Фамилия`,
  },
  {
    Component: Input,
    key      : `firstName`,
    rules    : [VALIDATE_NAME_RULE()],
    title    : `Имя`,
  },
  {
    Component: Input,
    key      : `patronymicName`,
    rules    : [VALIDATE_NAME_RULE(false)],
    title    : `Отчество`,
  },
  {
    Component    : Checkbox,
    key          : `noPatronymicFlg`,
    labelAsChild : true,
    title        : `Нет отчества`,
    valuePropName: `checked`,
    fieldWatchers: [{
      fieldToChange: `patronymicName`,
      handler      : newValue => ({ disabled: !!newValue }),
    }],
  },
  {
    Component: Select,
    key      : `genderId`,
    options  : genders,
    rules    : [
      {
        validator: (pass, v) => {
          if (!v) {
            return Promise.reject(new Error(`Обязательное поле`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `Пол`,
  },
  {
    Component: Input,
    key      : `birthPlace`,
    rules    : [
      {
        validator: (pass, v) => {
          if (!/^[а-яА-Я0-9\s./\-—−–:,()]+$/gi.test(v)) {
            return Promise.reject(new Error(`Русские буквы, “-” (дефис), “.” (точка), “,” (запятая), “() (скобки)”`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `Место рождения`,
  },
  {
    Component            : DatePicker,
    className            : `date-picker date-picker_wide`,
    format               : `DD.MM.YYYY`,
    key                  : `birthDtm`,
    normalizeOnSubmit    : normalizeMomentToStartOfDay,
    rules                : [VALIDATE_DATE_RULE],
    showToday            : false,
    title                : `Дата рождения`,
    transformInitialValue: (birthDtm: moment.MomentInput) => (moment.isMoment(birthDtm)
      ? birthDtm
      : (birthDtm ? moment(birthDtm) : ``)),
  },
  {
    Component            : Input,
    key                  : `phones`,
    rules                : [PHONE],
    title                : `Телефон`,
    transformInitialValue: (phones: any) => _.replace(
      _.get(_.find(phones, { phoneTypeId: 1 }), `phone`, ``),
      /\D/gi,
      ``,
    ),
  },
  {
    Component: Input,
    key      : `email`,
    rules    : [
      {
        validator: (pass, v) => {
          if (v && !validateEmail(v)) {
            return Promise.reject(new Error(`Введите корректный email`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `Email`,
  },
  {
    Component: Input,
    key      : `passportNumber`,
    rules    : [
      {
        validator: (pass, v) => {
          if (!validatePassportNumber(v)) {
            return Promise.reject(new Error(`6 цифр`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `Номер паспорта`,
  },
  {
    Component: Input,
    key      : `passportSerial`,
    rules    : [
      {
        validator: (pass, v) => {
          if (!validatePassportSerial(v)) {
            return Promise.reject(new Error(`4 цифры`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `Серия паспорта`,
  },
  {
    Component: Input,
    key      : `passportIssuer`,
    rules    : [
      {
        validator: (pass, v) => {
          if (!/^[а-яА-Я0-9\s.\W]+$/gi.test(v)) {
            return Promise.reject(new Error(`Буквы, цифры, знаки пунктуации`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `Кем выдан`,
  },
  {
    Component            : DatePicker,
    className            : `date-picker date-picker_wide`,
    format               : `DD.MM.YYYY`,
    key                  : `passportIssueDtm`,
    normalizeOnSubmit    : normalizeMomentToStartOfDay,
    rules                : [VALIDATE_DATE_RULE],
    showToday            : false,
    title                : `Дата выдачи паспорта`,
    transformInitialValue: (passportIssueDtm: moment.MomentInput) => (moment.isMoment(passportIssueDtm)
      ? passportIssueDtm
      : (passportIssueDtm ? moment(passportIssueDtm) : ``)),
  },
  {
    Component: Input,
    key      : `passportSubDivisionCode`,
    rules    : [
      {
        validator: (pass, v) => {
          if (!validateSubDivisonCode(v)) {
            return Promise.reject(new Error(`Цифры и дефис`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `Код подразделения`,
  },
  {
    Component: Input,
    key      : `inn`,
    rules    : [
      {
        validator: (pass, v) => {
          if (v && !/[0-9]{10,}/gi.test(v)) {
            return Promise.reject(new Error(`Неверный ИНН`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `ИНН`,
  },
  {
    Component: Input,
    key      : `driverLicense`,
    rules    : [
      {
        validator: (pass, v) => {
          if (v && !/[0-9a-zA-Z\s]/gi.test(v)) {
            return Promise.reject(new Error(`Неверный номер`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `Водительское удостоверение`,
  },
  {
    Component            : DatePicker,
    format               : `DD.MM.YYYY`,
    key                  : `driverLicenseDtm`,
    normalizeOnSubmit    : normalizeMomentToStartOfDay,
    title                : `Дата выдачи ВУ`,
    transformInitialValue: (driverLicenseDtm: moment.MomentInput) => (moment.isMoment(driverLicenseDtm)
      ? driverLicenseDtm
      : (driverLicenseDtm ? moment(driverLicenseDtm) : ``)),
  },
  {
    Component: Input,
    key      : `snils`,
    rules    : [
      {
        validator: (pass, v) => {
          if (v && !/[0-9]/gi.test(v)) {
            return Promise.reject(new Error(`Неверный СНИЛС`));
          }
          return Promise.resolve();
        },
      },
    ],
    title: `СНИЛС`,
  },
];

export const EDIT_WORK_FORM_FIELDS = [
  {
    Component: Input,
    key      : `organizationName`,
    title    : `Название организации`,
    rules    : [REQUIRED],
  },
  {
    Component: Input,
    key      : `organizationPosition`,
    title    : `Должность`,
    rules    : [REQUIRED],
  },
  {
    Component: Input,
    key      : `organizationPhone`,
    title    : `Телефон`,
    rules    : [PHONE, REQUIRED],
  },
  {
    Component    : Checkbox,
    key          : `organizationOfficialFlg`,
    title        : `Устроен официально`,
    labelAsChild : true,
    valuePropName: `checked`,
    rules        : [REQUIRED],
  },
];

export const EDIT_ADDRESS_FORM_FIELDS = [
  {
    Component: Input,
    hidden   : true,
    key      : `addressId`,
  },
  {
    Component        : InputSuggestions,
    dadata           : `address`,
    key              : `address`,
    normalizeOnSubmit: (v: any) => _.get(v, `value`),
    title            : `Адрес`,
    rules            : [
      REQUIRED,
      ({ getFieldValue }: { getFieldValue: any }) => ({
        validator: (pass: any, v: { data: any; value: any; }) => {
          if (!_.has(v, `data`) || !_.has(v, `value`)) {
            return Promise.reject(new Error(`Необходимо выбрать адрес из подсказок`));
          }
          const { data, value } = v;

          if (_.isEmpty(data) || !value) return Promise.reject(new Error(`Неверный адрес`));

          const {
            block,
            city,
            flat,
            house,
            settlement,
          } = data;

          const isNoFlat = getFieldValue(`isNoFlat`);

          if (!city && !settlement) return Promise.reject(new Error(`Неверный адрес (нет города или нас.пункта)`));
          if (!house && !block) return Promise.reject(new Error(`Неверный адрес (нет дома или корпуса)`));
          if (!isNoFlat && !flat) return Promise.reject(new Error(`Неверный адрес (нет квартиры)`));
          return Promise.resolve();
        },
      }),
    ],
  },
  {
    Component    : Checkbox,
    key          : `isNoFlat`,
    labelAsChild : true,
    title        : `Квартиры нет`,
    valuePropName: `checked`,
  },
];

export const getAllowedForTitle = (allowedRoles: TRoleName[]) => `Требуется одна из ролей: ${allowedRoles.join(`, `)}`;
