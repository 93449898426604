import _ from 'lodash';

import { prettifyAmount } from 'helper';

export const getStatusesToSelectFiltered = ({ statusesToSelect: _statusesToSelect, taskStatus = {} }) => {
  let statusesToSelect = _statusesToSelect;
  if (!_.isEmpty(taskStatus.possibleNextStatusIds)) return statusesToSelect;
  if (taskStatus.isOpen || (!taskStatus.isOpen && !taskStatus.isTerminal)) {
    statusesToSelect = _.reject(statusesToSelect, `isOpen`);
  } else {
    statusesToSelect = _.filter(statusesToSelect, `isTerminal`);
  }

  return statusesToSelect;
};

export const parseCreditHistory = (creditHistory, fields) => _.reduce(creditHistory, (result, _value, key) => {
  const field = _.find(fields, { name: key });
  let value = _value;
  if (field) {
    const { type } = field;

    if (value === `undefined`) {
      value = null;
    } else if (type === `amount`) {
      value = prettifyAmount(_.round(value));
    } else if (type === `boolean`) {
      value = _.toNumber(value) ? `Да` : `Нет`;
    }
  }
  if (value && value !== `undefined`) {
    result.push({
      label: _.get(field, `description`, key),
      value,
    });
  }

  return result;
}, []);

/**
 * Возвращает цвет, соответствующий указанному сегменту.
 * @param {string} segment - Сегмент, для которого нужно определить цвет.
 * @returns {string|undefined} Цвет сегмента или undefined, если сегмент не найден или некорректен.
 */
export const getSegmentColor = segment => {
  // Проверка на пустой или неверный сегмент
  if (!segment || typeof segment !== `string`) return;

  // Приведение сегмента к нижнему регистру и удаление лишних пробелов
  const segmentSanitized = _.trim(_.toLower(segment));
  if (!segmentSanitized) return;

  // Маппинг сегментов на цвета
  const colorMap = {
    [`желт`]  : `gold`, // Все вариации желтого
    [`жёлт`]  : `gold`, // Все вариации желтого
    [`yellow`]: `gold`, // Желтый (английский)
    [`blue`]  : `blue`, // Синий
    [`синий`] : `blue`, // Синий
    [`синие`] : `blue`, // Синий
    [`красн`] : `red`, // Красный
    [`red`]   : `red`, // Красный (английский)
    [`зелен`] : `green`, // Зеленый
    [`green`] : `green`, // Зеленый (английский)
  };

  // Проверка каждого сегмента на соответствие цвету в colorMap
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(colorMap)) {
    if (segmentSanitized.includes(key)) {
      return value; // Возвращаем цвет, если сегмент найден
    }
  }
};
