import * as React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { copyToClipboard } from 'helper';

import useMobileContext from 'hooks/useMobileContext';

import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const Wrapper = styled.div`
  padding: 4px 8px;
  margin: -4px -8px -4px 0;

  cursor: pointer;
  color: ${palette.black80Color};
  transition: opacity 200ms ease;

  :hover {
    color: ${palette.primary500Color};
  }

  :active {
    color: ${palette.primary500Color};
  }

  @media(min-width: ${breakpoints.lg}) {
    padding-top: 8px;
    margin-top: -8px;
    padding-bottom: 8px;
    margin-bottom: -8px;
  }
`;

interface IContentProps {
  as?: React.ElementType,
  className?: string;
  valueToCopy: string | number | JSX.Element;
}

interface IComponentProps {
  as?: React.ElementType,
  className?: string;
  title?: string;
  valueToCopy: string | number | JSX.Element;
}

const Content: React.FC<IContentProps> = React.memo(({ as, className, valueToCopy }) => (
  <Wrapper
    as={as}
    className={className}
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation();
      copyToClipboard(valueToCopy);
    }}
  >
    <CopyOutlined />
  </Wrapper>
));

export const CopyIconButton: React.FC<IComponentProps> = React.memo(({
  as,
  className,
  title = `Копировать`,
  valueToCopy,
}) => {
  const { mobile } = useMobileContext();

  return mobile
    ? <Content as={as} className={className} valueToCopy={valueToCopy} />
    : (
      <Tooltip title={title}>
        <Content as={as} className={className} valueToCopy={valueToCopy} />
      </Tooltip>
    );
});
