import * as React from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { useHotkeys } from 'react-hotkeys-hook';
import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import {
  openEditModal as openEditModalAction,
  removeLastCommunication as removeLastCommunicationAction,
  resetModal as resetModalAction,
  setModal as setModalAction,
} from 'models/communications/actions';
import { getModal as getModalSelector } from 'models/communications/selectors';

import useMobileContext from 'hooks/useMobileContext';

import {
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Button as ButtonDefault,
  Dropdown,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { Communications } from 'components/Modal/Communications/Communications';
import { getHotkeyTitle } from 'pages/Layout/GlobalHotkeys/helpers';

const Button = styled(ButtonDefault)<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 16px;
  padding: 8px 16px;

  ${p => p.$isMobile && `
      padding: 0;
      justify-content: center;
      background-color: ${getLightStyleOr(
    () => palette.whiteColor(p),
    () => palette.backgroundColor(p),
  )};
      color: ${palette.primaryColor(p)};
    `}
`;

interface IProps {}

const CommunicationToggle: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModalSelector);

  const openDraft = () => dispatch(setModalAction({ isOpen: true }));
  const openEditModal = () => dispatch(openEditModalAction());
  const openModal = (data?: any) => dispatch(resetModalAction({ isOpen: true, ...data }));
  const remove = () => dispatch(removeLastCommunicationAction());

  const hotkey = `shift+C`;
  useHotkeys(hotkey, () => openModal(), {}, []);

  const menuItems = [
    {
      label: `Продолжить черновик`,
      key  : `draft`,
      icon : <RollbackOutlined />,
    },
    {
      label: `Редактировать последнюю коммуникацию`,
      key  : `edit`,
      icon : <EditOutlined />,
    },
    {
      label : `Удалить последнюю коммуникацию`,
      key   : `remove`,
      icon  : <DeleteOutlined />,
      danger: true,
    },
  ];

  const onMenuClick = ({ key }: { key: string }) => {
    if (key === `draft`) openDraft();
    if (key === `edit`) openEditModal();
    if (key === `remove`) remove();
  };

  const { mobile } = useMobileContext();
  if (!modal?.personId && !modal?.isMttCall) return null;

  return (
    <DefaultTooltip title={`Создать коммуникацию ${getHotkeyTitle(hotkey)}`}>
      <Dropdown
        menu={{ items: menuItems, onClick: onMenuClick }}
        placement='bottomLeft'
        trigger={[`contextMenu`]}
      >
        <Button
          $isMobile={mobile}
          icon={<CommentOutlined />}
          onClick={() => openModal({ personId: modal.personId, taskId: modal.taskId })}
          shape={mobile ? `circle` : undefined}
          size={mobile ? `middle` : `large`}
          title='Создать коммуникацию'
          type={mobile ? `text` : `primary`}
        >
          {!mobile && `Создать коммуникацию`}
        </Button>
      </Dropdown>
      <Communications />
    </DefaultTooltip>
  );
};

export default CommunicationToggle;
