import React from 'react';
import _ from 'lodash';

import { prettifyNumber } from 'helper';

import { TEventlogItem } from 'models/eventlogs/types';
import { getInsuranceValue } from 'models/verifications/helpers';
import { TDecision } from 'models/verifications/types';

import useMobileContext from 'hooks/useMobileContext';

import {
  CheckCircleOutlined,
  LogoutOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Descriptions } from 'antd';
import { ItemHead } from 'components/Eventlog/Item/ItemHead';

import {
  Content,
  Description,
  Icon,
  Wrapper,
} from '../styled';

interface IComponentProps extends TEventlogItem {
  date:string;
  next?: TDecision;
  operatorName?:string;
  prev?: TDecision;
}

const getApprovedValue = (approved: boolean) => (approved ? `Одобрено` : `Отклонено`);

export const VerificationDecision: React.FC<IComponentProps> = ({ date, next, operatorName, prev }) => {
  const { mobile } = useMobileContext();
  if (_.isEmpty(prev) && _.isEmpty(next)) return null;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  if (_.isEqual(_.omitBy(prev, _.isNil), _.omitBy(next, _.isNil))) return null;
  if (next
    && next.approved
    && _.isEqual(_.omit(prev, [`declineReason`]), _.pick(next, [`declineReason`]))
  ) return null;
  if (next
    && !next.approved
    // @ts-ignore
    && next.declineReason === prev.declineReason
  ) return null;

  let color;
  let title;
  let icon;
  // @ts-ignore
  let descriptionItems;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  if (_.isEmpty(_.omitBy(prev, _.isNil))) {
    title = `Добавлено решение`;
    // @ts-ignore
    if (next.approved) {
      icon = <CheckCircleOutlined />;
      color = `green`;
      descriptionItems = _.compact([
        {
          label: `Решение`,
          value: `Одобрено`,
        },
        // @ts-ignore
        next.amount
          ? {
            label: `Сумма`,
            // @ts-ignore
            value: prettifyNumber(next.amount) || `?`,
          }
          : null,
        // @ts-ignore
        next.rate
          ? {
            label: `Ставка, %`,
            // @ts-ignore
            value: prettifyNumber(next.rate) || `?`,
          }
          : null,
        // @ts-ignore
        next.term
          ? {
            label: `Срок, мес`,
            // @ts-ignore
            value: prettifyNumber(next.term) || `?`,
          }
          : null,
        {
          label: `Страховка`,
          // @ts-ignore
          value: getInsuranceValue(next.insurance),
        },
        // @ts-ignore
        next.comment
          ? {
            label: `Комментарий`,
            // @ts-ignore
            value: next.comment || `?`,
          }
          : null,
      ]);
    } else {
      color = `red`;
      icon = <StopOutlined />;
      descriptionItems = _.compact([
        {
          label: `Решение`,
          // @ts-ignore
          value: getApprovedValue(next.approved),
        },
        // @ts-ignore
        next.declineReason
          ? {
            label: `Причина отказа`,
            // @ts-ignore
            value: next.declineReason,
          }
          : null,
        // @ts-ignore
        next.comment
          ? {
            label: `Комментарий`,
            // @ts-ignore
            value: next.comment,
          }
          : null,
      ]);
      icon = <StopOutlined />;
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
  } else if (_.isEmpty(_.omitBy(next, _.isNil))) {
    title = `Удалено решение`;
    color = `red`;
    descriptionItems = [];
    icon = <LogoutOutlined />;
  } else {
    title = `Изменено решение`;
    color = `orange`;
    // @ts-ignore
    if (prev.approved || next.approved) {
      descriptionItems = _.compact([
        {
          label: `Решение`,
          // @ts-ignore
          value: prev.approved === next.approved
            ? `Одобрено`
            // @ts-ignore
            : `${getApprovedValue(prev.approved)} -> ${getApprovedValue(next.approved)}`,
        },
        {
          label: `Сумма`,
          // @ts-ignore
          value: next.amount === prev.amount
            // @ts-ignore
            ? prettifyNumber(next.amount)
            // @ts-ignore
            : `${prettifyNumber(prev.amount) || `?`} -> ${prettifyNumber(next.amount) || `?`}`,
        },
        {
          label: `Ставка, %`,
          // @ts-ignore
          value: next.rate === prev.rate
            // @ts-ignore
            ? prettifyNumber(next.rate)
            // @ts-ignore
            : `${prettifyNumber(prev.rate) || `?`} -> ${prettifyNumber(next.rate) || `?`}`,
        },
        {
          label: `Срок, мес`,
          // @ts-ignore
          value: next.term === prev.term
            // @ts-ignore
            ? prettifyNumber(next.term)
            // @ts-ignore
            : `${prettifyNumber(prev.term) || `?`} -> ${prettifyNumber(next.term) || `?`}`,
        },
        {
          label: `Страховка`,
          // @ts-ignore
          value: next.insurance === prev.insurance
            // @ts-ignore
            ? getInsuranceValue(next.insurance)
            // @ts-ignore
            : `${getInsuranceValue(prev.insurance)} -> ${getInsuranceValue(next.insurance)}`,
        },
        // @ts-ignore
        (next.comment ? {
          label: `Комментарий`,
          // @ts-ignore
          value: next.comment === prev.comment
            // @ts-ignore
            ? next.comment
            // @ts-ignore
            : `${prev.comment || `?`} -> ${next.comment || `?`}`,
        } : null),
        // @ts-ignore
        (next.declineReason || prev.declineReason)
          ? {
            label: `Причина отказа`,
            // @ts-ignore
            value: next.declineReason === prev.declineReason
              // @ts-ignore
              ? next.declineReason
              // @ts-ignore
              : `${prev.declineReason || `?`} -> ${next.declineReason || `?`}`,
          }
          : null,
      ]);
      icon = <CheckCircleOutlined />;
    } else {
      descriptionItems = _.compact([
        {
          label: `Решение`,
          // @ts-ignore
          value: getApprovedValue(next.approved),
        },
        // @ts-ignore
        (next.comment ? {
          label: `Комментарий`,
          // @ts-ignore
          value: next.comment === prev.comment
            // @ts-ignore
            ? next.comment
            // @ts-ignore
            : `${prev.comment || `?`} -> ${next.comment || `?`}`,
        } : null),
        {
          label: `Причина отказа`,
          // @ts-ignore
          value: next.declineReason === prev.declineReason
            // @ts-ignore
            ? next.declineReason
            // @ts-ignore
            : `${prev.declineReason || `?`} -> ${next.declineReason || `?`}`,
        },
      ]);
      icon = <StopOutlined />;
    }
    // @ts-ignore
    color = next.approved ? `green` : `red`;
    // @ts-ignore
    icon = next.approved ? <CheckCircleOutlined /> : <StopOutlined />;
  }

  return (
    <Wrapper $isMobile={mobile}>
      <Icon $color={color}>{icon}</Icon>
      <Content>
        <ItemHead date={date} operatorName={operatorName} title={title} />
        {/*@ts-ignore*/}
        {!_.isEmpty(descriptionItems) && (
          <Description>
            <Descriptions
              bordered
              // @ts-ignore
              column={mobile ? 1 : _.size(descriptionItems)}
              layout={mobile ? `horizontal` : `vertical`}
              size='small'
            >
              {/*@ts-ignore*/}
              {_.map(descriptionItems, ({ label, value }) => (
                <Descriptions.Item key={label} label={label}>{value}</Descriptions.Item>
              ))}
            </Descriptions>
          </Description>
        )}
      </Content>
    </Wrapper>
  );
};
