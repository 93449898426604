import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import {
  formatDate,
  hasIntersection,
  prettifyNumber,
} from 'helper';

import { getClientApplicationsForDocs as getClientApplicationsForDocsSelector } from 'models/clients/selectors';
import { IDoc } from 'models/clients/types';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import useMobileContext from 'hooks/useMobileContext';

import {
  ContainerOutlined,
  DiffOutlined,
  FieldTimeOutlined,
  FileAddOutlined,
  FileProtectOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Select,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import {
  CREATE_SIGN_DOCUMENT_MODES,
  CREATE_SIGN_DOCUMENT_ROLES,
  UPLOAD_DOCUMENT_ROLES,
} from 'pages/PageClient/Single/Docs/constants';
import DownloadGroupControls from 'pages/PageClient/Single/Docs/DownloadGroupControls';

const SelectWrapper = styled.div`
  overflow: hidden;
`;

const ControlsSelect = styled(Select)`
  width: 100%;
`;

const ControlsButton = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
  min-width: 32px;

  @media(min-width: ${breakpoints.md}) {
    width: inherit;
    margin-bottom: 0;

    :not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: ${breakpoints.md}) {
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${ControlsButton} {
    :not(:last-child) {
      margin-right: 8px;
    }
  }

  @media(min-width: ${breakpoints.md}) {
    width: unset;

    ${ControlsButton} {
      width: 32px;

      :not(:last-child) {
        margin-right: 16px;
      }
    }
  }
`;

const StyledControls = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 16px;

  @media(min-width: ${breakpoints.md}) {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
`;
interface IProps {
  applicationId: string;
  docs: IDoc[];
  isSelectDisabled?: boolean;
  personId: string;
  openSignDocuments():void;
  openUploadHistory():void;
  openUploadModal():void;
  setApplicationId(applicationId:string):void;
  setCreateDocumentModalMode(mode:string):void;
  setIsCreateDocumentModalOpen(isOpen: boolean):void;
  setIsDocumentPackageDownloadModalOpen(isOpen: boolean):void;
}

const Controls: React.FC<IProps> = ({
  applicationId,
  docs,
  isSelectDisabled = false,
  openSignDocuments,
  openUploadHistory,
  openUploadModal,
  personId,
  setApplicationId,
  setCreateDocumentModalMode,
  setIsCreateDocumentModalOpen,
  setIsDocumentPackageDownloadModalOpen,
}) => {
  const { mobile } = useMobileContext();

  const applications = useSelector(state => getClientApplicationsForDocsSelector(state, personId));
  const userRoles = useSelector(getRoleNamesSelector);

  const canUpload = applicationId && hasIntersection(userRoles, UPLOAD_DOCUMENT_ROLES);
  const menu = useMemo(() => ({
    items: CREATE_SIGN_DOCUMENT_MODES.map(({ label, mode, roles }) => {
      const allAvailableRolesByMode = _.concat(CREATE_SIGN_DOCUMENT_ROLES, roles || []);
      const canCreateSignDocument = hasIntersection(userRoles, allAvailableRolesByMode);
      const createSignDocumentRolesString = _.join(allAvailableRolesByMode, `,`);
      return {
        disabled: !canCreateSignDocument,
        key     : mode,
        onClick : () => {
          setIsCreateDocumentModalOpen(true);
          setCreateDocumentModalMode(mode);
        },
        label: canCreateSignDocument ? label : `(доступно для ролей: ${createSignDocumentRolesString}`,
      };
    }),
  }), [CREATE_SIGN_DOCUMENT_MODES]);

  return (
    <StyledControls>
      <SelectWrapper>
        <ControlsSelect
          disabled={isSelectDisabled}
          // @ts-ignore
          onChange={setApplicationId}
          value={applicationId}
        >
          {_.map(applications, application => (
            <Select.Option key={application.id} value={application.id}>
              {`${application.id} от ${formatDate(application.applicationDtm)}, продукт: ${application.productName || application.productId}, запрошено: ${prettifyNumber(application.requestedLoan)}, статус: ${(application.applicationStatusName)}`} {/* eslint-disable-line max-len */}
            </Select.Option>
          ))}
        </ControlsSelect>
      </SelectWrapper>
      <ButtonsWrapper>
        <DownloadGroupControls
          applicationId={applicationId}
          buttonType='default'
          docs={docs}
          personId={personId}
        />
        <DefaultTooltip title='Формирование пакета ГАС'>
          <ControlsButton
            icon={<ContainerOutlined />}
            onClick={() => setIsDocumentPackageDownloadModalOpen(true)}
          >
            {mobile ? `Пакет ГАС` : ``}
          </ControlsButton>
        </DefaultTooltip>
        <ControlsButton
          disabled={!canUpload}
          icon={<DiffOutlined />}
          onClick={() => openUploadModal()}
        >
          Загрузить документ
        </ControlsButton>

        <Buttons>
          <DefaultTooltip placement='topRight' title='Журнал операторских загрузок документов по заявке'>
            <ControlsButton
              icon={<FieldTimeOutlined />}
              onClick={openUploadHistory}
            />
          </DefaultTooltip>

          <DefaultTooltip placement='topRight' title='Создать документ на подпись'>
            <Dropdown
              menu={menu}
              trigger={[`click`]}
            >
              <ControlsButton icon={<FileAddOutlined />} />
            </Dropdown>
          </DefaultTooltip>

          <DefaultTooltip placement='topRight' title='Просмотр файлов клиента на подписи'>
            <ControlsButton
              icon={<FileProtectOutlined />}
              onClick={openSignDocuments}
            />
          </DefaultTooltip>
        </Buttons>
      </ButtonsWrapper>
    </StyledControls>
  );
};

export default Controls;
