import {
  useCallback,
  useState,
} from 'react';

import { IPagesQty } from 'pages/PageClient/Single/Docs/types';

interface IUsePagesQty {
  (): {
    pagesQty: IPagesQty,
    setPagesQty: (pagesQty: number, index: number) => void
  };
}
export const usePagesQty:IUsePagesQty = () => {
  const [pagesQty, setStatePagesQty] = useState<IPagesQty>({});
  const setPagesQty = (index: number, qty: number) => {
    setStatePagesQty(prevState => ({
      ...prevState,
      [index]: qty,
    }));
  };

  return { pagesQty, setPagesQty };
};

interface IUseScale {
  (initialScale: number): {
    downscale: () => void,
    isDownscaleDisabled: boolean,
    isUpscaleDisabled: boolean,
    resetScale: () => void,
    scale: number,
    upscale: () => void,
  };
}
export const useScale:IUseScale = initialScale => {
  const [scale, setScale] = useState<number>(initialScale);

  const isDownscaleDisabled = scale === 0.25;
  const isUpscaleDisabled = scale === 16;

  const upscale = () => {
    if (!isUpscaleDisabled) setScale(scale * 2);
  };

  const downscale = () => {
    if (!isDownscaleDisabled) setScale(scale / 2);
  };

  const resetScale = () => setScale(initialScale);

  return {
    downscale,
    isDownscaleDisabled,
    isUpscaleDisabled,
    resetScale,
    scale,
    upscale,
  };
};

interface IUseRotation {
  (): {
    resetRotation: () => void;
    rotateLeft: () => void;
    rotateRight: () => void;
    rotation: number;
  }
}

export const useRotation: IUseRotation = () => {
  const initialRotation = 0;

  const [rotation, setRotation] = useState<number>(initialRotation);

  const rotateLeft = useCallback(() => setRotation(v => (v === -270 ? 0 : (v - 90))), []);
  const rotateRight = useCallback(() => setRotation(v => (v === 270 ? 0 : (v + 90))), []);
  const resetRotation = useCallback(() => setRotation(initialRotation), []);

  return { rotation, rotateRight, rotateLeft, resetRotation };
};
