import { css } from 'styled-components';
import { breakpoints } from 'styles/theme';

export const horizontalPaddingMixin = css`
  padding-left: 16px;
  padding-right: 16px;

  @media(min-width: ${breakpoints.md}) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media(min-width: ${breakpoints.lg}) {
    padding-left: 60px;
    padding-right: 60px;
  }
`;
