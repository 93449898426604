import React, { useState } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { getClientSurname as getClientSurnameSelector } from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';
import { getItemsForSelect as getDictionaryOptions } from 'models/dictionaries/selectors';
import { exportCourtNotifications as exportCourtNotificationsAction } from 'models/task/actions';
import { isLoading as isLoadingTaskSelector } from 'models/task/selectors';

import {
  Checkbox,
  Form,
  Modal,
  Radio,
  Select,
} from 'antd';

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

const registryOptions = [
  { value: true, label: `Создать новый реестр` },
  { value: false, label: `Добавить в текущий реестр` },
];

interface IValues {
  createRegistryFlg: boolean;
  imhaFlg : number;
  managerEmail : string;
  managerFullName : string;
  managerInt : string;
  registryTypeId : number | null;
  serviceFlg : number;
}

const defaultValues: IValues = {
  createRegistryFlg: true,
  imhaFlg          : 0,
  managerEmail     : ``,
  managerFullName  : ``,
  managerInt       : ``,
  registryTypeId   : null,
  serviceFlg       : 0,
};

interface IProps {
  personId?: string;
  taskIds: number[];
  type?: `legal` | `service`;
  visible: boolean;
  onClose(): void;
}

const LegalNotificationDownload: React.FC<IProps> = ({
  onClose,
  personId,
  taskIds,
  type = `legal`,
  visible,
}) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState<IValues>(defaultValues);
  const registryTypes = useSelector(state => getDictionaryOptions(state, `registryType`));
  const isTaskLoading = useSelector(isLoadingTaskSelector);
  const clientSurname:string = useSelector(state => getClientSurnameSelector(state, personId));
  const [isDictionaryLoading] = useDictionaries([`registryType`]);

  const setValue = (field: string, value: unknown) => setValues({ ...values, [field]: value });

  const onSubmit = () => {
    const { createRegistryFlg, imhaFlg, registryTypeId, serviceFlg } = values;
    dispatch(exportCourtNotificationsAction({
      callback: onClose,
      clientSurname,
      createRegistryFlg,
      imhaFlg,
      registryTypeId,
      serviceFlg,
      taskIds,
      type,
    }));
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      confirmLoading={isTaskLoading || isDictionaryLoading}
      okButtonProps={{ disabled: _.isNil(values.registryTypeId) }}
      okText='Сформировать'
      onCancel={onClose}
      onOk={onSubmit}
      open={visible}
      title='Формирование уведомлений'
    >
      <Form layout='vertical'>
        <Form.Item>
          <Radio.Group
            onChange={event => setValue(`createRegistryFlg`, event.target.value)}
            options={registryOptions}
            value={values.createRegistryFlg}
          />
        </Form.Item>
        <Form.Item label='Тип'>
          <StyledSelect
            onChange={value => setValue(`registryTypeId`, value)}
            options={registryTypes}
            placeholder='Выберите тип'
            value={values.registryTypeId}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox checked={!!values.imhaFlg} onChange={() => setValue(`imhaFlg`, values.imhaFlg ? 0 : 1)}>
            Уведомление ИМХА
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox checked={!!values.serviceFlg} onChange={() => setValue(`serviceFlg`, values.serviceFlg ? 0 : 1)}>
            Уведомление (сервис)
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LegalNotificationDownload;
