import React, { useState } from 'react';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  CheckCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  Modal,
  Upload,
} from 'antd';

import { ACCEPTED_FILES_STRING } from '../constants';

const StyledUpload = styled(Upload.Dragger)`
  border-color: ${palette.primaryColor} !important;
  border-radius: 8px !important;
`;

const UploadIcon = styled.p`
  color: ${palette.primaryColor};
  font-size: 32px;
`;

const UploadText = styled.p`
  color: ${palette.primaryColor};
`;

const Info = styled.p`
  margin-bottom: 8px;
`;

const UploadModal = ({ isVisible, onCancel: _onCancel, onUpload }) => {
  const [file, setFile] = useState(null);
  const onCancel = () => {
    setFile(null);
    _onCancel();
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      maskClosable={false}
      okButtonProps={{ disabled: !file }}
      okText='Отправить'
      onCancel={onCancel}
      onOk={() => {
        onUpload(file);
        onCancel();
      }}
      open={isVisible}
      title='Загрузка файла'
    >
      <Info>
        Файл должен содержать 2 столбца: personId (uuid), actionId (integer).
        Допустимые форматы: xls, xlsx, csv (разделитель в csv - &quot;;&quot;)
      </Info>
      <StyledUpload
        accept={ACCEPTED_FILES_STRING}
        customRequest={req => setFile(req?.file || null)}
        showUploadList={false}
      >
        {file
          ? (
            <>
              <UploadIcon><CheckCircleOutlined /></UploadIcon>
              <UploadText>{file.name}</UploadText>
            </>
          ) : (
            <>
              <UploadIcon><InboxOutlined /></UploadIcon>
              <UploadText>Кликните или перетащите сюда файл для загрузки</UploadText>
            </>
          )}
      </StyledUpload>
    </Modal>
  );
};

export default UploadModal;
