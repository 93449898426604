import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { ScrollbarsDesktop } from 'components/ScrollbarsDesktop/ScrollbarsDesktop';

export const AddressesCollectionWrapper = styled(ScrollbarsDesktop)`
  margin-bottom: 8px;
  max-height: 150px;

  @media(min-width: ${breakpoints.sm}) {
    max-height: 150px;
  }

  @media(min-width: ${breakpoints.md}) {
    max-height: 200px;
  }

  @media(min-width: ${breakpoints.lg}) {
    max-height: 250px;
  }

  @media(min-width: ${breakpoints.xl}) {
    max-height: 300px;
  }
`;
