import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { hasIntersection } from 'helper';

import { getClientTriggers as getClientTriggersAction } from 'models/clients/actions';
import {
  getClientLoans,
  getClientTriggers as getTriggersSelector,
  getIsLoading as isClientsLoadingSelector,
} from 'models/clients/selectors';
import { ILoan } from 'models/clients/types';
import { useDictionaries } from 'models/dictionaries/hooks';
import { useOperators } from 'models/operators/hooks';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import {
  Button,
  Space,
  Tooltip,
} from 'antd';
import { TriggerModal } from 'components/Modal/TriggerModal';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';

import {
  Table,
  Title,
  Wrap,
} from './styled';
import { IProps } from './types';

import {
  getColumns,
  WRITE_ALLOWED_ROLES,
} from './constants';

const Triggers: React.FC<IProps> = ({ isWrapped = true, personId: _personId }) => {
  const dispatch = useDispatch();
  const matchContext = useContext(ClientMatchContext);
  const personId = _personId || matchContext.personId as string;
  const userRoles = useSelector(getRoleNamesSelector);

  const loadTriggers = useCallback(
    () => dispatch(getClientTriggersAction({ personId })),
    [personId, dispatch],
  );

  useEffect(() => {
    if (personId) loadTriggers();
  }, [personId]);

  const triggers = useSelector(state => getTriggersSelector(state, personId));
  const loans:ILoan[] = useSelector(s => getClientLoans(s, personId));
  const [isDictionariesLoading, dictionaries] = useDictionaries([`trigger`, `triggerBlock`]);
  const [isOperatorsLoading, operators] = useOperators();
  const [isTriggerModalOpen, setIsTriggerModalOpen] = useState<boolean>(false);
  const [currentTriggerId, setCurrentTriggerId] = useState<string | null>(null);

  const openTriggerModal = (last: boolean) => {
    setIsTriggerModalOpen(true);
    setCurrentTriggerId(last ? triggers[0]?.triggerId : null);
  };

  const isLoading = useSelector(isClientsLoadingSelector) || isDictionariesLoading || isOperatorsLoading;
  const isWriteAllowed = hasIntersection(WRITE_ALLOWED_ROLES, userRoles);

  const content = (
    <Wrap $isWrapped={isWrapped}>
      <Tooltip title={isWriteAllowed ? undefined : `Доступно для ролей: ${_.join(WRITE_ALLOWED_ROLES, `, `)}`}>
        <Space>
          <Button disabled={!isWriteAllowed} onClick={() => openTriggerModal(false)}>
            Создать событие
          </Button>
          <Button
            disabled={!isWriteAllowed || _.isEmpty(triggers)}
            onClick={() => openTriggerModal(true)}
          >
            Редактировать последнее событие
          </Button>
        </Space>
      </Tooltip>
      <Title>История событий по клиенту</Title>
      <Table
        // @ts-ignore
        columns={getColumns(dictionaries, loans, operators)}
        dataSource={triggers}
        loading={isLoading}
        pagination={false}
        rowKey='callDate'
      />
      <TriggerModal
        isOpen={isTriggerModalOpen}
        onClose={() => setIsTriggerModalOpen(false)}
        onSuccess={loadTriggers}
        personId={personId}
        triggerId={currentTriggerId}
      />
    </Wrap>
  );

  return isWrapped ? (
    <ClientTab isReloading={isLoading} onReload={loadTriggers}>
      {content}
    </ClientTab>
  ) : content;
};

export default Triggers;
