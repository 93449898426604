import React from 'react';
import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';
import { palette } from 'styles/theme';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background-color: ${palette.backgroundColor};
`;

const run = keyframes`
  0% {
    left: 0;
    width: 0;
  }

  50% {
    left: 50%;
    width: 40%;
    transform: translate(-50%, 0);
  }

  100% {
    left: 100%;
    width: 0;
  }
`;

const Loader = styled.div`
  position: absolute;
  height: 100%;
  animation: ${run} 3s cubic-bezier(0.55, 0.09, 0.68, 0.53) infinite;
  background-color: ${palette.primary500Color};
  box-shadow: 1px 1px 3px 0 ${p => transparentize(0.35, palette.primary500Color(p))};
`;

const LinearProgress = ({ className }) => (
  <Wrapper className={className}>
    <Loader />
  </Wrapper>
);

export default LinearProgress;
