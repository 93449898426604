import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { hasIntersection } from 'helper';

import {
  getPersonAction,
  removePersonAction,
} from 'models/actions/actions';
import {
  getPersonActionsByPersonId,
  isLoading as isLoadingSelector,
} from 'models/actions/selectors';
import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { DefaultTable } from 'components/DefaultTable/DefaultTable';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';

import { COLUMNS } from './constants';

const PageClientSingleAction = () => {
  const dispatch = useDispatch();

  const { personId } = useContext(ClientMatchContext);

  // @ts-ignore
  const clientActions = useSelector(state => getPersonActionsByPersonId(state, personId), _.isEqual);
  const isLoading = useSelector(isLoadingSelector);
  const [isLoaded, setIsLoaded] = useState(false);

  const getClientActions = useCallback(() => dispatch(
    getPersonAction({ personId }),
  ), [personId, dispatch]);

  const onRemove = useCallback((actionId: any) => dispatch(
    removePersonAction({ actionId, personId }),
  ), [personId, dispatch]);

  useEffect(() => {
    if (!isLoaded && !isLoading && _.isEmpty(clientActions)) {
      setIsLoaded(true);
      getClientActions();
    }
  }, [getClientActions, clientActions, isLoaded, isLoading]);

  const userRoles = useSelector(getRoleNamesSelector);

  const canRemove = hasIntersection(userRoles, [ROLE_NAMES.SUPER_ADMIN, ROLE_NAMES.ACTION_CLIENT_EDITOR]);

  return (
    <ClientTab onReload={getClientActions}>
      <DefaultTable
        //@ts-ignore
        columns={COLUMNS({ canRemove, onRemove })}
        dataSource={clientActions}
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true,
          pageSize        : _.size(clientActions),
          size            : `small`,
        }}
        scroll={{ x: `max-content`, y: window.innerHeight - 250 }}
        size='small'
      />
    </ClientTab>
  );
};

export default PageClientSingleAction;
