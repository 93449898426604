import _ from 'lodash';
import {jwtDecode} from 'jwt-decode'; // Assuming you're using 'jwt-decode' - adjust if needed

export const checkIsAuthorized = (token: string) => {
  if (!token) return false;
  const decodedToken = jwtDecode(token);
  const currentDate = +(new Date()) / 1000;
  const expirationDate = _.get(decodedToken, 'exp', 0);
  return !(currentDate > expirationDate);
};

export const getUserData = (
  token: string
): { email: string | null; userId: number | null } | null => {
  if (!token) return null;
  return {
    email: _.get(jwtDecode(token), 'data.email', null),
    userId: _.get(jwtDecode(token), 'data.id', null),
  };
};
