import _ from 'lodash';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import {
  model as namespace,
  RELOAD_LAST_SEARCH,
  RESET,
  SEARCH,
  SET,
  SET_LAST_SEARCH,
  SET_LOADED_ITEMS,
  SET_LOADING,
} from './actions';
import * as api from './api';
import { getLastSearch } from './selectors';

const initialState = {
  isLoading  : false,
  items      : [],
  loadedItems: {},
};

export default {
  namespace,
  state        : initialState,
  subscriptions: {},
  effects      : {
    *[RELOAD_LAST_SEARCH](action, { all, put, select }) {
      const lastSearch = yield select(getLastSearch);
      yield all(_.map(lastSearch, payload => put({ type: SEARCH, payload })));
    },

    *[SEARCH](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке журнала событий произошла ошибка`;

      try {
        if (!action.payload.entity || (!action.payload.entityId && !action.payload.personId)) {
          throw new Error(`${defaultError}: не указаны entity и entityId или personId`);
        }
        const searchBody = _.omit(action.payload, [`callback`, `uuid`]);
        const items = parseResponse({
          defaultError,
          response: yield call(api.search, searchBody),
        });

        yield put({ type: SET, payload: { items } });
        yield put({ type: SET_LAST_SEARCH, payload: { uuid: action.payload.uuid, payload: action.payload } });
        yield put({ type: SET_LOADED_ITEMS, payload: _.pick(action.payload, [`entity`, `entityId`]) });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [RESET]() {
      return { ...initialState };
    },

    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;

      return {
        ...state,
        ...payload,
        items: _.uniqBy(_.concat(payload.items || [], state.items), `id`),
      };
    },

    [SET_LAST_SEARCH](state, { payload: { payload, uuid } }) {
      if (!uuid || _.isEmpty(payload)) return state;

      return {
        ...state,
        lastSearch: [
          ..._.uniqBy(_.filter([
            ...(state.lastSearch || []),
            payload,
          ], { uuid }), ({ entity, entityId }) => `${entity}_${entityId}`),
        ],
      };
    },

    [SET_LOADED_ITEMS](state, { payload: { entity, entityId } }) {
      return {
        ...state,
        loadedItems: {
          ...(state.loadedItems || {}),
          [`${entity}_${entityId}`]: +(new Date()),
        },
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
};
