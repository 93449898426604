import * as React from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';

import { update as updateAction } from 'models/dictionaries/actions';

import CreateModal from 'components/Modal/Create';

interface IComponentProps {
  entity: string;
  formFields: any[];
  isVisible: boolean;
  itemEditing: any;
  onClose: () => void;
}

export const DictionaryEditModal: React.FC<IComponentProps> = ({
  entity,
  formFields,
  isVisible,
  itemEditing,
  onClose,
}) => {
  const dispatch = useDispatch();

  const title = `Редактирование записи ${_.get(itemEditing, `name`)
    ? `'${_.get(itemEditing, `name`)}'`
    : ``}`;

  const handleUpdate = (item: { id: any; }) => {
    dispatch(updateAction({ entity, id: item.id, item }));
  };

  return (
    <CreateModal
      close={onClose}
      formFields={formFields}
      item={itemEditing}
      mode='update'
      title={title}
      update={handleUpdate}
      visible={isVisible}
    />
  );
};
