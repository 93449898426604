import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { rgba } from 'polished';
import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
} from 'styles/theme';

import OFFER_STATUSES from 'constants/OFFER_STATUSES';
import { hasIntersection } from 'helper';

import {
  getClient as getClientAction,
  getClientApplications as getClientApplicationsAction,
  getClientCars as getClientCarsAction,
  getClientPhones as getClientPhonesAction,
} from 'models/clients/actions';
import {
  getClientFullName as getClientFullNameSelector,
  getClientLoanById as getClientLoanSelector,
  getClientLoans as getClientLoansSelector,
} from 'models/clients/selectors';
import { ILoan } from 'models/clients/types';
import { useDictionaries } from 'models/dictionaries/hooks';
import {
  checkRightByCampaignName as checkRightSelector,
  getItems as getDictionarySelector,
} from 'models/dictionaries/selectors';
import {
  COLLECTION_ADMIN,
  COLLECTION_WRITE,
  ROLE_NAMES,
} from 'models/roles/constants';
import { getSingle as getTaskAction } from 'models/task/actions';
import {
  BANKRUPT_STATEMENTS_DOC_IDS,
  CAMPAIGN_NAMES,
  CAMPAIGNS_WITH_DOCS,
  CAMPAIGNS_WITH_PRE_COURT_NOTIFY,
  CAMPAIGNS_WITHOUT_OFFERS_TAB,
  CLAIM_DOCUMENTS,
  CLAIM_STATEMENTS_DOC_IDS,
  CLAIM_STATEMENTS_DOC_IDS_TO_COMPOSE,
  DEAD_STATEMENTS_DOC_IDS,
  FSSP_DOCUMENTS,
  OTHER_DOCUMENTS,
  TASK_BLOCK_TITLE,
} from 'models/task/constants';
import {
  getItem as getTaskSelector,
  isLoading as isLoadingSelector,
} from 'models/task/selectors';
import { ITask } from 'models/task/types';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import useMobileContext from 'hooks/useMobileContext';
import { useStatePreference } from 'hooks/useStatePreference';

import {
  Empty,
  Layout,
  Spin,
  Tabs,
} from 'antd';
import { Eventlog } from 'components/Eventlog/Eventlog';
import MarketingOfferTable from 'components/MarketingOfferTable';
import ClientApplications, { PageClientSingleApplication } from 'pages/PageClient/Single/Application';
import Call from 'pages/PageClient/Single/Call';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import Communications from 'pages/PageClient/Single/Communication';
import Debt from 'pages/PageClient/Single/Debt';
import { SaleForm } from 'pages/PageClient/Single/Decision/Forms/SaleForm';
import ClientDocs from 'pages/PageClient/Single/Docs';
import Loans from 'pages/PageClient/Single/Loan';
import Triggers from 'pages/PageClient/Single/Triggers';
import PageTaskPlural from 'pages/PageTask/Plural';
import { TaskCars } from 'pages/PageTask/Single/Cars';
import { PageTaskCommentSingle } from 'pages/PageTask/Single/Comment';
import { Scrollbars } from 'react-custom-scrollbars';

import { ClaimStatements } from './ClaimStatements';
import { ClientHeader } from './ClientHeader';
import { ClientInfo } from './ClientInfo';
import Courts from './Courts';
import { Dead } from './Dead';
import LegalDocuments from './LegalDocuments';
import LegalNotifications from './LegalNotifications';
import LegalRegistries from './LegalRegistries';
import LoanDetails from './LoanDetails';
import LoanItem from './LoanItem';
import Phones from './Phones';
import TopControls from './TopControls';
import Vector from './Vector';

const StyledTab = styled(Tabs.TabPane)`
  padding: 0 !important;
`;

const LoansWrap = styled.div`
  margin-bottom: 8px;
  margin-left: -8px;
  display: flex;
  flex-wrap: wrap;
`;

const LayoutStyled = styled(Layout)`
  min-height: 100%;
  position: relative;
  padding-bottom: 16px;
`;

const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 !important;
  min-height: 100%;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => rgba(theme.palette.backgroundColor, 0.8)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Loader = styled(Spin)`
  z-index: 11;
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${getLightStyleOr(palette.black5Color, palette.backgroundColor)};
  z-index: 1;
`;

const BackgroundTop = styled.div`
  background-color: ${palette.backgroundAccentColor};
  width: 100%;
  height: 270px;
`;

const InfoWrapper = styled.div`
  border-radius: 24px;
  background: ${palette.backgroundColor};
  border: 1px solid ${palette.black30Color};
  z-index: 2;
  display: flex;
  margin: 0 32px;
  flex: 1;

  @media(max-width: ${breakpoints.md}) {
    border: none;
    border-radius: 0;
    margin: 0;
  }
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;

  @media(max-width: ${breakpoints.md}) {
    width: 100%;
    padding: 0;
  }
`;

const InfoTabs = styled.div<{ $tab: string }>`
  flex: 1;
  position: relative;
  height: 100%;

  .ant-tabs {
    height: 100%;
    color: ${palette.textPrimaryColor};

    &-content {
      height: 100%;

      &-holder {
        height: 100%;
      }
    }
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-color: ${palette.borderDefaultColor};
  }

  .ant-tabs-nav {
    background-color: ${palette.black10Color};
  }

  .ant-tabs-nav-list {
    padding-left: 24px;
    padding-right: 24px;
  }

  .ant-tabs-tab {
    font-size: 14px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 24px;
  }

  .ant-tabs-tabpane {
    padding: 0 16px;
    height: 100%;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }

  @media (max-width: ${breakpoints.md}) {
    .ant-tabs-nav {
      margin-bottom: 8px !important;
      background-color: inherit !important;
    }

    .ant-tabs-tab {
      font-size: 12px !important;
    }
  }

  ${({ $tab }) => _.includes([`debt`, `loans`], $tab) && `
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-tabpane {
      padding: 0;
    }`}
`;

const Scroll = styled(Scrollbars)`
  height: 100% !important;
`;

interface IProps {
  history: {
    back(this:void): void;
  };
  match?: {
    params?: {
      personId?: string;
      taskId: string;
    }
  };
}

const PageTaskSingle: React.FC<IProps> = ({ history, match }) => {
  const dispatch = useDispatch();
  const { mobile } = useMobileContext();

  const taskId:number = _.toNumber(match?.params?.taskId);

  const task:ITask = useSelector(state => getTaskSelector(state, taskId) || {});
  // @ts-ignore
  const personId:string = match?.params.personId || task?.personId;
  const { applicationId, loanId } = task;

  useDictionaries([`hmParking`]);

  const campaigns = useSelector(state => _.orderBy(getDictionarySelector(state, `campaign`), `id`, `desc`));
  const campaignTypes = useSelector(state => _.orderBy(getDictionarySelector(state, `campaignType`), `id`, `desc`));

  const campaignType = _.find(campaignTypes, { id: task?.campaignTypeId });
  const campaign = _.find(campaigns, { id: campaignType?.campaignId });

  const canEditTask:boolean = useSelector(state => checkRightSelector(state, campaign?.name, `write`));
  const userRoles = useSelector(getRoleNamesSelector);
  const isLoading:boolean = useSelector(state => isLoadingSelector(state));
  const loanActual:ILoan = useSelector(state => getClientLoanSelector(state, personId, loanId));
  const loans:ILoan[] = useSelector(state => getClientLoansSelector(state, personId));
  const clientFullName:string = useSelector(state => getClientFullNameSelector(state, personId));

  const canViewCalls = hasIntersection(userRoles, [ROLE_NAMES.SUPER_ADMIN, ROLE_NAMES.TASK_CREATOR]);

  const getCars = useCallback(
    () => dispatch(getClientCarsAction({ force: true, personId })),
    [dispatch],
  );
  const getApplicationsWithCars = useCallback(
    () => dispatch(getClientApplicationsAction({ callback: getCars, force: true, personId })),
    [dispatch],
  );
  const getClientInfo = useCallback((callback: any) => dispatch(getClientAction({ personId, callback })), [dispatch]);
  const getClientPhones = useCallback(() => dispatch(getClientPhonesAction({ personId })), [dispatch]);
  const getTask = useCallback((force?:boolean) => dispatch(getTaskAction({ id: taskId, force })), [dispatch]);

  const [isPinned, setIsPinned] = useStatePreference(
    `ui.collection.isTaskClientPinned`,
    true,
  );

  const togglePinned = useCallback(() => setIsPinned(!isPinned), [isPinned]);

  const loansUpdatedAt = task?.loansUpdatedAt;

  useEffect(() => {
    getClientInfo(() => {
      // @ts-ignore
      getTask(true);
      getClientPhones();
      getApplicationsWithCars();
    });
  }, []);

  const campaignBlock = campaign?.block;
  const campaignName = campaign?.name;
  const isLoansEmpty:boolean = _.isEmpty(loans);
  const ScrollWrapComponent = mobile ? React.Fragment : Scroll;
  const scrollWrapProps = mobile ? {} : {
    autoHide: true,
    style   : {
      minHeight: isPinned ? `100%` : `800px`,
    },
  };

  const loansTab = (
    <Tabs.TabPane key='loans' tab='Займы'>
      <ScrollWrapComponent {...scrollWrapProps}>
        <Loans isWrapped={false} personId={personId} />
      </ScrollWrapComponent>
    </Tabs.TabPane>
  );

  const [tab, setTab] = useState<string>(mobile ? `info` : `loan`);
  const viewportRef = useRef(null);
  return (
    <ClientMatchContext.Provider value={{ personId, taskId }}>
      <LayoutStyled>
        {isLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
        )}
        <Content>
          <Background>
            <BackgroundTop />
          </Background>

          <TopControls
            canEditTask={canEditTask}
            goBack={history.back}
            personId={personId}
            taskId={taskId}
          />

          <InfoWrapper>
            <Info>
              {!mobile && (
              <>
                <ClientHeader
                  isPinned={isPinned}
                  personId={personId}
                  segment={task?.segment}
                  togglePinnedMode={togglePinned}
                />
                <ClientInfo
                  personId={personId}
                  taskId={taskId}
                />
              </>
              )}

              <InfoTabs $tab={tab} ref={viewportRef}>
                <Tabs
                  activeKey={tab}
                  moreIcon={null}
                  onChange={setTab}
                >
                  {mobile && (
                  <Tabs.TabPane key='info' tab='Инфо'>
                    <ClientHeader
                      isPinned={isPinned}
                      personId={personId}
                      segment={task?.segment}
                      togglePinnedMode={togglePinned}
                    />
                    <ClientInfo
                      noPadding
                      personId={personId}
                      taskId={taskId}
                    />
                  </Tabs.TabPane>
                  )}

                  <Tabs.TabPane key='loan' tab='Договоры'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      {isLoansEmpty
                        ? <Empty description='Договоры не были найдены' />
                        : (
                          <LoansWrap>
                            <LoanItem
                              active
                              loanId={loanActual?.id}
                              loansUpdatedAt={loansUpdatedAt}
                              onUpdate={() => getTask(true)}
                              personId={personId}
                            />
                            {_.map(_.reject(loans, { id: loanActual?.id }), l => (
                              <LoanItem key={l.id} loanId={l.id} personId={personId} />
                            ))}
                          </LoansWrap>
                        )}
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  {campaignBlock === TASK_BLOCK_TITLE.collection && (
                  <StyledTab key='debt' tab='Задолженность'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <Debt isWrapped={false} />
                    </ScrollWrapComponent>
                  </StyledTab>
                  )}

                  <Tabs.TabPane key='loanDetails' tab='Детали договора'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <LoanDetails loanId={loanActual?.id} personId={personId} />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>

                  {campaignBlock === TASK_BLOCK_TITLE.contactCenter && (
                  <Tabs.TabPane key='applications' tab='Заявки'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <PageClientSingleApplication isWrapped={false} personId={personId} />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {campaignName !== CAMPAIGN_NAMES.LEGAL && (
                  <Tabs.TabPane key='vector' tab='Вектор/КИ'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <Vector taskId={taskId} />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {campaignName === CAMPAIGN_NAMES.LEGAL && loansTab}

                  {_.includes(CAMPAIGNS_WITH_DOCS, campaignName) && (
                  <Tabs.TabPane key='docs' tab='Документы'>
                    <ClientDocs
                      applicationId={loanActual?.applicationId}
                      isWrapped={false}
                      personId={personId}
                    />
                  </Tabs.TabPane>
                  )}

                  {campaignName !== CAMPAIGN_NAMES.LEGAL && loansTab}

                  <Tabs.TabPane key='tasks' style={{ padding: 0 }} tab='Задачи'>
                    <PageTaskPlural personId={personId} />
                  </Tabs.TabPane>

                  {campaignBlock === TASK_BLOCK_TITLE.sale && (
                    <Tabs.TabPane key='saleVerificationDecision' style={{ padding: 0 }} tab='Решение'>
                      <SaleForm />
                    </Tabs.TabPane>
                  )}

                  <Tabs.TabPane key='triggers' tab='Триггеры'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <Triggers isWrapped={false} personId={personId} />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>

                  {_.includes(CAMPAIGNS_WITH_PRE_COURT_NOTIFY, campaignName) && (
                  <Tabs.TabPane
                    key='legalNotifications'
                    tab={campaignName === CAMPAIGN_NAMES.LEGAL ? `Требования/уведомления` : `Уведомления`}
                  >
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <LegalNotifications personId={personId} taskId={taskId} />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {_.includes(CAMPAIGNS_WITH_PRE_COURT_NOTIFY, campaignName) && (
                  <Tabs.TabPane key='legalRegistries' tab='Реестры'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <LegalRegistries loanId={loanActual?.id} personId={personId} />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {campaignName === CAMPAIGN_NAMES.LEGAL && (
                  <Tabs.TabPane key='legalClaimStatements' tab='Исковые/заявления'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <ClaimStatements
                        documentIdsToCompose={CLAIM_STATEMENTS_DOC_IDS_TO_COMPOSE}
                        documentTypes={CLAIM_STATEMENTS_DOC_IDS}
                        taskId={taskId}
                      />
                      <LegalDocuments
                        documents={CLAIM_DOCUMENTS}
                        generateModalTitle='Формирование документа Исковые/заявления'
                        loanId={loanActual?.id}
                        personId={personId}
                        taskId={taskId}
                      />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {campaignName === CAMPAIGN_NAMES.LEGAL && (
                  <Tabs.TabPane key='legalOther' tab='Иные заявления'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <LegalDocuments
                        documents={OTHER_DOCUMENTS}
                        generateModalTitle='Формирование документа Иные заявления'
                        loanId={loanActual?.id}
                        personId={personId}
                        taskId={taskId}
                      />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {campaignName === CAMPAIGN_NAMES.LEGAL && (
                  <Tabs.TabPane key='FSSP' tab='Пакет ФССП'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <LegalDocuments
                        documents={FSSP_DOCUMENTS}
                        generateModalTitle='Формирование документа ФССП'
                        loanId={loanActual?.id}
                        personId={personId}
                        taskId={taskId}
                      />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {campaignName === CAMPAIGN_NAMES.LEGAL && (
                    <Tabs.TabPane key='legalBankrupt' tab='Банкроты-заявления'>
                      <ScrollWrapComponent {...scrollWrapProps}>
                        <ClaimStatements
                          documentTypes={BANKRUPT_STATEMENTS_DOC_IDS}
                          taskId={taskId}
                        />
                      </ScrollWrapComponent>
                    </Tabs.TabPane>
                  )}

                  {campaignName === CAMPAIGN_NAMES.LEGAL && (
                  <Tabs.TabPane key='legalDead' tab='Умершие-Исковые/заявления'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <ClaimStatements
                        documentTypes={DEAD_STATEMENTS_DOC_IDS}
                        taskId={taskId}
                      />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {campaignName === CAMPAIGN_NAMES.LEGAL && (
                  <Tabs.TabPane key='judicial' tab='Суды и ИП'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <Courts taskId={taskId} />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {campaignName !== CAMPAIGN_NAMES.LEGAL && (
                  <Tabs.TabPane key='car' tab='Авто'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <TaskCars
                        applicationId={applicationId}
                        campaignName={campaignName}
                        personId={personId}
                      />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {campaignName === CAMPAIGN_NAMES.LEGAL && (
                  <Tabs.TabPane key='dead' tab='Умершие'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <Dead taskId={taskId} />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  <StyledTab key='communications' tab='Коммуникации'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <Communications isWrapped={false} personId={personId} viewportRef={viewportRef} />
                    </ScrollWrapComponent>
                  </StyledTab>

                  {canViewCalls && (
                  <StyledTab key='call' tab='Звонки'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <Call isWrapped={false} personId={personId} />
                    </ScrollWrapComponent>
                  </StyledTab>
                  )}

                  {campaignName !== CAMPAIGN_NAMES.LEGAL && (
                  <Tabs.TabPane key='eventlog' tab='Журнал'>
                    <ScrollWrapComponent {...scrollWrapProps}>
                      <Eventlog
                        entities={[
                          { entity: `task`, entityId: taskId },
                          { entity: `user`, personId },
                        ]}
                      />
                    </ScrollWrapComponent>
                  </Tabs.TabPane>
                  )}

                  {!_.includes(CAMPAIGNS_WITHOUT_OFFERS_TAB, campaignName) && (
                  <Tabs.TabPane key='marketingOffers' tab='Предложения'>
                    <MarketingOfferTable
                      personId={personId}
                      statusFilter={(status: number) => status === OFFER_STATUSES.NEW}
                    />
                  </Tabs.TabPane>
                  )}

                  <Tabs.TabPane key='comments' tab='Комментарии'>
                    <PageTaskCommentSingle clientFullName={clientFullName} isCompact={isPinned} personId={personId} />
                  </Tabs.TabPane>

                  {campaignName !== CAMPAIGN_NAMES.LEGAL && (
                  <Tabs.TabPane key='phones' tab='Дополнительные телефоны'>
                    <Phones editRoles={[COLLECTION_WRITE, COLLECTION_ADMIN]} personId={personId} />
                  </Tabs.TabPane>
                  )}

                  {campaignBlock === TASK_BLOCK_TITLE.sale && (
                  <Tabs.TabPane key='applications' tab='Заявки'>
                    <ClientApplications isWrapped={false} personId={personId} />
                  </Tabs.TabPane>
                  )}
                </Tabs>
              </InfoTabs>
            </Info>
          </InfoWrapper>
        </Content>
      </LayoutStyled>
    </ClientMatchContext.Provider>
  );
};

export default PageTaskSingle;
