/* eslint-disable max-len */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { hasIntersection } from 'helper';

import {
  cancelInsurance as cancelInsuranceAction,
  closeLoan as closeLoanAction,
  getClientLoanAgreement as getClientLoanAgreementAction,
  getClientLoanInsurance as getClientLoanInsuranceAction,
  getClientLoans as getClientLoansAction,
} from 'models/clients/actions';
import {
  getClient as getClientSelector,
  getClientLoansWithApplications as getClientLoansWithApplicationsSelector,
  getIsLoading as isLoadingSelector,
} from 'models/clients/selectors';
import { get as getDictionaryAction } from 'models/dictionaries/actions';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import useMobileContext from 'hooks/useMobileContext';

import { Modal } from 'antd';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import ConditionModal from 'pages/PageClient/Single/Info/ConditionModal';
import { LoansCards } from 'pages/PageClient/Single/Loan/LoansCards/LoansCards';
import { LoansTable } from 'pages/PageClient/Single/Loan/LoansTable/LoansTable';

import CreateAgreementModal from './CreateAgreementModal';
import LoanCloseModal from './LoanCloseModal';
import RepaymentLinkGenerateModal from './RepaymentLinkGenerateModal';

const PageClientSingleLoan = ({ isWrapped = true, personId: _personId }) => {
  const dispatch = useDispatch();

  const { mobile } = useMobileContext();

  const clientMatch = useContext(ClientMatchContext);
  const personId = _personId || clientMatch.personId;

  const [cancelInsuranceId, setCancelInsuranceId] = useState(null);
  const [activeLoanId, setActiveLoanId] = useState(null);

  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [isCancelInsuranceModalOpen, setIsCancelInsuranceModalOpen] = useState(false);
  const [isConditionModalVisible, setIsConditionModalVisible] = useState(false);
  const [isLoanCloseModalOpen, setIsLoanCloseModalOpen] = useState(false);
  const [isRepaymentLinkModalOpen, setRepaymentLinkModalOpen] = useState(false);

  const contractConditions = useSelector(state => getDictionarySelector(state, `contractCondition`));
  const assignees = useSelector(state => getDictionarySelector(state, `assignee`));

  const loans = useSelector(state => getClientLoansWithApplicationsSelector(state, personId), _.isEqual);

  const userRoles = useSelector(getRoleNamesSelector);
  const client = useSelector(state => getClientSelector(state, personId));
  const isLoading = useSelector(isLoadingSelector);

  const cancelInsurance = insuranceId => dispatch(cancelInsuranceAction({ personId, insuranceId }));
  const closeLoan = (loanId, factCloseDtm) => dispatch(closeLoanAction({ factCloseDtm, personId, loanId }));

  const getAgreement = params => dispatch(getClientLoanAgreementAction({ ...params, personId }));

  const getInsurance = useCallback(() => dispatch(getClientLoanInsuranceAction({ personId })), [personId, dispatch]);
  const getLoans = useCallback(callback => dispatch(getClientLoansAction({ callback, personId })), [personId, dispatch]);

  const toggleAgreementModal = loanId => {
    setActiveLoanId(isAgreementModalOpen ? null : loanId);
    setIsAgreementModalOpen(!isAgreementModalOpen);
  };

  const toggleCancelInsuranceModal = () => {
    setIsCancelInsuranceModalOpen(!isCancelInsuranceModalOpen);
  };

  const toggleConditionModal = loanId => {
    setActiveLoanId(isConditionModalVisible ? null : loanId);
    setIsConditionModalVisible(!isConditionModalVisible);
  };

  const toggleLoanCloseModal = loanId => {
    setActiveLoanId(isLoanCloseModalOpen ? null : loanId);
    setIsLoanCloseModalOpen(!isLoanCloseModalOpen);
  };

  const toggleRepaymentLinkModal = loanId => {
    setActiveLoanId(isRepaymentLinkModalOpen ? null : loanId);
    setRepaymentLinkModalOpen(!isRepaymentLinkModalOpen);
  };

  const onCancelInsurance = insuranceId => {
    setCancelInsuranceId(insuranceId);
    toggleCancelInsuranceModal();
  };

  const onLoansLoad = useCallback(() => {
    getLoans(getInsurance);
  }, [getLoans, getInsurance]);

  const canGetAgreement = hasIntersection(userRoles, [`superAdmin`, `collection`, `verificationRead`]);

  const getDictionary = useCallback((entity, force = true) => dispatch(getDictionaryAction({ entity, force })), [dispatch]);

  useEffect(() => {
    if (_.isEmpty(loans)) {
      onLoansLoad();
    }
    if (_.isEmpty(contractConditions)) {
      getDictionary(`contractCondition`);
    }
    if (_.isEmpty(assignees)) {
      getDictionary(`assignee`);
    }
  }, []);

  useEffect(() => {
    getInsurance();
  }, [getInsurance]);
  const content = (
    <>
      {mobile
        ? (
          <LoansCards
            assignees={assignees}
            loans={loans}
            onCancelInsurance={onCancelInsurance}
            personId={personId}
            toggleAgreementModal={toggleAgreementModal}
            toggleConditionModal={toggleConditionModal}
            toggleLoanCloseModal={toggleLoanCloseModal}
            toggleRepaymentLinkModal={toggleRepaymentLinkModal}
          />
        )
        : (
          <LoansTable
            assignees={assignees}
            contractConditions={contractConditions}
            loans={loans}
            onCancelInsurance={onCancelInsurance}
            personId={personId}
            toggleAgreementModal={toggleAgreementModal}
            toggleConditionModal={toggleConditionModal}
            toggleLoanCloseModal={toggleLoanCloseModal}
            toggleRepaymentLinkModal={toggleRepaymentLinkModal}
          />
        )}

      <Modal
        cancelText='Отмена'
        centered
        maskClosable={false}
        okButtonProps={{ danger: true }}
        okText='Да'
        onCancel={toggleCancelInsuranceModal}
        onOk={() => {
          cancelInsurance(cancelInsuranceId);
          toggleCancelInsuranceModal();
        }}
        open={isCancelInsuranceModalOpen}
        title='Отмена полиса'
      >
        Вы действительно хотите отменить полис?
      </Modal>

      <LoanCloseModal
        close={toggleLoanCloseModal}
        onSubmit={factCloseDtm => closeLoan(activeLoanId, factCloseDtm)}
        visible={isLoanCloseModalOpen}
      />

      {canGetAgreement && (
        <CreateAgreementModal
          client={client}
          close={toggleAgreementModal}
          onSubmit={params => getAgreement({ loanId: activeLoanId, ...params })}
          visible={isAgreementModalOpen}
        />
      )}

      <ConditionModal
        entity='contract'
        loanId={activeLoanId}
        onClose={toggleConditionModal}
        personId={personId}
        visible={isConditionModalVisible}
      />

      <RepaymentLinkGenerateModal
        isVisible={isRepaymentLinkModalOpen}
        loanId={activeLoanId}
        onClose={toggleRepaymentLinkModal}
        personId={personId}
      />
    </>
  );
  return isWrapped
    ? (
      <ClientTab isReloading={isLoading} onReload={onLoansLoad}>
        {content}
      </ClientTab>
    )
    : content;
};

export default PageClientSingleLoan;
