import api from 'api/index';

import {
  BankruptData,
  ISearchRequest,
  ISearchResponse,
} from 'models/bankrupts/types';

export const get = (searchRequest:ISearchRequest) => api.post<ISearchResponse>(
  `/proxy/f/bankrupt/search`,
  searchRequest,
);

export const create = (data: BankruptData) => api.post(`/proxy/hm/bankrupt/create`, data);

export const update = (bankruptId: string, data: BankruptData) => api.post(
  `/proxy/hm/bankrupt/update/${bankruptId}`,
  data,
);
