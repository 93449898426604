export const SORT_BY = [
  {
    label: `Дата`,
    value: `createdAt`,
  },
  {
    label: `Блок`,
    value: `campaignBlock`,
  },
  {
    label: `Тип кампании`,
    value: `campaignTypeName`,
  },
  {
    label: `Кампания`,
    value: `campaignName`,
  },
  {
    label: `Коммуникация`,
    value: `campaignName`,
  },
];

export const SORT_ORDER = [
  {
    label: `По убыванию`,
    value: `desc`,
  },
  {
    label: `По возрастанию`,
    value: `asc`,
  },
];

export const INITIAL_MODAL_STATE = {
  appealSubjectId          : null,
  applicationDate          : null,
  block                    : null,
  clientCharacteristicId   : null,
  collectionPromiseStatusId: null,
  collectionServiceId      : null,
  comment                  : null,
  communicationFeedbackId  : null,
  communicationId          : null,
  communicationTypeId      : null,
  id                       : null,
  ipCallExternalId         : null,
  isMinimized              : false,
  isOpen                   : false,
  mode                     : `create`,
  notification             : null,
  notificationDate         : null,
  operatorId               : null,
  parkingId                : null,
  paymentDate              : null,
  personId                 : null,
  promiseDate              : null,
  proposalAnnounced        : null,
  proposalReactionId       : null,
  saleFeedbackStatusId     : null,
  serviceDate              : null,
  takeDoc                  : null,
  takeDocDate              : null,
  taskId                   : null,
};
