import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment, { Moment } from 'moment/moment';

import {
  inputNumberFormatter,
  inputNumberParser,
} from 'helper';

import { postEnforcementProceeding as postAction } from 'models/courts/actions';
import { defaultValuesEnforcementProceedings } from 'models/courts/constants';
import {
  getItemById,
  isLoading as isLoadingSelector,
} from 'models/courts/selectors';
import {
  ICourtData,
  IEnforcementProceedingValues,
} from 'models/courts/types';
import { useDictionaries } from 'models/dictionaries/hooks';
import { getFullName as getFullNameSelector } from 'models/user/selectors';

import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Select,
} from 'antd';

import {
  Collapse,
  FormItemStyled,
  ModalStyled,
  Panel,
  StyledInputNumber,
} from './styled';

interface IProps {
  courtId: string,
  enforcementProceedingsId: string | null,
  isVisible: boolean,
  onClose:()=>void,
  onSuccess?:()=>void,
}

export const EnforcementProceedingModal: React.FC<IProps> = ({
  courtId,
  enforcementProceedingsId,
  isVisible,
  onClose,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const { court, isLoading, operatorFullName } = useSelector(state => ({
    court           : getItemById(state, courtId),
    isLoading       : isLoadingSelector(state),
    operatorFullName: getFullNameSelector(state),
  })) as {
    court: ICourtData,
    isLoading: boolean,
    operatorFullName: string,
  };
  const { enforcementProceedings, loanId, personId } = court;
  const [values, setValues] = useState<IEnforcementProceedingValues>({
    ...defaultValuesEnforcementProceedings,
    author   : operatorFullName,
    courtId,
    createDtm: moment.utc(),
    deleteFlg: false,
    enforcementProceedingsId,
    loanId,
    personId,
    updateDtm: moment.utc(),
  });
  const [isDictionariesLoading, dictionaries] = useDictionaries([`enforcementProceedingStatus`]);

  const enforcementProceeding = enforcementProceedingsId
    ? _.find(enforcementProceedings, { enforcementProceedingsId })
    : null;

  useEffect(
    () => {
      if (isVisible && enforcementProceeding) {
        setValues({
          arrestBankAccountFlg         : enforcementProceeding.arrestBankAccountFlg,
          auctionAcceptanceOnBalanceDtm: enforcementProceeding.auctionAcceptanceOnBalanceDtm
            ? moment.utc(enforcementProceeding.auctionAcceptanceOnBalanceDtm) : null,
          auctionAcceptanceOnBalanceSum       : enforcementProceeding.auctionAcceptanceOnBalanceSum,
          auctionEstimateSum                  : enforcementProceeding.auctionEstimateSum,
          auctionProgress                     : enforcementProceeding.auctionProgress,
          author                              : operatorFullName,
          autoArrestFlg                       : enforcementProceeding.autoArrestFlg,
          autoEnforcementProceedingsEndArticle: enforcementProceeding.autoEnforcementProceedingsEndArticle,
          autoEnforcementProceedingsEndDtm    : enforcementProceeding.autoEnforcementProceedingsEndDtm
            ? moment.utc(enforcementProceeding.autoEnforcementProceedingsEndDtm) : null,
          autoEnforcementProceedingsInitiationDtm: enforcementProceeding.autoEnforcementProceedingsInitiationDtm
            ? moment.utc(enforcementProceeding.autoEnforcementProceedingsInitiationDtm) : null,
          autoEnforcementProceedingsNumber: enforcementProceeding.autoEnforcementProceedingsNumber,
          autoInjunctionFlg               : enforcementProceeding.autoInjunctionFlg,
          autoSearchFlg                   : enforcementProceeding.autoSearchFlg,
          collectionWagesFlg              : enforcementProceeding.collectionWagesFlg,
          comment                         : enforcementProceeding.comment,
          courtId,
          createDtm                       : enforcementProceeding.createDtm
            ? moment.utc(enforcementProceeding.createDtm) : moment.utc(),
          deleteFlg                          : false,
          enforcementProceedingsId           : enforcementProceeding.enforcementProceedingsId,
          finishStatus                       : enforcementProceeding.finishStatus,
          loanId,
          personId,
          restrictionsTravelFlg              : enforcementProceeding.restrictionsTravelFlg,
          resubmissionFlg                    : enforcementProceeding.resubmissionFlg,
          sumEnforcementProceedingsEndArticle: enforcementProceeding.sumEnforcementProceedingsEndArticle,
          sumEnforcementProceedingsEndDtm    : enforcementProceeding.sumEnforcementProceedingsEndDtm
            ? moment.utc(enforcementProceeding.sumEnforcementProceedingsEndDtm) : null,
          sumEnforcementProceedingsInitiationDtm: enforcementProceeding.sumEnforcementProceedingsInitiationDtm
            ? moment.utc(enforcementProceeding.sumEnforcementProceedingsInitiationDtm) : null,
          sumEnforcementProceedingsNumber: enforcementProceeding.sumEnforcementProceedingsNumber,
          updateDtm                      : moment.utc(),
        });
      }
    },
    [enforcementProceedingsId, enforcementProceeding, isVisible],
  );

  const setValue = (field, data: ChangeEvent<HTMLInputElement> | Moment | string | boolean) => setValues({
    ...values,
    [field]: !_.isEmpty(data) && _.has(data, `target`) ? (data as ChangeEvent<HTMLInputElement>).target.value : data,
  });

  const handleClose = () => {
    setValues(defaultValuesEnforcementProceedings);
    onClose();
  };

  const handleSuccess = () => {
    if (_.isFunction(onSuccess)) onSuccess();
    handleClose();
  };

  const onSubmit = () => {
    dispatch(postAction({
      callback : handleSuccess,
      ...values,
      author   : operatorFullName,
      courtId,
      loanId,
      personId,
      updateDtm: moment.utc(),
    }));
  };

  const modalTitle = enforcementProceeding ? `ИП ${enforcementProceeding.sumEnforcementProceedingsNumber}` : `Новое ИП`;

  return (
    <ModalStyled
      cancelText='Отмена'
      centered
      confirmLoading={isLoading}
      maskClosable={false}
      okButtonProps={{
        disabled: isDictionariesLoading,
      }}
      okText='Сохранить'
      onCancel={handleClose}
      onOk={onSubmit}
      open={isVisible}
      title={modalTitle}
      zIndex={1001}
    >
      <Form layout='vertical'>
        <Collapse defaultActiveKey='Основная информация'>
          <Panel header='Основная информация' key='Основная информация'>
            <FormItemStyled label='Итоговый статус по ИП'>
              <Select
                onChange={value => setValue(`finishStatus`, value)}
                options={_.map(
                  dictionaries?.enforcementProceedingStatus,
                  ({ description }) => ({
                    label: description,
                    value: description,
                  }),
                )}
                value={values.finishStatus}
              />
            </FormItemStyled>
            <FormItemStyled>
              <Checkbox
                checked={values.arrestBankAccountFlg}
                onChange={({ target: { checked } }) => setValue(`arrestBankAccountFlg`, checked)}
              >
                Арест РС
              </Checkbox>
            </FormItemStyled>
            <FormItemStyled>
              <Checkbox
                checked={values.restrictionsTravelFlg}
                onChange={({ target: { checked } }) => setValue(`restrictionsTravelFlg`, checked)}
              >
                Ограничение выезда за пределы РФ
              </Checkbox>
            </FormItemStyled>
            <FormItemStyled>
              <Checkbox
                checked={values.collectionWagesFlg}
                onChange={({ target: { checked } }) => setValue(`collectionWagesFlg`, checked)}
              >
                Обращение на заработную плату
              </Checkbox>
            </FormItemStyled>
            <FormItemStyled>
              <Checkbox
                checked={values.autoInjunctionFlg}
                onChange={({ target: { checked } }) => setValue(`autoInjunctionFlg`, checked)}
              >
                Запрет на ТС
              </Checkbox>
            </FormItemStyled>
            <FormItemStyled>
              <Checkbox
                checked={values.autoArrestFlg}
                onChange={({ target: { checked } }) => setValue(`autoArrestFlg`, checked)}
              >
                Арест ТС
              </Checkbox>
            </FormItemStyled>
            <FormItemStyled>
              <Checkbox
                checked={values.autoSearchFlg}
                onChange={({ target: { checked } }) => setValue(`autoSearchFlg`, checked)}
              >
                Исполнительный розыск ТС
              </Checkbox>
            </FormItemStyled>
            <FormItemStyled label='Дата возбуждения ИП (сумма)'>
              <DatePicker
                onChange={value => setValue(`sumEnforcementProceedingsInitiationDtm`, value)}
                // @ts-ignore
                value={values.sumEnforcementProceedingsInitiationDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Номер ИП (сумма)'>
              <Input
                onChange={event => setValue(`sumEnforcementProceedingsNumber`, event)}
                value={values.sumEnforcementProceedingsNumber}
              />
            </FormItemStyled>
            <FormItemStyled label='Дата возбуждения ИП (ТС)'>
              <DatePicker
                onChange={value => setValue(`autoEnforcementProceedingsInitiationDtm`, value)}
                // @ts-ignore
                value={values.autoEnforcementProceedingsInitiationDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Номер ИП (ТС)'>
              <Input
                onChange={event => setValue(`autoEnforcementProceedingsNumber`, event)}
                value={values.autoEnforcementProceedingsNumber}
              />
            </FormItemStyled>
          </Panel>
          <Panel header='Торги' key='Торги'>
            <FormItemStyled label='Оценка сумма'>
              <StyledInputNumber
                formatter={inputNumberFormatter}
                min={0}
                // @ts-ignore
                onChange={event => setValue(`auctionEstimateSum`, event)}
                parser={inputNumberParser}
                placeholder='Сумма, ₽'
                precision={2}
                value={values.auctionEstimateSum}
              />
            </FormItemStyled>
            <FormItemStyled label='Принятие на баланс (дата)'>
              <DatePicker
                onChange={value => setValue(`auctionAcceptanceOnBalanceDtm`, value)}
                // @ts-ignore
                value={values.auctionAcceptanceOnBalanceDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Принятие на баланс (сумма)'>
              <StyledInputNumber
                formatter={inputNumberFormatter}
                min={0}
                // @ts-ignore
                onChange={event => setValue(`auctionAcceptanceOnBalanceSum`, event)}
                parser={inputNumberParser}
                placeholder='Сумма, ₽'
                precision={2}
                value={values.auctionAcceptanceOnBalanceSum}
              />
            </FormItemStyled>
            <FormItemStyled label='Ход торгов'>
              <Input
                onChange={event => setValue(`auctionProgress`, event)}
                value={values.auctionProgress}
              />
            </FormItemStyled>
          </Panel>

          <Panel header='Доп. инфо' key='Доп. инфо'>
            <FormItemStyled label='Дата окончания ИП (сумма)'>
              <DatePicker
                onChange={value => setValue(`sumEnforcementProceedingsEndDtm`, value)}
                // @ts-ignore
                value={values.sumEnforcementProceedingsEndDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Статья окончания ИП (сумма)'>
              <Input
                onChange={event => setValue(`sumEnforcementProceedingsEndArticle`, event)}
                value={values.sumEnforcementProceedingsEndArticle}
              />
            </FormItemStyled>
            <FormItemStyled label='Дата окончания ИП (залог)'>
              <DatePicker
                onChange={value => setValue(`autoEnforcementProceedingsEndDtm`, value)}
                // @ts-ignore
                value={values.autoEnforcementProceedingsEndDtm}
              />
            </FormItemStyled>
            <FormItemStyled label='Статья окончания ИП (залог)'>
              <Input
                onChange={event => setValue(`autoEnforcementProceedingsEndArticle`, event)}
                value={values.autoEnforcementProceedingsEndArticle}
              />
            </FormItemStyled>
            <FormItemStyled>
              <Checkbox
                checked={values.resubmissionFlg}
                onChange={({ target: { checked } }) => setValue(`resubmissionFlg`, checked)}
              >
                Требуется повторная подача ИЛ
              </Checkbox>
            </FormItemStyled>
            <FormItemStyled label='Комментарий'>
              <Input.TextArea
                // @ts-ignore
                onChange={event => setValue(`comment`, event.target.value)}
                rows={3}
                value={values.comment}
              />
            </FormItemStyled>
          </Panel>
        </Collapse>
      </Form>
    </ModalStyled>
  );
};
