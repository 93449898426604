import React, { useMemo } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import {
  PHONE,
  REQUIRED,
} from 'constants/FORM_VALIDATION_RULES';
import {
  getColumn,
  getSort,
  sortAlphabetically,
} from 'helper';

import {
  createClientPhone as createClientPhoneAction,
  updateClientPhone as updateClientPhoneAction,
} from 'models/clients/actions';
import {
  getClientPhones as getClientPhonesSelector,
  getIsLoadingPhones as getIsLoadingPhonesSelector,
} from 'models/clients/selectors';
import { getItems as getDictionary } from 'models/dictionaries/selectors';
import {
  PHONE_EDIT,
  SUPER_ADMIN,
  TRoleName,
} from 'models/roles/constants';

import { useModalHandlers } from 'hooks/useModalHandlers';

import {
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Input,
  Select,
} from 'antd';
import { DefaultTable } from 'components/DefaultTable/DefaultTable';
import CreateModal from 'components/Modal/Create';
import MttPhoneButton from 'components/MttPhoneButton';
import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';

export const COLUMNS = ({ allowedRoles, phonesDictionary, setItemEditing, toggleEditModal }: { allowedRoles: TRoleName[], phonesDictionary: any, setItemEditing: any , toggleEditModal: any }) => _.map([
  {
    key   : `phone`,
    title : `Телефон`,
    sorter: getSort(`phone`, sortAlphabetically),
  },
  {
    key   : `phoneTypeId`,
    render: (id: number) => _.get(_.find(phonesDictionary, { id }), `name`),
    title : `Тип`,
    sorter: getSort(`type`, sortAlphabetically),
  },
  {
    key   : `mttPhone`,
    fixed : `right`,
    render: (v: any, item: { phone: string; }) => (// @ts-ignore
      <MttPhoneButton phone={item?.phone} />
    ),
    title: ``,
    width: 50,
  },
  {
    key   : `phone`,
    fixed : `right`,
    render: (v, item) => (
      <EntityCardTitleControl
        actionTitle='Изменение данных'
        allowedRoles={allowedRoles}
        onClick={() => {
          setItemEditing(item);
          toggleEditModal();
        }}
      >
        <EditOutlined />
      </EntityCardTitleControl>
    ),
    title: ``,
    width: 50,
  },
], getColumn);

export const FORM_FIELDS = (mode = `create`) => [
  {
    key         : `phone`,
    title       : `Телефон`,
    Component   : Input,
    initialValue: ``,
    required    : true,
    rules       : [REQUIRED, PHONE],
  },
  {
    key          : `phoneTypeId`,
    title        : `Тип`,
    Component    : Select,
    disabled     : mode === `update`,
    initialValue : ``,
    optionsSource: `phonesDictionary`,
    required     : true,
    rules        : [REQUIRED],
  },
];

export const ALLOWED_ROLES: TRoleName[] = [
  SUPER_ADMIN,
  PHONE_EDIT,
];

export const AdditionalPhones = ({ personId }: { personId: string }) => {
  const dispatch = useDispatch();

  const { isLoadingPhones, phones, phonesDictionary } = useSelector(state => ({
    isLoadingPhones : getIsLoadingPhonesSelector(state),
    phones          : getClientPhonesSelector(state, personId),
    phonesDictionary: _.orderBy(getDictionary(state, `phoneTypeExternal`), `priority`, `desc`),
  }));

  const create = (data: any) => dispatch(createClientPhoneAction({ data, personId }));
  const update = ({ id: phoneId, phone }: { id: string; phone: string; }) => dispatch(updateClientPhoneAction({ personId, phone, phoneId }));

  const formFieldsCreate = useMemo(() => FORM_FIELDS(), []);
  const formFieldsUpdate = useMemo(() => FORM_FIELDS(`update`), []);

  const {
    closeEditModal,
    isCreateModalOpen,
    isEditModalOpen,
    itemEditing,
    setItemEditing,
    toggleCreateModal,
    toggleEditModal,
  } = useModalHandlers();

  return (
    <EntityCard>
      <EntityCardTitle>
        Дополнительные телефоны
        <EntityCardTitleControl
          actionTitle='Добавить телефон'
          allowedRoles={ALLOWED_ROLES}
          onClick={toggleCreateModal}
        >
          <PlusCircleOutlined />
        </EntityCardTitleControl>
      </EntityCardTitle>
      <EntityCardContent>
        <DefaultTable
          bordered={false}
          //@ts-ignore
          columns={COLUMNS({ allowedRoles: ALLOWED_ROLES, phonesDictionary, setItemEditing, toggleEditModal })}
          dataSource={phones}
          loading={isLoadingPhones}
          pagination={{
            hideOnSinglePage: true,
            pageSize        : _.size(phones),
            size            : `small`,
          }}
          rowKey='id'
          scroll={{ x: `max-content`, y: 100 }}
          size='small'
        />
      </EntityCardContent>

      <CreateModal
        close={toggleCreateModal}
        create={create}
        formFields={formFieldsCreate}
        isLoading={isLoadingPhones}
        // @ts-ignore
        phonesDictionary={phonesDictionary}
        title='Новый телефон'
        visible={isCreateModalOpen}
      />

      {isEditModalOpen && (
        <CreateModal
          close={closeEditModal}
          formFields={formFieldsUpdate}
          isLoading={isLoadingPhones}
          item={itemEditing}
          mode='update'
          // @ts-ignore
          phonesDictionary={phonesDictionary}
          title='Редактирование телефона'
          update={update}
          visible={isEditModalOpen}
        />
      )}
    </EntityCard>
  );
};
