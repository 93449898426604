import _ from 'lodash';
import { io, Socket } from 'socket.io-client';

import { checkIsAuthorized, getUserData } from './helper';
import {intercom} from './intercom';

interface IntercomEvents {
  SOCKET_CONNECT: boolean;
  SOCKET_CONNECT_ERROR: Error;
  SOCKET_MESSAGE: [string, any];
  SOCKET_VERSION: [string, string];
  SOCKET_MTT_CALL: [string, { fullName: string; personId: number; phone: string }];
  LEADS_UPDATED: [string];
  SOCKET_EMIT: { payload: [string, any]; key?: string };
}

interface SocketEmitMessage<K extends keyof IntercomEvents> {
  payload: [K, IntercomEvents[K]];
  key?: string;
}

// Определим тип для интервала переподключения
let reconnectInterval: NodeJS.Timeout | null = null;

interface UserData {
  userId: string;
  [key: string]: any;
}

let socket: Socket | undefined = undefined;

// Типизируем функцию createSocket
export const createSocket = (accessToken: string, apiUrl: string): Socket | undefined => {
  if (socket) return socket;
  // @ts-ignore
  const userData: UserData | null = getUserData(accessToken);
  if (!userData) return;

  // Если userId отсутствует, завершить выполнение
  if (!userData?.userId) return;

  // socket = io(_.replace(import.meta.env.VITE_REACT_APP_API_URL, `/api`, ``) || `http://localhost:3001`, {
  socket = io(_.replace(apiUrl, `/api`, ``) || `http://localhost:3001`, {
    autoConnect: false,
    extraHeaders: {
      Authorization: accessToken,
    },
    query: { ...(userData || {}) },
  });

  const reconnectSocket = (): void => {
    if (reconnectInterval) return;
    reconnectInterval = setInterval(() => {
      if (socket?.disconnected) {
        socket.connect();
      } else {
        clearInterval(reconnectInterval!);
        reconnectInterval = null;
      }
    }, 5000);
  };

  console.log(`socket.io instance initialized`);

  // Если сокет не существует или отключен, и авторизация пройдена
  if ((!socket || (socket.disconnected && !reconnectInterval)) && checkIsAuthorized(accessToken)) {
    socket.connect();

    socket.on(`connect`, () => {
      intercom.postMessage(`SOCKET_CONNECT`, true);
      console.log(`socket.io connection established`);
    });

    socket.on(`connect_error`, (error: Error) => {
      intercom.postMessage(`SOCKET_CONNECT_ERROR`, error);
      console.log(`socket.io connection error`, error);

      reconnectSocket();
    });

    socket.on(`disconnect`, () => {
      intercom.postMessage(`SOCKET_CONNECT`, false);
      reconnectSocket();
    });

    socket.on(`messageAdded`, ({ dialogId, message }: { dialogId: string; message: any }) => {
      intercom.postMessage(`SOCKET_MESSAGE`, [`messageAdded`, { dialogId, message }]);
    });

    socket.on(`messageEdited`, ({ dialogId, message }: { dialogId: string; message: any }) => {
      intercom.postMessage(`SOCKET_MESSAGE`, [`messageEdited`, { id: dialogId, message }]);
    });

    socket.on(`messageDeleted`, ({ dialogId, messageId }: { dialogId: string; messageId: string }) => {
      intercom.postMessage(`SOCKET_MESSAGE`, [`messageDeleted`, { dialogId, messageId }]);
    });

    socket.on(`setRead`, ({ id, message }: { id: string; message: any }) => {
      intercom.postMessage(`SOCKET_MESSAGE`, [`setRead`, { id, message }]);
    });

    socket.on(`notificationsAdded`, ({ notifications }: { notifications: any[] }) => {
      intercom.postMessage(`SOCKET_MESSAGE`, [`notificationsAdded`, notifications]);
    });

    socket.on(`setVersion`, ({ commitHash }: { commitHash: string }) => {
      intercom.postMessage(`SOCKET_VERSION`, [`setVersion`, commitHash]);
    });

    socket.on(`mttCall`, ({ fullName, personId, phone }: { fullName: string; personId: number; phone: string }) => {
      intercom.postMessage(`SOCKET_MTT_CALL`, [`mttCall`, { fullName, personId, phone }]);
    });

    socket.on(`leadsUpdated`, () => {
      intercom.postMessage(`LEADS_UPDATED`, [`leadsUpdated`]);
    });

    // @ts-ignore
    intercom.subscribe('SOCKET_EMIT', (message: SocketEmitMessage<keyof IntercomEvents>) => {
      try {
        const [eventName, data] = message.payload;

        socket?.emit(eventName, data, (reply: any) => {
          if (message.key) {
            intercom.postReply(eventName, reply);
          }
        });
      } catch (error) {
        console.error(error);
      }
    });
  }

  return socket;
};
