import * as React from 'react';
import { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import {
  formatDate,
  getColumn,
  getShortened,
  prettifyAmount,
} from 'helper';

import { SettingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { DefaultTable } from 'components/DefaultTable/DefaultTable';
import { EntityCard } from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import {
  EntityDetails,
  IRow,
} from 'pages/PageClient/Single/components/EntityDetails/EntityDetails';

const StyledEntityCard = styled(EntityCard)`
  border: 1px solid ${palette.black20Color};
  width: calc(100vw - 32px);
  margin-right: 8px;
  padding-top: 8px;
  margin-left: 8px;
  border-radius: 8px;

  @media(min-width: ${breakpoints.sm}) {
    width: 304px;
  }
`;

const ROWS: IRow[] = [
  {
    key   : `paymentDtm`,
    title : `Дата`,
    render: v => formatDate(v),
  },
  {
    key   : `totalSum`,
    title : `Сумма`,
    render: v => prettifyAmount(v),
  },
  {
    key  : `paymentForm`,
    title: `Форма оплаты`,
  },
  {
    key   : `paymentId`,
    title : `id`,
    render: v => getShortened(v),
  },
];

const StyledEntityDetails = styled(EntityDetails)`

`;

const ActionsButtonWrapper = styled.button`
  border: none;
  padding: 4px 8px;
  background: ${palette.primary5Color};
  color: ${palette.black80Color};
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;

  .anticon {
    margin-right: 8px;
    color: ${palette.primary500Color};
  }
`;

interface IComponentProps {
  repayment: any;
}

export const LoanRepaymentCard: React.FC<IComponentProps> = ({ repayment }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <StyledEntityCard>
      <StyledEntityDetails
        record={repayment}
        rows={[
          ...ROWS,
          {
            key   : `system_actions`,
            title : `Действия`,
            render: (value, record) => (!_.isEmpty(record.details) ? (
              <ActionsButtonWrapper onClick={() => setIsVisible(true)}>
                <SettingOutlined /> Детали
              </ActionsButtonWrapper>
            ) : undefined),
          },
        ]}
      />

      <Modal
        onCancel={() => setIsVisible(false)}
        onOk={() => setIsVisible(false)}
        open={isVisible}
        title='Детали'
      >
        <DefaultTable
          // @ts-ignore
          columns={_.map([
            {
              align : `right`,
              key   : `sum`,
              title : `Сумма`,
              render: prettifyAmount,
              width : 130,
            },
            {
              align: `left`,
              key  : `type`,
              title: `Тип`,
            },
          ], getColumn)}
          dataSource={repayment.details}
          pagination={{
            hideOnSinglePage: true,
            pageSize        : _.size(repayment.details),
            size            : `small`,
          }}
          size='small'
        />
      </Modal>
    </StyledEntityCard>
  );
};
