import * as React from 'react';

import useMobileContext from 'hooks/useMobileContext';

import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';

// eslint-disable-next-line @typescript-eslint/ban-types
type TComponentProps = TooltipProps & {};

export const DefaultTooltip: React.FC<TComponentProps> = ({ children, title, placement = `top` }) => {
  const { mobile } = useMobileContext();

  return mobile ? children : <Tooltip placement={placement} title={title}>{children}</Tooltip>;
};
