import api from 'api/index';
import { getLimitParams } from 'helper';

export const get = params => api.get(`/role?${getLimitParams(params)}`);
export const getSingle = id => api.get(`/role/${id}`);
export const getOperators = id => api.get(`/role/${id}/operators`);
export const group = (ids, roleGroupId) => api.post(`/role/group`, { ids, roleGroupId });
export const remove = ids => api.delete(`/role`, { data: { ids } });
export const removeFromGroup = (id, roleGroupId) => api.delete(`/role/${id}/group/${roleGroupId}`);
export const removeSingle = id => api.delete(`/role/${id}`);
export const update = (id, data) => api.patch(`/role/${id}`, data);
export const create = data => api.put(`/role`, data);
export const assignToUsers = (id, userIds) => api.post(`/role/${id}/assign`, { userIds });
export const removeFromUsers = (id, userIds) => api.post(`/role/${id}/remove`, { userIds });
