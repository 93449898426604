import React from 'react';

import { mapResultsForSelect } from 'pages/PageClient/Single/VerificationResult/CheckList/Fields/MapResultsForSelect';
import { ICheckListFieldProps } from 'pages/PageClient/Single/VerificationResult/CheckList/Fields/types';
import { Select } from 'pages/PageClient/Single/VerificationResult/CheckList/styled';

interface SingleChoiceProps extends ICheckListFieldProps {
  value: number
}

export const SingleChoice: React.FC<SingleChoiceProps> = ({ isDisabled, onChange, value, verificationResults }) => (
  <Select
    disabled={isDisabled}
    // @ts-ignore
    onChange={onChange}
    options={mapResultsForSelect(verificationResults)}
    value={value}
  />
);
