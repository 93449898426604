import React from 'react';

import { copyToClipboard } from 'helper';

import { CopyOutlined } from '@ant-design/icons';
import { EntityCardTitle } from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardRow } from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';

import {
  ExternalLink,
  MttPhoneButtonStyled,
} from './styled';

export const WorkInfoItem = ({
  index,
  organizationName,
  organizationOfficialFlg,
  organizationPhone,
  organizationPosition,
}) => (
  <>
    <EntityCardTitle>
      {`#${index + 1} - ${organizationName} (устроен ${organizationOfficialFlg ? `` : `не`}официально)`}

      <EntityCardTitleControl
        actionTitle='Копировать'
        onClick={() => copyToClipboard(organizationName)}
      >
        <CopyOutlined />
      </EntityCardTitleControl>
    </EntityCardTitle>
    <EntityCardRow title='Должность' valueToCopy={organizationPosition}>
      {organizationPosition}
    </EntityCardRow>
    <EntityCardRow title='Телефон' valueToCopy={organizationPhone}>
      <ExternalLink href={`tel:${organizationPhone}`}>{organizationPhone}</ExternalLink>
      <MttPhoneButtonStyled phone={organizationPhone} />
    </EntityCardRow>
  </>
);

