import styled from 'styled-components';
import {
  getLightStyleOr,
  palette,
} from 'styles/theme';

import {
  Button,
  Collapse as CollapseDefault,
  Form,
  InputNumber,
  Modal,
} from 'antd';

const { Panel: PanelDefault } = CollapseDefault;
export const StyledInputNumber = styled(InputNumber)`width: 200px;`;
export const Panel = styled(PanelDefault)`
  padding: 0;
`;

export const Collapse = styled(CollapseDefault)`
  .ant-collapse-content-box {
    padding: 0;
    padding-top: 16px;
  }
  margin-bottom: 16px;
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 24px 0;
  }

  .ant-collapse-header-text {
    flex: 1 !important;
  }

  .ant-form-item-control-input {
    min-height: unset !important;
  }

  .ant-collapse {
    background-color: ${getLightStyleOr(palette.whiteColor, palette.black10Color)} !important;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-header {
    background-color: ${getLightStyleOr(palette.whiteColor, palette.black10Color)} !important;
  }
`;

export const FormItemStyled = styled(Form.Item)`
  padding: 0 24px;
  .ant-form-item-label > label {
    font-weight: 500;
    font-size: 16px !important;
  }
`;

export const FormItemHeaderStyled = styled(FormItemStyled)`
  .ant-form-item-label > label {
    font-size: 18px !important;
    font-weight: 600;
  }
`;

export const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 4px;
`;

export const PanelHeaderTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;

export const PanelHeaderControls = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export const PanelHeaderButton = styled(Button)`
  color: ${palette.whiteColor};
  margin-left: 8px;
`;
