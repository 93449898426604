import * as React from 'react';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { Input } from 'components/Messenger/Input/Input';
import { MessagesList } from 'components/Messenger/MessagesList';
import {IMessage} from "components/Messenger/types";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${palette.backgroundColor};
`;

const MessengerFrame = styled.div`
  width: 100%;
  height: 100%;
  max-width: 680px;
  border: 1px solid ${palette.black10Color};

  background: ${palette.black5Color};
  display: flex;
  flex-direction: column;
`;

interface IComponentProps {
  dialog?: any;
  notificationTitle?: string;
}

export const Messenger: React.FC<IComponentProps> = ({ dialog, notificationTitle }) => {
  const [messageToEdit, setMessageToEdit] = React.useState<IMessage | null>(null);
  const [messageToReply, setMessageToReply] = React.useState<IMessage | null>(null);
  const [messageToDelete, setMessageToDelete] = React.useState<IMessage | null>(null);

  return (
    <Wrapper>
      <MessengerFrame>
        <MessagesList
          dialog={dialog}
          onDelete={(message: IMessage | null) => setMessageToDelete(message)}
          onEdit={setMessageToEdit}
          onReply={setMessageToReply}
        />

        <Input
          dialog={dialog}
          messageToDelete={messageToDelete}
          messageToEdit={messageToEdit}
          messageToReply={messageToReply}
          notificationTitle={notificationTitle}
          setMessageToDelete={setMessageToDelete}
          setMessageToEdit={setMessageToEdit}
          setMessageToReply={setMessageToReply}
        />
      </MessengerFrame>
    </Wrapper>
  );
};
