import * as React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { palette } from 'styles/theme';

import { CopyIconButton } from 'components/CopyIconButton/CopyIconButton';
import { entityCardRowTextMixin } from 'pages/PageClient/Single/components/EntityCard/EntityCard';

const Wrapper = styled.div``;

const CopyButton = styled(CopyIconButton)``;

interface IComponentProps {
  children?: React.ReactNode;
  title: string;
  valueToCopy?: string | number | any;
}

const cellMixin = css`
  padding: 8px 16px;
  display: flex;
`;

const TitleCell = styled.div`
  ${cellMixin};
`;

const Title = styled.span`
  ${entityCardRowTextMixin};
  color: ${palette.textSecondaryColor};
`;

const ValueCell = styled.div`
  ${cellMixin};
`;

const Value = styled.div`
  ${entityCardRowTextMixin};
  display: flex;
  color: ${palette.textPrimaryColor};
`;

export const EntityDetailsRowVertical: React.FC<IComponentProps> = props => {
  const { children, title, valueToCopy } = props;

  return !_.isNil(children) && (
  <Wrapper>
    <TitleCell>
      <Title>
        {title}
      </Title>
    </TitleCell>
    <ValueCell>
      <Value>
        {children}
        {Boolean(valueToCopy) && (
          <CopyButton valueToCopy={valueToCopy} />
        )}
      </Value>
    </ValueCell>
  </Wrapper>
  );
};
