import _ from 'lodash';

import { REQUIRED } from 'constants/FORM_VALIDATION_RULES';
import {
  prettifyNumber,
  validateCarNumber,
  validateVinLength,
  validateVinSybmols,
} from 'helper';

import {
  Input,
  InputNumber,
} from 'antd';

export const FORM_FIELDS = {
  vin: {
    title    : `VIN`,
    Component: Input,
    rules    : [
      {
        validator: (pass: any, v: any) => {
          if (!validateVinLength(v)) return Promise.reject(new Error(`Длина 11 или 17 символов`));
          return Promise.resolve();
        },
      },
      {
        validator: (pass: any, v: any) => {
          if (!validateVinSybmols(v)) return Promise.reject(new Error(`Только англ буквы и цифры`));
          return Promise.resolve();
        },
      },
      REQUIRED,
    ],
  },
  carNumber: {
    title    : `Гос. номер`,
    Component: Input,
    rules    : [
      {
        validator: (pass: any, v: any) => {
          if (!validateCarNumber(v)) return Promise.reject(new Error(`Латиница, формат A111AA77 или A111AA777`));
          return Promise.resolve();
        },
      },
      REQUIRED,
    ],
  },
  vehicleChassisNumber: {
    title    : `Номер шасси`,
    Component: Input,
    rules    : [
      {
        validator: (pass: any, v: any) => {
          if (!validateVinLength(v)) return Promise.reject(new Error(`Длина 11 или 17 символов`));
          return Promise.resolve();
        },
      },
      {
        validator: (pass: any, v: any) => {
          if (!validateVinSybmols(v)) return Promise.reject(new Error(`Только англ буквы и цифры`));
          return Promise.resolve();
        },
      },
      REQUIRED,
    ],
  },
  vehicleBodyNumber: {
    title       : `Номер кузова`,
    Component   : Input,
    dependencies: [`vin`],
    rules       : [
      {
        validator: (pass: any, v: any) => {
          if (!validateVinLength(v)) return Promise.reject(new Error(`Длина 11 или 17 символов`));
          return Promise.resolve();
        },
      },
      {
        validator: (pass: any, v: any) => {
          if (!validateVinSybmols(v)) return Promise.reject(new Error(`Только англ буквы и цифры`));
          return Promise.resolve();
        },
      },
      REQUIRED,
      // @ts-ignore
      ({ getFieldValue }) => ({
        validator: (pass: any, v: any) => {
          if (v !== getFieldValue(`vin`)) return Promise.reject(new Error(`Номер кузова не совпадает с VIN`));
          return Promise.resolve();
        },
      }),
    ],
  },
  pledgeCost: {
    title    : `Залоговая стоимость`,
    Component: Input,
    normalize: prettifyNumber,
    rules    : [
      REQUIRED,
      {
        validator: (pass: any, value: number) => {
          if (value < 0) return Promise.reject(new Error(`Стоимость не может быть меньше 0`));
          return Promise.resolve();
        },
      },
    ],
  },
  cost: {
    title    : `Цена`,
    normalize: prettifyNumber,
    Component: InputNumber,
    rules    : [
      REQUIRED,
      {
        validator: (pass: any, value: number) => {
          if (value < 0) return Promise.reject(new Error(`Цена не может быть меньше 0`));
          return Promise.resolve();
        },
      },
    ],
  },
  make: {
    title    : `Марка`,
    Component: Input,
    rules    : [
      {
        validator: (pass: any, v: string) => {
          if (!/^[a-zA-Z\s-]+$/gi.test(v)) {
            return Promise.reject(new Error(`Только латинские буквы, пробел, дефис`));
          }
          return Promise.resolve();
        },
      },
      REQUIRED,
    ],
  },
  model: {
    title    : `Модель`,
    Component: Input,
    rules    : [
      {
        validator: (pass: any, v: string) => {
          if (!/^[0-9a-zA-Z\s-]+$/gi.test(v)) {
            return Promise.reject(new Error(`Только латинские буквы, пробел, дефис, цифры`));
          }
          return Promise.resolve();
        },
      },
      REQUIRED,
    ],
  },
  color: {
    title    : `Цвет`,
    Component: Input,
    rules    : [
      {
        validator: (pass: any, v: string) => {
          if (!/^[а-яА-Я\s-]+$/gi.test(v)) {
            return Promise.reject(new Error(`Заполните цвет (русские буквы, пробел, дефис)`));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  year: {
    title    : `Год выпуска`,
    Component: InputNumber,
    rules    : [
      {
        validator: (pass: any, v: string) => {
          if (!/^[0-9]{4}$/gi.test(v)) return Promise.reject(new Error(`Заполните год (4 цифры)`));
          return Promise.resolve();
        },
      },
    ],
  },
  ptsSerial: {
    title    : `ПТС, серия`,
    Component: Input,
    rules    : [
      {
        validator: (pass: any, v: string) => {
          if (!/^[а-яА-Я0-9]+$/gi.test(v)) return Promise.reject(new Error(`Заполните цифрами или русскими буквами`));
          return Promise.resolve();
        },
      },
    ],
  },
  ptsNumber: {
    title    : `ПТС, номер`,
    Component: Input,
    rules    : [
      {
        validator: (pass: any, v: string) => {
          if (!/^[0-9]+$/gi.test(v)) return Promise.reject(new Error(`Заполните номер ПТС (только цифры)`));
          return Promise.resolve();
        },
      },
    ],
  },
  id                 : { title: `Идентификатор` },
  creditFlg          : { title: `Кредитный`, normalize: (v: any) => (v ? `Да` : `Нет`) },
  owner              : { title: `Владелец` },
  vehicleEngineNumber: { title: `Номер двигателя` },
};

// @ts-ignore
export const CAR_FIELD_NAMES = _.compact(_.map(FORM_FIELDS, ({ Component }, key) => (Component ? key : null)));
