import _ from 'lodash';
import AceEditor from 'react-ace';

import { JSONParse } from 'helper';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/ext-language_tools';

const JSON_FIELD = (key, title, restProps = {}) => ({
  Component        : AceEditor,
  key,
  initialValue     : `{}`,
  mode             : `json`,
  normalizeOnSubmit: v => (_.isString(v) ? JSONParse(v) : v),
  setOptions       : {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion : true,
    enableSnippets           : true,
    showLineNumbers          : true,
    tabSize                  : 2,
  },
  style: {
    maxWidth: `100%`,
  },
  theme                : `solarized_dark`,
  title,
  transformInitialValue: v => (_.isObject(v) ? JSON.stringify(v, null, 2) : v),
  ...restProps,
});

export default JSON_FIELD;
