import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

export const getState = (state: any) => _.get(state, model, {});

export const getCalls = createSelector(getState, ({ calls }) => calls);

export const getClientCalls = createSelector(
  [getCalls, (state, personId) => personId],
  (calls, personId) => calls[personId] || [],
);

export const getIsLoading = createSelector(getState, ({ isLoading }) => isLoading);

export const getTableState = createSelector(getState, ({ tableState }) => tableState);
