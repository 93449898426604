import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import {
  get as getAction,
  setTableState as setTableStateAction,
} from 'models/courts/actions';
import { COLUMNS } from 'models/courts/constants';
import { useTableState } from 'models/courts/hooks';
import {
  getItems as getItemsSelector,
  isLoading as isLoadingSelector,
} from 'models/courts/selectors';
import { ICourtData } from 'models/courts/types';
import { useDictionaries } from 'models/dictionaries/hooks';
import { useSearchInTasks } from 'models/task/hooks';

import {
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button as ButtonDefault } from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { CourtModal } from 'components/Modal/CourtModal/CourtModal';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';

const Button = styled(ButtonDefault)`
  &+& {
    margin-left: 8px;
  }
`;

const AddButton = styled(ButtonDefault)`
  max-width: 300px;
  margin-bottom: 16px;
`;

interface IProps {
  loanId?: string;
  personId?: string;
  setCourtIds?: (ids: string[]) => void;
  tableScrollY: number;
}
export const CourtTable: React.FC<IProps> = ({ loanId, personId, setCourtIds, tableScrollY }) => {
  const dispatch = useDispatch();

  const getItems = useCallback(() => dispatch(getAction()), [dispatch]);
  const [isDictionariesLoading, dictionaries] = useDictionaries([`enforcementProceedingStatus`]);

  const [tableState, onTableChange] = useTableState(personId);
  const setTableState = useCallback(state => dispatch(setTableStateAction(state)), [dispatch]);

  const { isLoading, items } = useSelector(state => ({
    isLoading: isLoadingSelector(state),
    items    : getItemsSelector(state),
  })) as { isLoading: boolean, items: ICourtData[] };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCourt, setSelectedCourt] = useState<ICourtData | null>(null);
  const openModal = (court: ICourtData) => {
    setSelectedCourt(court);
    setIsModalOpen(true);
  };
  const openCreateModal = () => {
    setSelectedCourt(null);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCourt(null);
  };

  const onTableColumnsChange = () => {
    const columns = tableState?.columns || {};

    setTableState({
      ...(tableState || {}),
      columns,
    });
  };

  useEffect(getItems, [personId]);

  const goToTasks = useSearchInTasks();
  const onSearchInTasks = (_personId: string) => goToTasks(_personId);
  const columns = COLUMNS({ dictionaries, tableState });
  return (
    <>
      {personId && (
        <AddButton onClick={openCreateModal}>Добавить судебное производство</AddButton>
      )}
      <AdjustableTable
        columns={columns}
        dataSource={items}
        defaultColumns={tableState?.columns || _.map(columns, `key`)}
        filters={tableState?.filters}
        loading={isDictionariesLoading || isLoading}
        onChange={onTableChange}
        onTableColumnsChange={onTableColumnsChange}
        pagination={tableState?.pagination}
        preferences={{
          path      : `courts`,
          cellRender: (_pass, item:ICourtData) => (
            <>
              <DefaultTooltip placement='left' title='Редактировать'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => openModal(item)}
                  shape='circle'
                  type='primary'
                />
              </DefaultTooltip>
              {!personId && (
                <DefaultTooltip placement='left' title='Искать во взыскании'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => onSearchInTasks(item?.personId)}
                    shape='circle'
                    type='primary'
                  />
                </DefaultTooltip>
              )}
            </>
          ),
        }}
        preferencesWidth={!personId ? 88 : 44}
        rowKey='courtId'
        rowSelection={_.isFunction(setCourtIds) ? {
          onChange: (ids: string[]) => setCourtIds(ids),
        } : undefined}
        scroll={{
          scrollToFirstRowOnChange: true,
          x                       : _.isEmpty(items)
            ? undefined
            : `100%`,
          y: tableScrollY,
        }}
        scrollableHeader
      />
      <CourtModal
        courtId={selectedCourt?.courtId}
        isVisible={isModalOpen}
        loanId={selectedCourt?.loanId || loanId}
        onClose={closeModal}
        onSuccess={getItems}
        personId={selectedCourt?.personId || personId}
      />
    </>
  );
};
