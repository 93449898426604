import { createAction } from 'redux-actions';

export const model = `communications`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const ADD_ITEM = `ADD_ITEM`;
export const CHECK_MODAL_MINIMIZE = `CHECK_MODAL_MINIMIZE`;
export const CREATE = `CREATE`;
export const GET_EDITABLE_ID = `GET_EDITABLE_ID`;
export const OPEN_EDIT_MODAL = `OPEN_EDIT_MODAL`;
export const REMOVE_LAST_COMMUNICATION = `REMOVE_LAST_COMMUNICATION`;
export const REPLACE_ITEM = `REPLACE_ITEM`;
export const RESET_MODAL = `RESET_MODAL`;
export const SEARCH = `SEARCH`;
export const SET_EDITABLE_ID = `SET_EDITABLE_ID`;
export const SET_FILTERS = `SET_FILTERS`;
export const SET_ITEMS = `SET_ITEMS`;
export const SET_LOADING = `SET_LOADING`;
export const SET_MODAL = `SET_MODAL`;
export const SET_SORTER = `SET_SORTER`;
export const UPDATE = `UPDATE`;

export const create = ca(CREATE);
export const openEditModal = ca(OPEN_EDIT_MODAL);
export const removeLastCommunication = ca(REMOVE_LAST_COMMUNICATION);
export const resetModal = ca(RESET_MODAL);
export const search = ca(SEARCH);
export const setFilters = ca(SET_FILTERS);
export const setLoading = ca(SET_LOADING);
export const setModal = ca(SET_MODAL);
export const setSorter = ca(SET_SORTER);
export const update = ca(UPDATE);
