import {intercom} from "./intercom";

export const socketState = {
  connected: false,
};

intercom.subscribe(
  `SOCKET_CONNECT`,
  (connected: boolean) => {
    console.log(`socket.io connection ${connected ? `established` : `lost`}`); // eslint-disable-line no-console
    socketState.connected = (connected as unknown as boolean);
  },
);

intercom.subscribe(`SOCKET_CONNECT_ERROR`, (error: any) => {
  console.log(`socket.io connection error`, error.payload); // eslint-disable-line no-console
});

// @ts-ignore
export const socketEmit = (eventName: string, data: any) => intercom.postRequest(`SOCKET_EMIT`, [eventName, data]);
