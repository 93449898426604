import React from 'react';
import _ from 'lodash';

import { Modal } from 'antd';

const DANGER = { danger: true };

const RemoveModal = ({
  ids,
  items: _items,
  close,
  onSubmit: _onSubmit,
  namePath = `name`,
  modalTitle = `Удаление записей`,
  question = `Вы действительно хотите удалить эти записи?`,
  visible,
}) => {
  const items = _.filter(_items, ({ id }) => _.includes(ids, id));

  const itemNames = _.map(items, _.isFunction(namePath)
    ? namePath
    : item => (
      <div key={_.get(item, namePath)}>
        {_.get(item, namePath)}
      </div>
    ));

  const onSubmit = () => {
    _onSubmit();
    close();
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      maskClosable={false}
      okButtonProps={DANGER}
      okText='Удалить'
      onCancel={close}
      onOk={onSubmit}
      open={visible}
      title={modalTitle}
    >
      {question}
      {itemNames}
    </Modal>
  );
};

export default RemoveModal;
