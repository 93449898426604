import React from 'react';
import _ from 'lodash';
import AceEditor from 'react-ace';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/ext-language_tools';

const JSON_COLUMN = (key, title, height, restProps = {}) => ({
  align : `left`,
  key,
  render: v => (
    <AceEditor
      height={height || `200px`}
      id={`${key}_${title}`}
      mode='json'
      readOnly
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion : false,
        enableSnippets           : false,
        showLineNumbers          : true,
        tabSize                  : 2,
      }}
      theme='solarized_dark'
      value={_.isObject(v) ? JSON.stringify(v, null, 2) : v}
    />
  ),
  title,
  width: 400,
  ...restProps,
});

export default JSON_COLUMN;
