import _ from 'lodash';

export const getHotkeyTitle = (hotkey: string | null | undefined) => {
  const symbols = _.split(hotkey, `+`);

  return _.join(_.map(symbols, symbol => {
    if (symbol === `shift`) {
      return `⇧`;
    }

    return symbol;
  }), ``);
};
