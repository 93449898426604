import _ from 'lodash';

interface IAdditionalData {
  taskId?: number;
  verificationId?: number;
}

interface IModalData extends IAdditionalData {
  personId: string;
}

interface IGetDataFromPath {
  modalData: IModalData;
  path: string;
}

export const getDataFromPath = (location: any):IGetDataFromPath => {
  let path = _.get(location, `pathname`) || window.location.pathname;
  const pathArray = _.split(path, `/`);
  if (_.size(pathArray) > 2) path = `/${_.nth(pathArray, 1)}`;
  let personIdPathIndex;
  const additionalData:IAdditionalData = {};
  switch (pathArray[1]) {
    case `client`:
      personIdPathIndex = 3;
      break;
    case `tasks`:
      // @ts-ignore
      additionalData.taskId = _.toNumber(pathArray[3]) || null;
      personIdPathIndex = 5;
      break;
    case `verification`:
      // @ts-ignore
      additionalData.verificationId = _.toNumber(pathArray[3]) || null;
      personIdPathIndex = 4;
      break;
  }
  if (!_.has(additionalData, `taskId`)) {
    // @ts-ignore
    additionalData.taskId = null;
  }
  // @ts-ignore
  if (!_.has(additionalData, `verificationId`)) additionalData.verificationId = null;

  return {
    modalData: {
      ...additionalData,
      // @ts-ignore
      personId: personIdPathIndex
        ? pathArray[personIdPathIndex] || null
        : null,
    },
    path,
  };
};
