/* eslint-disable max-len */
import React, {
  useContext,
  useMemo,
} from 'react';
import _ from 'lodash';

import {
  formatDate,
  prettifyNumber,
} from 'helper';

import { APPLICATION_OPERATION_STATUSES } from 'models/clients/constants';

import { Descriptions } from 'antd';
import MobileContext from 'pages/Layout/MobileContext';

const DETAILS = [
  {
    label: `ID операции`,
    prop : `operationId`,
  },
  {
    label: `Статус`,
    prop : `operationStatusId`,
  },
  {
    label: `Сумма, ₽`,
    prop : `sum`,
  },
  {
    label: `Дата создания`,
    prop : `createDtm`,
  },
  {
    label: `Платёжный шлюз`,
    prop : `paymentGateway`,
  },
  {
    label: `Дата ошибки`,
    prop : `errorDtm`,
  },
  {
    label: `Описание ошибки`,
    prop : `errorDescription`,
  },
];

const PRETTIFY_NUMBER_FIELDS = [
  `sum`,
];

const FORMAT_DATE_FIELDS = [
  `createDtm`,
  `errorDtm`,
];

const ApplicationOperation = ({ operation }) => {
  const mobile = useContext(MobileContext);
  const column = mobile ? 1 : _.size(DETAILS);
  const layout = mobile ? `horizontal` : `vertical`;

  const title = `Операция ${operation?.operationId}`;
  const normalizedOperation = useMemo(() => ({
    ...operation,
    ..._.reduce(operation, (result, v, k) => ({
      ...result,
      [k]: _.includes(PRETTIFY_NUMBER_FIELDS, k)
        ? prettifyNumber(v)
        : (_.includes(FORMAT_DATE_FIELDS, k) && (v > 0) ? formatDate(v) : v),
    }), {}),
    operationStatusId: APPLICATION_OPERATION_STATUSES[operation.operationStatusId] || APPLICATION_OPERATION_STATUSES.default,
  }), [operation?.operationId]);

  if (_.isEmpty(operation)) return null;
  return (
    <Descriptions
      bordered
      column={column}
      layout={layout}
      size='small'
      title={title}
    >
      {_.map(_.filter(DETAILS, ({ prop }) => !_.isNil(_.get(normalizedOperation, prop))), ({ label, prop }) => (
        <Descriptions.Item key={label} label={label}>{_.get(normalizedOperation, prop)}</Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default ApplicationOperation;
