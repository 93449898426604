import _ from 'lodash';

import { useDictionaries } from 'models/dictionaries/hooks';
import { IVerificationResultType } from 'models/dictionaries/types';
import { useVerification } from 'models/verifications/hooks/useVerification';

export const useVerificationResultsTypeIdsForVerification = (verificationId: number): number[] => {
  const [, dictionaries] = useDictionaries<{
    verificationResultType: IVerificationResultType[]
  }>([`verificationResultType`]);
  const [, verification] = useVerification(verificationId);

  let verificationResultTypes: IVerificationResultType[];
  if (verification?.scriptId) {
    verificationResultTypes = _.filter(
      dictionaries?.verificationResultType,
      ({ productId, scriptIds }) => (
        _.includes(scriptIds, verification.scriptId)
        && (!productId || _.includes(productId, _.get(verification, `application.productId`)))
      ),
    );
  } else {
    verificationResultTypes = _.reject(dictionaries?.verificationResultType, `scriptIds`);
  }
  return _.map(verificationResultTypes, `id`);
};
