import React, {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment from 'moment';

import { hasIntersection } from 'helper';

import {
  create as createAction,
  get as getItemsAction,
  update as updateAction,
  uploadFile as uploadFileAction,
} from 'models/actions/actions';
import {
  getItems as getItemsSelector,
  isLoaded as isLoadedSelector,
  isLoading as isLoadingSelector,
} from 'models/actions/selectors';
import { ROLE_NAMES } from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { useModalHandlers } from 'hooks/useModalHandlers';

import {
  EditOutlined,
  FileExcelOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import CreateModal from 'components/Modal/Create';
import { AdminPluralLayout } from 'pages/PageAdmin/components/AdminPluralLayout';
import { AdminSearchWithControls } from 'pages/PageAdmin/components/AdminSearchWithControls';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';

import UploadModal from './Modal/Upload';

import {
  COLUMNS,
  DISABLED_TOOLTIP,
  FORM_FIELDS,
} from './constants';

const formatData = (data: { dates: _.List<string | number | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | null> | null | undefined; }, includeId = false) => ({
  ...(includeId ? _.pick(data, [`id`]) : {}),
  ..._.pick(data, [
    `name`,
    `description`,
    `additionalInfo`,
    `technicalActionFlg`,
  ]),
  startDtm: moment(_.head(data.dates))
    .utc()
    .startOf(`day`),
  endDtm: moment(_.last(data.dates))
    .utc()
    .endOf(`day`),
});

const getInitialValues = (item: { startDtm: moment.MomentInput; endDtm: moment.MomentInput; }) => ({
  ...item,
  dates: [moment.utc(item.startDtm), moment.utc(item.endDtm)],
});

interface IProps {}

const ActionsPlural: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(isLoadedSelector);
  const isLoading = useSelector(isLoadingSelector);
  const items = useSelector(getItemsSelector);
  const userRoles = useSelector(getRoleNamesSelector);

  const {
    closeEditModal,
    isCreateModalOpen,
    isEditModalOpen,
    itemEditing,
    setItemEditing,
    toggleCreateModal,
    toggleEditModal,
  } = useModalHandlers();

  const getItems = () => dispatch(getItemsAction());

  useEffect(() => {
    if (!isLoaded) getItems();
  }, [isLoaded]);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const toggleUploadModal = () => setIsUploadModalOpen(!isUploadModalOpen);

  const onCreate = (data: { dates: _.List<string | number | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | null> | null | undefined; }) => dispatch(createAction(formatData(data)));

  const onUpdate = (data: { dates: _.List<string | number | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | null> | null | undefined; }) => dispatch(updateAction(formatData(data, true)));

  const uploadExcel = (file: any) => dispatch(uploadFileAction({ file }));

  const canEdit = hasIntersection(userRoles, [
    ROLE_NAMES.SUPER_ADMIN,
    ROLE_NAMES.ACTION_EDITOR,
  ]);

  return (
    <>
      <AdminSearchWithControls
        controls={[
          {
            icon   : <SyncOutlined />,
            title  : `Перезагрузить`,
            onClick: getItems,
          },
          {
            icon        : <FileExcelOutlined />,
            title       : `Загрузить файл`,
            onClick     : toggleUploadModal,
            tooltipTitle: canEdit ? `Загрузить файл` : DISABLED_TOOLTIP,
            isDisabled  : !canEdit,
          },
          {
            title       : `Добавить акцию`,
            icon        : <PlusCircleOutlined />,
            onClick     : toggleCreateModal,
            tooltipTitle: canEdit ? `Добавить акцию` : DISABLED_TOOLTIP,
            isDisabled  : !canEdit,
          },
        ]}
        search={{
          isHidden: true,
        }}
      />

      <AdminPluralLayout>
        <AdjustableTable
          columns={COLUMNS()}
          dataSource={items}
          loading={isLoading}
          pagination={{
            hideOnSinglePage: true,
            pageSize        : _.size(items),
            size            : `small`,
          }}
          preferences={{
            path      : `admin.actions`,
            cellRender: (value, record) => (
              <div>
                {record.id > 0 && (
                  <DefaultTooltip placement='topRight' title={canEdit ? `Редактировать` : DISABLED_TOOLTIP}>
                    <Button
                      disabled={!canEdit}
                      icon={<EditOutlined />}
                      onClick={() => {
                        setItemEditing(record);
                        toggleEditModal();
                      }}
                      type='primary'
                    />
                  </DefaultTooltip>
                )}
              </div>
            ),
          }}
          scroll={{ x: `max-content`, y: window.innerHeight - 250 }}
        />

        <CreateModal
          close={toggleCreateModal}
          create={onCreate}
          formFields={FORM_FIELDS}
          title='Новая акция'
          visible={isCreateModalOpen}
        />

        <CreateModal
          close={closeEditModal}
          formFields={FORM_FIELDS}
          getInitialValues={getInitialValues}
          isLoading={isLoading}
          item={itemEditing}
          mode='update'
          title={`Редактирование акции ${_.get(itemEditing, `name`, ``)}`}
          update={onUpdate}
          visible={isEditModalOpen}
        />

        <UploadModal
          isVisible={isUploadModalOpen}
          onCancel={toggleUploadModal}
          onUpload={uploadExcel}
        />

      </AdminPluralLayout>
    </>
  );
};

export default ActionsPlural;
