/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';

import { useDictionaries } from 'models/dictionaries/hooks';
import {
  getTaskCreditHistory,
  getTaskVector,
} from 'models/task/selectors';

import { Empty } from 'antd';

import {
  Block,
  BlockContent,
  BlockTitle,
  Row,
  RowLabel,
  RowValue,
} from './styled';

interface IProps {
  taskId: number;
}

const Vector: React.FC<IProps> = ({ taskId }) => {
  useDictionaries([`creditHistoryField`]);
  const creditHistory = useSelector(state => getTaskCreditHistory(state, taskId));
  const vector = useSelector(state => getTaskVector(state, taskId));

  return (
    <>
      {(_.isEmpty(creditHistory) && _.isEmpty(vector)) && (
      <Empty
        description='Данных нет'
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
      )}
      {!_.isEmpty(creditHistory) && (
        <Block>
          <BlockTitle>Кредитная история</BlockTitle>
          <BlockContent>
            {_.map(creditHistory, ({ label, value }) => (
              <Row key={label}>
                <RowLabel>
                  {label}
                </RowLabel>
                <RowValue>{value}</RowValue>
              </Row>
            ))}
          </BlockContent>
        </Block>
      )}
      {!_.isEmpty(vector) && (
        <Block>
          <BlockTitle>Вектор</BlockTitle>
          <BlockContent>
            {_.map(vector, ({ label, value }) => (
              <Row key={label}>
                <RowLabel>
                  {label}
                </RowLabel>
                <RowValue>{value}</RowValue>
              </Row>
            ))}
          </BlockContent>
        </Block>
      )}
    </>
  );
};

export default Vector;
