import * as React from 'react';
import _ from 'lodash';

import { PaperClipOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { InputIconButton } from 'components/Messenger/Input/InputIconButton';

interface IComponentProps {
  accept: string;
  customRequest: (file: string | RcFile | Blob) => void;
  onClick?: () => void;
}

export const InputUploadButton: React.FC<IComponentProps> = ({ accept, customRequest, onClick = _.noop }) => (
  <Upload
    accept={accept}
    customRequest={({ file }) => customRequest(file)}
    multiple
    // @ts-ignore
    onClick={onClick}
    showUploadList={false}
  >
    <InputIconButton>
      <PaperClipOutlined />
    </InputIconButton>
  </Upload>
);
