import React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  palette,
} from 'styles/theme';

import { Button as ButtonDefault } from 'antd';

const Button = styled(ButtonDefault)`
  margin-left: 16px;
`;

const ButtonPrimary = styled(Button)`
  color: ${palette.whiteColor};
  background: ${palette.primary500Color};
`;

const ButtonsWrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  margin-left: -16px;
  background-color: ${palette.whiteColor};
  width: 100vw;
  padding: 16px 60px;
  justify-content: flex-end;
  border-top: 1px solid ${palette.black10Color};

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    width: 100%;
    right: unset;
    left: 0;
    padding: 16px;

    ${Button} {
      margin-left: 0;
      margin-bottom: 8px;
      width: calc(100% - 32px);
    }
  }
`;

interface IProps {
  clearResults: () => void;
  openUploadModal: () => void;
  updateResults: (options: { clear: boolean; omitNotification: boolean }) => void;
}

export const BottomButtons: React.FC<IProps> = ({ clearResults, openUploadModal, updateResults }) => (
  <ButtonsWrapper>
    <Button onClick={clearResults}>
      Очистить
    </Button>
    <Button onClick={() => openUploadModal()}>
      Загрузить документ
    </Button>
    <ButtonPrimary onClick={() => updateResults({ clear: false, omitNotification: false })}>
      Сохранить
    </ButtonPrimary>
  </ButtonsWrapper>
);
