export const APPLICATION_STATUSES = {
  NEW                            : 1,
  SIGNING_FIRST_DOCUMENTS_PACKAGE: 3,
  VERIFICATION                   : 7,
  DENIAL                         : 11,
  APPROVAL                       : 12,
  CHOOSING_OFFER                 : 20,
  CAR_PICTURING                  : 21,
  STS_RECOGNITION                : 23,
  ERROR                          : 999,
};

export const APPLICATION_STATUSES_COLORS = {
  [APPLICATION_STATUSES.NEW]                            : `default`,
  [APPLICATION_STATUSES.SIGNING_FIRST_DOCUMENTS_PACKAGE]: `processing`,
  [APPLICATION_STATUSES.VERIFICATION]                   : `processing`,
  [APPLICATION_STATUSES.DENIAL]                         : `pink`,
  [APPLICATION_STATUSES.APPROVAL]                       : `success`,
  [APPLICATION_STATUSES.CHOOSING_OFFER]                 : `warning`,
  [APPLICATION_STATUSES.CAR_PICTURING]                  : `processing`,
  [APPLICATION_STATUSES.STS_RECOGNITION]                : `processing`,
  [APPLICATION_STATUSES.ERROR]                          : `error`,
};

export const APPLICATION_STATUSES_NAMES = {
  [APPLICATION_STATUSES.NEW]                            : `Новая`,
  [APPLICATION_STATUSES.SIGNING_FIRST_DOCUMENTS_PACKAGE]: `Подписание первого пакета документов`,
  [APPLICATION_STATUSES.VERIFICATION]                   : `На верификации`,
  [APPLICATION_STATUSES.DENIAL]                         : `Отказ`,
  [APPLICATION_STATUSES.APPROVAL]                       : `Одобрение`,
  [APPLICATION_STATUSES.CHOOSING_OFFER]                 : `Выбор оффера`,
  [APPLICATION_STATUSES.CAR_PICTURING]                  : `Фото авто`,
  [APPLICATION_STATUSES.STS_RECOGNITION]                : `Распознавание стс`,
  [APPLICATION_STATUSES.ERROR]                          : `Ошибка`,
};

export const LOAN_STATUSES = {
  NEW        : 1,
  DEACTIVATED: 6,
  ISSUED     : 9,
  ACTIVE     : 12,
  CLOSED     : 11,
  ERROR      : 999,
};

// Не добавлен в основные LOAN_STATUSES, т.к. является не актуальным статусом займа, но агрегатором состояния договора
export const LOAN_STATUS_ASSIGNED = `assigned`;

export const LOAN_STATUSES_COLORS = {
  [LOAN_STATUSES.NEW]        : `default`,
  [LOAN_STATUSES.DEACTIVATED]: `default`,
  [LOAN_STATUSES.ISSUED]     : `default`,
  [LOAN_STATUSES.ACTIVE]     : `#40BAB5`,
  [LOAN_STATUS_ASSIGNED]     : `success`,
  [LOAN_STATUSES.CLOSED]     : `default`,
  [LOAN_STATUSES.ERROR]      : `error`,
};

export const LOAN_STATUSES_NAMES = {
  [LOAN_STATUSES.NEW]        : `Новый`,
  [LOAN_STATUSES.DEACTIVATED]: `Деактивированный`,
  [LOAN_STATUSES.ISSUED]     : `Произведена выдача`,
  [LOAN_STATUSES.ACTIVE]     : `Активный`,
  [LOAN_STATUS_ASSIGNED]     : `Продан`,
  [LOAN_STATUSES.CLOSED]     : `Закрытый`,
  [LOAN_STATUSES.ERROR]      : `Ошибка`,
};

export const APPLICATION_OPERATION_STATUSES = {
  9      : `Успешная операция`,
  11     : `Повторное одобрение`,
  12     : `Успешная операция`,
  999    : `Ошибка`,
  default: `В процессе`,
};

export const INSURANCE_PRODUCT_IDS = [12, 13, 14];

export const SIGN_DOCUMENT_STATUSES = {
  DRAFT : [6],
  NEW   : [1, 2],
  SIGNED: [5],
  ERROR : [4, 999],
};

export const SIGN_DOCUMENT_STATUS_NAMES = {
  DRAFT : `Черновики`,
  NEW   : `В процессе`,
  SIGNED: `Подписано`,
  ERROR : `Ошибка / просрочено`,
};

export const APPLICATION_STATUS_WEIGHTS = {
  7  : 13,
  12 : 12,
  11 : 11,
  777: 10, // на верификации с датой закрытия
  23 : 9,
  22 : 8,
  21 : 7,
  20 : 6,
  4  : 5,
  3  : 4,
  2  : 3,
  1  : 2,
  999: 1,
};

export const REPAYMENT_STATUSES = {
  1  : `Новая`,
  12 : `Успех`,
  999: `Ошибка`,
};

export const MARKETING_OFFERS_EXCEL_COLUMNS = [
  {
    name  : `author`,
    format: `string`, // общий
  },
  {
    name  : `personId`,
    format: `string`, // общий
  },
  {
    name  : `productId`,
    format: `number`, // общий
  },
  {
    name  : `totalLimit`,
    format: `number`, // общий
  },
  {
    name  : `insuranceFlg`,
    format: `boolean`, // ИСТИНА/ЛОЖЬ
  },
  {
    name  : `insuranceAmount`,
    format: `number`, // общий
  },
  {
    name  : `insuranceProductId`,
    format: `number`, // общий
  },
  {
    name  : `insuranceTerm`,
    format: `number`, // общий
  },
  {
    name  : `limit`,
    format: `number`, // общий
  },
  {
    name  : `term`,
    format: `number`, // общий
  },
  {
    name  : `rate`,
    format: `number`, // общий
  },
  {
    name  : `payment`,
    format: `number`, // общий
  },
  {
    name  : `offerStatusId`,
    format: `number`, // общий
  },
  {
    name  : `startDtm`,
    format: `date`, // дата
  },
  {
    name  : `closeDtm`,
    format: `date`, // дата
  },
  {
    name  : `marketingOfferTypeId`,
    format: `number`, // общий
  },
  {
    name  : `createDtm`,
    format: `date`, // дата
  },
];
