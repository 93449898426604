import { createAction } from 'redux-actions';

export const model = `ui`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const RESET = `RESET`;
export const SET = `SET`;
export const SET_ACTIVE_TAB = `SET_ACTIVE_TAB`;
export const SET_LOADING = `SET_LOADING`;
export const SET_MODAL = `SET_MODAL`;

export const reset = ca(RESET);
export const set = ca(SET);
export const setLoading = ca(SET_LOADING);
export const setModal = ca(SET_MODAL);
