import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import moment from 'moment/moment';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  setFilters as setFiltersAction,
  setSorter as setSorterAction,
} from 'models/communications/actions';
import {
  SORT_BY,
  SORT_ORDER,
} from 'models/communications/constants';
import {
  getFilters as getFiltersSelector,
  getSorter as getSorterSelector,
} from 'models/communications/selectors';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';

import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  Button as ButtonDefault,
  DatePicker,
  Select as SelectDefault,
} from 'antd';
import MobileContext from 'pages/Layout/MobileContext';

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  &:first-child {
    padding-right: 8px;
    padding-left: 8px;
  }

  &:last-of-type {
    max-width: calc(100% - 40px);
  }

  &+& {
    border-left: 1px solid ${palette.black30Color};
    padding-left: 16px;
    margin-left: 4px;
  }

  >* {
    margin-left: 8px;
  }
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${palette.black80Color};
  margin-left: -8px;
  white-space: nowrap;
`;

const TableTitle = styled.div`
  width: 20%;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.black80Color};
  white-space: nowrap;
  padding: 12px 0 4px 0;

  &:first-of-type {
    margin-left: 24px;
  }
`;

const Select = styled(SelectDefault)`
  min-width: 150px;
  flex-grow: 1;
`;

const Button = styled(ButtonDefault)`
  max-width: fit-content;
  padding: 4px 24px !important;
`;

const RangePicker = styled(DatePicker.RangePicker)`
  flex-grow: 1;
  min-width: 280px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${palette.black10Color};
  padding: 8px 40px;
  padding-right: 16px;

  ${Section} {
    border-left: none;
    margin-left: 0;
    margin-bottom: 8px;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    width: 100%;
  }

  ${Title} {
    min-width: 100px;
    text-align: left;
    margin-left: 0;
  }

  ${Button} {
     padding: 4px;
     flex-grow: 1;
   }
`;

const MobileWrapperTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  flex-grow: 1;
`;

const MobileWrapperControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  color: ${palette.black80Color};
  padding: 12px 16px;
  cursor: pointer;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${palette.black5Color};

  ${Section}, ${Wrapper} {
    flex-direction: column;
    min-width: 100%;
  }

  ${Wrapper} {
    padding: 16px;
    padding-bottom: 8px;
  }

  ${Section} {
    >* {
      margin-bottom: 8px;
    }
  }

  ${Title} {
    align-self: flex-start;
    padding-left: 4px;
  }

  ${Button}, ${Select}, ${RangePicker} {
    min-width: 100%;
  }

  ${Button} {
    margin-top: 8px;
  }
`;

const Controls = ({ isCommunicationsDetailedView, onReload, setCommunicationsDetailedView }) => {
  const dispatch = useDispatch();
  const mobile = useContext(MobileContext);

  const { communicationTypes, filters, sorter } = useSelector(state => {
    const filtersInner = { ...getFiltersSelector(state) };
    if (!_.isEmpty(filtersInner.createdAt)) {
      filtersInner.createdAt = _.map(filtersInner.createdAt, item => {
        if (!item) return null;
        if (moment.isMoment(item)) return item;
        return moment(item);
      });
    }

    return {
      communicationTypes: getDictionarySelector(state, `communicationType`),
      filters           : filtersInner,
      sorter            : getSorterSelector(state),
    };
  });

// Stringify the filters outside useSelector
  const filtersStr = JSON.stringify(filters);

  const setSorter = useCallback(s => dispatch(setSorterAction(s)), [dispatch]);
  const setFiltersDispatch = useCallback(f => dispatch(setFiltersAction(f)), [dispatch]);

  const resetFilters = () => setFiltersDispatch({});
  const setFilters = newFilters => setFiltersDispatch({ ...filters, ...newFilters });

  useEffect(() => {
    onReload()
  }, [filtersStr]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(p => !p);

  const Content = (
    <Wrapper>
      <Section>
        <Title>Сортировать по:</Title>
        <Select
          onChange={v => setSorter({ sortBy: v })}
          options={SORT_BY}
          value={sorter.sortBy}
        />
        <Select
          onChange={v => setSorter({ sortOrder: v })}
          options={SORT_ORDER}
          value={sorter.sortOrder}
        />
        <Button
          danger={sorter.isLastCut}
          onClick={() => setSorter({ isLastCut: !sorter.isLastCut })}
          type='primary'
        >
          {sorter.isLastCut ? `Обычный режим` : `Последний срез`}
        </Button>
        <Button
          danger={!isCommunicationsDetailedView}
          onClick={() => setCommunicationsDetailedView(!isCommunicationsDetailedView)}
          type='primary'
        >
          {isCommunicationsDetailedView ? `Скрыть` : `Раскрыть`}
        </Button>
      </Section>
      <Section>
        <Title>Фильтры:</Title>
        <RangePicker
          format='DD.MM.YYYY'
          onChange={dates => setFilters({
            createdAt: dates ? [moment.utc(dates[0])
              .startOf(`day`), moment.utc(dates[1])
              .endOf(`day`)] : null,
          })}
          placeholder={[`Дата создания`, `коммуникации`]}
          value={filters.createdAt}
        />
        <Select
          allowClear
          onChange={v => setFilters({ communicationTypeId: v })}
          options={_.map(communicationTypes, ({ description, id, name }) => ({
            label: description || name,
            value: id,
          }))}
          placeholder='Коммуникация'
          value={filters.communicationTypeId}
        />
        <Button onClick={resetFilters} type='primary'>Сбросить фильтры</Button>
      </Section>
      {!mobile && (
        <Section>
          <TableTitle>Дата</TableTitle>
          <TableTitle>Блок</TableTitle>
          <TableTitle>Кампания</TableTitle>
          <TableTitle>Оператор</TableTitle>
          <TableTitle>Тип коммуникации</TableTitle>
        </Section>
      )}
    </Wrapper>
  );

  if (!mobile) return Content;
  return (
    <MobileWrapper>
      <MobileWrapperControls onClick={toggle}>
        <MobileWrapperTitle>Фильтры-сортировка</MobileWrapperTitle>
        {isOpen ? <UpOutlined /> : <DownOutlined />}
      </MobileWrapperControls>
      {isOpen && <Content />}
    </MobileWrapper>
  );
};

export default Controls;
