import _ from 'lodash';
import { createSelector } from 'reselect';

import { model } from './actions';

export const getState = state => _.get(state, model, {});

export const getItems = createSelector(getState, ({ items }) => items);
export const getItemsByIds = createSelector(
  [getItems, (state, ids) => ids],
  (items, ids) => _.filter(
    items,
    item => _.includes(ids, item?.courtId),
  ),
);

export const getItemById = createSelector(
  [getItemsByIds, (state, id) => id],
  (items, courtId) => _.find(items, { courtId }),
);

export const isLoading = createSelector(getState, ({ isLoading: _isLoading }) => _isLoading);

export const getTableState = createSelector(getState, ({ tableState }) => tableState);

export const getRequestParams = createSelector(
  getTableState,
  tableState => {
    const { filters } = tableState || {};
    const [lastName, firstName, patronymicName] = _.split(_.trim(filters?.clientFullName?.[0]), ` `);

    const enforcementProceedings = _.omitBy({
      finishStatuses: filters?.[`enforcementProceedings.finishStatus`] || null,
      sumEnforcementProceedingsInitiationDtmFrom: filters?.[`enforcementProceedings.sumEnforcementProceedingsInitiationDtm`]?.[0] || null,
      sumEnforcementProceedingsInitiationDtmTo: filters?.[`enforcementProceedings.sumEnforcementProceedingsInitiationDtm`]?.[1] || null,
    }, _.isNil);

    return _.omitBy({
      courtCase             : filters?.courtCase ? filters.courtCase[0] : null,
      contractNumber        : filters?.contractNumber ? filters.contractNumber[0] : null,
      enforcementProceedings: _.isEmpty(enforcementProceedings) ? null : enforcementProceedings,
      courtSentDtmFrom: filters?.courtSentDtm?.[0] || null,
      courtSentDtmTo: filters?.courtSentDtm?.[1] || null,
      judgmentDtmFrom: filters?.judgmentDtm?.[0] || null,
      judgmentDtmTo: filters?.judgmentDtm?.[1] || null,
      firstName     : firstName || null,
      lastName      : lastName || null,
      patronymicName: patronymicName || null,
      personIds     : filters?.personIds ?? null,
    }, _.isNil);
  },
);
