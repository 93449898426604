import _ from 'lodash';
import moment from 'moment/moment';

import {
  formatDate,
  getColumn,
  prettifyNumber,
} from 'helper';

import {
  IEnforcementProceedingValues,
  IValues,
} from 'models/courts/types';

import { getDateRangeFilter } from 'components/Table/getDateRangeFilter';

export const COLUMNS = ({ dictionaries, tableState }) => _.map([
  {
    key   : `createDtm`,
    title : `Дата`,
    render: v => formatDate(v),
    width : 120,
  },
  {
    key  : `clientFullName`,
    title: `ФИО`,
    width: 200,
  },
  {
    key  : `contractNumber`,
    title: `Номер договора`,
    width: 150,
  },
  {
    key  : `operatorName`,
    title: `Оператор`,
    width: 300,
  },
  {
    key  : `loanId`,
    title: `loanId`,
    width: 280,
  },
  {
    key  : `newOwnerFio`,
    title: `ФИО нового собственника транспортного средства (ТС)`,
    width: 340,
  },
  {
    key  : `cost`,
    title: `Оценка ТС согласно отчета оценщика`,
    width: 230,
  },
  {
    key  : `totalDebt`,
    title: `Сумма задолженности на дату формирования заявления/иска в суд`,
    width: 410,
  },
  {
    key  : `DPD`,
    title: `Количество дней просрочки на дату формирования заявления/иска`,
    width: 400,
  },
  {
    key  : `paidDuty`,
    title: `Оплаченная государственная пошлина`,
    width: 240,
  },
  {
    key  : `returnedDuty`,
    title: `Возвращенная государственная пошлина`,
    width: 260,
  },
  {
    key  : `courtName`,
    title: `Наименование суда`,
    width: 140,
  },
  {
    key   : `courtSentDtm`,
    title : `Дата направления в суд`,
    render: v => formatDate(v, `dd.MM.yyyy`),
    width : 170,
    ...getDateRangeFilter(`courtSentDtm`, tableState),
  },
  {
    key  : `courtCase`,
    title: `Номер дела в суде`,
    width: 130,
  },
  {
    key  : `courtComments`,
    title: `Замечания от суда`,
    width: 130,
  },
  {
    key   : `courtSessionDtm`,
    title : `Дата и время судебного заседания`,
    render: v => moment(v).utc().format(`DD.MM.YYYY HH:mm`),
    width : 220,
  },
  {
    key  : `provisionalMeasuresResult`,
    title: `Обеспечительные меры (результат рассмотрения)`,
    width: 300,
  },
  {
    key   : `provisionalMeasuresDtm`,
    title : `Обеспечительные меры (дата)`,
    render: v => formatDate(v, `dd.MM.yyyy`),
    width : 200,
  },
  {
    key  : `provisionalMeasuresDetails`,
    title: `Обеспечительные меры (детали)`,
    width: 200,
  },
  {
    key  : `provisionalMeasuresAppeal`,
    title: `Обжалование определения суда об отказе в принятии обеспечительных мер`,
    width: 450,
  },
  {
    key  : `judgment`,
    title: `Итоговое решение суда`,
    width: 160,
  },
  {
    key   : `judgmentDtm`,
    title : `Итоговое решение суда (Дата)`,
    render: v => formatDate(v, `dd.MM.yyyy`),
    width : 190,
    ...getDateRangeFilter(`judgmentDtm`, tableState),
  },
  {
    key  : `judgmentAppeal`,
    title: `Обжалование решения суда`,
    width: 180,
  },
  {
    key   : `judgmentEffectiveDtm`,
    title : `Дата вступления решения в законную силу`,
    render: v => formatDate(v, `dd.MM.yyyy`),
    width : 260,
  },
  {
    key  : `autoImplementationName`,
    title: `ТС передано истцу в натуре/продажа ТС`,
    width: 250,
  },
  {
    key  : `courtOrderNumber`,
    title: `Судебный приказ (номер)`,
    width: 180,
  },
  {
    key   : `courtOrderDtm`,
    title : `Судебный приказ (дата)`,
    render: v => formatDate(v, `dd.MM.yyyy`),
    width : 180,
  },
  {
    key  : `courtOrderSubject`,
    title: `Судебный приказ (предмет взыскания)`,
    width: 240,
  },
  {
    key  : `performanceListNumber`,
    title: `Исполнительный лист (номер)`,
    width: 230,
  },
  {
    key   : `performanceListDtm`,
    title : `Исполнительный лист (дата)`,
    render: v => formatDate(v, `dd.MM.yyyy`),
    width : 230,
  },
  {
    key  : `performanceListSubject`,
    title: `Исполнительный лист (предмет взыскания)`,
    width: 300,
  },
  {
    key  : `SSPName`,
    title: `Наименование ССП`,
    width: 130,
  },
  {
    key  : `SSPContact`,
    title: `Контакты ССП`,
    width: 130,
  },
  {
    key   : `courtOrderFSSPSendDtm`,
    title : `Дата направления Судебного приказа в ФССП`,
    render: v => formatDate(v, `dd.MM.yyyy`),
    width : 310,
  },
  {
    key   : `performanceListFSSPSendDtm`,
    title : `Дата направления Исполнительного листа`,
    render: v => formatDate(v, `dd.MM.yyyy`),
    width : 300,
  },
  {
    key  : `bailiffComplaints`,
    title: `Жалобы на приставов`,
    width: 200,
  },
  {
    key  : `cashClient`,
    title: `Размер поступивших ДС заемщик`,
    width: 230,
  },
  {
    key  : `cashThirdPerson`,
    title: `Размер поступивших ДС 3 лицо`,
    width: 230,
  },
  {
    key  : `cashFSSP`,
    title: `Размер поступивших ДС ФССП`,
    width: 230,
  },
  {
    key  : `updateOperatorName`,
    title: `Автор обновления`,
    width: 180,
  },
  {
    key   : `updateDtm`,
    title : `Дата обновления записи`,
    render: v => formatDate(v),
    width : 200,
  },
  {
    key   : `returnStateDutyDtm`,
    title : `Заявление в суд о возврате госпошлины (дата)`,
    render: v => formatDate(v),
    width : 300,
  },
  {
    key  : `courtCaseWorldJudge`,
    title: `Номер дела (мировой суд)`,
    width: 200,
  },
  {
    key  : `returnDefinition`,
    title: `Определение о возврате заявления/искового заявления`,
    width: 400,
  },
  {
    key  : `chComplaint`,
    title: `Частная жалоба`,
    width: 200,
  },
  {
    key  : `cancelDefaultDecision`,
    title: `Заявление об отмене заочного решения (детали)`,
    width: 300,
  },
  {
    key  : `appeal`,
    title: `Апелляция (детали)`,
    width: 150,
  },
  {
    key  : `cassation`,
    title: `Кассация (детали)`,
    width: 180,
  },
  {
    key  : `supremeCourt`,
    title: `Верховный суд (детали)`,
    width: 200,
  },
  {
    key   : `annulmentJudgmentDtm`,
    title : `Дата отмены СП`,
    render: v => formatDate(v),
    width : 180,
  },
  {
    key  : `performanceListComment`,
    title: `Исполнительный лист (комментарии)`,
    width: 230,
  },
  {
    key   : `courtProceedWritExecutionDtm`,
    title : `Дата получения СП/ИЛ в ФССП`,
    render: v => formatDate(v),
    width : 200,
  },
  {
    key  : `comment`,
    title: `Комментарий общий`,
    width: 200,
  },
  {
    key           : `enforcementProceedings.finishStatus`,
    dataIndex     : [`enforcementProceedings`, `0`, `finishStatus`],
    title         : `Итоговый статус по ИП`,
    width         : 200,
    filtered      : !_.isEmpty(tableState?.filters?.enforcementProceeding?.finishStatus),
    filteredValue : tableState?.filters?.enforcementProceeding?.finishStatus,
    filterMultiple: true,
    filters       : _.map(dictionaries.enforcementProceedingStatus, value => ({
      text : value.description,
      value: value.description,
    })),
  },
  {
    key      : `enforcementProceedings.sumEnforcementProceedingsInitiationDtm`,
    dataIndex: [`enforcementProceedings`, `0`, `sumEnforcementProceedingsInitiationDtm`],
    title    : `Дата возбуждения ИП (сумма)`,
    render   : v => formatDate(v),
    ...getDateRangeFilter(`enforcementProceedings.sumEnforcementProceedingsInitiationDtm`, tableState),
    width    : 200,
  },
  {
    key      : `enforcementProceedings.sumEnforcementProceedingsNumber`,
    dataIndex: [`enforcementProceedings`, `0`, `sumEnforcementProceedingsNumber`],
    title    : `Номер ИП (сумма)`,
    width    : 200,
  },
  {
    key      : `enforcementProceedings.autoEnforcementProceedingsInitiationDtm`,
    dataIndex: [`enforcementProceedings`, `0`, `autoEnforcementProceedingsInitiationDtm`],
    title    : `Дата возбуждения ИП (ТС)`,
    render   : v => formatDate(v),
    width    : 200,
  },
  {
    key      : `enforcementProceedings.autoEnforcementProceedingsNumber`,
    dataIndex: [`enforcementProceedings`, `0`, `autoEnforcementProceedingsNumber`],
    title    : `Номер ИП (ТС)`,
    width    : 200,
  },
  {
    key      : `enforcementProceedings.auctionEstimateSum`,
    dataIndex: [`enforcementProceedings`, `0`, `auctionEstimateSum`],
    title    : `Торги Оценка`,
    width    : 200,
    render   : prettifyNumber,
  },
  {
    key      : `enforcementProceedings.auctionAcceptanceOnBalanceDtm`,
    dataIndex: [`enforcementProceedings`, `0`, `auctionAcceptanceOnBalanceDtm`],
    title    : `Торги Принятие на баланс, дата`,
    width    : 200,
    render   : v => formatDate(v),
  },
  {
    key      : `enforcementProceedings.auctionAcceptanceOnBalanceSum`,
    dataIndex: [`enforcementProceedings`, `0`, `auctionAcceptanceOnBalanceSum`],
    title    : `Торги Принятие на баланс, сумма`,
    width    : 200,
    render   : prettifyNumber,
  },
  {
    key      : `enforcementProceedings.auctionProgress`,
    dataIndex: [`enforcementProceedings`, `0`, `auctionProgress`],
    title    : `Ход торгов`,
    width    : 200,
  },
  {
    key      : `enforcementProceedings.sumEnforcementProceedingsEndDtm`,
    dataIndex: [`enforcementProceedings`, `0`, `sumEnforcementProceedingsEndDtm`],
    title    : `Дата окончания ИП (сумма)`,
    render   : v => formatDate(v),
    width    : 200,
  },
  {
    key      : `enforcementProceedings.sumEnforcementProceedingsEndArticle`,
    dataIndex: [`enforcementProceedings`, `0`, `sumEnforcementProceedingsEndArticle`],
    title    : `Статья окончания ИП (сумма)`,
    width    : 200,
  },
  {
    key      : `enforcementProceedings.autoEnforcementProceedingsEndDtm`,
    dataIndex: [`enforcementProceedings`, `0`, `autoEnforcementProceedingsEndDtm`],
    title    : `Дата окончания ИП (залог)`,
    render   : v => formatDate(v),
    width    : 200,
  },
  {
    key      : `enforcementProceedings.autoEnforcementProceedingsEndArticle`,
    dataIndex: [`enforcementProceedings`, `0`, `autoEnforcementProceedingsEndArticle`],
    title    : `Статья окончания ИП (залог)`,
    width    : 200,
  },
], getColumn);

export const defaultValues: IValues = {
  DPD                         : null,
  SSPContact                  : ``,
  SSPName                     : ``,
  annulmentJudgmentDtm        : null,
  appeal                      : ``,
  autoImplementationName      : ``,
  bailiffComplaints           : ``,
  cancelDefaultDecision       : ``,
  cashClient                  : null,
  cashFSSP                    : null,
  cashThirdPerson             : null,
  cassation                   : ``,
  chComplaint                 : ``,
  comment                     : ``,
  cost                        : null,
  courtCase                   : ``,
  courtCaseWorldJudge         : ``,
  courtComments               : ``,
  courtName                   : ``,
  courtOrderDtm               : null,
  courtOrderFSSPSendDtm       : null,
  courtOrderNumber            : ``,
  courtOrderSubject           : ``,
  courtProceedWritExecutionDtm: null,
  courtSentDtm                : null,
  courtSessionDtm             : null,
  judgment                    : ``,
  judgmentAppeal              : ``,
  judgmentDtm                 : null,
  judgmentEffectiveDtm        : null,
  newOwnerFio                 : ``,
  paidDuty                    : null,
  performanceListComment      : ``,
  performanceListDtm          : null,
  performanceListFSSPSendDtm  : null,
  performanceListNumber       : ``,
  performanceListSubject      : ``,
  provisionalMeasuresAppeal   : ``,
  provisionalMeasuresDetails  : ``,
  provisionalMeasuresDtm      : null,
  provisionalMeasuresResult   : ``,
  returnDefinition            : ``,
  returnStateDutyDtm          : null,
  returnedDuty                : null,
  supremeCourt                : ``,
  totalDebt                   : null,
};

export const defaultValuesEnforcementProceedings: IEnforcementProceedingValues = {
  arrestBankAccountFlg                   : false,
  auctionAcceptanceOnBalanceDtm          : null,
  auctionAcceptanceOnBalanceSum          : null,
  auctionEstimateSum                     : null,
  auctionProgress                        : ``,
  author                                 : ``,
  autoArrestFlg                          : false,
  autoEnforcementProceedingsEndArticle   : ``,
  autoEnforcementProceedingsEndDtm       : null,
  autoEnforcementProceedingsInitiationDtm: null,
  autoEnforcementProceedingsNumber       : ``,
  autoInjunctionFlg                      : false,
  autoSearchFlg                          : false,
  collectionWagesFlg                     : false,
  comment                                : ``,
  courtId                                : ``,
  createDtm                              : null,
  deleteFlg                              : false,
  enforcementProceedingsId               : ``,
  finishStatus                           : ``,
  loanId                                 : ``,
  personId                               : ``,
  restrictionsTravelFlg                  : false,
  resubmissionFlg                        : false,
  sumEnforcementProceedingsEndArticle    : ``,
  sumEnforcementProceedingsEndDtm        : null,
  sumEnforcementProceedingsInitiationDtm : null,
  sumEnforcementProceedingsNumber        : ``,
  updateDtm                              : moment.utc(),
};
