import api from 'api/index';

export const downloadFile = url => api.get(`/fileStorage?url=${url}`, { responseType: `blob` });
export const getFile = url => api.post(`/fileStorage`, { url });

export const getFiles = ({ archive = true, files, name }) => api.post(
  `/fileStorage/files`,
  { archive, files, name },
  { responseType: archive ? `blob` : undefined },
);
export const removeFile = url => api.delete(`/fileStorage?url=${url}`);

export const uploadFile = ({ directory, file }) => {
  const formData = new FormData();
  formData.append(`file`, file);
  return api.put(`/fileStorage/${directory}`, formData, {
    headers: { 'Content-Type': `multipart/form-data` },
  });
};
