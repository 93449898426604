import React from 'react';
import _ from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';

import {
  formatDate,
  getColumn as getColumnDefault,
  getSort,
  sortAlphabetically,
} from 'helper';

import DateFilterDropDown from 'components/DateFilterDropDown';
import { CellDateTime } from 'components/DefaultTable/CellDateTime/CellDateTime';
import { ExternalLink } from 'pages/PageClient/Single/Info/styled';

const getColumn = props => getColumnDefault({ sorter: getSort(props.key, sortAlphabetically), ...props });

export const COLUMNS = ({ tableState }) => _.map([
  {
    key        : `phone`,
    title      : `Телефон`,
    width      : 160,
    renderExcel: v => {
      if (!_.isEmpty(v)) {
        const parsedPhone = parsePhoneNumber(v, `RU`);

        return parsedPhone.formatInternational();
      }
      return v;
    },
    render: v => {
      if (!_.isEmpty(v)) {
        const parsedPhone = parsePhoneNumber(v, `RU`);

        return (
          <ExternalLink href={parsedPhone.getURI()}>
            {parsedPhone.formatInternational()}
          </ExternalLink>
        );
      }
      return v;
    },
  },
  {
    key  : `smsText`,
    title: `Текст`,
    width: 210,
  },
  {
    key        : `createDtm`,
    render     : v => <CellDateTime value={v} />,
    renderExcel: v => {
      if (v) return formatDate(v);
      return v;
    },
    sorter        : getSort(`createDtm`),
    title         : `Отправлено`,
    width         : 130,
    filterDropdown: ({ confirm, selectedKeys, setSelectedKeys }) => (
      // @ts-ignore
      <DateFilterDropDown
        confirm={confirm}
        placeholder={[`с`, `по`]}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
      />
    ),
    filtered     : !_.isEmpty(tableState?.filters?.createDtm),
    filteredValue: tableState?.filters?.createDtm,
    onFilter     : (_value, record) => (
      !!record?.createDtm
      && moment(record?.createDtm).isBetween(tableState?.filters?.createDtm?.[0], tableState?.filters?.createDtm?.[1])
    ),
  },
  {
    key  : `operator`,
    title: `Оператор`,
    width: 100,
  },
  {
    key  : `smsStatusName`,
    title: `Статус`,
    width: 140,
  },
  {
    key  : `operatorStatusText`,
    title: `Статус у оператора`,
    width: 180,
  },
  {
    key  : `smsExternalId`,
    title: `Внешний идентификатор`,
    width: 220,
  },
  {
    key   : `comment`,
    sorter: getSort(`comment`),
    title : `Комментарий`,
    width : 210,
  },
], getColumn);
