import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import { rgba } from 'polished';
import styled from 'styled-components';

import {
  getClientApplications as getApplicationsAction,
  getClientCreditHistory as getCreditHistoryAction,
} from 'models/clients/actions';
import {
  getClientApplicationsForCreditHistory as getClientApplicationsForCreditHistorySelector,
  getCreditHistory as getCreditHistorySelector,
  getIsLoading as isLoadingSelector,
  isCreditHistoryLoaded as isCreditHistoryLoadedSelector,
} from 'models/clients/selectors';

import { useDynamicFilterValues } from 'hooks/useDynamicFilterValues';
import { usePath } from 'hooks/usePath';
import { useQueryProps } from 'hooks/useQueryProps';

import { Empty } from 'antd';
import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { AdjustableTable } from 'pages/PageClient/Single/components/AdjustableTable/AdjustableTable';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import {
  TabContentWrapper as TabContentWrapperDefault,
} from 'pages/PageClient/Single/components/TabContentWrapper/TabContentWrapper';
import {
  getMaxDate,
  getSum,
  SUMMARY_COLUMNS,
} from 'pages/PageClient/Single/CreditHistory/Summary/constants';

import Controls from './Controls';

import { COLUMNS } from './constants';

const TabContentWrapper = styled(TabContentWrapperDefault)`
  ${({ isWrapped }) => !isWrapped && `
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  `}
`;

const StyledAdjustableTable = styled(AdjustableTable)`
  .ant-table-cell-ellipsis {
    overflow-wrap: anywhere;
    white-space: unset;
  }

  .table {
    &-row {
      &_blue {
        background-color: ${rgba(`blue`, 0.15)} !important;
      }
    }

    &-cell {
      &_gray {
        background-color: gray;
        color: white;
      }

      &_red {
        background-color: red;
        color: white;
      }
    }
  }
`;

const PageClientSingleCreditHistory = ({ isWrapped = true, taskId }) => {
  const dispatch = useDispatch();
  const { personId, verificationId } = useContext(ClientMatchContext);

  let basePath = `/client/person/${personId}/credit-history`;
  if (_.includes(window.location.pathname, `verification`)) {
    basePath = `/verification/${verificationId}/person/${personId}/credit-history`;
  }
  if (_.includes(window.location.pathname, `tasks/task`)) {
    basePath = `/tasks/task/${taskId}/person/${personId}`;
  }

  const { queryProps: { applicationId: appId } } = useQueryProps();
  const applicationId = appId === `undefined` ? undefined : appId;

  const { applications, creditHistory, isCreditHistoryLoaded, isLoading } = useSelector(state => ({
    applications         : getClientApplicationsForCreditHistorySelector(state, personId),
    creditHistory        : getCreditHistorySelector(state, personId, applicationId),
    isCreditHistoryLoaded: isCreditHistoryLoadedSelector(state, personId, applicationId),
    isLoading            : isLoadingSelector(state),
  }));

  const getApplications = () => dispatch(getApplicationsAction({ personId }));
  const getCreditHistory = () => dispatch(getCreditHistoryAction({ personId, applicationId }));

  const { replacePath } = usePath();

  const setApplicationId = useCallback(
    _applicationId => replacePath(`${basePath}?applicationId=${_applicationId}`),
    [],
  );
  useEffect(
    () => {
      if (!applicationId && !_.isEmpty(applications)) {
        setApplicationId(_.get(_.first(applications), `id`));
      }
    },
    [],
  );

  useEffect(
    () => {
      if (applicationId && !isCreditHistoryLoaded) getCreditHistory();
    },
    [applicationId],
  );

  useEffect(
    () => {
      if (_.isEmpty(applications)) getApplications();
    },
    [],
  );

  const [filteredInfo, setFilteredInfo] = useState({});
  const [isGroupingEnabled, setIsGroupingEnabled] = useState(false);

  const [accountTypes] = useDynamicFilterValues(creditHistory, `accountType`);
  const [accountRatings] = useDynamicFilterValues(creditHistory, `accountRating`);
  const [affiliations] = useDynamicFilterValues(creditHistory, `affiliation`);

  const filteredData = creditHistory.filter(item => (
    _.includes(filteredInfo?.accountType, item?.accountType)
      && _.includes(filteredInfo?.accountRating, item?.accountRating)
      && _.includes(filteredInfo?.affiliation, item?.affiliation)
  ));

  const groupedByBusinessCategory = _.groupBy(filteredData, `businessCategory`);
  const uniqueTypesArray = _.keys(groupedByBusinessCategory);

  const groupedData = _.map(uniqueTypesArray, (businessCategory, index) => {
    const dataByBusinessCategory = groupedByBusinessCategory[businessCategory];
    const businessCategoryName = businessCategory !== `undefined` ? businessCategory : `Не определён`;

    const openedDate = getMaxDate(`openedDate`)(dataByBusinessCategory);
    const lastPaymentDate = getMaxDate(`lastPaymentDate`)(dataByBusinessCategory);
    const reportingDate = getMaxDate(`reportingDate`)(dataByBusinessCategory);

    const creditLimit = getSum(`creditLimit`)(dataByBusinessCategory);
    const currentBalanceAmount = getSum(`currentBalanceAmount`)(dataByBusinessCategory);
    const amountPastDue = getSum(`amountPastDue`)(dataByBusinessCategory);
    const monthlyPayment = getSum(`monthlyPayment`)(
      _.filter(dataByBusinessCategory, { accountRating: `Активный` }),
    );
    const remainingDebt = getSum(`remainingDebt`)(dataByBusinessCategory);

    return {
      businessCategory: businessCategoryName,
      openedDate,
      lastPaymentDate,
      reportingDate,
      creditLimit,
      currentBalanceAmount,
      amountPastDue,
      monthlyPayment,
      remainingDebt,
      key             : index,
      children        : dataByBusinessCategory,
    };
  });

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  useEffect(() => {
    setFilteredInfo(prevState => ({
      ...prevState,
      accountType: accountTypes,
    }));
  }, [accountTypes]);

  useEffect(() => {
    setFilteredInfo(prevState => ({
      ...prevState,
      accountRating: accountRatings,
    }));
  }, [accountRatings]);

  useEffect(() => {
    setFilteredInfo(prevState => ({
      ...prevState,
      affiliation: affiliations,
    }));
  }, [affiliations]);

  const content = (
    <>
      <TabContentWrapper isWrapped={isWrapped}>
        <Controls
          applicationId={applicationId}
          isGroupingEnabled={isGroupingEnabled}
          personId={personId}
          setApplicationId={setApplicationId}
          toggleGroupingEnabled={() => setIsGroupingEnabled(v => !v)}
        />
      </TabContentWrapper>

      {creditHistory.length ? (
        <StyledAdjustableTable
          className='table'
          columns={COLUMNS({
            accountRatings,
            accountTypes,
            affiliations,
            filteredInfo,
          })}
          dataSource={isGroupingEnabled ? groupedData : filteredData}
          onChange={handleChange}
          pagination={{
            hideOnSinglePage: true,
            pageSize        : _.size(filteredData),
            size            : `small`,
          }}
          preferences={{
            path: `clientInfo.creditHistory`,
          }}
          rowClassName={({
            accountRatingText,
            normRate,
          }) => (normRate >= 50 && normRate <= 150 && _.toLower(accountRatingText) !== `счет закрыт`
            ? `table-row_blue`
            : `table-row_blue`)}
          scroll={{ x: `max-content`, y: window.innerHeight - 450 }}
          summary={{
            columns: SUMMARY_COLUMNS,
            data   : filteredData,
          }}
        />
      ) : (
        <Empty
          description='Нет данных'
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </>
  );
  return isWrapped ? (
    <ClientTab isReloading={isLoading} onReload={getCreditHistory}>
      {content}
    </ClientTab>
  ) : content;
};

export default PageClientSingleCreditHistory;
