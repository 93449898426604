import { parseResponse } from 'api/helpers';
import {
  b64toBlob,
  downloadBlob,
  showError,
} from 'helper';

import { getFile } from './api';

export const downloadFile = async ({ file, url }) => {
  const defaultError = `При загрузке файла произошла ошибка`;
  try {
    const b64 = parseResponse({
      dataPath: `data.file`,
      defaultError,
      response: await getFile(url),
    });
    const blob = b64toBlob(b64, file.type);
    downloadBlob(blob, file.name);
  } catch (error) {
    showError({ defaultError, error });
  }
};
