/* eslint-disable max-len */
import _ from 'lodash';
import { routerRedux } from 'dva/router';
import { intercom } from '@cashdrive/api/socket';

import {
  clearAuthHeaders,
  setToken,
} from 'api';
import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { get as getDictionary } from 'models/dictionaries/actions';
import {
  GET_CURRENT as GET_CURRENT_OPERATOR,
  model as operatorModel,
} from 'models/operators/actions';
import { get as getRoleGroups } from 'models/roleGroups/actions';
import { isLoaded as isLoadedRoleGroupsSelector } from 'models/roleGroups/selectors';
import { get as getRoles } from 'models/roles/actions';
import { isLoaded as isLoadedRolesSelector } from 'models/roles/selectors';
import {
  reset as resetUi,
  set as setUi,
  setLoading,
} from 'models/ui/actions';
import { get as getUiItemSelector } from 'models/ui/selectors';
import { getLatest as getVersionLatest } from 'models/version/actions';

import { notification } from 'antd';

import {
  EMAIL_CONFIRM,
  GET,
  GET_CURRENT,
  LOGIN,
  LOGOUT,
  LOGOUT_REFRESH,
  model as namespace,
  RECOVER,
  REFRESH_TOKEN,
  REQUEST_RECOVER,
  RESET,
  SET,
  SET_ERROR,
  SET_LOADED,
  SET_LOADING,
  SET_TOKEN,
} from './actions';
import * as api from './api';
import { isAuth } from './helpers';
import {
  isLoaded as isLoadedSelector,
  isLoading as isLoadingSelector,
  isRefreshTokenLoading as isRefreshTokenLoadingSelector,
} from './selectors';

import { INITIAL_STATE } from './constants';
import {createSocket} from "@cashdrive/api/socket";

export default {
  namespace,
  state        : INITIAL_STATE,
  subscriptions: {
    setup({ dispatch }) {
      if (isAuth()) {
        dispatch({ type: GET_CURRENT });
        dispatch({ type: REFRESH_TOKEN });
      }
      intercom.subscribe(`LOGOUT`, () => dispatch({ type: LOGOUT_REFRESH }));
      intercom.subscribe(`SET_TOKEN`, token => dispatch({type: SET_TOKEN, payload: token}));
    },
  },
  effects: {
    *[EMAIL_CONFIRM]({ payload }, { call, put }) {
      yield put(setLoading(true));
      const defaultError = `При подтверждении email произошла ошибка`;

      try {
        if (isAuth()) {
          yield put({ type: LOGOUT });
        }

        const data = parseResponse({
          defaultError,
          response: yield call(api.emailConfirm, payload),
        });

        if (_.get(data, `message`)) notification.success(data);
      } catch (error) {
        const errorText = _.isString(error) ? (error || defaultError) : defaultError;
        yield put({ type: SET_ERROR, payload: { emailConfirm: errorText } });
        showError({ defaultError, error });
      } finally {
        yield put(setLoading(false));
        yield put(routerRedux.replace({ pathname: `/auth`, search: `` }));
      }
    },

    *[GET](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке списка пользователей произошла ошибка`;

      try {
        const items = parseResponse({
          defaultError,
          response: yield call(api.get),
        });

        yield put({ type: SET, payload: { items } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_CURRENT](action, { all, call, put, select }) {
      yield put(setLoading(true));
      const defaultError = `При загрузке пользовательских данных произошла ошибка`;
      // const noContactPersonError = `По данному пользователю не найдены контактные лица`;
      if (!isAuth()) return;
      const forceLoad = _.get(action, `payload.force`, false);
      const isLoaded = yield select(isLoadedSelector);
      const isLoading = yield select(isLoadingSelector);
      if (!forceLoad && (isLoaded || isLoading)) return;

      yield put({ type: SET_LOADING, payload: true });
      try {
        let current;
        try {
          current = parseResponse({
            defaultError,
            response: yield call(api.getCurrent),
          });
        } catch (e) {
          yield put({ type: LOGOUT, payload: { showMessage: false } });
          return;
        }
        const [
          isLoadedRoles,
          isLoadedRoleGroups,
        ] = yield all([
          select(isLoadedRolesSelector),
          select(isLoadedRoleGroupsSelector),
        ]);

        if (!isLoadedRoles) yield put(getRoles());
        if (!isLoadedRoleGroups) yield put(getRoleGroups());
        yield put(getDictionary({ entity: `campaign` }));

        yield put({ type: SET, payload: { current } });
        yield put({ type: SET_LOADED, payload: true });
        yield put({ type: SET_LOADING, payload: false });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
        yield put(setLoading(false));
      }
    },

    *[LOGIN]({ payload }, { call, put }) {
      const defaultError = `При попытке входа в систему произошла ошибка.\nПроверьте корректность введенных данных и попробуйте еще раз.`; // eslint-disable-line max-len
      yield put(setLoading(true));

      try {
        yield put({ type: SET_ERROR, payload: { auth: `` } });

        const token = parseResponse({
          dataPath: `data.token`,
          defaultError,
          response: yield call(api.login, payload),
        });

        intercom.postMessage(`SET_TOKEN`, token);

        yield put(getVersionLatest()); // проверяем, не появилась ли новая версия приложения
      } catch (error) {
        const errorText = _.isString(error) ? (error || defaultError) : defaultError;
        yield put({ type: SET_ERROR, payload: { auth: errorText } });
        showError({ defaultError, error });
      } finally {
        yield put(setLoading(false));
      }
    },

    *[SET_TOKEN]({ payload }, { put, select }) {
      setToken(payload);
      createSocket(payload, import.meta.env.VITE_REACT_APP_API_URL);
      yield put({ type: GET_CURRENT });
      yield put({ type: `${operatorModel}/${GET_CURRENT_OPERATOR}` });
      const redirectAfterLogin = yield select(state => getUiItemSelector(state, `redirectAfterLogin`));
      if (redirectAfterLogin) {
        yield put(routerRedux.replace(redirectAfterLogin));
        yield put(setUi({ redirectAfterLogin: null }));
      }
    },

    *[LOGOUT](action, { put }) {
      yield put({ type: SET_LOADING, payload: true });
      yield put(setLoading(true));
      const themeName = localStorage.getItem(`themeName`);
      localStorage.clear();

      if (themeName) {
        localStorage.setItem(`themeName`, themeName);
      }

      clearAuthHeaders();
      intercom.postMessage(`LOGOUT`);
      // yield put({ type: LOGOUT_REFRESH });
      yield put({ type: SET_LOADING, payload: false });
      yield put(setLoading(false));
      setTimeout(() => {
        localStorage.removeItem(`persist:root`);
        window.location.reload();
      }, 400);
    },

    *[LOGOUT_REFRESH](action, { put }) {
      yield put(resetUi());
      yield put(routerRedux.replace(`/auth`));  // eslint-disable-line
      yield put({ type: RESET });
    },

    *[RECOVER]({ payload }, { call, put }) {
      yield put(setLoading(true));
      const defaultError = `При восстановлении пароля произошла ошибка`;

      try {
        const { cb, id, password, recoverToken } = payload;

        const data = parseResponse({
          defaultError,
          response: yield call(api.recover, {
            id,
            password,
            recoverToken,
          }),
        });

        if (_.get(data, `message`)) notification.success(data);

        yield put(routerRedux.replace({ pathname: `/auth`, search: `` }));

        if (_.isFunction(cb)) cb();
      } catch (error) {
        const errorText = _.isString(error) ? (error || defaultError) : defaultError;
        yield put({ type: SET_ERROR, payload: { recover: errorText } });
        showError({ defaultError, error });
      } finally {
        yield put(setLoading(false));
      }
    },

    *[REFRESH_TOKEN](action, { call, put, select }) {
      const defaultError = `При запросе на обновление токена произошла ошибка`;

      try {
        const isLoading = yield select(isRefreshTokenLoadingSelector);
        if (isLoading) return;

        yield put({ type: SET, payload: { isRefreshTokenLoading: true } });

        const data = parseResponse({
          defaultError,
          response: yield call(api.refreshToken),
        });

        const { token } = data;
        if (token) {
          setToken(token);
          intercom.postMessage(`SET_TOKEN`, token);
        }
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET, payload: { isRefreshTokenLoading: false } });
      }
    },

    *[REQUEST_RECOVER]({ payload }, { call, put }) {
      yield put(setLoading(true));
      const defaultError = `При запросе на восстановление пароля произошла ошибка`;

      try {
        const data = parseResponse({
          defaultError,
          response: yield call(api.requestRecover, payload),
        });

        if (_.get(data, `message`)) notification.success(data);
      } catch (error) {
        const errorText = _.isString(error) ? (error || defaultError) : defaultError;
        yield put({ type: SET_ERROR, payload: { requestRecover: errorText } });
        showError({ defaultError, error });
      } finally {
        yield put(setLoading(false));
      }
    },
  },

  reducers: {
    [RESET]() {
      return INITIAL_STATE;
    },

    [SET](state, { payload = {} }) {
      if (_.isEmpty(payload)) return state;

      return {
        ...state,
        ...payload,
      };
    },

    [SET_ERROR](state, { payload = {} }) {
      return {
        ...state,
        error: {
          ...state.error,
          ...payload,
        },
      };
    },

    [SET_LOADED](state, { payload = false }) {
      return {
        ...state,
        isLoaded: payload,
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },

  },
};
