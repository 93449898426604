import api from 'api/index';

export const create = data => api.post(`/proxy/acm/action`, data);
export const createPersonAction = data => api.post(`/proxy/acm/person-action`, data);
export const get = () => api.get(`/proxy/acm/action`);
export const getPersonAction = personId => api.get(`/proxy/acm/action/person/${personId}`);
export const removePersonAction = data => api.post(`/proxy/acm/person-action/delete`, data);
export const update = (id, data) => api.post(`/proxy/acm/action`, { id, ...data });
export const uploadFile = file => {
  const formData = new FormData();
  formData.append(`file`, file, file.name);

  return api.post(`/action/upload`, formData, { headers: { 'Content-Type': `multipart/form-data` } });
};
