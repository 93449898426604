import * as React from 'react';
import { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  copyToClipboard,
  formatDate,
  getShortened,
} from 'helper';

import {
  IApplication,
  ICar,
} from 'models/clients/types';
import { TRoleName } from 'models/roles/constants';
import { useModalHandlers } from 'hooks/useModalHandlers';

import useMobileContext from 'hooks/useMobileContext';

import {
  CopyOutlined,
  DollarCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { CarImplementationModal } from 'pages/PageClient/Single/Car/Modal/Implementation/CarImplementationModal';
import { FORM_FIELDS } from 'pages/PageClient/Single/Car/constants';
import { CarNumberPlate } from 'pages/PageClient/Single/components/CarNumberPlate/CarNumberPlate';
import {
  EntityCard,
  EntityCardContent,
  entityCardRowTextMixin,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardDivider } from 'pages/PageClient/Single/components/EntityCard/EntityCardDivider';
import { ExpandButton } from 'pages/PageClient/Single/components/EntityCard/EntityCardExpandButton';
import { EntityCardRow } from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';

const StyledExpandButton = styled(ExpandButton)`
  margin-top: 4px;
`;

const ApplicationDtmWrapper = styled.div`
  ${entityCardRowTextMixin};
  color: ${palette.black80Color};
`;

const CarTitle = styled.div<{ $isHighlighted?: boolean; }>`
  margin-right: 8px;
  font-weight: ${({ $isHighlighted }) => ($isHighlighted ? `bold` : `500`)};
`;

interface IComponentProps {
  allowedRoles?: TRoleName[];
  car: ICar;
  isCollapsed?: boolean;
  isEditHidden?: boolean;
  isHighlighted?: boolean;
  onEditClick?(): void;
}

const getApplicationIdTitle = ({ application, mobile }: { application: IApplication; mobile: boolean }) => (mobile ? getShortened(application.id) : application.id);
const getApplicationDtmTitle = (application: IApplication) => formatDate(new Date(application?.applicationDtm));
export const CarCard: React.FC<IComponentProps> = ({
   allowedRoles,
   car,
   isCollapsed,
   isEditHidden,
   isHighlighted,
   onEditClick,
 }) => {
  const { isEditModalOpen, toggleEditModal } = useModalHandlers();
  const { applications, carNumber, make, model } = car;

  const carTitle = _.join(_.compact([make, model]), ` `);
  const { mobile } = useMobileContext();
  const [collapsed, setCollapsed] = useState(_.isNil(isCollapsed) ? mobile : isCollapsed);

  return (
    <EntityCard>
      <CarImplementationModal car={car} isVisible={isEditModalOpen} onClose={toggleEditModal} />
      <EntityCardTitle>
        {carTitle && (
          <CarTitle $isHighlighted={isHighlighted}>
            {carTitle}
          </CarTitle>
        )}
        <CarNumberPlate carNumber={carNumber} />
        {!isEditHidden && (
          <EntityCardTitleControl
            actionTitle='Редактировать'
            allowedRoles={allowedRoles}
            onClick={onEditClick}
          >
            {/*@ts-ignore */}
            <EditOutlined />
          </EntityCardTitleControl>
        )}
        <EntityCardTitleControl
          actionTitle='Реализовать авто'
          allowedRoles={allowedRoles}
          onClick={toggleEditModal}
        >
          {/*@ts-ignore */}
          <DollarCircleOutlined />
        </EntityCardTitleControl>
      </EntityCardTitle>

      <EntityCardContent>
        <EntityCardTitle>
          Заявки:
        </EntityCardTitle>
        {_.map(applications, application => (
          <ApplicationDtmWrapper key={application.id}>
            {getApplicationIdTitle({ application, mobile })}
            <EntityCardTitleControl
              actionTitle='Копировать'
              onClick={() => copyToClipboard(application.id)}
            >
              {/*@ts-ignore */}
              <CopyOutlined />
            </EntityCardTitleControl>
            от {getApplicationDtmTitle(application)}
          </ApplicationDtmWrapper>
        ))}

        {!collapsed
          && (
            <>
              <EntityCardDivider />
              {_.map(car, (value, key) => {
                if (_.isObject(value) || _.isNil(value)) return null;
                // eslint-disable-next-line @typescript-eslint/unbound-method
                const normalize = _.get(FORM_FIELDS, `${key}.normalize`, _.identity);

                return (
                  <EntityCardRow
                    key={`${car.id}_${key}`}
                    title={_.get(FORM_FIELDS, `${key}.title`, key)}
                    //@ts-ignore
                    valueToCopy={normalize(value)}
                  >
                    {key === `carNumber` ? (
                      // @ts-ignore
                      <CarNumberPlate carNumber={value} />
                    ) : (
                      <>{normalize(value)}</>
                    )}
                  </EntityCardRow>
                );
              })}
            </>
          )}

        {mobile && (
          <StyledExpandButton collapsed={collapsed} onClick={() => setCollapsed(!collapsed)} />
        )}
      </EntityCardContent>
    </EntityCard>
  );
};
