import * as React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
} from 'styles/theme';

const Wrapper = styled.div`
  display: flex;

  border: 1px solid ${palette.black40Color};
  border-radius: 2px;
  background: ${palette.backgroundColor};

  font-family: PT Root UI, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;

  color: ${getLightStyleOr(palette.black80Color, palette.black100Color)};

  @media(min-width: ${breakpoints.lg}) {
    font-size: 14px;
  }
`;

const NumberWrapper = styled.div`
  padding: 2px 4px;
`;

const RegistrationCodeWrapper = styled.span`
  color: ${palette.black100Color};
  font-weight: 700;
  margin-right: 4px;
  margin-left: 4px;
`;

const RegistrationRegionCodeWrapper = styled.div`
  padding: 2px;
  color: ${palette.backgroundColor};
  background: ${palette.primary500Color};
`;

interface IComponentProps {
  carNumber?: string;
}

const numberRegExp = /([A-ZА-Я])([0-9]{3})([A-ZА-Я]{2})([0-9]*)/;
const dashRegExp = /^[—–−-]$/gi;

const splitCarNumber = (carNumber: string) => {
  const [serialStart, registrationCode, serialEnd, registrationRegionCode] = _.drop(numberRegExp.exec(carNumber));

  return {
    serialStart,
    registrationCode,
    serialEnd,
    registrationRegionCode,
  };
};

export const CarNumberPlate: React.FC<IComponentProps> = ({ carNumber }) => {
  if (!carNumber) {
    return null;
  }

  if (dashRegExp.test(carNumber)) {
    return carNumber;
  }

  if (!numberRegExp.test(carNumber)) {
    return carNumber;
  }

  const { registrationCode, registrationRegionCode, serialEnd, serialStart } = splitCarNumber(carNumber);

  return (
    <Wrapper>
      <NumberWrapper>
        {serialStart}
        <RegistrationCodeWrapper>
          {registrationCode}
        </RegistrationCodeWrapper>
        {serialEnd}
      </NumberWrapper>
      <RegistrationRegionCodeWrapper>
        {registrationRegionCode}
      </RegistrationRegionCodeWrapper>
    </Wrapper>
  );
};
