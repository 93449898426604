import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { parseResponse } from 'api/helpers';
import {
  downloadBlob,
  showError,
} from 'helper';

import { getFiles } from 'models/fileStorage/api';
import { downloadFile as downloadFileHelper } from 'models/fileStorage/helpers';

import {
  DownloadOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { AttachmentFileThumbnail } from 'components/Messenger/Attachment/AttachmentFileThumbnail';
import { AttachmentPreviewModal } from 'components/Messenger/Attachment/AttachmentPreviewModal';
import { AttachmentsTitle } from 'components/Messenger/Attachment/AttachmentsTitle';
import { AttachmentsWrapper } from 'components/Messenger/Attachment/AttachmentsWrapper';
import { useAttachmentsPreview } from 'components/Messenger/Attachment/useAttachmentsPreview';
import { ACCEPT_COMMON_IMAGES } from 'components/Messenger/constants';
import { getFileKey } from 'components/Messenger/helpers';
import { MessageImageThumbnail } from 'components/Messenger/Message/MessageImageThumbnail';
import { IMessage } from 'components/Messenger/types';

const Wrapper = styled.div`
`;

const IconWrapper = styled.div`
  font-size: 16px;
`;

const IconWrapperSuccess = styled(IconWrapper)`
  transition: color 200ms ease;
  cursor: pointer;

  :hover {
    color: ${palette.primary600Color};
  }
`;

interface IComponentProps {
  className?: string;
  message: IMessage;
}

export const MessageAttachments: React.FC<IComponentProps> = ({ className, message }) => {
  const { id } = message;

  const [files, setFiles] = useState([]);

  const {
    closePreviewMode,
    isPreviewMode,
    previewImageIndex,
    selectImageByIndex,
    selectNextImage,
    selectPrevImage,
  } = useAttachmentsPreview(files);

  const previewImage = isPreviewMode
    // @ts-ignore
    ? files[previewImageIndex]
    : null;

  // @ts-ignore
  const onFileLoaded = (file, index: number) => {
    // @ts-ignore
    setFiles(prevFiles => [
      ...prevFiles.slice(0, index),
      file,
      ...prevFiles.slice(index + 1),
    ]);
  };

  // @ts-ignore
  const downloadFile = async file => downloadFileHelper({ file, url: `/message/${id}/${file.name}` });

  const downloadArchive = async () => {
    const defaultError = `При загрузке архива произошла ошибка`;
    try {
      const name = `message_${id}_files`;
      const blob = parseResponse({
        dataPath: `data`,
        defaultError,
        response: await getFiles({
          archive: true,
          // @ts-ignore
          files  : _.map(_.filter(files, `name`), file => ({ ...file, url: `/message/${id}/${file.name}` })),
          name,
        }),
      });
      downloadBlob(blob, name);
    } catch (error) {
      // @ts-ignore
      const errorMessage = `${defaultError}:\n${error.toString()}`;
      console.error(errorMessage); // eslint-disable-line no-console
      showError({ defaultError, error: errorMessage });
    }
  };

  useEffect(() => {
    // @ts-ignore
    setFiles(message.files);
  }, [message.files]);

  return (
    <Wrapper className={className}>

      <AttachmentsTitle files={files}>
        <DefaultTooltip title='Скачать архив'>
          <IconWrapperSuccess onClick={() => downloadArchive()}>
            <FileZipOutlined />
          </IconWrapperSuccess>
        </DefaultTooltip>
      </AttachmentsTitle>

      <AttachmentsWrapper>
        {_.map(files, (file, index) => (
          <div key={getFileKey(file)}>
            {/*@ts-ignore*/}
            {!_.includes(ACCEPT_COMMON_IMAGES, file.type) ? (
              <AttachmentFileThumbnail
                action={{
                  onClick: e => {
                    e.stopPropagation();
                    // eslint-disable-next-line no-console
                    downloadFile(file).catch(console.error);
                  },
                  title: `Скачать файл`,
                  icon : <DownloadOutlined />,
                  type : `success`,
                }}
                file={file}
                onClick={() => selectImageByIndex(index)}
              />
            ) : (
              <MessageImageThumbnail
                file={file}
                onClick={() => selectImageByIndex(index)}
                onDownloadFile={() => downloadFile(file)}
                onFileLoaded={newFile => onFileLoaded(newFile, index)}
                // @ts-ignore
                url={`/message/${id}/${file.name}`}
              />
            )}
          </div>
        ))}
      </AttachmentsWrapper>

      {isPreviewMode && (
        <AttachmentPreviewModal
          image={{
            src: previewImage?.url,
            ...previewImage,
          }}
          // @ts-ignore
          imageIndex={previewImageIndex}
          isVisible={isPreviewMode}
          onCancel={closePreviewMode}
          onClickNext={selectNextImage}
          onClickPrev={selectPrevImage}
          quantity={files.length}
        />
      )}
    </Wrapper>
  );
};
