import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { SIGN_DOCUMENT_STATUS_NAMES } from 'models/clients/constants';

import { DefaultTable } from 'components/DefaultTable/DefaultTable';

import { COLUMNS } from './constants';

const Wrapper = styled.div`
  &+& {
    margin-top: 16px;
  }
`;

const Subtitle = styled.p`
  font-weight: 600;
  color: ${palette.textPrimaryColor};
  margin-bottom: 8px;
`;

const Item = ({
  closeSignDocument,
  fileStatuses,
  finalizeSignDocument,
  isCloseSignDocumentButton,
  isDraft,
  signDocuments,
  status,
}) => {
  const docs = signDocuments[_.toLower(status)];
  if (_.isEmpty(docs)) return null;

  const columns = COLUMNS({
    closeSignDocument,
    fileStatuses,
    finalizeSignDocument,
    isCloseSignDocumentButton: isCloseSignDocumentButton || isDraft,
    isFinalizeDocumentButton : isDraft,
  });

  return (
    <Wrapper>
      <Subtitle>{SIGN_DOCUMENT_STATUS_NAMES[status]}</Subtitle>
      <DefaultTable
        columns={columns}
        dataSource={_.orderBy(docs, `createDate`, `desc`)}
        pagination={false}
        rowKey='id'
        size='small'
      />
    </Wrapper>
  );
};

export default Item;
