import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  formatDate,
  getFullNameInitials,
} from 'helper';

import {
  FieldTimeOutlined,
  FileOutlined,
  TagsOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';

const Wrapper = styled.li`
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-bottom: 16px;
  border-bottom: 1px solid ${palette.borderDefaultColor};
  padding-bottom: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${palette.textPrimaryColor};
  line-height: 18px;
  gap: 8px;

  .anticon {
    color: ${palette.primary500Color};
  }
`;

const DocumentType = styled.div`
  color: ${palette.textSecondaryColor};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DateWrapper = styled.div`
  color: ${palette.textSecondaryColor};
  white-space: nowrap;

  .anticon {
    margin-left: 8px;
  }
`;

const DocumentName = styled.div`
  font-size: 16px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .anticon {
    margin-right: 8px;
  }
`;

const Operator = styled.div`
  color: ${palette.textSecondaryColor};
  white-space: nowrap;

  .anticon {
    margin-left: 8px;
  }
`;

const ApplicationId = styled.div`
  .anticon {
    margin-left: 8px;
  }
`;

export const UploadHistoryItem = ({
  applicationId,
  className,
  createdAt,
  documentTypeId,
  documentTypes,
  next,
  operatorId,
  operators,
  showApplicationId,
}) => {
  const date = formatDate(new Date(createdAt));
  const documentType = _.get(_.find(documentTypes, { id: documentTypeId }), `name`);
  const name = next;
  const operator = getFullNameInitials(_.get(_.find(operators, { id: operatorId }), `user`));

  return (
    <Wrapper className={className}>
      <Row>
        <DefaultTooltip title={documentType}>
          <DocumentType>
            {documentType}
          </DocumentType>
        </DefaultTooltip>
        <DateWrapper>
          {date}
          <FieldTimeOutlined />
        </DateWrapper>
      </Row>
      <Row>
        <DefaultTooltip title={name}>
          <DocumentName title={name}>
            <FileOutlined />
            {name}
          </DocumentName>
        </DefaultTooltip>
        <Operator>
          {operator}
          <UserOutlined />
        </Operator>
      </Row>

      {showApplicationId && (
        <Row>
          <div />
          <ApplicationId>
            {applicationId}
            <TagsOutlined />
          </ApplicationId>
        </Row>
      )}
    </Wrapper>
  );
};
