import * as React from 'react';
import styled from 'styled-components';

import { Layout } from 'antd';

const StyledLayout = styled(Layout)`
  padding: 0;
`;

const LayoutContent = styled(Layout.Content)`
  padding: 0 !important;
`;

interface IComponentProps {
  children: React.ReactNode;
}

export const DefaultPageLayout: React.FC<IComponentProps> = ({ children }) => (
  <StyledLayout>
    <LayoutContent>
      {children}
    </LayoutContent>
  </StyledLayout>
);
