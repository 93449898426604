import styled from 'styled-components';
import { palette } from 'styles/theme';

import { Collapse } from 'antd';

export const StyledCollapse = styled(Collapse)`
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 4px;
  padding: 8px;

  &:nth-child(odd) {
    background-color: ${palette.black10Color};
  }
`;
export const Label = styled.div`
  flex: 1;
  flex-basis: 150px;
  text-align: left;
  font-weight: bold;
`;
export const LabelRegular = styled(Label)`
  font-weight: unset;
`;
export const Value = styled.div`
  flex: 1;
  text-align: right;
  flex-basis: 100%;
`;
