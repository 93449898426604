import { createAction } from 'redux-actions';

export const model = `version`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const GET_LATEST = `GET_VERSION_LATEST`;
export const SAVE = `SAVE`;
export const SHOW_NOTIFICATION = `SHOW_NOTIFICATION`;

export const getLatest = ca(GET_LATEST);
