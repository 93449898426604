import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { getClientSms as getClientSmsAction } from 'models/clients/actions';
import {
  getClientSms as getClientSmsSelector,
  getIsLoading as getIsLoadingSelector,
} from 'models/clients/selectors';

import useMobileContext from 'hooks/useMobileContext';

import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';
import { EntityCardsGridWrapper } from 'pages/PageClient/Single/components/EntityCard/EntityCardsGridWrapper';
import { TabContentWrapper } from 'pages/PageClient/Single/components/TabContentWrapper/TabContentWrapper';
import { SmsCard } from 'pages/PageClient/Single/Sms/SmsCard';
import { SmsTable } from 'pages/PageClient/Single/Sms/SmsTable';

const PageClientSingleSms = () => {
  const { personId } = useContext(ClientMatchContext);
  const dispatch = useDispatch();
  const { mobile } = useMobileContext();

  const getSms = useCallback((force = true) => dispatch(getClientSmsAction({ personId, force })), [dispatch, personId]);

  const sms = useSelector(state => getClientSmsSelector(state, personId));
  const isLoading = useSelector(state => getIsLoadingSelector(state));

  useEffect(() => {
    if (personId) getSms();
  }, [personId, getSms]);

  return (
    <ClientTab isReloading={isLoading} onReload={() => getSms(true)}>
      {mobile ? (
        <TabContentWrapper>
          <EntityCardsGridWrapper>
            {_.map(sms, (item, index) => (
              <SmsCard key={index} sms={item} />
            ))}
          </EntityCardsGridWrapper>
        </TabContentWrapper>
      ) : (
        <SmsTable sms={sms} />
      )}
    </ClientTab>
  );
};

export default PageClientSingleSms;
