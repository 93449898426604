import * as React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { remove as removeItemsAction } from 'models/dictionaries/actions';
import { getItems as getItemsSelector } from 'models/dictionaries/selectors';

import RemoveModal from 'components/Modal/Remove';

interface IComponentProps {
  entity: string;
  isVisible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  selectedRowKeys: any[];
}

export const DictionaryRemoveModal: React.FC<IComponentProps> = ({
  entity,
  isVisible,
  onClose,
  onSubmit,
  selectedRowKeys,
}) => {
  const dispatch = useDispatch();

  const removeModalItems = useSelector(state => getItemsSelector(state, entity));
  const ids = _.filter(selectedRowKeys, id => id >= 0);
  const removeItems = (items: any[]) => dispatch(removeItemsAction({ entity, ids: items }));

  const handleSubmit = () => {
    const itemsToRemove = _.filter(selectedRowKeys, id => (entity === `verificationStatus`
      ? (id > 0)
      : (id >= 0)));

    removeItems(itemsToRemove);
    onSubmit();
  };

  return (
    <RemoveModal
      close={onClose}
      ids={ids}
      items={removeModalItems}
      modalTitle='Удаление записей'
      namePath='name'
      onSubmit={handleSubmit}
      question='Вы действительно хотите удалить эти записи?'
      visible={isVisible}
    />
  );
};
