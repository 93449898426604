import {
  useContext,
  useMemo,
} from 'react';
import _ from 'lodash';
import UAParser from 'ua-parser-js';

import MobileContext from 'pages/Layout/MobileContext';

interface MobileContextHook {
  isSafari: boolean;
  mobile: boolean;
}

const useMobileContext = (): MobileContextHook => {
  const mobile = useContext(MobileContext);
  const uaParser = useMemo(() => new UAParser(), []);
  const uaParsed = useMemo(() => uaParser.getResult(), [uaParser]);
  const browser = useMemo(() => _.toLower(_.get(uaParsed, `browser.name`, ``)), [uaParsed]);
  return useMemo(() => ({ isSafari: _.includes(browser, `safari`), mobile }), [browser, mobile]);
};

export default useMobileContext;
