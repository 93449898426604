import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  copyToClipboard,
  getColumn as getColumnDefault,
  getSort,
  sortAlphabetically,
} from 'helper';

import { CopyOutlined } from '@ant-design/icons';

const CopyIcon = styled(CopyOutlined)`
  margin-left: 8px;

  color: ${palette.textSecondaryColor};

  :hover {
    color: ${palette.textPrimaryColor};
  }
`;

const getColumn = (props: any) => getColumnDefault({ align: `left`, width: 150, ...props });

export const PAGE_INFO_COLUMNS = () => _.map([
  {
    key   : `name`,
    title : `Название`,
    sorter: getSort(`name`, sortAlphabetically),
  },
  {
    key   : `url`,
    title : `Ссылка`,
    render: (v: string) => (
      <>
        <a href={v} rel='noreferrer' target='_blank'>{v}</a> <CopyIcon
          onClick={() => copyToClipboard(v)}
        />
      </>
    ),
  },
  {
    key   : `login`,
    title : `Логин`,
    render: v => (v ? (
      <>
        {v} <CopyIcon
          onClick={() => copyToClipboard(v)}
        />
      </>
    ) : null),
  }, {
    key   : `password`,
    title : `Пароль`,
    render: v => (v ? (
      <>
        **** <CopyIcon
          onClick={() => copyToClipboard(v)}
        />
      </>
    ) : null),
  }, {
    key   : `group`,
    title : `Группа`,
    sorter: getSort(`group`, sortAlphabetically),
  },
], getColumn);
