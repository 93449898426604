import { createAction } from 'redux-actions';

export const model = `bankrupts`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const GET = `GET`;
export const POST = `POST`;
export const RESET = `RESET`;
export const SET = `SET`;
export const SET_ITEMS = `SET_ITEMS`;
export const SET_LOADING = `SET_LOADING`;
export const SET_TABLE_STATE = `SET_TABLE_STATE`;

export const get = ca(GET);
export const post = ca(POST);
export const reset = ca(RESET);
export const set = ca(SET);
export const setItems = ca(SET_ITEMS);
export const setLoading = ca(SET_LOADING);
export const setTableState = ca(SET_TABLE_STATE);
