import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { get as getDictionaryAction } from 'models/dictionaries/actions';
import {
  getItems as getDictionary,
  isLoading as isLoadingSelector,
} from 'models/dictionaries/selectors';

import { Layout } from 'antd';
import { DefaultTable } from 'components/DefaultTable/DefaultTable';
import { PAGE_INFO_COLUMNS } from 'pages/PageInfo/Plural/constants';

interface IInfoItem {
  group: string;
  id: number;
  login: string;
  name: string;
  password: string;
  url: string;
}

interface IComponentProps {

}

const PageInfoPlural: React.FC<IComponentProps> = () => {
  const dispatch = useDispatch();

  const tableScrollY = window.innerHeight - 300 < 200 ? 250 : window.innerHeight - 215;

  const infos: IInfoItem[] = useSelector(state => getDictionary(state, `info`));
  const isLoading = useSelector(isLoadingSelector);

  const getInfos = () => dispatch(getDictionaryAction({
    entity: `info`,
    force : true,
  }));

  useEffect(() => {
    getInfos();
  }, []);

  return (
    <Layout>
      <Layout.Content>
        <DefaultTable
          // @ts-ignore
          columns={PAGE_INFO_COLUMNS()}
          dataSource={infos}
          loading={isLoading}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 50,
            pageSizeOptions: [`50`, `100`, `150`],
            size           : `small`,
          }}
          rowKey='id'
          scroll={{
            scrollToFirstRowOnChange: true,
            x                       : `max-content`,
            y                       : tableScrollY,
          }}
          size='small'
        />
      </Layout.Content>
    </Layout>
  );
};

export default PageInfoPlural;
