import React from 'react';

import { Communication } from 'models/communications/types';
import { TEventlogItem } from 'models/eventlogs/types';

import useMobileContext from 'hooks/useMobileContext';

import { MessageOutlined } from '@ant-design/icons';
import { ItemHead } from 'components/Eventlog/Item/ItemHead';
import CommunicationItem from 'pages/PageClient/Single/Communication/Item';

import {
  Content,
  Description,
  Icon,
  Title,
  Wrapper,
} from '../styled';

interface IComponentProps extends TEventlogItem {
  date: string;
  next?: Communication;
  operatorName?:string;
  prev?: Communication;
}

const title = `Изменена коммуникация`;
export const UserCommunicationUpdate: React.FC<IComponentProps> = ({ date, next, operatorName, prev }) => {
  const { mobile } = useMobileContext();

  return (
    <Wrapper $isMobile={mobile}>
      <Icon $color='orange'><MessageOutlined /></Icon>
      <Content>
        <ItemHead date={date} operatorName={operatorName} title={title} />
        <Description>
          <Title>Стало</Title>
          {/*@ts-ignore*/}
          <CommunicationItem hideTitleLabels={[`Дата`]} isMobile {...next} />
          <Title>Было</Title>
          {/*@ts-ignore*/}
          <CommunicationItem hideTitleLabels={[`Дата`]} isMobile {...prev} />
        </Description>
      </Content>
    </Wrapper>
  );
};
