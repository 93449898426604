import * as React from 'react';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import { transparentize } from 'polished';
import { Document } from 'react-pdf';
import {
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import { IDoc } from 'models/clients/types';

import {
  LeftOutlined,
  RightOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import {
  useRotation,
  useScale,
} from 'pages/PageClient/Single/Docs/DocsCarousel/hooks';
import { PageStyled as PageStyledDefault } from 'pages/PageClient/Single/Docs/DocsCarousel/styled';

const Wrapper = styled.div<{ isVisible: boolean; }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  background: ${palette.blackestColor};

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? `visible` : `hidden`)};
  transition: opacity, 200ms ease;

  .react-transform-wrapper {
    overflow: unset;
  }
`;

const Header = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  z-index: 2;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;

  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) => (isVisible ? `none` : `translateY(-100%)`)};
  transition: all 400ms ease;

  width: 100%;
  height: 54px;
  background: ${p => transparentize(0.3, palette.blackestColor(p))};
  color: ${palette.whiteColor};
`;

const GoBackButton = styled.div`
  display: flex;
  align-items: center;

  transition: opacity 100ms ease;
  cursor: pointer;

  :active {
    opacity: 0.5;
  }

  .anticon {
    font-size: 18px;
    margin-right: 8px;
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  font-weight: 500;
  text-align: center;
`;

const Footer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  z-index: 1;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${p => transparentize(0.3, palette.blackestColor(p))};
  color: ${palette.whiteColor};

  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;

  width: 100%;
  height: 54px;

  transition: all 400ms ease;
  transform: ${({ isVisible }) => (isVisible ? `none` : `translateY(+100%)`)};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

const ImageWrapper = styled.div<{ rotation: number }>`
  width: 100%;
  height: 100%;

  justify-content: center;
  display: flex;
  align-items: center;

  transform: ${({ rotation }) => `rotate(${rotation}deg)`};
`;

const CarouselImage = styled.img`
  width: 100%;
  object-fit: contain;
  height: 100%;
`;

const Arrow = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 54px;
  z-index: 1;

  width: 54px;
  height: calc(100% - 108px);

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${palette.whiteColor};

  cursor: pointer;
  background: transparent;

  opacity: ${({ isVisible }) => (isVisible ? `1` : `0`)};
  transition: all 300ms ease;

  .anticon {
    transition: opacity 300ms ease;
    opacity: 0.5;
    font-size: 18px;
  }

  :active {
    background: ${p => transparentize(0.5, palette.blackestColor(p))};

    .anticon {
      opacity: 1;
    }
  }
`;

const ArrowLeft = styled(Arrow)`
  left: 0;
  transform: ${({ isVisible }) => (isVisible ? `none` : `translateX(-100%)`)};
`;

const ArrowRight = styled(Arrow)`
  right:0;
  transform: ${({ isVisible }) => (isVisible ? `none` : `translateX(+100%)`)};
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const IconWrapper = styled.div<{ isDisabled?: boolean; }>`
  .anticon {
    font-size: 18px;
    color: ${palette.whiteColor};
    cursor: pointer;

    padding-right: 8px;
    padding-left: 8px;
    transition: opacity 100ms ease;

    :active {
      opacity: 0.5;
    }
  }
`;

const PdfWrapper = styled.div`
  padding-top: 48px;
  padding-bottom: 48px;
  top: 48px;
  max-height: calc(100vh - 96px);
  overflow-y: auto;
  background-color: ${palette.whiteColor};

  .react-pdf__Document {
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
  }
`;

export const PageStyled = styled(PageStyledDefault)`
  canvas {
    max-width: unset;
  }
`;

interface IComponentProps {
  children: React.ReactNode;
  contentType: string;
  isVisible: boolean;
  isZoomed: boolean;
  items: IDoc[];
  onClickNext(): void;
  onClickPrev(): void;
  onClose(): void;
  previewItemIndex: number;
}

const WrapperComponent = ({ children, contentType, isZoomed }: { children: any; contentType: string; isZoomed: boolean; }) => {
  if (_.includes(contentType, `image`)) {
    return <TransformWrapper doubleClick={{ mode: isZoomed ? `reset` : `zoomIn` }}>{children}</TransformWrapper>;
  }
  if (_.includes(contentType, `pdf`)) {
    return <div>{children({})}</div>;
  }
  return <div>{children({})}</div>;
};

export const ImagePreviewModalMobile: React.FC<IComponentProps> = ({
  isVisible,
  items,
  onClickNext,
  onClickPrev,
  onClose,
  previewItemIndex,
}) => {
  const imageRef = useRef(null);
  const initialScale = 1;

  const [isZoomed, setIsZoomed] = useState(false);
  const { downscale, scale, upscale } = useScale(initialScale);
  const [isInterfaceHidden, setIsInterfaceHidden] = useState(false);

  const { rotateRight, rotation } = useRotation();

  const item = items[previewItemIndex];
  const [pagesQty, setPagesQty] = useState<number | null>(null);

  const toggleInterfaceVisibility = () => setIsInterfaceHidden(v => !v);
  const toggleIsZoomed = () => setIsZoomed(v => !v);

  useEffect(() => {
    document.body.style.overflow = isVisible ? `hidden` : `initial`;
  }, [isVisible]);

  return (
    <Wrapper isVisible={isVisible}>
      <WrapperComponent contentType={item.contentType} isZoomed={isZoomed}>
        {({ zoomIn, zoomOut }: { zoomIn: () => void; zoomOut: () => void; }) => (
          <>
            <Header isVisible={!isInterfaceHidden}>
              <GoBackButton onClick={onClose}>
                <LeftOutlined /> Назад
              </GoBackButton>
              <Pagination>
                {`${previewItemIndex + 1} из ${items.length}`}
              </Pagination>
              <Controls>
                <IconWrapper onClick={rotateRight}>
                  <RotateRightOutlined />
                </IconWrapper>
                <IconWrapper onClick={() => (zoomOut || downscale)()}>
                  <ZoomOutOutlined />
                </IconWrapper>
                <IconWrapper onClick={() => (zoomIn || upscale)()}>
                  <ZoomInOutlined />
                </IconWrapper>
              </Controls>
            </Header>

            <ArrowLeft isVisible={!isInterfaceHidden} onClick={() => onClickPrev()}>
              <LeftOutlined />
            </ArrowLeft>
            <ArrowRight isVisible={!isInterfaceHidden} onClick={() => onClickNext()}>
              <RightOutlined />
            </ArrowRight>

            <TransformComponent>
              {_.includes(item.contentType, `pdf`) && (
                <PdfWrapper onClick={toggleInterfaceVisibility} onDoubleClick={toggleIsZoomed}>
                  <Document file={item.url} onLoadSuccess={({ numPages }) => setPagesQty(numPages)}>
                    {_.times(pagesQty as number, pageIndex => (
                      <PageStyled
                        key={pageIndex}
                        pageIndex={pageIndex}
                        renderAnnotationLayer={false}
                        // @ts-ignore
                        renderForms={false}
                        renderTextLayer={false}
                        rotate={rotation}
                        scale={scale}
                      />
                    ))}
                  </Document>
                </PdfWrapper>
              )}
              {_.includes(item.contentType, `image`) && (
                <ImageWrapper
                  onClick={toggleInterfaceVisibility}
                  onDoubleClick={toggleIsZoomed}
                  rotation={rotation}
                >
                  <CarouselImage
                    alt={item.label}
                    ref={imageRef}
                    src={item.url}
                  />
                </ImageWrapper>
              )}
            </TransformComponent>

            <Footer isVisible={!isInterfaceHidden}>
              {item.label}
            </Footer>
          </>
        )}
      </WrapperComponent>
    </Wrapper>
  );
};
