import * as React from 'react';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';

import { parseResponse } from 'api/helpers';
import { hasIntersection } from 'helper';

import { getClientInfo as getClientInfoAction } from 'models/clients/actions';
import {
  getPersonIdByPhone,
  updateClientInfo,
} from 'models/clients/api';
import {
  getClient as getClientSelector,
  getIsLoadingInfo as isLoadingInfoSelector,
} from 'models/clients/selectors';
import { getItems as getDictionarySelector } from 'models/dictionaries/selectors';
import {
  CLIENT_EDIT,
  SUPER_ADMIN,
} from 'models/roles/constants';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';

import { usePath } from 'hooks/usePath';

import {
  Button,
  notification,
} from 'antd';
import CreateModal from 'components/Modal/Create';
import { FORM_FIELDS } from 'pages/PageClient/Single/Info/constants';

const NotificationControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  margin-left: -8px;

  Button {
    margin-left: 8px;
  }
`;

interface IComponentProps {
  isOpen: boolean;
  personId: string;
  toggleOpen(): void;
}

type IProps = IComponentProps;

const notificationKey = `phoneAlert`;
export const EditClientModalComponent: ({isOpen, personId, toggleOpen}: IProps) => (null | React.JSX.Element) = ({ isOpen, personId, toggleOpen }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const getClientInfo = useCallback(() => dispatch(getClientInfoAction({ personId })), [dispatch, personId]);
  const { canEditClient, client, genders, isLoadingInfo } = useSelector(state => ({
    canEditClient: hasIntersection(getRoleNamesSelector(state), [SUPER_ADMIN, CLIENT_EDIT]),
    client       : getClientSelector(state, personId),
    genders      : _.map(getDictionarySelector(state, `gender`), ({ id: _id, name }) => ({
      label: name,
      value: _id,
    })),
    isLoadingInfo: isLoadingInfoSelector(state),
  }));

  useEffect(() => {
    if (isOpen) getClientInfo();
  }, [isOpen, getClientInfo]);
  const { pushPath } = usePath();

  const doUpdateClientInfo = async (data: any) => {
    const defaultError = `Ошибка при попытке обновления клиентских данных`;
    try {
      if (!personId) throw new Error(`Не указан personId`);
      if (_.isEmpty(data)) {
        return notification.info({
          message: `Данные клиента не были обновлены ввиду отсутствия изменений на форме редактирования`,
        });
      }

      parseResponse({
        defaultError,
        errorPath: `data`,
        response : await updateClientInfo(personId, data),
      });
      getClientInfo();
    } catch (error) {
      console.log({ error }); // eslint-disable-line no-console
      return notification.error({
        message: `${defaultError}: ${error}`,
      });
    }
  };

  const onClose = () => {
    setIsLoading(false);
    toggleOpen();
  };

  const checkClientPhone = async (data: { phone: string; }) => {
    setIsLoading(true);
    const personIdByPhone = await getPersonIdByPhone(data?.phone);
    if (personIdByPhone) {
      return notification.warning({
        duration: 0,
        key     : notificationKey,
        message : (
          <div>
            <div>{`Номер уже указан как основной у клиента ${personIdByPhone}`}</div>
            <NotificationControls>
              <Button
                danger
                onClick={() => {
                  // @ts-ignore
                  notification.close(notificationKey);
                  doUpdateClientInfo(data);
                }}
                type='primary'
              >
                Всё равно сохранить
              </Button>
              <Button
                onClick={() => {
                  // @ts-ignore
                  notification.close(notificationKey);
                  pushPath(`/client/person/${personIdByPhone}/info`);
                }}
                type='primary'
              >
                Перейти к клиенту
              </Button>
            </NotificationControls>
          </div>
        ),
      });
    }
    await doUpdateClientInfo(data);
    setIsLoading(false);
  };
  const getUpdateFields = (item: any, values: { phones: any; }) => {
    const clearValues = { ..._.omit(values, `phones`), phone: values.phones };
    const initialItem = _.pick(client, _.keys(clearValues));

    return _.reduce(clearValues, (result, value, key) => {
      if ((!initialItem[key] && !value)
        || (_.toLower(_.toString(initialItem[key])) === _.toLower(_.toString(value)))
        || _.isEqual(initialItem[key], value)
      ) return result;
      return {
        ...result,
        [key]: value,
      };
    }, {});
  };

  if (!canEditClient) return null;

  return (
    <CreateModal
      close={onClose}
      formFields={FORM_FIELDS({ genders })}
      getUpdateFields={getUpdateFields}
      isLoading={isLoadingInfo || isLoading}
      item={client}
      mode='update'
      title='Редактирование клиента'
      update={checkClientPhone}
      visible={isOpen}
    />
  );
};

export const EditClientModal = EditClientModalComponent;
