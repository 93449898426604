import React, { useState } from 'react';
import moment from 'moment';

import { normalizeMomentToStartOfDay } from 'helper';

import {
  DatePicker,
  Modal,
  Space,
} from 'antd';

import { DATE_FORMAT } from './constants';

const LoanCloseModal = ({ close, onSubmit, visible }) => {
  const [date, onDateChange] = useState(moment());

  const handleSubmit = () => {
    onSubmit(normalizeMomentToStartOfDay(date));
    onDateChange(moment());
    close();
  };

  return (
    <Modal
      cancelText='Отмена'
      centered
      okText='Ok'
      onCancel={close}
      onOk={handleSubmit}
      open={visible}
      title='Закрыть договор'
    >
      <Space direction='vertical'>
        Дата закрытия
        <DatePicker
          allowClear={false}
          format={DATE_FORMAT}
          onChange={onDateChange}
          value={date}
        />
      </Space>
    </Modal>
  );
};

export default LoanCloseModal;
