import React, {
  useCallback,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import {
  get as getItemsAction,
  setSearchString as setSearchStringAction,
} from 'models/dictionaries/actions';
import { getSearchString as searchStringSelector } from 'models/dictionaries/selectors';

import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { DocumentTemplateUploadModal } from 'components/Modal/DocumentTemplateUploadModal';
import { AdminSearchWithControls } from 'pages/PageAdmin/components/AdminSearchWithControls';
import {
  CUSTOM_CONTROLS_BY_ENTITY,
  TEST_ATTRIBUTES_SEARCH_BY_ENTITY,
  UNADDABLE_ENTITIES,
  UNREMOVABLE_ENTITIES,
  UNSEARCHABLE_ENTITIES,
} from 'pages/PageAdmin/Dictionaries/Plural/constants';

interface IComponentProps {
  canEdit: boolean;
  entity: any;
  isLoading: boolean;
  loadCustomData: () => void;
  onReload: () => void;
  selectedRowKeys: any[];
  toggleCreateModal: () => void;
  toggleRemoveModal: () => void;
}

export const DictionarySearch: React.FC<IComponentProps> = ({
  canEdit,
  entity,
  isLoading,
  loadCustomData,
  onReload,
  selectedRowKeys,
  toggleCreateModal,
  toggleRemoveModal,
}) => {
  const dispatch = useDispatch();

  const [isUploadDocumentTemplateModalOpen, setUploadDocumentTemplateModalOpen] = useState(false);

  // @ts-ignore
  const searchString = useSelector(state => searchStringSelector(state, entity));

  const getItems = useCallback(() => {
    dispatch(getItemsAction({ entity, force: true }));
  }, [entity]);

  const setSearchString = useCallback((value: string) => {
    dispatch(setSearchStringAction({ entity, searchString: value }));
  }, [entity]);

  const onSearchControlClick = () => {
    setSearchString(``);
    getItems();

    if (loadCustomData) {
      loadCustomData();
    }
  };

  // @ts-ignore
  const testAttributes = TEST_ATTRIBUTES_SEARCH_BY_ENTITY[entity] || {};

  return (
    <>
      <AdminSearchWithControls
        controls={[
          {
            icon         : isLoading ? <LoadingOutlined spin /> : <SyncOutlined />,
            onClick      : onSearchControlClick,
            isDisabled   : isLoading,
            testAttribute: testAttributes.searchRefresh,
            title        : _.includes(UNSEARCHABLE_ENTITIES, entity)
              ? `Перезагрузить` : `Очистить поиск и перезагрузить`,
          },
          {
            isHidden     : !canEdit || _.includes(UNREMOVABLE_ENTITIES, entity),
            isDisabled   : _.isEmpty(selectedRowKeys),
            icon         : <DeleteOutlined />,
            onClick      : toggleRemoveModal,
            testAttribute: testAttributes.deleteButton,
            title        : `Удалить выбранные записи`,
          },
          {
            isHidden     : !canEdit || _.includes(UNADDABLE_ENTITIES, entity),
            onClick      : toggleCreateModal,
            icon         : <PlusOutlined />,
            testAttribute: testAttributes.addButton,
            title        : `Добавить запись`,
          },
          // @ts-ignore
          ...(CUSTOM_CONTROLS_BY_ENTITY({
            canEdit,
            dispatch,
            selectedRowKeys,
            setUploadDocumentTemplateModalOpen,
          })[entity] || []),
        ]}
        data-test-search-button={testAttributes.searchButton}
        data-test-search-field={testAttributes.searchField}
        search={{
          value   : searchString,
          onChange: setSearchString,
          isHidden: _.includes(UNSEARCHABLE_ENTITIES, entity),
        }}
      />

      <DocumentTemplateUploadModal
        isVisible={isUploadDocumentTemplateModalOpen}
        onClose={() => setUploadDocumentTemplateModalOpen(v => !v)}
        onReload={onReload}
      />
    </>
  );
};
