import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'dva';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Table,
  Tooltip,
} from 'antd';
import CreateModal from 'components/Modal/Create';
import { FORM_FIELDS } from 'pages/PageClient/Single/Info/AdditionalPhones';
import {
  COLUMNS,
  EDIT_ROLES,
} from './constants';
import { hasIntersection } from 'helper';
import {
  createClientPhone as createClientPhoneAction,
  updateClientPhone as updateClientPhoneAction,
} from 'models/clients/actions';
import {
  getClientPhones as getClientPhonesSelector,
  getIsLoadingPhones as getIsLoadingPhonesSelector,
} from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';
import { isLoading as isLoadingSelector } from 'models/task/selectors';
import { getRoleNames as getRoleNamesSelector } from 'models/user/selectors';
import { useModalHandlers } from 'hooks/useModalHandlers';

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const ControlButton = styled(Button)`
  position: absolute;
  right: 34px;
  top: 4px;
  z-index: 100;
`;

const Phones = ({ editRoles = [], className = '', personId }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);
  const isLoadingPhones = useSelector(getIsLoadingPhonesSelector);
  const phones = useSelector(state => getClientPhonesSelector(state, personId));
  const [, dictionaries] = useDictionaries(['phoneTypeExternal']);
  const phonesDictionary = _.orderBy(
    dictionaries.phoneTypeExternal,
    'priority',
    'desc',
  );
  const userRoles = useSelector(getRoleNamesSelector);

  const create = data => dispatch(createClientPhoneAction({ data, personId }));
  const update = ({ id: phoneId, phone }) => dispatch(updateClientPhoneAction({ personId, phone, phoneId }));

  const canEdit = hasIntersection(userRoles, _.concat(EDIT_ROLES, editRoles));
  const formFieldsCreate = FORM_FIELDS();
  const formFieldsUpdate = FORM_FIELDS('update');

  const {
    closeEditModal,
    isCreateModalOpen,
    isEditModalOpen,
    itemEditing,
    setItemEditing,
    toggleCreateModal,
    toggleEditModal,
  } = useModalHandlers();

  return (
    <Container className={className}>
      <Tooltip placement='topRight' title='Добавить телефон'>
        <ControlButton
          disabled={!canEdit}
          icon={<PlusCircleOutlined />}
          onClick={toggleCreateModal}
          shape='circle'
          type='primary'
        />
      </Tooltip>
      <Table
        bordered
        columns={COLUMNS({
          canEdit,
          phonesDictionary,
          setItemEditing,
          toggleEditModal,
        })}
        dataSource={phones}
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true,
          pageSize        : _.size(phones),
          size            : 'small',
        }}
        rowKey='id'
        scroll={{
          x: 'max-content',
          y: window.innerHeight - 700,
        }}
        size='small'
      />
      <CreateModal
        close={toggleCreateModal}
        create={create}
        formFields={formFieldsCreate}
        isLoading={isLoadingPhones}
        phonesDictionary={phonesDictionary}
        title='Новый телефон'
        visible={isCreateModalOpen}
      />
      {isEditModalOpen && (
        <CreateModal
          close={closeEditModal}
          formFields={formFieldsUpdate}
          isLoading={isLoadingPhones}
          item={itemEditing}
          mode='update'
          phonesDictionary={phonesDictionary}
          title='Редактирование телефона'
          update={update}
          visible={isEditModalOpen}
        />
      )}
    </Container>
  );
};

export default Phones;
