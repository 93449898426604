import * as React from 'react';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import { resetFilters as resetFiltersAction } from 'models/courts/actions';
import { useTableState } from 'models/courts/hooks';

import {
  ClearOutlined,
  FileExcelOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button as ButtonDefault,
  Input,
} from 'antd';
import { DefaultTooltip } from 'components/DefaultTooltip/DefaultTooltip';
import { SubHeader } from 'components/SubHeader/SubHeader';

const Buttons = styled.div`
  display: flex;
  margin-left: -16px;

  @media (max-width: ${breakpoints.md}) {
    margin-left: -20px;
  }
`;

const Button = styled(ButtonDefault)`
  margin-left: 16px;

  @media (max-width: ${breakpoints.md}) {
    min-width: calc((100vw - 72px) / 3);
  }
`;

const FiltersItem = styled.div`
  display: flex;
  flex: 1;
  max-width: 400px;
  margin-right: 16px;
`;

const Filters = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

`;

const StyledSubheader = styled(SubHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;

    ${Filters} {
      flex-direction: column;
      width: 100%;

      ${FiltersItem} {
        max-width: 100%;
        min-width: 100%;
        margin-right: 0;
        margin-bottom: 12px;

        input {
          min-width: 100%;
        }
      }
    }
  }
`;

interface IValues {
  clientFullName: string | null;
  contractNumber: string | null;
  courtCase: string | null;
}

interface IProps {
  courtIds: string[];
  onDownloadExcel():void;
}

export const CourtSubHeader: React.FC<IProps> = ({ courtIds, onDownloadExcel }) => {
  const dispatch = useDispatch();
  const [tableState, setTableState] = useTableState();
  const [values, setValues] = useState<IValues>({
    clientFullName: tableState?.filters?.clientFullName?.[0],
    contractNumber: tableState?.filters?.contractNumber?.[0],
    courtCase     : tableState?.filters?.courtCase?.[0],
  });
  useEffect(() => {
      setValues({
        clientFullName: tableState?.filters?.clientFullName?.[0],
        contractNumber: tableState?.filters?.contractNumber?.[0],
        courtCase: tableState?.filters?.courtCase?.[0],
      })
    },
  [
    tableState?.filters?.clientFullName?.[0],
    tableState?.filters?.contractNumber?.[0],
    tableState?.filters?.courtCase?.[0],
  ]);

  const setValue = (value: string, key:string) => {
    setValues(_values => ({
      ..._values,
      [key]: value ? [value] : null,
    }));
  };

  const onResetFilters = useCallback(() => dispatch(resetFiltersAction()), [dispatch]);

  useEffect(() => {
    onResetFilters()
  }, []);

  const setFilters = () => {
    setTableState(
      tableState?.pagination,
      {
        ...tableState?.filters,
        clientFullName: values.clientFullName ? _.castArray(values.clientFullName) : null,
        contractNumber: values.contractNumber ? _.castArray(values.contractNumber) : null,
        courtCase     : values.courtCase ? _.castArray(values.courtCase) : null,
      },
      tableState?.sorter,
    );
  };

  return (
    <StyledSubheader>
      <Filters>
        <FiltersItem>
          <Input
            onChange={e => setValue(e.target.value, `clientFullName`)}
            onPressEnter={setFilters}
            placeholder='ФИО'
            // @ts-ignore
            value={values.clientFullName}
          />
        </FiltersItem>
        <FiltersItem>
          <Input
            onChange={e => setValue(e.target.value, `contractNumber`)}
            onPressEnter={setFilters}
            placeholder='Номер договора'
            // @ts-ignore
            value={values.contractNumber}
          />
        </FiltersItem>
        <FiltersItem>
          <Input
            onChange={e => setValue(e.target.value, `courtCase`)}
            onPressEnter={setFilters}
            placeholder='Номер дела'
            // @ts-ignore
            value={values.courtCase}
          />
        </FiltersItem>
      </Filters>
      <Buttons>
        <DefaultTooltip title='Сбросить фильтры'>
          <Button icon={<ClearOutlined />} onClick={onResetFilters} />
        </DefaultTooltip>
        <DefaultTooltip title='Скачать в Excel'>
          <Button
            disabled={_.isEmpty(courtIds)}
            icon={<FileExcelOutlined />}
            onClick={onDownloadExcel}
            type='primary'
          />
        </DefaultTooltip>
        <DefaultTooltip title='Поиск'>
          <Button icon={<SearchOutlined />} onClick={setFilters} type='primary' />
        </DefaultTooltip>
      </Buttons>
    </StyledSubheader>
  );
};
