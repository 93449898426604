export const DEFAULT_PREFERENCES = {
  notifications: {
    placement                   : `topRight`,
    importantNotificationList   : [],
    telegramBellNotificationList: [],
  },
  ui: {

  },
  verification: {
    isEmptyAutoSend        : false,
    isResultsIncompleteSend: false,
  },
};

export const ATTORNEY_TYPE_ID = 111;
