import * as React from 'react';
import _ from 'lodash';

import {
  Button,
  Form,
  Tooltip,
} from 'antd';

interface IComponentProps {
  isLoading: boolean;
  pdn: number;
  tooltip?: string;
  calculatePdn():void;
}

export const FormRowCalculatePdn: React.FC<IComponentProps> = ({ calculatePdn, isLoading, pdn, tooltip }) => (
  <Form.Item label={_.isNil(pdn) ? `ПДН` : `ПДН  - ${pdn}`}>
    <Tooltip placement='top' title={tooltip}>
      <Button
        disabled={!!tooltip}
        loading={isLoading}
        onClick={calculatePdn}
        type='default'
      >
        Расчет ПДН
      </Button>
    </Tooltip>
  </Form.Item>
);
