export const INSURANCE_STATUSES = {
  NEW      : 1,
  CANCELLED: 11,
  ACTIVE   : 12,
};

export const INSURANCE_STATUSES_COLORS = {
  [INSURANCE_STATUSES.NEW]      : `default`,
  [INSURANCE_STATUSES.CANCELLED]: `error`,
  [INSURANCE_STATUSES.ACTIVE]   : `success`,
};

export const INSURANCE_STATUSES_NAMES = {
  [INSURANCE_STATUSES.NEW]      : `Новая`,
  [INSURANCE_STATUSES.CANCELLED]: `Отменена`,
  [INSURANCE_STATUSES.ACTIVE]   : `Активная`,
};
