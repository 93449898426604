import _ from 'lodash';
import ExcelJS from 'exceljs';
import moment from 'moment';

import {
  downloadBlob,
  formatDate,
} from 'helper';

import { FORM_FIELDS } from './constants';

export const getInitialValues = genders => (item, mode) => ({
  ..._.reduce(FORM_FIELDS({ genders }), (result, field) => ({
    ...result,
    [field.key]: mode === `create`
      ? (field.initialValue || ``)
      : (_.get(item, field.key, _.get(item, `user.${field.key}`, field.initialValue || ``))),
  }), {}),
  birthDate: moment.isMoment(_.get(item, `user.birthDate`))
    ? item.user.birthDate
    : (_.get(item, `user.birthDate`) ? moment(item.user.birthDate) : ``),
  roleGroupId: null,
});

export const getUpdateFields = genders => (item, values) => ({
  ...{
    ...item.user,
    ..._.pick(values, _.map(_.filter(FORM_FIELDS({ genders }), { entity: `user` }), `key`)),
  },
  ..._.omit(item, `user`),
  ..._.pick(values, _.map(_.filter(FORM_FIELDS({ genders }), { entity: `operator` }), `key`)),
  mttTerminals: _.split(values.mttTerminals, `,`),
});

export const downloadExcel = async ({ columns, items }) => {
  const dateFormatted = formatDate(new Date(), `dd.MM.yyyy`);
  const fileName = `Сотрудники Кэшдрайв_${dateFormatted}`;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(fileName);

  // Устанавливаем столбцы
  worksheet.columns = _.map(columns, ({ key, title, width }) => ({
    header: title,
    key,
    width : width / 6,
  }));

  // Заполняем книгу данными
  _.each(items, item => {
    worksheet.addRow(_.reduce(columns, (result, column, key) => {
      const render = column.renderExcel || column.render || _.identity;
      const itemValue = _.get(item, column.dataIndex || column.key, ``);
      return {
        ...result,
        [column.key]: render(itemValue, item),
      };
    }, {}));
  });

  // Преобразуем книгу в blob
  const blob = await workbook.xlsx.writeBuffer();
  const blobObject = new Blob(
    [blob],
    { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` },
  );

  // Скачиваем файл
  downloadBlob(blobObject, `${fileName}.xlsx`);
};
