import React from 'react';

import { TEventlogItem } from 'models/eventlogs/types';

import useMobileContext from 'hooks/useMobileContext';

import {
  CheckCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { ItemHead } from 'components/Eventlog/Item/ItemHead';

import {
  Content,
  Description,
  Icon,
  Wrapper,
} from '../styled';

interface IComponentProps extends TEventlogItem {
  date:string;
  operatorName?:string;
}

export const VerificationSend: React.FC<IComponentProps> = ({ comment, date, next, operatorName }) => {
  const { mobile } = useMobileContext();
  const title = `Отправлено решение`;
  let description;
  let icon;
  let color;
  if (comment === `approve`) {
    color = `green`;
    description = `Одобрено`;
    icon = <CheckCircleOutlined />;
  } else {
    color = `red`;
    description = `Отказано. Строка решения: ${next}`;
    icon = <StopOutlined />;
  }

  return (
    <Wrapper $isMobile={mobile}>
      <Icon $color={color}>{icon}</Icon>
      <Content>
        <ItemHead date={date} operatorName={operatorName} title={title} />
        <Description>{description}</Description>
      </Content>
    </Wrapper>
  );
};
