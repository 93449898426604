import styled from 'styled-components';
import { palette } from 'styles/theme';

export const SummaryHeadline = styled.div`
  font-weight: 500;
  color: ${palette.primary500Color};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  .anticon {
    margin-right: 8px;
  }
`;

export const SummaryTitle = styled.div<{ align: `right` | `left` | `center` }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: ${({ align }) => align};
  color: ${palette.black80Color};
`;

export const SummaryValue = styled.div<{ align: `right` | `left` | `center` }>`
  text-align: ${({ align }) => align};
`;
