/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import {
  Route,
  Switch,
} from 'dva/router';

import {
  getClient as getClientAction,
  getClientApplications as getClientApplicationsAction,
  getClientLoans as getClientLoansAction,
} from 'models/clients/actions';
import { isClientLoaded as isClientLoadedSelector } from 'models/clients/selectors';
import { get as getDictionaryAction } from 'models/dictionaries/actions';

import PageNotFound from 'pages/PageNotFound';

import Action from './Action';
import Application from './Application';
import Call from './Call';
import Car from './Car';
import CarService from './CarService';
import ClientMatchContext from './ClientMatchContext';
import Comment from './Comment';
import Communication from './Communication';
import CreditHistory from './CreditHistory';
import Debt from './Debt';
import Decision from './Decision';
import Docs from './Docs';
import Eventlog from './Eventlog';
import Geolocation from './Geolocation';
import Info from './Info';
import Loan from './Loan';
import MarketingOffer from './MarketingOffer';
import Sms from './Sms';
import Triggers from './Triggers';
import Vector from './Vector';
import VerificationResult from './VerificationResult';

interface IProps {
  match: {
    params: {
      personId: string;
      verificationId: string;
    };
  };
}

const PageClientSingle: React.FC<IProps> = ({ match }) => {
  const personId = _.get(match, `params.personId`);
  const verificationId = _.toNumber(_.get(match, `params.verificationId`));

  // @ts-ignore
  const isLoaded = useSelector(state => isClientLoadedSelector(state, personId));

  const dispatch = useDispatch();
  const getDictionary = useCallback((entity: string, force = true) => dispatch(getDictionaryAction({ entity, force })), [dispatch]);

  const getApplications = useCallback(() => dispatch(getClientApplicationsAction({ personId })), [dispatch, personId]);
  const getClient = useCallback((callback: () => void) => dispatch(getClientAction({ personId, callback })), [dispatch, personId]);
  const getLoans = useCallback(() => dispatch(getClientLoansAction({ personId })), [dispatch, personId]);

  useEffect(() => {
    getDictionary(`gender`);
    if (!isLoaded) {
      getClient(() => {
        getApplications();
        getLoans();
      });
    }
  }, [getApplications, getClient, getDictionary, getLoans, isLoaded]);

  return (
    <ClientMatchContext.Provider value={{ personId, verificationId }}>
      <Switch>
        <Route component={Info} exact path='/client/person/:personId/info' />
        <Route component={Info} exact path='/sale/:verificationId/person/:personId/info' />
        <Route component={Info} exact path='/verification/:verificationId/person/:personId/info' />

        <Route component={Application} exact path='/client/person/:personId/application' />
        <Route component={Application} exact path='/sale/:verificationId/person/:personId/application' />
        <Route component={Application} exact path='/verification/:verificationId/person/:personId/application' />

        <Route component={Loan} exact path='/client/person/:personId/loan' />
        <Route component={Loan} exact path='/sale/:verificationId/person/:personId/loan' />
        <Route component={Loan} exact path='/verification/:verificationId/person/:personId/loan' />

        <Route component={CreditHistory} exact path='/client/person/:personId/credit-history' />
        <Route component={CreditHistory} exact path='/sale/:verificationId/person/:personId/credit-history' />
        <Route component={CreditHistory} exact path='/verification/:verificationId/person/:personId/credit-history' />

        <Route component={MarketingOffer} exact path='/client/person/:personId/marketingOffer' />
        <Route component={MarketingOffer} exact path='/verification/:verificationId/person/:personId/marketingOffer' />

        <Route component={Docs} path='/client/person/:personId/doc' />
        <Route component={Docs} path='/sale/:verificationId/person/:personId/doc' />
        <Route component={Docs} path='/verification/:verificationId/person/:personId/doc' />

        <Route component={Car} path='/client/person/:personId/car' />
        <Route component={Car} path='/sale/:verificationId/person/:personId/car' />
        <Route component={Car} path='/verification/:verificationId/person/:personId/car' />

        <Route component={CarService} path='/sale/:verificationId/person/:personId/car-service' />
        <Route component={CarService} path='/verification/:verificationId/person/:personId/car-service' />

        <Route component={Comment} path='/client/person/:personId/comment' />
        <Route component={Comment} path='/sale/:verificationId/person/:personId/comment' />
        <Route component={Comment} path='/verification/:verificationId/person/:personId/comment' />

        <Route component={Debt} path='/client/person/:personId/debt' />
        <Route component={Debt} path='/sale/:verificationId/person/:personId/debt' />
        <Route component={Debt} path='/verification/:verificationId/person/:personId/debt' />

        <Route component={Sms} path='/client/person/:personId/sms' />
        <Route component={Sms} path='/sale/:verificationId/person/:personId/sms' />
        <Route component={Sms} path='/verification/:verificationId/person/:personId/sms' />

        <Route component={Communication} path='/client/person/:personId/communication' />
        <Route component={Communication} path='/sale/:verificationId/person/:personId/communication' />
        <Route component={Communication} path='/verification/:verificationId/person/:personId/communication' />

        <Route component={Call} path='/client/person/:personId/call' />

        <Route component={Action} path='/client/person/:personId/action' />

        <Route component={Triggers} path='/client/person/:personId/trigger' />
        <Route component={Triggers} path='/sale/:verificationId/person/:personId/trigger' />
        <Route component={Triggers} path='/verification/:verificationId/person/:personId/trigger' />

        <Route component={Vector} path='/sale/:verificationId/person/:personId/vector' />
        <Route component={Vector} path='/verification/:verificationId/person/:personId/vectorNew' />
        {/* <Route component={VectorOld} path='/verification/:verificationId/person/:personId/vector' /> */}

        <Route component={Geolocation} path='/sale/:verificationId/person/:personId/geolocation' />
        <Route component={Geolocation} path='/verification/:verificationId/person/:personId/geolocation' />

        <Route component={VerificationResult} exact path='/sale/:verificationId/person/:personId/check' />
        <Route component={VerificationResult} exact path='/verification/:verificationId/person/:personId/check' />

        <Route component={Decision} path='/sale/:verificationId/person/:personId/decision' />
        <Route component={Decision} path='/verification/:verificationId/person/:personId/decision' />

        <Route component={Eventlog} path='/sale/:verificationId/person/:personId/eventlog' />
        <Route component={Eventlog} path='/verification/:verificationId/person/:personId/eventlog' />

        <Route component={PageNotFound} />
      </Switch>
    </ClientMatchContext.Provider>
  );
};

export default PageClientSingle;
