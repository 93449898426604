import _ from 'lodash';

export const getItems = ({ carServiceTitles, key, value: _value }) => _.map(_.keys(_value), itemKey => {
  const itemDictionary = _.find(carServiceTitles, { name: `${key}.${itemKey}` });
  const value = _value[itemKey];
  const description = _.get(itemDictionary, `description`, itemKey);

  if (_.isArray(value) && _.get(itemDictionary, `isStringArray`)) {
    return {
      format: _.get(itemDictionary, `format`),
      name  : description,
      value : _.join(value, `, `),
    };
  }

  if (_.isArray(value) && _.get(itemDictionary, `isObjectArray`)) {
    return {
      items: _.map(
        _.map(value, item => getItems({ carServiceTitles, key: `${key}.${itemKey}`, value: item })),
        (items, index) => ({
          items,
          subtitle: `${index + 1}`,
        }),
      ),
      subtitle: description,
    };
  }

  if (_.isObjectLike(value)) {
    return {
      items   : getItems({ carServiceTitles, key: `${key}.${itemKey}`, value }),
      subtitle: description,
    };
  }

  return {
    format: _.get(itemDictionary, `format`),
    name  : description,
    value,
  };
});
