export const EMOJI_LIST = [
  `😀`, `😃`, `😄`, `😁`, `😆`, `😅`, `😂`, `🤣`, `☺️`, `😊`, `😇`, `🙂`, `🙃`, `😉`, `😌`, `😍`, `😘`,
  `😗`, `😙`, `😚`, `😋`, `😛`, `😝`, `😜`, `🤪`, `🤨`, `🧐`, `🤓`, `😎`, `🤩`, `😏`, `😒`, `😞`, `😔`,
  `😟`, `😕`, `🙁`, `☹️`, `😣`, `😖`, `😫`, `😩`, `😢`, `😭`, `😤`, `😠`, `😡`, `🤬`, `🤯`, `😳`, `😱`,
  `😨`, `😰`, `😥`, `😓`, `🤗`, `🤔`, `🤭`, `🤫`, `🤥`, `😶`, `😐`, `😑`, `😬`, `🙄`, `😯`, `😦`, `😧`,
  `😮`, `😲`, `😴`, `🤤`, `😪`, `😵`, `🤐`, `🤢`, `🤮`, `🤧`, `😷`, `🤒`, `🤕`, `🤑`, `🤠`, `😈`, `👿`,
  `👹`, `👺`, `🤡`, `💩`, `👻`, `💀`, `☠️`, `👽`, `👾`, `🤖`, `🎃`, `😺`, `😸`, `😹`, `😻`, `😼`, `😽`,
  `🙀`, `😿`, `😾`, `👋`, `🤚`, `🖐`, `✋`, `🖖`, `👌`, `✌️`, `🤞`, `🤟`, `🤘`, `🤙`, `👈`, `👉`, `👆`,
  `🖕`, `👇`, `☝️`, `👍`, `👎`, `✊`, `👊`, `🤛`, `🤜`, `👏`, `🙌`, `👐`, `🤲`, `🤝`, `🙏`, `✍️`, `💅`,
  `🤳`, `💪`, `👂`, `👃`, `👀`, `👶`, `🧒`, `👦`, `👧`, `🧑`, `👱`, `👨`, `🧔`, `👩`, `👱‍♀️`, `👱‍♂️`, `🧓`,
  `👴`, `👵`, `🙍`, `🙍‍♂️`, `🙍‍♀️`, `🙎`, `🙎‍♂️`, `🙎‍♀️`, `🙅`, `🙅‍♂️`, `🙅‍♀️`, `🙆`, `🙆‍♂️`, `🙆‍♀️`, `💁`, `💁‍♂️`, `💁‍♀️`,
  `🙋`, `🙋‍♂️`, `🙋‍♀️`, `🙇`, `🙇‍♂️`, `🙇‍♀️`, `🤦`, `🤦‍♂️`, `🤦‍♀️`, `🤷`, `🤷‍♂️`, `🤷‍♀️`, `🐶`, `🐱`, `🐭`, `🐹`, `🐰`,
  `🦊`, `🐻`, `🐼`, `🐨`, `🐯`, `🦁`, `🐮`, `🐷`, `🐽`, `🐸`, `🐵`, `🙈`, `🙉`, `🙊`, `🐒`, `🐔`, `🐧`,
  `🐦`, `🐤`, `🐣`, `🐥`,
];

export const EMOJI_RECENTLY_MAX = 21;
