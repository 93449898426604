import React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { palette } from 'styles/theme';

import {
  formatDuration,
  getColumn,
  getSort,
} from 'helper';

import { ArrowRightOutlined } from '@ant-design/icons';
import DateFilterDropDown from 'components/DateFilterDropDown';
import { CellDateTime } from 'components/DefaultTable/CellDateTime/CellDateTime';
import { CellId } from 'components/DefaultTable/CellId/CellId';

const PhoneType = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneIcon = styled(ArrowRightOutlined)<{ $isOutgoing: boolean; }>`
  margin-right: 8px;

  ${({ $isOutgoing }) => ($isOutgoing ? css`
    transform: rotate(-45deg);
    color: ${palette.primary500Color};
  ` : css`
    transform: rotate(135deg);
  `)}
`;

const TYPE_FILTER = [
  {
    text : `Входящий`,
    value: `incoming`,
  },
  {
    text : `Исходящий`,
    value: `outgoing`,
  },
];

export const COLUMNS = (tableState: { filters: { startTime: any; type: any; }; }) => _.map([
  {
    key   : `callId`,
    render: (v: string) => <CellId length={16} value={v} />,
    title : `ID`,
    width : 120,
  },
  {
    defaultSortOrder: `descend`,
    key             : `startTime`,
    title           : `Дата звонка`,
    filterDropdown  : ({ confirm, selectedKeys, setSelectedKeys, visible }: { confirm: () => void; selectedKeys: any; setSelectedKeys: any; visible: boolean; }) => (
      <DateFilterDropDown
        confirm={confirm}
        // @ts-ignore
        isVisible={visible}
        placeholder={[`с`, `по`]}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
      />
    ),
    filtered     : !_.isEmpty(tableState?.filters?.startTime),
    filteredValue: tableState?.filters?.startTime,
    sorter       : getSort(`startTime`, (a: any, b: any) => (_.isNil(a) ? -1 : (a < b ? -1 : 1))),
    render       : (v: number | undefined) => (_.isNil(v) ? `—` : <CellDateTime value={v} />),
    width        : 100,
  },
  {
    key           : `type`,
    title         : `Тип звонка`,
    filtered      : !_.isEmpty(tableState?.filters?.type),
    filteredValue : tableState?.filters?.type,
    filters       : TYPE_FILTER,
    filterMultiple: false,
    render        : (v: string) => (
      <PhoneType>
        <PhoneIcon $isOutgoing={v === `outgoing`} />
        {v === `outgoing` ? `Исходящий` : `Входящий`}
      </PhoneType>
    ),
    width: 100,
  },
  {
    key   : `duration`,
    render: (v: any) => (_.isNil(v) ? `—` : formatDuration(v)),
    title : `Примерная продолжительность (мм:cc)`,
    width : 140,
  },
], getColumn);
