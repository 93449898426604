import _ from 'lodash';
import moment from 'moment';

import { parseResponse } from 'api/helpers';
import { showError } from 'helper';

import { notification } from 'antd';

import {
  CREATE,
  CREATE_PERSON_ACTION,
  GET,
  GET_PERSON_ACTION,
  model as namespace,
  REMOVE_PERSON_ACTION,
  SET,
  SET_LOADING,
  SET_PERSON_ACTIONS,
  UPDATE,
  UPLOAD_FILE,
} from './actions';
import * as api from './api';
import { getItems as getItemsSelector } from './selectors';

const initialState = {
  isLoaded     : false,
  isLoading    : false,
  items        : [],
  personActions: {},
};

export default {
  namespace,
  state  : initialState,
  effects: {
    *[CREATE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { data } = action.payload;
      const defaultError = `При создании акции произошла ошибка`;

      try {
        if (_.isEmpty(data)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.create, data),
        });

        yield put({ type: GET });
        notification.success({ message: `Акция успешно создана` });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[CREATE_PERSON_ACTION](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { actionIds, personId } = action.payload;
      const defaultError = `При добавлении акции клиенту с personId ${personId} произошла ошибка`;

      try {
        if (!personId || _.isEmpty(actionIds) || !_.isArray(actionIds)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.createPersonAction, _.map(actionIds, actionId => ({ actionId, personId }))),
        });

        yield put({ type: GET_PERSON_ACTION, payload: { personId } });
        notification.success({ message: `Акции успешно добавлены` });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const defaultError = `При загрузке акций произошла ошибка`;
      try {
        const items = parseResponse({
          defaultError,
          response: yield call(api.get),
        });

        yield put({
          type   : SET,
          payload: {
            items: _.map(items, data => ({
              ...data,
              dates: [moment.utc(data.startDtm), moment.utc(data.endDtm)],
            })),
            isLoaded: true,
          },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[GET_PERSON_ACTION](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { personId } = action.payload;
      const defaultError = `При загрузке акций клиента с personId ${personId} произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);

        const items = parseResponse({
          defaultError,
          response: yield call(api.getPersonAction, personId),
        });

        yield put({
          type   : SET_PERSON_ACTIONS,
          payload: { personId, items },
        });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[REMOVE_PERSON_ACTION](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { actionId, personId } = action.payload;
      const defaultError = `При удалении акции клиента с id ${personId} произошла ошибка`;

      try {
        if (!personId) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.removePersonAction, { actionId, personId }),
        });

        yield put({ type: GET_PERSON_ACTION, payload: { personId } });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPDATE](action, { call, put, select }) {
      yield put({ type: SET_LOADING, payload: true });
      const { data, id } = action.payload;
      const defaultError = `При редактировании акции произошла ошибка`;

      try {
        if (!id || _.isEmpty(data)) throw new Error(defaultError);

        parseResponse({
          defaultError,
          response: yield call(api.update, id, data),
        });

        const currentItems = yield select(getItemsSelector);

        yield put({
          type   : SET,
          payload: {
            items: _.map(currentItems, currentItem => (currentItem.id === id
              ? {
                ...data,
                dates: [moment.utc(data.startDtm), moment.utc(data.endDtm)],
              } : currentItem)),
          },
        });
        notification.success({ message: `Акция успешно отредактирована` });
        if (_.isFunction(_.get(action.payload, `callback`))) action.payload.callback();
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },

    *[UPLOAD_FILE](action, { call, put }) {
      yield put({ type: SET_LOADING, payload: true });
      const { file } = action.payload;
      const defaultError = `При загрузке файла произошла ошибка`;

      try {
        const addedCount = parseResponse({
          defaultError,
          response: yield call(api.uploadFile, file),
        });

        notification.success({ message: `Добавлено связей акция-personId: ${addedCount}` });
      } catch (error) {
        showError({ defaultError, error });
      } finally {
        yield put({ type: SET_LOADING, payload: false });
      }
    },
  },
  reducers: {
    [SET](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        ...payload,
      };
    },

    [SET_PERSON_ACTIONS](state, { payload }) {
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        personActions: {
          ...(state.personActions || {}),
          [payload.personId]: payload.items || [],
        },
      };
    },

    [SET_LOADING](state, { payload = false }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
};
