import React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

import {
  formatDate,
  prettifyNumber,
} from 'helper';

import { getClientVerifications as getClientVerificationsSelector } from 'models/clients/selectors';

import { usePath } from 'hooks/usePath';

import {
  Button,
  Select,
} from 'antd';

const ControlsSelect = styled(Select)`
  flex: 1;
  min-width: 400px;
`;

const ControlsButton = styled(Button)`
  margin-left: 16px;
`;

export const StyledControls = styled.div`
  position: absolute;
  top: 16px;
  left: 24px;
  width: calc(100% - 24px);
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 58px 0 32px;

  @media (max-width: ${breakpoints.lg}) {
    padding-left: 16px;
    padding-right: 40px;
  }

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0 16px;

    ${ControlsSelect} {
      min-width: 288px;
    }

    ${ControlsButton} {
      margin-top: 8px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    padding-left: 0;
    padding-right: 8px;
    margin-left: -8px;
  }
`;

interface IProps {
  personId: string;
  verificationId: number;
}

export const VerificationSelect: React.FC<IProps> = ({ personId, verificationId }) => {
  const { replacePath } = usePath();

  const setVerificationId = (_verificationId: number) => {
    replacePath(`/verification/${_verificationId}/person/${personId}/check`);
  };

  const verifications = useSelector(state => getClientVerificationsSelector(state, personId));
  return (
    <StyledControls>
      <ControlsSelect
        // @ts-ignore
        onChange={setVerificationId}
        value={verificationId}
      >
        {_.map(verifications, verification => (
          <Select.Option key={verification.id} value={verification.id}>
            {`${verification.applicationId} от ${formatDate(new Date(verification.createdAt))}${verification.application?.productId ? `, продукт: ${verification.application?.productName || verification.application?.productId}` : ``}${verification.application?.requestedLoan ? `, запрошено: ${prettifyNumber(verification.application?.requestedLoan)}` : ``}${verification.application?.applicationStatusName ? `, статус: ${(verification.application?.applicationStatusName)}` : ``}`} {/* eslint-disable-line max-len */}
          </Select.Option>
        ))}
      </ControlsSelect>
    </StyledControls>
  );
};
