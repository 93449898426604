import styled from 'styled-components';
import { palette } from 'styles/theme';

import { Button } from 'antd';

export const StyledButton = styled(Button)`
  display: block;
  margin-left: 8px;
  height: 24px;
  padding: 4px 8px;
  border: 0;
  color: ${palette.black80Color};
  background-color: transparent;

  &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  .anticon, svg {
    display: block;
  }
`;

export const Label = styled.div<{ $isComment?: boolean; }>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: ${palette.black80Color};
  flex: 1;
  margin-left: 16px;

  ${({ $isComment }) => $isComment && `max-width: fit-content;`}
`;

export const Value = styled(Label)<{ $bold?: boolean; }>`
  color: ${palette.black100Color};
  text-align: left;
  flex: 1;
  white-space: pre-wrap;
  ${({ $bold }) => $bold && `font-weight: 800;`}
`;
