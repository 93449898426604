import * as React from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import {
  breakpoints,
  getLightStyleOr,
  palette,
  shadows,
} from 'styles/theme';

import {
  reply as replyMessageAction,
  sendMessage as sendMessageAction,
  update as updateMessageAction,
} from 'models/dialogs/actions';
import { isLoading as isLoadingSelector } from 'models/dialogs/selectors';
import { getId as getCurrentUserIdSelector } from 'models/user/selectors';

import useMobileContext from 'hooks/useMobileContext';

import { RcFile } from 'antd/lib/upload';
import { ACCEPT } from 'components/Messenger/constants';
import { InputAttachments } from 'components/Messenger/Input/InputAttachments';
import { InputControls } from 'components/Messenger/Input/InputControls/InputControls';
import { InputTextarea } from 'components/Messenger/Input/InputTextarea';
import { useMentions } from 'components/Messenger/Input/useMentions';
import {
  IDialog,
  IMessage,
} from 'components/Messenger/types';

const ACCEPT_STR = _.join(ACCEPT);

const Wrapper = styled.div`
  padding: 8px;
  background: ${getLightStyleOr(palette.backgroundColor, palette.black5Color)};
  width: 100%;
  max-width: 678px;
  border-top: 1px solid ${palette.borderDefaultColor};

  @media(min-width: ${breakpoints.md}) {
    padding: 16px;
  }
`;

const InputWrapper = styled.div`
  border-radius: 8px;
  box-shadow: ${shadows.image};
  background: ${palette.backgroundColor};

  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

interface IComponentProps {
  clearFiles: () => void;
  dialog: IDialog;
  files: any[];
  isProcessing: boolean;
  message: string;
  messageToEdit: IMessage | null;
  messageToReply: IMessage | null;
  notificationsListIds: number[];
  notificationTitle?: string;
  addFile(file: RcFile): void;
  removeFileByIndex(index: number): void;
  resetState():void;
  setMessage(message: string): void;
  setNotificationsListIds(ids: number[]): void;
}

export const InputCore = React.forwardRef<unknown, IComponentProps>((
  {
    addFile,
    clearFiles,
    dialog,
    files,
    isProcessing: isFileProcessing,
    message,
    messageToEdit,
    messageToReply,
    notificationsListIds,
    notificationTitle,
    removeFileByIndex,
    resetState,
    setMessage,
    setNotificationsListIds,
  },
  ref,
) => {
  const dispatch = useDispatch();

  const isNothingToSend = !message && _.isEmpty(files);
  const isLoading = useSelector(isLoadingSelector);
  const isSendingDisabled = isNothingToSend || isLoading || isFileProcessing;

  const { mentionOptions } = useMentions();
  // @ts-ignore
  const filteredOptions = _.filter(mentionOptions, option => !_.includes(notificationsListIds, option.id));

  const { mobile } = useMobileContext();

  const authorId = useSelector(getCurrentUserIdSelector);

  const focusInput = () => {
    // @ts-ignore
    if (ref && ref.current) {
      // @ts-ignore
      ref.current.focus();
    }
  };

  // @ts-ignore
  const addNotificationList = ({ key }) => {
    const notificationListId = _.toNumber(key);

    if (_.includes(notificationsListIds, notificationListId)) {
      return;
    }

    // @ts-ignore
    setNotificationsListIds(v => [...v, notificationListId]);
  };

  const addEmoji = (emoji: string) => {
    // @ts-ignore
    const input = ref.current.textarea;
    const position = input.selectionStart as number;
    // @ts-ignore
    setMessage(v => `${v.slice(0, position)}${emoji}${v.slice(position)}`);

    setTimeout(() => {
      input.selectionStart = position + emoji.length;
      input.selectionEnd = position + emoji.length;
    }, 0);
  };

  const sendMessage = () => {
    focusInput();

    if (isSendingDisabled) return;

    const action = messageToEdit
      ? updateMessageAction
      : messageToReply
        ? replyMessageAction
        : sendMessageAction;

    const messageId = messageToEdit?.id || messageToReply?.id || null;

    const filesMeta = _.map(files, ({
      lastModified,
      lastModifiedDate,
      name,
      size,
      type,
      uid,
    }) => ({ lastModified, lastModifiedDate, name, size, type, uid }));

    dispatch(action({
      callback: resetState,
      id      : dialog.id,
      messageId,
      message : {
        files,
        filesMeta,
        authorId,
        dialogId           : dialog.id,
        raw                : message,
        notificationListIds: notificationsListIds,
      },
      title: notificationTitle,
      url  : window.location.pathname,
    }));
  };

  return (
    <Wrapper>
      {!_.isEmpty(files) && (
        <InputAttachments
          clearFiles={clearFiles}
          files={files}
          isProcessing={isFileProcessing}
          onRemoveFile={index => removeFileByIndex(index)}
        />
      )}

      <InputWrapper>
        <InputTextarea
          // @ts-ignore
          inputRef={ref}
          mentionOptions={filteredOptions}
          message={message}
          onChange={setMessage}
          onPressEnter={e => {
            if (!e.shiftKey && !mobile) {
              e.preventDefault();
              e.stopPropagation();
              sendMessage();
            }
          }}
          onSelectMention={addNotificationList}
        />

        <InputControls
          acceptString={ACCEPT_STR}
          isDisabled={isSendingDisabled}
          isLoading={isLoading}
          onEmojiClose={focusInput}
          onEmojiSelect={addEmoji}
          onSend={sendMessage}
          onUpload={data => addFile(data)}
          onUploadClick={focusInput}
        />
      </InputWrapper>
    </Wrapper>
  );
});
