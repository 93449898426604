import * as React from 'react';
import styled from 'styled-components';
import { palette } from 'styles/theme';

export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.black80Color};
`;

export const Control = styled.div`
   display: flex;
   align-items: center;

  >* {
    flex: 1;
  }
`;

export const ControlNoGrow = styled(Control)`
   flex-grow: 0 !important;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  &+& {
    border-top: 1px solid ${palette.black10Color};
  }

  >* {
    flex: 1;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    ${Label}, ${Control} {
      width: 100%;
      .ant-switch {
        max-width: 44px;
      }
    }

    ${Label} {
      margin-bottom: 8px;
    }
  }
`;

interface IComponentProps {
  children: React.ReactNode;
  label: string;
  noGrow?: boolean;
}

export const Section: React.FC<IComponentProps> = ({ children, label, noGrow }) => (
  <Row>
    <Label>{label}</Label>
    {noGrow ? <ControlNoGrow>{children}</ControlNoGrow> : <Control>{children}</Control>}
  </Row>
);
