import React from 'react';
import _ from 'lodash';
import { useSelector } from 'dva';

import { getClient as getClientSelector } from 'models/clients/selectors';
import { ROLE_NAMES } from 'models/roles/constants';

import { EditOutlined } from '@ant-design/icons';
import {
  EntityCard,
  EntityCardContent,
  EntityCardTitle,
} from 'pages/PageClient/Single/components/EntityCard/EntityCard';
import { EntityCardRow } from 'pages/PageClient/Single/components/EntityCard/EntityCardRow';
import { EntityCardTitleControl } from 'pages/PageClient/Single/components/EntityCard/EntityCardTitleButton';

import {
  ExternalLink,
  MttPhoneButtonStyled,
} from './styled';

interface IComponentProps {
  openEditModal: () => void;
  personId: string;
}

export const Contact: React.FC<IComponentProps> = ({ openEditModal, personId }) => {
  const { CLIENT_EDIT, SUPER_ADMIN } = ROLE_NAMES;
  const allowedRoles = [SUPER_ADMIN, CLIENT_EDIT];

  const client = useSelector(state => getClientSelector(state, personId));

  const phone = `+${_.get(client, `phone`)}`;

  const items = _.reject([
    {
      name : `Email`,
      value: _.get(client, `email`) ? (
        <ExternalLink href={`mailto:${_.get(client, `email`)}`}>
          {_.get(client, `email`)}
        </ExternalLink>
      ) : null,
      text: _.get(client, `email`),
    },
    {
      name : `Телефон (основной)`,
      value: (
        <>
          <ExternalLink href={`tel:${phone}`}>
            {phone}
          </ExternalLink>
          {/* @ts-ignore */}
          <MttPhoneButtonStyled phone={phone} />
        </>),
      text: phone,
    },
  ], ({ value }) => _.isNil(value));

  return (
    <EntityCard>
      <EntityCardTitle>
        Контакты

        <EntityCardTitleControl
          actionTitle='Изменение данных'
          allowedRoles={allowedRoles}
          onClick={() => openEditModal()}
        >
          <EditOutlined />
        </EntityCardTitleControl>
      </EntityCardTitle>

      <EntityCardContent>
        {_.map(items, ({ name, text, value }, index) => (
          <EntityCardRow key={index} title={name} valueToCopy={text}>
            {value}
          </EntityCardRow>
        ))}
      </EntityCardContent>
    </EntityCard>
  );
};
