import * as React from 'react';
import { useDispatch } from 'dva';

import { create as createAction } from 'models/dictionaries/actions';

import CreateModal from 'components/Modal/Create';

interface IComponentProps {
  entity: string;
  formFields: any[];
  initialValues: any;
  isVisible: boolean;
  onClose: () => void;
}

export const DictionaryCopyModal: React.FC<IComponentProps> = ({
  entity,
  formFields,
  initialValues,
  isVisible,
  onClose,
}) => {
  const dispatch = useDispatch();

  const handleCreate = (item: any) => {
    dispatch(createAction({ entity, item }));
  };

  return (
    <CreateModal
      close={onClose}
      create={handleCreate}
      formFields={formFields}
      item={initialValues}
      mode='copy'
      title='Новая запись'
      visible={isVisible}
    />
  );
};
