import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';
import styled from 'styled-components';
import { palette } from 'styles/theme';

import {
  closeClientMarketingOffer as closeClientMarketingOfferAction,
  getClientMarketingOffers as getClientMarketingOffersAction,
  sendClientMarketingOfferDecision as sendClientMarketingOfferDecisionAction,
} from 'models/clients/actions';
import { getClientMarketingOffers as getClientMarketingOffersSelector } from 'models/clients/selectors';
import { useDictionaries } from 'models/dictionaries/hooks';

import { DefaultTable } from 'components/DefaultTable/DefaultTable';

import { COLUMNS } from './constants';

const StyledTable = styled(DefaultTable)`
  .ant-table-row {
    &.gray {
      background-color: ${palette.black10Color};

      .ant-table-cell {
        background-color: ${palette.black10Color};
      }
    }

    &.blue {
      background-color: ${palette.blue20Color};

      .ant-table-cell {
        background-color: ${palette.blue20Color};
      }
    }

    &.green {
      background-color: ${palette.green20Color};

      .ant-table-cell {
        background-color: ${palette.green20Color};
      }
    }
  }
`;

const MarketingOfferTable = ({
                               personId,
                               hideControls = false,
                               hiddenColumns = [],
                               offsetY = 300,
                               rowKey = `id`,
                               statusFilter,
                               size = `small`,
                             }) => {
  const dispatch = useDispatch();
  const items = useSelector(state => getClientMarketingOffersSelector(state, personId));

  const apply = offerId => dispatch(sendClientMarketingOfferDecisionAction({ chosenFlg: true, offerId }));
  const close = offerId => dispatch(closeClientMarketingOfferAction({ offerId, personId }));
  const decline = offerId => dispatch(sendClientMarketingOfferDecisionAction({ chosenFlg: false, offerId }));
  const getItems = () => dispatch(getClientMarketingOffersAction({ personId }));
  // eslint-disable-next-line no-unused-vars
  const [, dictionaries] = useDictionaries([
    `offerStatus`,
    `marketingOfferType`,
    `product`,
  ]);

  useEffect(() => {
    if (_.isEmpty(items)) getItems();
  }, []);

  if (!personId) return null;

  const dataSource = _.isFunction(statusFilter)
    ? _.filter(items, ({ offerStatusId }) => statusFilter(offerStatusId))
    : items;
  const columns = _.reject(
    COLUMNS(hideControls ? { close } : { apply, close, decline }, dictionaries),
    ({ key }) => _.includes(hiddenColumns, key),
  );

  return (
    <StyledTable
      columns={columns}
      dataSource={dataSource}
      hideControls={hideControls}
      pagination={{
        hideOnSinglePage: true,
        pageSize        : _.size(dataSource),
        size            : `small`,
      }}
      rowClassName={offer => offer?.color}
      rowKey={rowKey}
      scroll={{
        x: `max-content`,
        y: window.innerHeight - offsetY,
      }}
      scrollableHeader
      size={size}
    />
  );
};

export default MarketingOfferTable;
