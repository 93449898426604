import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import _ from 'lodash';
import { useDispatch } from 'dva';

import {
  getClient as getClientAction,
  getClientApplicationDocs as getClientApplicationDocsAction,
  getClientApplications as getClientApplicationsAction,
  getClientCars as getClientCarsAction,
} from 'models/clients/actions';
import { getMultiple as getDictionariesAction } from 'models/dictionaries/actions';
import { getSingle as getVerificationAction } from 'models/verifications/actions';
import { useVerification } from 'models/verifications/hooks/useVerification';

import ClientMatchContext from 'pages/PageClient/Single/ClientMatchContext';
import { ClientTab } from 'pages/PageClient/Single/components/ClientTab/ClientTab';

import { SaleForm } from './Forms/SaleForm';
import { VerificationForm } from './Forms/VerificationForm';
import { useVerificationPath } from './useVerificationPath';

const PageClientSingleDecision = () => {
  const { personId, verificationId } = useContext(ClientMatchContext) as { personId: string; verificationId: number };

  const dispatch = useDispatch();

  const [isLoading, verification] = useVerification(verificationId);

  const getApplications = useCallback((callback: () => void) => dispatch(
    getClientApplicationsAction({ personId, callback }),
  ),
  [dispatch, personId]);
  const getCars = useCallback((force: boolean) => dispatch(
    getClientCarsAction({ personId, force }),
  ),
  [dispatch, personId]);
  const getClient = useCallback((callback = _.noop) => dispatch(
    getClientAction({ personId, callback }),
  ), [dispatch, personId]);
  const getDictionaries = useCallback((entities: string[]) => dispatch(
    getDictionariesAction({ entities, force: true }),
  ), [dispatch]);
  const getDocs = useCallback((applicationId: string) => dispatch(
    getClientApplicationDocsAction({ personId, applicationId, hideMessage: true }),
  ), [dispatch, personId]);
  const getVerification = useCallback((callback = _.noop) => dispatch(
    getVerificationAction({ id: verificationId, callback }),
  ), [dispatch, verificationId]);

  const { isSale } = useVerificationPath({});
  const { applicationId } = verification;

  const onReload = () => {
    getDictionaries([
      `verificationLimit`,
      `verificationResult`,
      `verificationResultType`,
      `verificationStatus`,
    ]);
    getVerification(() => {
      getClient(() => {
        // @ts-ignore
        getApplications(getDocs(applicationId));
        getCars(true);
      });
    });
  };

  useEffect(() => {
    onReload();
  }, []);

  return (
    <ClientTab isReloading={isLoading} onReload={onReload}>
      {isSale ? <SaleForm /> : <VerificationForm />}
    </ClientTab>
  );
};

export default PageClientSingleDecision;
