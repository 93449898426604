import { passwordStrength } from 'check-password-strength';

export interface PasswordCheckResult {
  isValid: boolean;
  message: string;
}

export enum Language {
  RU = `ru`,
  EN = `en`,
}

export type Messages = Record<Language, {
  case: string;
  error: string;
  length: string;
  requirements: string;
  space: string;
  strength: string;
  success: string;
}>;

export const messages: Messages = {
  [Language.RU]: {
    case        : `Пароль должен содержать буквы различных регистров.`,
    error       : `Произошла ошибка при проверке пароля.`,
    length      : `Пароль должен содержать не менее 12 символов.`,
    requirements: `Требования к паролю: не менее 12 символов, буквы различных регистров, без пробелов, сложность выше среднего.`, // eslint-disable-line max-len
    space       : `Пароль не должен содержать пробелов.`,
    strength    : `Сложность пароля должна быть выше среднего, попробуйте добавить специальные символы`,
    success     : `Пароль соответствует всем требованиям.`,
  },
  [Language.EN]: {
    case        : `Password must contain letters of different cases.`,
    error       : `An error occurred while checking the password.`,
    length      : `Password must be at least 12 characters long.`,
    requirements: `Password requirements: at least 12 characters long, contain letters of different cases, should not contain spaces, strength should be above medium.`, // eslint-disable-line max-len
    space       : `Password should not contain spaces.`,
    strength    : `Password strength should be above medium, try to add special symbols`,
    success     : `Password meets all requirements.`,
  },
};

export const validatePassword = (password: string, lang: Language = Language.RU): PasswordCheckResult => {
  try {
    const result = passwordStrength(password);
    let isValid = true;
    const messagesArr: string[] = [];

    if (result.length < 12) {
      isValid = false;
      messagesArr.push(messages[lang].length);
    }

    if (!result.contains.includes(`lowercase`) || !result.contains.includes(`uppercase`)) {
      isValid = false;
      messagesArr.push(messages[lang].case);
    }

    if (password.includes(` `)) {
      isValid = false;
      messagesArr.push(messages[lang].space);
    }

    if (result.id < 2) {
      isValid = false;
      messagesArr.push(messages[lang].strength);
    }

    return {
      isValid,
      message: isValid ? messages[lang].success : messagesArr.join(`\n`),
    };
  } catch (error) {
    console.error(`Ошибка при проверке пароля: ${error}`); // eslint-disable-line no-console
    return {
      isValid: false,
      message: messages[lang].error,
    };
  }
};

