/* eslint-disable import/no-cycle */
import { createAction } from 'redux-actions';

export const model = `user`;

const ca = (type, meta) => createAction(`${model}/${type}`, payload => payload, () => meta);

export const EMAIL_CONFIRM = `EMAIL_CONFIRM`;
export const GET = `GET`;
export const GET_CURRENT = `GET_CURRENT`;
export const LOGIN = `LOGIN`;
export const LOGOUT = `LOGOUT`;
export const LOGOUT_REFRESH = `LOGOUT_REFRESH`;
export const RECOVER = `RECOVER`;
export const REFRESH_TOKEN = `REFRESH_TOKEN`;
export const REQUEST_RECOVER = `REQUEST_RECOVER`;
export const RESET = `RESET`;
export const SET = `SET`;
export const SET_ERROR = `SET_ERROR`;
export const SET_LOADED = `SET_LOADED`;
export const SET_LOADING = `SET_LOADING`;
export const SET_TOKEN = `SET_TOKEN`;

export const emailConfirm = ca(EMAIL_CONFIRM);
export const get = ca(GET);
export const getCurrent = ca(GET_CURRENT);
export const login = ca(LOGIN);
export const logout = ca(LOGOUT);
export const recover = ca(RECOVER);
export const refreshToken = ca(REFRESH_TOKEN);
export const requestRecover = ca(REQUEST_RECOVER);
export const reset = ca(RESET);
export const set = ca(SET);
export const setError = ca(SET_ERROR);
export const setLoading = ca(SET_LOADING);
