import { formatDate } from 'helper';

const DATE_COLUMN = (key, title, restProps = {}) => ({
  align : `center`,
  key,
  render: v => formatDate(v, `dd.MM.yyyy`),
  title,
  width : 90,
  ...restProps,
});

export default DATE_COLUMN;
