import * as React from 'react';
import { useDispatch } from 'dva';

import { create as createAction } from 'models/dictionaries/actions';

import CreateModal from 'components/Modal/Create';

interface IComponentProps {
  entity: string;
  formFields: any[];
  isVisible: boolean;
  onClose: () => void;
}

export const DictionaryCreateModal: React.FC<IComponentProps> = ({ entity, formFields, isVisible, onClose }) => {
  const dispatch = useDispatch();

  const handleCreate = (item: any) => {
    dispatch(createAction({ entity, item }));
  };

  return (
    <CreateModal
      close={onClose}
      create={handleCreate}
      formFields={formFields}
      mode='create'
      title='Новая запись'
      visible={isVisible}
    />
  );
};
