import React from 'react';
import _ from 'lodash';

import {
  getColumn,
  getSort,
  sortAlphabetically,
} from 'helper';

import { ROLE_NAMES } from 'models/roles/constants';

import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import MttPhoneButton from 'components/MttPhoneButton';

export const COLUMNS = ({ canEdit, phonesDictionary, setItemEditing, toggleEditModal }) => _.map([
  {
    key   : `phone`,
    title : `Телефон`,
    sorter: getSort(`phone`, sortAlphabetically),
  },
  {
    key   : `phoneTypeId`,
    render: id => _.get(_.find(phonesDictionary, { id }), `name`),
    title : `Тип`,
    sorter: getSort(`type`, sortAlphabetically),
  },
  {
    key   : `mttPhone`,
    fixed : `right`,
    render: (v, item) => (
      <MttPhoneButton phone={item?.phone} />
    ),
    title: ``,
    width: 50,
  },
  {
    key   : `phone`,
    fixed : `right`,
    render: (v, item) => (
      <Button
        disabled={!canEdit}
        icon={<EditOutlined />}
        onClick={() => {
          setItemEditing(item);
          toggleEditModal();
        }}
        type='primary'
      />
    ),
    title: ``,
    width: 50,
  },
], getColumn);

export const EDIT_ROLES = [ROLE_NAMES.SUPER_ADMIN, ROLE_NAMES.PHONE_EDIT];
