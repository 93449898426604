import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  useDispatch,
  useSelector,
} from 'dva';

import { savePreferences as savePreferencesAction } from 'models/operators/actions';
import { getCurrentPreferences as getPreferences } from 'models/operators/selectors';

function immutableSet(object: any, path: _.PropertyPath, value: any) {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  return _.setWith(_.cloneDeep(object), path, value, _.cloneDeep);
}

export function useStatePreference(
  path: string,
  defaultValue: any,
) {
  const dispatch = useDispatch();
  const preferences = useSelector(state => getPreferences(state));

  const savePreferences = useCallback((value: any) => {
    const data = immutableSet(preferences, path, value);
    dispatch(savePreferencesAction({ preferences: data }));
  }, [dispatch, JSON.stringify(preferences), path]);

  const preferencesValue = _.get(preferences, path);

  const initialState = preferencesValue || defaultValue;

  const [statePreference, setStatePreference] = useState(initialState);

  useEffect(() => {
    if (!_.isNil(preferencesValue)) {
      setStatePreference(_.get(preferences, path));
    }
  }, [preferencesValue]);

  return [statePreference, savePreferences];
}
